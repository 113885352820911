import { Box, Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { MoreActionMenu, PageContainer } from '@nexdynamic/nex-ui-react';
import type { BulkPriceChange } from '@nexdynamic/squeegee-common';
import { useNavigate, useParams } from 'react-router-dom';
import { Data } from '../../../../../../Data/Data';
import Text from '../../../../../components/Text';
import usePriceChangeActions from '../../hooks/usePriceChangeActions';
import { PriceChangeOverview } from './PriceChangeOverview';

export const ViewBulkPriceChange = () => {
    const { id = '' } = useParams();
    const navigate = useNavigate();
    const { edit, notify, apply, rollback, remove } = usePriceChangeActions(id);
    const actions = [edit, notify, apply, rollback, remove];

    const change = Data.get<BulkPriceChange>(id);
    if (!change) return <Box>Change not found</Box>;

    return (
        <PageContainer>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ flex: 1 }}>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <Link
                                underline='none'
                                component='button'
                                sx={{ cursor: 'pointer' }}
                                onClick={() => navigate('/pricing/changes')}
                            >
                                <Text variant='subtitle1' text='bulk-price-change.list-title' />
                            </Link>
                            <Typography variant='subtitle1' color='text.primary'>
                                {change.name}
                            </Typography>
                        </Breadcrumbs>
                    </Box>
                    <MoreActionMenu label='price-change-actions' actions={actions}></MoreActionMenu>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <PriceChangeOverview change={change} />
            </Grid>
        </PageContainer>
    );
};
