import DeleteOutline from '@mui/icons-material/DeleteOutline';
import EditOutlined from '@mui/icons-material/EditOutlined';
import type { ExpenseCategory } from '@nexdynamic/squeegee-common';
import useTranslation from '../../hooks/useTranslation';

import type { NexAction } from '@nexdynamic/nex-ui-react';
import { useConfirm, useNexAction } from '@nexdynamic/nex-ui-react';
import { Logger } from '../../../Logger';
import { TagService } from '../../../Tags/TagService';
import useStoredObject from '../../hooks/useStoredObject';
import type { ExpenseCategoryFields } from '../components/ExpenseCategoryForm';
import { isDuplicateExpenseCategory } from './useCreateExpenseCategory';

type UseExpenseCategoryActions = (id: string) => {
    running: boolean;
    remove: NexAction<() => void>;
    edit: NexAction<(fields: ExpenseCategoryFields) => void>;
};

const useExpenseCategoryActions: UseExpenseCategoryActions = id => {
    const { t } = useTranslation();
    const tag = useStoredObject<ExpenseCategory>(id);
    const [handleAction, running] = useNexAction();
    const { askForConfirmation } = useConfirm();

    const translations = { name: tag?.description || '' };

    return {
        running,
        remove: {
            run: () =>
                handleAction<void>(
                    {
                        successMsg: t('expense-category.removed', translations),
                        errorMsg: t('expense-category.remove-failed'),
                    },
                    async () => {
                        try {
                            const { agreed } = await askForConfirmation({
                                agreeLabel: t('general.remove'),
                                disagreeLabel: t('general.cancel'),
                                title: t('expense-category.remove-confirm-title'),
                                description: t('expense-category.remove-confirm-description', translations),
                            });

                            if (!agreed) return { success: false, notify: false };

                            if (!tag) return { success: false, errorMsg: t('expense-category.remove-failed') };
                            await TagService.removeTag(tag);
                            return { success: true };
                        } catch (error) {
                            Logger.error(error);
                            return { success: false, errorMsg: t('expense-category.remove-failed') };
                        }
                    }
                ),
            name: t('general.remove'),
            icon: <DeleteOutline />,
        },
        edit: {
            run: (fields: ExpenseCategoryFields) =>
                handleAction<void>(
                    {
                        successMsg: t('expense-category.updated', translations),
                        errorMsg: t('expense-category.update-failed'),
                    },
                    async () => {
                        try {
                            if (!tag || isDuplicateExpenseCategory(fields.description, tag._id))
                                return { success: false, errorMsg: t('expense-category.remove-failed') };

                            const newExpenseCategory: ExpenseCategory = {
                                ...tag,
                                description: fields.description,
                                balanceLedgerId: fields.balanceLedgerId || null,
                            };
                            await TagService.updateTag(newExpenseCategory, true);
                            return { success: true };
                        } catch (error) {
                            Logger.error(error);
                            return { success: false, errorMsg: t('expense-category.remove-failed') };
                        }
                    }
                ),
            name: t('general.edit'),
            icon: <EditOutlined />,
        },
    };
};

export default useExpenseCategoryActions;
