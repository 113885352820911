import { activationStrategy } from 'aurelia-router';
import { Inbox } from './Inbox';

export class InboxModule {
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    inbox: ReturnType<typeof Inbox>;

    attached() {
        const container = document.getElementById('inbox-root');
        if (!container) throw new Error('inbox-root element not found');
        this.inbox = Inbox(container);
    }

    detached() {
        this.inbox?.unmount();
    }
}
