import type { BulkPriceChange } from '@nexdynamic/squeegee-common';
import { Data } from '../../../Data/Data';
import { ChangeFields } from '../features/changes/views/change/ChangeFields';

export const createPriceChange: (fields: ChangeFields) => Promise<BulkPriceChange> = async fields => {
    const bulkPriceChange = ChangeFields.toBulkPriceChange(fields);
    await Data.put(bulkPriceChange);

    return bulkPriceChange;
};
