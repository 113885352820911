import { Checkbox, FormControlLabel, FormGroup, Paper } from '@mui/material';

export type PendingViewSettings = {
    showLowRiskCustomers: boolean;
};

type Props = {
    onSettingChange: (setting: string, value: boolean) => void;
    settings: PendingViewSettings;
};

const Filters = ({ onSettingChange, settings }: Props) => {
    return (
        <Paper sx={{ p: 1 }}>
            <FormGroup sx={{ ml: '11px' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={settings.showLowRiskCustomers}
                            onChange={(_, checked) => onSettingChange('showLowRiskCustomers', checked)}
                        />
                    }
                    label="Show Low Risk Customers"
                />
            </FormGroup>
        </Paper>
    );
};

export default Filters;
