import { useContext } from 'react';
import TrackerContext from '../../TrackerContext';
import Timeline from '../Timeline';

const TimelineSection = () => {
    const context = useContext(TrackerContext);
    return <Timeline {...context} />;
};

export default TimelineSection;
