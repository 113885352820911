import { activationStrategy } from 'aurelia-router';
import { Balance } from './Balance';

export class BalanceModule {
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    balance: ReturnType<typeof Balance>;

    attached() {
        const container = document.getElementById('balance-root');
        if (!container) throw new Error('balance-root element not found');
        this.balance = Balance(container);
    }

    detached() {
        this.balance?.unmount();
    }
}
