import { activationStrategy } from 'aurelia-router';
import { PortalDirectory } from './PortalDirectory';
export class PortalDirectoryModule {
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    portalDirectory: ReturnType<typeof PortalDirectory>;

    attached() {
        const container = document.getElementById('portal-directory-root');
        if (!container) throw new Error('portal-directory-root element not found');
        this.portalDirectory = PortalDirectory(container);
    }

    detached() {
        this.portalDirectory?.unmount();
    }
}
