import NoImageIcon from '@mui/icons-material/ImageNotSupported';
import { Box, Card, CardContent, ListItemText, Stack, Typography } from '@mui/material';
import type { GettingStartedOptions } from '../../types/GettingStarted';
import type { ListingInformationOptions } from '../../types/ListingInformationSettings';

type Props = {
    gettingStartedOptions: GettingStartedOptions;
    listingInformationOptions: ListingInformationOptions;
};

const PreviewAndPublishStep = ({ gettingStartedOptions, listingInformationOptions }: Props) => {
    return (
        <Box display="flex" flexDirection="column" gap={1} mb={2}>
            <Typography variant="h5">Preview and Publish</Typography>
            <Typography variant="body1">
                {`Please review the information you've provided and click the 'Publish' button to make your portal live.`}
            </Typography>
            <Card variant="outlined" sx={{ mt: 1 }}>
                <CardContent>
                    <Stack spacing={1}>
                        <Stack direction="row" spacing={2} width="100%">
                            <Box sx={{ display: 'flex', width: '50%', justifyContent: 'center' }}>
                                {listingInformationOptions.logo ? (
                                    <Box sx={{ textAlign: 'center' }}>
                                        <img src={listingInformationOptions.logo} alt="Logo" style={{ maxHeight: 50 }} />
                                        <Typography sx={{ mt: 0.5 }}>Logo</Typography>
                                    </Box>
                                ) : (
                                    <Box sx={{ textAlign: 'center', p: 1 }}>
                                        <NoImageIcon />
                                        <Typography>No logo provided</Typography>
                                    </Box>
                                )}
                            </Box>
                            <Box sx={{ display: 'flex', width: '50%', justifyContent: 'center' }}>
                                {listingInformationOptions.coverImage ? (
                                    <Box sx={{ textAlign: 'center' }}>
                                        <img src={listingInformationOptions.coverImage} alt="Cover" style={{ maxHeight: 50 }} />
                                        <Typography sx={{ mt: 0.5 }}>Cover Image</Typography>
                                    </Box>
                                ) : (
                                    <Box sx={{ textAlign: 'center', p: 1 }}>
                                        <NoImageIcon />
                                        <Typography>No cover image provided</Typography>
                                    </Box>
                                )}
                            </Box>
                        </Stack>
                        <ListItemText primary="Business Name" secondary={listingInformationOptions.businessName} />
                        <ListItemText
                            primary="Business Description"
                            secondary={
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    sx={{
                                        display: '-webkit-box',
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {listingInformationOptions.businessDescription}
                                </Typography>
                            }
                        />
                        {listingInformationOptions.showContactNumber && (
                            <ListItemText primary="Contact Number" secondary={listingInformationOptions.contactNumber} />
                        )}
                        {listingInformationOptions.showEmailAddress && (
                            <ListItemText primary="Email" secondary={listingInformationOptions.emailAddress} />
                        )}
                        {listingInformationOptions.showBusinessAddress && (
                            <ListItemText primary="Business Address" secondary={listingInformationOptions.businessAddress} />
                        )}
                        <ListItemText primary="Directory URL" secondary={`https://squeeg.ee/portal/${gettingStartedOptions.urlHandle}`} />
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    );
};

export default PreviewAndPublishStep;
