import DeleteOutline from '@mui/icons-material/DeleteOutline';
import EditOutlined from '@mui/icons-material/EditOutlined';
import MessageOutlined from '@mui/icons-material/MessageOutlined';
import NewReleasesOutlined from '@mui/icons-material/NewReleasesOutlined';
import UndoOutlined from '@mui/icons-material/UndoOutlined';
import type { BulkPriceChange } from '@nexdynamic/squeegee-common';
import { useNavigate } from 'react-router';
import useStoredObject from '../../../../hooks/useStoredObject';
import useTranslation from '../../../../hooks/useTranslation';

import { useConfirm, useNexAction, type NexAction, type NexActionHandlerResult } from '@nexdynamic/nex-ui-react';
import { applyPriceChange } from './applyPriceChange';
import { editPriceChange } from './editPriceChange';
import { notifyPriceChange } from './notifyPriceChange';
import { removePriceChange } from './removePriceChange';
import { rollbackPriceChange } from './rollbackPriceChange';

type UsePriceChangeActions = (id: string) => {
    running: boolean;
    remove: NexAction<() => Promise<NexActionHandlerResult>>;
    notify: NexAction<() => Promise<NexActionHandlerResult>>;
    apply: NexAction<() => Promise<NexActionHandlerResult>>;
    rollback: NexAction<() => Promise<NexActionHandlerResult>>;
    edit: NexAction<() => void>;
};

const usePriceChangeActions: UsePriceChangeActions = id => {
    const navigate = useNavigate();
    const bulkPriceChange = useStoredObject<BulkPriceChange>(id);

    if (!bulkPriceChange) throw new Error('Price change not found');

    const { askForConfirmation } = useConfirm();

    const { t } = useTranslation();

    const [handleAction, running] = useNexAction();

    return {
        running,
        remove: {
            run: () => removePriceChange(bulkPriceChange, askForConfirmation, handleAction, t, navigate),
            disabled: !!bulkPriceChange.result,
            name: t('general.remove'),
            icon: <DeleteOutline />,
        },
        notify: {
            run: () => notifyPriceChange(bulkPriceChange, askForConfirmation, handleAction, t),
            name: t('general.notify'),
            icon: <MessageOutlined />,
        },
        apply: {
            run: () => applyPriceChange(bulkPriceChange, askForConfirmation, handleAction, t),
            name: t('general.apply'),
            icon: <NewReleasesOutlined />,
            disabled: !!bulkPriceChange.result,
        },
        rollback: {
            run: () => rollbackPriceChange(bulkPriceChange, askForConfirmation, handleAction, t),
            name: t('general.rollback'),
            icon: <UndoOutlined />,
            disabled: !bulkPriceChange.result?.success,
        },
        edit: {
            run: () => editPriceChange(bulkPriceChange, askForConfirmation, navigate, t),
            name: t('general.edit'),
            icon: <EditOutlined />,
            disabled: !!bulkPriceChange.result,
        },
    };
};

export default usePriceChangeActions;
