import { Avatar, Box, ListItem, Typography } from '@mui/material';
import type { AccountUser } from '@nexdynamic/squeegee-common';

const LeaderboardListItem = ({ user, time, rank }: { user: AccountUser | null; time: number; rank: number | null }) => {
    const timeInHours = Math.floor(time / 1000 / 60 / 60);
    const timeInMinutes = Math.floor((time / 1000 / 60 / 60 - timeInHours) * 60);

    return (
        <ListItem
            key={user ? user._id : Math.random() * 100}
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '1rem',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                }}
            >
                <Typography
                    sx={{
                        marginRight: '1rem',
                        fontWeight: 'bold',
                        color: '#6C6C6E',
                    }}
                >
                    {rank ? `0${rank}.` : 'N/A'}
                </Typography>

                <Avatar
                    src={user?.avatar}
                    sx={{
                        width: '2rem',
                        height: '2rem',
                    }}
                />

                <Typography fontWeight='bold'>{user ? user?.name : '-'}</Typography>
            </Box>
            <Typography
                sx={{
                    color: '#6C6C6E',
                }}
            >{`${timeInHours} hrs ${timeInMinutes} mins`}</Typography>
        </ListItem>
    );
};

export default LeaderboardListItem;
