import type { BlockTemplateUpdateInput, TemplateUpdateInput } from '@nexdynamic/squeegee-common';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import useTranslation from '../../../../hooks/useTranslation';
import { templateUpdate } from '../TemplateService';

type UseTemplateActions = (id: string) => {
    updateTemplate: (
        fields: TemplateUpdateInput | BlockTemplateUpdateInput
    ) => Promise<TemplateUpdateInput | BlockTemplateUpdateInput | undefined | null>;
    running: boolean;
};

export const useUpdateTemplate: UseTemplateActions = id => {
    const [running, setRunning] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const updateTemplate = async (fields: TemplateUpdateInput | BlockTemplateUpdateInput) => {
        try {
            setRunning(true);
            const template = await templateUpdate(id, fields);
            if (!template) throw new Error('No template returned');
            enqueueSnackbar(t('templates.saved', { name: fields.name || '' }), { variant: 'success' });
            return template;
        } catch (error) {
            if (error) console.error(error);
            enqueueSnackbar(t('templates.createFailed'), { variant: 'error' });
        }
        setRunning(false);
    };

    return {
        running,
        updateTemplate,
    };
};

export default useUpdateTemplate;
