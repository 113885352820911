import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import type * as React from 'react';
import { deleteAdvertisement } from '../../../services/AdvertisementService';

type DeleteDialogProps = {
    dialogOpen: boolean;
    setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    id: string;
    onDelete: () => void;
};

export default function DeleteAdvertAlertDialog({ dialogOpen, setDialogOpen, id, onDelete }: DeleteDialogProps) {
    const handleClose = () => {
        setDialogOpen(false);
    };
    const handleDelete = () => {
        deleteAdvertisement(id).then(onDelete);
        setDialogOpen(false);
    };

    return (
        <div>
            <Dialog open={dialogOpen} onClose={handleClose}>
                <DialogTitle>{'Delete advert?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>{'Are you sure you want to remove this item?'}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleDelete}>Yes</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
