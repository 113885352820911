import { activationStrategy } from 'aurelia-router';
import { Suppliers } from './Suppliers';

export class SuppliersModule {
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    suppliers: ReturnType<typeof Suppliers>;

    attached() {
        const container = document.getElementById('suppliers-root');
        if (!container) throw new Error('suppliers-root element not found');
        this.suppliers = Suppliers(container);
    }

    detached() {
        this.suppliers?.unmount();
    }
}
