import { Box, Button, Card, List, ListItem, ListItemSecondaryAction, ListItemText, Switch } from '@mui/material';
import { portalComponentSettingsDefaults } from '@nexdynamic/squeegee-portal-common';
import { useState } from 'react';
import { ApplicationState } from '../../../../ApplicationState';
import type { AureliaReactComponentDialogComponent } from '../../../../Dialogs/AureliaReactComponentDialogComponent';
import { setPortalComponentSetting } from './setPortalComponentSetting';

export const EditProfileSettingsEditor: AureliaReactComponentDialogComponent<any> = () => {
    const settingDefaults = portalComponentSettingsDefaults['edit-profile-button'];
    type SettingType = typeof settingDefaults;
    const editProfileComponentSettings = ApplicationState.getSetting<SettingType>('portal.component-edit-profile-button', settingDefaults);
    const [editProfileSettings, setEditProfileSettings] = useState<SettingType>(editProfileComponentSettings);

    return (
        <>
            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                    <Button
                        variant="contained"
                        sx={{ mr: 2, mt: 2 }}
                        onClick={() => setPortalComponentSetting('edit-profile-button', editProfileSettings)}
                    >
                        {ApplicationState.localise('general.save-button')}
                    </Button>
                </Box>
                <Card sx={{ m: 2 }} variant="outlined">
                    <List>
                        <ListItem>
                            <ListItemText>
                                {ApplicationState.localise('portal-component.edit-profile-disable-billing-address-updates')}
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <Switch
                                    checked={editProfileSettings.disableBillingAddressChanges}
                                    color="secondary"
                                    onChange={e =>
                                        setEditProfileSettings({
                                            disableBillingAddressChanges: e.target.checked,
                                        })
                                    }
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Card>
            </Box>
        </>
    );
};
