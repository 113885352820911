import { Box, Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { PageContainer } from '@nexdynamic/nex-ui-react';
import { copyObject, type Supplier } from '@nexdynamic/squeegee-common';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import Text from '../../components/Text';
import useStoredObject from '../../hooks/useStoredObject';
import { supplierGetContacts } from '../SupplierService';
import { SupplierForm, type SupplierFormFields } from '../components/SupplierForm';
import useSupplierUpdate from '../hooks/useUpdateSupplier';

const SupplierEditView = () => {
    const navigate = useNavigate();
    const { id = '' } = useParams();
    const supplier = useStoredObject<Supplier>(id);
    const { updateSupplier } = useSupplierUpdate(id);

    const onSubmit = async (fields: SupplierFormFields) => {
        await updateSupplier(fields);
        navigate(`/suppliers/${id}`);
    };

    const view = useMemo(() => {
        if (supplier) {
            const copy = copyObject(supplier);
            const supplierFormFields: SupplierFormFields = {
                name: copy.name,
                notes: copy.notes || '',
                contactIds: supplierGetContacts(copy.contactIds).map(contact => contact._id),
            };
            return <SupplierForm fields={supplierFormFields} onSubmit={onSubmit} />;
        }
        return (
            <Box>
                <Text text='audiences.audience-not-found' />
            </Box>
        );
    }, [supplier, onSubmit]);

    return (
        <PageContainer>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link sx={{ cursor: 'pointer' }} component='button' underline='none' onClick={() => navigate('/suppliers/list')}>
                            <Text variant='subtitle1' text='suppliers.title' />
                        </Link>
                        <Link sx={{ cursor: 'pointer' }} underline='none' onClick={() => navigate(`/suppliers/${supplier?._id}`)}>
                            <Typography variant='subtitle1'>{supplier?.name}</Typography>
                        </Link>
                        <Text variant='subtitle1' color='text.primary' text='general.edit' />{' '}
                    </Breadcrumbs>
                </Box>
            </Grid>

            <Grid item xs={12}>
                {view}
            </Grid>
        </PageContainer>
    );
};

export default SupplierEditView;
