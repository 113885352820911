import { Box, Breadcrumbs, Grid } from '@mui/material';
import { PageContainer } from '@nexdynamic/nex-ui-react';
import Text from '../../../../components/Text';
import CampaignList from '../components/CampaignList';
import CreateCampaignButton from '../components/CreateCampaignButton';

export const Campaigns = () => {
    return (
        <PageContainer>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ flex: 1 }}>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <Text variant='subtitle1' color='text.primary' text='campaigns.title' />
                        </Breadcrumbs>
                    </Box>
                    <Box>
                        <CreateCampaignButton />
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <CampaignList />
            </Grid>
        </PageContainer>
    );
};

export default Campaigns;
