import { Box, Grid, Typography, useTheme } from '@mui/material';
import { Themes } from '@nexdynamic/squeegee-templates';

const Colours = () => {
    const theme = useTheme();

    return (
        <div>
            <Grid container padding={3}>
                <Grid item xs={6}>
                    <Typography variant='h3'>Squeegee Palette</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {Object.entries(Themes).map(([key, value]) => {
                            return (
                                <Box
                                    key={key}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 1,
                                    }}
                                >
                                    <Typography variant='h4'>{key}</Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            gap: 1,
                                        }}
                                    >
                                        {Object.entries(value.palette).map(([key, value]) => {
                                            if (typeof value === 'string') {
                                                return (
                                                    <Box
                                                        key={key}
                                                        sx={{
                                                            width: 42,
                                                            height: 42,
                                                            borderRadius: '50%',
                                                            backgroundColor: value as string,
                                                        }}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <Box
                                                        key={key}
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: 1,
                                                        }}
                                                    >
                                                        {Object.entries(value).map(([key, value]) => {
                                                            return (
                                                                <Box
                                                                    key={key}
                                                                    sx={{
                                                                        width: 42,
                                                                        height: 42,
                                                                        borderRadius: '50%',
                                                                        backgroundColor: value as string,
                                                                    }}
                                                                />
                                                            );
                                                        })}
                                                    </Box>
                                                );
                                            }
                                        })}
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='h3'>MUI Palette</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {Object.entries(theme.palette).map(([key, value]) => {
                            if (typeof value === 'string') {
                                return (
                                    <Box
                                        key={key}
                                        sx={{
                                            width: 42,
                                            height: 42,
                                            borderRadius: '50%',
                                            backgroundColor: value as string,
                                        }}
                                    />
                                );
                            } else {
                                return (
                                    <Box
                                        key={key}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 1,
                                        }}
                                    >
                                        {Object.entries(value).map(([key, value]) => {
                                            return (
                                                <Box
                                                    key={key}
                                                    sx={{
                                                        width: 42,
                                                        height: 42,
                                                        borderRadius: '50%',
                                                        backgroundColor: value as string,
                                                    }}
                                                />
                                            );
                                        })}
                                    </Box>
                                );
                            }
                        })}
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default Colours;
