import { useEffect, useState } from 'react';
import { fetchNotificationsUnreadCount } from '../../../Notifications/functions/fetchNotificationsUnreadCount';

export const useInboxUnreadCount = () => {
    const [count, setCount] = useState<number>(0);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setError(false);
        setLoading(true);
        const get = fetchNotificationsUnreadCount();

        get.then(count => {
            setCount(count || 0);
        })
            .catch(error => {
                console.error('Error fetching notifications', error);
                setError(true);
            })
            .finally(() => setLoading(false));
    }, []);

    return {
        loading,
        count,
        error,
    };
};
