import { to2dp } from '@nexdynamic/squeegee-common';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import moment from 'moment';
import { useMemo } from 'react';
import { ApplicationState } from '../../../ApplicationState';
import type { ScheduleItem } from '../../../Schedule/Components/ScheduleItem';
import { AssignmentService } from '../../../Users/Assignees/AssignmentService';
import { Utilities } from '../../../Utilities';
import type { BarData } from '../../components/charts/StackedBarChart';
import { useScheduleItemsByDay } from '../../hooks/useScheduleItemsByDay';

export const useSkillsFulfillmentWeekStats = (
    selectedDate: Dayjs,
    availability: Record<string, number>,
    workerSkills: Record<string, Array<string>>
) => {
    const { scheduleItemsByDay } = useScheduleItemsByDay(selectedDate.toISOString());
    const barData = useMemo(() => {
        return daySkillData(selectedDate, scheduleItemsByDay, availability, workerSkills);
    }, [scheduleItemsByDay, availability, workerSkills]);

    return barData;
};

export function daySkillData(
    selectedDate: Dayjs,
    scheduleItemsByDay: Record<string, Record<string, ScheduleItem>>,
    availability: Record<string, number>,
    workerSkills: Record<string, Array<string>>
) {
    const startOfWeek = moment(selectedDate.toDate()).startOf('isoWeek').toDate();
    const daysOfWeek = [0, 1, 2, 3, 4, 5, 6].map(day => dayjs(startOfWeek).add(day, 'day').toDate());
    const barData: BarData[] = [];
    const barDataLabels: Array<string> = [];
    const statsByDay: Record<string, SkillSummaryForScheduleItems> = {};
    const skills: Array<string> = [];
    const rounds: Array<string> = [];
    const services: Array<string> = [];

    for (const date of daysOfWeek) {
        const dateKey = dayjs(date).format('YYYY-MM-DD');
        const scheduleItems = scheduleItemsByDay[dateKey] ? Object.values(scheduleItemsByDay[dateKey]) : [];
        const stats = getRequiredHoursBySkillForJobs(dateKey, scheduleItems, availability, workerSkills);
        statsByDay[dateKey] = stats;
        for (const service of Object.keys(stats.serviceSummaries)) {
            if (!services.includes(service)) services.push(service);
        }
        for (const round of Object.keys(stats.roundSummaries)) {
            if (!rounds.includes(round)) rounds.push(round);
        }

        const barSkills: Record<string, number> = {};
        if (!ApplicationState.getSetting('global.availability-bar-type', false)) {
            for (const skill of Object.keys(stats.skillSummaries)) {
                if (!skills.includes(skill)) skills.push(skill);
                if (!barDataLabels.includes(skill + ' fufilled')) barDataLabels.push(skill + ' fufilled');
                if (!barDataLabels.includes(skill + ' unfufilled')) barDataLabels.push(skill + ' unfufilled');
                const unfufilled = stats.skillSummaries[skill].unfufilled < 0 ? 0 : stats.skillSummaries[skill].unfufilled;
                barSkills[skill + ' fufilled'] = stats.skillSummaries[skill].duration - unfufilled;
                barSkills[skill + ' unfufilled'] = unfufilled;
            }
        } else {
            // Use monetary value instead of skills
            for (const scheduleItem of scheduleItems) {
                const value = scheduleItem.occurrence.price || 0;
                if (!barDataLabels.includes('Value fufilled')) barDataLabels.push('Value fufilled');
                if (!barDataLabels.includes('Value unfufilled')) barDataLabels.push('Value unfufilled');

                const assigned = !!AssignmentService.getAssignees(scheduleItem.occurrence).length;

                if (assigned) {
                    barSkills['Value fufilled'] = (barSkills['Value fufilled'] || 0) + value;
                } else {
                    barSkills['Value unfufilled'] = (barSkills['Value unfufilled'] || 0) + value;
                }
            }
        }
        barData.push({ date, ...barSkills });
    }

    return {
        statsByDay,
        barData,
        skills,
        rounds,
        services,
        barDataLabels,
    };
}

export type SkillSummaryForScheduleItems = {
    totals: { matched: number; unmatched: number; required: number; requiredSkillHours: number; availableTotal: number };
    skillSummaries: Record<
        string,
        { count: number; duration: number; availableWorkers: number; availableWorkerHours: number; unfufilled: number }
    >;
    serviceSummaries: Record<string, { count: number; duration: number }>;
    roundSummaries: Record<string, { count: number; duration: number }>;
} & { assignedHoursPerWorker?: Record<string, number> };

export function getRequiredHoursBySkillForJobs(
    dateKey: string,
    scheduleItems: Array<ScheduleItem>,
    availability: Record<string, number>,
    workerSkills: Record<string, Array<string>>
): SkillSummaryForScheduleItems {
    // Get worker availability by skill
    let required = 0;
    let requiredSkillHours = 0;
    let matched = 0;
    let unmatched = 0;
    let availableTotal = 0;
    const availabilityBySkill: Record<string, { count: number; hours: number }> = {};
    for (const worker in workerSkills) {
        const skills = workerSkills[worker];
        availableTotal += availability[worker + dateKey] || 0;

        for (const skill of skills) {
            availabilityBySkill[skill] = availabilityBySkill[skill] || { count: 0, hours: 0 };
            availabilityBySkill[skill].hours += availability[worker + dateKey] || 0;
            availabilityBySkill[skill].count++;
        }
    }

    const skillSummaries: Record<
        string,
        { count: number; duration: number; availableWorkers: number; availableWorkerHours: number; unfufilled: number }
    > = {};

    const serviceSummaries: Record<string, { count: number; duration: number }> = {};
    const roundSummaries: Record<string, { count: number; duration: number }> = {};
    const assignedHoursPerWorker: Record<string, number> = {};
    for (const si of scheduleItems) {
        required += Utilities.durationToMinutes(si.occurrence.duration) / 60;

        for (const service of si.occurrence.services) {
            serviceSummaries[service.description] = serviceSummaries[service.description] || { count: 0, duration: 0 };
            serviceSummaries[service.description].count++;
            serviceSummaries[service.description].duration += Utilities.durationToMinutes(si.occurrence.duration) / 60;
        }

        for (const round of si.occurrence.rounds) {
            roundSummaries[round.description] = roundSummaries[round.description] || { count: 0, duration: 0 };
            roundSummaries[round.description].count++;
            roundSummaries[round.description].duration += Utilities.durationToMinutes(si.occurrence.duration) / 60;
        }

        if (si.occurrence.skills?.length) {
            for (const skill of si.occurrence.skills) {
                skillSummaries[skill] = skillSummaries[skill] || { count: 0, duration: 0 };
                skillSummaries[skill].count++;
                skillSummaries[skill].duration += Utilities.durationToMinutes(si.occurrence.duration) / 60;

                // If we have availability for this skill, add it to the summary
                if (availabilityBySkill[skill]) {
                    skillSummaries[skill].availableWorkerHours = availabilityBySkill[skill].hours;
                    skillSummaries[skill].availableWorkers = availabilityBySkill[skill].count;
                }
            }
        }
        const assignees = AssignmentService.getAssignees(si.occurrence, true);
        for (const assignee of assignees) {
            assignedHoursPerWorker[assignee._id] =
                (assignedHoursPerWorker[assignee._id] || 0) + Utilities.durationToMinutes(si.occurrence.duration) / 60;
        }
    }

    // Update the total matched and unmatched
    for (const skillSummary of Object.values(skillSummaries)) {
        requiredSkillHours += skillSummary.duration;
        matched += skillSummary.availableWorkerHours || 0;
        skillSummary.unfufilled = skillSummary.duration - skillSummary.availableWorkerHours || 0;

        skillSummary.availableWorkerHours = to2dp(skillSummary.availableWorkerHours);
        skillSummary.duration = to2dp(skillSummary.duration);
        skillSummary.unfufilled = to2dp(skillSummary.unfufilled);
    }

    for (const roundSummary of Object.values(roundSummaries)) {
        roundSummary.duration = to2dp(roundSummary.duration);
    }

    for (const id in assignedHoursPerWorker) {
        assignedHoursPerWorker[id] = to2dp(assignedHoursPerWorker[id]);
    }

    unmatched = required - matched;

    return {
        totals: {
            matched: to2dp(matched),
            unmatched: to2dp(unmatched),
            required: to2dp(required),
            requiredSkillHours: to2dp(requiredSkillHours),
            availableTotal: to2dp(availableTotal),
        },
        skillSummaries,
        roundSummaries,
        serviceSummaries,
        assignedHoursPerWorker,
    };
}
