import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography, lighten, styled } from '@mui/material';
import { useState } from 'react';
import { ApplicationState } from '../../../ApplicationState';

const CodeBlock = styled(Typography)(({ theme }) => ({
    fontFamily: 'monospace',
    backgroundColor: lighten(theme.palette.background.paper, 0.05),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    display: 'inline-block',
    margin: theme.spacing(1),
}));

const Page0 = () => {
    const userName = ApplicationState.dataEmail;

    return (
        <>
            <Typography variant="body1">{`We've developed a robust query system for your audit logs, in this dialog you'll find documentation on our query language which will help you find exactly what you want, faster.`}</Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1">{`Our query language is a simple string based query system, you can use the following operators:`}</Typography>
            <CodeBlock variant="body1">{`&&`}</CodeBlock>
            <CodeBlock variant="body1">{`||`}</CodeBlock>
            <CodeBlock variant="body1">{`!`}</CodeBlock>
            <CodeBlock variant="body1">{`..`}</CodeBlock>
            <CodeBlock variant="body1">{`before:`}</CodeBlock>
            <CodeBlock variant="body1">{`after:`}</CodeBlock>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1">{`The && operator is used to find audit entries that contain both words, for example:`}</Typography>
            <CodeBlock variant="body1">{`${userName} && transaction`}</CodeBlock>
            <Typography variant="body1">{`The || operator is used to find audit entries that contain either word, for example:`}</Typography>
            <CodeBlock variant="body1">{`${userName} || support@squeeg.ee`}</CodeBlock>
            <Typography variant="body1">{`The ! operator is used to find audit entries that do not contain the word, for example:`}</Typography>
            <CodeBlock variant="body1">{`!support@squeeg.ee`}</CodeBlock>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1">{`You can use these operators together to create complex queries, for example:`}</Typography>
            <CodeBlock variant="body1">{`!delete || !modified && ${userName}`}</CodeBlock>
            <Typography variant="body1">{`This query will find all audit entries that do not contain deleted or modified objects and contain the user name.`}</Typography>
        </>
    );
};
const Page1 = () => {
    const userName = ApplicationState.dataEmail;
    const today = new Date().toISOString().slice(0, 10).split('-').reverse().join('/');
    return (
        <>
            <Typography variant="body1">{`Searching by date is easy, you must follow the date format of DD/MM/YYYY. For example:`}</Typography>
            <CodeBlock variant="body1">{today}</CodeBlock>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1">{`You can also string dates together with our other operators, for example:`}</Typography>
            <CodeBlock variant="body1">{`${today} && ${userName}`}</CodeBlock>
            <Typography variant="body1">{`This query will find all audit entries that were created on ${today} and contain the user name.`}</Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1">{`You can search between two dates, for example:`}</Typography>
            <CodeBlock variant="body1">{`01/01/2021..${today}`}</CodeBlock>
            <Typography variant="body1">{`This query will find all audit entries that were created between 01/01/2021 and today. You should keep in mind the first date must be before the last date.`}</Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1">{`You can search before and after a date, for example:`}</Typography>
            <CodeBlock variant="body1">{`before:${today} && after:01/01/2021`}</CodeBlock>
            <Typography variant="body1">{`This query will find all audit entries that were created before today and after 01/01/2021.`}</Typography>
        </>
    );
};

const QuerySystemDocs = ({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) => {
    const [page, setPage] = useState(0);

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Search Query System</DialogTitle>
            <DialogContent sx={{ pb: 0 }}>{page === 0 ? <Page0 /> : page === 1 ? <Page1 /> : null}</DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Close</Button>
                <Button disabled={page === 0} onClick={() => setPage(page - 1)}>
                    Back
                </Button>
                <Button disabled={page === 1} onClick={() => setPage(page + 1)}>
                    Next
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default QuerySystemDocs;
