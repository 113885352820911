import { activationStrategy } from 'aurelia-router';
import { Pricing } from './Pricing';

export class PricingModule {
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    pricing: ReturnType<typeof Pricing>;

    attached() {
        const container = document.getElementById('pricing-root');
        if (!container) throw new Error('pricing-root element not found');
        this.pricing = Pricing(container);
    }

    detached() {
        this.pricing?.unmount();
    }
}
