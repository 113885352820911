import { activationStrategy } from 'aurelia-router';
import { Editor } from './Editor';
export class EditorModule {
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    editor: ReturnType<typeof Editor>;

    attached() {
        const container = document.getElementById('editor-root');
        if (!container) throw new Error('editor-root element not found');
        this.editor = Editor(container);
    }

    detached() {
        this.editor?.unmount();
    }
}
