import { Box, keyframes } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import type { IAdvertisementListing } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logAdvertEvent, refreshCheckoutStatus } from '../services/AdvertisementService';
import AdvertListingCard from './AdvertListingCard';

import AdvertDetails from './dialogs/AdvertDetails';
import { EmptyAdvertList } from './EmptyAdvertList';
type MarketplaceAdvertsListProps = {
    adverts: Array<IAdvertisementListing>;
    onSaved: (advert: IAdvertisementListing) => void;
};

const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
export const AdvertList = ({ adverts }: MarketplaceAdvertsListProps) => {
    const [selectedAdvert, setSelectedAdvert] = useState<IAdvertisementListing | null>(null);
    const navigate = useNavigate();
    const handleCheckoutSuccess = (advert: IAdvertisementListing) => {
        setSelectedAdvert(null);
        refreshCheckoutStatus(advert.id)
            .then(() => {
                navigate(`/marketplace/my-purchases/${advert.id}`);
            })
            .catch(() => {
                navigate(`/marketplace/my-purchases/${advert.id}`);
            });
    };

    const handleClose = () => {
        setSelectedAdvert(null);
    };

    const handleViewAdvertisment = (advert: IAdvertisementListing) => {
        logAdvertEvent(advert.id, 'ADVERTISEMENT_VIEWED', 'User clicked on an advertisement to view it.');
        setSelectedAdvert(advert);
    };

    return (
        <>
            <Box sx={{ px: 2 }}>
                <Grid2
                    sx={{
                        animation: `${fade} 1s ease 0.2s both`,
                    }}
                    container
                    spacing={2}
                >
                    {(adverts.length &&
                        adverts.map(advert => {
                            return (
                                <Grid2 key={advert.id} xl={4} lg={6} md={12} sm={12} xs={12}>
                                    <AdvertListingCard onClick={advert => handleViewAdvertisment(advert)} advert={advert} />
                                </Grid2>
                            );
                        })) || <EmptyAdvertList />}
                </Grid2>
            </Box>
            {selectedAdvert && (
                <AdvertDetails
                    onCheckoutSuccess={handleCheckoutSuccess}
                    onClose={handleClose}
                    open={true}
                    advert={selectedAdvert}
                ></AdvertDetails>
            )}
        </>
    );
};
