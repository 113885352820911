import type { LedgerAccountSubType, PaymentAccount } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { Data } from '../../Data/Data';
import './payment-account-select.scss';

const paymentAccounts: Array<LedgerAccountSubType> = [
    'equity-owner-funds-out',
    'equity-owner-funds-in',
    'asset.current-bank-account',
    'liability.current-credit-card',
    'asset.current-loan',
    'asset.current-prepayment',
    'equity-owner-funds-in',
    'liability.current-corporation-tax',
    'liability.current-directors-loan',
    'liability.non-current-loan',
];

export class PaymentAccountSelect {
    @bindable() value: undefined | string;

    @bindable() excludeAccountId: undefined | string;
    protected allAccounts: Readonly<Array<PaymentAccount>>;
    protected loading = true;

    @bindable protected selected: PaymentAccount | undefined;
    async init() {
        this.allAccounts = await Data.all(
            'paymentaccounts',
            x =>
                (this.excludeAccountId === undefined || x._id !== this.excludeAccountId) &&
                (x.subType === undefined || paymentAccounts.includes(x.subType))
        );
        this.selected = this.allAccounts.find(x => x._id === this.value);
    }

    valueChanged() {
        if (this.loading) return;
        this.selected = this.allAccounts.find(x => x._id === this.value);
    }

    getAccounts = async (searchText: string, currentValue: PaymentAccount) => {
        const results = [];
        if (currentValue) {
            this.selected = currentValue;
            this.value = currentValue?._id;
            results.push(currentValue);
        }

        const sResults = await Data.all<PaymentAccount>(
            'paymentaccounts',
            x =>
                (this.excludeAccountId === undefined || x._id !== this.excludeAccountId) &&
                (x.subType === undefined || paymentAccounts.includes(x.subType)) &&
                (!searchText || x.name.toLowerCase().includes(searchText.toLowerCase()))
        );
        results.push(...sResults);
        return results;
    };

    async attached() {
        this.loading = true;
        await this.init();
        this.loading = false;
    }
}
