import type { SelectChangeEvent } from '@mui/material';
import { Box, Checkbox, ListItem, ListItemText, MenuItem, OutlinedInput, Select, Slider, TextField } from '@mui/material';
import { GradientButton, useIsSmallScreen } from '@nexdynamic/nex-ui-react';
import { useState } from 'react';
import { createPortalTheme, type PortalThemeOptions } from '../../../../DirectoryAndPortal/types/PortalTheme';

type AdvFontFamilyProps = {
    theme: PortalThemeOptions;
    addFont: (variants: Array<FontVariant>, fontFamily: string, fontWeight?: number) => void;
};

export type FontVariant =
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'body1'
    | 'body2'
    | 'subtitle1'
    | 'subtitle2'
    | 'button'
    | 'caption'
    | 'overline';

const AdvFontFamily = ({ theme: themeProps, addFont }: AdvFontFamilyProps) => {
    const theme = createPortalTheme(themeProps);
    const isMobile = theme.breakpoints.down('md');

    const [fontFamily, setFontFamily] = useState<string>(theme.typography.body1.fontFamily as string);
    const [fontWeight, setFontWeight] = useState<number>(theme.typography.body1.fontWeight as number);
    const [selectedVariants, setSelectedVariants] = useState<Array<FontVariant>>(['body1']);
    const [overrideFontWeight, setOverrideFontWeight] = useState<boolean>(false);
    const [hasChanged, setHasChanged] = useState<boolean>(false);

    const handleChange = (e: SelectChangeEvent<typeof selectedVariants>) => {
        const {
            target: { value },
        } = e;
        setSelectedVariants(typeof value === 'string' ? [value as FontVariant] : value);
        setHasChanged(true);
    };

    const isSmallScreen = useIsSmallScreen();

    return (
        <ListItem sx={{ gap: 1, flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'flex-start' : 'center' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: isSmallScreen ? 'flex-start' : 'center',
                }}
            >
                <ListItemText primary='Font Variants' secondary='Select the variants to apply your changes to' />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {selectedVariants.length > 1 && (
                        <Box>
                            <GradientButton color='error' onClick={() => setSelectedVariants(['body1'])}>
                                Clear
                            </GradientButton>
                        </Box>
                    )}
                    <Select
                        multiple
                        variant='outlined'
                        size='small'
                        value={selectedVariants}
                        onChange={handleChange}
                        renderValue={selected => (selected as string[]).join(', ')}
                        input={<OutlinedInput />}
                    >
                        <MenuItem value='h1'>H1</MenuItem>
                        <MenuItem value='h2'>H2</MenuItem>
                        <MenuItem value='h3'>H3</MenuItem>
                        <MenuItem value='h4'>H4</MenuItem>
                        <MenuItem value='h5'>H5</MenuItem>
                        <MenuItem value='h6'>H6</MenuItem>
                        <MenuItem value='body1'>Body 1</MenuItem>
                        <MenuItem value='body2'>Body 2</MenuItem>
                        <MenuItem value='subtitle1'>Subtitle 1</MenuItem>
                        <MenuItem value='subtitle2'>Subtitle 2</MenuItem>
                        <MenuItem value='button'>Button</MenuItem>
                        <MenuItem value='caption'>Caption</MenuItem>
                        <MenuItem value='overline'>Overline</MenuItem>
                    </Select>
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: isSmallScreen ? 'flex-start' : 'center',
                }}
            >
                <ListItemText primary='Font Family' secondary='Enter the name of a Google Font' />
                <TextField variant='outlined' size='small' value={fontFamily} onChange={e => setFontFamily(e.target.value)} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <ListItemText primary='Font Weight' secondary='Enter a number between 100 and 900' />
                    <Checkbox checked={overrideFontWeight} onChange={e => setOverrideFontWeight(e.target.checked)} />
                </Box>
                <Box sx={{ width: '100%', px: isSmallScreen ? 0 : 2 }}>
                    <Slider
                        min={100}
                        max={900}
                        step={100}
                        valueLabelDisplay='auto'
                        size='small'
                        value={fontWeight}
                        onChange={(_, value) => setFontWeight(Number(value))}
                        marks={[
                            { value: 100, label: '100' },
                            { value: 200, label: '200' },
                            { value: 300, label: '300' },
                            { value: 400, label: '400' },
                            { value: 500, label: '500' },
                            { value: 600, label: '600' },
                            { value: 700, label: '700' },
                            { value: 800, label: '800' },
                            { value: 900, label: '900' },
                        ]}
                        disabled={!overrideFontWeight}
                    />
                </Box>
            </Box>
            {hasChanged && (
                <GradientButton
                    color='info'
                    onClick={() => {
                        addFont(selectedVariants, fontFamily, overrideFontWeight ? fontWeight : undefined);
                        setHasChanged(false);
                    }}
                >
                    Apply
                </GradientButton>
            )}
        </ListItem>
    );
};

export default AdvFontFamily;
