import { ScopedCssBaseline, styled } from '@mui/material';
import { ConfirmationProvider } from '@nexdynamic/nex-ui-react';
import { SnackbarProvider } from 'notistack';
import type { FC } from 'react';
import ThemeProvider from '../themes/ThemeProvider';

//remove when https://github.com/iamhosseindhv/notistack/issues/440 fixed
const Snackbar = styled(SnackbarProvider)`
    &.SnackbarItem-variantSuccess {
        background: ${p => p.theme.gradients.success.main};
    }
    &.SnackbarItem-variantError {
        background: ${p => p.theme.gradients.error.main};
    }
    &.SnackbarItem-variantWarning {
        background: ${p => p.theme.gradients.warning.main};
    }
`;

type Props = {
    children: React.ReactNode;
};

const LibraryAppProviders: FC<Props> = ({ children }) => {
    return (
        <ThemeProvider>
            <ConfirmationProvider>
                <ScopedCssBaseline />
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} maxSnack={3}>
                    {children}
                </Snackbar>
            </ConfirmationProvider>
        </ThemeProvider>
    );
};

export default LibraryAppProviders;
