import type { TemplateState } from '@nexdynamic/squeegee-common';
import type { RawDraftContentState } from 'draft-js';
import { convertFromRaw, EditorState } from 'draft-js';
import { useMemo } from 'react';
import RichText from '../../../../components/editor/RichText';

type TemplateEditorProps = {
    onChange: (state: TemplateState) => void;
    initialValue?: RawDraftContentState;
};

export const TemplateEditor = ({ onChange, initialValue }: TemplateEditorProps) => {
    return useMemo(
        () => (
            <>
                <RichText
                    initialState={initialValue ? EditorState.createWithContent(convertFromRaw(initialValue)) : undefined}
                    onChange={onChange}
                />
            </>
        ),
        [initialValue, onChange]
    );
};
