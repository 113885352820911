import { Box, Button, Dialog, DialogActions, DialogContent, Typography, useMediaQuery, useTheme } from '@mui/material';
import type { IAdvertisementListing } from '@nexdynamic/squeegee-common';
import { AdvertCheckout } from '../AdvertCheckout';
import { Price } from '../Price';

import { useState } from 'react';

type DialogProps = {
    onSuccess: () => void;
    open: boolean;
    advert: IAdvertisementListing;
    onCancel: () => void;
};

const AdvertCheckoutDialog = ({ onSuccess, open, advert, onCancel }: DialogProps) => {
    const { title, description, sellerName, price, currency } = advert;
    const [checkoutSuccess, setCheckoutSuccess] = useState(false);
    const handleSuccess = () => {
        setCheckoutSuccess(true);
        onSuccess();
    };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // TODO Should not be setting fixed width and height or padding, gap or colours
    return (
        <div>
            <Dialog
                fullScreen={isMobile ? true : false}
                onPointerCancel={onCancel}
                open={open}
                onClose={onCancel}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                PaperProps={{ sx: { width: '37.5rem', height: isMobile ? '100%' : '50rem' } }}
            >
                <DialogContent sx={{ paddingBottom: '0', width: '100%' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Box width='100%'>
                            <Box
                                sx={{
                                    display: 'flex',
                                    maxHeight: '1rem',
                                    marginBottom: '1rem',
                                }}
                            >
                                <Typography sx={{ fontSize: 13 }} color='text.secondary' gutterBottom>
                                    Distance: {advert.distance?.toFixed()} km
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.6rem',
                                        ml: 'auto',
                                        maxWidth: '20rem',
                                        height: '0.5rem',
                                        background: '#f5f5f5',
                                        borderRadius: '3px',
                                        padding: 1,
                                    }}
                                >
                                    <Typography sx={{ color: '#696969', fontSize: '0.9em', ml: 'auto' }}>{sellerName}</Typography>
                                </Box>
                            </Box>

                            <Typography variant='h6' component='div' sx={{ mt: '1rem' }}>
                                {title}
                            </Typography>
                            <Typography sx={{ mb: 1.4, fontSize: '0.9em' }} color='text.secondary'>
                                {description}
                            </Typography>
                            <Price price={price} currency={currency} />
                        </Box>
                    </Box>
                    {checkoutSuccess ? (
                        <Typography sx={{ fontSize: '1.2em', textAlign: 'center', mt: '1rem' }}>Purchase successful!</Typography>
                    ) : (
                        <AdvertCheckout advert={advert} onSuccess={handleSuccess} onCancel={onCancel} />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AdvertCheckoutDialog;
