import { useEffect, useState } from 'react';
import { ApplicationState } from '../../ApplicationState';
import type { IApplicationStateFlags } from '../../ApplicationStateFlags';
import { StateFlagsChangedEvent } from '../../Events/StateFlagsChangedEvent';

type UseStateFlags = () => IApplicationStateFlags;

export const useStateFlags: UseStateFlags = () => {
    const [stateFlags, setStateFlags] = useState<IApplicationStateFlags>({
        connectionStatusText: ApplicationState.stateFlags.connectionStatusText,
        devMode: ApplicationState.stateFlags.devMode,
        enablingDevMode: ApplicationState.stateFlags.enablingDevMode,
        showDevModeCounter: ApplicationState.stateFlags.showDevModeCounter,
    });

    useEffect(() => {
        const sub = StateFlagsChangedEvent.subscribe(() => {
            setStateFlags({
                connectionStatusText: ApplicationState.stateFlags.connectionStatusText,
                devMode: ApplicationState.stateFlags.devMode,
                enablingDevMode: ApplicationState.stateFlags.enablingDevMode,
                showDevModeCounter: ApplicationState.stateFlags.showDevModeCounter,
            });
        });
        return () => {
            sub.dispose();
        };
    }, []);

    return stateFlags;
};

export default useStateFlags;
