import { Box } from '@mui/material';
import { GradientButton } from '@nexdynamic/nex-ui-react';
import type { AudienceFilter, AudienceFilterModel, AudienceFilterOperator, AudienceFilterOption } from '@nexdynamic/squeegee-common';
import { AudienceFilterValueType, camelAndPascalCaseToTitleCase, uuid } from '@nexdynamic/squeegee-common';
import type { FormEvent } from 'react';
import { useState } from 'react';
import useTranslation from '../../../../../hooks/useTranslation';
import FilterField from '../FilterField';
import FilterOperator from '../FilterOperator';
import FilterValueInput from '../FilterValueInput';

type Props = {
    filters: Array<AudienceFilterModel>;
    onSubmit: (filter: AudienceFilter) => void;
};

export const AudienceFilterForm = ({ filters, onSubmit }: Props) => {
    const [activeFilter, setActiveFilter] = useState<AudienceFilterModel>(filters[0]);
    const [operator, setOperator] = useState<AudienceFilterOperator | undefined>(activeFilter.operators[0]);
    const [value, setValue] = useState<string>('');
    const [filterOptions, setFilterOptions] = useState<AudienceFilterOption[]>([]);
    const { t } = useTranslation();

    const addFilter = (event: FormEvent) => {
        event.preventDefault();
        const filter: AudienceFilter = {
            id: uuid(),
            field: activeFilter.field,
            label: camelAndPascalCaseToTitleCase(activeFilter.field),
            value,
            operator,
            options: filterOptions,
        };
        setValue('');
        onSubmit(filter);
    };

    return (
        <form onSubmit={addFilter}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                <Box sx={{ flex: 1, minWidth: 140 }}>
                    <FilterField
                        label='Field'
                        filters={filters}
                        value={activeFilter?.field}
                        onChange={filter => {
                            setActiveFilter(filter);
                            setFilterOptions([filter.options[0]]);
                            setValue(filter.options.length ? filter.options[0].value : '');
                            setOperator(filter.operators[0]);
                        }}
                    />
                </Box>
                {operator && (
                    <Box sx={{ flex: 1, minWidth: 180 }}>
                        <FilterOperator
                            label='Operator'
                            value={operator}
                            filter={activeFilter}
                            onChange={operator => setOperator(operator)}
                        />
                    </Box>
                )}
                <Box sx={{ flex: 1, minWidth: 180 }}>
                    <FilterValueInput
                        label='Value'
                        disabled={activeFilter.valueType !== AudienceFilterValueType.Boolean && !operator}
                        filter={activeFilter}
                        value={value}
                        selected={filterOptions[0]?.id}
                        onChange={v => setValue(v)}
                        onSelected={option => {
                            setFilterOptions([option]);
                            setValue(option.value);
                        }}
                    />
                </Box>
                <Box sx={{ flex: 1, minWidth: 120, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <GradientButton type='submit' text={t('audiences.add-filter')} sx={{ flex: 1 }} color='primary'></GradientButton>
                </Box>
            </Box>
        </form>
    );
};

export default AudienceFilterForm;
