import { Box } from '@mui/material';
import InboxOfflineMessage from './InboxOfflineMessage';

type Props = {
    children: React.ReactNode;
};

export const MAIL_LIST_HEADER_HEIGHT = 56;

const MailListHeader = ({ children }: Props) => {
    return (
        <Box
            sx={{
                px: 2,
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
                position: 'sticky',
                top: 0,
                bgcolor: 'background.paper',
                borderBottom: 1,
                borderColor: 'divider',
                zIndex: 10,
                display: 'flex',
                height: MAIL_LIST_HEADER_HEIGHT,
                alignItems: 'center',
            }}
        >
            <Box sx={{ flex: 1 }}>{children}</Box>
            <InboxOfflineMessage />
        </Box>
    );
};

export default MailListHeader;
