import { MentionInput, useRequestInput } from '@nexdynamic/nex-ui-react';
import type { TranslationKey } from '@nexdynamic/squeegee-common';
import type { TextFieldProps } from 'formik-mui';
import React from 'react';
import { ApplicationState } from '../../../ApplicationState';
import { StandardMessageModelTokens } from '../../../StandardMessageModel';

type Props = TextFieldProps & {
    variant?: 'outlined';
};

const TokenTextField = (props: Props) => {
    const { requestInput } = useRequestInput();
    const error = props.form.touched[props.field.name] && Boolean(props.form.errors[props.field.name]);

    const mentions = React.useMemo(
        () =>
            Object.keys(new StandardMessageModelTokens()).map(key => {
                const label = ApplicationState.localise(`standardMessageModel.${key}` as TranslationKey);
                const value = `${key}`;
                return {
                    label,
                    value,
                };
            }),
        []
    );

    const handleChange = async (event: React.ChangeEvent) => {
        let value = (event.target as HTMLInputElement).value;

        if (value) {
            const found = /\[([a-z]+?=\?)]/i.exec(value);
            if (found) {
                const tokenKey = found[1];
                const newParamToken =
                    (await requestInput({
                        title: ApplicationState.localise(`standardMessageModel.${tokenKey}` as TranslationKey),
                    })) || '';

                value = value.replace(/\?/, newParamToken);
            }
        }
        (event.target as HTMLInputElement).value = value;
        props.field.onChange(event);
    };

    return (
        <MentionInput
            variant={props.variant}
            mentions={mentions}
            trigger='['
            label={props.label}
            name={props.field.name}
            value={props.field.value}
            error={error}
            helperText={error ? <>{props.form.errors[props.field.name]}</> : props.helperText}
            onChange={handleChange}
            {...props}
        />
    );
};

export default TokenTextField;
