import { Button, Chip, useTheme } from '@mui/material';
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { useIsSmallScreen } from '@nexdynamic/nex-ui-react';
import type { Customer } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { CustomerDialog } from '../../../../Customers/Components/CustomerDialog';
import type { Leads } from '../../hooks/leads/useLeadAnalytics';
import { determineChipProps } from '../../hooks/portalAnalyticsUtils';
import { CustomNoRowsOverlay } from './MUI/CustomNoRowsOverlay';

type Props = {
    leads: Leads;
};

const LeadsTable = ({ leads }: Props) => {
    const getStatus = (customer: Customer) => {
        if (leads.rawLeads.leads.some(lead => lead._id === customer._id)) {
            return 'Raw';
        }
        if (leads.pendingLeads.leads.some(lead => lead._id === customer._id)) {
            return 'Pending';
        }
        if (leads.acceptedLeads.leads.some(lead => lead._id === customer._id)) {
            return 'Accepted';
        }
        if (leads.declinedLeads.leads.some(lead => lead._id === customer._id)) {
            return 'Declined';
        }
        if (leads.expiredLeads.leads.some(lead => lead._id === customer._id)) {
            return 'Expired';
        }
        if (leads.convertedLeads.leads.some(lead => lead._id === customer._id)) {
            return 'Converted';
        }
        if (leads.activeLeads.leads.some(lead => lead._id === customer._id)) {
            return 'Active';
        }
        if (leads.cancelledLeads.leads.some(lead => lead._id === customer._id)) {
            return 'Cancelled';
        }
        return 'N/A';
    };

    const isSmallScreen = useIsSmallScreen();

    const columns: Array<GridColDef> = [
        {
            type: 'dateTime',
            field: 'createdDate',
            headerName: 'Created At',
            valueFormatter: (value: string) => moment(value).format('LLL'),
            valueGetter: value => new Date(value).valueOf(),
            flex: isSmallScreen ? undefined : 0.12,
        },
        { field: '_externalId', headerName: 'Ref', flex: isSmallScreen ? undefined : 0.1 },
        { field: 'name', headerName: 'Name', flex: isSmallScreen ? undefined : 0.15 },
        { field: 'email', headerName: 'Email', flex: isSmallScreen ? undefined : 0.2 },
        { field: 'telephoneNumber', headerName: 'Phone', flex: isSmallScreen ? undefined : 0.16 },
        {
            field: 'status',
            headerName: 'Status',
            renderCell: (params: GridRenderCellParams<Customer>) => {
                const chipProps = determineChipProps(getStatus(params.row), theme);
                return (
                    <Chip
                        label={getStatus(params.row)}
                        color={chipProps.string}
                        icon={chipProps.icon}
                        sx={{
                            '& .MuiChip-icon': {
                                fontSize: '1.2rem',
                            },
                        }}
                    />
                );
            },
            valueGetter: (_value, row) => getStatus(row),
            flex: isSmallScreen ? undefined : 0.16,
            headerAlign: 'right',
            align: 'right',
        },
        {
            field: 'view',
            headerName: '',
            renderCell: (params: GridRenderCellParams<Customer>) => {
                return (
                    <Button
                        color="primary"
                        onClick={() => {
                            new CustomerDialog(params.row).show();
                        }}
                    >
                        View
                    </Button>
                );
            },
            valueGetter: (_value, row) => getStatus(row),
            flex: isSmallScreen ? undefined : 0.1,
            headerAlign: 'right',
            align: 'right',
        },
    ];

    const theme = useTheme();

    return (
        <DataGrid
            rows={leads.allLeads.leads}
            getRowId={row => row._id}
            columns={columns}
            initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            sx={{
                'flex': 1,
                '& p': {
                    m: 0,
                },
                '& .MuiDataGrid-columnHeader:focus-within': {
                    outline: 'none',
                },
                '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
                '& .MuiDataGrid-virtualScroller': {
                    minHeight: '300px',
                },
                'borderColor': theme.palette.divider,
                '--DataGrid-containerBackground': 'transparent',
                '--DataGrid-rowBorderColor': theme.palette.divider,
                '--DataGrid-cellBorderColor': theme.palette.divider,
                '& .MuiDataGrid-withBorderColor': { borderColor: theme.palette.divider },
            }}
            slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                toolbar: () => {
                    return (
                        <GridToolbarContainer>
                            <GridToolbarExport />
                        </GridToolbarContainer>
                    );
                },
            }}
            disableRowSelectionOnClick
        />
    );
};

export default LeadsTable;
