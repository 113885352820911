import OneWayIcon from '@mui/icons-material/ArrowRightAltRounded';
import TwoWayIcon from '@mui/icons-material/CompareArrowsRounded';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import { useIsConnected } from '@nexdynamic/nex-ui-react';
import type {
    EmailEngineAccount,
    EmailEngineIMAPConfig,
    EmailEngineOauth2Config,
    EmailEngineSMTPConfig,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import { EmailAuthType } from '@nexdynamic/squeegee-common';
import type { SnackbarKey } from 'notistack';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import type React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { ApplicationState } from '../../../ApplicationState';
import { Api } from '../../../Server/Api';
import { useSetting } from '../../hooks/useSetting';
import useTranslation from '../../hooks/useTranslation';
import type { EmailSettingsViewRoutes } from '../EmailSettingsViewRoutes';
import { getMatchingProviderFromAccount } from '../utils/getMatchingProviderFromAccount';
import type { Providers } from './Providers';

export type EmailProvider = {
    id: string;
    name: TranslationKey;
    route?: EmailSettingsViewRoutes;
    description: TranslationKey;
    type: EmailAuthType;
    connected: boolean;
    connection: EmailEngineOauth2Config | EmailEngineIMAPConfig | EmailEngineSMTPConfig | undefined;
    icon?: string | React.ReactNode;
    oneWay: boolean;
    devOnly: boolean;
};

type EmailEngineContextType = {
    account: EmailEngineAccount | undefined;
    registerAccount: (options: {
        type?: EmailAuthType;
        redirectUrl?: string;
        smtp?: EmailEngineSMTPConfig;
        imap?: EmailEngineIMAPConfig;
        skipAuthLink?: boolean;
    }) => Promise<string | undefined>;
    reconnectAccount: () => Promise<void>;
    reconnecting: boolean;
    syncAccount: () => Promise<void>;
    syncing: boolean;
    getAuthLink: ({ type, redirectUrl }: { type: EmailAuthType; redirectUrl?: string }) => Promise<any>;
    disconnectAccount: () => Promise<any>;
    disconnecting: boolean;
    emailProviders: Providers;
};

const EmailEngineContext = createContext<EmailEngineContextType | undefined>(undefined);

export const EmailEngineProvider = ({ children }: { children: React.ReactNode }) => {
    const { t } = useTranslation();
    const isConnected = useIsConnected();

    const [account] = useSetting<EmailEngineAccount>('global.email-engine-account');
    const [reconnecting, setReconnecting] = useState(false);
    const [disconnecting, setDisconnecting] = useState(false);
    const [syncing, setSyncing] = useState(false);

    const [emailProviders, setEmailProviders] = useState<Providers>({});

    useEffect(() => {
        const emailApi = ApplicationState.account.emailAPI || 'squeegee-smtp';

        const emailProviders = {
            squeegee: {
                id: 'squeegee-smtp',
                name: 'email-settings.provider-one-way-title',
                description: 'email-settings.provider-one-way-description',
                icon: <OneWayIcon color="inherit" fontSize="large" />,
                connected: emailApi === 'squeegee-smtp',
                connection: undefined,
                type: EmailAuthType.Squeegee,
                oneWay: true,
                devOnly: false,
                route: 'one-way',
            },
            custom: {
                id: 'custom-provider',
                name: 'email-settings.provider-two-way-title',
                description: 'email-settings.provider-two-way-description',
                type: EmailAuthType.Imap,
                connected: emailApi === 'email-engine' && account?.type === 'imap',
                connection: getMatchingProviderFromAccount(account),
                icon: <TwoWayIcon color="inherit" fontSize="large" />,
                oneWay: false,
                devOnly: false,
                route: 'custom-provider',
            },
            gmail: {
                id: 'gmail',
                name: 'email-settings.provider-gmail-title',
                description: 'email-api.gmail-description',
                type: EmailAuthType.Gmail,
                connected: emailApi === 'email-engine' && account?.app === EmailAuthType.Gmail,
                connection: getMatchingProviderFromAccount(account),
                icon: <GoogleIcon color="inherit" />,
                oneWay: false,
                devOnly: true,
                route: 'gmail',
            },
            microsoft: {
                id: 'microsoft',
                name: 'email-settings.provider-microsoft-title',
                description: 'email-api.microsoft-description',
                type: EmailAuthType.Microsoft,
                connected: emailApi === 'email-engine' && account?.app === EmailAuthType.Microsoft,
                connection: getMatchingProviderFromAccount(account),
                icon: <MicrosoftIcon color="inherit" />,
                oneWay: false,
                devOnly: false,
                route: 'microsoft',
            },
        } as const;

        setEmailProviders(emailProviders);

        Api.get(null, '/api/email-engine/account', undefined, undefined, true);
    }, [account]);

    const reconnectAccount = async () => {
        setReconnecting(true);
        const response = await Api.put(null, '/api/email-engine/account/reconnect', {}).then(res => res?.data as { reconnect: boolean });

        if (response?.reconnect) {
            enqueueSnackbar('Account reconnected', { variant: 'success' });
        } else {
            enqueueSnackbar('Failed to reconnect account', { variant: 'error' });
        }
        setReconnecting(false);
        return;
    };

    const syncAccount = async () => {
        setSyncing(true);
        const response = await Api.put(null, '/api/email-engine/account/sync', {}).then(res => res?.data as { sync: boolean });

        if (response?.sync) {
            enqueueSnackbar('Account synced', { variant: 'success' });
        } else {
            enqueueSnackbar('Failed to sync account', { variant: 'error' });
        }
        setSyncing(false);
        return;
    };

    const registerAccount = async ({
        type,
        redirectUrl,
        smtp,
        imap,
        skipAuthLink,
    }: {
        type?: EmailAuthType;
        redirectUrl?: string;
        smtp?: EmailEngineSMTPConfig;
        imap?: EmailEngineIMAPConfig;
        skipAuthLink?: boolean;
    }) => {
        const response = await Api.post(null, '/api/email-engine/account', {
            ...(skipAuthLink ? undefined : { getAuthLink: { type, redirectUrl } }),
            smtp,
            imap,
        }).then(res => res?.data as { url: string });

        if (response?.url) {
            enqueueSnackbar('Account registered', { variant: 'success' });
            return response?.url;
        } else {
            enqueueSnackbar('Failed to register account', { variant: 'error' });
            return;
        }
    };

    const getAuthLink = async ({ type, redirectUrl }: { type: EmailAuthType; redirectUrl?: string }) => {
        const response = await Api.post<{ url: string }>(null, '/api/email-engine/generate-auth-link', {
            type,
            redirectUrl,
        });
        return response?.data.url;
    };

    const disconnectAccount = async () => {
        setDisconnecting(true);
        const response = await Api.delete(null, '/api/email-engine/account');
        ApplicationState.account.emailAPI = 'squeegee-smtp';
        ApplicationState.save();
        setDisconnecting(false);
        enqueueSnackbar('Account disconnected', { variant: 'success' });
        return response?.data;
    };

    useEffect(() => {
        let snackbarId: SnackbarKey | undefined;
        if (!isConnected) {
            snackbarId = enqueueSnackbar(t('network.connection-lost'), { variant: 'offline', persist: true });
            return;
        }

        closeSnackbar(snackbarId);
    }, [isConnected]);

    return (
        <EmailEngineContext.Provider
            value={{
                registerAccount,
                account,
                reconnectAccount,
                reconnecting,
                syncAccount,
                syncing,
                getAuthLink,
                disconnectAccount,
                disconnecting,
                emailProviders,
            }}
        >
            {children}
        </EmailEngineContext.Provider>
    );
};

export const useEmailEngine = () => {
    const context = useContext(EmailEngineContext);
    if (context === undefined) {
        throw new Error('useEmailEngine must be used within an EmailEngineProvider');
    }
    return context;
};
