import type { BlockTemplate, Template } from '@nexdynamic/squeegee-common';
import useStoredObjects from '../../../../hooks/useStoredObjects';

class Options {
    includeReadonly = false;
}

type UseTemplates = (options?: Options) => Array<Template | BlockTemplate>;

const useTemplates: UseTemplates = ({ includeReadonly } = new Options()) => {
    const templates = useStoredObjects<Template>('templates');
    const blockTemplates = useStoredObjects<BlockTemplate>('blocktemplates');

    const sortedTemplates: Array<Template | BlockTemplate> = [...templates, ...blockTemplates].sort((a, b) => a.name.localeCompare(b.name));

    return includeReadonly ? sortedTemplates : sortedTemplates.filter(t => !t.readonly);
};

export default useTemplates;
