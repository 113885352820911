import { Box } from '@mui/material';
import { ListPagination, MultiSelectProvider, getPagingQueryVariables } from '@nexdynamic/nex-ui-react';
import type { AudienceFilterGroup, TranslationKey } from '@nexdynamic/squeegee-common';
import { audienceFilterToCustomerSearch, copyObject } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import Text from '../../../../../components/Text';
import useCustomers from '../../../../../hooks/useCustomers';
import useTranslation from '../../../../../hooks/useTranslation';
import ContactList from '../../../contacts/components/ContactList';
import ContactsCount from '../../../contacts/components/ContactsCount';

type AudienceFiltersPreviewProps = {
    filterGroups: Array<AudienceFilterGroup>;
    audienceDescription?: TranslationKey;
    audienceNoResultsDescription?: TranslationKey;
};
const CONTACTS_PER_PAGE = 10;
const AudienceFiltersPreview = ({
    filterGroups,
    audienceDescription = 'general.contacts',
    audienceNoResultsDescription = 'audiences.no-contacts-in-audience',
}: AudienceFiltersPreviewProps) => {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState<string>('');
    const paging = getPagingQueryVariables(page, CONTACTS_PER_PAGE);
    const { t } = useTranslation();
    const { customers, count } = useCustomers({
        paging,
        search: audienceFilterToCustomerSearch(copyObject(filterGroups), search),
    });

    const onChange = (newPage: number) => {
        setPage(newPage);
    };

    const pagingProps = { count, ...paging, page, onChange };

    return (
        <MultiSelectProvider disableSelect={true}>
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ flex: 1 }}>
                    <Text variant='h6' text={audienceDescription} sx={{ mb: 1 }} />
                </Box>
                <Box>
                    <ContactsCount count={count} />
                </Box>
            </Box>
            <ContactList onSearch={setSearch} contacts={customers} emptyMessage={t(audienceNoResultsDescription)} />
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
                <ListPagination {...pagingProps} />
            </Box>
        </MultiSelectProvider>
    );
};

export default AudienceFiltersPreview;
