import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import { IconButton, Stack, Typography } from '@mui/material';
import { useEmailSettingsViewRouter } from '../useEmailSettingsViewRouter';

type Props = {
    title: string;
};

const Header = ({ title }: Props) => {
    const { navigateBack } = useEmailSettingsViewRouter();
    return (
        <Stack direction="row" alignItems="center" spacing={1} mb={1}>
            <IconButton onClick={navigateBack}>
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" gutterBottom>
                {title}
            </Typography>
        </Stack>
    );
};

export default Header;
