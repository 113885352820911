import {
    BalanceApiLedgerDetailsProvider,
    BalanceProvider,
    BalanceTRPCProvider,
    InternalLedgerDetailsProvider,
} from '@nexdynamic/balance-components-react';
import { useMemo, useRef, useState } from 'react';
import { configureTwoFactorAuth } from '../../Account/TwoFactorAuth/configureTwoFactorAuth';
import { ApplicationState } from '../../ApplicationState';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { Api } from '../../Server/Api';
import { RethinkDbAuthClient } from '../../Server/RethinkDbAuthClient';
import { getHeaders } from '../../Server/getHeaders';
import { isDevMode } from '../../isDevMode';
import { SqueegeeBalanceDbIndexedDbStore } from './SqueegeeBalanceDbIndexedDbStore';
import { SqueegeeLedgersProvider } from './SqueegeeLedgersProvider';
type Props = {
    children?: React.ReactNode;
    hideLoader?: boolean;
};

let updateQueueCount = 0;

DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
    if (event.hasAnyType('customers', 'transactions', 'paymentaccounts', 'supplier', 'journalentries')) {
        updateQueueCount++;
    }
});

export const getBalanceEndpoint = () => {
    const apiEndpoint = Api.apiEndpoint;
    // LOCAL WEBPACK PROXIES THE CALL TO http://localhost:8090
    if (apiEndpoint.includes('localhost:')) return 'http://localhost:3000/balance-api';
    // DEV
    if (apiEndpoint.startsWith('https://dev.')) return 'https://dev.balance.sqgee.com/api';
    if (apiEndpoint.startsWith('https://staging.')) return 'https://staging.balance.sqgee.com/api';
    // PROD
    return 'https://balance.sqgee.com/api';
};

export const SqueegeeBalance = ({ children, hideLoader }: Props) => {
    const [updateCount, setUpdateCount] = useState(updateQueueCount);

    const apiEndpoint = Api.apiEndpoint;

    const showTwoFactorDialog = async () => {
        return await configureTwoFactorAuth();
    };

    const balanceApi = `${apiEndpoint}/api/balance`;
    const trpcApi = `${getBalanceEndpoint()}/trpc`;

    const trpcHeaders = useMemo(() => getHeaders(RethinkDbAuthClient.session), [RethinkDbAuthClient.session]);
    const storeProvider = useRef(new SqueegeeBalanceDbIndexedDbStore(ApplicationState.dataEmail));

    const sampleLedgerDetailsProvider = new InternalLedgerDetailsProvider();

    const fetchHeaders = {
        'data-email': ApplicationState.dataEmail,
        'authorization': `Bearer ${RethinkDbAuthClient.getSessionKeyAndValue() || ''}`, // must be lowercase
        'Authorization': `Bearer ${RethinkDbAuthClient.getSessionKeyAndValue() || ''}`, // must be lowercase
        'device-id': ApplicationState.deviceId,
    };

    const balanceLedgersProvider = useMemo(() => {
        return new BalanceApiLedgerDetailsProvider({
            trpcEndpoint: trpcApi,
            trpcHeaders,
        });
    }, [trpcHeaders]);

    const remoteLedgerDetailsProvider = useRef(new SqueegeeLedgersProvider());

    return (
        <BalanceTRPCProvider trpcEndpoint={trpcApi} trpcHeaders={getHeaders(RethinkDbAuthClient.session)}>
            <BalanceProvider
                showTwoFactorDialog={showTwoFactorDialog}
                apiEndpoint={balanceApi}
                ledgerDetailsProviders={[sampleLedgerDetailsProvider, remoteLedgerDetailsProvider.current, balanceLedgersProvider]}
                fetchHeaders={fetchHeaders}
                updateQueueCount={updateCount}
                storeProvider={storeProvider.current}
                devMode={isDevMode()}
                onRefreshed={() => {
                    updateQueueCount = 0;
                    setUpdateCount(updateQueueCount);
                }}
                hideLoader={hideLoader}
                disabledViews={{
                    journals: true,
                }}
            >
                {children}
            </BalanceProvider>
        </BalanceTRPCProvider>
    );
};
