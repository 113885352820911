import CircleIcon from '@mui/icons-material/Circle';
import { IconButton, Tooltip } from '@mui/material';
import type { StoredEvent } from '@nexdynamic/squeegee-common';
import { useMemo } from 'react';
import { TimerService } from '../../../Tracking/TimerService';
type CalculateXPositionProps = {
    startTime: Date;
    containerWidth: number;
    referenceTime: Date;
};

type UnboundEventProps = {
    storedEvent: StoredEvent | undefined;
    startTime: Date;
    containerWidth: number;
    refTime: Date;
    workingHours: number;
    workingDayStart: Date;
};

const UnboundEvent = ({ storedEvent, startTime, containerWidth, refTime, workingHours, workingDayStart }: UnboundEventProps) => {
    const referenceTime = useMemo(() => {
        const date = new Date(refTime);
        date.setHours(workingDayStart.getHours());
        date.setMinutes(0);
        date.setSeconds(0);
        return date;
    }, [refTime, workingDayStart]);

    const calculateXPosition = ({ startTime, containerWidth, referenceTime }: CalculateXPositionProps) => {
        const millisecondsInAMinute = 60000;
        const millisecondsInAnHour = 60 * millisecondsInAMinute;
        const startTimeInMilliseconds = startTime.getTime();
        const referenceTimeInMilliseconds = referenceTime.getTime();

        const hoursDifference = (startTimeInMilliseconds - referenceTimeInMilliseconds) / millisecondsInAnHour;
        const minuteWidth = containerWidth / (workingHours * 60);

        return minuteWidth * hoursDifference * 60;
    };

    return (
        <IconButton
            onClick={
                storedEvent
                    ? (event: React.MouseEvent<HTMLButtonElement>) => {
                          event.stopPropagation();
                          TimerService.showScheduleItemForActiveTimer(storedEvent);
                      }
                    : undefined
            }
            sx={{
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                left: `${calculateXPosition({ startTime, containerWidth, referenceTime })}px`,
                color: 'error.light',
                zIndex: 2,
            }}
        >
            <Tooltip title='Unbound event' placement='top' arrow>
                <CircleIcon color='inherit' sx={{ fontSize: 11 }} />
            </Tooltip>
        </IconButton>
    );
};

export default UnboundEvent;
