import type { TimeEntry } from '@nexdynamic/squeegee-common';

export type Stats = {
    recorded: number;
    worked: number;
    unrecorded: number;
    timeEntries: TimeEntry[];
    byDay: Record<
        string,
        {
            date: string;
            recorded: number;
            unrecorded: number;
            timeEntries: TimeEntry[];
        }
    >;
    byDayAndWorker: Record<
        string,
        {
            worker: string;
            date: string;
            recorded: number;
            started: number;
            finished: number;
            timeEntries: TimeEntry[];
        }
    >;
};

function getFirstAndLastTimestampsOfTimeEntries(timeEntries: TimeEntry[]) {
    const firstTimeEntry = timeEntries.reduce((acc: TimeEntry, curr) => {
        if (!acc || (curr.start?.eventTimestamp && (!acc.start?.eventTimestamp || curr.start.eventTimestamp < acc.start.eventTimestamp))) {
            return curr;
        }
        return acc;
    }, undefined);

    const lastTimeEntry = timeEntries.reduce((acc: TimeEntry, curr) => {
        if (!acc || (curr.stop && (!acc.stop || curr.stop.eventTimestamp > acc.stop.eventTimestamp))) {
            return curr;
        }
        return acc;
    }, undefined);
    return {
        firstTimestamp: firstTimeEntry?.start?.eventTimestamp,
        lastTimestamp: lastTimeEntry?.stop?.eventTimestamp,
    };
}

export function getUnrecordedTime(timeEntries: TimeEntry[]) {
    const { firstTimestamp, lastTimestamp } = getFirstAndLastTimestampsOfTimeEntries(timeEntries);
    if (!firstTimestamp || !lastTimestamp) {
        console.warn('No valid time entries found');
        return;
    }

    const totalDurationInMs = Math.abs(lastTimestamp - firstTimestamp);

    const recordedTime = timeEntries.reduce((acc, curr) => {
        if (curr.type === 'recorded' && curr.stop?.eventTimestamp && curr.start?.eventTimestamp) {
            const diffInMs = Math.abs(curr.stop.eventTimestamp - curr.start.eventTimestamp);

            return acc + diffInMs;
        }
        return acc;
    }, 0);

    const unrecordedTime = totalDurationInMs - recordedTime;

    return unrecordedTime / 3600000;
}

export function formatTime(time: number) {
    const hours = Math.floor(time);
    const minutes = Math.floor((time - hours) * 60);
    return `${hours} hrs ${minutes} mins`;
}

// calculations for average time per job

export function getAverageTimePerJob(recordedTime: number, jobCount: number) {
    return recordedTime / jobCount;
}

export function getStatsForTimeEntries(timeEntries: Array<TimeEntry>): Stats {
    // Group time entries by day and by worker

    const rangeSummary: Stats = {
        recorded: 0,
        worked: 0,
        unrecorded: 0,
        timeEntries: [],
        byDayAndWorker: {},
        byDay: {},
    };

    // Iterate through all time entries so we can get some stats
    for (const curr of timeEntries) {
        // If this time entry is a dud ignore it
        if (!curr.start || !curr.stop) continue;

        // Generate date and assignee group by key
        const date = new Date(curr.start.eventTimestamp);
        const dateStr = date.toISOString().substring(0, 10);
        const dateWorkerKey = `${dateStr}-${curr.assigneeId}`;

        // If we don't have a group for this date and assignee, create one
        if (!rangeSummary.byDayAndWorker[dateWorkerKey]) {
            rangeSummary.byDayAndWorker[dateWorkerKey] = {
                worker: curr.assigneeId,
                date: dateStr,
                recorded: 0,
                started: 0,
                finished: 0,
                timeEntries: [],
            };
        }
        const workerData = rangeSummary.byDayAndWorker[dateWorkerKey];

        // Add to the stats
        workerData.recorded += curr.stop.eventTimestamp - curr.start.eventTimestamp;
        // If we don't have a start time or the start time is earlier than the current start time, set it
        if (!workerData.started && workerData.started > curr.start.eventTimestamp) workerData.started = curr.start.eventTimestamp;
        // If we don't have a finish time or the finish time is later than the current finish time, set it
        if (!workerData.finished && workerData.finished < curr.stop.eventTimestamp) workerData.finished = curr.stop.eventTimestamp;
        workerData.timeEntries.push(curr);
        rangeSummary.timeEntries.push(curr);
    }

    // Now we have all the recorded time for each worker on each day, we can calculate the totals and unrecorded time
    const entries = Object.values(rangeSummary.byDayAndWorker);
    for (const curr of entries) {
        const dateKey = curr.date;

        if (!rangeSummary.byDay[dateKey]) {
            rangeSummary.byDay[dateKey] = { date: dateKey, recorded: 0, unrecorded: 0, timeEntries: [] };
        }
        // Update the day stats for this day
        const dayData = rangeSummary.byDay[dateKey];
        dayData.recorded += curr.recorded / 3600000;
        // Calculate the unrecorded time, it should be the time between each time entry added up
        const unrecordedTime = () => {
            let unrecordedTime = 0;
            let lastTimeEntry = null;
            for (const timeEntry of curr.timeEntries) {
                if (lastTimeEntry && timeEntry.start && lastTimeEntry.stop) {
                    unrecordedTime += timeEntry.start.eventTimestamp - lastTimeEntry.stop.eventTimestamp;
                }
                lastTimeEntry = timeEntry;
            }
            return unrecordedTime;
        };
        dayData.unrecorded += unrecordedTime() / 3600000;
        dayData.timeEntries.push(...curr.timeEntries);
        // Update the range stats
        rangeSummary.recorded += curr.recorded / 3600000;
        rangeSummary.unrecorded = Object.values(rangeSummary.byDay).reduce((acc, curr) => acc + curr.unrecorded, 0);
    }

    return rangeSummary;
}
