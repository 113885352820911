import type { AudienceFilterModel, AudienceFilterOption, Customer, StoredObjectResourceTypes, Tag, TranslationKey } from '@nexdynamic/squeegee-common';
import {
    AudienceFilterOperator,
    AudienceFilterValueType,
    CustomerSearchFields,
    camelAndPascalCaseToTitleCase,
    notNullUndefinedEmptyOrZero
} from '@nexdynamic/squeegee-common';
import { Data } from '../../../../Data/Data';
import { Logger } from '../../../../Logger';
import { t } from '../../../../t';

const textOperators: Array<AudienceFilterOperator> = [AudienceFilterOperator.Contains, AudienceFilterOperator.DoesNotContain];
const stateOperator: Array<AudienceFilterOperator> = [AudienceFilterOperator.Is, AudienceFilterOperator.IsNot];

export const audienceFiltersResourceTypesToWatch: Array<StoredObjectResourceTypes> = ['customers', 'tags'];

export const getAudienceFilters = () => {
    return Object.keys(CustomerSearchFields)
        .filter((fieldName: keyof typeof CustomerSearchFields) => fieldName !== 'additional')
        .map((fieldName: keyof typeof CustomerSearchFields) => {
            const field = CustomerSearchFields[fieldName];
            let valueType: AudienceFilterValueType;
            let operators: Array<AudienceFilterOperator> = [];
            let options: Array<AudienceFilterOption> = [];
            switch (field.type) {
                case 'text': {
                    valueType = AudienceFilterValueType.Text;
                    operators = textOperators;
                    break;
                }
                case 'boolean': {
                    valueType = AudienceFilterValueType.Boolean;
                    options = [
                        { id: 'true', label: t('general.yes'), value: 'y' },
                        { id: 'false', label: t('general.no'), value: 'n' },
                    ];
                    break;
                }
                case 'select': {
                    valueType = AudienceFilterValueType.Select;
                    operators = stateOperator;
                    options = field.options.map(x => ({ id: x, label: camelAndPascalCaseToTitleCase(x), value: x }));
                    break;
                }
                case 'tag-facet': {
                    valueType = AudienceFilterValueType.Select;
                    operators = stateOperator;
                    options = field.options(Data.all<Tag>('tags').slice()).map(x => ({ id: x, label: camelAndPascalCaseToTitleCase(x), value: x }));
                    break;
                }
                case 'customer-facet': {
                    valueType = AudienceFilterValueType.Select;
                    operators = stateOperator;
                    options = field.options(Data.all<Customer>('customers').slice()).map(x => ({ id: x, label: camelAndPascalCaseToTitleCase(x), value: x }));
                    break;
                }
                default: {
                    Logger.error(`Unknown field type: ${field}`);
                    return;
                }
            }

            const filter: AudienceFilterModel = {
                field: fieldName,
                label: t(`audience-filter.${fieldName}` as TranslationKey),
                valueType,
                operators,
                options,
            };
            return filter;
        }).filter(notNullUndefinedEmptyOrZero);
}

