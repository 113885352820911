import { Box, Button, DialogActions, InputAdornment, Popover, TextField, Typography } from '@mui/material';
import { GradientButton } from '@nexdynamic/nex-ui-react';
import { useEffect, useState } from 'react';
import SwitchItem from './SwitchItem';

type Props = {
    open: boolean;
    onClose: () => void;
    anchorEl: HTMLElement | null;
    showAllWorkers: boolean;
    setShowAllWorkers: (showAllWorkers: boolean) => void;
    showUnboundEvents: boolean;
    setShowUnboundEvents: (showUnboundEvents: boolean) => void;
    showUnrecordedJobs: boolean;
    setShowUnrecordedJobs: (showUnrecordedJobs: boolean) => void;
    defaultDayStart: Date;
    setDefaultDayStart: (defaultDayStart: Date) => void;
    defaultDayEnd: Date;
    setDefaultDayEnd: (defaultDayEnd: Date) => void;
    unrecordedTimeThreshold: number;
    setUnrecordedTimeThreshold: (unrecordedTimeThreshold: number) => void;
};

const SettingsDialog = ({
    open,
    onClose,
    anchorEl,
    showAllWorkers,
    setShowAllWorkers,
    showUnboundEvents,
    setShowUnboundEvents,
    showUnrecordedJobs,
    setShowUnrecordedJobs,
    defaultDayStart,
    setDefaultDayStart,
    defaultDayEnd,
    setDefaultDayEnd,
    unrecordedTimeThreshold,
    setUnrecordedTimeThreshold,
}: Props) => {
    const [cachedSettings, setCachedSettings] = useState<{
        showAllWorkers: boolean;
        showUnboundEvents: boolean;
        showUnrecordedJobs: boolean;
        defaultDayStart: Date;
        defaultDayEnd: Date;
        unrecordedTimeThreshold: number;
    }>({
        showAllWorkers,
        showUnboundEvents,
        showUnrecordedJobs,
        defaultDayStart,
        defaultDayEnd,
        unrecordedTimeThreshold,
    });

    useEffect(() => {
        const settings = localStorage.getItem('time-tracker-settings');
        if (!settings) return;
        const parsedSettings = JSON.parse(settings);
        const defaultDayStart = new Date(parsedSettings.defaultDayStart);
        const defaultDayEnd = new Date(parsedSettings.defaultDayEnd);
        setCachedSettings({ ...parsedSettings, defaultDayStart, defaultDayEnd });
    }, []);

    const handleShowAllWorkers = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCachedSettings(prev => ({ ...prev, showAllWorkers: event.target.checked }));
    };

    const handleShowUnboundEvents = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCachedSettings(prev => ({ ...prev, showUnboundEvents: event.target.checked }));
    };

    const handleShowUnrecordedJobs = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCachedSettings(prev => ({ ...prev, showUnrecordedJobs: event.target.checked }));
    };

    const handleDefaultDayStart = (event: React.ChangeEvent<HTMLInputElement>) => {
        const [hours, mins] = event.target.value.split(':');
        const dayEnd = new Date();
        dayEnd.setHours(Number(hours), Number(mins), 0, 0);
        setCachedSettings(prev => ({ ...prev, defaultDayStart: dayEnd }));
    };

    const handleDefaultDayEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
        const [hours, mins] = event.target.value.split(':');
        const dayEnd = new Date();
        dayEnd.setHours(Number(hours), Number(mins), 0, 0);
        setCachedSettings(prev => ({ ...prev, defaultDayEnd: dayEnd }));
    };

    const handleUnrecordedTimeThreshold = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCachedSettings(prev => ({ ...prev, unrecordedTimeThreshold: Number(event.target.value) }));
    };

    const handleClose = () => {
        setCachedSettings({
            showAllWorkers,
            showUnboundEvents,
            showUnrecordedJobs,
            defaultDayStart,
            defaultDayEnd,
            unrecordedTimeThreshold,
        });
        onClose();
    };

    const handleSave = () => {
        setShowAllWorkers(cachedSettings.showAllWorkers);
        setShowUnboundEvents(cachedSettings.showUnboundEvents);
        setShowUnrecordedJobs(cachedSettings.showUnrecordedJobs);
        setDefaultDayStart(cachedSettings.defaultDayStart);
        setDefaultDayEnd(cachedSettings.defaultDayEnd);
        setUnrecordedTimeThreshold(cachedSettings.unrecordedTimeThreshold);

        localStorage.setItem('time-tracker-settings', JSON.stringify(cachedSettings));
        onClose();
    };

    const isSaveAllowed = !cachedSettings
        ? false
        : cachedSettings.defaultDayStart &&
          cachedSettings.defaultDayEnd &&
          cachedSettings.unrecordedTimeThreshold > -1 &&
          cachedSettings.defaultDayStart.getTime() < cachedSettings.defaultDayEnd.getTime()
        ? true
        : false;

    return (
        <Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
            <Box sx={{ display: 'flex', flexDirection: 'column', background: '#F5F5F5', p: 2, pb: 1 }}>
                <Typography fontWeight={500} marginBottom={2}>
                    Quick Settings
                </Typography>

                <Box sx={{ display: 'flex', width: '100%', gap: 1, mb: 2 }}>
                    <TextField
                        size='small'
                        label='Default Start'
                        type='time'
                        value={cachedSettings.defaultDayStart.toTimeString().slice(0, 5)}
                        onChange={handleDefaultDayStart}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                    />
                    <TextField
                        size='small'
                        label='Default End'
                        type='time'
                        value={cachedSettings.defaultDayEnd.toTimeString().slice(0, 5)}
                        onChange={handleDefaultDayEnd}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                    />
                </Box>
                <TextField
                    size='small'
                    label='Unrecorded Time Threshold'
                    type='number'
                    value={cachedSettings.unrecordedTimeThreshold}
                    placeholder='2'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        endAdornment: <InputAdornment position='end'>hours</InputAdornment>,
                    }}
                    fullWidth
                    sx={{ mb: 2 }}
                    onChange={handleUnrecordedTimeThreshold}
                />

                <SwitchItem
                    checked={cachedSettings.showAllWorkers}
                    onChange={handleShowAllWorkers}
                    color='primary'
                    label='Show all workers'
                    align='right'
                    labelPlacement='start'
                    fullWidth
                />

                <SwitchItem
                    checked={cachedSettings.showUnboundEvents}
                    onChange={handleShowUnboundEvents}
                    color='primary'
                    label='Show unbound events'
                    align='right'
                    labelPlacement='start'
                    fullWidth
                />

                <SwitchItem
                    checked={cachedSettings.showUnrecordedJobs}
                    onChange={handleShowUnrecordedJobs}
                    color='primary'
                    label='Show unrecorded jobs'
                    align='right'
                    labelPlacement='start'
                    fullWidth
                />
            </Box>
            <DialogActions sx={{ background: '#F5F5F5' }}>
                <Button onClick={handleClose} size='small'>
                    Close
                </Button>
                <GradientButton color='primary' onClick={handleSave} size='small' disabled={!isSaveAllowed}>
                    Save
                </GradientButton>
            </DialogActions>
        </Popover>
    );
};

export default SettingsDialog;
