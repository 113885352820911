import { Box } from '@mui/material';
import { FormListItemActions } from '@nexdynamic/nex-ui-react';
import type { AudienceFilter, AudienceFilterModel, AudienceFilterOperator, AudienceFilterOption } from '@nexdynamic/squeegee-common';
import { camelAndPascalCaseToTitleCase } from '@nexdynamic/squeegee-common';
import type { FormEvent } from 'react';
import { useState } from 'react';
import FilterField from '../FilterField';
import FilterOperator from '../FilterOperator';
import FilterValueInput from '../FilterValueInput';

type FilterItemProps = {
    filter: AudienceFilter;
    filters: Array<AudienceFilterModel>;
    onRemove: () => void;
    onChange: (filter: AudienceFilter) => void;
};

const FilterItem = ({ filter, filters, onRemove, onChange }: FilterItemProps) => {
    const [editMode, setEditMode] = useState(false);
    const [operator, setOperator] = useState<AudienceFilterOperator | undefined>(filter.operator);
    const [value, setValue] = useState<string>(filter.value);
    const [activeFilter, setActiveFilter] = useState<AudienceFilterModel | undefined>(filters.find(f => f.field === filter.field));
    const [audienceFilterOptions, setAudienceFilterOptions] = useState<AudienceFilterOption[]>(filter.options ? filter.options : []);
    const [previousFilter] = useState<AudienceFilter>(filter);

    const save = (event: FormEvent) => {
        event.preventDefault();
        setEditMode(false);
        if (activeFilter?.field) {
            const updatedFilter: AudienceFilter = {
                id: filter.id,
                field: activeFilter.field,
                label: camelAndPascalCaseToTitleCase(activeFilter.field),
                value,
                operator,
                options: audienceFilterOptions,
            };
            onChange(updatedFilter);
        }
    };

    const discard = () => {
        setOperator(previousFilter.operator);
        setValue(previousFilter.value);
        setAudienceFilterOptions(previousFilter.options ? previousFilter.options : []);
        setActiveFilter(filters.find(f => f.field === previousFilter.field));

        setEditMode(false);
    };

    const onFilterChange = (filter: AudienceFilterModel) => {
        setActiveFilter(filter);
        setOperator(filter.operators[0]);
        setValue(filter.options.length ? filter.options[0].value : value);
    };

    const handleEdit = () => {
        setEditMode(true);
    };

    return (
        <form onSubmit={save}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                <Box sx={{ flex: 1, minWidth: 140 }}>
                    <FilterField
                        label={editMode ? 'Field' : undefined}
                        disabled={!editMode}
                        filters={filters}
                        value={activeFilter?.field}
                        onChange={onFilterChange}
                    />
                </Box>
                {Boolean(operator) && (
                    <Box sx={{ flex: 1, minWidth: 180 }}>
                        <FilterOperator
                            label={editMode ? 'Operator' : undefined}
                            disabled={!editMode}
                            value={operator}
                            filter={activeFilter}
                            onChange={operator => setOperator(operator)}
                        />
                    </Box>
                )}
                <Box sx={{ flex: 1, minWidth: 180 }}>
                    <FilterValueInput
                        label={editMode ? 'Value' : undefined}
                        disabled={!editMode}
                        filter={activeFilter}
                        value={value}
                        selected={audienceFilterOptions[0]?.id}
                        onChange={v => setValue(v)}
                        onSelected={o => {
                            setAudienceFilterOptions([o]);
                            setValue(o.value);
                        }}
                    />
                </Box>

                <Box sx={{ minWidth: 120, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <FormListItemActions editMode={editMode} onRemove={onRemove} onEdit={handleEdit} onDiscard={discard} />
                </Box>
            </Box>
        </form>
    );
};

export default FilterItem;
