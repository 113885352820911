import { GradientButton } from '@nexdynamic/nex-ui-react';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../../../../hooks/useTranslation';

export const ChangeButton = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <GradientButton
            aria-label={t('general.create')}
            text={t('general.create')}
            variant='contained'
            onClick={() => navigate('/pricing/changes/create')}
        ></GradientButton>
    );
};
