import { Grid, Paper, Typography } from '@mui/material';
import type { BulkPriceChange } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../../../../../../ApplicationState';
import useTranslation from '../../../../../hooks/useTranslation';

export const ChangeDetails = ({ change }: { change: BulkPriceChange }) => {
    const { t } = useTranslation();
    return (
        <Paper sx={{ p: 3, mt: 2 }}>
            <Grid container>
                <Grid item lg={12}>
                    <Typography sx={{ mb: 1.5 }} variant="h6">
                        Change Details
                    </Typography>
                </Grid>
                <Grid item lg={4}>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        <b>Planned Date</b>
                        <br></br>
                        {moment(change.plannedDate).format('ll')}
                    </Typography>
                </Grid>
                {change.type.is === 'percentage' && (
                    <>
                        <Grid item lg={4}>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <b>Type</b>
                                <br></br>
                                Percentage {change.type.value > 0 ? 'Increase' : 'Decrease'}
                            </Typography>
                        </Grid>
                        <Grid item lg={4}>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <b>Amount</b>
                                <br></br>
                                {(change.type.value * 100).toFixed(2)}% {change.type.value > 0 ? 'increase' : 'decrease'} to the current
                                price
                            </Typography>
                        </Grid>
                    </>
                )}
                {change.type.is === 'fixed' && (
                    <>
                        <Grid item lg={4}>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                <b>Type:</b>
                                <br></br>
                                Fixed Price {change.type.value > 0 ? 'increase' : 'decrease'}
                            </Typography>
                        </Grid>
                        <Grid item lg={4}>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {ApplicationState.currencySymbol()}
                                {change.type.value.toFixed(2)} {change.type.value > 0 ? 'Increase' : 'Decrease'} to the current price
                            </Typography>
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid container>
                <Grid item lg={12}>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        <b>{t('price-change.apply-is-not-automatic')}</b>
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};
