import type { Customer } from '@nexdynamic/squeegee-common';
import { searchCustomers } from '@nexdynamic/squeegee-common';
import { useEffect, useState } from 'react';
import useStoredObjects from './useStoredObjects';

type Options = {
    search?: string;
    paging?: { take: number; skip: number };
};

type UseCustomers = (options?: Options) => {
    customers: Customer[];
    count: number;
};

const useCustomers: UseCustomers = (options = {}) => {
    const [filteredCustomers, setFilteredCustomers] = useState<Array<Customer>>([]);
    const [customersToReturn, setCustomersToReturn] = useState<Array<Customer>>([]);
    const [count, setCount] = useState(0);

    const currentCustomers = useStoredObjects<Customer>('customers', { sort: (a, b) => a.name.localeCompare(b.name) });
    const [allCustomers] = useState<Array<Customer>>(currentCustomers);

    useEffect(() => {
        const newFilteredCustomers = !options.search
            ? allCustomers
            : searchCustomers({ customers: allCustomers, searchText: options.search, searchJobs: true });
        setCount(newFilteredCustomers.length);
        setFilteredCustomers(newFilteredCustomers);
    }, [options.search, allCustomers]);

    useEffect(() => {
        if (options?.paging?.take) {
            setCustomersToReturn(filteredCustomers.slice(options.paging.skip, options.paging.skip + options.paging.take));
        } else {
            setCustomersToReturn(filteredCustomers);
        }
    }, [options?.paging?.take, options.paging?.skip, filteredCustomers]);

    return {
        customers: customersToReturn,
        count,
    };
};

export default useCustomers;
