import type { ComponentNames } from '@nexdynamic/squeegee-portal-common';
import { portalComponentSettingsDefaults } from '@nexdynamic/squeegee-portal-common';
import { ApplicationState } from '../../../../ApplicationState';
import { NotifyUserMessage } from '../../../../Notifications/NotifyUserMessage';

export const setPortalComponentSetting = <TSettingType,>(id: ComponentNames, settings: TSettingType) => {
    const defaultSettings = portalComponentSettingsDefaults[id];
    for (const key in defaultSettings) {
        if (settings[key as keyof TSettingType] === undefined) {
            settings[key as keyof TSettingType] = defaultSettings[key as keyof typeof defaultSettings];
        }
    }
    ApplicationState.setSetting(`portal.component-${id}`, settings);
    new NotifyUserMessage('general.saved-successfully');

    return settings;
};
