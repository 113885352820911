import type { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

type AreaChartProps = {
  categories: Array<any>;
  series: ApexAxisChartSeries;
  strokeColor: string;
};

export const BareAreaChart = ({
  categories,
  series,
  strokeColor,
}: AreaChartProps) => {
  const options: ApexOptions = {
    chart: {
      type: "area",
      sparkline: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: 1,
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
    yaxis: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
      labels: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
      fixed: {
        enabled: false,
      },
      x: {
        show: true,
      },
      y: {
        formatter: (value) => {
          return `${value} GBP`;
        },
      },
      marker: {
        show: false,
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        color: strokeColor,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0,
        opacityFrom: 0.6,
        opacityTo: 0,
        stops: [0, 100],
      },
    },
  };

  return (
    <Chart
      type="area"
      options={options}
      series={series}
      height="100%"
      width="100%"
    />
  );
};
