// WorkerTimeEntry.tsx
import ErrorIcon from '@mui/icons-material/Error';
import RunCircleIcon from '@mui/icons-material/RunCircle';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Avatar, Box, ListItemText, Tooltip, Typography } from '@mui/material';
import type { AccountUser, TimeEntry, TimeEntry as TimedEvent } from '@nexdynamic/squeegee-common';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TimerService } from '../../../Tracking/TimerService';
import TrackerContext from '../TrackerContext';
import { getStatsForTimeEntries } from '../utils/StatUtils';
import TimeBlock from './TimeBlock';
import UnboundEvent from './UnboundEvent';
import CreateTimeEntryDialog from './dialogs/CreateTimeEntryDialog';
import EditTimeEntryDialog from './dialogs/EditTimeEntryDialog';
import DeleteTimeEntryAlert from './dialogs/alerts/DeleteTimeEntryAlert';

interface WorkerTimeEntryProps {
    worker: AccountUser;
    timeEntries: TimeEntry[];
    workDayStart: Date;
    workDayEnd: Date;
    refTime: Date;
    onSelected?: (workerId: number | string) => void;
    selected: boolean;
    unboundEvents: TimedEvent[];
    showUnboundEvents: boolean;
    unrecordedTimeThreshold: number;
}

const TimeRow = ({
    timeEntries,
    worker,
    workDayStart,
    workDayEnd,
    refTime,
    selected,
    onSelected,
    unboundEvents,
    showUnboundEvents,
    unrecordedTimeThreshold,
}: WorkerTimeEntryProps) => {
    const [editTimeEntryDialogOpen, setEditTimeEntryDialogOpen] = useState<boolean>(false);
    const [createTimeEntryDialogOpen, setCreateTimeEntryDialogOpen] = useState<boolean>(false);
    const [deleteTimeEntryAlertOpen, setDeleteTimeEntryAlertOpen] = useState<boolean>(false);
    const blockAxisRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState<number>(blockAxisRef.current?.clientWidth || 0);
    const [selectedTimeEntry, setSelectedTimeEntry] = useState<TimeEntry | null>(null);

    const handleCreateEntryDialog = (event: React.MouseEvent) => {
        setDialogAnchorPosition({
            top: event.clientY - 6,
            left: event.clientX + 2,
        });
        setCreateTimeEntryDialogOpen(true);
    };

    const handleEntryDeleted = () => {
        if (selectedTimeEntry === null || selectedTimeEntry === undefined) {
            console.error("Can't delete time entry, no time entry selected. @WorkerTimeEntry.tsx:handleEntryDeleted()");
            return;
        }

        setDeleteTimeEntryAlertOpen(false);
        if (!selectedTimeEntry.start || !selectedTimeEntry.stop) return;
        TimerService.deleteEntry(selectedTimeEntry.start);
        TimerService.deleteEntry(selectedTimeEntry.stop);
    };

    const updateContainerWidth = () => {
        const blockAxisElement = blockAxisRef.current;
        if (blockAxisElement) {
            const width = blockAxisElement.clientWidth;
            if (width !== containerWidth) setContainerWidth(width);
        }
    };

    useEffect(() => {
        let resizeObserver: ResizeObserver | undefined;
        if (typeof ResizeObserver !== 'undefined' && blockAxisRef.current) {
            resizeObserver = new ResizeObserver(() => {
                updateContainerWidth();
            });
            resizeObserver.observe(blockAxisRef.current as Element);
        }
        return () => {
            resizeObserver?.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockAxisRef.current]);

    const workingHours = workDayEnd.getHours() - workDayStart.getHours();

    const [dialogAnchorPosition, setDialogAnchorPosition] = useState<{ top: number; left: number } | null>(null);
    const [thisWorker, setThisWorker] = useState<string | null>(null);

    const unboundEventsForWorker = unboundEvents.filter(event => event.assigneeId === worker._id);
    const areAnyTimersRunning = !!timeEntries.some(entry => entry.type === 'running');
    const { dayStats } = useContext(TrackerContext);

    const statsForSelectedWorker = useMemo(() => {
        if (!dayStats?.timeEntries) return null;
        const filteredTimeEntries = dayStats.timeEntries.filter((te: TimeEntry) => te.assigneeId === worker._id);
        return getStatsForTimeEntries(filteredTimeEntries);
    }, [dayStats, worker._id]);

    return (
        <>
            <Box
                onClick={event => {
                    event.stopPropagation();
                    setThisWorker(worker._id);
                    handleCreateEntryDialog(event);
                }}
                sx={{
                    paddingBottom: '8px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        'position': 'absolute',
                        'left': `0`,
                        'width': `115px`,
                        'height': '50px',
                        'display': 'flex',
                        'alignItems': 'center',
                        'py': '8px',
                        '&:hover': {
                            background: 'rgb(230, 230, 230)',
                        },
                        'cursor': 'pointer',
                        'zIndex': theme => theme.zIndex.drawer - 1,
                        ...(!selected ? {} : { background: '#e6e6e6' }),
                    }}
                    onClick={event => {
                        event.stopPropagation();
                        onSelected?.(worker._id);
                    }}
                >
                    <Avatar sx={{ width: '24px', height: '24px', mx: '8px' }} src={worker.avatar} />
                    <ListItemText
                        primary={<Typography variant="body2">{worker.name}</Typography>}
                        secondary={
                            <Typography variant="body2">
                                {(
                                    timeEntries.reduce((acc, entry) => {
                                        const start = entry.start?.eventTimestamp || new Date().getTime();
                                        const end = entry.stop?.eventTimestamp || refTime.getTime();
                                        const duration = end - start;
                                        return acc + duration;
                                    }, 0) /
                                    1000 /
                                    60 /
                                    60
                                ).toFixed(1) + ' hrs' || '0 hrs'}
                            </Typography>
                        }
                    />
                    <Box sx={{ position: 'absolute', top: '20px', right: '4px' }}>
                        {areAnyTimersRunning ? (
                            <Tooltip title="Timer running">
                                <RunCircleIcon sx={{ color: '#65B769' }} />
                            </Tooltip>
                        ) : unboundEventsForWorker.length > 0 ? (
                            <Tooltip title="Unbound events">
                                <ErrorIcon sx={{ color: '#F44336' }} />
                            </Tooltip>
                        ) : statsForSelectedWorker &&
                          unrecordedTimeThreshold > 0 &&
                          statsForSelectedWorker?.unrecorded > unrecordedTimeThreshold ? (
                            <Tooltip title={`Unrecorded time over threshold of ${unrecordedTimeThreshold} hrs`}>
                                <WarningRoundedIcon sx={{ color: '#FFC107' }} />
                            </Tooltip>
                        ) : (
                            <></>
                        )}
                    </Box>
                </Box>

                <Box
                    ref={blockAxisRef}
                    id={`block-axis-${worker.name}`}
                    sx={{ position: 'relative', height: '40px', width: 'calc(100% - 115px)', ml: 'auto' }}
                >
                    {timeEntries.map((entry, index) => (
                        <>
                            <TimeBlock
                                id={`time-block-${worker._id}-${index}`}
                                setEditDialogAnchorPosition={setDialogAnchorPosition}
                                setEditDialogOpen={setEditTimeEntryDialogOpen}
                                setSelectedTimeEntry={setSelectedTimeEntry}
                                key={`time-block-${worker._id}-${index}`}
                                entry={entry}
                                containerWidth={containerWidth}
                                workingHours={workingHours}
                                workingDayStart={workDayStart}
                                refTime={refTime}
                            />
                        </>
                    ))}
                    {unboundEvents &&
                        showUnboundEvents &&
                        unboundEventsForWorker.map(event => (
                            <UnboundEvent
                                storedEvent={event.stop ? event.stop : event.start}
                                containerWidth={containerWidth}
                                refTime={refTime}
                                workingDayStart={workDayStart}
                                startTime={
                                    event.start?.eventTimestamp
                                        ? new Date(event.start.eventTimestamp)
                                        : event.stop?.eventTimestamp
                                        ? new Date(event.stop.eventTimestamp)
                                        : new Date()
                                }
                                workingHours={workingHours}
                                key={event.occurrenceId}
                            />
                        ))}
                </Box>
            </Box>
            <DeleteTimeEntryAlert
                open={deleteTimeEntryAlertOpen}
                onConfirm={handleEntryDeleted}
                onClose={() => setDeleteTimeEntryAlertOpen(false)}
            />
            {editTimeEntryDialogOpen && selectedTimeEntry && (
                <EditTimeEntryDialog
                    selectedWorkerId={thisWorker || worker._id}
                    anchorPosition={dialogAnchorPosition}
                    selectedTimeEntry={selectedTimeEntry}
                    setOpen={setEditTimeEntryDialogOpen}
                    open={editTimeEntryDialogOpen}
                    onClose={() => {
                        setEditTimeEntryDialogOpen(false);
                    }}
                    setDeleteDialog={setDeleteTimeEntryAlertOpen}
                />
            )}
            {createTimeEntryDialogOpen && (
                <CreateTimeEntryDialog
                    selectedDate={refTime}
                    selectedWorkerId={thisWorker || worker._id}
                    anchorPosition={dialogAnchorPosition || undefined}
                    open={createTimeEntryDialogOpen}
                    onClose={() => setCreateTimeEntryDialogOpen(false)}
                />
            )}
        </>
    );
};

export default TimeRow;
