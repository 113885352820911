import type { useConfirm } from '@nexdynamic/nex-ui-react';
import type { BulkPriceChange, TranslationKey } from '@nexdynamic/squeegee-common';
import type { useNavigate } from 'react-router';
import type useTranslation from '../../../../hooks/useTranslation';

export const editPriceChange = async (
    bulkPriceChange: BulkPriceChange,
    askForConfirmation: ReturnType<typeof useConfirm>['askForConfirmation'],
    navigate: ReturnType<typeof useNavigate>,
    t: ReturnType<typeof useTranslation>['t']
) => {
    if (!bulkPriceChange) return;

    if (bulkPriceChange.notified) {
        const { agreed } = await askForConfirmation({
            title: t('general.continue'),
            description: t('Are you sure you wish to edit, you have already notified customers of this change?' as TranslationKey),
        });
        if (!agreed) return;
    }

    navigate(`/pricing/changes/${bulkPriceChange._id}/edit`);
};
