import { activationStrategy } from 'aurelia-router';
import { Training } from './Training';

export class TrainingModule {
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    training: ReturnType<typeof Training>;

    attached() {
        const container = document.getElementById('training-root');
        if (!container) throw new Error('training-root element not found');
        this.training = Training(container);
    }

    detached() {
        this.training?.unmount();
    }
}
