import DeleteOutline from '@mui/icons-material/DeleteOutline';
import EditOutlined from '@mui/icons-material/EditOutlined';
import type { Audience } from '@nexdynamic/squeegee-common';
import { useNavigate } from 'react-router';
import useTranslation from '../../../../hooks/useTranslation';

import type { NexAction, NexActionHandlerResult } from '@nexdynamic/nex-ui-react';
import { useConfirm, useNexAction } from '@nexdynamic/nex-ui-react';
import useStoredObject from '../../../../hooks/useStoredObject';
import { audienceRemove } from '../AudienceService';

type UseAudienceActions = (id: string) => {
    running: boolean;
    remove: NexAction<() => Promise<NexActionHandlerResult>>;
    edit: NexAction<() => void>;
};

const useAudienceActions: UseAudienceActions = id => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const audience = useStoredObject<Audience>(id);
    const [handleAction, running] = useNexAction();
    const { askForConfirmation } = useConfirm();

    const translations = { name: audience?.name || '' };

    return {
        running,
        remove: {
            run: () =>
                handleAction(
                    {
                        successMsg: t('audiences.removed', translations),
                        errorMsg: t('audiences.remove-failed', translations),
                    },
                    async () => {
                        if (audience?._id) {
                            const { agreed } = await askForConfirmation({
                                title: t('audiences.remove-confirm-title'),
                                description: t('audiences.remove-confirm-description', translations),
                                disagreeLabel: t('general.cancel'),
                                agreeLabel: t('general.remove'),
                            });

                            if (agreed) {
                                await audienceRemove(audience);
                                navigate('/send/audiences');
                                return { success: true };
                            }
                        }
                        return { success: true, notify: false };
                    }
                ),
            name: t('general.remove'),
            icon: <DeleteOutline />,
        },
        edit: {
            run: () => navigate(`/send/audiences/${id}/edit`),
            name: t('general.edit'),
            icon: <EditOutlined />,
        },
    };
};

export default useAudienceActions;
