import type { NexContact, NexContactsProviderApi } from "@nexdynamic/nex-ui-react";
import { ContactFormFields } from "@nexdynamic/nex-ui-react";
import type { Supplier } from "@nexdynamic/squeegee-common";
import { Contact } from "@nexdynamic/squeegee-common";
import { Data } from "../../Data/Data";

const nexContactToContact = (nexContact: NexContact): Contact => {
    const contact: Contact = new Contact(nexContact);
    return contact;
}

const createContact = async (fields: ContactFormFields): Promise<NexContact> => {
    try {
        const nexContact = ContactFormFields.toContact(fields);
        const newContact = nexContactToContact(nexContact);
        await Data.put(newContact);
        return nexContact
    } catch (error) {
        console.error(error);
        throw error;
    }

}

const removeContact = async (contactId: string): Promise<void> => {
    // Remove the contact from the supplier contacts
    const supplier = Data.all<Supplier>('supplier').find(s => s.contactIds?.includes(contactId));
    if (supplier) {
        supplier.contactIds = supplier.contactIds?.filter(id => id !== contactId);
        await Data.put(supplier);
    }

    const contactToDelete = Data.get<Contact>(contactId);
    if (contactToDelete) await Data.delete(contactToDelete);
}

const updateContact = async (contactId: string, fields: ContactFormFields): Promise<void> => {
    try {
        const oldContact = Data.get<Contact>(contactId);
        if (!oldContact) throw new Error(`Contact with id ${contactId} not found`);

        const methods = ContactFormFields.getMethods(fields);

        const updatedContact: Contact = {
            ...oldContact,
            methods,
            firstName: fields.firstName,
            lastName: fields.lastName,
            company: fields.company,
        }
        // Do Validation

        await Data.put(updatedContact);
    } catch (error) {
        console.error(error);
    }
}

const getContact = async (contactId: string): Promise<NexContact | undefined> => {
    try {
        const contact = Data.get<Contact>(contactId);
        // Types should overlap
        return contact;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const getContacts = async (): Promise<NexContact[]> => {
    try {
        const contacts = Data.all<Contact>('contact');
        // Types should overlap
        return [...contacts];
    } catch (error) {
        console.error(error);
        throw error;
    }
}


export const ContactsApi: NexContactsProviderApi = {
    createContact,
    removeContact,
    getContact,
    getContacts,
    updateContact,
}