import { Box, Grid, Paper, Typography } from '@mui/material';
import type { TranslationKey } from '@nexdynamic/squeegee-common';
import Text from '../../../../components/Text';
import useTranslation from '../../../../hooks/useTranslation';
import { ClickedIcon, DeliveredIcon, FailedIcon, OpenedIcon, SentIcon } from '../../../components/icons/DeliveryStatus';

export type DeliveryStats = {
    sent: number;
    delivered: number;
    opened: number;
    clicks: number;
    failed: number;
};

export type DeliveryStatsProps = {
    stats: {
        sms?: DeliveryStats;
        email?: DeliveryStats;
    };
};

type Stat = {
    icon: JSX.Element;
    title: TranslationKey;
    sms?: string;
    smsDescription?: TranslationKey;
    email?: string;
    emailDescription?: TranslationKey;
};

const DeliveryReports = ({ stats }: DeliveryStatsProps) => {
    const { t } = useTranslation();
    const { sms, email } = stats;
    const statsDescriptions: Array<Stat> | undefined = [
        {
            icon: SentIcon,
            title: 'general.sent',
            sms: sms?.sent.toString(),
            smsDescription: sms ? t('campaign.sms-sent-stat-description', { count: sms.sent.toString() }) : undefined,
            email: email?.sent.toString(),
            emailDescription: email ? t('campaign.email-sent-stat-description', { count: email.sent.toString() }) : undefined,
        },
        {
            icon: DeliveredIcon,
            title: 'general.delivered',
            sms: sms?.delivered.toString(),
            smsDescription: sms ? t('campaign.sms-delivered-stat-description', { count: sms.delivered.toString() }) : undefined,
            email: email?.delivered.toString(),
            emailDescription: email ? t('campaign.email-delivered-stat-description', { count: email.delivered.toString() }) : undefined,
        },
        {
            icon: OpenedIcon,
            title: 'Clicks' as TranslationKey,
            sms: sms?.clicks.toString(),
            smsDescription: sms ? t('campaign.sms-opened-stat-description', { count: sms.opened.toString() }) : undefined,
            email: email?.clicks.toString(),
            emailDescription: email ? t('campaign.email-opened-stat-description', { count: email.opened.toString() }) : undefined,
        },
        {
            icon: OpenedIcon,
            title: 'general.opened',
            sms: sms?.opened.toString(),
            smsDescription: sms ? t('campaign.sms-opened-stat-description', { count: sms.opened.toString() }) : undefined,
            email: email?.opened.toString(),
            emailDescription: email ? t('campaign.email-opened-stat-description', { count: email.opened.toString() }) : undefined,
        },
        {
            icon: ClickedIcon,
            title: 'Clicks' as TranslationKey,
            sms: sms?.clicks.toString(),
            smsDescription: sms ? t('campaign.sms-opened-stat-description', { count: sms.opened.toString() }) : undefined,
            email: email?.clicks.toString(),
            emailDescription: email ? t('campaign.email-opened-stat-description', { count: email.opened.toString() }) : undefined,
        },
        {
            icon: FailedIcon,
            title: 'general.failed',
            sms: sms?.failed.toString(),
            smsDescription: sms ? t('campaign.sms-failed-stat-description', { count: sms.failed.toString() }) : undefined,
            email: email?.failed.toString(),
            emailDescription: email ? t('campaign.email-failed-stat-description', { count: email.failed.toString() }) : undefined,
        },
    ];

    return (
        <Grid container sx={{ mt: 2 }} spacing={2}>
            {statsDescriptions?.map((stat, index) => (
                <Grid key={`stat-${index}`} item xs={6} md={4} lg={3}>
                    <Paper>
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                gap: 1,
                            }}
                            p={2}
                        >
                            <Box sx={{ minWidth: 80, p: 1, flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <Text variant="h6" mb={1} text={stat.title} />

                                <Box>{stat.icon}</Box>
                            </Box>
                            <Box sx={{ flex: 1, display: 'flex' }}>
                                {stat.sms && (
                                    <Box sx={{ flex: 1, textAlign: 'center', mr: 1 }}>
                                        <Text variant="subtitle2" text="general.sms" />
                                        <Typography variant="h4" fontWeight="bold" title={stat.smsDescription}>
                                            {stat.sms}
                                        </Typography>
                                    </Box>
                                )}
                                {stat.email && (
                                    <Box sx={{ flex: 1, textAlign: 'center' }}>
                                        <Text variant="subtitle2" text="general.email" />
                                        <Typography variant="h4" fontWeight="bold" title={stat.emailDescription}>
                                            {stat.email}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
};

export default DeliveryReports;
