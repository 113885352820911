import BlockIcon from '@mui/icons-material/Block';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    IconButton,
    InputAdornment,
    TextField,
    ThemeProvider,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import type { AdvertStateTypes, IAdvertisement } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import { ApplicationState } from '../../../../ApplicationState';
import { AdvertisementAssetCache, refreshCheckoutStatus, updateAdvertisement } from '../../services/AdvertisementService';
import { AdvertAssets } from '../AdvertAssets';
import { AdvertTypeChip } from '../AdvertTypeLabel';
import { AttachmentCount } from '../AttachmentCount';
import { Price } from '../Price';
import DeleteAdvertAlertDialog from './alerts/DeleteAdvertAlert';
import PublishAdvertAlertDialog from './alerts/PublishAdvertAlert';
import TransferAssetsAlert from './alerts/TransferAssetsAlert';

type DialogProps = {
    onSaved?: (advert: IAdvertisement) => void;
    onClose: () => void | undefined;
    open: boolean;
    advert: IAdvertisement;
};

const TitleEdit = ({ editTitle, setEditTitle }: { editTitle: string; setEditTitle: React.Dispatch<React.SetStateAction<string>> }) => {
    return <TextField label="Advert Title" value={editTitle} onChange={x => setEditTitle(x.target.value)} margin="dense" fullWidth />;
};

const DescriptionEdit = ({
    editDescription,
    setEditDescription,
}: {
    editDescription: string;
    setEditDescription: React.Dispatch<React.SetStateAction<string>>;
}) => {
    return (
        <TextField
            label="Advert Description"
            value={editDescription}
            multiline
            onChange={x => setEditDescription(x.target.value)}
            margin="normal"
            minRows={4}
            fullWidth
        />
    );
};

export const StateChip = ({ state }: { state: AdvertStateTypes | 'marketplace.adverts-state-direct' }) => {
    if (!state) return null;

    const stateToChipColour: Record<AdvertStateTypes | 'marketplace.adverts-state-direct', string | string[]> = {
        'advert-state.draft': ['#bdbdbd', '#616161'],
        'advert-state.published': ['#49a7da', '#51adde'],
        'advert-state.archived': '#80cbc4',
        'advert-state.deleted': ['#ff7961', '#f44336'],
        'advert-state.purchased': ['#81c784', '#519657'],
        'advert-state.disputed': ['#ffb74d', '#c88719'],
        'advert-state.awaiting-approval': ['#ffb74d', '#c88719'],
        'advert-state.rejected': ['#ff7961', '#f44336'],
        'advert-state.paid': ['#bb45e9', '#e94581'],
        'advert-state.transferred': ['#bb45e9', '#e94581'],
        'advert-state.checkout': ['#bb45e9', '#e94581'],
        'marketplace.adverts-state-direct': ['##32a852', '#3273a8'],
    };

    if (state === 'advert-state.paid') {
        return (
            <Chip
                size="small"
                sx={{
                    background: 'linear-gradient(117deg, rgba(187,69,233,1) 0%, rgba(233,69,129,1) 100%)',
                    color: 'white',
                    borderRadius: 2,
                }}
                label="Paid"
            />
        );
    }
    const colour = stateToChipColour[state];
    const borderColour = Array.isArray(colour) ? colour[0] : colour;
    const textColour = Array.isArray(colour) ? colour[1] : colour;
    return (
        <Chip
            size="small"
            variant="outlined"
            sx={{ borderColor: `${borderColour}`, color: `${textColour}` }}
            label={ApplicationState.localise(state)}
        />
    );
};

const MyAdvertDetails = ({ onSaved, onClose, open, advert }: DialogProps) => {
    const { id, title, description, price, tags, type, state, squeegeeAssets } = advert;

    // Delete dialog open state
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [transferDialogOpen, setTransferDialogOpen] = useState(false);
    const [publishDialogOpen, setPublishDialogOpen] = useState(false);

    // Edit field states
    const [editTitle, setEditTitle] = useState<string>(title);
    const [editDescription, setEditDescription] = useState<string>(description);
    const [editPrice, setEditPrice] = useState<string>(price.toString());

    const onSave = async () => {
        const fieldsToUpdate = { title: editTitle, description: editDescription, price: Number(editPrice) };
        await updateAdvertisement(id, fieldsToUpdate);
        if (onSaved) onSaved({ ...advert, ...fieldsToUpdate });
        onClose();
    };
    const onUnpublish = async () => {
        const fieldsToUpdate: Partial<IAdvertisement> = { state: 'draft' };
        await updateAdvertisement(id, fieldsToUpdate);
        if (onSaved) onSaved({ ...advert, ...fieldsToUpdate });
        onClose();
    };

    const refreshCheckout = () => {
        refreshCheckoutStatus(id).then(response => {
            if (onSaved) onSaved({ ...advert, ...response });
        });
    };

    const onPublish = () => {
        if (onSaved) onSaved({ ...advert, state: 'published' });
        onClose();
    };

    const onDelete = () => {
        if (onSaved) onSaved({ ...advert, state: 'deleted' });
        for (const asset of squeegeeAssets || []) {
            AdvertisementAssetCache.removeItem(asset.id);
        }
        onClose();
    };

    // whats the type for InputBase event listener?
    const handlePriceChange = (e: any) => {
        const regex = /^[0-9.\b]+$/;
        if (e.target.value === '' || regex.test(e.target.value)) setEditPrice(e.target.value);
    };

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                fullScreen={isMobile ? true : false}
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ sx: { width: '37.5rem', height: isMobile ? '100%' : '50rem' } }}
            >
                <DialogContent sx={{ paddingBottom: '0', width: '100%' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Box width="100%">
                            <Box
                                sx={{
                                    display: 'flex',
                                    marginBottom: '0.5rem',
                                }}
                            >
                                <Tooltip title="Delete advert">
                                    <IconButton onClick={() => setDeleteDialogOpen(true)} sx={{ height: '32px', width: '32px' }}>
                                        <DeleteOutlineIcon sx={{ color: '#ff7961' }} />
                                    </IconButton>
                                </Tooltip>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        ml: 'auto',
                                        gap: '0.5rem',
                                    }}
                                >
                                    <StateChip state={`advert-state.${state}`} />
                                </Box>
                            </Box>

                            {state === 'published' || state === 'draft' ? (
                                <>
                                    <TitleEdit editTitle={editTitle} setEditTitle={setEditTitle} />
                                    <DescriptionEdit editDescription={editDescription} setEditDescription={setEditDescription} />
                                </>
                            ) : (
                                <>
                                    <Typography variant="h6" component="div" sx={{ mt: '1rem' }}>
                                        {title}
                                    </Typography>
                                    <Typography sx={{ mb: 1.4, fontSize: '0.9em' }} color="text.secondary">
                                        {description}
                                    </Typography>
                                </>
                            )}

                            {squeegeeAssets && <AdvertAssets squeegeeAssets={squeegeeAssets} onClose={onClose} />}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            paddingX: '1rem',
                            mb: '0.7rem',
                        }}
                    >
                        <Box sx={{ display: 'flex' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    gap: 1,
                                }}
                            >
                                <AttachmentCount count={squeegeeAssets?.length || 0} />
                                <AdvertTypeChip advertType={type} />

                                {tags.map((tag, index) => {
                                    return <Chip key={index} label={tag} />;
                                })}
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'end',
                                    justifyContent: 'flex-end',
                                    marginLeft: 'auto',
                                }}
                            >
                                {state === 'published' || state === 'draft' ? (
                                    <TextField
                                        placeholder="Sale Price"
                                        value={editPrice}
                                        onChange={handlePriceChange}
                                        margin="dense"
                                        size="small"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">{advert.currency}</InputAdornment>,
                                        }}
                                        sx={{ maxWidth: '10rem' }}
                                    ></TextField>
                                ) : (
                                    <Typography variant="h6">
                                        <Price price={advert.price} currency={advert.currency} />{' '}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Divider sx={{ marginY: '0.5rem', borderRadius: '2px' }} />
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            {advert.state === 'published' && (
                                <Tooltip title="Unpublish advert">
                                    <IconButton sx={{ color: '#616161', height: '32px', width: '32px' }} onClick={onUnpublish}>
                                        <BlockIcon />
                                    </IconButton>
                                </Tooltip>
                            )}

                            <Box sx={{ ml: 'auto' }}>
                                <Button sx={{ paddingY: 0.5, color: '#696969' }} onClick={onClose}>
                                    Close
                                </Button>

                                {advert.state === 'checkout' && <Button onClick={() => refreshCheckout()}>Refresh Checkout Status</Button>}

                                {advert.state === 'paid' && advert.squeegeeAssets?.length && (
                                    <Button onClick={() => setTransferDialogOpen(true)}>Transfer Assets</Button>
                                )}

                                {advert.state === 'draft' && <Button onClick={() => setPublishDialogOpen(true)}>Publish Advert</Button>}
                                <Button
                                    sx={{
                                        'width': '100px',
                                        'paddingY': 0.5,
                                        'border': 1,
                                        'borderColor': '#209DE6',
                                        'color': '#209DE6',
                                        '&:hover': {
                                            color: 'white',
                                            background: `#209DE6`,
                                        },
                                    }}
                                    onClick={onSave}
                                >
                                    {state === 'published' ? <>Republish</> : <>Save</>}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>
            {deleteDialogOpen && (
                <DeleteAdvertAlertDialog
                    id={id}
                    dialogOpen={deleteDialogOpen}
                    setDialogOpen={setDeleteDialogOpen}
                    onDelete={onDelete}
                ></DeleteAdvertAlertDialog>
            )}
            {publishDialogOpen && (
                <PublishAdvertAlertDialog
                    id={id}
                    onCancel={() => setPublishDialogOpen(false)}
                    onSuccess={onPublish}
                ></PublishAdvertAlertDialog>
            )}
            {transferDialogOpen && (
                <TransferAssetsAlert id={id} onCancel={() => setPublishDialogOpen(false)} onSuccess={onPublish}></TransferAssetsAlert>
            )}
        </ThemeProvider>
    );
};

export default MyAdvertDetails;
