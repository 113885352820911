import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { Box, CircularProgress, Typography } from '@mui/material';
import { BalanceAccountSelectField, useBalanceDb } from '@nexdynamic/balance-components-react';
import type { LedgerId } from '@nexdynamic/balance-core';
import { EmptyList } from '@nexdynamic/nex-ui-react';
import { Field, Form } from 'formik';
import { TextField } from 'formik-mui';
import * as yup from 'yup';
import { t } from '../../../t';
import { SqueegeeBalance } from '../../balance/SqueegeeBalance';
import useTranslation from '../../hooks/useTranslation';

export const ExpenseCategoryFieldsSchema = () =>
    yup
        .object({
            description: yup.string().required(t('expense-category-form.name-required')),
        })
        .required();

export class ExpenseCategoryFields {
    description = '';
    balanceLedgerId?: LedgerId | null = '' as LedgerId; // default to expenditure
}

const BalanceSelect = () => {
    const { loading, balanceDb } = useBalanceDb();
    if (loading) return <EmptyList text={t('balance.loading-accounts')} icon={<CircularProgress size={24} />} />;
    if (!loading && !balanceDb) return <EmptyList text={t('balance.error-loading-accounts')} icon={<ErrorIcon />} />;

    return (
        <>
            <Typography sx={{ mb: 1 }}>{t('balance.select-balance-account')}</Typography>
            <BalanceAccountSelectField name="balanceLedgerId" onlyChildrenOf="expenditure" />
        </>
    );
};

export const ExpenseCategoryForm = () => {
    const { t } = useTranslation();

    return (
        <Form autoComplete="off">
            <Box sx={{ flex: 1, minWidth: 200 }}>
                <Field
                    sx={{ width: '100%' }}
                    required
                    component={TextField}
                    type="text"
                    label={t('expense-category.name')}
                    name="description"
                />
                <Box sx={{ mt: 2 }}>
                    <SqueegeeBalance hideLoader={true}>
                        <BalanceSelect />
                    </SqueegeeBalance>
                </Box>
            </Box>
        </Form>
    );
};

export default ExpenseCategoryForm;
