import { Box, Input, Slider, Typography } from '@mui/material';
import type { ChangeEvent } from 'react';

export const SliderTimeDifference = ({ value, changed }: { value: number; changed: (value: number) => void }) => {
    const handleValueChange = (_e: Event, newValue: number | number[]) => {
        const newNumberValue = newValue as number;
        if (newNumberValue === value) return;
        changed(newNumberValue);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value === '' ? 0 : Number(e.target.value);
        if (newValue === value) return;
        changed(newValue);
    };

    return (
        <Box sx={{ width: 300 }}>
            <Typography id='time-difference-slider' gutterBottom>
                Maximum Time Allowed Between Device Log In
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
                <Slider value={value} onChange={handleValueChange} marks={true} step={2} min={0} max={30} />

                <Input value={value} margin='dense' onChange={handleInputChange} type='number' />
            </Box>
        </Box>
    );
};
