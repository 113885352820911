import { Box } from '@mui/material';
import type { Stats } from '../utils/StatUtils';
import { formatTime } from '../utils/StatUtils';

const TimeBreakdown = ({
    stats,
}: {
    stats:
        | Stats
        | {
              recorded: number;
              unrecorded: number;
          };
}) => {
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                    sx={{
                        width: '1.5rem',
                        height: '1.5rem',
                        borderRadius: '50%',
                        backgroundColor: '#445F8C',
                        marginRight: '0.5rem',
                    }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Box sx={{ fontWeight: 700 }}>Recorded</Box>
                    <Box sx={{ fontWeight: 300 }}>{formatTime(stats.recorded)}</Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                    sx={{
                        width: '1.5rem',
                        height: '1.5rem',
                        borderRadius: '50%',
                        backgroundColor: '#F66578',
                        marginRight: '0.5rem',
                    }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Box sx={{ fontWeight: 700 }}>Unrecorded</Box>
                    <Box sx={{ fontWeight: 300 }}>{formatTime(stats.unrecorded)}</Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                    sx={{
                        width: '1.5rem',
                        height: '1.5rem',
                        borderRadius: '50%',
                        backgroundColor: '#66B988',
                        marginRight: '0.5rem',
                    }}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Box sx={{ fontWeight: 700 }}>Total</Box>
                    <Box sx={{ fontWeight: 300 }}>{formatTime(stats.recorded + stats.unrecorded)}</Box>
                </Box>
            </Box>
        </>
    );
};

export default TimeBreakdown;
