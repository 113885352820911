import { Button } from '@mui/material';
import type { StandardApiResponse, TranslationKey } from '@nexdynamic/squeegee-common';
import { DialogAnimation } from '../../../Dialogs/DialogAnimation';
import { Api } from '../../../Server/Api';
import { SupportDialog } from '../../../Support/SupportDialog';

interface RequestInfoButtonProps {
    onClose: () => void | undefined;
    advertId: string;
    advertTitle: string;
}

const RequestInfoButton = ({ onClose, advertId, advertTitle }: RequestInfoButtonProps) => {
    const title = 'Request Advert Info' as TranslationKey;
    const subject = `Marketplace Query for "${advertTitle}" (${advertId})`;
    const body = `Hi, I would like more information about the advert "${advertTitle}" (${advertId}).`;
    return (
        <Button
            onClick={async () => {
                onClose();
                const dialog = new SupportDialog(false, subject, body, title);
                await dialog.show(DialogAnimation.SLIDE_UP);

                if (dialog.cancelled) return;

                Api.post<StandardApiResponse>(Api.apiEndpoint, `/api/marketplace/advert/${advertId}/request-info`);
            }}
            variant="text"
            size="small"
        >
            Request Info
        </Button>
    );
};

export default RequestInfoButton;
