import type { BleedingLineChartData, ComparisonChartData, LineChartData } from '../squeegee-viz/types';

const RandomDataGen = (multiple: number) => {
    return Array.from({ length: 15 }, (_, i) => Math.floor(Math.random() * multiple) + i * 10);
};

export const RevenueMonthData: LineChartData = {
    total: RandomDataGen(50).reduce((a, b) => a + b, 0),
    chartData: {
        categories: Array.from({ length: 15 }, (_, i) => `March ${i * 2 + 1}`),
        series: [
            {
                name: 'Revenue',
                data: RandomDataGen(50),
            },
        ],
    },
};
export const RevenueYearData: LineChartData = {
    total: RandomDataGen(240).reduce((a, b) => a + b, 0) * 12,
    chartData: {
        categories: Array.from({ length: 12 }, (_, i) => {
            const month = new Date(2023, i, 1).toLocaleString('default', {
                month: 'short',
            });
            return month;
        }),
        series: [
            {
                name: 'Revenue',
                data: Array.from({ length: 12 }, (_, i) => Math.floor(Math.random() * 1500) + i * 10),
            },
        ],
    },
};

export const ProfitData: BleedingLineChartData = {
    total: RandomDataGen(50).reduce((a, b) => a + b, 0),
    chartData: {
        categories: Array.from({ length: 15 }, (_, i) => `March ${i * 2 + 1}`),
        series: [
            {
                name: 'Profit',
                data: RandomDataGen(50),
            },
        ],
    },
};
export const WorkerRevenueData: ComparisonChartData = {
    monthTotal: RandomDataGen(666).reduce((a, b) => a + b, 0),
    yearTotal: RandomDataGen(50).reduce((a, b) => a + b, 0) * 12,
    chartData: {
        categories: Array.from({ length: 15 }, (_, i) => `March ${i * 2 + 1}`),
        series: [
            {
                name: 'Mark',
                data: RandomDataGen(111),
            },
            {
                name: 'Ed',
                data: RandomDataGen(222),
            },
            {
                name: 'Jack',
                data: RandomDataGen(333),
            },
        ],
    },
};
