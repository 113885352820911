import type { ComponentNames } from '@nexdynamic/squeegee-portal-common';
import type { ReactNode } from 'react';
import { AppointmentHistorySettingsEditor } from './SettingComponentEditors/AppointmentHistorySettingsEditor';
import { BookAppointmentSettingsEditor } from './SettingComponentEditors/BookAppointmentSettingsEditor';
import { EditProfileSettingsEditor } from './SettingComponentEditors/EditProfileSettingsEditor';
import { PortalChatSettingsEditor } from './SettingComponentEditors/PortalChatSettingsEditor';
import { ProfileSettingsEditor } from './SettingComponentEditors/ProfileSettingsEditor';
import { TodaysAppointmentSettingsEditor } from './SettingComponentEditors/TodaysAppointmentSettingsEditor';
import UpcomingAppointmentSettingsEditor from './SettingComponentEditors/UpcomingAppointmentSettingsEditor';

export const settingEditorComponents: Record<ComponentNames, ReactNode | undefined> = {
    'address-search': undefined,
    'all-quotes': undefined,
    'appointment-history': <AppointmentHistorySettingsEditor />,
    'automatic-payments': undefined,
    'book-appointment': <BookAppointmentSettingsEditor />,
    'chat': <PortalChatSettingsEditor />,
    'component-previewer': undefined,
    'edit-profile-button': <EditProfileSettingsEditor />,
    'financial-history': undefined,
    'layout': undefined,
    'my-ratings': undefined,
    'profile': <ProfileSettingsEditor />,
    'quote': undefined,
    'sign-in': undefined,
    'sign-out': undefined,
    'spacer': undefined,
    'stripe-pre-pay': undefined,
    'todays-appointment': <TodaysAppointmentSettingsEditor />,
    'welcome-card': undefined,
    'quote-flow': undefined,
    'upcoming-appointments': <UpcomingAppointmentSettingsEditor />,
} as const;
