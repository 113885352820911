import { AureliaReactComponentDialog } from '../../../../Dialogs/AureliaReactComponentDialog';
import { LoaderEvent } from '../../../../Events/LoaderEvent';
import { Logger } from '../../../../Logger';
import type { PrepaymentOptions, StripePrepayProps } from './StripePrepayOptionsComponent';
import { StripePrepayOptionsComponent } from './StripePrepayOptionsComponent';

export const stripePrepayOptionsDialog = async (currentPrepayOptions: PrepaymentOptions) => {
    try {
        new LoaderEvent(false);
        const aureliaReactComponentDialog = new AureliaReactComponentDialog<PrepaymentOptions, StripePrepayProps>({
            dialogTitle: 'stripe.prepay-options',
            isSecondaryView: true,
            component: StripePrepayOptionsComponent,
            componentProps: {
                prepaymentOptions: currentPrepayOptions,
                okCallback: (options: PrepaymentOptions) => {
                    aureliaReactComponentDialog.ok(options);
                },
                cancelCallback: () => {
                    aureliaReactComponentDialog.cancel();
                },
            },
        });
        return await aureliaReactComponentDialog;
    } catch {
        Logger.error('Error in two factor config dialog');
    }
};
