import { MoreActionMenu } from '@nexdynamic/nex-ui-react';
import type { FC } from 'react';
import useAudienceActions from '../hooks/useAudienceActions';

type Props = {
    audienceId: string;
};

const AudienceActions: FC<Props> = ({ audienceId }) => {
    const { edit, running, remove } = useAudienceActions(audienceId);
    const actions = [edit, remove];

    return <MoreActionMenu label='audience-actions' actions={actions} disabled={running} />;
};

export default AudienceActions;
