// Registered on the portal

import type { DateRangeValue } from '@nexdynamic/nex-ui-react';
import { calculatePercentageChange } from '@nexdynamic/nex-ui-react';
import type { Quote } from '@nexdynamic/squeegee-common';
import { useMemo } from 'react';
import { Data } from '../../../../Data/Data';
import { QuoteService } from '../../../../Quotes/QuoteService';
import { groupTimestamps } from '../portalAnalyticsUtils';
import type { Grouping } from '../usePortalAnalytics';
import type { AllLeads } from './useLeadAnalytics';

export const useAcceptedLeads = (portalLeads: AllLeads, grouping: Grouping, dateRange: DateRangeValue) => {
    const { leads: allLeads, previousLeads: allPreviousLeads } = portalLeads;
    const now = Date.now();

    const { leads, previousLeads } = useMemo(() => {
        const quotes = new Set(
            Data.all<Quote>('quotes', q => q.expiry > now, { state: 'accepted' })
                .filter(q => !QuoteService.quoteIsConverted(q))
                .map(quote => quote.customerId)
        );
        return {
            leads: allLeads.filter(lead => quotes.has(lead._id)),
            previousLeads: allPreviousLeads.filter(lead => quotes.has(lead._id)),
        };
    }, [allLeads]);

    const timestamps = useMemo(() => {
        return leads.map(lead => new Date(lead.createdDate).valueOf());
    }, [leads]);

    const groupedLeads = useMemo(() => {
        return groupTimestamps(grouping, timestamps, dateRange);
    }, [timestamps, grouping]);

    const percentageChange = calculatePercentageChange(previousLeads.length, leads.length);

    return {
        leads,
        groupedLeads,
        percentageChange,
        count: leads.length,
        timestamps,
    };
};
