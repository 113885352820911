import { List, ListItem, ListItemText, Stack, TextField, Typography } from '@mui/material';
import { debounce } from '@nexdynamic/squeegee-common';
import { portalComponentSettingsDefaults } from '@nexdynamic/squeegee-portal-common';
import { useCallback, useState } from 'react';
import { ApplicationState } from '../../../../ApplicationState';
import useTranslation from '../../../hooks/useTranslation';

const UpcomingAppointmentSettingsEditor = () => {
    const settingDefaults = portalComponentSettingsDefaults['upcoming-appointments'];
    type SettingType = typeof settingDefaults;

    const currentSettings = ApplicationState.getSetting<SettingType>('portal.component-upcoming-appointments', settingDefaults);
    const [settings, setSettings] = useState<SettingType>(currentSettings || settingDefaults);

    const onSave = (value: SettingType) => {
        ApplicationState.setSetting('portal.component-upcoming-appointments', value);
        console.log('Saved', value);
    };

    const debouncedSave = useCallback(debounce(onSave, 500), []);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const newSettings = { ...settings, [event.target.name]: Number(value) };
        setSettings(newSettings);
        debouncedSave(newSettings);
    };

    const { t } = useTranslation();
    return (
        <List>
            <ListItem>
                <Stack width="100%">
                    <ListItemText
                        primary={t('upcoming-appointments.appointment-limit-threshold-title')}
                        secondary={t('upcoming-appointments.appointment-limit-threshold-description')}
                    />
                    <TextField
                        placeholder={t('upcoming-appointments.appointment-limit-threshold-title')}
                        fullWidth
                        size="small"
                        type="number"
                        name="appointmentLimit"
                        value={settings.appointmentLimit}
                        onChange={onChange}
                        inputProps={{ min: 0, max: 200 }}
                    />
                    <Typography variant="caption" sx={{ mt: 1 }}>
                        Set to 0 to disable the limit
                    </Typography>
                </Stack>
            </ListItem>
        </List>
    );
};

export default UpcomingAppointmentSettingsEditor;
