import type { IAdvertisementListing } from '@nexdynamic/squeegee-common';

import { useEffect, useState } from 'react';
import { ApplicationState } from '../../../ApplicationState';
import { RethinkDbAuthClient } from '../../../Server/RethinkDbAuthClient';
import { getAdvertisements } from '../services/AdvertisementService';
import type { FilterAdvertsQuery } from '../views/Adverts';

const useAdverts = (query?: FilterAdvertsQuery) => {
    const [adverts, setAdverts] = useState<Array<IAdvertisementListing>>([]);
    const [filteredAdverts, setFilteredAdverts] = useState<Array<IAdvertisementListing>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const sessionKey = RethinkDbAuthClient.getSessionKeyAndValue();
    const maxDistance = ApplicationState.getSetting<number>('global.marketplace-receive-area', 15);

    const requestSearch = (adverts: Array<IAdvertisementListing>, filterAdvertsQuery: FilterAdvertsQuery = {}) => {
        const { selectedSellers, keyword, tags } = filterAdvertsQuery;
        const filteredData = adverts.filter(advert => {
            let include = true;

            if (advert.distance && advert.distance > maxDistance) {
                include = false;
            } else if (selectedSellers?.length && selectedSellers?.includes(advert.sellerName) === false) {
                include = false;
            } else if (tags?.length && advert.tags.some(x => tags.includes(x)) === false) {
                include = false;
            } else if (keyword) {
                const { description, sellerName, tags, title } = advert;

                const keywordMatch =
                    description.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
                    sellerName.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
                    tags.some(t => t.toLowerCase().indexOf(keyword.toLowerCase()) > -1) ||
                    title.toLowerCase().indexOf(keyword.toLowerCase()) > -1;

                include = keywordMatch;
            }
            return include;
        });

        setFilteredAdverts(filteredData.sort((a, b) => (a.distance || 0) - (b.distance || 0)));
    };

    const fetchAdvertisements = async () => {
        if (!sessionKey) {
            return console.error('ERROR SESSION KEY NOT SET');
        }
        setLoading(true);
        const advertsResponse = await getAdvertisements();
        setAdverts(advertsResponse.advertisements || []);
        setLoading(false);
    };

    useEffect(() => {
        fetchAdvertisements();
    }, []);
    useEffect(() => {
        requestSearch(adverts, query);
    }, [query, adverts]);

    return {
        loading,
        adverts: filteredAdverts,
        refetchAdverts: fetchAdvertisements,
    };
};

export default useAdverts;
