import { Grid } from '@mui/material';
import type { BulkPriceChange } from '@nexdynamic/squeegee-common';
import { ChangeAffectedJobs } from '../jobs/ChangeAffectedJobs';
import { ChangeDetails } from './ChangeDetails';
import { ChangeFooter } from './ChangeFooter';
import { ChangeHeader } from './ChangeHeader';
import { ChangeResult } from './ChangeResult';

type PriceChangeOverviewProps = {
    change: BulkPriceChange;
};

export const PriceChangeOverview = ({ change }: PriceChangeOverviewProps) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <ChangeHeader change={change} />
                <ChangeDetails change={change} />
                {!change.result && <ChangeAffectedJobs change={change} />}
                {change.result && <ChangeResult change={change} />}
                <ChangeFooter change={change} />
            </Grid>
        </Grid>
    );
};
