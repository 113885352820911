import { Box, Paper } from '@mui/material';
import type { TimeEntry } from '@nexdynamic/squeegee-common';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import type { Dayjs } from 'dayjs';
import type { Stats } from '../../utils/StatUtils';
import Statistics from '../Statistics';

import { WeekNavigator } from '../../../components/WeekNavigator';
import StackedBarChart from '../../../components/charts/StackedBarChart';
import { useWeekStats } from '../../hooks/useWeekStats';

type TrackerStatsProps = {
    timeEntries: TimeEntry[];
    stats: Stats;
    selectedDate: Dayjs;
    setSelectedDate: (date: Dayjs) => void;
};

const TrackerStats = ({ timeEntries, stats, selectedDate, setSelectedDate }: TrackerStatsProps) => {
    const { barData } = useWeekStats(selectedDate, stats);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                minHeight: '100px',
                height: '300px',
                width: '100%',
                mb: 2,
                gap: '1rem',
            }}
        >
            <Paper elevation={1} sx={{ width: '50%', height: '100%' }}>
                <ParentSize>
                    {({ width, height }) => (
                        <>
                            <WeekNavigator selectedDate={selectedDate} setSelectedDate={setSelectedDate} height={28} width={width} />
                            <StackedBarChart
                                height={height - 8}
                                width={width}
                                selectedDate={selectedDate}
                                setSelectedDate={setSelectedDate}
                                data={barData}
                                defaultWhiteSpace={12}
                                hideYScale={true}
                                columnConfig={{
                                    recordedTime: {
                                        color: '#445F8C',
                                        label: 'Recorded Time',
                                    },
                                    unrecordedTime: {
                                        color: '#F66578',
                                        label: 'Unrecorded Time',
                                    },
                                }}
                            />
                        </>
                    )}
                </ParentSize>
            </Paper>
            <Box width='50%' height='100%'>
                <Statistics
                    timeEntries={timeEntries}
                    recordedTime={stats.recorded || 0}
                    unrecordedTime={stats.unrecorded || 0}
                    billedTime={stats.worked || 0}
                />
            </Box>
        </Box>
    );
};

export default TrackerStats;
