import useId from '@mui/material/utils/useId';
import type { ResponsiveChartContainerProps } from '@mui/x-charts';
import {
    AreaPlot,
    ChartsAxis,
    ChartsAxisHighlight,
    ChartsClipPath,
    ChartsGrid,
    ChartsTooltip,
    LineHighlightPlot,
    LinePlot,
    MarkPlot,
    ResponsiveChartContainer,
} from '@mui/x-charts';
import useTranslation from '../../../../hooks/useTranslation';
import { ClipLineRange } from './ClipLineRange';

// you must pass the `series` and `xAxis` props to the component

const LineChart = (
    chartContainerProps: ResponsiveChartContainerProps & { clipRange?: { from: number | Date | string; to: number | Date | string } }
) => {
    const id = useId();
    const clipPathId = `${id}-clip-path`;
    const noData = chartContainerProps.series.every(series => (series.data as Array<number>).every((data: number) => data === 0));

    const { t } = useTranslation();

    return (
        <ResponsiveChartContainer {...chartContainerProps}>
            {!noData ? (
                <>
                    <ChartsGrid horizontal />
                    <g clipPath={`url(#${clipPathId})`}>
                        <AreaPlot skipAnimation />
                        <ClipLineRange to={chartContainerProps.clipRange?.to}>
                            <LinePlot skipAnimation style={{ strokeLinecap: 'round' }} />
                        </ClipLineRange>
                        <ClipLineRange from={chartContainerProps.clipRange?.from}>
                            <LinePlot style={{ strokeDasharray: '4 6', strokeLinecap: 'round' }} skipAnimation />
                        </ClipLineRange>
                    </g>
                    <ChartsAxis
                        slotProps={{
                            axisLine: { display: 'none' },
                            axisTick: { display: 'none' },
                        }}
                    />
                    <ChartsAxisHighlight x="line" />
                    <MarkPlot />
                    <LineHighlightPlot />
                    <ChartsTooltip />
                    <ChartsClipPath id={clipPathId} />
                </>
            ) : (
                <text style={{ fontSize: '20px', fill: '#666' }}>
                    <tspan x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
                        {t('general.no-data-available')}
                    </tspan>
                </text>
            )}
        </ResponsiveChartContainer>
    );
};

export default LineChart;
