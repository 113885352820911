import { Box, Grid, Paper, Typography } from '@mui/material';
import { DiscardButton, FormStep, GradientButton } from '@nexdynamic/nex-ui-react';
import type { AudienceFilterGroup, AudienceUpdateInput } from '@nexdynamic/squeegee-common';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ApplicationState } from '../../../../../ApplicationState';
import useTranslation from '../../../../hooks/useTranslation';
import AudienceFilters from './filters/AudienceFilters';
export class AudienceFormFields {
    name = '';
    description?: string = '';

    filterGroups: Array<AudienceFilterGroup> = [];

    static toAudienceUpdateInput({ description, name, filterGroups }: Partial<AudienceFormFields>): AudienceUpdateInput {
        return {
            description,
            name,
            filterGroups,
        };
    }
}

type AudienceFormProps = {
    fields?: AudienceFormFields;
    onSubmit: (fields: AudienceFormFields) => Promise<void>;
};

const t = ApplicationState.localise;

const CreateAudienceSchema = () =>
    yup
        .object({
            name: yup.string().required(t('audiences-form.name-required')),
            description: yup.string().optional(),
        })
        .required();

const AudienceForm = ({ fields, onSubmit }: AudienceFormProps) => {
    const initialValues = fields ? fields : new AudienceFormFields();
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={CreateAudienceSchema()}
            onSubmit={values => {
                return onSubmit(values);
            }}
        >
            {({ isSubmitting, values }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Form autoComplete='off'>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                        <Box sx={{ flex: 1, mr: 1 }}>
                                            <Typography variant='h6'>{values.name ? values.name : 'Untitled Audience'}</Typography>
                                        </Box>
                                        <Box>
                                            <DiscardButton sx={{ mr: 1 }} onDiscard={() => navigate(-1)} />
                                            <GradientButton
                                                aria-label={t('general.save')}
                                                text={t('general.save')}
                                                type='submit'
                                                disabled={isSubmitting}
                                                color='success'
                                            ></GradientButton>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={2}>
                                            <FormStep
                                                fields={['name', 'description']}
                                                title={t('audiences-form.details')}
                                                description={t('audiences-form.audience-description')}
                                            >
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                                    <Box sx={{ flex: 1, minWidth: 200 }}>
                                                        <Field
                                                            sx={{ width: '100%' }}
                                                            required
                                                            component={TextField}
                                                            type='text'
                                                            label={t('general.name')}
                                                            name='name'
                                                        />
                                                    </Box>
                                                    <Box sx={{ flex: 1, minWidth: 200 }}>
                                                        <Field
                                                            sx={{ width: '100%' }}
                                                            component={TextField}
                                                            type='text'
                                                            label={t('general.description')}
                                                            name='description'
                                                        />
                                                    </Box>
                                                </Box>
                                            </FormStep>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Form>
                    </Grid>

                    <Grid item xs={12}>
                        <AudienceFilters filters={values?.filterGroups} />
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
};

export default AudienceForm;
