import { Box, Breadcrumbs, FormControlLabel, Grid, Link, MenuItem, Paper, Radio, Typography } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DiscardButton, FormStep, GradientButton } from '@nexdynamic/nex-ui-react';
import type { BulkPriceChange } from '@nexdynamic/squeegee-common';
import { PriceChangeNearestValues } from '@nexdynamic/squeegee-common';
import { Field, Form, Formik } from 'formik';
import { RadioGroup, Select, TextField } from 'formik-mui';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { ApplicationState } from '../../../../../../ApplicationState';
import { t } from '../../../../../../t';
import Text from '../../../../../components/Text';
import { ChangeFields } from './ChangeFields';
import { ChangeFormSchema } from './ChangeFormSchema';

type ChangeFormProps = {
    fields?: ChangeFields;
    onSubmit: (fields: ChangeFields) => Promise<BulkPriceChange>;
};

export const ChangeForm = ({ fields, onSubmit }: ChangeFormProps) => {
    const initialValues = fields ? fields : new ChangeFields();
    const navigate = useNavigate();
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={ChangeFormSchema()}
            onSubmit={async values => {
                const change = await onSubmit(values);
                navigate(`/pricing/changes/${change._id}`);
            }}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Form data-testid='BulkPriceChangeForm' autoComplete='off'>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                        <Box sx={{ flex: 1, mr: 1 }}>
                                            <Breadcrumbs aria-label='breadcrumb'>
                                                <Link
                                                    underline='none'
                                                    component='button'
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={() => navigate('/pricing/changes')}
                                                >
                                                    <Text variant='subtitle1' text='bulk-price-change.list-title' />
                                                </Link>
                                                <Typography variant='subtitle1' color='text.primary'>
                                                    {initialValues.name}
                                                </Typography>
                                            </Breadcrumbs>
                                        </Box>
                                        <Box>
                                            <DiscardButton sx={{ mr: 1 }} onDiscard={() => navigate(-1)} />
                                            <GradientButton
                                                aria-label={t('general.save')}
                                                text={t('general.save')}
                                                type='submit'
                                                disabled={isSubmitting}
                                                variant='contained'
                                            ></GradientButton>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={3}>
                                            <FormStep
                                                fields={['name', 'description']}
                                                title={t('bulk-price-change.form-title')}
                                                description={t('bulk-price-change.form-description')}
                                            >
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                                    <Box sx={{ flex: 1, minWidth: 200 }}>
                                                        <Field
                                                            sx={{ width: '100%' }}
                                                            required
                                                            component={TextField}
                                                            type='text'
                                                            label={t('general.name')}
                                                            name='name'
                                                        />
                                                    </Box>
                                                    <Box sx={{ flex: 1, minWidth: 200 }}>
                                                        <Field
                                                            sx={{ width: '100%' }}
                                                            component={TextField}
                                                            type='text'
                                                            label={t('general.description')}
                                                            name='description'
                                                        />
                                                    </Box>
                                                </Box>
                                            </FormStep>
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={3}>
                                            <FormStep
                                                fields={
                                                    values.type === 'percentage'
                                                        ? ['plannedDate', 'type', 'value', 'nearest']
                                                        : ['plannedDate', 'type', 'value']
                                                }
                                                title={t('bulk-price-change.form-title')}
                                                description={t('bulk-price-change.form-description')}
                                            >
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                                    <Field component={RadioGroup} name='type' sx={{ width: '100%' }}>
                                                        <FormControlLabel
                                                            value='percentage'
                                                            control={<Radio disabled={isSubmitting} />}
                                                            label={t('bulk-price-change.percentage')}
                                                            disabled={isSubmitting}
                                                        />
                                                        <FormControlLabel
                                                            value='fixed'
                                                            control={<Radio disabled={isSubmitting} />}
                                                            label={t('bulk-price-change.fixed')}
                                                            disabled={isSubmitting}
                                                        />
                                                    </Field>

                                                    <Box sx={{ flex: 1, minWidth: 200 }}>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DatePicker
                                                                sx={{ width: '100%' }}
                                                                value={moment(values.plannedDate)}
                                                                onChange={newValue =>
                                                                    setFieldValue('plannedDate', Number(newValue) || moment().valueOf())
                                                                }
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                    <Box sx={{ flex: 1, minWidth: 200 }}>
                                                        <Field
                                                            sx={{ width: '100%' }}
                                                            component={TextField}
                                                            type='number'
                                                            label={t(
                                                                values.type === 'percentage' ? 'general.percentage' : 'general.fixed-value'
                                                            )}
                                                            name='value'
                                                        />
                                                    </Box>
                                                    {values.type === 'percentage' && (
                                                        <Box sx={{ flex: 1, div: { width: '100%' } }}>
                                                            <Field component={Select} name='nearest' label={t('general.nearest')}>
                                                                {PriceChangeNearestValues.map(value => (
                                                                    <MenuItem key={value} value={value}>
                                                                        {ApplicationState.currencySymbol()}
                                                                        {value.toFixed(2)}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </FormStep>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Form>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
};
