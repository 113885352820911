import { Box, Breadcrumbs, Grid, Link } from '@mui/material';
import { PageContainer } from '@nexdynamic/nex-ui-react';
import { useNavigate } from 'react-router';
import Text from '../../components/Text';
import { SupplierForm, type SupplierFormFields } from '../components/SupplierForm';
import useSupplierCreate from '../hooks/useSupplierCreate';

const SupplierAddView = () => {
    const navigate = useNavigate();

    const { createSupplier } = useSupplierCreate();

    const create = async (fields: SupplierFormFields) => {
        const supplier = await createSupplier(fields);
        if (supplier) navigate(`/suppliers/${supplier._id}`);
    };

    return (
        <PageContainer>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link sx={{ cursor: 'pointer' }} component='button' underline='none' onClick={() => navigate('/suppliers/list')}>
                            <Text variant='subtitle1' text='suppliers.title' />
                        </Link>
                        <Text variant='subtitle1' color='text.primary' text='suppliers.add' />
                    </Breadcrumbs>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <SupplierForm onSubmit={create} />
            </Grid>
        </PageContainer>
    );
};

export default SupplierAddView;
