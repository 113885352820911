import type { Palette, PaletteOptions } from '@mui/material';
import { Box, ListItemButton, ListItemText, Popover, Tooltip } from '@mui/material';
import { useState } from 'react';
import GColorPicker, { useColorPicker } from 'react-best-gradient-color-picker';

type Props = {
    textPrimary: string;
    textSecondary: string;
    palette?: Palette | PaletteOptions;
    updatePalette: (color: string, key: string, isBackground: boolean, isGradient?: boolean) => void;
    colorKey: 'paper' | 'default' | 'divider';
    disableGradient?: boolean;
};

const BackgroundPicker = ({ textPrimary, textSecondary, palette, updatePalette, colorKey, disableGradient }: Props) => {
    const { isGradient } = useColorPicker(
        (colorKey === 'paper' ? palette?.background?.paper : palette?.background?.default) || '#fff',
        color => updatePalette(color, colorKey, true)
    );
    const [anchorPos, setAnchorPos] = useState<{ top: number; left: number }>();

    const onCloseColorPicker = (e: MouseEvent) => {
        e.stopPropagation();
        setAnchorPos(undefined);
    };

    const value =
        colorKey === 'divider' ? palette?.divider : colorKey === 'paper' ? palette?.background?.paper : palette?.background?.default;

    return (
        <ListItemButton onClick={e => setAnchorPos({ top: e.clientY, left: e.clientX })} disableRipple disableTouchRipple>
            <ListItemText primary={textPrimary} secondary={textSecondary} />
            <Box sx={{ display: 'flex', gap: 1, ml: 2 }}>
                <Tooltip title={colorKey === 'paper' ? 'Paper' : 'Default'}>
                    <Box
                        sx={{
                            width: 24,
                            height: 24,
                            border: 1,
                            borderRadius: 4,
                            borderColor: palette?.divider || '#E0E0E0',
                            background: value,
                        }}
                    />
                </Tooltip>
            </Box>
            <Popover
                open={!!anchorPos}
                anchorPosition={anchorPos}
                anchorReference="anchorPosition"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                slotProps={{ paper: { sx: { overflow: 'visible' } } }}
                onClose={onCloseColorPicker}
                onClick={e => e.stopPropagation()}
            >
                <Box onClick={e => e.stopPropagation()} sx={{ p: 1 }}>
                    <GColorPicker
                        value={value}
                        onChange={color => updatePalette(color, colorKey, colorKey !== 'divider', isGradient)}
                        hideEyeDrop
                        disableDarkMode
                        hideColorTypeBtns={disableGradient}
                    />
                </Box>
            </Popover>
        </ListItemButton>
    );
};

export default BackgroundPicker;
