import type {
    BlockTemplateCreateInput,
    BlockTemplateUpdateInput,
    Campaign,
    TemplateCreateInput,
    TemplateState,
    TemplateUpdateInput,
} from '@nexdynamic/squeegee-common';
import {
    BlockTemplate,
    BlockTemplateUpdateSchema,
    Template,
    isBlockTemplate,
    isBlockTemplateInput,
    isBlockTemplateUpdateInput,
} from '@nexdynamic/squeegee-common';
import toHTML from 'draftjs-to-html';
import { Data } from '../../../../Data/Data';
import templateStyles from '../../TemplateStyles';

type CreateTemplateInput = BlockTemplateCreateInput | TemplateCreateInput;

export const templateCreate = async <T extends CreateTemplateInput>(
    fields: T
): Promise<T extends BlockTemplateCreateInput ? BlockTemplate : Template> => {
    const isBlockTemplate = isBlockTemplateInput(fields);
    let template: BlockTemplate | Template;

    if (isBlockTemplate) {
        template = new BlockTemplate(fields);
    } else {
        template = {
            ...new Template(),
            ...fields,
        };
        template.html = getHTMLForTemplate(template.state, template.name);
    }

    await Data.put<Template | BlockTemplate>(template);

    return template as T extends BlockTemplateCreateInput ? BlockTemplate : Template;
};

export const templateRemove = async (template: Template | BlockTemplate): Promise<void> => {
    const campaignsToUpdate = Data.all<Campaign>('campaigns', { templateId: template._id }).map(c => {
        c.templateId = undefined;
        return c;
    });
    await Data.put<Campaign>(campaignsToUpdate);
    await Data.delete([template]);
};

type UpdateTemplateInput = BlockTemplateUpdateInput | TemplateUpdateInput;

export const templateUpdate = async <T extends UpdateTemplateInput>(
    id: string,
    fields: T
): Promise<T extends UpdateTemplateInput ? BlockTemplate : Template> => {
    const currentTemplate = Data.get<Template | BlockTemplate>(id);

    if (currentTemplate) {
        const template: BlockTemplate | Template = {
            ...currentTemplate,
            ...fields,
        };

        if (isBlockTemplateUpdateInput(fields)) {
            BlockTemplateUpdateSchema.parse(template);
        } else if (!isBlockTemplate(template)) {
            template.html = getHTMLForTemplate(template.state, template.name);
        }

        await Data.put<Template | BlockTemplate>(template);

        return template as T extends UpdateTemplateInput ? BlockTemplate : Template;
    } else {
        throw new Error('Template not found');
    }
};

const customEntityRender = (entity: { type: string; mutalibity: string }, text: string) => {
    if (entity.type === 'MENTION') {
        return `<span>${text}</span>`;
    }
};

export const getHTMLForTemplate = (state: TemplateState, title: string): string => {
    const content = toHTML(state, undefined, undefined, customEntityRender);

    return `<!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width,initial-scale=1">
        <meta name="x-apple-disable-message-reformatting">
        <title>${title}</title>
        <style>${templateStyles.styles}</style>
    </head>

    <body>
        <div class="content-wrapper">
            <div class="content">
                ${content}
            </div>
        </div>

    <div class="unsubscribe-link">
        <center>
            <small><a href="[unsubscribeUrl]" target="_blank">Unsubscribe</a></small>
        </center>
     <div/>

    </body>
</html>
    `;
};
