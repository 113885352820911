import DeleteOutline from '@mui/icons-material/DeleteOutline';
import EditOutlined from '@mui/icons-material/EditOutlined';
import PublishOutlined from '@mui/icons-material/PublishOutlined';
import type { Campaign } from '@nexdynamic/squeegee-common';
import { CampaignStatus } from '@nexdynamic/squeegee-common';
import { useNavigate } from 'react-router';
import useStoredObject from '../../../../hooks/useStoredObject';
import useTranslation from '../../../../hooks/useTranslation';

import type { NexAction, NexActionHandlerResult } from '@nexdynamic/nex-ui-react';
import { useConfirm, useNexAction } from '@nexdynamic/nex-ui-react';
import { campaignPublish, campaignRemove } from '../CampaignService';

type UseCampaignActions = (id: string) => {
    running: boolean;
    remove: NexAction<() => Promise<NexActionHandlerResult>>;
    publish: NexAction<() => Promise<NexActionHandlerResult>>;
    edit: NexAction<() => void>;
};

const useCampaignActions: UseCampaignActions = id => {
    const navigate = useNavigate();
    const campaign = useStoredObject<Campaign>(id);
    const { askForConfirmation } = useConfirm();

    const [handleAction, running] = useNexAction();

    const { t } = useTranslation();
    const translations = { name: campaign?.name || '' };

    return {
        running,
        remove: {
            run: async () => {
                return await handleAction(
                    {
                        successMsg: t('campaigns.removed', translations),
                        errorMsg: t('campaigns.remove-failed', translations),
                    },
                    async () => {
                        if (campaign) {
                            const { agreed } = await askForConfirmation({
                                disagreeLabel: t('general.cancel'),
                                agreeLabel: t('general.remove'),
                                title: t('campaigns.remove-confirmation-title'),
                                description: t('campaigns.remove-confirmation-description', translations),
                            });
                            if (agreed) {
                                await campaignRemove(campaign);
                                return { success: true };
                            } else {
                                return { success: true, notify: false };
                            }
                        }
                        return { success: false, errorMsg: t('campaigns.campaign-not-found') };
                    }
                );
            },
            name: t('general.remove'),
            icon: <DeleteOutline />,
        },
        publish: {
            run: async () => {
                return await handleAction(
                    { successMsg: t('campaigns.published', translations), errorMsg: t('campaigns.publish-failed', translations) },
                    async () => {
                        if (campaign) {
                            const { agreed } = await askForConfirmation({
                                title: t('campaigns.publish-confirmation-title'),
                                description: t('campaigns.publish-confirmation-description', translations),
                            });
                            if (agreed && campaign?._id) {
                                const { success, errors } = await campaignPublish(campaign);
                                return { success, errorMsg: errors?.[0] };
                            } else {
                                return { success: true, notify: false };
                            }
                        }

                        return { success: false, errorMsg: 'campaigns.campaign-not-found' };
                    }
                );
            },
            name: t('general.publish'),
            icon: <PublishOutlined />,
            disabled: campaign?.status !== CampaignStatus.Draft,
        },
        edit: {
            run: () => navigate(`/send/campaigns/${id}/edit`),
            name: t('general.edit'),
            icon: <EditOutlined />,
            disabled: campaign?.status === CampaignStatus.Published,
        },
    };
};

export default useCampaignActions;
