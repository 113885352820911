import type { Customer, Quote } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import type { QuoteFormFields } from '../Components/quote-form';
import { QuoteActions } from '../QuoteActions';

export class QuoteFormDialog extends CustomDialog<Quote | undefined> {
    constructor(protected customer: Customer, public quote?: Quote) {
        super(
            'quoteFormDialog',
            '../Quotes/Dialogs/QuoteFormDialog.html',
            ApplicationState.localise(quote ? 'quote.edit-quote' : 'quote.new-quote'),
            { cssClass: 'quote-form-dialog no-nav-shadow', isSecondaryView: true, okLabel: '', cancelLabel: '' }
        );
    }

    private inProgress: boolean;
    saveQuote = async (fields: QuoteFormFields) => {
        if (this.inProgress) return;
        this.inProgress = true;
        const { success, error, quote } = await QuoteActions.saveQuoteForm(this.customer, fields, this.quote);

        if (success) return this.ok(quote);

        if (error) new NotifyUserMessage(error);

        this.inProgress = false;
    };
}
