import type { Customer, IFabAction, TranslationKey } from '@nexdynamic/squeegee-common';
import { Quote, notNullUndefinedEmptyOrZero } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import AutomaticPaymentsActions from '../AutomaticPayments/AutomaticPaymentsActions';
import { CustomerService } from '../Customers/CustomerService';
import { Data } from '../Data/Data';
import type { IMenuBarAction } from '../Menus/IMenuBarAction';
import { AdvertisementAssetCache } from '../ReactUI/Marketplace/services/AdvertisementService';
import { QuoteActions } from './QuoteActions';
import { QuoteService } from './QuoteService';

type QuoteTag = { text: string; color: string };
export class QuoteUtils {
    static getTags(quote: Quote): Array<QuoteTag> {
        const tags: Array<QuoteTag> = [
            {
                text: moment(quote.date).format('LL'),
                color: '#486493',
            },
        ];

        const expired = Quote.hasExpired(quote);

        switch (quote.state) {
            case 'pending':
                if (!expired) {
                    tags.push({ text: ApplicationState.localise('quote.state-pending'), color: '#f5b400' });
                } else tags.push({ text: ApplicationState.localise('general.expired'), color: '#da384d' });
                break;
            case 'declined':
                tags.push({ text: ApplicationState.localise('quote.state-declined'), color: '#da384d' });
                break;
            case 'accepted':
                tags.push({ text: ApplicationState.localise('quote.state-accepted'), color: '#66b988' });
                break;
        }

        if (quote.transferred) {
            tags.push({ text: ApplicationState.localise('quote.transferred'), color: '#e94581' });
        }

        if (quote.sentDate) tags.push({ text: ApplicationState.localise('general.sent'), color: '#399bff' });
        else tags.push({ text: ApplicationState.localise('general.not-sent'), color: '#de6b1f' });
        if (quote.firstViewedDate) tags.push({ text: ApplicationState.localise('general.viewed'), color: '#ab47bc' });

        const customer = Data.get<Customer>(quote.customerId);
        for (const quoteJobId of quote.items?.map(x => x.relatedId?.replace('quote:', '')).filter(notNullUndefinedEmptyOrZero) || []) {
            if (quoteJobId && customer?.jobs?.[quoteJobId]) {
                tags.push({
                    text: ApplicationState.localise('quote.state-converted'),
                    color: '#66b988',
                });
                break;
            }
        }

        return tags;
    }

    static getMenuActions(quote: Quote): Array<IMenuBarAction> {
        const items: Array<IMenuBarAction> = [
            {
                tooltip: 'actions.edit-quote',
                handler: () => QuoteActions.edit(quote),
                roles: ['Owner', 'Admin', 'Creator'],
            },
            {
                tooltip: 'actions.delete-quote',
                handler: () => QuoteActions.delete(quote),
                roles: ['Owner', 'Admin', 'Creator'],
                actionType: 'action-delete',
            },
        ];

        if (ApplicationState.features.marketplaceSeller && quote.state !== 'declined' && ApplicationState.isInAnyRole(['Admin', 'Owner'])) {
            items.push({
                tooltip: 'Sell as Lead' as TranslationKey,
                actionType: 'action-sell',
                handler: () => QuoteActions.sellAsLead(quote),
                roles: ['Owner', 'Admin'],
            });
        }

        if (
            ApplicationState.stateFlags.devMode ||
            (ApplicationState.features.transferData && ApplicationState.isInAnyRole(['Owner', 'Admin']) && !quote.transferred)
        ) {
            items.push({
                tooltip: 'Transfer Lead' as TranslationKey,
                actionType: 'action-sell',
                handler: async () => await QuoteActions.transferLead(quote),
                roles: ['Owner', 'Admin'],
            });
        }
        return items;
    }

    static getFabActions = (quote: Quote) => {
        const customer = CustomerService.getCustomer(quote.customerId);
        if (!customer) throw new Error('Customer not found');
        const jobs = QuoteService.getJobs(quote).map(j => customer.jobs[j._id.replace('quote:', '')]);
        if (!jobs) throw new Error('Jobs not found');

        const actions: Array<IFabAction> = [];
        actions.push(
            {
                tooltip: 'quote.preview',
                actionType: 'action-preview',
                handler: () => QuoteActions.preview(quote._id),
                roles: ['Owner', 'Admin', 'Creator'],
            },
            {
                tooltip: 'quote.send',
                actionType: 'action-send',
                handler: () => QuoteActions.send(quote),
                roles: ['Owner', 'Admin', 'Creator'],
            }
        );
        if (QuoteService.quoteIsConverted(quote)) {
            actions.push({
                tooltip: 'quote.send-booking-confirmation',
                actionType: 'action-email',
                handler: () => QuoteActions.promptSendBookingConfirmation(customer._id, jobs),
                roles: ['Owner', 'Admin', 'Creator'],
            });
        }
        actions.push({
            tooltip: 'quote.convert-to-job',
            actionType: 'action-convert-to-job',
            handler: () => QuoteActions.convertToJob(quote),
            roles: ['Owner', 'Admin', 'Creator'],
        });

        if (!customer.automaticPaymentMethod && quote.state === 'accepted') {
            actions.push({
                tooltip: 'actions.send-automatic-payment-invite',
                actionType: 'action-send',
                handler: () => AutomaticPaymentsActions.sendInviteMsg(customer._id),
                roles: ['Owner', 'Admin', 'Creator'],
            });
        }

        if (quote.state === 'pending') {
            actions.push(
                {
                    tooltip: 'quote.mark-accepted',
                    actionType: 'action-done',
                    handler: () => QuoteActions.accept(quote),
                    roles: ['Owner', 'Admin', 'Creator'],
                },
                {
                    tooltip: 'quote.mark-declined',
                    actionType: 'action-cancel',
                    handler: () => QuoteActions.decline(quote),
                    roles: ['Owner', 'Admin', 'Creator'],
                }
            );
        }

        return actions;
    };

    static quoteIsListedOnMarketplace(quote: Quote) {
        return quote.items.some(i => i.relatedId && AdvertisementAssetCache.assetIsListed(i.relatedId));
    }
}
