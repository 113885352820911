import type { AccountUser, Customer, Team, TranslationKey } from '@nexdynamic/squeegee-common';
import { FrequencyType, Job, Service } from '@nexdynamic/squeegee-common';
import { inject } from 'aurelia-framework';
import { ApplicationState } from '../ApplicationState';
import { CustomerService } from '../Customers/CustomerService';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { Prompt } from '../Dialogs/Prompt';
import { JobService } from '../Jobs/JobService';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { AssignmentService } from '../Users/Assignees/AssignmentService';
import { Utilities } from '../Utilities';

@inject(Element)
export class LegacyQuote extends CustomDialog<void> {
    private static _cssClasses = 'details-dialog has-header-content';
    public job: Job;
    protected menuTitle: TranslationKey;

    protected assignees: Array<Team | AccountUser> = [];

    protected failedValidation = false;

    private _cleanQuote: string;

    constructor(private customer: Customer, public quoteType: 'quote' | 'appointment' = 'quote', private jobDate?: string) {
        super('new-quote-for-' + customer._id, '../Quotes/LegacyQuote.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: LegacyQuote._cssClasses,
            isSecondaryView: true,
            destructive: true,
        });

        const frequency = quoteType === 'quote' && ApplicationState.defaultJobFrequency;
        const type = (frequency && frequency.type) || FrequencyType.NoneRecurring;
        const interval = (frequency && frequency.interval) || 0;

        const location = Utilities.copyObject(this.customer.address);
        this.job = new Job(
            this.customer._id,
            undefined,
            undefined,
            this.jobDate,
            location,
            undefined,
            undefined,
            undefined,
            undefined,
            interval,
            type,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            ApplicationState.account.defaultJobDuration,
            undefined,
            undefined
        );
        this.menuTitle =
            quoteType === 'appointment'
                ? ApplicationState.localise('quotes.appointment-for-customer-title', { name: customer.name })
                : ApplicationState.localise('quotes.quote-for-customer-title', { name: customer.name });
        this.setQuoteType(quoteType);
        this._cleanQuote = JSON.stringify(this.job);
    }

    private _switchedServices: Array<Service> = [];
    protected setQuoteType(type?: 'quote' | 'appointment') {
        if (type) this.quoteType = type;

        if (this.quoteType === 'appointment') {
            this._switchedServices = this.job.services;
            this.job.services = [new Service(ApplicationState.localise('quotes.appointment-service-name'))];
        } else {
            this.job.services = this._switchedServices;
        }
    }

    protected delegateSave = () => this.save();
    public async abortCancel() {
        const currentQuote = JSON.stringify(this.job);

        const jobUpdated = this._cleanQuote !== currentQuote;

        if (jobUpdated) await Prompt.saveOrDiscard(this.delegateSave);

        return false;
    }

    public validateCustomer = () => {
        if (CustomerService.validateCustomerAndNotifyUI(this.customer).length) {
            this.failedValidation = true;
            return false;
        }
        this.failedValidation = false;
        return true;
    };

    public validateJob = () => {
        if (JobService.validateJobAndNotifyUI(this.job).length) {
            this.failedValidation = true;
            return false;
        }
        this.failedValidation = false;
        return true;
    };

    protected async save() {
        try {
            if (!JobService.validateJobAndNotifyUI(this.job).length) {
                if (!this.customer.address.addressDescription && this.job.location)
                    this.customer.address = Utilities.copyObject(this.job.location);

                if (this.validateCustomer()) {
                    this.job.isQuote = this.quoteType === 'quote';

                    this.customer.jobs[this.job._id] = this.job;

                    await CustomerService.addOrUpdateCustomer(this.customer);
                    await AssignmentService.assign(
                        this.customer.jobs[this.job._id],
                        this.assignees.map(x => x._id),
                        false,
                        true
                    );
                    this.ok();
                }
            }
        } catch (error) {
            new NotifyUserMessage('notifications.error-during-save');
            return Logger.error('Error in save() on Quote', error);
        }
    }
}
