import SearchIcon from '@mui/icons-material/Search';
import { Box, Fade, Grid, InputAdornment, List, TextField } from '@mui/material';
import { EmptyList, useMultiSelect } from '@nexdynamic/nex-ui-react';
import type { Customer, TranslationKey } from '@nexdynamic/squeegee-common';
import { useMemo, useState } from 'react';
import useTranslation from '../../../../hooks/useTranslation';
import ContactListItem from './ContactListItem';
import ContactsIcon from './ContactsIcon';

type ContactListProps = {
    contacts?: Array<Customer>;
    emptyMessage?: TranslationKey;
    onSearch?: (search: string) => void;
};

const ContactList = ({ contacts, emptyMessage = 'contacts.empty', onSearch }: ContactListProps) => {
    const { select, isSelected } = useMultiSelect();
    const [search, setSearch] = useState<string>('');

    const { t } = useTranslation();

    const handleSearch = (search: string) => {
        setSearch(search);
        onSearch?.(search);
    };

    const view = useMemo(() => {
        if (contacts?.length === 0 && !search.length) {
            return <EmptyList text={emptyMessage} icon={<ContactsIcon />} />;
        }

        if (contacts?.length === 0 && search.length) {
            return <EmptyList text={t('contacts.no-results')} icon={<ContactsIcon />} />;
        }

        return (
            <List dense={true}>
                {contacts?.map(contact => (
                    <ContactListItem selected={isSelected?.(contact)} onSelect={select} contact={contact} key={contact._id} />
                ))}
            </List>
        );
    }, [emptyMessage, contacts, search, isSelected, select]);

    return (
        <Grid container spacing={2}>
            {typeof onSearch !== 'undefined' && (
                <Grid item xs={12}>
                    <Box sx={{ mt: 1 }}>
                        <TextField
                            onChange={event => handleSearch(event.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            size="small"
                            label={t('general.search')}
                            placeholder={t('general.search-placeholder')}
                            variant="outlined"
                        />
                    </Box>
                </Grid>
            )}
            <Grid item xs={12}>
                <Fade in={true} timeout={120}>
                    <div>{view}</div>
                </Fade>
            </Grid>
        </Grid>
    );
};

export default ContactList;
