// Install the necessary dependencies first

import MonacoEditor, { useMonaco } from '@monaco-editor/react';
import { Box, Button, Stack, Typography } from '@mui/material';
import prettier from 'prettier';
import jsonParser from 'prettier/parser-babel';
import type React from 'react';
import { useEffect, useRef } from 'react';

interface JsonEditorProps {
    baseJson: object;
    onClose: () => void;
    onSave: (json: object) => void;
}

const JsonEditor: React.FC<JsonEditorProps> = ({ baseJson, onClose, onSave }) => {
    const monaco = useMonaco();
    const editorRef = useRef<any>(null);

    useEffect(() => {
        if (monaco) {
            monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
                validate: true,
            });

            if (editorRef.current) {
                // eslint-disable-next-line no-bitwise
                editorRef.current.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS, handleSave);
                console.log(monaco.KeyMod.CtrlCmd, monaco.KeyCode.KeyS);
            }
        }
    }, [monaco]);

    const handleEditorDidMount = (editor: any) => {
        editorRef.current = editor;
    };

    const handleSave = () => {
        formatJson();
        if (editorRef.current) {
            const value = editorRef.current.getValue();
            try {
                const json = JSON.parse(value);
                onSave(json);
            } catch (error) {
                console.error('Invalid JSON:', error);
            }
        }
    };

    const formatJson = () => {
        if (editorRef.current) {
            const unformatted = editorRef.current.getValue();
            const formatted = prettier.format(unformatted, {
                parser: 'json',
                plugins: [jsonParser],
                trailingComma: 'all',
            });
            editorRef.current.setValue(formatted);
        }
    };

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1} mb={1}>
                <Typography variant="h6">JSON Editor</Typography>
                <Stack direction="row" gap={1}>
                    <Button color="secondary" onClick={onClose}>
                        Close
                    </Button>
                    <Button color="secondary" onClick={formatJson}>
                        Format
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleSave}>
                        Save
                    </Button>
                </Stack>
            </Stack>
            <MonacoEditor
                theme="vs-dark"
                height="100%"
                defaultLanguage="json"
                defaultValue={JSON.stringify(baseJson, null, 2)}
                onMount={handleEditorDidMount}
                options={{
                    automaticLayout: true,
                    formatOnType: true,
                    formatOnPaste: true,
                }}
            />
        </Box>
    );
};

export default JsonEditor;
