import Label from '@mui/icons-material/LabelOutlined';
import { GradientIcon } from '@nexdynamic/nex-ui-react';

const ExpenseCategoiresIcon = () => {
    return (
        <GradientIcon gradient='primary'>
            <Label />
        </GradientIcon>
    );
};

export default ExpenseCategoiresIcon;
