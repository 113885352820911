import OkIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorIcon from '@mui/icons-material/ErrorRounded';
import WarningIcon from '@mui/icons-material/WarningRounded';
import { LinearProgress, ListItemText, Paper, Stack, Typography } from '@mui/material';
import useTranslation from '../../hooks/useTranslation';
import bytesToMbString from '../utils/bytesToMbString';

const EmailEngineQuotaReport = ({ quota }: { providerName: string; quota: { usage?: number; limit?: number; status?: string } }) => {
    const { t } = useTranslation();
    const quotaPercentage = Number(quota.status?.replace('%', ''));
    return (
        <Paper sx={{ p: 2 }}>
            <Stack direction="row" gap={1}>
                <ListItemText
                    primary={t('email-settings.cloud-storage-quota')}
                    // secondary={`${bytesToMbString(quota.usage || 0)} of ${bytesToMbString(quota.limit || 0)} used (${quotaPercentage}%)`}
                    secondary={t('email-settings.cloud-storage-quota-description', {
                        usage: bytesToMbString(quota.usage || 0),
                        limit: bytesToMbString(quota.limit || 0),
                        percentage: quotaPercentage.toString(),
                    })}
                    sx={{ mt: 0 }}
                />
                {quotaPercentage >= 80 && <WarningIcon sx={{ color: 'warning.main', fontSize: 32, mr: 0.5 }} />}
                {quotaPercentage > 90 && <ErrorIcon sx={{ color: 'error.main', fontSize: 32, mr: 0.5 }} />}
                {quotaPercentage < 80 && <OkIcon sx={{ color: 'success.main', fontSize: 32, mr: 0.5 }} />}
            </Stack>
            <LinearProgress
                variant="determinate"
                value={quotaPercentage}
                color={quotaPercentage > 90 ? 'error' : quotaPercentage > 80 ? 'warning' : 'success'}
            />
            {quotaPercentage > 90 && (
                <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                    {quotaPercentage !== 100
                        ? t('email-settings.cloud-storage-quota-almost-full')
                        : t('email-settings.cloud-storage-quota-full')}
                </Typography>
            )}
        </Paper>
    );
};

export default EmailEngineQuotaReport;
