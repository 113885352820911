import type { IFabAction } from '../Components/Fabs/IFabAction';
import { FabWithActions } from '../FabWithActions';
import { QuoteActions } from './QuoteActions';

export class Quotes {
    protected fabActions: Array<IFabAction> = [
        {
            tooltip: 'quotes.create',
            actionType: 'action-new-quote',
            handler: QuoteActions.create,
            roles: ['Owner', 'Admin', 'Creator'],
        },
    ];

    attached() {
        FabWithActions.register(this.fabActions);
    }

    detached() {
        FabWithActions.unregister();
    }
}
