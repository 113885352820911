import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import type { AudienceFilter, AudienceFilterGroup, AudienceFilterModel } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import AudienceFilterForm from './AudienceFilterForm';
import type { FilterTerm } from './AudienceFilters';
import { FilterGroupItem } from './FilterGroupItem';

type FilterGroupProps = {
    isLast: boolean;
    update: (group: AudienceFilterGroup, filter: AudienceFilter) => void;
    remove: (group: AudienceFilterGroup, filter: AudienceFilter) => void;
    add: (group: AudienceFilterGroup, filter: AudienceFilter, termValue: FilterTerm) => void;
    availableFilters: Array<AudienceFilterModel>;
    filterGroup: AudienceFilterGroup;
    moveFilter: (currentGroup: AudienceFilterGroup, filter: AudienceFilter, term: FilterTerm) => void;
    mergeGroup: (currentGroup: AudienceFilterGroup) => void;
};
export const FilterGroup = ({ availableFilters, filterGroup, update, remove, add, isLast, moveFilter, mergeGroup }: FilterGroupProps) => {
    const [term, setTerm] = useState<FilterTerm>('or');

    const changeTerm = (term: FilterTerm) => {
        if (term === null) return;

        setTerm(term);

        // if this is not that last filter in the group then when i change the term to a and i need to merge this group with the next group
        if (!isLast && term === 'and') {
            mergeGroup(filterGroup);
        }
    };

    return (
        <>
            <Box sx={{ border: 1, borderColor: 'divider', borderRadius: 1, px: 2 }}>
                {filterGroup.map((filter, i) => (
                    <Box sx={{ my: 2 }} key={filter.id}>
                        <FilterGroupItem
                            isLast={i === filterGroup.length - 1}
                            filter={filter}
                            group={filterGroup}
                            update={update}
                            remove={remove}
                            add={add}
                            availableFilters={availableFilters}
                            moveFilter={moveFilter}
                        />
                    </Box>
                ))}
            </Box>

            <ToggleButtonGroup
                    sx={{ my: 2 }}
                value={isLast ? term : 'or'}
                    size='small'
                    color='primary'
                    exclusive
                    onChange={(_event, term) => changeTerm(term)}
                >
                    <ToggleButton value='and'>And</ToggleButton>
                    <ToggleButton value='or'>Or</ToggleButton>
                </ToggleButtonGroup>

            {isLast && (
                <Box sx={{ mt: 2 }}>
                    <AudienceFilterForm
                        onSubmit={filter => add(filterGroup, filter, term)}
                        filters={availableFilters}
                    />
                </Box>
            )}
        </>
    );
};
