import ConvertedIcon from '@mui/icons-material/CurrencyExchange';
import GroupsIcon from '@mui/icons-material/Groups';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import { LineChart } from '@mui/x-charts';
import { DateRange, GradientButton } from '@nexdynamic/nex-ui-react';
import dayjs from 'dayjs';
import moment from 'moment';
import { analyticsPortalLineChartSx } from '../analyticsPortalLineChartSx';
import StatCard from '../components/Overview/StatCard';
const visitorData = [
    {
        date: '2021-01-01',
        value: 100,
    },
    {
        date: '2021-02-01',
        value: 200,
    },
    {
        date: '2021-03-01',
        value: 150,
    },
    {
        date: '2021-04-01',
        value: 250,
    },
    {
        date: '2021-05-01',
        value: 200,
    },
    {
        date: '2021-06-01',
        value: 300,
    },
];

const xAxisLabels = visitorData.map(({ date }) => dayjs(date).format('MMM YYYY'));

const OverviewPlaceholder = () => {
    const theme = useTheme();
    return (
        <Stack spacing={1} pt={2} position="relative">
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 1,
                    backgroundImage: `linear-gradient(to top, ${theme.palette.background.default} 0%,  rgba(242, 242, 242, 0.5) 100%)`,
                    width: '100%',
                    height: '100%',
                }}
            >
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" flex={1} height="100%">
                    <Typography variant="h6" textAlign="center">
                        {`Portal analytics are only available on Ultimate and above.`}
                    </Typography>
                    <GradientButton
                        variant="contained"
                        color="secondary"
                        onClick={() => window.location.assign('/account/subscription')}
                        sx={{ mt: 1 }}
                    >
                        Upgrade Now
                    </GradientButton>
                </Box>
            </Box>
            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="flex-end">
                <Typography variant="h6" sx={{ px: 1 }}>
                    Analytics
                </Typography>
                <DateRange
                    value={{ start: moment().subtract(30, 'days').toDate(), end: moment().toDate() }}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onChange={() => {}}
                    ranges={[
                        {
                            group: '',
                            label: 'Last 7 days',
                            start: moment().subtract(6, 'days').valueOf(),
                            end: moment().valueOf(),
                        },
                    ]}
                    defaultSelect
                    hidePickers
                />
            </Stack>

            <Paper sx={{ flex: 1, overflow: 'hidden' }} variant="outlined">
                <Stack direction="row" bgcolor={theme.palette.background.default}>
                    <StatCard
                        label="Page Views"
                        value={249}
                        icon={<VisibilityIcon sx={{ color: 'text.secondary' }} />}
                        percentageChange={8}
                        button
                        selected
                    />
                    <StatCard
                        label="Leads"
                        value={24}
                        icon={<GroupsIcon sx={{ color: 'text.secondary' }} />}
                        percentageChange={-12}
                        button
                    />
                    <StatCard
                        label="Conversions"
                        value={12}
                        icon={<ConvertedIcon sx={{ color: 'text.secondary' }} />}
                        percentageChange={0}
                        button
                    />
                    <StatCard label="Conversion Rate" value="50%" />
                </Stack>
                <Box sx={{ position: 'relative' }}>
                    <LineChart
                        series={[
                            {
                                id: 'views',
                                data: visitorData.map(({ value }) => value),
                                label: 'Page Views',
                                area: true,
                                color: theme.palette.info.dark,
                                curve: 'linear',
                                showMark: false,
                            },
                        ]}
                        xAxis={[{ scaleType: 'point', data: xAxisLabels }]}
                        height={400}
                        slotProps={{
                            legend: { hidden: true },
                            axisTickLabel: { style: { fill: theme.palette.text.secondary } },
                            axisLine: { style: { display: 'none' } },
                            axisTick: { style: { display: 'none' } },
                        }}
                        grid={{ vertical: false, horizontal: true }}
                        sx={analyticsPortalLineChartSx}
                        skipAnimation
                    />
                </Box>
            </Paper>

            <Typography variant="h6" sx={{ px: 1, pt: 1 }}>
                All Leads
            </Typography>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell align="right" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{moment().format('L')}</TableCell>
                            <TableCell>John Doe</TableCell>
                            <TableCell>john@example.com</TableCell>
                            <TableCell>07415071322</TableCell>
                            <TableCell>Converted</TableCell>
                            <TableCell align="right">
                                <Button variant="contained">View</Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        </Stack>
    );
};

export default OverviewPlaceholder;
