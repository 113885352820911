import { Box, CircularProgress } from '@mui/material';

const ViewLoading = () => {
    return (
        <Box sx={{ flex: 1, alignItems: 'center', display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
        </Box>
    );
};

export default ViewLoading;
