import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import type { Notification } from '@nexdynamic/squeegee-common';

import type { VirtualInfiniteLoaderRef } from '@nexdynamic/nex-ui-react';
import { VirtualInfiniteList } from '@nexdynamic/nex-ui-react';
import React, { useEffect } from 'react';
import MailItem from './MailItem';
import { MAIL_LIST_HEADER_HEIGHT } from './MailListHeader';

type MailListProps = {
    notifications: Array<Notification>;
    loadMore?: () => Promise<void>;
    hasMore: boolean;
    onSelect?: (notification: Notification) => void;
};

const SQUEEGEE_TOOLBAR_HEIGHT = 64;

export const MailList = ({ notifications, onSelect, loadMore, hasMore }: MailListProps) => {
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));
    const listRef = React.useRef<VirtualInfiniteLoaderRef>(null);

    const offset = Math.floor(MAIL_LIST_HEADER_HEIGHT + (isSmallerScreen ? 0 : 32) + SQUEEGEE_TOOLBAR_HEIGHT);
    const containerHeight = `calc(var(--max-view-height) - ${offset}px)`;

    const isItemLoaded = (index: number) => notifications[index] !== undefined;

    const renderItem = (index: number) => {
        if (!isItemLoaded(index)) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
                    <CircularProgress />
                </Box>
            );
        } else {
            const notification = notifications[index];
            return <MailItem id={notification._id} onSelect={onSelect} notification={notification} />;
        }
    };

    const getItemSize = () => {
        return 60;
    };

    const loadMoreItems = async () => {
        if (hasMore && loadMore) await loadMore();
    };

    useEffect(() => {
        if (listRef?.current && notifications.length === 0) {
            listRef.current.resetloadMoreItemsCache();
        }
    }, [notifications]);

    const itemCount = hasMore ? notifications.length + 1 : notifications.length;

    return (
        <Box sx={{ height: containerHeight, position: 'relative', overflowY: 'auto' }}>
            <VirtualInfiniteList
                ref={listRef}
                renderItem={renderItem}
                itemCount={itemCount}
                getItemSize={getItemSize}
                isItemLoaded={isItemLoaded}
                loadMoreItems={loadMoreItems}
            />
        </Box>
    );
};

export default MailList;
