import { useCurrencyCode } from '../hooks/useCurrencyCode';
import { useLocale } from '../hooks/useLocale';

export const Price = ({ price, currency }: { price?: number; currency?: string }) => {
    const locale = useLocale();
    const currencyCode = useCurrencyCode();
    const formattedPrice = price ? price.toLocaleString(locale, { style: 'currency', currency: currency || currencyCode }) : '';

    return <>{formattedPrice}</>;
};
