import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Input, InputLabel, Typography } from '@mui/material';
import { Box } from '@mui/system';
import type { IAdvertisement, ISqueegeeAdvertAsset } from '@nexdynamic/squeegee-common';

import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Logger } from '../../../Logger';

import { AdvertisementAssetCache, createAdvertisement } from '../services/AdvertisementService';

export const SellJobs = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [statusMessage, setStatusMessage] = useState<string | null>(null);

    const [formData, setFormData] = useState({
        title: 'Advert title',
        description: 'New job advert',
        price: 0,
        accountEmail: '',
        complianceAgreement: false,
    });

    const handleSuccess = async (advert: IAdvertisement) => {
        console.log(AdvertisementAssetCache.assetIds.length);
        setIsSubmitted(true);
        navigate(`/marketplace/my-adverts/${advert.id}`);
        const assetsToAdd = advert.squeegeeAssets?.map(x => x.id) || [];

        AdvertisementAssetCache.addItems(assetsToAdd);
        console.log(AdvertisementAssetCache.assetIds.length);
    };

    const handleFailed = (err: Error) => {
        Logger.error(err.message);
        setStatusMessage(`Uh oh there was an error creating an advert for these jobs.`);
    };

    const jobIds = searchParams.get('jobIds');
    const isRoundTransfer = searchParams.get('is-round-transfer');
    const custjobIds = jobIds?.split(',') || [];
    const custJobIdsData = custjobIds.map(x => ({ customerId: x.split(':')[0], jobId: x.split(':')[1] }));
    const attachSqueegeeAssets = custJobIdsData.map(x => ({ customerId: x.customerId, id: x.jobId, type: 'job' } as ISqueegeeAdvertAsset));

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    if (!jobIds) return <div>Job Ids not found</div>;
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        setTimeout(() => {
            setIsSubmitting(false);
            createAdvertisement({
                attachSqueegeeAssets: attachSqueegeeAssets,
                description: formData.description,
                title: formData.title,
                price: formData.price,
                tags: [],
                type: 'squeegee-assets',
                directTransferAccountEmail: formData.accountEmail,
                isRoundTransfer: isRoundTransfer === 'true',
            })
                .then(x => handleSuccess(x.advertisements[0]))
                .catch(handleFailed);
        }, 2000);
        // Send form data to backend for processing
        console.log(formData);
    };

    return (
        <Box padding={3}>
            {isSubmitting ? (
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            ) : isSubmitted ? (
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        height: 'calc(var(--max-view-height) - 64px)',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    textAlign="center"
                >
                    <Typography variant="h5">Application submitted.</Typography>
                    <Button href="/marketplace/home" variant="outlined" color="primary">
                        Okay
                    </Button>
                </Box>
            ) : (
                <form onSubmit={handleSubmit}>
                    <Box marginBottom={2}>
                        <Typography variant="h5">Squeegee Marketplace Sell Jobs Form</Typography>
                    </Box>

                    <Box marginBottom={2}>{attachSqueegeeAssets.length} Jobs will be attached to this advert.</Box>

                    <Box marginBottom={2}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="advertTitle">Advert Title</InputLabel>
                            <Input id="title" name="title" value={formData.title} onChange={handleInputChange} required />
                        </FormControl>
                    </Box>

                    <Box marginBottom={2}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="email">Description</InputLabel>
                            <Input id="description" name="description" value={formData.description} onChange={handleInputChange} required />
                        </FormControl>
                    </Box>
                    <Box marginBottom={2}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="email">Direct Transfer to Account</InputLabel>
                            <Input
                                id="accountEmail"
                                name="accountEmail"
                                type="email"
                                value={formData.accountEmail}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Box>
                    <Box marginBottom={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="complianceAgreement"
                                    name="complianceAgreement"
                                    checked={formData.complianceAgreement}
                                    onChange={handleInputChange}
                                    color="primary"
                                    required
                                />
                            }
                            label="I agree to comply with Squeegee Marketplace policies and guidelines."
                        />
                    </Box>

                    <Box marginBottom={2}>
                        <Button variant="contained" color="primary" type="submit">
                            Submit Advert
                        </Button>
                    </Box>
                    {statusMessage && <Box marginBottom={2}>{statusMessage}</Box>}
                </form>
            )}
        </Box>
    );
};
