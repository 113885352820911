import { FormHelperText, Link, Typography } from '@mui/material';
import { FormStep } from '@nexdynamic/nex-ui-react';
import { CampaignDeliveryType, helpersBulkSmsEnabled } from '@nexdynamic/squeegee-common';
import { ErrorMessage, Field } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
import { t } from 'i18next';
import { presentSmsSettings } from '../../../../../../Account/utils/presentSmsSettings';
import Text from '../../../../../components/Text';
import useApplicationState from '../../../../../hooks/useApplicationState';

type CampaignDeliveryMethodsProps = {
    fieldName: string;
};

const CampaignDeliveryMethods = ({ fieldName }: CampaignDeliveryMethodsProps) => {
    const { account } = useApplicationState();

    const smsEnabled = helpersBulkSmsEnabled(account);

    const showSettings = () => {
        presentSmsSettings();
    };

    return (
        <FormStep
            fields={[fieldName]}
            title={t('campaigns-form.delivery-methods-title')}
            description={t('campaigns-form.delivery-methods-description')}
        >
            <Field
                data-testid='deliveryMethodEmail'
                value={CampaignDeliveryType.Email}
                type='checkbox'
                Label={{ label: 'Email' }}
                name={fieldName}
                component={CheckboxWithLabel}
            />
            <Field
                disabled={!smsEnabled}
                data-testid='deliveryMethodSMS'
                value={CampaignDeliveryType.Sms}
                type='checkbox'
                Label={{ label: 'SMS' }}
                name={fieldName}
                component={CheckboxWithLabel}
            />

            {!smsEnabled && (
                <Typography variant='body2' color='warning.main'>
                    <Link component='button' color='warning.main' onClick={showSettings}>
                        <Text text='campaigns.sms-not-enabled' />
                    </Link>
                </Typography>
            )}
            <FormHelperText error={true}>
                <ErrorMessage name={fieldName} />
            </FormHelperText>
        </FormStep>
    );
};

export default CampaignDeliveryMethods;
