import { Outlet, Route, Routes } from 'react-router';

import { Container, Typography } from '@mui/material';
import {
    AccountTransactionsView,
    BalanceRefreshBanner,
    BalanceSettingsView,
    BalanceSetupCompletedGuard,
    BalanceSetupView,
    ChartOfAccountsView,
    DashboardView,
    FundsAccountView,
    ReportView,
    ReportsOverview,
    Submissions,
    TestHeaders,
    VATReturnObligations,
    VATSetupView,
} from '@nexdynamic/balance-components-react';
import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import ViewLoading from '../components/ViewLoading';

const View = () => {
    return (
        <>
            <BalanceRefreshBanner bannerPosition="bottom" pollInterval={1000} />
            <Outlet />
        </>
    );
};

const BalanceRoutes = () => {
    const navigate = useNavigate();

    return (
        <Suspense fallback={<ViewLoading />}>
            <Routes>
                <Route path="/" element={<View />}>
                    <Route
                        path="/dashboard/*"
                        element={
                            <BalanceSetupCompletedGuard url="/balance/setup">
                                <DashboardView />
                            </BalanceSetupCompletedGuard>
                        }
                    />
                    <Route
                        path="/accounts/*"
                        element={
                            <BalanceSetupCompletedGuard url="/balance/setup">
                                <ChartOfAccountsView />
                            </BalanceSetupCompletedGuard>
                        }
                    ></Route>
                    <Route
                        path="/fund-accounts/*"
                        element={
                            <BalanceSetupCompletedGuard url="/balance/setup">
                                <FundsAccountView />
                            </BalanceSetupCompletedGuard>
                        }
                    ></Route>
                    <Route
                        path="/fund-accounts/:id"
                        element={
                            <BalanceSetupCompletedGuard url="/balance/setup">
                                <FundsAccountView />
                            </BalanceSetupCompletedGuard>
                        }
                    ></Route>
                    <Route
                        path="/reports/*"
                        element={
                            <BalanceSetupCompletedGuard url="/balance/setup">
                                <ReportsOverview />
                            </BalanceSetupCompletedGuard>
                        }
                    />
                    <Route
                        path="/reports/:type"
                        element={
                            <BalanceSetupCompletedGuard url="/balance/setup">
                                <ReportView />
                            </BalanceSetupCompletedGuard>
                        }
                    />
                    <Route
                        path="/submissions/*"
                        element={
                            <BalanceSetupCompletedGuard url="/balance/setup">
                                <Submissions />
                            </BalanceSetupCompletedGuard>
                        }
                    />
                    <Route
                        path="/vat-returns"
                        element={
                            <BalanceSetupCompletedGuard url="/balance/setup">
                                <VATReturnObligations />
                            </BalanceSetupCompletedGuard>
                        }
                    />
                    <Route
                        path="/vat-returns/obligations"
                        element={
                            <BalanceSetupCompletedGuard url="/balance/setup">
                                <VATReturnObligations />
                            </BalanceSetupCompletedGuard>
                        }
                    />
                    <Route
                        path="/vat-returns/setup"
                        element={
                            <BalanceSetupCompletedGuard url="/balance/setup">
                                <VATSetupView />
                            </BalanceSetupCompletedGuard>
                        }
                    />
                    <Route
                        path="/transactions/*"
                        element={
                            <BalanceSetupCompletedGuard url="/balance/setup">
                                <AccountTransactionsView />
                            </BalanceSetupCompletedGuard>
                        }
                    ></Route>
                    <Route
                        path="/settings/*"
                        element={
                            <BalanceSetupCompletedGuard url="/balance/setup">
                                <BalanceSettingsView hideProviders={true} />
                            </BalanceSetupCompletedGuard>
                        }
                    />
                    <Route
                        path="/test-headers/*"
                        element={
                            <BalanceSetupCompletedGuard url="/balance/setup">
                                <Container maxWidth="xl">
                                    <TestHeaders />
                                </Container>
                            </BalanceSetupCompletedGuard>
                        }
                    />
                    <Route path="/setup/*" element={<BalanceSetupView onCompleted={() => navigate('dashboard')} />} />

                    <Route
                        path="*"
                        element={
                            <Typography variant="h6" textAlign="center">
                                Error Page Not Found
                            </Typography>
                        }
                    />
                </Route>
            </Routes>
        </Suspense>
    );
};

export default BalanceRoutes;
