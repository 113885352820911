import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import type { ListItemButtonProps } from '@mui/material';
import { Box, Chip, ListItemButton, ListItemText } from '@mui/material';

type Props = {
    primary: string;
    secondary: string;
    tags?: Array<string>;
    onSelect?: () => void;
    ButtonProps?: ListItemButtonProps;
};

const CannedResponseListItem = ({ primary, secondary, tags, onSelect, ButtonProps }: Props) => {
    return (
        <ListItemButton onClick={onSelect} {...ButtonProps}>
            <ListItemText primary={primary} secondary={secondary} />
            <Box sx={{ display: 'flex', gap: 0.5, mr: 2 }}>
                {tags?.slice(0, 2).map(tag => (
                    <Chip key={tag} label={tag} size='small' />
                ))}
                {tags?.length && tags.length > 2 && <Chip label={`+${tags.length - 2}`} size='small' />}
            </Box>
            <ChevronRightIcon />
        </ListItemButton>
    );
};

export default CannedResponseListItem;
