import type { EmailEngineAccount } from '@nexdynamic/squeegee-common';

export const getMatchingProviderFromAccount = (account: EmailEngineAccount | undefined) => {
    if (!account) return;

    switch (account.type) {
        case 'gmail': {
            return account.oauth2;
        }
        case 'imap': {
            return account.imap;
        }
        default: {
            return;
        }
    }
};
