import MailServerForm from '../../../components/MailServerForm';
import { useMailServer } from '../../../provider/MailServerProvider';

const MailServerStep = ({ protocol, setExternalValues }: { protocol: 'smtp' | 'imap'; setExternalValues?: (values: any) => void }) => {
    const { mailServerSettings, autoConfig } = useMailServer();
    const otherProtocol = protocol === 'smtp' ? 'imap' : 'smtp';
    const initialEmailAddress = mailServerSettings[protocol]?.auth?.user || mailServerSettings[otherProtocol]?.auth?.user || '';
    const initialPassword = mailServerSettings[protocol]?.auth?.pass || mailServerSettings[otherProtocol]?.auth?.pass || '';

    return (
        <MailServerForm
            protocol={protocol}
            initialValues={{
                emailAddress: initialEmailAddress,
                appPassword: initialPassword,
                serverAddress: mailServerSettings[protocol]?.host || '',
                port: mailServerSettings[protocol]?.port || autoConfig?.[protocol]?.port || (protocol === 'smtp' ? 465 : 993),
                useTLS: mailServerSettings[protocol]?.secure || autoConfig?.[protocol]?.tls || true,
            }}
            setExternalValues={setExternalValues}
        />
    );
};

export default MailServerStep;
