import { Box, Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { PageContainer } from '@nexdynamic/nex-ui-react';
import type { Audience } from '@nexdynamic/squeegee-common';
import { useNavigate, useParams } from 'react-router';
import Text from '../../../../components/Text';
import useStoredObject from '../../../../hooks/useStoredObject';
import AudienceDashboard from '../component/AudienceDashboard';

export const AudienceView = () => {
    const { id = '' } = useParams();
    const navigate = useNavigate();
    const audience = useStoredObject<Audience>(id);

    const View = () => {
        if (audience) return <AudienceDashboard audience={audience} />;
        return (
            <Box>
                <Text text='audiences.audience-not-found' />
            </Box>
        );
    };

    return (
        <PageContainer>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flex: 1 }}>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <Link
                                underline='none'
                                component='button'
                                sx={{ cursor: 'pointer' }}
                                onClick={() => navigate('/send/audiences')}
                            >
                                <Text variant='subtitle1' text='audiences.title' />
                            </Link>
                            <Typography data-testid='AudienceViewTitle' variant='subtitle1' color='text.primary'>
                                {audience?.name}
                            </Typography>
                        </Breadcrumbs>
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <View />
            </Grid>
        </PageContainer>
    );
};

export default AudienceView;
