import { GradientButton } from '@nexdynamic/nex-ui-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const CreateCampaignButton = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <GradientButton
            data-testid='CampaignCreateButton'
            text={t('campaigns.create-action')}
            onClick={() => navigate('create')}
            color='secondary'
        ></GradientButton>
    );
};

export default CreateCampaignButton;
