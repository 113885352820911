import type { BlockTemplate, BlockTemplateCreateInput, Template, TemplateCreateInput } from '@nexdynamic/squeegee-common';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import useTranslation from '../../../../hooks/useTranslation';
import { templateCreate } from '../TemplateService';

type CreateTemplateInput = BlockTemplateCreateInput | TemplateCreateInput;

type UseTemplateActions = () => {
    createTemplate: (fields: CreateTemplateInput) => Promise<Template | BlockTemplate | undefined | null>;
    running: boolean;
};

export const useCreateTemplate: UseTemplateActions = () => {
    const [running, setRunning] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const createTemplate = async (fields: TemplateCreateInput) => {
        try {
            setRunning(true);
            const template = await templateCreate(fields);
            if (!template) throw new Error('No template returned');
            enqueueSnackbar(t('templates.created', { name: fields.name }), { variant: 'success' });
            return template;
        } catch (error) {
            if (error) console.error(error);
            enqueueSnackbar(t('templates.createFailed'), { variant: 'error' });
        }
        setRunning(false);
    };

    return {
        running,
        createTemplate,
    };
};
