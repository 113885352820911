import { type SelectChangeEvent } from '@mui/material';
import type { LayoutsByViewmode } from '@nexdynamic/squeegee-common';
import { uuid, type ISession, type StoredLayout } from '@nexdynamic/squeegee-common';
import { AuthStates } from '@nexdynamic/squeegee-portal-common';
import type React from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import type { Layout as LayoutItem } from 'react-grid-layout';
import { ApplicationState } from '../../../ApplicationState';
import { CustomerService } from '../../../Customers/CustomerService';
import { prompt } from '../../../Dialogs/ReactDialogProvider';
import { Logger } from '../../../Logger';
import { NotifyUserMessage } from '../../../Notifications/NotifyUserMessage';
import { Api } from '../../../Server/Api';
import './react-grid-layout/styles.css';
import './react-resizable/styles.css';
import type { ViewAsCustomer } from './types/ViewAsCustomer';
import { debouncedPortalRefresh } from './utils/debouncedPortalRefresh';
import type { OldStoredLayout } from './utils/migrateOldLayouts';
import migrateOldLayouts from './utils/migrateOldLayouts';
import { widgetSizes } from './utils/widgetSizes';

type PortalLayoutContextProps = {
    viewmode: 'xs' | 'sm';
    setViewMode: (viewmode: 'xs' | 'sm') => void;
    selectedLayout: StoredLayout | null;
    layout: Array<LayoutItem>;
    storedLayouts: Array<StoredLayout>;
    viewAsCustomer: ViewAsCustomer;
    preferredAuthMode: 'signed-in' | 'signed-out' | null;
    handleInsertComponent: (componentName: keyof typeof widgetSizes) => void;
    handleRemoveComponent: (componentName: string) => void;
    handleNewLayout: () => void;
    handleSaveLayout: () => Promise<void>;
    handleResetLayout: () => void;
    handleLayoutChange: (event: SelectChangeEvent<string>) => void;
    handleLayoutUpdate: (layout: Array<LayoutItem>) => void;
    handleDeleteLayout: () => void;
    handleViewAsCustomer: (value: ViewAsCustomer) => void;
    setIsDirty: (dirty: boolean) => void;
    shouldRenderWidget: (layoutItem: LayoutItem, viewmode: 'xs' | 'sm') => boolean;
};

const PortalLayoutContext = createContext<PortalLayoutContextProps | undefined>(undefined);

const PortalLayoutProvider = ({ children }: { children: React.ReactNode }) => {
    const [viewmode, setViewMode] = useState<'xs' | 'sm'>('sm');
    const [storedLayouts, setStoredLayouts] = useState<Array<StoredLayout>>([]);
    const [layout, setLayout] = useState<Array<LayoutItem>>([]);
    const [selectedLayout, setSelectedLayout] = useState<StoredLayout | null>(null);

    const [preferredAuthMode, setPreferredAuthMode] = useState<'signed-in' | 'signed-out'>('signed-out');
    const [viewAsCustomer, setViewAsCustomer] = useState<{ id: string; label: string }>({ id: '', label: 'None (signed out)' });

    const [isDirty, setIsDirty] = useState(true);

    const shouldRenderWidget = (layoutItem: LayoutItem, viewmode: 'xs' | 'sm') => {
        if (AuthStates[layoutItem.i as keyof typeof AuthStates] === preferredAuthMode) return true;
        if (AuthStates[layoutItem.i as keyof typeof AuthStates] === 'optional') return true;
        if (layoutItem.i.includes('spacer') && viewmode !== 'xs') return true;

        return false;
    };

    useEffect(() => {
        const savedLayouts: Array<StoredLayout> = ApplicationState.getSetting('global.portal-layouts', []);

        // code to migrate old layouts
        if ((savedLayouts as unknown as Array<OldStoredLayout>).some(l => l.layoutsByViewmode)) {
            const layoutsToMigrate: Array<OldStoredLayout> = (
                savedLayouts as Array<StoredLayout & { layoutsByViewmode: LayoutsByViewmode }>
            ).filter(l => l.layoutsByViewmode) as unknown as Array<OldStoredLayout>;
            const migratedLayouts = migrateOldLayouts(layoutsToMigrate);
            ApplicationState.setSetting('global.portal-layouts', migratedLayouts);
            setStoredLayouts(migratedLayouts);
            return;
        } else {
            setStoredLayouts(savedLayouts);
        }

        if (savedLayouts.length > 0) {
            setSelectedLayout(savedLayouts[0]);
            setLayout(savedLayouts[0].layout);
        }

        // check if we already have a saved customer session
        const session = localStorage.getItem('squeegee-portal-widget:session');
        if (session) {
            const sessionData = JSON.parse(session) as ISession;
            const customerName = CustomerService.getCustomer(sessionData.id);
            setViewAsCustomer({ id: sessionData.id, label: customerName?.name || 'Unknown' });
            setPreferredAuthMode('signed-in');
        } else {
            setPreferredAuthMode('signed-out');
        }
    }, []);

    const handleNewLayout = () => {
        const newLayout = {
            id: uuid(),
            name: `Layout ${storedLayouts.length + 1}`,
            layout: new Array<LayoutItem>(),
        };
        setStoredLayouts([...storedLayouts, newLayout]);
        setSelectedLayout(newLayout);
        setLayout(newLayout.layout);
    };

    const handleLayoutUpdate = (inputLayout: LayoutItem[], handleChangesMade?: (changesMade: boolean, reason?: string) => void) => {
        if (!selectedLayout) return;
        const referenceLayout = layout;
        const modifiedLayouts = new Array<LayoutItem>();

        for (const item of referenceLayout) {
            if (!shouldRenderWidget(item, viewmode)) {
                modifiedLayouts.push(item);
                continue;
            }
            const newItem = inputLayout.find(l => l.i === item.i);
            if (newItem) {
                modifiedLayouts.push(newItem);
            }
        }

        setLayout([...modifiedLayouts]);
        if (handleChangesMade) {
            const referenceLayout = selectedLayout.layout;
            const isDifferent = JSON.stringify(referenceLayout) !== JSON.stringify(layout);
            handleChangesMade(isDifferent, 'layout update');
        }
    };

    const handleLayoutChange = (event: SelectChangeEvent<string>) => {
        const layoutName = event.target.value;
        const storedLayout = storedLayouts.find(l => l.name === layoutName) as StoredLayout;
        setSelectedLayout(storedLayout || null);
        setLayout(storedLayout?.layout);
    };

    const handleSaveLayout = async () => {
        const updatedLayouts: Array<StoredLayout> = selectedLayout
            ? storedLayouts.map(l => (l.name === selectedLayout.name ? { ...selectedLayout, layout } : l))
            : [...storedLayouts, { id: uuid(), name: `Layout ${storedLayouts.length + 1}`, layout }];

        setStoredLayouts(updatedLayouts);
        await ApplicationState.setSetting('global.portal-layouts', updatedLayouts);

        new NotifyUserMessage('layouts.layout-saved-successfully');
        if (!selectedLayout) {
            setSelectedLayout({ id: uuid(), name: `Layout ${storedLayouts.length + 1}`, layout });
        }
    };

    const handleResetLayout = () => {
        setLayout([]);
    };

    const handleDeleteLayout = async () => {
        if (selectedLayout) {
            const warningDialog = prompt('layouts.delete-warning-title', 'layouts.delete-warning');

            await warningDialog.show();
            if (warningDialog.cancelled) return;

            const index = storedLayouts.findIndex(l => l.name === selectedLayout.name);
            if (index === -1) return;

            const updatedLayouts = storedLayouts.filter((_, i) => i !== index);
            ApplicationState.setSetting('global.portal-layouts', updatedLayouts);
            setStoredLayouts(updatedLayouts);
            setSelectedLayout(updatedLayouts[index - 1] || null);
            setLayout(updatedLayouts[index - 1]?.layout || []);
        }
    };

    const handleInsertComponent = (componentName: keyof typeof widgetSizes) => {
        let newLayout = layout;
        const exists = componentName === 'spacer' ? false : layout.some(l => l.i === componentName);
        if (exists) newLayout = layout.filter(l => l.i !== componentName);
        else if (componentName === 'spacer') newLayout.push({ i: 'spacer' + (layout.length + 1), x: 0, y: Infinity, w: 1, h: 2 });
        else
            newLayout.push({
                i: componentName,
                x: 0,
                y: Infinity,
                w: widgetSizes[componentName].minW,
                h: widgetSizes[componentName].minH,
                ...widgetSizes[componentName],
            });

        console.log('new layout', newLayout);
        setLayout(newLayout);
        setIsDirty(true);
    };

    const handleRemoveComponent = (componentName: string) => {
        setLayout(layout.filter(l => l.i !== componentName));
    };

    const handleViewAsCustomer = async (value: ViewAsCustomer) => {
        setViewAsCustomer(value);
        setIsDirty(true);
        if (!value.id) {
            setPreferredAuthMode('signed-out');
            localStorage.removeItem('squeegee-portal-widget:session');
            return;
        }

        // creates a fake session for the customer
        const sessionResponse = await Api.get<{ success: boolean; error?: string; session?: ISession }>(
            Api.apiEndpoint,
            `/api/directory/session/${value.id}`
        );

        const session = sessionResponse?.data?.session;
        setPreferredAuthMode(session ? 'signed-in' : 'signed-out');
        Logger.info('Created session for customer', session);

        if (session) {
            localStorage.setItem('squeegee-portal-widget:session', JSON.stringify(session));
            window.dispatchEvent(new Event('refreshPortal'));
        }
    };

    useEffect(() => {
        if (isDirty) {
            debouncedPortalRefresh();
            setIsDirty(false);
        }
    }, [isDirty]);

    return (
        <PortalLayoutContext.Provider
            value={{
                storedLayouts: storedLayouts,
                layout,
                viewmode,
                setViewMode,
                preferredAuthMode,
                selectedLayout,
                viewAsCustomer,
                handleInsertComponent,
                handleRemoveComponent,
                handleNewLayout,
                handleSaveLayout,
                handleResetLayout,
                handleLayoutUpdate,
                handleLayoutChange,
                handleDeleteLayout,
                handleViewAsCustomer,
                setIsDirty,
                shouldRenderWidget,
            }}
        >
            {children}
        </PortalLayoutContext.Provider>
    );
};

const usePortalLayout = (): PortalLayoutContextProps => {
    const context = useContext(PortalLayoutContext);

    if (!context) {
        throw new Error('usePortalLayout must be used within a PortalLayoutProvider');
    }

    return context;
};

export { PortalLayoutProvider, usePortalLayout };
