import type { Transaction } from '@nexdynamic/squeegee-common';
import { TransactionUtilities, type Customer } from '@nexdynamic/squeegee-common';
import { useMemo } from 'react';
import { Data } from '../../../Data/Data';
import useStoredObjects from '../../hooks/useStoredObjects';
import type { CustomerRiskProfile } from '../customerRisk';
import { calculateCustomerRisk } from '../customerRisk';

export type PendingCustomer = Customer & {
    balance: { amount: number; debtAgeDays: number; overdue: number };
    risk: CustomerRiskProfile;
};

export const usePendingCustomers = (provider?: 'gocardless' | 'stripe') => {
    const customersWithPendingTransactions = useStoredObjects<Transaction>('transactions').filter(
        transaction =>
            transaction.status === 'pending' &&
            !transaction.voidedId &&
            transaction.paymentDetails?.paymentProvider === provider
    );

    const customersWithPendingTransactionIds = customersWithPendingTransactions.map(transaction => transaction.customerId);

    const pendingCustomers = useStoredObjects<Customer>('customers').filter(
        customer =>
            (provider && customer.paymentProviderMetaData?.[provider]?.status === 'pending') ||
            customersWithPendingTransactionIds.includes(customer._id)
    );

    const items = useMemo(() => {
        console.log(pendingCustomers.length, 'pending customers');
        return pendingCustomers.map(customer => {
            const transactionsForCustomer = Data.all<Transaction>('transactions').filter(
                transaction => transaction.customerId === customer._id
            );
            const balance = TransactionUtilities.getBalance(transactionsForCustomer, new Date().toISOString()) as {
                amount: number;
                debtAgeDays: number;
                overdue: number;
                pending: number;
            };

            balance.pending = 0;

            transactionsForCustomer.forEach(transaction => {
                if (transaction.status === 'pending') {
                    balance.pending += transaction.amount;
                }
            });

            if (customer.automaticPaymentMethod !== provider) {
                console.warn('Customer has pending transactions but not using the correct payment provider');
            }

            return {
                ...customer,
                balance,
                pendingTransactionsCount: transactionsForCustomer.filter(transaction => transaction.status === 'pending').length,
                risk: calculateCustomerRisk(customer, transactionsForCustomer),
            };
        });
    }, [pendingCustomers]);

    return items;
};
