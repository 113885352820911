import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Box, ButtonBase, Checkbox, FormControlLabel, Stack, TextField, Typography } from '@mui/material';
import { AttachmentService } from '../../../../../Attachments/AttachmentService';
import { SelectAttachmentsDialog } from '../../../../../Attachments/Dialogs/SelectAttachmentsDialog';
import { DialogAnimation } from '../../../../../Dialogs/DialogAnimation';
import { NotifyUserMessage } from '../../../../../Notifications/NotifyUserMessage';
import type { ListingInformationOptions } from '../../types/ListingInformationSettings';

type ListingInformationProps = {
    listingInformation: ListingInformationOptions;
    handleListingInformationChange: (key: string, value: string | boolean) => void;
};

export const ListingInformationStep = ({ listingInformation, handleListingInformationChange }: ListingInformationProps) => {
    const uploadLogo = async () => {
        const url = await selectUploadAttachment();
        if (url) handleListingInformationChange('logo', url);
    };

    const uploadBanner = async () => {
        const url = await selectUploadAttachment();
        if (url) handleListingInformationChange('coverImage', url);
    };

    const selectUploadAttachment = async (): Promise<string | undefined> => {
        const attachmentDialog = new SelectAttachmentsDialog(undefined, { selectOne: true, isPublic: true });
        const attachment = (await attachmentDialog.show(DialogAnimation.SLIDE))?.[0];
        if (attachmentDialog.cancelled) return;

        if (!attachment.mimeType?.startsWith('image')) {
            new NotifyUserMessage('attachment.must-be-selected');
            return undefined;
        }
        if (!attachment.isPublic) {
            new NotifyUserMessage('attachment.must-be-public');
            return undefined;
        }

        return AttachmentService.getPublicUrl(attachment);
    };

    return (
        <Box display="flex" flexDirection="column" gap={2} mb={2}>
            <Stack spacing={1}>
                <Typography variant="h5">Listing Information</Typography>
                <Typography variant="body1" gutterBottom>
                    {`Please provide the following information to create your business listing. This information will be displayed
                            on your portal for customers to view.`}
                </Typography>
            </Stack>
            <Stack spacing={1} direction="row">
                <ButtonBase sx={{ width: '50%', borderRadius: 1, p: 2 }} onClick={uploadLogo}>
                    <Stack alignItems="center">
                        {listingInformation.logo ? (
                            <img src={listingInformation.logo} alt="Logo" style={{ maxHeight: 50 }} />
                        ) : (
                            <AddPhotoAlternateIcon />
                        )}
                        <Typography sx={{ mt: 1 }}>{listingInformation.logo ? 'Change' : 'Add'} Logo</Typography>
                    </Stack>
                </ButtonBase>
                <ButtonBase sx={{ width: '50%', borderRadius: 1, p: 2 }} onClick={uploadBanner}>
                    <Stack alignItems="center">
                        {listingInformation.coverImage ? (
                            <img src={listingInformation.coverImage} alt="Cover" style={{ maxHeight: 50 }} />
                        ) : (
                            <AddPhotoAlternateIcon />
                        )}
                        <Typography sx={{ mt: 1 }}>{listingInformation.coverImage ? 'Change' : 'Add'} Cover Image</Typography>
                    </Stack>
                </ButtonBase>
            </Stack>
            <TextField
                label="Business Name"
                fullWidth
                value={listingInformation.businessName}
                onChange={e => handleListingInformationChange('businessName', e.target.value)}
            />
            <TextField
                label="Business Description"
                fullWidth
                multiline
                rows={4}
                value={listingInformation.businessDescription}
                onChange={e => handleListingInformationChange('businessDescription', e.target.value)}
            />
            <Stack direction="row" spacing={1}>
                <TextField
                    label="Contact Number"
                    fullWidth
                    value={listingInformation.contactNumber}
                    onChange={e => handleListingInformationChange('contactNumber', e.target.value)}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={listingInformation.showContactNumber}
                            onChange={e => handleListingInformationChange('showContactNumber', e.target.checked)}
                        />
                    }
                    label="Show"
                />
            </Stack>
            <Stack direction="row" spacing={1}>
                <TextField
                    label="Email Address"
                    fullWidth
                    value={listingInformation.emailAddress}
                    onChange={e => handleListingInformationChange('emailAddress', e.target.value)}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={listingInformation.showEmailAddress}
                            onChange={e => handleListingInformationChange('showEmailAddress', e.target.checked)}
                        />
                    }
                    label="Show"
                />
            </Stack>
            <Stack direction="row" spacing={1}>
                <TextField
                    label="Business Address"
                    fullWidth
                    value={listingInformation.businessAddress}
                    onChange={e => handleListingInformationChange('businessAddress', e.target.value)}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={listingInformation.showBusinessAddress}
                            onChange={e => handleListingInformationChange('showBusinessAddress', e.target.checked)}
                        />
                    }
                    label="Show"
                />
            </Stack>
        </Box>
    );
};
