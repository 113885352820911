import SearchIcon from '@mui/icons-material/Search';
import { Container, InputAdornment, TextField } from '@mui/material';
import { useDebounce } from '@nexdynamic/nex-ui-react';
import * as marked from 'marked';
import { useEffect, useState } from 'react';
import { TrainingAccordion } from '../components/TrainingAccordion';
import { SearchLectures } from '../funcs/SearchLectures';
import { videoOptionDetails } from '../videoOptionDetails';

type VideoDetails = {
    videoTitle: string;
    videoContent: string;
    videoSource: string;
};

export type GraphQLLecture = {
    title: string;
    description: string;
    content: string;
    videoSrc?: string;
    slug: string;
    lesson?: {
        title: string;
        slug: string;
        cover: {
            url: string;
        };
    };
};

export type Lecture = {
    title: string;
    description: string;
    content: string;
    lesson: string;
    url: string;
    imageUrl: string;
    videoId?: string;
};

export const TrainingView = () => {
    const [currentLecture, setCurrentLecture] = useState<Lecture | null>(null);
    const [videoDetails, setVideoDetails] = useState<VideoDetails | null>(null);
    const [search, setSearchText] = useState<string>('');
    const searchText = useDebounce(search, 400);

    const handleOnClick = (lecture: Lecture) => {
        setCurrentLecture(lecture);
    };

    useEffect(() => {
        if (!currentLecture) return;
        const videoTitle = currentLecture.title;
        const videoContent = marked.marked(currentLecture.content, { breaks: true });
        const videoSource = `${videoOptionDetails.videoUrl}${currentLecture.videoId}${videoOptionDetails.videoOptions}`;

        setVideoDetails({ videoTitle, videoContent, videoSource });

        if (videoDetails) {
            viewLecture();
        }
    }, [currentLecture]);

    const viewLecture = () => {
        const container = document.getElementById('video-container');
        if (container) {
            container.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const clearCurrentVideo = () => {
        setVideoDetails(null);
        setCurrentLecture(null);
    };

    const handleSearch = (text: string) => {
        clearCurrentVideo();
        setSearchText(text);
    };

    return (
        <Container maxWidth="xl">
            {currentLecture && videoDetails ? (
                <Container
                    maxWidth="xl"
                    id="video-container"
                    sx={{ width: '100%', height: '35vh', overflow: 'hidden', position: 'relative' }}
                >
                    <iframe
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        title="videoTitle"
                        src={videoDetails?.videoSource}
                        allowFullScreen={true}
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', border: 'none' }}
                    />
                </Container>
            ) : null}
            <Container maxWidth="xl">
                <TextField
                    onChange={e => handleSearch(e.target.value)}
                    variant="outlined"
                    placeholder="Search..."
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        style: {
                            borderRadius: '25px',
                            backgroundColor: '#f0f0f0',
                            paddingLeft: '5px',
                            paddingRight: '5px',
                        },
                    }}
                    sx={{
                        my: '10px',
                        width: '100%',
                    }}
                />
            </Container>
            {searchText ? (
                <SearchLectures clickHandler={handleOnClick} searchText={searchText} />
            ) : (
                <TrainingAccordion clickHandler={handleOnClick} />
            )}
        </Container>
    );
};
