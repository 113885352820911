import { AureliaReactComponentDialog } from "../../../Dialogs/AureliaReactComponentDialog";
import type { ScheduleDetailsRatingsDialogProps } from "./ScheduleDetailsRatingsDialogComponent";
import ScheduleDetailsRatingsDialogComponent from "./ScheduleDetailsRatingsDialogComponent";

export class ScheduleDetailsRatingsDialog extends AureliaReactComponentDialog<undefined, ScheduleDetailsRatingsDialogProps> {
    constructor(props: ScheduleDetailsRatingsDialogProps) {
        super({
            dialogTitle: 'directory.customer-rating-view-title',
            component: ScheduleDetailsRatingsDialogComponent,
            isSecondaryView: true,
            componentProps: props,
        });
    }
}