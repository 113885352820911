import { Box, Breadcrumbs, Fade, Grid, Link, Typography } from '@mui/material';
import { PageContainer } from '@nexdynamic/nex-ui-react';
import type { Supplier } from '@nexdynamic/squeegee-common';
import { useNavigate, useParams } from 'react-router';
import Text from '../../components/Text';
import useStoredObject from '../../hooks/useStoredObject';
import SupplierDashboard from '../components/SupplierDashboard';

export const SupplierView = () => {
    const { id = '' } = useParams();
    const navigate = useNavigate();
    const supplier = useStoredObject<Supplier>(id);

    const View = () => {
        if (supplier) return <SupplierDashboard supplier={supplier} />;
        return (
            <Box>
                <Text text='supplier.supplier-not-found' />
            </Box>
        );
    };

    return (
        <Fade in={true}>
            <div>
                <PageContainer>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ flex: 1 }}>
                                <Breadcrumbs aria-label='breadcrumb'>
                                    <Link
                                        underline='none'
                                        component='button'
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => navigate('/suppliers/list')}
                                    >
                                        <Text variant='subtitle1' text='suppliers.title' />
                                    </Link>
                                    <Typography variant='subtitle1' color='text.primary'>
                                        {supplier?.name}
                                    </Typography>
                                </Breadcrumbs>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <View />
                    </Grid>
                </PageContainer>
            </div>
        </Fade>
    );
};

export default SupplierView;
