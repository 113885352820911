import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Logger } from '../../../Logger';
import AppProviders from '../../AppProviders';
import ApplicationStateProvider from '../../components/ApplicationStateProvider';
import { BrowserFallBackToHashRouter } from '../../components/BrowserFallBackToHashRouter';
import UncaughtErrorFallback from '../../components/UncaughtErrorFallback';
import PortalDirectoryApp from './PortalDirectoryApp';

export const PortalDirectory = (element: HTMLElement) => {
    const root = ReactDOM.createRoot(element);

    const script = document.querySelector('#portal-widgets-script');
    if (!script) {
        const scriptElement = document.createElement('script');
        scriptElement.id = 'portal-widgets-script';
        scriptElement.src = 'https://widgets.sqg.ee/main.js';
        scriptElement.defer = true;
        document.head.appendChild(scriptElement);
        scriptElement.onload = () => {
            Logger.info('loaded portal script');
        };
    }

    root.render(
        <ApplicationStateProvider>
            <BrowserFallBackToHashRouter>
                <AppProviders>
                    <ErrorBoundary fallback={<UncaughtErrorFallback />}>
                        <PortalDirectoryApp />
                    </ErrorBoundary>
                </AppProviders>
            </BrowserFallBackToHashRouter>
        </ApplicationStateProvider>
    );

    return {
        unmount: () => root.unmount(),
    };
};
