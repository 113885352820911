import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Checkbox, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import type { Audience } from '@nexdynamic/squeegee-common';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import useStoredObjects from '../../../../hooks/useStoredObjects';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

type Props = {
    fieldName: string;
    required?: boolean;
};

const AudienceSelectInput = ({ fieldName, required }: Props) => {
    const audiences = useStoredObjects<Audience>('audiences');
    const { setFieldValue, errors, touched, isSubmitting, setFieldTouched, initialValues } = useFormikContext<Record<string, string>>();

    const [selected, setSelected] = useState<Audience[]>([]);

    useEffect(() => {
        if (audiences.length) {
            const audienceIds = initialValues[fieldName];
            const existingAudiences = audiences?.filter(audience => audienceIds?.indexOf(audience._id) > -1);
            setSelected(existingAudiences);
        }
    }, []);

    return (
        <Autocomplete
            data-testid='audienceSelectInput'
            value={selected}
            disabled={isSubmitting}
            multiple
            fullWidth
            sx={{ width: '100%' }}
            options={audiences}
            disableCloseOnSelect
            autoHighlight
            getOptionLabel={option => option.name}
            onClose={() => setFieldTouched(fieldName, true)}
            onChange={(_, audiences) => {
                setSelected(audiences);
                setFieldValue(
                    fieldName,
                    audiences.map(audience => audience._id)
                );
            }}
            renderOption={(props, option, { selected }) => (
                <li {...props} key={option._id}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    <Box>
                        <Typography component='p' variant='subtitle1'>
                            {option.name}
                        </Typography>
                        <Typography color='textSecondary' variant='body2'>
                            {option.description}
                        </Typography>
                    </Box>
                </li>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    error={Boolean(touched[fieldName] && errors[fieldName])}
                    helperText={touched[fieldName] && errors[fieldName]}
                    autoComplete='none'
                    label='Choose a Audience'
                    name={fieldName}
                    InputProps={{
                        ...params.InputProps,
                        required: required && Boolean(touched[fieldName] && errors[fieldName]),
                        autoComplete: 'new-password', // disable autocomplete and autofill
                        endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                    }}
                />
            )}
        />
    );
};

export default AudienceSelectInput;
