import CheckIcon from '@mui/icons-material/CheckRounded';
import FailedIcon from '@mui/icons-material/ErrorRounded';
import { CircularProgress, Fade, Stack, Typography } from '@mui/material';

type Props = {
    protocol: 'smtp' | 'imap';
    validating: boolean;
    success: boolean;
};

const ValidatingStep = ({ protocol, validating, success }: Props) => {
    return (
        <Stack spacing={2} alignItems="center" justifyContent="center" height="512px">
            {validating ? (
                <>
                    <CircularProgress />
                    <Typography>Validating {protocol.toUpperCase()} Settings...</Typography>
                </>
            ) : (
                <Fade in={!validating}>
                    {success ? (
                        <CheckIcon sx={{ fontSize: 128, color: 'success.main' }} />
                    ) : (
                        <FailedIcon sx={{ fontSize: 128, color: 'error.main' }} />
                    )}
                </Fade>
            )}
        </Stack>
    );
};

export default ValidatingStep;
