import { activationStrategy } from 'aurelia-router';
import { Audit } from './Audit';
export class AuditModule {

    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    audit: ReturnType<typeof Audit>;

    attached() {
        const container = document.getElementById('audit-root');
        if (!container) throw new Error('audit-root element not found');
        this.audit = Audit(container);
    }

    detached() {
        this.audit?.unmount();
    }
}
