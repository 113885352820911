import { Box, Step, StepLabel, Stepper } from '@mui/material';
import { GradientButton } from '@nexdynamic/nex-ui-react';
import type { EditorTemplate } from '@nexdynamic/send-editor/core';
import type { Template, TemplateState } from '@nexdynamic/squeegee-common';
import { BlockTemplate, isBlockTemplate, isEditorTemplate } from '@nexdynamic/squeegee-common';
import React, { useMemo, useRef, useState } from 'react';
import { useSetting } from '../../../../hooks/useSetting';
import useStoredObject from '../../../../hooks/useStoredObject';
import { TemplateDetailsForm, TemplateDetailsFormFields } from './TemplateDetailsForm';
import { TemplateFormEditorStep } from './TemplateFormEditorStep';
import { TemplateSmsForm, TemplateSmsFormFields } from './TemplateSmsForm';

type TemplateStepsProps = {
    templateId?: string;
    onFinished: (
        fields: TemplateDetailsFormFields,
        smsFields: TemplateSmsFormFields,
        template: TemplateState | EditorTemplate,
        openEditor?: boolean
    ) => Promise<void>;
};

export const TemplateFormSteps = ({ onFinished, templateId }: TemplateStepsProps) => {
    const existingTemplate = useStoredObject<Template | BlockTemplate>(templateId);
    const [step, setStep] = useState<number>(0);
    const formRef = useRef<HTMLFormElement>(null);

    const [blockEditorEnabled] = useSetting('beta-features.block-editor');

    const [templateDetailsFormFields, setTemplateDetailsFormFields] = useState<TemplateDetailsFormFields>(
        new TemplateDetailsFormFields(existingTemplate?.name, existingTemplate?.subject)
    );

    const [templateSmsFormFields, setTemplateSmsFormFields] = useState<TemplateSmsFormFields>(
        new TemplateSmsFormFields(existingTemplate?.smsTemplate, existingTemplate?.includeLink, existingTemplate?.useSubjectAsSmsMessage)
    );

    const [templateState, setTemplateState] = useState<TemplateState | undefined>((existingTemplate as Template | undefined)?.state);
    const [editorTemplate, setEditorTemplate] = useState<EditorTemplate | undefined>(
        isBlockTemplate(existingTemplate) ? BlockTemplate.toEditorTemplate(existingTemplate as BlockTemplate) : undefined
    );

    const finished = (template: TemplateState | EditorTemplate, openEditor?: boolean) => {
        if (blockEditorEnabled && template) {
            onFinished(templateDetailsFormFields, templateSmsFormFields, template, openEditor);
        } else if (template) {
            onFinished(templateDetailsFormFields, templateSmsFormFields, template);
        }
    };

    const steps = useMemo(() => {
        return [
            {
                label: 'Details',
                Form: React.forwardRef<HTMLFormElement>((_, ref) => (
                    <TemplateDetailsForm
                        ref={ref}
                        onSubmit={async fields => {
                            setTemplateDetailsFormFields(fields);
                            setStep(1);
                        }}
                        initialValues={templateDetailsFormFields}
                    />
                )),
            },
            {
                label: 'SMS',
                Form: React.forwardRef<HTMLFormElement>((_, ref) => (
                    <TemplateSmsForm
                        ref={ref}
                        initialValues={templateSmsFormFields}
                        onSubmit={async fields => {
                            setTemplateSmsFormFields(fields);
                            setStep(2);
                        }}
                        subject={templateDetailsFormFields?.subject || ''}
                    />
                )),
            },
            {
                label: 'Template',
                Form: React.forwardRef<HTMLFormElement>((_, ref) => (
                    <TemplateFormEditorStep
                        ref={ref}
                        onSubmit={async (template, openEditor) => {
                            if (isEditorTemplate(template)) {
                                setEditorTemplate(template);
                            } else {
                                setTemplateState(template);
                            }
                            finished(template, openEditor);
                        }}
                        initialTemplateState={templateState}
                        initialEditorTemplate={editorTemplate}
                    />
                )),
            },
        ];
    }, [blockEditorEnabled, templateDetailsFormFields, templateSmsFormFields, templateState, editorTemplate]);

    const Form = steps[step].Form;

    return (
        <Box>
            <Stepper activeStep={step} alternativeLabel>
                {steps.map(step => (
                    <Step key={step.label}>
                        <StepLabel>{step.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Box pt={2} pb={2}>
                <Form ref={formRef} />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                {step > 0 ? (
                    <GradientButton variant="outlined" text="Back" onClick={() => setStep(step - 1)} color="primary"></GradientButton>
                ) : null}
                <GradientButton
                    text={step == steps.length - 1 ? 'Finish' : 'Next'}
                    onClick={() => formRef.current?.requestSubmit()}
                    color="success"
                ></GradientButton>
            </Box>
        </Box>
    );
};
