import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserFallBackToHashRouter } from '../../ReactUI/components/BrowserFallBackToHashRouter';
import Text from '../../ReactUI/components/Text';
import ApplicationStateProvider from '../components/ApplicationStateProvider';
import AnalyticsApp from './AnalyticsApp';

export const Analytics = (element: HTMLElement) => {
    const root = createRoot(element);

    root.render(
        <ApplicationStateProvider>
            <BrowserFallBackToHashRouter>
                <ErrorBoundary fallback={<Text text='general.unable-to-load-view' />}>
                    <AnalyticsApp />
                </ErrorBoundary>
            </BrowserFallBackToHashRouter>
        </ApplicationStateProvider>
    );

    return {
        unmount: () => root.unmount(),
    };
};
