import type { Transaction } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-typed-observable-plugin';
import { PaymentActions } from '../../Payments/PaymentActions';
import { TransactionDialog } from '../../Transactions/Components/TransactionDialog';

export class PaymentSummaryCustomElement {
    @bindable payment: Transaction;
    @bindable showActions: boolean;

    view() {
        if (!this.payment) return;
        new TransactionDialog(this.payment).show();
    }

    cancelPayment() {
        if (!this.payment) return;
        PaymentActions.cancelPayment(this.payment._id);
    }
}
