import { List } from '@mui/material';
import { EmptyList } from '@nexdynamic/nex-ui-react';
import { withErrorBoundary } from 'react-error-boundary';
import ErrorLogger from '../../../../ErrorLogger';
import useTranslation from '../../../../hooks/useTranslation';
import AudienceListItem from './AudienceListItem';
import AudiencesIcon from './AudiencesIcon';

type AudienceContactsProps = {
    audienceContainer: { audiences: Array<string> };
};

export const AudienceContacts = withErrorBoundary(
    ({ audienceContainer }: AudienceContactsProps) => {
        const { t } = useTranslation();
        const items = audienceContainer?.audiences?.map(audienceId => {
            return <AudienceListItem audienceId={audienceId} key={audienceId} />;
        });
        return (
            <List data-testid='AudienceContacts' role='list'>
                {items?.length ? items : <EmptyList text={t('campaigns.audience-list-empty')} icon={<AudiencesIcon />} />}
            </List>
        );
    },
    { FallbackComponent: ErrorLogger }
);

export default AudienceContacts;
