import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import AppProviders from '../AppProviders';
import ApplicationStateProvider from '../components/ApplicationStateProvider';
import { BrowserFallBackToHashRouter } from '../components/BrowserFallBackToHashRouter';
import Text from '../components/Text';
import { TrainingApp } from './TrainingApp';

export const Training = (element: HTMLElement) => {
    const root = ReactDOM.createRoot(element);

    root.render(
        <ApplicationStateProvider>
            <BrowserFallBackToHashRouter>
                <AppProviders>
                    <ErrorBoundary fallback={<Text text="general.unable-to-load-view" />}>
                        <TrainingApp />
                    </ErrorBoundary>
                </AppProviders>
            </BrowserFallBackToHashRouter>
        </ApplicationStateProvider>
    );

    return {
        unmount: () => root.unmount(),
    };
};
