import { Box, Typography } from '@mui/material';
import useTranslation from './hooks/useTranslation';

export const Offline = () => {
    const { t } = useTranslation();
    return (
        <Box sx={{ margin: '32px', padding: '32px', borderRadius: '5px', backgroundColor: '#d66700', color: '#ffffff' }}>
            <Typography variant='h4'>{t('general.app-offline-title')}</Typography>
            <Typography variant='body1'>{t('general.app-offline-description')}</Typography>
        </Box>
    );
};
