import type { Quote } from '@nexdynamic/squeegee-common';
import { Currency } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { QuoteActions } from '../QuoteActions';

export class QuoteCustomElement {
    @bindable quote?: Quote;

    protected view = () => {
        if (!this.quote) return;
        QuoteActions.view(this.quote);
    };

    @computedFrom('quote.currency')
    get currencySymbol() {
        if (!this.quote) return;
        return Currency.get(this.quote.currency)?.symbol;
    }
}
