import { activationStrategy } from 'aurelia-router';
import { Pending } from './Pending';
export class SendModule {
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    pending: ReturnType<typeof Pending>;

    attached() {
        const container = document.getElementById('pending-root');
        if (!container) throw new Error('pending-root element not found');
        this.pending = Pending(container);
    }

    detached() {
        this.pending?.unmount();
    }
}
