import { Checkbox, ListItem, ListItemText } from '@mui/material';
import { useIsSmallScreen } from '@nexdynamic/nex-ui-react';
import { createPortalTheme, type PortalThemeOptions } from '../../../../DirectoryAndPortal/types/PortalTheme';

type AdvOtherProps = {
    theme: PortalThemeOptions;
    onDisableElevationColorShift: (disableElevationColorShift: boolean) => void;
};

const AdvOther = ({ theme: themeProps, onDisableElevationColorShift }: AdvOtherProps) => {
    const theme = createPortalTheme(themeProps);
    const isSmallScreen = useIsSmallScreen();

    const regex = /"backgroundImage"\s*:\s*"none"/;
    const disableElevationColorShift = JSON.stringify(theme.components?.MuiPaper?.styleOverrides?.root || '{}').match(regex) !== null;

    return (
        <ListItem sx={{ gap: 1, flexDirection: isSmallScreen ? 'column' : 'row', alignItems: isSmallScreen ? 'flex-start' : 'center' }}>
            <ListItemText
                primary='Disable Elevation Color Shift'
                secondary='Prevents the paper color from changing based on the elevation'
            />
            <Checkbox defaultChecked={disableElevationColorShift} onChange={(_, checked) => onDisableElevationColorShift(checked)} />
        </ListItem>
    );
};

export default AdvOther;
