import type { Customer } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { Api } from '../../Server/Api';

export const getStripeSignUpForCustomer = (customer: Customer) => {
    if (!ApplicationState.account.stripePublishableKey) return;

    if (customer.hideStripeFromInvites || customer.hideStripePayButtonFromInvoice) return;
    const stripe = customer.paymentProviderMetaData?.stripe;
    const stripeAlreadySetup = stripe?.sourceOrMandateId && ['active', 'pending'].includes(stripe?.status || '');

    if (stripeAlreadySetup) return;

    if (customer.paymentProviderMetaData?.gocardless?.status === 'active') return;

    if (ApplicationState.account.invoiceSettings.hideStripePayButtonFromInvoice) return;

    return `${Api.currentHostAndScheme}/go/a/${customer._id}/stripe/card`;
};
