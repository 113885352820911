import { useInterval } from '@nexdynamic/nex-ui-react';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Api } from '../../Server/Api';
import useTranslation from './useTranslation';

export const usePollApiStatus = (notify = true) => {
    const [apiOnline, setApiOnline] = useState<boolean>(Api.isConnected);
    const [connectionRestored, setConnectionRestored] = useState<boolean | null>(null);

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const run = () => {
        setConnectionRestored(false);
        const newValue = Api.isConnected;
        if (apiOnline === false && newValue === true) {
            setConnectionRestored(true);
        }
        if (newValue !== apiOnline) {
            setApiOnline(newValue);
        }
    };

    //Run on mount
    useEffect(() => {
        run();
    }, []);

    //Run every 5 seconds
    useInterval(() => {
        run();
    }, 5000);

    useEffect(() => {
        if (!notify) return;
        // Don't notify for the first time
        if (apiOnline === null) return;
        if (!apiOnline) {
            enqueueSnackbar(t('general.connectionLost'), { variant: 'error', preventDuplicate: true });
        } else if (connectionRestored) {
            enqueueSnackbar(t('general.connectionRestored'), { variant: 'success', preventDuplicate: true });
        }
    }, [apiOnline, notify]);

    return apiOnline;
};

export default usePollApiStatus;
