import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import AppProviders from '../AppProviders';
import ApplicationStateProvider from '../components/ApplicationStateProvider';
import { BrowserFallBackToHashRouter } from '../components/BrowserFallBackToHashRouter';
import Text from '../components/Text';
import LibraryApp from './LibraryApp';
import LibraryAppProviders from './LibraryAppProviders';

export const Library = (element: HTMLElement) => {
    const root = createRoot(element);

    root.render(
        <AppProviders>
            <ApplicationStateProvider>
                <BrowserFallBackToHashRouter>
                    <LibraryAppProviders>
                        <ErrorBoundary fallback={<Text text='general.unable-to-load-view' />}>
                            <LibraryApp />
                        </ErrorBoundary>
                    </LibraryAppProviders>
                </BrowserFallBackToHashRouter>
            </ApplicationStateProvider>
        </AppProviders>
    );

    return {
        unmount: () => root.unmount(),
    };
};
