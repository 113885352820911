import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import InputIcon from '@mui/icons-material/Input';
import UserIcon from '@mui/icons-material/Person';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { default as RestoreBackupIcon } from '@mui/icons-material/Restore';

import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import type { BackupInitiatorTask } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../../ApplicationState';

const getTextDescription = (initiatorTask?: BackupInitiatorTask): string => {
    const key = initiatorTask ?? 'auto';
    return ApplicationState.localise(`settings.backup-initiator-task-${key}`);
};
const getIcon = (initiatorTask?: BackupInitiatorTask) => {
    switch (initiatorTask) {
        case 'shift-schedule':
            return <EditCalendarIcon fontSize="inherit" />;
        case 'manual':
            return <UserIcon fontSize="inherit" />;
        case 'import-data':
            return <InputIcon fontSize="inherit" />;
        case 'reset-schedule':
            return <EventRepeatIcon fontSize="inherit" />;
        case 'restore-backup':
            return <RestoreBackupIcon fontSize="inherit" />;
        case 'auto':
            return <AccessTimeIcon fontSize="inherit" />;
        case 'delete-user':
            return <PersonRemoveIcon fontSize="inherit" />;
        case undefined:
            return null;
    }
};
export const BackupInitiatorTaskComponent = ({ initiatorTask }: { initiatorTask?: BackupInitiatorTask }) => {
    const textDescription = getTextDescription(initiatorTask);
    return (
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
            {getIcon(initiatorTask)}
            <Typography>{textDescription}</Typography>
        </Stack>
    );
};
