import type { LedgerId } from '@nexdynamic/balance-core';
import type { Contact } from '@nexdynamic/squeegee-common';
import { Supplier } from '@nexdynamic/squeegee-common';
import { Data } from '../../Data/Data';
import type { SupplierFormFields } from './components/SupplierForm';

export const supplierCreate = async (fields: SupplierFormFields): Promise<Supplier> => {
    // Always get the contacts to ensure we have the latest version
    const contacts = supplierGetContacts(fields.contactIds);

    const notes = fields.notes?.trim();
    const supplier = new Supplier({
        name: fields.name,
        notes,
        contactIds: contacts.map(c => c._id)
    });

    await Data.put(supplier);
    return supplier;
};


export const supplierRemove = async (supplier: Supplier): Promise<void> => {
    await Data.delete(supplier);
}

export const supplierGetContacts = (contactIds: Array<string>): Array<Contact> => {
    const contacts = [];

    for (const contactId of contactIds ?? []) {
        const contact = Data.get<Contact>(contactId);
        if (contact) contacts.push(contact);
    }

    return [...contacts];
}

export const supplierUpdate = async (supplierId: string, fields: SupplierFormFields): Promise<Supplier> => {
    const supplier = Data.get<Supplier>(supplierId);
    if (!supplier) throw new Error(`Supplier with id ${supplierId} not found`);

    // Always get the contacts to ensure we have the latest version
    const contacts = supplierGetContacts(fields.contactIds);

    supplier.name = fields.name;
    supplier.notes = fields.notes;
    supplier.contactIds = contacts.map(c => c._id);
    await Data.put(supplier);
    return supplier;
}

export const getSupplierLedgerId = (supplierId: string): LedgerId => {
    return `liabilities.current_liabilities.creditors.suppliers.${supplierId}`;
}

export const isDuplicateSupplier = (_name: string, id?: string): boolean => {
    const name = _name.trim().toLowerCase();
    const allSuppliers = Data.all<Supplier>('supplier');

    for (const supplier of allSuppliers) {
        if (supplier.name.toLowerCase() === name && supplier._id !== id) return true;
    }

    return false;
}