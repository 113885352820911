import { Box, List } from '@mui/material';
import { EmptyList, SearchQueryField, useIsSmallScreen, useSearchQueryParams } from '@nexdynamic/nex-ui-react';
import type { Supplier } from '@nexdynamic/squeegee-common';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useStoredObjects from '../../hooks/useStoredObjects';
import useTranslation from '../../hooks/useTranslation';
import SupplierListItem from './SupplierListItem';
import SuppliersIcon from './SuppliersIcon';

const searchFilter = (search: string, supplier: Supplier) => {
    return supplier.name.toLowerCase().includes(search.toLowerCase());
};

const SupplierList = () => {
    const {
        searchQuery: [search],
    } = useSearchQueryParams();
    const suppliers = useStoredObjects<Supplier>('supplier', search ? { where: s => searchFilter(search, s) } : undefined);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const isSmallScreen = useIsSmallScreen();

    const handleView = (supplier: Supplier) => {
        navigate(`/suppliers/${supplier._id}`);
    };

    const items = useMemo(() => {
        if (suppliers.length === 0)
            return (
                <EmptyList
                    text={search?.length ? t('suppliers.search-empty', { searchTerm: search }) : t('suppliers.empty')}
                    icon={<SuppliersIcon />}
                />
            );
        return suppliers?.length ? (
            suppliers.map(supplier => {
                return <SupplierListItem onClick={handleView} supplier={supplier} key={supplier._id} />;
            })
        ) : (
            <EmptyList text={t('suppliers.empty')} icon={<SuppliersIcon />} />
        );
    }, [suppliers, search]);

    return (
        <>
            <Box sx={{ width: '100%', maxWidth: isSmallScreen ? '100%' : 320 }}>
                <SearchQueryField />
            </Box>

            <List>{items}</List>
        </>
    );
};

export default SupplierList;
