import { Box, Grid, Paper, Typography } from '@mui/material';
import { DiscardButton, GradientButton } from '@nexdynamic/nex-ui-react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../../../../../hooks/useTranslation';
import { CampaignCreateFields } from '../../hooks/useCampaignCreate';
import CampaignCreateSchema from '../../schemas/CampaignCreateSchema';
import CampaignDeliveryMethods from './CampaignDeliveryMethodsStep';
import CampaignDetailsStep from './CampaignDetailsStep';
import CampaignTemplateStep from './CampaignTemplateStep';
import CampaignToStep from './CampaignToStep';

type CampaignFormProps = {
    fields?: CampaignCreateFields;
    onSubmit: (fields: CampaignCreateFields) => Promise<void>;
};

const CampaignForm = ({ fields, onSubmit }: CampaignFormProps) => {
    const { t } = useTranslation();
    const initialValues = fields ? fields : new CampaignCreateFields();
    const navigate = useNavigate();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={CampaignCreateSchema()}
            onSubmit={async fields => {
                await onSubmit(fields);
            }}
        >
            {({ isSubmitting, values }) => (
                <Form data-testid='CampaignForm'>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                <Box sx={{ flex: 1, mr: 1 }}>
                                    <Typography variant='h6'>{values.name ? values.name : t('campaign.untitled')}</Typography>
                                </Box>
                                <Box>
                                    <DiscardButton sx={{ mr: 1 }} onDiscard={() => navigate(-1)} />
                                    <GradientButton
                                        aria-label={t('campaigns.save')}
                                        text={t('general.save')}
                                        type='submit'
                                        disabled={isSubmitting}
                                        color='success'
                                    ></GradientButton>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper sx={{ p: 2 }}>
                                <CampaignDetailsStep />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper sx={{ p: 2 }}>
                                <CampaignToStep />
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper sx={{ p: 2 }}>
                                <CampaignDeliveryMethods fieldName='selectedDeliveryMethods' />
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper sx={{ p: 2 }}>
                                <CampaignTemplateStep />
                            </Paper>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default CampaignForm;
