import type { PaymentEvent, Transaction } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';

export class PaymentHistoryCustomElement {
    @bindable() payment?: Transaction;

    @computedFrom('payment.paymentDetails')
    protected get history() {
        return this.payment?.paymentDetails?.paymentHistory?.map(x => ({
            created: x.created,
            action: x.action,
            description: this.getDescription(x)
        }))
    }

    protected getDescription(event: PaymentEvent): string {
        if (!event) return '';

        let description = typeof event.details.description === 'string' ? event.details.description : (event.details.cause as string || 'CauseUnknown');

        if (this.payment?.transactionSubType === 'auto.go-cardless' && description.includes('status code 409'))
            return 'The payment failed submission to GoCardless with status code 409. It was detected as a possible duplicate so you may need to re-request this payment against the customer rather than the job.'

        if (this.payment?.transactionSubType === 'auto.go-cardless' && description.includes('status code 422'))
            return 'The payment failed submission to GoCardless with status code 422. The customer mandate may have been cancelled or there may be an issue with your connection to GoCardless.'

        return description;
    }
}
