import type {
    Attachment,
    Customer,
    DayOfWeek,
    IQuoteItem,
    IQuoteItemJob,
    Job,
    QuotedJob,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import { Currency, Frequency, FrequencyBuilder, FrequencyType, Linker, Quote } from '@nexdynamic/squeegee-common';

import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { AttachmentService } from '../Attachments/AttachmentService';
import { DateTimePicker } from '../Components/DateTimePicker/DateTimePicker';
import { CustomerFormDialog } from '../Customers/Components/CustomerFormDialog';
import { SelectCustomerDialog } from '../Customers/Components/SelectCustomerDialog';
import { CustomerService } from '../Customers/CustomerService';
import { Data } from '../Data/Data';
import { AureliaReactComponentDialog } from '../Dialogs/AureliaReactComponentDialog';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { Prompt } from '../Dialogs/Prompt';
import { SendMessageToCustomer } from '../Dialogs/SendMessageToCustomer';
import { TextAreaDialog } from '../Dialogs/TextAreaDialog';
import { JobService } from '../Jobs/JobService';
import { JobActions } from '../Jobs/actions/JobActions';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import type { TransferQuotesProps } from '../ReactUI/transfer/TransferQuotes';
import { TransferQuotes } from '../ReactUI/transfer/TransferQuotes';
import { Api } from '../Server/Api';
import { openSystemBrowser } from '../Utilities';
import type { QuoteFormFields } from './Components/quote-form';
import { QuoteFormDialog } from './Dialogs/QuoteFormDialog';
import { QuoteSummaryDialog } from './Dialogs/QuoteSummaryDialog';
import { QuoteService } from './QuoteService';

export class QuoteActions {
    public static async view(quote: Quote) {
        try {
            if (!quote) return Logger.error('Unable to view quote');

            const customer = Data.get<Customer>(quote.customerId);
            if (customer) return new QuoteSummaryDialog(quote).show();

            const deleteQuoteDialog = new Prompt('general.warning', 'quote.customer-not-found-delete-quote', {
                okLabel: 'quote.customer-not-found-delete-quote-delete-button',
                cancelLabel: 'general.cancel',
            });

            if (await deleteQuoteDialog.show()) await QuoteService.delete(quote);
        } catch (error) {
            Logger.error('Error in view quote', { quote, error });
            new NotifyUserMessage('quotes.error-viewing');
        }
    }

    public static preview(quoteId: string) {
        try {
            const url = `${Api.apiEndpoint}/go/q/${quoteId}?preview_mode=true`;
            openSystemBrowser(url);
        } catch (error) {
            Logger.error('Error in preview quote', error);
            new NotifyUserMessage('quotes.error-viewing');
        }
    }

    public static async edit(quote: Quote) {
        try {
            if (!quote) return Logger.error('Unable to view quote');

            const customer = CustomerService.getCustomer(quote.customerId);
            if (!customer) return Logger.error('Customer not found for quote');

            const dialog = new QuoteFormDialog(customer, quote);
            await dialog.show();
        } catch (error) {
            Logger.error('Error in selectQuote() on Quotes', { quote, error });
            new NotifyUserMessage('quotes.error-viewing');
        }
    }

    public static async create() {
        try {
            const selectCustomerDialog = new SelectCustomerDialog();
            let customer = await selectCustomerDialog.show(DialogAnimation.SLIDE_UP);
            if (selectCustomerDialog.cancelled || !customer) return;

            if (selectCustomerDialog.customerIsNew) {
                const dialog = new CustomerFormDialog(customer);
                customer = await dialog.show();
                if (dialog.cancelled) return;
            }

            const quoteDialog = new QuoteFormDialog(customer);
            const quote = await quoteDialog.show();
            if (quote) new QuoteSummaryDialog(quote).show();
        } catch (error) {
            Logger.error('Unable to create quote', error);
        }
    }

    public static async delete(quote: Quote) {
        try {
            const dialog = new Prompt('general.confirm', 'quote.delete-confirm', {
                okLabel: 'actions.delete-quote',
                cancelLabel: 'general.cancel',
            });
            await dialog.show();
            if (!dialog.cancelled) {
                await QuoteService.delete(quote);
                new NotifyUserMessage('quote.deleted');
            }
        } catch (error) {
            new NotifyUserMessage('quote.delete-error');
            Logger.error('Unable to delete quote', error);
        }
    }

    public static sellAsLead(quote: Quote) {
        return new Promise<void>(resolve => {
            const ids = quote.items.length ? quote.items.map(x => x.relatedId).join(',') : quote._id;
            const type: 'quoted-lead' | 'lead' = quote.items.length ? 'quoted-lead' : 'lead';
            const customerId = quote.customerId;
            const notes = quote.notes || '';
            ApplicationState.navigateToRouteFragment(
                `marketplace/sell-quoted-jobs?quotedJobIds=${ids}&type=${type}&customerId=${customerId}&notes=${encodeURIComponent(notes)}`
            );
            resolve();
        });
    }

    public static async transferLead(quote: Quote) {
        const { dialog, show } = AureliaReactComponentDialog.show<boolean, TransferQuotesProps>({
            dialogTitle: 'marketplace.transfer-quotes',
            component: TransferQuotes,
            componentProps: {
                customerId: quote.customerId,
                notes: quote.notes || '',
                quoteIds: [quote.items.length ? quote.items.map(x => x.relatedId).join(',') : quote._id],
                type: quote.items.length ? 'quoted-lead' : 'lead',
            },
            isSecondaryView: true,
        });

        const result = await show;

        if (dialog.cancelled) return;

        if (result) {
            new NotifyUserMessage('jobs.transfer-complete');
            dialog.dispose();
        }
    }
    private static saveQuoteFormInProgress: Promise<TSaveQuoteFormResult>;
    public static async saveQuoteForm(
        customer: Customer,
        fields: QuoteFormFields,
        quoteToUpdate?: Quote,
        autoSave = false
    ): Promise<TSaveQuoteFormResult> {
        await QuoteActions.saveQuoteFormInProgress;
        QuoteActions.saveQuoteFormInProgress = new Promise<TSaveQuoteFormResult>(async resolve => {
            try {
                const isNew = !quoteToUpdate;
                const quote = Quote.update(quoteToUpdate || new Quote(customer._id), fields);

                QuoteActions.applyQuoteIdToJobs(quote._id, fields.jobs);

                const { errors, valid } = await Quote.validate(quote);
                if (valid) {
                    //If the state has been changed apply the actions
                    if (quoteToUpdate?.state !== fields.state) {
                        switch (fields.state) {
                            case 'accepted':
                                await QuoteActions.accept(quote, false);
                                break;
                            case 'declined':
                                await QuoteActions.decline(quote, false);
                                break;
                            case 'pending':
                                await QuoteService.markAsPending(quote, false);
                                break;
                        }
                    }

                    await Data.put([quote, ...fields.jobs]);

                    if (!autoSave && isNew) {
                        await QuoteActions.promptSend(quote);
                    }

                    return resolve({ success: true, quote });
                } else {
                    new NotifyUserMessage(errors[0] || 'general.validation-failed');
                    return resolve({ success: false });
                }
            } catch (error) {
                Logger.error('Quote failed validation', error);
                return resolve({ error: 'quote.failed-to-save', success: false });
            }
        });

        return QuoteActions.saveQuoteFormInProgress;
    }

    private static applyQuoteIdToJobs(quoteId: string, jobs: Array<QuotedJob>) {
        if (!quoteId) throw new Error('No quote id passed unable apply quote to jobs');
        for (const job of jobs) {
            job.quoteId = quoteId;
        }
    }

    public static jobToQuoteItems(job: QuotedJob): Array<IQuoteItemJob> {
        // When services are not priced individually we need to show it as one line item
        //TODO Fix bug with service price being saved as a string
        const totalServicePrice = job.services.reduce((prev, s) => {
            let price: number;
            if (typeof s.price === 'string') {
                price = parseFloat(s.price) || 0;
            } else {
                price = s.price || 0;
            }

            return (prev += (s.quantity || 1) * price);
        }, 0);

        const pricedIndividually = Boolean(totalServicePrice === job.price);
        const currencySymbol = Currency.get(ApplicationState.account.currency)?.symbol;
        const frequency = FrequencyBuilder.getFrequency(
            job.frequencyInterval,
            job.frequencyType,
            job.date || moment().format('YYYY-MM-DD'),
            job.frequencyDayOfMonth,
            job.frequencyWeekOfMonth,
            Array.isArray(job.frequencyDayOfWeek) ? job.frequencyDayOfWeek[0] : job.frequencyDayOfWeek
        );

        let summary = '';
        if (job.frequencyRange) {
            summary = new Frequency(
                moment(job.date) || moment().format('YYYY-MM-DD'),
                job.frequencyInterval,
                job.frequencyType,
                job.frequencyWeekOfMonth,
                job.frequencyDayOfMonth,
                job.frequencyDayOfWeek as DayOfWeek | undefined,
                job.frequencyRange
            ).summary;
        }
        const frequencyText =
            summary !== ''
                ? summary
                : ApplicationState.localise(frequency.localisationKeyAndParams.key, frequency.localisationKeyAndParams.params);
        const jobDate = `${job.date ? moment(job.date).format('LL') + ' - ' : ''}`;

        if (pricedIndividually) {
            return job.services.map(service => {
                if (!job._id) throw new Error('Job id was invalid while trying to convert job to a quote item');
                const total = (service.price || 0) * (service.quantity || 1);
                const unitPrice = typeof service.price === 'string' ? parseFloat(service.price as string) : service.price || 0;
                const quantity = service.quantity || 1;
                return {
                    relatedId: job._id,
                    total: parseFloat(total.toFixed(2)),
                    quantity,
                    frequencyDescription: frequencyText,
                    //Service price sometimes is a string seems incorrect
                    unitPrice,
                    title:
                        job.frequencyType === FrequencyType.NoneRecurring
                            ? `${service.description}`
                            : `${service.description} ${frequencyText}`.trim(),
                    type: 'job',
                    description: ApplicationState.localise('quote.line-item-description', {
                        date: jobDate,
                        unitPrice: `${currencySymbol}${unitPrice?.toFixed(2)}`,
                        quantity: quantity.toString(),
                    }).trim(),
                    address: job.location,
                    initialFields: job.initialFields,
                    longDescription: service.longDescription || '',
                };
            });
        } else {
            const title = job.services.map(s => s.description).join(', ');
            const item: IQuoteItemJob = {
                relatedId: job._id,
                total: job.price || 0,
                quantity: 1,
                frequencyDescription: frequencyText,
                unitPrice: job.price || 0,
                title: job.frequencyType === FrequencyType.NoneRecurring ? `${title}` : `${title} ${frequencyText}`.trim(),
                type: 'job',
                description: ApplicationState.localise('quote.line-item-description', {
                    date: jobDate,
                    unitPrice: `${currencySymbol}${job.price?.toFixed(2)}`,
                    quantity: '1',
                }).trim(),
                address: job.location,
                initialFields: job.initialFields,
                longDescription: job.services[0]?.longDescription || '',
            };
            return [item];
        }
    }

    public static removeJobFromQuoteFields(fields: QuoteFormFields, jobId: string) {
        //remove all quote items that match this job is
        fields.items = fields.items.filter(item => item.relatedId !== jobId);

        //Remove any jobs from the job fields that match this job id
        fields.jobs = fields.jobs.filter(job => job._id !== jobId);
    }

    public static replaceJob(fields: QuoteFormFields, quotedJob: QuotedJob) {
        //Remove the job from quote and recreate everything again
        QuoteActions.removeJobFromQuoteFields(fields, quotedJob._id);
        // WTF make sure this job if removed is not marked as deleted
        quotedJob._deleted = false;
        fields.jobs.push(quotedJob);
        fields.items.push(...QuoteActions.jobToQuoteItems(quotedJob));
    }

    public static async decline(quote: Quote, save = true) {
        try {
            const dialog = new TextAreaDialog('general.confirm', '', ApplicationState.localise('quote.enter-decline-reason'));
            const reason = await dialog.show();
            if (!dialog.cancelled) {
                await QuoteService.decline(quote, reason, save);

                new NotifyUserMessage('quote.declined');
            }
        } catch (error) {
            new NotifyUserMessage('quote.decline-error');
            Logger.error('Unable to mark quote as declined', error);
        }
    }

    public static async accept(quote: Quote, save = true) {
        try {
            const dialog = new Prompt('general.confirm', 'quote.accept-confirm');
            await dialog.show();
            if (!dialog.cancelled) {
                await QuoteService.accept(quote, save);

                new NotifyUserMessage('quote.accepted');
            }
        } catch (error) {
            new NotifyUserMessage('quote.accept-error');
            Logger.error('Unable to mark quote as accepted', error);
        }
    }

    public static async checkQuoteProcessed(quote: Quote): Promise<boolean> {
        if (!quote.quoteNumber) {
            const dialog = new Prompt('general.warning', 'quote.not-yet-processed', {
                cancelLabel: '',
                okLabel: 'general.dismiss-message',
            });
            await dialog.show();
            return false;
        }

        return true;
    }

    public static async send(quote: Quote) {
        try {
            if (!ApplicationState.isVerifiedForMessaging) throw new Error('Please verify your email to send messages');
            const customer = Data.get<Customer>(quote.customerId);
            if (!customer) throw new Error('Unable send quote customer not found');
            const quoteSettings = QuoteService.getQuoteSettings();

            const { sms, email } = await QuoteService.getQuoteTemplates(quote, customer, quoteSettings);
            const newMessage = new SendMessageToCustomer(customer, { sms, email, emailIsHtml: true }, undefined, undefined, false);
            await newMessage.show(DialogAnimation.NONE);

            if (!newMessage.cancelled) {
                await QuoteService.markAsSent(quote, Date.now());
                new NotifyUserMessage('notifications.quote-sent');
            }
        } catch (error) {
            new NotifyUserMessage('quote.sent-error');
            Logger.error('Unable to send quote', error);
        }
    }

    public static async markAsSent(quote: Quote) {
        try {
            const datePicker = new DateTimePicker(false, new Date().toISOString(), 'quote.sent-date');
            datePicker.init();
            await datePicker.open();

            if (!datePicker.canceled) {
                await QuoteService.markAsSent(quote, new Date(datePicker.selectedDate).valueOf(), true);
                new NotifyUserMessage('quote.marked-as-sent');
            }
        } catch (error) {
            new NotifyUserMessage('quote.mark-as-sent-error');
            Logger.error('Unable to mark quote as sent');
        }
    }

    public static async markAsUnsent(quote: Quote) {
        try {
            const dialog = new Prompt('general.confirm', 'quote.mark-as-unsent-description', { okLabel: 'general.yes' });

            await dialog.show();

            if (!dialog.cancelled) {
                await QuoteService.markAsUnsent(quote);
                new NotifyUserMessage('quote.marked-as-unsent');
            }
        } catch (error) {
            new NotifyUserMessage('quote.mark-as-unsent-error');
            Logger.error('Unable to mark quote as unsent');
        }
    }

    public static async convertToJob(quote: Quote) {
        try {
            const dialog = new Prompt('general.confirm', 'quote.confirm-convert-to-job', { okLabel: 'general.convert' });
            await dialog.show();

            if (!dialog.cancelled) {
                const customer = CustomerService.getCustomer(quote.customerId);
                if (!customer) throw new Error('Unable to find customer');

                const jobs = await QuoteService.convertItemsToJobs(quote);

                const moreThanOne = jobs.length > 1;

                if (jobs.length) {
                    const dialog = new Prompt(
                        'general.confirm',
                        moreThanOne
                            ? ApplicationState.localise('quote.convert-complete-confirm-plural', { count: jobs.length.toString() })
                            : 'quote.convert-complete-confirm',
                        { okLabel: 'general.save' }
                    );
                    await dialog.show();

                    const storedJobs = new Array<Job>();
                    if (!dialog.cancelled) {
                        for (const job of jobs) {
                            storedJobs.push(
                                await JobService.addOrUpdateJob(
                                    customer._id,
                                    job.job,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    undefined,
                                    job.initialFields,
                                    job.job.assignees?.map(a => a._id)
                                )
                            );
                        }
                        const linker = AttachmentService.getLinkerForParent(quote._id);
                        if (linker) {
                            for (const { targetId, value } of Linker.getLinks(linker)) {
                                if (value !== 'attachments') continue;
                                const attachment = Data.get<Attachment>(targetId);
                                if (!attachment) continue;

                                for (const job of storedJobs) await AttachmentService.linkAttachment(attachment, job);
                            }
                        }
                        await QuoteService.accept(quote);
                        await CustomerService.updateState(customer, 'active');
                        new NotifyUserMessage(moreThanOne ? 'quote.convert-to-jobs-success' : 'quote.convert-to-job-success');
                        QuoteActions.promptSendBookingConfirmation(
                            customer._id,
                            jobs.map(j => j.job)
                        );
                    }
                }
            }
        } catch (error) {
            Logger.error('Unable to convert quote into jobs', error);
            new NotifyUserMessage('quote.convert-jobs-error');
        }
    }

    static async promptSend(quote: Quote) {
        try {
            const dialog = new Prompt('general.confirm', 'quote.prompt-send', { okLabel: 'general.send', cancelLabel: 'general.not-now' });
            await dialog.show();
            if (!dialog.cancelled) {
                await QuoteActions.send(quote);
            }
        } catch (error) {
            Logger.error('Unable to promptSend quote', error);
        }
    }

    static async promptSendBookingConfirmation(customerId: string, jobs: Array<Job>) {
        try {
            const dialog = new Prompt('booking.send-confirmation', 'booking.prompt-send-confirmation', {
                okLabel: 'general.send',
                cancelLabel: 'general.not-now',
            });
            await dialog.show();
            if (!dialog.cancelled) {
                await JobActions.sendBookingConfirmationForJobs(customerId, jobs);
            }
        } catch (error) {
            Logger.error('Unable to promptSendBookingConfirmation', error);
        }
    }

    static groupItems(items: Array<IQuoteItem>): { [id: string]: Array<IQuoteItem> } {
        if (!items.length) return {};

        const groupedItems: { [id: string]: Array<IQuoteItem> } = items.reduce((acc, item) => {
            if (item.relatedId !== undefined) {
                if (acc[item.relatedId]) {
                    acc[item.relatedId].push(item);
                } else {
                    acc[item.relatedId] = [item];
                }
            }
            return acc;
        }, {} as { [id: string]: Array<IQuoteItem> });

        return groupedItems;
    }
}

export type TSaveQuoteFormResult = { error?: TranslationKey; success: boolean; quote?: Quote };
