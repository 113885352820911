import { Typography } from '@mui/material';
import { Suspense } from 'react';
import { Outlet, Route, Routes } from 'react-router';
import ViewLoading from '../components/ViewLoading';
import PendingMain from './views/PendingMain';

export const PendingRouter = () => {
    return (
        <Suspense fallback={<ViewLoading />}>
            <Outlet />
            <Routes>
                <Route path="/" element={<PendingMain />} />

                <Route
                    path="*"
                    element={
                        <Typography variant="h6" textAlign="center">
                            Error Page Not Found
                        </Typography>
                    }
                />
            </Routes>
        </Suspense>
    );
};

export default PendingRouter;
