import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PersonIcon from '@mui/icons-material/Person';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import type { SimplePaletteColorOptions, Theme } from '@mui/material';
import type { DateRangeValue } from '@nexdynamic/nex-ui-react';
import moment from 'moment';
import type { Group, Grouping } from './usePortalAnalytics';

export const groupByHour = (timestamps: Array<number>, startDate: moment.Moment, endDate: moment.Moment) => {
    const grouped: { [hour: string]: number } = {};
    timestamps.forEach(timestamp => {
        const hour = moment(timestamp).format('YYYY-MM-DDTH');
        grouped[hour] = (grouped[hour] || 0) + 1;
    });

    const result: Group[] = [];
    const currentHour = startDate.clone().startOf('hour');
    while (currentHour.isBefore(endDate)) {
        const hourString = currentHour.format('YYYY-MM-DDTH');
        result.push({ time: currentHour.format('hA'), count: grouped[hourString] || 0 });
        currentHour.add(1, 'hour');
    }

    return result;
};

export const groupByDay = (timestamps: Array<number>, startDate: moment.Moment, endDate: moment.Moment) => {
    const grouped: { [day: string]: number } = {};
    timestamps.sort().forEach(timestamp => {
        const day = moment(timestamp).format('L');
        grouped[day] = (grouped[day] || 0) + 1;
    });

    const result: Group[] = [];
    const currentDay = startDate.clone().startOf('day');
    while (currentDay.isBefore(endDate)) {
        const dayString = currentDay.format('L');
        result.push({ time: dayString.slice(0, -5), count: grouped[dayString] || 0 });
        currentDay.add(1, 'day');
    }

    return result;
};

export const groupByWeek = (timestamps: Array<number>, startDate: moment.Moment, endDate: moment.Moment) => {
    const grouped: { [week: string]: number } = {};
    timestamps.forEach(timestamp => {
        const week = moment(timestamp).format('YYYY-WW');
        grouped[week] = (grouped[week] || 0) + 1;
    });

    const result: Group[] = [];
    const currentWeek = startDate.clone().startOf('week');
    while (currentWeek.isBefore(endDate)) {
        const weekString = currentWeek.format('YYYY-WW');
        result.push({ time: currentWeek.format('MMM Do'), count: grouped[weekString] || 0 });
        currentWeek.add(1, 'week');
    }

    return result;
};

export const groupByMonth = (timestamps: Array<number>, startDate: moment.Moment, endDate: moment.Moment) => {
    const grouped: { [month: string]: number } = {};
    timestamps.forEach(timestamp => {
        const month = moment(timestamp).format('YYYY-MM');
        grouped[month] = (grouped[month] || 0) + 1;
    });

    const result: Group[] = [];
    const currentMonth = startDate.clone().startOf('month');
    while (currentMonth.isBefore(endDate)) {
        const monthString = currentMonth.format('YYYY-MM');
        result.push({ time: currentMonth.format('MMM YY'), count: grouped[monthString] || 0 });
        currentMonth.add(1, 'month');
    }

    return result;
};

export const determineGrouping = (startDate: moment.Moment, endDate: moment.Moment): Grouping => {
    const diffInDays = endDate.diff(startDate, 'days');
    if (diffInDays <= 1) return 'hour';
    if (diffInDays <= 7) return 'day';
    if (diffInDays <= 30) return 'day';
    if (diffInDays <= 60) return 'week';
    if (diffInDays >= 180) return 'month';
    return 'month';
};

export const determineFormat = (grouping: Grouping) => {
    switch (grouping) {
        case 'hour':
            return 'hA';
        case 'day':
            return 'DD/MM';
        case 'week':
            return 'MMM Do';
        case 'month':
            return 'MMM YY';
    }
};

export const groupTimestamps = (grouping: Grouping, timestamps: Array<number>, dateRange: DateRangeValue) => {
    timestamps = timestamps.sort((a, b) => b - a);
    switch (grouping) {
        case 'hour':
            return groupByHour(timestamps, moment(dateRange.start), moment(dateRange.end));
        case 'day':
            return groupByDay(timestamps, moment(dateRange.start), moment(dateRange.end));
        case 'week':
            return groupByWeek(timestamps, moment(dateRange.start), moment(dateRange.end));
        case 'month':
            return groupByMonth(timestamps, moment(dateRange.start), moment(dateRange.end));
    }
};

export const determineChipProps = (
    status: string,
    theme: Theme
): {
    color: SimplePaletteColorOptions | string;
    string: 'info' | 'warning' | 'success' | 'error' | 'default' | 'primary';
    icon?: JSX.Element;
} => {
    switch (status) {
        case 'Raw':
            return { color: theme.palette.info, string: 'info', icon: <PersonIcon /> };
        case 'Pending':
            return { color: theme.palette.warning, string: 'warning', icon: <AutorenewIcon /> };
        case 'Accepted':
            return { color: theme.palette.success, string: 'success', icon: <ThumbUpIcon /> };
        case 'Declined':
            return { color: theme.palette.error, string: 'error', icon: <ThumbDownIcon /> };
        case 'Expired':
            return { color: theme.palette.grey[500], string: 'default', icon: <HighlightOffIcon /> };
        case 'Converted':
            return { color: theme.palette.success, string: 'success', icon: <CheckIcon /> };
        case 'Active':
            return { color: theme.palette.success.light, string: 'success', icon: <CheckIcon /> };
        case 'Cancelled':
            return { color: theme.palette.error, string: 'error', icon: <DoDisturbAltIcon /> };
        default:
            return { color: theme.palette.grey[500], string: 'default' };
    }
};
