import AdsClickIcon from '@mui/icons-material/AdsClickOutlined';
import DraftsOutlined from '@mui/icons-material/DraftsOutlined';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import MarkEmailReadOutlined from '@mui/icons-material/MarkEmailReadOutlined';
import SendOutlined from '@mui/icons-material/SendOutlined';
import { GradientIcon } from '@nexdynamic/nex-ui-react';

export const SentIcon = (
    <GradientIcon gradient="info">
        <SendOutlined />
    </GradientIcon>
);

export const DeliveredIcon = (
    <GradientIcon gradient="primary">
        <MarkEmailReadOutlined />
    </GradientIcon>
);

export const OpenedIcon = (
    <GradientIcon gradient="success">
        <DraftsOutlined />
    </GradientIcon>
);

export const ClickedIcon = (
    <GradientIcon gradient="secondary">
        <AdsClickIcon />
    </GradientIcon>
);

export const FailedIcon = (
    <GradientIcon gradient="error">
        <ErrorOutline />
    </GradientIcon>
);
