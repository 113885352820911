import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Input,
    InputLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';

interface ApplicationFormData {
    fullName: string;
    email: string;
    phoneNumber: string;
    productsOrServices: string;
    sellingExperience: string;
    businessReferences: string;
    certificationsOrLicenses: string;
    sellingPlatform: string;
    customerSatisfaction: string;
    complianceAgreement: boolean;
    additionalInformation: string;
    hasCertificationsOrLicenses: boolean;
    certificationsOrLicensesDetails: string;
}

const initialFormValues: ApplicationFormData = {
    fullName: '',
    email: '',
    phoneNumber: '',
    productsOrServices: '',
    sellingExperience: '',
    businessReferences: '',
    certificationsOrLicenses: '',
    sellingPlatform: '',
    customerSatisfaction: '',
    complianceAgreement: false,
    additionalInformation: '',
    hasCertificationsOrLicenses: false,
    certificationsOrLicensesDetails: '',
};

export default function SellOnSqueegeeForm() {
    const [formData, setFormData] = useState<ApplicationFormData>(initialFormValues);
    const [hasCertificationsOrLicenses, setHasCertificationsOrLicenses] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        setTimeout(() => {
            setIsSubmitting(false);
            setIsSubmitted(true);
        }, 2000);
        // Send form data to backend for processing
        console.log(formData);
    };

    return (
        <Box padding={3}>
            {isSubmitting ? (
                <Box display='flex' justifyContent='center'>
                    <CircularProgress />
                </Box>
            ) : isSubmitted ? (
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        height: 'calc(var(--max-view-height) - 64px)',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    textAlign='center'
                >
                    <Typography variant='h5'>Application submitted.</Typography>
                    <Button href='/marketplace/home' variant='outlined' color='primary'>
                        Okay
                    </Button>
                </Box>
            ) : (
                <form onSubmit={handleSubmit}>
                    <Box marginBottom={2}>
                        <Typography variant='h5'>Squeegee Marketplace Seller Application Form</Typography>
                    </Box>

                    <Box marginBottom={2}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor='fullName'>Full Name</InputLabel>
                            <Input id='fullName' name='fullName' value={formData.fullName} onChange={handleInputChange} required />
                        </FormControl>
                    </Box>

                    <Box marginBottom={2}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor='email'>Email Address</InputLabel>
                            <Input id='email' name='email' type='email' value={formData.email} onChange={handleInputChange} required />
                        </FormControl>
                    </Box>

                    <Box marginBottom={2}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor='phoneNumber'>Phone Number</InputLabel>
                            <Input id='phoneNumber' name='phoneNumber' value={formData.phoneNumber} onChange={handleInputChange} required />
                        </FormControl>
                    </Box>

                    <Box marginBottom={2}>
                        <TextField
                            id='productsOrServices'
                            name='productsOrServices'
                            label='What type of products or services do you plan to sell on Squeegee Marketplace?'
                            multiline
                            rows={4}
                            variant='outlined'
                            value={formData.productsOrServices}
                            onChange={handleInputChange}
                            required
                            fullWidth
                        />
                    </Box>

                    <Box marginBottom={2}>
                        <TextField
                            id='sellingExperience'
                            name='sellingExperience'
                            label='Do you have any experience selling your product or services online?'
                            multiline
                            rows={4}
                            variant='outlined'
                            value={formData.sellingExperience}
                            onChange={handleInputChange}
                            required
                            fullWidth
                        />
                    </Box>
                    <Box marginBottom={2}>
                        <TextField
                            id='businessReferences'
                            name='businessReferences'
                            label='Do you have any references or links to your company website or social media pages?'
                            multiline
                            rows={4}
                            variant='outlined'
                            value={formData.businessReferences}
                            onChange={handleInputChange}
                            required
                            fullWidth
                        />
                    </Box>

                    <Box marginBottom={2}>
                        <FormControl component='fieldset' fullWidth>
                            <FormLabel component='legend'>
                                Do you have any relevant certifications or licenses for the products or services you plan to sell on
                                Squeegee Marketplace?
                            </FormLabel>
                            <RadioGroup
                                aria-label='certificationsOrLicenses'
                                name='certificationsOrLicenses'
                                value={formData.certificationsOrLicenses}
                                onChange={e => {
                                    handleInputChange(e);
                                    setHasCertificationsOrLicenses(e.target.value === 'yes');
                                }}
                            >
                                <FormControlLabel value='yes' control={<Radio />} label='Yes' />
                                <FormControlLabel value='no' control={<Radio />} label='No' />
                            </RadioGroup>
                        </FormControl>
                    </Box>

                    {hasCertificationsOrLicenses && (
                        <Box marginBottom={2}>
                            <TextField
                                id='certificationsOrLicensesDetails'
                                name='certificationsOrLicensesDetails'
                                label='Please provide details of your certifications or licenses'
                                multiline
                                rows={4}
                                variant='outlined'
                                value={formData.certificationsOrLicensesDetails}
                                onChange={handleInputChange}
                                required
                                fullWidth
                            />
                        </Box>
                    )}

                    <Box marginBottom={2}>
                        <TextField
                            id='sellingPlatform'
                            name='sellingPlatform'
                            label='Which other online selling platforms, if any, do you currently sell on?'
                            multiline
                            rows={4}
                            variant='outlined'
                            value={formData.sellingPlatform}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Box>

                    {/* <Box marginBottom={2}>
                        <FormControl component='fieldset'>
                            <Typography variant='subtitle1'>
                                On a scale of 1-5, how would you rate your overall customer satisfaction?
                            </Typography>
                            <InputLabel shrink>Customer Satisfaction</InputLabel>
                            <Box display='flex' alignItems='center'>
                                <Box flexGrow={1}>
                                    <input
                                        type='range'
                                        id='customerSatisfaction'
                                        name='customerSatisfaction'
                                        min='1'
                                        max='5'
                                        step='1'
                                        value={formData.customerSatisfaction}
                                        onChange={handleInputChange}
                                    />
                                </Box>
                                <Box marginLeft={2}>
                                    <Typography variant='h6'>{formData.customerSatisfaction}</Typography>
                                    </Box>
                            </Box>
                        </FormControl>
                    </Box> */}

                    <Box marginBottom={2}>
                        <TextField
                            id='additionalInformation'
                            name='additionalInformation'
                            label='Please provide any additional information about yourself or your business that you think would be helpful for us to know.'
                            multiline
                            rows={4}
                            variant='outlined'
                            value={formData.additionalInformation}
                            onChange={handleInputChange}
                            fullWidth
                        />
                    </Box>
                    <Box marginBottom={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id='complianceAgreement'
                                    name='complianceAgreement'
                                    checked={formData.complianceAgreement}
                                    onChange={handleInputChange}
                                    color='primary'
                                    required
                                />
                            }
                            label='I agree to comply with Squeegee Marketplace policies and guidelines.'
                        />
                    </Box>

                    <Box marginBottom={2}>
                        <Button variant='contained' color='primary' type='submit'>
                            Submit Application
                        </Button>
                    </Box>
                </form>
            )}
        </Box>
    );
}
