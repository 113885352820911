import QuickreplyIcon from '@mui/icons-material/Quickreply';
import { Box, List, Paper, Snackbar, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import type {
    AureliaReactComponentDialogComponent,
    AureliaReactComponentDialogComponentStandardProps,
} from '../../Dialogs/AureliaReactComponentDialogComponent';
import useTranslation from '../hooks/useTranslation';
import type { CannedResponse } from './CannedResponse';
import CannedResponseListItem from './CannedResponseListItem';

export type CannedResponseDialogOptions = {
    cannedResponses: Array<CannedResponse>;
};

type Props = AureliaReactComponentDialogComponentStandardProps<string> & CannedResponseDialogOptions;

const CannedResponsesDialog: AureliaReactComponentDialogComponent<CannedResponseDialogOptions> = ({
    cannedResponses,
    okCallback,
}: Props) => {
    const { t } = useTranslation();

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    const copyToClipboardAndClose = async (text: string) => {
        try {
            if (navigator.clipboard) {
                await navigator.clipboard.writeText(text);
                setSnackbarOpen(true);
                okCallback(text);
            }
            if (window.location.protocol !== 'https:' && window.location.hostname !== 'localhost') {
                // fallback for insecure environments such as localhost
                const textArea = document.createElement('textarea');
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand('copy');
                document.body.removeChild(textArea);
                setSnackbarOpen(true);
                okCallback(text);
            }
        } catch (error) {
            console.error('Failed to copy to clipboard', error);
        }
    };

    const [filteredCannedResponses, setFilteredCannedResponses] = useState<Array<CannedResponse>>(cannedResponses);

    const handleSearchQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setFilteredCannedResponses(
            cannedResponses.filter(
                cannedResponse =>
                    cannedResponse.title.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    cannedResponse.message.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    (cannedResponse.tags &&
                        cannedResponse.tags.some(tag => tag.toLowerCase().includes(event.target.value.toLowerCase()))) ||
                    false
            )
        );
    };

    return (
        <>
            <Box sx={{ m: 2 }}>
                <TextField
                    label={t('settings.canned-responses-search')}
                    variant='outlined'
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                    size='small'
                    fullWidth
                />
            </Box>
            <Paper sx={{ m: 2 }}>
                <List>
                    {filteredCannedResponses.map(cannedResponse => (
                        <CannedResponseListItem
                            key={cannedResponse.id}
                            primary={cannedResponse.title}
                            secondary={cannedResponse.message.slice(0, 50)}
                            tags={cannedResponse.tags}
                            onSelect={() => copyToClipboardAndClose(cannedResponse.message)}
                        />
                    ))}
                    {!filteredCannedResponses.length && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, alignItems: 'center', gap: 1 }}>
                            <QuickreplyIcon sx={{ fontSize: 32 }} color='disabled' />
                            <Typography>{t('settings.canned-responses-empty')}</Typography>
                        </Box>
                    )}
                </List>
            </Paper>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => {
                    setSnackbarOpen(false);
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={t('settings.canned-responses-copy-info')}
            />
        </>
    );
};

export default CannedResponsesDialog;
