import CloudOffIcon from '@mui/icons-material/CloudOff';
import { Alert, Box, Fade } from '@mui/material';
import usePollApiStatus from '../../hooks/usePollApiStatus';
import useTranslation from '../../hooks/useTranslation';
const InboxOfflineMessage = () => {
    const isOnline = usePollApiStatus();
    const { t } = useTranslation();

    if (isOnline) return null;

    return (
        <Box>
            <Fade in>
                <Alert component='div' icon={<CloudOffIcon fontSize='inherit' />} severity='warning'>
                    {t('inbox.offline_description')}
                </Alert>
            </Fade>
        </Box>
    );
};

export default InboxOfflineMessage;
