import { activationStrategy } from 'aurelia-router';
import { Availability } from './Availability';

export class AvailabilityModule {
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    availability: ReturnType<typeof Availability>;

    attached() {
        const container = document.getElementById('pricing-root');
        if (!container) throw new Error('pricing-root element not found');
        this.availability = Availability(container);
    }

    detached() {
        this.availability?.unmount();
    }
}
