import type { PriceChangeNearest, PriceChangeType } from '@nexdynamic/squeegee-common';
import { PriceChangeTypeValues } from '@nexdynamic/squeegee-common';
import type { SchemaOf } from 'yup';
import { mixed, number, object, string } from 'yup';
import { t } from '../../../../../../t';
import type { ChangeFields } from './ChangeFields';

export const ChangeFormSchema = () => {
    const schema: SchemaOf<ChangeFields> = object({
        id: string().optional(),
        name: string().required(t('bulk-price-change.name-required')),
        description: string(),
        plannedDate: number().required(),
        type: mixed<PriceChangeType>()
            .required()
            .oneOf<PriceChangeType>([...PriceChangeTypeValues]),
        value: number().required(),
        nearest: mixed<PriceChangeNearest>().when('type', {
            is: 'percentage',
            then: number()
                .required()
                .oneOf([0.01, 0.1, 0.25, 0.5, 1] as Array<PriceChangeNearest>),
        }),
    });
    return schema;
};
