import type { DateRangeOption } from '@nexdynamic/nex-ui-react';
import moment from 'moment';
export const analyticsRanges: Array<DateRangeOption> = [
    {
        group: '',
        label: 'Today',
        start: moment().startOf('d').valueOf(),
        end: moment().endOf('d').valueOf(),
    },
    {
        group: '',
        label: 'Last 7 days',
        start: moment().subtract(6, 'days').valueOf(),
        end: moment().valueOf(),
    },
    {
        group: '',
        label: 'Last 30 days',
        start: moment().subtract(30, 'days').valueOf(),
        end: moment().valueOf(),
    },
    {
        group: '',
        label: 'Last 12 months',
        start: moment().subtract(12, 'months').valueOf(),
        end: moment().valueOf(),
    },
];
