import moment from 'moment';
import { useEffect, useState } from 'react';
import { refreshLectureCache } from '../funcs/refreshLectureCache';
import type { Lecture } from '../Views/TrainingView';

export type SubjectLectures = {
    [subject: string]: Array<Lecture>;
};

export type LectureData = {
    lectures: Array<Lecture>;
    lecturesBySubject: SubjectLectures;
};

export const useLectures = (): LectureData => {
    const [lectures, setLectures] = useState<Array<Lecture>>([]);
    const [lecturesBySubject, setLecturesBySubject] = useState<SubjectLectures>({});

    const getSubject = (lecture: Lecture) => {
        return lecture.lesson.replace(/[^a-zA-Z0-9]/g, '-').trim();
    };

    useEffect(() => {
        const initLectures = async () => {
            const lectureCacheJson = localStorage.getItem('squeegee-training-videos');
            const lectureCache: { created: string; lectures: Array<Lecture> } =
                lectureCacheJson &&
                (() => {
                    try {
                        return JSON.parse(lectureCacheJson);
                    } catch {
                        return null;
                    }
                })();

            if (!lectureCache) {
                await refreshLectureCache([]);
            } else {
                setLectures(lectureCache.lectures);
                console.log(lectures);
                if (moment().subtract(1, 'day').format('YYYY-MM-DD') > lectureCache.created) {
                    await refreshLectureCache(lectureCache.lectures);
                }
                const newLecturesBySubject: { [subject: string]: Lecture[] } = {};
                for (const lecture of lectureCache.lectures) {
                    const subject = getSubject(lecture);
                    if (!newLecturesBySubject[subject]) newLecturesBySubject[subject] = [];
                    newLecturesBySubject[subject].push(lecture);
                }

                setLecturesBySubject(newLecturesBySubject);
            }
        };

        initLectures();
    }, []);

    return { lectures, lecturesBySubject };
};
