import { Box, Button, List, Skeleton, Stack } from '@mui/material';
import { TransactionItem, useTransactions } from '@nexdynamic/balance-components-react';
import { EmptyList } from '@nexdynamic/nex-ui-react';
import moment from 'moment';
import { ApplicationState } from '../../../ApplicationState';
import Text from '../../components/Text';
import useTranslation from '../../hooks/useTranslation';
import { getSupplierLedgerId } from '../SupplierService';

type Props = {
    supplierId: string;
};

const SupplierRecentTransactions = ({ supplierId }: Props) => {
    const ledgerId = getSupplierLedgerId(supplierId);
    const { t } = useTranslation();

    const viewAllTransactions = () => {
        ApplicationState.navigateToRouteFragment(`/balance/transactions?ledgerId=${ledgerId}`);
    };

    const { transactions, loading } = useTransactions({
        dateRangeQuery: [
            {
                start: moment().subtract(1, 'year').toDate(),
                end: moment().toDate(),
            },
        ],
        ledgerIdQuery: [ledgerId],
        searchQuery: [null],
        offsetQuery: [0],
        rowsQuery: [10],
        groupingQuery: ['day'],
        sortQuery: ['newest-to-oldest'],
        page: 0,
    });

    const Content = () => {
        if (loading) {
            return (
                <Stack gap={1}>
                    <Skeleton variant="rectangular" height={50} />
                    <Skeleton variant="rectangular" height={50} />
                    <Skeleton variant="rectangular" height={50} />
                </Stack>
            );
        }
        if (transactions.length === 0) {
            return <EmptyList text={t('supplier.no-transactions')} />;
        }

        return (
            <List>
                {transactions.map(transaction => (transaction ? <TransactionItem transaction={transaction} key={transaction.id} /> : null))}
            </List>
        );
    };

    return (
        <>
            <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
                <Box sx={{ flex: 1 }}>
                    <Text text="supplier.recent-transactions" variant="h6" mb={1} />
                </Box>
                {transactions.length > 0 && (
                    <Button variant="outlined" size="small" onClick={viewAllTransactions}>
                        <Text text="supplier.view-all-transactions" />
                    </Button>
                )}
            </Box>

            <Content />
        </>
    );
};

export default SupplierRecentTransactions;
