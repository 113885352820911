import { useState } from 'react';
import { publishAdvertisement } from '../../../services/AdvertisementService';
import AlertDialog from './AlertDialog';

type PublishDialogProps = {
    id: string;
    onSuccess: () => void;
    onCancel: () => void;
};

export default function PublishAdvertAlertDialog({ id, onSuccess, onCancel }: PublishDialogProps) {
    const [accessDeniedError, setAccessDeniedError] = useState('');

    const handlePublish = () => {
        publishAdvertisement(id)
            .then(onSuccess)
            .catch(error => setAccessDeniedError(JSON.parse(error.message).message));
    };

    return (
        <div>
            <AlertDialog
                title="Publish advert?"
                content="Are you sure you want to list this item on the Squeegee Marketplace?"
                okText="Yes"
                onOk={handlePublish}
                onCancel={onCancel}
                error={accessDeniedError}
            />
        </div>
    );
}
