import { Box, Grid, Paper } from '@mui/material';
import type { BulkPriceChange } from '@nexdynamic/squeegee-common';
import AudienceFiltersPreview from '../../../../../send/features/audiences/component/filters/AudienceFiltersPreview';

export const ChangeResultSuccess = ({ change }: { change: BulkPriceChange }) => {
    if (!change.affectedJobFilter) return null;

    return (
        <Box>
            {change.affectedJobFilter && (
                <Grid item xs={12}>
                    <Paper>
                        <Box p={3}>
                            <AudienceFiltersPreview
                                filterGroups={change.affectedJobFilter}
                                audienceDescription='bulk-price-change.affected-jobs-title'
                                audienceNoResultsDescription='bulk-price-change.affected-jobs-no-results'
                            />
                        </Box>
                    </Paper>
                </Grid>
            )}
        </Box>
    );
};
