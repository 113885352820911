import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import { Box, Button, Fade, IconButton, Stack, Step, StepLabel, Typography } from '@mui/material';
import { DialogPaper, GradientStepper } from '@nexdynamic/nex-ui-react';
import type { EmailEngineIMAPConfig, EmailEngineSMTPConfig } from '@nexdynamic/squeegee-common';
import { wait } from '@nexdynamic/squeegee-common';
import { useEffect, useState } from 'react';
import { useMailServer } from '../../provider/MailServerProvider';
import { useEmailSettingsViewRouter } from '../../useEmailSettingsViewRouter';
import { StepIcon } from './StepIcon';
import MailServerStep from './steps/MailServerStep';
import ValidatingStep from './steps/ValidatingStep';

export const enum MailServerSetupStep {
    SMTP = 0,
    VALIDATING_SMTP = 1,
    IMAP = 2,
    VALIDATING_IMAP = 3,
}

const MailServerSetupWizard = () => {
    const { navigateTo, navigateBack } = useEmailSettingsViewRouter();
    const { setMailServerSettings, verifySmtpImapSettings, verifyingSmtpImapSettings } = useMailServer();

    const [validating, setValidating] = useState(false);

    const [mailServerSmtpFormValues, setMailServerSmtpFormValues] = useState<EmailEngineSMTPConfig>();
    const [mailServerImapFormValues, setMailServerImapFormValues] = useState<EmailEngineIMAPConfig>();

    useEffect(() => {
        setVerified({ ...verified, smtp: false });
    }, [mailServerSmtpFormValues]);

    useEffect(() => {
        setVerified({ ...verified, imap: false });
    }, [mailServerImapFormValues]);

    const [activeStep, setActiveStep] = useState(0);

    const handleNext = (currentStep = activeStep) => {
        const nextStep = currentStep + 1;
        if (nextStep === 4) {
            navigateBack();
            return;
        }
        setActiveStep(nextStep);
        return nextStep;
    };

    const handleBack = (currentStep = activeStep) => {
        if (currentStep === MailServerSetupStep.SMTP) return;
        setActiveStep(currentStep - 1);
    };

    const [verified, setVerified] = useState({
        smtp: false,
        imap: false,
    });

    const handleValidate = async () => {
        let protocol: 'smtp' | 'imap';
        if (activeStep === MailServerSetupStep.SMTP) protocol = 'smtp';
        else if (activeStep === MailServerSetupStep.IMAP) protocol = 'imap';
        else return;

        // Go to validating step
        const currentStep = handleNext();

        const mailServerFormValues = protocol === 'smtp' ? mailServerSmtpFormValues : mailServerImapFormValues;
        const values = { [protocol]: mailServerFormValues };
        setMailServerSettings(values);
        setValidating(true);
        const success = await verifySmtpImapSettings(values);
        setVerified({ ...verified, [protocol]: success });
        setValidating(false);
        await wait(1000);
        if (success) handleNext(currentStep);
        else handleBack(activeStep + 1);
    };

    return (
        <>
            <DialogPaper sx={{ p: 2 }}>
                <Stack direction="row" alignItems="center" spacing={1} mb={3}>
                    <IconButton onClick={() => navigateTo('email-settings')} disabled={verifyingSmtpImapSettings}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" gutterBottom>
                        Mail Server Setup Wizard
                    </Typography>
                </Stack>
                <GradientStepper color="secondary" activeStep={activeStep}>
                    <Step index={0}>
                        <StepLabel StepIconComponent={StepIcon}>Configure SMTP</StepLabel>
                    </Step>
                    <Step index={2}>
                        <StepLabel StepIconComponent={StepIcon}>Configure IMAP</StepLabel>
                    </Step>
                </GradientStepper>

                <Box py={2}>
                    {/* WTF: Do NOT remove the <Box> tags, they are required for the Fade animation to work */}
                    {activeStep === MailServerSetupStep.SMTP && (
                        <Fade in={true}>
                            <Box>
                                <MailServerStep protocol="smtp" setExternalValues={setMailServerSmtpFormValues} />
                            </Box>
                        </Fade>
                    )}
                    {activeStep === MailServerSetupStep.VALIDATING_SMTP && (
                        <Fade in={true}>
                            <Box>
                                <ValidatingStep protocol="smtp" validating={validating} success={verified.smtp} />
                            </Box>
                        </Fade>
                    )}
                    {activeStep === MailServerSetupStep.IMAP && (
                        <Fade in={true}>
                            <Box>
                                <MailServerStep protocol="imap" setExternalValues={setMailServerImapFormValues} />
                            </Box>
                        </Fade>
                    )}
                    {activeStep === MailServerSetupStep.VALIDATING_IMAP && (
                        <Fade in={true}>
                            <Box>
                                <ValidatingStep protocol="imap" validating={validating} success={verified.imap} />
                            </Box>
                        </Fade>
                    )}
                </Box>

                <Box display="flex" justifyContent="flex-end" gap={1}>
                    <Button onClick={() => handleBack()} disabled={activeStep === MailServerSetupStep.SMTP || validating}>
                        Back
                    </Button>
                    {activeStep !== MailServerSetupStep.VALIDATING_IMAP && (
                        <Button onClick={handleValidate} disabled={validating}>
                            Validate {activeStep === MailServerSetupStep.SMTP ? 'SMTP' : 'IMAP'} Settings
                        </Button>
                    )}
                </Box>
            </DialogPaper>
        </>
    );
};

export default MailServerSetupWizard;
