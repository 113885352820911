import { Box, Paper, Stack, Typography } from '@mui/material';
import type React from 'react';

interface CalendarLayoutProps {
    children: React.ReactNode;
    workDayStart: Date;
    workDayEnd: Date;
}

const CalendarLayout: React.FC<CalendarLayoutProps> = ({ children, workDayStart, workDayEnd }) => {
    const startHour = workDayStart.getHours();
    const endHour = workDayEnd.getHours();
    const workDayHours = endHour - startHour;

    const hours = Array.from({ length: workDayHours }, (_, i) => i + startHour);
    const subdivisions = Array.from({ length: workDayHours * 4 }, (_, i) => i + startHour * 4);

    const xAxisStyles = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        borderTop: '1px solid #ccc',
        borderBottom: '1px solid #ccc',
    };

    const hourStyles = {
        flex: 1,
        textAlign: 'center',
        paddingLeft: 1,
        paddingRight: 1,
    };

    return (
        <>
            <Paper
                id="calendar-grid"
                sx={{
                    'display': 'flex',
                    'flexDirection': 'column',
                    'position': 'relative',
                    'flex': 1,
                    'overflowY': 'scroll',
                    'scrollbarWidth': 'none',
                    'msOverflowStyle': 'none',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    'borderRadius': 0,
                    'backgroundColor': '#FDFDFD',
                }}
            >
                <Stack direction="row" sx={{ bgcolor: 'white' }}>
                    <Box
                        id="static-column"
                        sx={{
                            ...xAxisStyles,
                            minWidth: '115px',
                            maxWidth: '115px',
                            zIndex: theme => theme.zIndex.drawer - 1,
                        }}
                    >
                        <Typography
                            variant="body2"
                            sx={{
                                ...hourStyles,
                                boxShadow: 'none',
                            }}
                        >
                            Workers
                        </Typography>
                    </Box>
                    <Box id="x-axis" sx={{ ...xAxisStyles, boxShadow: 2 }}>
                        {hours.map(hour => (
                            <Typography key={hour} variant="body2" sx={hourStyles}>
                                {hour.toString().padStart(2, '0')}:00
                            </Typography>
                        ))}
                    </Box>
                </Stack>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '115px',
                        height: '100%',
                        boxShadow: 2,
                        background: theme => theme.palette.background.paper,
                        zIndex: theme => theme.zIndex.drawer - 2,
                    }}
                />
                <Box
                    id="hours"
                    sx={{
                        position: 'absolute',
                        minWidth: `calc(${(100 / workDayHours) * workDayHours}% - 115px)`,
                        right: `0px`,
                        height: '100%',
                    }}
                >
                    {hours.map(hour => (
                        <Box
                            key={hour}
                            sx={{
                                position: 'absolute',
                                left: `calc(${((hour - startHour) / workDayHours) * 100}%)`,
                                height: '100%',
                                width: '1px',
                                backgroundColor: '#ccc',
                            }}
                        />
                    ))}
                    {subdivisions.map(subdivision => (
                        <Box
                            key={subdivision}
                            sx={{
                                position: 'absolute',
                                left: `calc(${((subdivision - startHour) / workDayHours) * 100}%)`,
                                height: '100%',
                                width: '1px',
                                backgroundColor: '#ccc',
                            }}
                        />
                    ))}
                </Box>
                <Box
                    id="container"
                    position="relative"
                    sx={{
                        'width': '100%',
                        'ml': 'auto',
                        'overflow': 'auto',
                        'scrollbarWidth': 'none',
                        'msOverflowStyle': 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}
                >
                    <Box
                        id="time-blocks-container"
                        position="relative"
                        width="{`calc(
                        ${(100 / (workDayHours)) * workDayHours}%)`}"
                        height="100%"
                        marginLeft="auto"
                    >
                        {children}
                    </Box>
                </Box>
            </Paper>
        </>
    );
};

export default CalendarLayout;
