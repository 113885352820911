import { Box } from '@mui/system';
import type { IAdvertisement } from '@nexdynamic/squeegee-common';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MyAdvertDetails2 from '../components/dialogs/MyAdvertDetails2';
import MarketplaceNavbar from '../components/MarketplaceNavbar';
import { MyAdvertList } from '../components/MyAdvertList';
import useMyAdverts from '../hooks/useMyAdverts';

export type FilterAdvertsQuery = {
    selectedSellers?: Array<string>;
    tags?: Array<string>;
    keyword?: string;
};

export const MyAdverts = () => {
    const [query, setQuery] = useState<FilterAdvertsQuery>({});
    const { adverts, refetchAdverts } = useMyAdverts(query);
    const rawAdverts = useMyAdverts().adverts;
    const [selectedAdvert, setSelectedAdvert] = useState<IAdvertisement | null>(null);

    const refreshAdverts = () => {
        refetchAdverts();
    };

    const cancelSearch = () => {
        setQuery({
            ...query,
            keyword: '',
        });
    };

    const onQueryChange = (newQuery: FilterAdvertsQuery) => {
        const updatedQuery = {
            ...query,
            ...newQuery,
        };
        setQuery(updatedQuery);
    };

    const navigate = useNavigate();

    const handleClose = () => {
        navigate('/marketplace/my-adverts');
        setSelectedAdvert(null);
    };

    const handleSelect = (selectedAdvert: IAdvertisement) => {
        navigate(`/marketplace/my-adverts/${selectedAdvert.id}`);
    };

    const handleSaved = () => {
        handleClose();
        refreshAdverts();
    };
    const params = useParams();
    useEffect(() => {
        const id = params['id'] as string;
        if (id) {
            const advert = adverts.find(advert => advert.id === id);

            if (advert) {
                setSelectedAdvert(advert);
            }
        }
    });

    return (
        <Box>
            <MarketplaceNavbar
                allData={rawAdverts}
                refresh={refreshAdverts}
                cancelSearch={cancelSearch}
                requestSearch={onQueryChange}
                searched={query.keyword || ''}
            />

            <MyAdvertList onSelect={handleSelect} adverts={adverts}></MyAdvertList>

            {selectedAdvert && (
                <MyAdvertDetails2 onSaved={handleSaved} onClose={handleClose} open={true} advert={selectedAdvert}></MyAdvertDetails2>
            )}
        </Box>
    );
};
