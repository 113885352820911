import type { TimeEntry } from '@nexdynamic/squeegee-common';
import { groupStoredEventsIntoTimeEntries } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import useStoredEvents from './useStoredEvents';

type Options = {
    paging?: { take: number; skip: number };
    date?: string;
    occurrenceId?: string;
    customerId?: string;
    jobId?: string;
    allocateTimeTo?: string;
    dateRange?: {
        start: string;
        end: string;
    };
};

type UseTimeEntries = {
    items: Array<TimeEntry>;
    count: number;
};

export const getDateRangeByWeek = (rawDate: string) => {
    return { start: moment(rawDate).startOf('week').toISOString(), end: moment(rawDate).endOf('week').toISOString() };
};

const useTimeEntries = ({ dateRange, date, occurrenceId, allocateTimeTo, jobId, customerId, paging }: Options): UseTimeEntries => {
    const [items, setItems] = useState<Array<TimeEntry>>([]);
    const storedEvents = useStoredEvents({ date, dateRange, occurrenceId, allocateTimeTo, jobId, customerId });
    const filteredItems = useMemo(() => groupStoredEventsIntoTimeEntries(storedEvents), [storedEvents]);

    useEffect(() => {
        if (paging?.take) {
            setItems(filteredItems.slice(paging?.skip, paging?.skip + paging?.take));
        } else {
            setItems(filteredItems);
        }
    }, [paging?.take, paging?.skip, filteredItems]);

    return {
        items,
        count: filteredItems.length,
    };
};

export default useTimeEntries;
