import type { AccountUser, TimeEntry as DefaultTimeEntry } from '@nexdynamic/squeegee-common';
import type { TimeEntry, Worker } from '../data/types';

export function timedEventsToTimeEntries(timeEntries: DefaultTimeEntry[], _id?: string | number) {
    if (_id === undefined) {
        return (
            timeEntries
                .map(entry => {
                    const { start, stop, assigneeId, occurrenceId, type } = entry;

                    const startTime = start ? new Date(start.eventTimestamp) : new Date();
                    const endTime = stop ? new Date(stop.eventTimestamp) : new Date();
                    const duration = start && stop ? stop.eventTimestamp - start.eventTimestamp : undefined;

                    const timeEntry: TimeEntry = {
                        id: occurrenceId || assigneeId,
                        type: type,
                        workerId: assigneeId,
                        startTime,
                        endTime,
                        duration,
                        originalTimeEntry: entry,
                    };

                    return timeEntry;
                })
        )
    } else {
        return (
            timeEntries
                .filter(entry => entry.assigneeId === _id)
                .map(entry => {
                    const { start, stop, assigneeId, occurrenceId, type } = entry;

                    const startTime = start ? new Date(start.eventTimestamp) : new Date();
                    const endTime = stop ? new Date(stop.eventTimestamp) : new Date();
                    const duration = start && stop ? stop.eventTimestamp - start.eventTimestamp : undefined;

                    const timeEntry: TimeEntry = {
                        id: occurrenceId || assigneeId,
                        type: type,
                        workerId: assigneeId,
                        startTime,
                        endTime,
                        duration,
                        originalTimeEntry: entry,
                    };

                    return timeEntry;
                })
        );
    }
}


function convertAccountUserToWorker(accountUser: AccountUser, timeEntries: DefaultTimeEntry[] = []): Worker {
    const { _id, name } = accountUser;
    const workerTimeEntries = timedEventsToTimeEntries(timeEntries, _id);

    const worker: Worker = {
        id: _id,
        name: name,
        timeEntries: workerTimeEntries,
        avatar: accountUser.avatar,
    };
    return worker;
}

export function convertAccountUsersToWorkers(accountUsers: AccountUser[], timeEntries: DefaultTimeEntry[] = []): Worker[] {
    const workers: Worker[] = [];

    for (const accountUser of accountUsers) {
        const worker = convertAccountUserToWorker(accountUser, timeEntries);
        workers.push(worker);
    }
    return workers.sort((a, b) => a.name.localeCompare(b.name));
}
