import type { IQuoteItem, Quote } from '@nexdynamic/squeegee-common';
import { FrequencyBuilder, copyObject, to2dp } from '@nexdynamic/squeegee-common';
import type { UtmParams } from '@nexdynamic/squeegee-portal-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { Data } from '../../Data/Data';
import { AureliaReactComponentDialog } from '../../Dialogs/AureliaReactComponentDialog';
import { GlobalFlags } from '../../GlobalFlags';
import type { UtmParamsDialogOptions } from '../../ReactUI/dialogs/CustomerDialog/UtmParamsDialog';
import UtmParamsDialog from '../../ReactUI/dialogs/CustomerDialog/UtmParamsDialog';
import { QuoteActions } from '../QuoteActions';

export class QuoteSummaryCustomElement {
    @bindable() quote: Quote;
    protected isMobile = GlobalFlags.isMobileApp;
    protected items: Array<{ key: string; value: IQuoteItem[]; subtotal: number; uniqueAddress?: string }> = [];
    markAsSent = () => QuoteActions.markAsSent(this.quote);

    markAsUnsent = () => QuoteActions.markAsUnsent(this.quote);

    // refreshes the grouped items when the quote changes to update ui
    quoteChanged() {
        this.attached();
    }

    protected updateGroupedItems() {
        const getSubtotal = (quoteItem: IQuoteItem) => {
            const taxRate = quoteItem.taxRate || this.quote.taxRate;
            if (!taxRate) return quoteItem.total;

            if (this.quote.priceIncludesTax) {
                return to2dp(quoteItem.total / (1 + taxRate));
            }

            return quoteItem.total;
        };

        this.items = Object.entries(QuoteActions.groupItems(copyObject(this.quote.items))).map(([key, value]) => {
            const subtotal = value.reduce((acc, item) => acc + getSubtotal(item), 0);
            const subtotalTax = this.quote.taxRate ? to2dp(subtotal * this.quote.taxRate) : 0;

            return {
                key,
                value,
                subtotal,
                subtotalTax,
                uniqueAddress: '',
            };
        });
    }

    attached() {
        if (!this.quote) {
            throw new Error('QuoteSummaryCustomElement requires a quote');
        }

        this.updateGroupedItems();
        this.getUniqueAddressForItem();
    }

    @computedFrom('quote')
    get taxRate() {
        if (this.quote && this.quote.taxRate !== undefined) {
            return to2dp(this.quote.taxRate * 100);
        }
    }

    quoteItemHasUniqueAddress(quoteItem: IQuoteItem) {
        return quoteItem.address?.addressDescription && quoteItem.address.addressDescription !== this.quote.address.addressDescription;
    }

    protected getUniqueAddressForItem() {
        for (const item of this.items) {
            for (const quoteItem of item.value) {
                if (!this.quoteItemHasUniqueAddress(quoteItem)) continue;

                item.uniqueAddress = quoteItem.address?.addressDescription || '';
            }
        }
    }

    quoteItemFrequency(quoteItem: IQuoteItem) {
        if (!quoteItem.frequency) {
            return '';
        }
        const freq = FrequencyBuilder.getFrequencyFromDateAndFrequencyData(quoteItem.frequency);

        if (quoteItem.frequency) {
            return ApplicationState.localise(freq.localisationKeyAndParams.key, freq.localisationKeyAndParams.params);
        }
        return '';
    }
    protected openQuoteUtmParams = async () => {
        const quoteUtmParamsDialog = new AureliaReactComponentDialog<UtmParams, UtmParamsDialogOptions>({
            component: UtmParamsDialog,
            componentProps: {
                utmParams: this.quote.utmParams,
            },
            dialogTitle: 'customer-dialog.utm-params-title',
            isSecondaryView: true,
        });

        const utmParams = await quoteUtmParamsDialog.show();

        if (quoteUtmParamsDialog.cancelled) return;

        if (utmParams) {
            this.quote.utmParams = utmParams;
            Data.put(this.quote);
        }
    };
}
