import { Box, Grid, Paper } from '@mui/material';
import { FormStep } from '@nexdynamic/nex-ui-react';
import { Form, Formik } from 'formik';
import React from 'react';
import useTranslation from '../../../../hooks/useTranslation';
import TemplateSMSInput from '../components/TemplateSMSInput';
export class TemplateSmsFormFields {
    constructor(public smsTemplate = '', public includeLink = true, public useSubjectAsSmsMessage = true) {}
}

type TemplateSmsFormProps = {
    initialValues: TemplateSmsFormFields;
    onSubmit: (fields: TemplateSmsFormFields) => Promise<void>;
    subject: string;
};

export const TemplateSmsForm = React.forwardRef<HTMLFormElement, TemplateSmsFormProps>(({ initialValues, onSubmit, subject }, ref) => {
    const { t } = useTranslation();

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={values => {
                return onSubmit(values);
            }}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Form autoComplete="off" ref={ref}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={2}>
                                            <FormStep
                                                fields={['smsTemplate']}
                                                title={t('templates-form.smsStepTitle')}
                                                description={t('templates-form.smsStepDescription')}
                                            >
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                                    <Box sx={{ flex: 1, minWidth: '100%' }}>
                                                        <TemplateSMSInput subject={subject} fieldName="smsTemplate" />
                                                    </Box>
                                                </Box>
                                            </FormStep>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Form>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
});
