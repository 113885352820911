import { Typography } from '@mui/material';
import { Suspense } from 'react';
import { Outlet, Route, Routes } from 'react-router';
import ViewLoading from '../../components/ViewLoading';

import AudiencesRoutes from '../features/audiences/AudiencesRoutes';
import CampaignsRoutes from '../features/campaigns/CampaignsRoutes';
import TemplatesRoutes from '../features/templates/TemplatesRoutes';

export const SendMain = () => {
    return (
        <Suspense fallback={<ViewLoading />}>
            <Outlet />
            <Routes>
                <Route path='/campaigns/*' element={<CampaignsRoutes />} />
                <Route path='/audiences/*' element={<AudiencesRoutes />} />
                <Route path='/templates/*' element={<TemplatesRoutes />} />

                <Route
                    path='*'
                    element={
                        <Typography variant='h6' textAlign='center'>
                            Error Page Not Found
                        </Typography>
                    }
                />
            </Routes>
        </Suspense>
    );
};

export default SendMain;
