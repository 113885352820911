import type { SxProps } from "@mui/material";
import { Box } from "@mui/material";
import Card from "../styled-components/Card";
import { BareComparisonChart } from "./base/BaseComparisonChart";
import ChartHeader from "./ChartHeader";

export type ComparisonChartData = {
  monthTotal: string | number;
  yearTotal: string | number;
  chartData: {
    categories: Array<any>;
    series: ApexAxisChartSeries;
  };
};

type ComparisonChartProps = {
  title: string;
  subheading: string;
  currentValue: string | number;
  data: ComparisonChartData;
  sx?: SxProps;
  strokeColor: string;
  width?: string | number;
  height?: string | number;
  onRemove?: () => void;
};

const ComparisonChart = ({
  title,
  subheading,
  currentValue,
  strokeColor,
  data,
  width,
  height,
  sx = {},
  onRemove,
}: ComparisonChartProps) => {
  return (
    <Card sx={sx} width={width} height={height}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <ChartHeader
          strokeColor={strokeColor}
          currentValue={currentValue}
          title={title}
          subheading={subheading}
          onRemove={onRemove}
        />

        <Box sx={{ flex: 1, height: "calc(100% - 100px)" }}>
          <BareComparisonChart
            strokeColor={strokeColor}
            categories={data.chartData.categories}
            series={data.chartData.series}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default ComparisonChart;
