import { FormControlLabel, Switch } from '@mui/material';

export const ToggleShowMobileDevicesOnly = ({
    showMobileOnlyChanged,
    checked,
}: {
    showMobileOnlyChanged: (showOnMobileOnly: boolean) => void;
    checked: boolean;
}) => {
    return (
        <FormControlLabel
            control={<Switch checked={checked} onChange={(_e, checked) => showMobileOnlyChanged(checked)} />}
            label='Show Mobile Devices Only'
        />
    );
};
