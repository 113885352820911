import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import type { IAdvertisementListing } from '@nexdynamic/squeegee-common';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import type React from 'react';
import { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { StripeUtils } from '../../../Stripe/StripeUtils';
import { getSecretForPaymentIntent } from '../services/AdvertisementService';
import TermsAndConditions from './TermsAndConditions';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const stripePromise = loadStripe(StripeUtils.squeegeePublishableKey);

export const AdvertCheckout = ({
    advert,
    onSuccess,
    onCancel,
}: {
    advert: IAdvertisementListing;
    onSuccess: () => void;
    onCancel: () => void;
}) => {
    const [clientSecret, setClientSecret] = useState('');
    const [termsHtml, setTermsHtml] = useState('');
    const [error, setError] = useState('');
    const expiresFrom = Date.now() + 1000 * 60 * 3;
    const [expired, setExpired] = useState(false);
    useEffect(() => {
        if (clientSecret) return;
        getSecretForPaymentIntent(advert.id)
            .then(res => {
                setClientSecret(res?.client_secret || '');
                setTermsHtml(res?.termsHtml || '');
            })
            .catch(err => {
                setError(err.message);
            });
    });

    if (error) return <Box p={1}>{error}</Box>;

    if (!clientSecret) return <div>Please wait loading...</div>;

    const renderer = ({ minutes, seconds, completed }: { minutes: number; seconds: number; completed: boolean }) => {
        if (completed) {
            setExpired(true);
            // Render a completed state
            return <span>Expired</span>;
        } else {
            // Render a countdown
            return (
                <span>
                    Expires in {minutes} minutes {seconds} seconds
                </span>
            );
        }
    };

    return (
        <div>
            <Typography variant='h5' textAlign={'center'} color={'red'}>
                <Countdown date={expiresFrom} renderer={renderer}></Countdown>
            </Typography>
            {(!expired && clientSecret && (
                <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
                    <CheckoutForm termsHtml={termsHtml} onSuccess={onSuccess} onCancel={onCancel} />
                </Elements>
            )) || <div>loading...</div>}
        </div>
    );
};

const CheckoutForm = ({ termsHtml, onSuccess, onCancel }: { termsHtml: string; onSuccess: () => void; onCancel: () => void }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState('');
    const [accepted, setAccepted] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleCancel = async (event: React.MouseEvent) => {
        event.preventDefault();

        onCancel();
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        if (isProcessing) return;
        setIsProcessing(true);
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            redirect: 'if_required',
            confirmParams: {
                return_url: window.location.href + '/complete',
            },
        });
        setIsProcessing(false);
        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            setErrorMessage(result.error.message || 'Unknown error');
        } else {
            onSuccess();
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <Box padding={2}>
            <form onSubmit={handleSubmit}>
                <TermsAndConditions
                    acceptanceChanged={accepted => {
                        setAccepted(accepted);
                    }}
                    htmlText={termsHtml}
                />
                <PaymentElement />

                {errorMessage && <div>{errorMessage}</div>}
                <Divider sx={{ marginY: '0.5rem', borderRadius: '2px' }} />
                {(isProcessing && <CircularProgress />) || (
                    <Box sx={{ display: 'flex', ml: 'auto', gap: '10px' }}>
                        <Button sx={{ paddingY: 0.5, color: '#696969' }} onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button type='submit' disabled={!accepted || !stripe}>
                            Pay
                        </Button>
                    </Box>
                )}
            </form>
        </Box>
    );
};

export default CheckoutForm;
