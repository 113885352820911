import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
interface DeleteTimeEntryAlertProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
}
const DeleteTimeEntryAlert = ({ open, onClose, onConfirm }: DeleteTimeEntryAlertProps) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Delete Time Entry</DialogTitle>
            <DialogContent>
                <DialogContentText>Are you sure you want to delete this time entry?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onConfirm} variant='text' color='error'>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default DeleteTimeEntryAlert;
