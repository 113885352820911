import type { LayoutsByViewmode } from '@nexdynamic/squeegee-common';
import { uuid, type StoredLayout } from '@nexdynamic/squeegee-common';

export type OldStoredLayout = {
    name: string;
    layoutsByViewmode: LayoutsByViewmode;
    settings?: any;
};

const migrateOldLayouts = (layouts: Array<OldStoredLayout>): Array<StoredLayout> => {
    const newLayouts: Array<StoredLayout> = [];
    for (const layout of layouts) {
        const newLayout: StoredLayout = {
            id: uuid(),
            name: layout.name + ' (migrated)',
            layout: layout.layoutsByViewmode.sm,
        };
        newLayouts.push(newLayout);
    }
    console.log('migrated old layouts', newLayouts);
    return newLayouts;
};

export default migrateOldLayouts;
