import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ViewLoading from '../components/ViewLoading';
import { TrainingView } from './Views/TrainingView';

export const TrainingApp = () => {
    return (
        <Suspense fallback={<ViewLoading />}>
            <Routes>
                <Route path="/training/*" element={<TrainingView />}></Route>
            </Routes>
        </Suspense>
    );
};
