import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type AlertDialogProps = {
    title: string;
    content: string;
    okText?: string;
    cancelText?: string;
    hideCancel?: boolean;
    onOk: () => void;
    onCancel?: () => void;
    error?: string;
    errorAction?: JSX.Element;
};

export default function AlertDialog({
    title,
    content,
    okText,
    cancelText,
    onOk,
    onCancel,
    hideCancel,
    error,
    errorAction,
}: AlertDialogProps) {
    const handleCancel = () => {
        onCancel && onCancel();
    };
    const handleOK = () => {
        onOk();
    };

    return (
        <div>
            <Dialog open={true} onClose={handleCancel}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                    {error && (
                        <DialogContentText color="error" id="alert-dialog-error" sx={{ whiteSpace: 'pre-wrap' }}>
                            {error}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    {error && errorAction}
                    {!hideCancel && <Button onClick={handleCancel}>{cancelText ? cancelText : 'Cancel'}</Button>}
                    <Button onClick={handleOK}>{okText ? okText : 'OK'}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
