import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';

const useTranslation = () => {
    const t = (key: TranslationKey, params?: Record<string, string>) => {
        return ApplicationState.localise(key as TranslationKey, params);
    };

    return { t };
};

export default useTranslation;
