import type { Audience, AudienceCreateInput } from '@nexdynamic/squeegee-common';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import useTranslation from '../../../../hooks/useTranslation';
import { audienceCreate } from '../AudienceService';
import type { AudienceFormFields } from '../component/AudienceForm';
type UseCreateAudience = () => {
    running: boolean;
    createAudience: (fields: AudienceFormFields) => Promise<Audience | null | undefined>;
};

export const toAudienceCreateInput = (fields: AudienceFormFields): AudienceCreateInput => {
    return {
        name: fields.name,
        description: fields.description || '',
        filterGroups: fields.filterGroups,
    };
};

const useCreateAudience: UseCreateAudience = () => {
    const [running, setRunning] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const createAudience = async (fields: AudienceFormFields) => {
        try {
            setRunning(true);
            const audience = await audienceCreate(toAudienceCreateInput(fields));
            enqueueSnackbar(t('audiences.created', { name: fields.name }), { variant: 'success' });
            return audience;
        } catch (error) {
            console.error(error);
            enqueueSnackbar(t('audiences.create-failed'), { variant: 'error' });
        }
        setRunning(false);
    };

    return {
        running,
        createAudience,
    };
};

export default useCreateAudience;
