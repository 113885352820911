import { Box, Grid, Paper, Typography } from '@mui/material';
import { ContactsField, DiscardButton, FormStep, GradientButton } from '@nexdynamic/nex-ui-react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import useTranslation from '../../hooks/useTranslation';

type SupplierFormProps = {
    fields?: SupplierFormFields;
    onSubmit: (values: SupplierFormFields) => Promise<void>;
};

const SupplierFieldsSchema = () =>
    yup
        .object({
            name: yup.string().required(t('supplier-form.name-required')),
            notes: yup.string().optional(),
        })
        .required();

export class SupplierFormFields {
    name = '';
    notes = '';
    contactIds: Array<string> = [];
}

export const SupplierForm = ({ fields, onSubmit }: SupplierFormProps) => {
    const initialValues = fields ? fields : new SupplierFormFields();
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={SupplierFieldsSchema()}
            onSubmit={values => {
                return onSubmit(values);
            }}
        >
            {({ isSubmitting, values }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Form autoComplete='off'>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                        <Box sx={{ flex: 1, mr: 1 }}>
                                            <Typography variant='h6'>{values.name ? values.name : t('supplier.title')}</Typography>
                                        </Box>
                                        <Box>
                                            <DiscardButton sx={{ mr: 1 }} onDiscard={() => navigate(-1)} />
                                            <GradientButton
                                                aria-label={t('general.save')}
                                                text={t('general.save')}
                                                type='submit'
                                                disabled={isSubmitting}
                                                color='success'
                                            ></GradientButton>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={2}>
                                            <FormStep
                                                fields={['name', 'notes']}
                                                title={t('supplier-form.details')}
                                                description={t('supplier-form.supplier-details-description')}
                                            >
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
                                                    <Box sx={{ flex: 1, minWidth: 200 }}>
                                                        <Field
                                                            sx={{ width: '100%' }}
                                                            required
                                                            component={TextField}
                                                            type='text'
                                                            label={t('supplier.name')}
                                                            name='name'
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                                    <Box sx={{ flex: 1, minWidth: 200 }}>
                                                        <Field
                                                            sx={{ width: '100%' }}
                                                            multiline
                                                            rows={4}
                                                            component={TextField}
                                                            type='text'
                                                            label={t('supplier.notes')}
                                                            name='notes'
                                                        />
                                                    </Box>
                                                </Box>
                                            </FormStep>
                                        </Box>
                                    </Paper>
                                </Grid>

                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={2}>
                                            <FormStep
                                                fields={['contactIds']}
                                                title={t('supplier-form.contacts')}
                                                description={t('supplier-form.contact-description')}
                                            >
                                                <ContactsField name='contactIds' />
                                            </FormStep>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Form>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
};

export default SupplierForm;
