import { activationStrategy } from 'aurelia-router';
import { Analytics } from './Analytics';

export class AnalyticsModule {
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    analytics: ReturnType<typeof Analytics>;

    attached() {
        const container = document.getElementById('analytics-root');
        if (!container) throw new Error('analytics-root element not found');
        this.analytics = Analytics(container);
    }

    detached() {
        this.analytics?.unmount();
    }
}
