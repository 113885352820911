import { Box, Paper, Stack } from '@mui/material';
import type { TimeEntry } from '@nexdynamic/squeegee-common';
import Leaderboard from './Leaderboard';
import TimeBreakdown from './TimeBreakdown';

type StatisticsProps = {
    timeEntries: TimeEntry[];
    recordedTime: number;
    unrecordedTime: number;
    billedTime: number;
};

const Statistics = ({ recordedTime, unrecordedTime, timeEntries }: StatisticsProps) => {
    return (
        <Stack spacing={1} height='100%'>
            <Paper
                elevation={1}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderRadius: '10px',
                    padding: '1rem',
                }}
            >
                <TimeBreakdown stats={{ recorded: recordedTime, unrecorded: unrecordedTime }} />
            </Paper>
            <Box sx={{ height: '100%' }}>
                <Leaderboard timeEntries={timeEntries} />
            </Box>
        </Stack>
    );
};

export default Statistics;
