import { List, ListItem, ListItemText, ListSubheader, Typography } from '@mui/material';
import type { Device, LimitBreakingAccount } from '@nexdynamic/squeegee-common';
import { to2dp } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { Data } from '../../../Data/Data';

type AccountLimitBreakerListProps = {
    distanceConstraintMetres: number;
    timeConstraintMinutes: number;
    limitBreakers: Array<LimitBreakingAccount>;
};

const metrePerMinuteToMph = (distance: number, time: number): number | void => {
    if (isNaN(distance / time)) return;
    else {
        return Math.round((distance / time) * 0.037 * 100) / 100;
    }
};
export const AccountLimitBreakerList = ({
    distanceConstraintMetres,
    timeConstraintMinutes,
    limitBreakers,
}: AccountLimitBreakerListProps) => {
    return (
        <List
            sx={{ width: '100%', maxWidth: 1600 }}
            subheader={
                <ListSubheader sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '2rem', border: 'solid 2px black' }}>
                    Items out of the constraints: {distanceConstraintMetres} metres, in {timeConstraintMinutes} minute(s).
                </ListSubheader>
            }
        >
            {limitBreakers.map(limitBreaker => (
                <List
                    sx={{
                        border: 'solid 2px grey',
                        backgroundColor: '#90909075',
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '16px',
                    }}
                    key={limitBreaker.id}
                    subheader={
                        <ListSubheader
                            sx={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                                fontSize: '1.5rem',
                                border: 'solid 2px black',
                                backgroundColor: '#90909075',
                                width: '70%',
                                margin: 'auto',
                                marginTop: '16px',
                                marginBottom: '16px',
                            }}
                        >
                            Account, {limitBreaker.user} fails the limits!
                        </ListSubheader>
                    }
                >
                    {limitBreaker.limitBreaks.map(limitBreak => {
                        const timeDifferenceMinutes = Math.round(limitBreak.timeDifferenceMs / 1000 / 60);
                        const distanceBetweenLocationsMetres = limitBreak.distanceBetweenRecordedPointsInMetres;
                        const distanceBetweenLocationKilometres = to2dp(limitBreak.distanceBetweenRecordedPointsInMetres / 1000);
                        const deviceA = Data.get<Device>(limitBreak.deviceA.id)?.model || 'unknown';
                        const deviceADetails = {
                            label: deviceA.length > 15 ? deviceA.substring(0, 15) + '...' : deviceA,
                            description: deviceA,
                        };
                        const deviceB = Data.get<Device>(limitBreak.deviceB.id)?.model || 'unknown';
                        const deviceBDetails = {
                            label: deviceB.length > 15 ? deviceB.substring(0, 15) + '...' : deviceB,
                            description: deviceB,
                        };

                        return (
                            <ListItem
                                key={limitBreaker.id + '-' + limitBreak.deviceA.timestamp}
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    justifyContent: 'space-evenly',
                                    borderBottom: 'solid 2px grey',
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <Typography fontWeight='bold'>{moment(limitBreak.deviceA.timestamp, 'x').format('lll')}</Typography>
                                    }
                                />
                                <ListItemText
                                    primary={
                                        <Typography title={deviceADetails.description}>
                                            {limitBreak.deviceA.isMobile ? 'Mobile' : 'Desktop'} device A located
                                        </Typography>
                                    }
                                />
                                <ListItemText primary={<Typography fontWeight='bold'>{distanceBetweenLocationKilometres}km</Typography>} />
                                <ListItemText
                                    primary={
                                        <Typography title={deviceBDetails.description}>
                                            from {limitBreak.deviceB.isMobile ? 'mobile' : 'desktop'} device B
                                        </Typography>
                                    }
                                />

                                <ListItemText
                                    primary={
                                        <Typography fontWeight='bold'>
                                            {timeDifferenceMinutes !== 0
                                                ? `${timeDifferenceMinutes} minute${timeDifferenceMinutes > 1 ? 's' : ''} apart`
                                                : 'at the same time'}
                                        </Typography>
                                    }
                                />
                                <ListItemText primary={<Typography>=</Typography>} />
                                <ListItemText
                                    primary={`${
                                        metrePerMinuteToMph(distanceBetweenLocationsMetres, timeDifferenceMinutes) !== Infinity
                                            ? metrePerMinuteToMph(distanceBetweenLocationsMetres, timeDifferenceMinutes) + 'mph'
                                            : 'Quantum Speed'
                                    }`}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            ))}
        </List>
    );
};
