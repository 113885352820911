import type { TypographyProps } from '@mui/material';
import { Typography } from '@mui/material';
import type { TranslationKey } from '@nexdynamic/squeegee-common';
import useTranslation from '../../ReactUI/hooks/useTranslation';

type Props = {
    text: TranslationKey;
    params?: Record<string, string>;
    component?: React.ElementType;
    tReady?: unknown;
} & TypographyProps;
// avoid adding tReady to the props causes validation error
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Text = ({ text, params, tReady, ...rest }: Props) => {
    const { t } = useTranslation();

    return <Typography {...rest}>{t(text, params)}</Typography>;
};

export default Text;
