import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, Typography } from '@mui/material';
import { GradientButton } from '@nexdynamic/nex-ui-react';
const ButtonExamples = () => {
    return (
        <div>
            <Grid container spacing={4} padding={3}>
                <Grid item>
                    <Box sx={{ border: 1, borderRadius: '12px', borderColor: '#ccc', width: '24rem', px: 2, pb: 2, pt: 0.5 }}>
                        <Typography variant='subtitle1' textAlign='center' sx={{ pb: 0.5 }}>
                            Button Colors
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                            <Button variant='contained' color='primary'>
                                Primary
                            </Button>
                            <Button variant='contained' color='secondary'>
                                Secondary
                            </Button>
                            <Button variant='contained' color='error'>
                                Error
                            </Button>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem', mt: '1rem' }}>
                            <Button variant='contained' color='info'>
                                Info
                            </Button>
                            <Button variant='contained' color='success'>
                                Success
                            </Button>
                            <Button variant='contained' color='warning'>
                                Warning
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{ border: 1, borderRadius: '12px', borderColor: '#ccc', width: '24rem', px: 2, pb: 2, pt: 0.5 }}>
                        <Typography variant='subtitle1' textAlign='center' sx={{ pb: 0.5 }}>
                            Gradient Button
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                            <GradientButton variant='contained' color='primary'>
                                Primary
                            </GradientButton>
                            <GradientButton variant='contained' color='secondary'>
                                Secondary
                            </GradientButton>
                            <GradientButton variant='contained' color='error'>
                                Error
                            </GradientButton>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem', mt: '1rem' }}>
                            <GradientButton variant='contained' color='info'>
                                Info
                            </GradientButton>
                            <GradientButton variant='contained' color='success'>
                                Success
                            </GradientButton>
                            <GradientButton variant='contained' color='warning'>
                                Warning
                            </GradientButton>
                        </Box>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{ border: 1, borderRadius: '12px', borderColor: '#ccc', width: '24rem', px: 2, pb: 2, pt: 0.5 }}>
                        <Typography variant='subtitle1' textAlign='center' sx={{ pb: 0.5 }}>
                            Button Disabled
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                            <Button variant='contained' color='primary' disabled>
                                Primary
                            </Button>
                            <Button variant='contained' color='secondary' disabled>
                                Secondary
                            </Button>
                            <Button variant='contained' color='error' disabled>
                                Error
                            </Button>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem', mt: '1rem' }}>
                            <Button variant='contained' color='info' disabled>
                                Info
                            </Button>
                            <Button variant='contained' color='success' disabled>
                                Success
                            </Button>
                            <Button variant='contained' color='warning' disabled>
                                Warning
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{ border: 1, borderRadius: '12px', borderColor: '#ccc', width: '24rem', px: 2, pb: 2, pt: 0.5 }}>
                        <Typography variant='subtitle1' textAlign='center' sx={{ pb: 0.5 }}>
                            Outlined
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                            <Button variant='outlined' color='primary'>
                                Primary
                            </Button>
                            <Button variant='outlined' color='secondary'>
                                Secondary
                            </Button>
                            <Button variant='outlined' color='error'>
                                Error
                            </Button>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem', mt: '1rem' }}>
                            <Button variant='outlined' color='info'>
                                Info
                            </Button>
                            <Button variant='outlined' color='success'>
                                Success
                            </Button>
                            <Button variant='outlined' color='warning'>
                                Warning
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{ border: 1, borderRadius: '12px', borderColor: '#ccc', width: '24rem', px: 2, pb: 2, pt: 0.5 }}>
                        <Typography variant='subtitle1' textAlign='center' sx={{ pb: 0.5 }}>
                            Text
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                            <Button variant='text' color='primary'>
                                Primary
                            </Button>
                            <Button variant='text' color='secondary'>
                                Secondary
                            </Button>
                            <Button variant='text' color='error'>
                                Error
                            </Button>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem', mt: '1rem' }}>
                            <Button variant='text' color='info'>
                                Info
                            </Button>
                            <Button variant='text' color='success'>
                                Success
                            </Button>
                            <Button variant='text' color='warning'>
                                Warning
                            </Button>
                        </Box>
                    </Box>
                </Grid>

                <Grid item>
                    <Box sx={{ border: 1, borderRadius: '12px', borderColor: '#ccc', width: '24rem', px: 2, pb: 2, pt: 0.5 }}>
                        <Typography variant='subtitle1' textAlign='center' sx={{ pb: 0.5 }}>
                            With Icons
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                            <Button variant='contained' color='primary' endIcon={<AddIcon />}>
                                Primary
                            </Button>
                            <Button variant='contained' color='secondary' endIcon={<AddIcon />}>
                                Secondary
                            </Button>
                            <Button variant='contained' color='error' endIcon={<AddIcon />}>
                                Error
                            </Button>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem', mt: '1rem' }}>
                            <Button variant='contained' color='info' endIcon={<AddIcon />}>
                                Info
                            </Button>
                            <Button variant='contained' color='success' endIcon={<AddIcon />}>
                                Success
                            </Button>
                            <Button variant='contained' color='warning' endIcon={<AddIcon />}>
                                Warning
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{ border: 1, borderRadius: '12px', borderColor: '#ccc', width: '24rem', px: 2, pb: 2, pt: 0.5 }}>
                        <Typography variant='subtitle1' textAlign='center' sx={{ pb: 0.5 }}>
                            Button Sizes
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                            <Button variant='contained' color='primary' size='small'>
                                Contained
                            </Button>
                            <Button variant='outlined' color='primary' size='small'>
                                Outlined
                            </Button>
                            <Button variant='text' color='primary' size='small'>
                                Text
                            </Button>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem', mt: '1rem' }}>
                            <Button variant='contained' color='primary' size='medium'>
                                Contained
                            </Button>
                            <Button variant='outlined' color='primary' size='medium'>
                                Outlined
                            </Button>
                            <Button variant='text' color='primary' size='medium'>
                                Text
                            </Button>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem', mt: '1rem' }}>
                            <Button variant='contained' color='primary' size='large'>
                                Contained
                            </Button>
                            <Button variant='outlined' color='primary' size='large'>
                                Outlined
                            </Button>
                            <Button variant='text' color='primary' size='large'>
                                Text
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default ButtonExamples;
