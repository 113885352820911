import { useNexAction } from '@nexdynamic/nex-ui-react';
import type { Supplier } from '@nexdynamic/squeegee-common';
import { useTranslation } from 'react-i18next';
import { isDuplicateSupplier, supplierUpdate } from '../SupplierService';
import type { SupplierFormFields } from '../components/SupplierForm';

type UseUpdateSupplier = (id: string) => {
    updateSupplier: (fields: SupplierFormFields) => Promise<Supplier | undefined>;
    running: boolean;
};

const useSupplierUpdate: UseUpdateSupplier = (id: string) => {
    const { t } = useTranslation();
    const [handleAction, running] = useNexAction();

    const updateSupplier = async (fields: SupplierFormFields) => {
        const translations = { name: fields.name };
        const result = await handleAction<Supplier>(
            {
                successMsg: t('supplier.updated', translations),
                errorMsg: t('supplier.update-failed'),
            },
            async () => {
                if (isDuplicateSupplier(fields.name, id)) {
                    return { success: false, errorMsg: t('supplier.duplicate', { name: fields.name }) };
                } else {
                    const supplier = await supplierUpdate(id, fields);
                    if (supplier) return { success: true, value: supplier };
                    return { success: false, errorMsg: t('supplier.update-failed') };
                }
            }
        );

        return result.value;
    };

    return {
        updateSupplier,
        running,
    };
};

export default useSupplierUpdate;
