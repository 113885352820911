import { Button, Chip, FormControl, FormLabel, TextField } from '@mui/material';
import { DialogPaper } from '@nexdynamic/nex-ui-react';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { ApplicationState } from '../../../ApplicationState';
import { prompt } from '../../../Dialogs/ReactDialogProvider';
import { RethinkDbAuthClient } from '../../../Server/RethinkDbAuthClient';
import { Utilities } from '../../../Utilities';
import useApplicationState from '../../hooks/useApplicationState';
import useTranslation from '../../hooks/useTranslation';
import ActionableListItem from '../components/ActionableListItem';
import Header from '../components/Header';
import { useEmailEngine } from '../provider/EmailEngineProvider';

export const OneWaySettings = () => {
    const { t } = useTranslation();
    const { account } = useApplicationState();

    const [replyToEmail, setReplyToEmail] = useState(account.customReplyTo || '');
    const [replyToError, setReplyToError] = useState('');

    const {
        emailProviders: { squeegee: provider },
        disconnectAccount,
    } = useEmailEngine();

    const [oneWayEnabled, setOneWayEnabled] = useState(account.emailAPI === 'squeegee-smtp');

    if (!provider) return null;

    const handleConnect = async () => {
        if (oneWayEnabled) return;
        const areYouSurePrompt = prompt('prompts.disconnect-two-way-email-title', 'prompts.disconnect-two-way-email-description', {
            okLabel: 'general.disconnect',
            cancelLabel: 'general.cancel',
        });

        const sure = await areYouSurePrompt.show();
        if (sure) {
            await disconnectAccount();
            setOneWayEnabled(true);
        }
    };

    const handleValidateReplyTo = () => {
        const isEmail = Utilities.validateEmail(replyToEmail);
        if (!isEmail) setReplyToError(t('email-settings.invalid-email'));
        else setReplyToError('');
    };

    const handleSaveReplyTo = async () => {
        try {
            if (!replyToEmail || replyToError) return;

            account.customReplyTo = replyToEmail || (RethinkDbAuthClient.session && RethinkDbAuthClient.session.email) || '';

            await ApplicationState.save();
            enqueueSnackbar(t('notifications.settings-saved'), { variant: 'success' });
        } catch (e) {
            enqueueSnackbar(t('general.unknown-error-occurred'), { variant: 'error' });
        }
    };

    return (
        <>
            <DialogPaper sx={{ p: 2 }}>
                <Header title={t('email-settings.connection-details')} />
                <ActionableListItem
                    primary={t(provider.name)}
                    secondary={provider.connected ? t('email-api.squeegee-smtp') : t('email-settings.switch-to-one-way')}
                    slots={{
                        icon: provider.icon,
                        secondaryArea: (
                            <Chip
                                label={provider.connected ? t('general.connected') : t('general.not-connected')}
                                color={provider.connected ? 'success' : 'default'}
                                size="small"
                            />
                        ),
                    }}
                    slotProps={{ paper: { sx: { backgroundImage: 'none', backgroundColor: 'transparent' }, elevation: 0 } }}
                    onClick={handleConnect}
                />
            </DialogPaper>
            <DialogPaper sx={{ p: 2 }}>
                <FormControl>
                    <FormLabel>{t('email-settings.reply-to-email')}</FormLabel>
                    <TextField
                        placeholder="you@example.com"
                        type="email"
                        value={replyToEmail}
                        onChange={e => setReplyToEmail(e.target.value)}
                        onBlur={handleValidateReplyTo}
                        helperText={replyToError}
                        error={!!replyToError}
                        sx={{ my: 1 }}
                    />
                </FormControl>
                <Button variant="contained" color="primary" onClick={handleSaveReplyTo} disabled={!!replyToError || !replyToEmail}>
                    {t('general.save')}
                </Button>
            </DialogPaper>
        </>
    );
};

export default OneWaySettings;
