import { Box, CircularProgress, Fade } from '@mui/material';
import type { Notification, TranslationKey } from '@nexdynamic/squeegee-common';
import type { ComponentProps } from 'react';
import { useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import usePollApiStatus from '../../hooks/usePollApiStatus';
import { InboxEmpty } from '../components/InboxEmpty';
import MailList from '../components/MailList';

type MailListContainerProps = {
    notifications: Notification[];
    loading?: boolean;
    loadMore?: () => Promise<void>;
    hasMore?: boolean;
    EmptyIcon: ComponentProps<typeof InboxEmpty>['icon'];
    emptyText: TranslationKey;
};

export const MailListContainer = ({ notifications, loadMore, EmptyIcon, emptyText, hasMore, loading }: MailListContainerProps) => {
    const navigate = useNavigate();

    const isOnline = usePollApiStatus();

    const onSelect = (notification: Notification) => {
        navigate(`${notification._id}?messageId=${notification._id}`);
    };

    const view = useMemo(() => {
        if (notifications.length === 0 && loading) {
            return (
                <Fade in>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
                        <CircularProgress />
                    </Box>
                </Fade>
            );
        }
        if (notifications.length === 0)
            return (
                <Fade in>
                    <div>
                        <InboxEmpty text={emptyText} icon={EmptyIcon} />
                    </div>
                </Fade>
            );
        return (
            <Fade in>
                <div>
                    <MailList hasMore={Boolean(hasMore)} loadMore={loadMore} notifications={notifications} onSelect={onSelect} />
                </div>
            </Fade>
        );
    }, [notifications, isOnline]);

    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: 1 }}>
                {view}
                <Outlet />
            </Box>
        </Box>
    );
};

export default MailListContainer;
