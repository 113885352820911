// Registered on the portal

import { calculatePercentageChange, type DateRangeValue } from '@nexdynamic/nex-ui-react';
import type { Customer } from '@nexdynamic/squeegee-common';
import { sortByCreatedDateDesc } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { useMemo } from 'react';
import { Data } from '../../../../Data/Data';
import { Api } from '../../../../Server/Api';
import { groupTimestamps } from '../portalAnalyticsUtils';
import type { Grouping } from '../usePortalAnalytics';

export const isLead = (customer: Customer) =>
    customer.createdOnPortal || customer.source?.includes('directory') || customer.source?.includes('Quote:');

export const usePortalLeads = (dateRange: DateRangeValue, grouping: Grouping) => {
    const isInternalEmail = (email: string) => email.match(/sqg|sqgee|squeegee|squeeg.ee|nexdynamic/);

    const leads = useMemo(() => {
        return Data.all<Customer>('customers')
            .filter(customer => {
                if (isLead(customer) && (!Api.isProduction || !isInternalEmail(customer.email || ''))) {
                    return moment(customer.createdDate).isBetween(dateRange.start, dateRange.end, undefined, '[]');
                }
            })
            ?.sort(sortByCreatedDateDesc);
    }, [dateRange]);

    const previousLeads = useMemo(() => {
        return Data.all<Customer>('customers')
            .filter(customer => {
                if (
                    (customer.createdOnPortal || customer.source?.includes('directory') || customer.source?.includes('Quote:')) &&
                    !customer.email?.match(/sqg|sqgee|squeegee|squeeg.ee|nexdynamic/)
                ) {
                    const previousPeriodStart = moment(dateRange?.start).subtract(moment(dateRange.end).diff(dateRange.start));
                    return moment(customer.createdDate).isBetween(previousPeriodStart, dateRange.start, undefined, '[]');
                }
            })
            ?.sort(sortByCreatedDateDesc);
    }, [dateRange]);

    const timestamps = useMemo(() => {
        return leads.map(lead => new Date(lead.createdDate).valueOf());
    }, [leads]);

    const groupedLeads = useMemo(() => {
        return groupTimestamps(grouping, timestamps, dateRange);
    }, [timestamps, grouping]);

    const percentageChange = calculatePercentageChange(previousLeads.length, leads.length);

    return {
        leads,
        groupedLeads,
        previousLeads,
        percentageChange,
        timestamps,
        count: leads.length,
    };
};
