import { Alert, Divider, Stack } from '@mui/material';
import { DialogPaper } from '@nexdynamic/nex-ui-react';
import { wait } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../../ApplicationState';
import { LoaderEvent } from '../../../Events/LoaderEvent';
import { GlobalFlags } from '../../../GlobalFlags';
import { Api } from '../../../Server/Api';
import { openSystemBrowser } from '../../../Utilities';
import useTranslation from '../../hooks/useTranslation';
import EmailProviderActions from '../components/EmailProviderActions';
import EmailEngineQuotaReport from '../components/QuotaReport';
import { useEmailEngine } from '../provider/EmailEngineProvider';
import { useEmailSettingsViewRouter } from '../useEmailSettingsViewRouter';

export const MicrosoftSettings = () => {
    const { navigateTo } = useEmailSettingsViewRouter();
    const { t } = useTranslation();
    const {
        account,
        reconnectAccount,
        reconnecting,
        disconnectAccount,
        disconnecting,
        syncAccount,
        syncing,
        registerAccount,
        emailProviders: { microsoft },
    } = useEmailEngine();

    if (!microsoft) return null;

    const handleDisconnectProvider = () => {
        navigateTo('email-settings');
        disconnectAccount();
    };

    const handleConnectClick = async () => {
        try {
            if (!microsoft) return;

            new LoaderEvent(true, true, 'email-settings.initialising-connection-to-microsoft');

            if (microsoft.connected) return await reconnectAccount();

            const host = GlobalFlags.isMobileApp ? 'web+squeegee:/' : Api.currentHostAndScheme;
            const path = '/email-settings';

            const squeegeeUrl = host + path;

            const redirectUrl = `${Api.currentHostAndScheme}/api/email-engine/connected-redirect?uuid=${
                ApplicationState.account.uuid
            }&squeegeeUrl=${encodeURIComponent(squeegeeUrl)}`;

            const authLink = await registerAccount({ type: microsoft.type, redirectUrl });
            if (!authLink) return;

            if (GlobalFlags.isMobileApp) openSystemBrowser(authLink);
            else window.open(authLink, '_parent');
        } finally {
            await wait(1000);
            new LoaderEvent(false);
        }
    };

    return (
        <>
            <Stack gap={1.5} direction="row">
                <DialogPaper sx={{ p: 2, width: '100%' }}>
                    <Stack gap={1.5}>
                        <EmailProviderActions
                            provider={microsoft}
                            handleConnect={handleConnectClick}
                            handleDisconnect={handleDisconnectProvider}
                            handleSync={syncAccount}
                            loading={disconnecting || reconnecting || syncing}
                        />
                        {account?.quota && microsoft.connected && (
                            <EmailEngineQuotaReport providerName={microsoft.name} quota={account.quota} />
                        )}
                    </Stack>
                    <Divider sx={{ my: 1 }} />
                    <Alert severity="info" sx={{ my: 1 }}>
                        {t('email-settings.no-scheduling-for-two-way-emails')}
                    </Alert>
                    <Alert severity="info" sx={{ my: 1 }}>
                        {t('email-settings.feature-in-beta')}
                    </Alert>
                </DialogPaper>
            </Stack>
        </>
    );
};
