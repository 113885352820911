import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ViewLoading from '../components/ViewLoading';
import { AvailabilityView } from './views/AvailabilityView';

export const AvailabilityApp = () => {
    return (
        <Suspense fallback={<ViewLoading />}>
            <Routes>
                <Route path='/availability/*' element={<AvailabilityView />}></Route>
            </Routes>
        </Suspense>
    );
};
