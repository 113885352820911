import { Outlet, Route, Routes } from 'react-router';

import Audience from './views/Audience';
import Audiences from './views/Audiences';
import CreateAudience from './views/CreateAudience';
import EditAudience from './views/EditAudience';

const AudiencesView = () => {
    return <Outlet></Outlet>;
};

const AudiencesRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<AudiencesView />}>
                <Route index element={<Audiences />} />
                <Route path='create' element={<CreateAudience />} />
                <Route path=':id' element={<Audience />} />
                <Route path=':id/edit' element={<EditAudience />} />
            </Route>
        </Routes>
    );
};

export default AudiencesRoutes;
