import ClearIcon from '@mui/icons-material/Clear';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useIsSmallScreen } from '@nexdynamic/nex-ui-react';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { settingEditorComponents } from '../ComponentSettings/settingEditorComponents';
import GridLayout from './components/GridLayout';
import LayoutEditorSettingsDialog from './components/LayoutEditorSettingsDialog';
import PortalLayoutEditorHeader from './components/Sidebar/PortalLayoutEditorHeader';
import SmallScreenWarning from './components/SmallScreenWarning';
import { usePortalLayout } from './PortalLayoutProvider';
import './react-grid-layout/styles.css';
import './react-resizable/styles.css';

type Props = {
    handleChangesMade: (changesMade: boolean, reason?: string) => void;
};

const PortalLayoutEditorMain = ({ handleChangesMade }: Props) => {
    const isSmallScreen = useIsSmallScreen();
    const [warningOpen, setWarningOpen] = useState(isSmallScreen);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [SettingsComponent, setSettingsComponent] = useState<ReactNode>();
    const [settingsAnchorEl, setSettingsAnchorEl] = useState<HTMLElement | null>(null);

    const { handleResetLayout, setIsDirty } = usePortalLayout();

    const handleSettings = (componentName: keyof typeof settingEditorComponents) => {
        const SettingsComponent = settingEditorComponents[componentName];
        setSettingsComponent(SettingsComponent);
        setSettingsOpen(true);
    };

    const handleCloseSettings = () => {
        setSettingsOpen(false);
        setIsDirty(true);
    };

    useEffect(() => {
        setIsDirty(true);
    }, []);

    return (
        <Box sx={{ position: 'relative', pt: 2, pb: '50%', height: '100%' }}>
            <Box sx={{ position: 'sticky', top: 0, zIndex: 1000, bgcolor: theme => theme.palette.background.default }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Typography variant="h6" gutterBottom sx={{ px: 1 }}>
                        Layout Editor
                    </Typography>
                    <Stack direction="row" pb={1}>
                        <Tooltip title="Layout Settings">
                            <IconButton onClick={e => setSettingsAnchorEl(e.currentTarget)}>
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Clear Layout">
                            <IconButton onClick={handleResetLayout}>
                                <ClearIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Stack>
                <PortalLayoutEditorHeader />
            </Box>
            <Box sx={{ width: '100%' }}>
                <GridLayout handleSettings={handleSettings} handleChangesMade={handleChangesMade} />
            </Box>
            {/* <Paper
                    elevation={5}
                    sx={{ position: 'fixed', bottom: 16, left: '50%', transform: 'translateX(-50%)', zIndex: theme => theme.zIndex.fab }}
                >
                    <ToggleButtonGroup value={viewmode} onChange={(_, newViewMode) => newViewMode && setViewMode(newViewMode)} exclusive>
                        <ToggleButton value="sm">
                            <LaptopIcon />
                        </ToggleButton>
                        <ToggleButton value="xs">
                            <PhoneIphoneIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Paper> */}
            <LayoutEditorSettingsDialog open={!!settingsAnchorEl} onClose={() => setSettingsAnchorEl(null)} anchorEl={settingsAnchorEl} />
            <Dialog open={settingsOpen} onClose={handleCloseSettings} fullWidth fullScreen={isSmallScreen}>
                <DialogTitle>Widget Settings</DialogTitle>
                <DialogContent sx={{ padding: 0 }}>{SettingsComponent}</DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSettings}>Close</Button>
                </DialogActions>
            </Dialog>
            <SmallScreenWarning open={warningOpen} onClose={() => setWarningOpen(false)} />
        </Box>
    );
};
export default PortalLayoutEditorMain;
