import { useChartId, useDrawingArea, useXScale } from '@mui/x-charts';
import React from 'react';

interface ClipLineRangeProps {
    from?: number | Date | string;
    to?: number | Date | string;
    children: React.ReactNode;
}

export const ClipLineRange = (props: ClipLineRangeProps) => {
    const { from, to, children } = props;
    const { left, width, top, bottom, height } = useDrawingArea();
    const xScale = useXScale();
    const chartId = useChartId();

    const clipId = `${chartId}-line-limit-${from?.toString().replace(' ', '')}-${to?.toString().replace(' ', '')}`;

    const startPositon = from === undefined ? left : xScale(from as number) ?? left;
    const endPositon = to === undefined ? left + width : xScale(to as number) ?? left + width;

    return (
        <React.Fragment>
            <clipPath id={clipId}>
                <rect x={startPositon} y={0} width={endPositon - startPositon} height={top + height + bottom} />
            </clipPath>
            <g clipPath={`url(#${clipId})`}>{children}</g>
        </React.Fragment>
    );
};
