import { Box, Switch, ThemeProvider, Typography, useTheme } from '@mui/material';
import type React from 'react';

type Props = {
    label: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    align?: 'left' | 'right';
    labelPlacement?: 'end' | 'start';
    color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined;
    fullWidth?: boolean;
};

const SwitchItem = ({ label, checked, onChange, align, labelPlacement, color, fullWidth }: Props) => {
    const theme = useTheme();
    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: align ? (align === 'left' ? 'row' : 'row-reverse') : 'row',
                    alignItems: 'center',
                    width: fullWidth ? '100%' : 'fit-content',
                    ml: align === 'left' ? '-11px' : labelPlacement !== 'start' ? '16px' : '5px',
                    mr: align === 'right' ? '-11px' : '16px',
                }}
            >
                <Switch checked={checked} onChange={onChange} color={color || 'primary'} />
                <Typography variant='body1' sx={{ mr: labelPlacement === 'start' ? 'auto' : 0 }}>
                    {label}
                </Typography>
            </Box>
        </ThemeProvider>
    );
};

export default SwitchItem;
