import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, TextField, Typography } from '@mui/material';
import type { ImageSizeDialogDimensions } from '@nexdynamic/nex-ui-react';
import { ImageSizeDialog, useDebounce } from '@nexdynamic/nex-ui-react';
import { AtomicBlockUtils, EditorState, Modifier } from 'draft-js';
import { useState } from 'react';
import type { Basic } from 'unsplash-js/dist/methods/photos/types';
import useTranslation from '../../../hooks/useTranslation';
import UnsplashGallery from './UnsplashGallery';

type Props = {
    editorState?: EditorState;
    onChange?: (editorState: EditorState) => void;
};

const UnsplashButton = ({ editorState, onChange }: Props) => {
    const [showPicker, setShowPicker] = useState(false);
    const [searchText, setQuery] = useState('');
    const [selectedImage, setSelectedImage] = useState<Basic | null>(null);
    const { t } = useTranslation();
    const query = useDebounce(searchText, 500);

    const choosePhoto = (image: Basic) => {
        setSelectedImage(image);
    };

    const onImageSizeSet = (size: ImageSizeDialogDimensions) => {
        if (selectedImage) {
            handleChange(selectedImage, size);
            requestAnimationFrame(() => {
                setQuery('');
                setShowPicker(false);
                setSelectedImage(null);
            });
        }
    };

    const handleChange = (selectedPhoto: Basic, size: ImageSizeDialogDimensions) => {
        if (editorState && onChange && selectedPhoto) {
            const entityData = {
                src: selectedPhoto.urls.regular,
                alt: selectedPhoto.alt_description || '',
                id: selectedPhoto.id,
                width: size.width,
                height: size.height,
            };
            const entityKey = editorState.getCurrentContent().createEntity('IMAGE', 'MUTABLE', entityData).getLastCreatedEntityKey();
            let newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');

            const captionKey = newEditorState
                .getCurrentContent()
                .createEntity('LINK', 'MUTABLE', {
                    url: selectedPhoto.user.links.html,
                    target: '_blank',
                })
                .getLastCreatedEntityKey();

            const contentState = Modifier.replaceText(
                newEditorState.getCurrentContent(),
                newEditorState.getSelection(),
                `${t('unsplash.image-by', { name: selectedPhoto.user.name })}`,
                undefined,
                captionKey
            );

            newEditorState = EditorState.push(newEditorState, contentState, 'insert-characters');

            onChange(newEditorState);
        }
    };

    const closeDialog = () => {
        setShowPicker(false);
    };

    return (
        <>
            <div
                role='button'
                tabIndex={0}
                className='rdw-option-wrapper'
                onClick={() => setShowPicker(true)}
                onKeyDown={() => {
                    //
                }}
            >
                <ImageSearchIcon />
            </div>
            <Dialog PaperProps={{ elevation: 0 }} maxWidth='lg' fullWidth open={showPicker} onClose={() => setShowPicker(false)}>
                <DialogTitle sx={{ textAlign: 'center' }}>
                    <Typography variant='h6'>Unsplash</Typography>
                    <small>
                        <Link href='https://unsplash.com/license' target='_blank' rel='noopener noreferrer'>
                            View Unsplash licence details
                        </Link>
                    </small>
                </DialogTitle>

                <Box sx={{ px: 3, pb: 2 }}>
                    <TextField onChange={event => setQuery(event.target.value)} fullWidth size='small' label='Search' variant='outlined' />
                </Box>

                <DialogContent>
                    <UnsplashGallery onSelected={choosePhoto} query={query} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Close</Button>
                </DialogActions>
            </Dialog>

            {!!selectedImage && (
                <ImageSizeDialog
                    open={true}
                    onSubmit={onImageSizeSet}
                    dimensions={{ width: selectedImage.width, height: selectedImage.height }}
                />
            )}
        </>
    );
};

type ButtonProps = {
    editorState?: EditorState;
    onChange?: (editorState: EditorState) => void;
};
export const ToolbarButton = (props: ButtonProps) => {
    return <UnsplashButton {...props} />;
};

export default ToolbarButton;
