import type { Campaign, CampaignDeliveryReport } from '@nexdynamic/squeegee-common';
import { CampaignDeliveryType } from '@nexdynamic/squeegee-common';
import useStoredObject from '../../../../hooks/useStoredObject';
import type { DeliveryStatsProps } from './DeliveryReports';
import DeliveryReports from './DeliveryReports';

type Props = {
    campaign: Campaign;
};

const CampaignDeliveryReports = ({ campaign }: Props) => {
    const report = useStoredObject<CampaignDeliveryReport>(campaign.campaignDeliveryReportId);
    const smsSent = report?.sms.sent || 0;
    const smsDelivered = report?.sms.delivered || 0;
    const smsOpened = report?.sms.click || 0;
    const smsClicks = report?.sms.click || 0;
    const smsFailed = report?.sms.failed || 0;
    const emailSent = report?.email.sent || 0;
    const emailDelivered = report?.email.delivered || 0;
    const emailOpened = report?.email.open || 0;
    const emailClicks = report?.email.click || 0;
    const emailFailed = report?.email.failed || 0;

    const stats: DeliveryStatsProps = {
        stats: {
            sms: campaign.deliveryMethods?.some(method => method === CampaignDeliveryType.Sms)
                ? {
                      sent: smsSent,
                      delivered: smsDelivered,
                      opened: smsOpened,
                      clicks: smsClicks,
                      failed: smsFailed,
                  }
                : undefined,
            email: campaign.deliveryMethods?.some(method => method === CampaignDeliveryType.Email)
                ? {
                      sent: emailSent,
                      delivered: emailDelivered,
                      opened: emailOpened,
                      clicks: emailClicks,
                      failed: emailFailed,
                  }
                : undefined,
        },
    };

    return <DeliveryReports stats={stats.stats} />;
};

export default CampaignDeliveryReports;
