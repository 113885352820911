import { styled } from '@mui/material';
import type { TranslationKey } from '@nexdynamic/squeegee-common';
import type { DraftModel, RawDraftContentState } from 'draft-js';
import { EditorState, Modifier, convertToRaw } from 'draft-js';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { ApplicationState } from '../../../ApplicationState';

import { useRequestInput } from '@nexdynamic/nex-ui-react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { StandardMessageModelTokens } from '../../../StandardMessageModel';
import './RichText.css';
import { RichTextContentStyles } from './RichTextContentStyles';
import { ToolbarButton } from './unsplash/UnsplashButton';

const EditorRoot = styled('div')(RichTextContentStyles);

export type RichTextProps = {
    initialState?: EditorState;
    onChange: (state: RawDraftContentState) => void;
    options?: Array<string>;
    inlineOptions?: Array<string>;
    hideCustomButtons?: boolean;
    hideSuggestions?: boolean;
};

const handleCustomisableToken = async (
    content: DraftModel.ImmutableData.ContentState,
    prompt: (token: string) => Promise<string | null>
): Promise<DraftModel.ImmutableData.ContentState | undefined> => {
    const latestEntityKey = content.getLastCreatedEntityKey();

    try {
        const entity = content.getEntity(latestEntityKey);
        const data = entity.getData();

        if (!data.text || entity.getType() !== 'MENTION') return;
        // Check if this token matches a customisable token if not continue
        const found = /\[([a-z]+?=\?)]/i.exec(data.text);
        if (!found) return;

        const tokenKey = found[1];
        const newParamToken = (await prompt(ApplicationState.localise(`standardMessageModel.${tokenKey}` as TranslationKey))) || '';

        const text = data.text.replace(/\?/, newParamToken);
        const value = data.value.replace(/\?/, newParamToken);

        let newContent = content.replaceEntityData(latestEntityKey, { ...data, text, value });

        let selection = newContent.getSelectionAfter();
        selection = selection.merge({
            anchorOffset: selection.getAnchorOffset() - (data.text.length + 1),
            focusOffset: selection.getFocusOffset(),
        });

        newContent = Modifier.replaceText(newContent, selection, text, undefined, latestEntityKey);
        console.log('newContent', newContent);
        return newContent;
    } catch {
        // Yum
    }
};

export default function RichText({
    onChange,
    initialState,
    options,
    inlineOptions,
    hideCustomButtons,
    hideSuggestions = false,
}: RichTextProps): JSX.Element {
    const [editorState, setEditorState] = useState<EditorState>(initialState || EditorState.createEmpty());
    const { requestInput } = useRequestInput();

    const editorStateChange = async (state: EditorState) => {
        const content = state.getCurrentContent();

        const updatedContent = await handleCustomisableToken(content, async (token: string) => {
            const result = await requestInput({
                title: ApplicationState.localise('dialogs.set-value-for-token', { token }),
            });
            // TODO get default value
            return result;
        });

        if (updatedContent) {
            setEditorState(EditorState.push(state, updatedContent, 'apply-entity'));
        } else {
            setEditorState(state);
        }
    };

    useEffect(() => {
        const currentContent = editorState.getCurrentContent();
        const rawContentState = convertToRaw(currentContent);
        onChange(rawContentState);
    }, [editorState, onChange]);

    const suggestions = React.useMemo(
        () =>
            Object.keys(new StandardMessageModelTokens()).map(key => {
                const text = ApplicationState.localise(`standardMessageModel.${key}` as TranslationKey);
                const value = `${key}]`;
                return {
                    text,
                    value,
                    url: `#`,
                };
            }),
        []
    );

    const toolbarButtons = React.useMemo(() => [<ToolbarButton key="unsplash-button" />], []);

    return (
        <EditorRoot>
            <Editor
                mention={
                    hideSuggestions
                        ? undefined
                        : {
                              separator: ' ',
                              trigger: '[',
                              suggestions,
                          }
                }
                toolbar={{
                    options: options || [
                        'history',
                        'inline',
                        'blockType',
                        'fontSize',
                        'fontFamily',
                        'list',
                        'textAlign',
                        'colorPicker',
                        'link',
                        'emoji',
                        'image',
                    ],
                    inline: {
                        options: inlineOptions || ['bold', 'italic', 'underline', 'strikethrough'],
                    },
                }}
                editorStyle={{ minHeight: 300 }}
                editorState={editorState}
                toolbarClassName="rich-text-toolbar"
                wrapperClassName="send-content-wrapper"
                editorClassName="rich-text-editor"
                onEditorStateChange={editorStateChange}
                toolbarCustomButtons={hideCustomButtons ? undefined : toolbarButtons}
            />
        </EditorRoot>
    );
}
