import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import type { AudienceFilter, AudienceFilterGroup, AudienceFilterModel } from '@nexdynamic/squeegee-common';
import type { FilterTerm } from './AudienceFilters';
import FilterItem from './FilterItem';

type FilterGroupItemProps = {
    group: AudienceFilterGroup;
    filter: AudienceFilter;
    update: (group: AudienceFilterGroup, filter: AudienceFilter) => void;
    remove: (group: AudienceFilterGroup, filter: AudienceFilter) => void;
    add: (group: AudienceFilterGroup, filter: AudienceFilter, termValue: FilterTerm) => void;
    moveFilter: (currentGroup: AudienceFilterGroup, filter: AudienceFilter, term: FilterTerm) => void;
    availableFilters: Array<AudienceFilterModel>;
    isLast: boolean;
};

export const FilterGroupItem = ({ filter, update, remove, availableFilters, group, isLast, moveFilter }: FilterGroupItemProps) => {
    return (
        <>
            <FilterItem
                onChange={updatedFilter => update(group, updatedFilter)}
                onRemove={() => remove(group, filter)}
                filters={availableFilters}
                filter={filter}
            />

            {!isLast && (
                <ToggleButtonGroup
                    sx={{ mt: 2 }}
                    value='and'
                    size='small'
                    color='primary'
                    exclusive
                    onChange={(_event, term) => term === 'or' && moveFilter(group, filter, term)}
                >
                    <ToggleButton value='and'>And</ToggleButton>
                    <ToggleButton value='or'>Or</ToggleButton>
                </ToggleButtonGroup>
            )}
        </>
    );
};
