/**
 * Converts bytes to a human-readable string with appropriate units (MB, GB, TB, etc.).
 * @param bytes - The number of bytes.
 * @returns A string representing the size in appropriate units.
 */
function bytesToMbString(bytes: number): string {
    if (bytes === 0) return '0 MB';

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const size = bytes / Math.pow(1024, i);

    return `${size.toFixed(2)} ${sizes[i]}`;
}

export default bytesToMbString;
