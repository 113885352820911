import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import type { GettingStartedOptions } from '../../types/GettingStarted';

type GettingStartedProps = {
    gettingStarted: GettingStartedOptions;
    setGettingStarted: (gettingStarted: GettingStartedOptions) => void;
};

export const GettingStartedStep = ({ gettingStarted, setGettingStarted }: GettingStartedProps) => {
    return (
        <Box display="flex" flexDirection="column" gap={1} mb={2}>
            <Typography variant="h5">Accept Terms & Conditions</Typography>
            <Typography variant="body1">
                {`By publishing your business listing, you accept responsibility for any data, information, copy, images and content
                that is shared on the directory. Squeegee reserves the right to disable this feature for any reason it feels
                necessary to protect Squeegee, it's employees, it's customers and partners.`}
            </Typography>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={gettingStarted.termsAccepted}
                        onChange={e => setGettingStarted({ ...gettingStarted, termsAccepted: e.target.checked })}
                    />
                }
                label="I agree"
                sx={{ alignSelf: 'flex-end' }}
            />

            <Typography variant="h5" gutterBottom>
                Choose your URL Handle
            </Typography>
            <TextField
                label="URL Handle"
                fullWidth
                value={gettingStarted.urlHandle}
                onChange={e => setGettingStarted({ ...gettingStarted, urlHandle: e.target.value })}
            />
            <Typography variant="body2" color="textSecondary">
                {`https://squeeg.ee/portal/`}
                <strong>{!gettingStarted.urlHandle ? 'your-url-handle' : gettingStarted.urlHandle}</strong>
            </Typography>
        </Box>
    );
};

export default GettingStartedStep;
