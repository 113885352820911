import type { SelectChangeEvent } from '@mui/material';
import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, InputLabel, Typography } from '@mui/material';
import type { IAdvertisement, ISqueegeeAdvertAsset } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import type {
    AureliaReactComponentDialogComponent,
    AureliaReactComponentDialogComponentStandardProps,
} from '../../Dialogs/AureliaReactComponentDialogComponent';
import { Logger } from '../../Logger';
import { createAdvertisement, transferFranchiseAssets } from '../Marketplace/services/AdvertisementService';
import { SelectFranchiseSibling } from './SelectFranchiseSibling';

export type TransferJobsProps = {
    jobIds: Array<string>;
    isRoundTransfer?: boolean;
};

type Props = AureliaReactComponentDialogComponentStandardProps<boolean> & TransferJobsProps;

export const TransferJobs: AureliaReactComponentDialogComponent<TransferJobsProps> = ({ jobIds, isRoundTransfer, okCallback }: Props) => {
    const [statusMessage, setStatusMessage] = useState<string | null>(null);
    const [formData, setFormData] = useState({
        accountEmail: '',
        complianceAgreement: false,
    });

    const handleSuccess = async (advert: IAdvertisement) => {
        await transferFranchiseAssets(advert.id);

        okCallback(true);
    };

    const handleFailed = (err: Error) => {
        setIsSubmitting(false);
        setFormData({ accountEmail: formData.accountEmail, complianceAgreement: false });
        setStatusMessage(`Uh oh there was an error creating a transfer for these jobs`);
        Logger.error(err.message);
    };

    const custJobIdsData = jobIds.map(x => ({ customerId: x.split(':')[0], jobId: x.split(':')[1] }));
    const attachSqueegeeAssets = custJobIdsData.map(x => ({ customerId: x.customerId, id: x.jobId, type: 'job' } as ISqueegeeAdvertAsset));

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = e.target;
        setFormData({ ...formData, [name]: name === 'accountEmail' ? value : checked });
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        setTimeout(() => {
            setIsSubmitting(false);
            createAdvertisement({
                title: `Transfer Jobs to ${formData.accountEmail}`,
                description: 'Job transfer',
                type: 'squeegee-assets',
                price: 0,
                tags: ['transfer'],
                attachSqueegeeAssets,
                directTransferAccountEmail: formData.accountEmail,
                isRoundTransfer,
            })
                .then(x => handleSuccess(x.advertisements[0]))
                .catch(handleFailed);
        }, 2000);
    };
    const handleSelectChange = (e: SelectChangeEvent) => {
        setFormData({ ...formData, accountEmail: e.target.value });
    };
    return (
        <Box padding={3}>
            {isSubmitting ? (
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            ) : (
                <form onSubmit={handleSubmit}>
                    <Box marginBottom={2}>
                        <Typography variant="h5">Transfer Jobs Form</Typography>
                    </Box>

                    <Box marginBottom={2}>{attachSqueegeeAssets.length} jobs will be transferred.</Box>

                    <Box marginBottom={2}>
                        <Typography variant="h6">Transfer Jobs</Typography>
                    </Box>
                    <Box marginBottom={2}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="email">Transfer to Account</InputLabel>
                            <SelectFranchiseSibling formData={formData} handleSelectChange={handleSelectChange} />
                        </FormControl>
                    </Box>
                    <Box marginBottom={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="complianceAgreement"
                                    name="complianceAgreement"
                                    checked={formData.complianceAgreement}
                                    onChange={handleInputChange}
                                    color="primary"
                                    required
                                />
                            }
                            label="I agree to comply with Squeegee policies and guidelines. Transferring these jobs will remove them from your account."
                        />
                    </Box>

                    <Box marginBottom={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={!formData.complianceAgreement || !formData.accountEmail}
                        >
                            Submit Transfer
                        </Button>
                    </Box>
                    {statusMessage && <Box marginBottom={2}>{statusMessage}</Box>}
                </form>
            )}
        </Box>
    );
};
