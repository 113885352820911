import type {
    Customer,
    FrequencyData,
    Job,
    JobGroup,
    JobOccurrence,
    LngLat,
    Location,
    QuotedJob,
    StoredObject,
    Tag,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import {
    Frequency,
    FrequencyBuilder,
    FrequencyType,
    JobOccurrenceStatus,
    TagType,
    copyObject,
    notNullUndefinedEmptyOrZero,
} from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { LoaderEvent } from '../Events/LoaderEvent';
import { JobOccurrenceService } from '../Jobs/JobOccurrenceService';
import { JobService } from '../Jobs/JobService';
import { LocationUtilities } from '../Location/LocationUtilities';
import { JobGroupData } from '../Tags/TagData';
import { TagService } from '../Tags/TagService';

export class RoundService {
    private static async updateAllJobsInRound(id: string, updateJobFunction: (job: Job) => void, resetSchedules = false) {
        const customersToBeUpdated: Array<Customer> = [];
        const allCustomers = Data.all<Customer>('customers');
        for (let i = 0; i < allCustomers.length; i++) {
            const customer = allCustomers[i];
            let customerUpdated = false;
            if (customer.jobs) {
                for (let i = 0; i < Object.keys(customer.jobs).map(jobId => customer.jobs[jobId]).length; i++) {
                    const job = Object.keys(customer.jobs).map(jobId => customer.jobs[jobId])[i];
                    if (job.rounds) {
                        let index = job.rounds.length;
                        while (index--) {
                            if (job.rounds[index]._id === id) {
                                updateJobFunction(job);
                                if (resetSchedules) await JobOccurrenceService.deleteExistingOccurrences(job);
                                customerUpdated = true;
                            }
                        }
                    }
                }
            }
            if (customerUpdated) await customersToBeUpdated.push(customer);
        }
        if (customersToBeUpdated.length) Data.put(customersToBeUpdated);
    }

    public static async deleteRound(id: string) {
        const round = Data.get<JobGroup>(id);

        if (round) {
            await RoundService.updateAllJobsInRound(id, job => {
                const findRoundIndex = job.rounds.findIndex(round => round._id === id);
                if (findRoundIndex > -1) job.rounds.splice(findRoundIndex, 1);
            });
            await Data.delete(round);
        }
    }

    public static async renameRound(newDescription: string, id: string) {
        const originalRound = Data.get<JobGroup>(id);
        if (!originalRound) return;
        const round = copyObject(originalRound);

        if (round) {
            const oldDescription = round.description;
            try {
                new LoaderEvent(true, true, 'dialogs.rename-round');
                round.description = newDescription;
                const updates = [round] as Array<StoredObject>;
                const replacedRoundIds = [] as Array<string>;

                const rounds = Data.all<Tag>('tags', { type: TagType.ROUND }).filter(r => r._id !== round._id);
                for (const round of rounds) {
                    if (round.description === newDescription || round.description === oldDescription) {
                        replacedRoundIds.push(round._id);
                        round._deleted = true;
                        updates.push(round);
                    }
                }

                for (const customer of Data.all<Customer>('customers')) {
                    for (const jobId in customer.jobs) {
                        const job = customer.jobs[jobId];
                        if (job.rounds) {
                            const occurrences = Data.all<JobOccurrence>('joboccurrences', {
                                jobId: job._id,
                                status: JobOccurrenceStatus.NotDone,
                            });

                            for (const occurrence of occurrences) {
                                const occurrenceRound =
                                    occurrence.rounds &&
                                    occurrence.rounds.find(
                                        r =>
                                            r.description === newDescription ||
                                            r.description === oldDescription ||
                                            replacedRoundIds.includes(r._id)
                                    );
                                if (occurrenceRound) {
                                    occurrence.rounds = [round];
                                    updates.push(occurrence);
                                }
                            }

                            const jobRound =
                                job.rounds &&
                                job.rounds.find(
                                    r =>
                                        r.description === newDescription ||
                                        r.description === oldDescription ||
                                        replacedRoundIds.includes(r._id)
                                );
                            if (jobRound) {
                                job.rounds = [round];
                                if (!updates.includes(customer)) updates.push(customer);
                            }
                        }
                    }
                }

                await Data.put(updates);
                await RoundService.setRoundFrequencyData(round._id, round.frequencyData);
            } catch (error) {
                console.error(error);
            } finally {
                new LoaderEvent(false);
            }
        }
    }

    public static async setRoundColor(newColor: string, id: string) {
        const round = Data.get<JobGroup>(id);
        const occurrences: Array<JobOccurrence> = [];
        if (round) {
            await RoundService.updateAllJobsInRound(id, job => {
                const jobRound = job.rounds && job.rounds.find(round => round._id === id);
                if (jobRound) jobRound.color = newColor;

                for (const occurrence of Data.all<JobOccurrence>('joboccurrences', undefined, { jobId: job._id })) {
                    const occurrenceRound = occurrence.rounds && occurrence.rounds.find(r => r._id === id);
                    if (occurrenceRound && occurrenceRound.color !== newColor) {
                        occurrenceRound.color = newColor;
                        occurrences.push(occurrence);
                    }
                }
            });
            round.color = newColor;
            await Data.put([round as StoredObject].concat(occurrences));
        }
    }

    public static async setRoundFrequencyData(roundId: string, frequencyResult?: FrequencyData | undefined) {
        const round = Data.get<JobGroup>(roundId);
        if (round) {
            await RoundService.updateAllJobsInRound(
                roundId,
                job => {
                    if (!frequencyResult || !frequencyResult.firstScheduledDate) return;
                    RoundService.setRoundAndFrequencyDataOnJob(job, round, frequencyResult);
                },
                true
            );

            round.frequencyData = frequencyResult;
            await Data.put(round, true, 'lazy');
        }
    }

    public static setRoundAndFrequencyDataOnJob(
        job: Job,
        round: JobGroup,
        frequencyResult: FrequencyData,
        useRoundDateForNextDate = false
    ) {
        let nextDate: string | undefined = round.frequencyData?.firstScheduledDate;
        if (!useRoundDateForNextDate) {
            const freq = FrequencyBuilder.getFrequency(
                frequencyResult.interval,
                frequencyResult.type,
                frequencyResult.firstScheduledDate,
                frequencyResult.dayOfMonth,
                frequencyResult.weekOfMonth,
                frequencyResult.dayOfWeek
            );

            nextDate = freq.getNextDate();

            if (!nextDate) throw 'There is no future.';
        }

        job.date = nextDate;
        job.frequencyType = frequencyResult.type;
        job.frequencyInterval = frequencyResult.interval;
        job.frequencyDayOfMonth = frequencyResult.dayOfMonth;
        job.frequencyDayOfWeek = frequencyResult.dayOfWeek;
        job.frequencyWeekOfMonth = frequencyResult.weekOfMonth;
        job.rounds = [copyObject(round)];
    }

    public static async setRoundSchedules(id: string, frequencyResult?: FrequencyData[] | undefined) {
        const round = Data.get<JobGroup>(id);
        if (round) {
            round.schedules = frequencyResult;
            await Data.put(round, true, 'lazy');
        }
    }

    public static async setRoundLocation(id: string, newLocation: Location | undefined) {
        const round = Data.get<JobGroup>(id);
        if (round) {
            round.location = newLocation;
            await Data.put(round);
        }
    }

    public static async getRounds(): Promise<Array<JobGroup>> {
        const tags = TagService.getTags();
        return tags
            .filter(tag => tag.type === TagType.ROUND)
            .sort((a, b) => (a.description > b.description ? 1 : a.description < b.description ? -1 : 0)) as Array<JobGroup>;
    }

    public static getRoundFrequencyText(round: JobGroup): TranslationKey | '' {
        const frequencyResult = round.frequencyData;
        if (frequencyResult !== undefined && frequencyResult.type && frequencyResult.firstScheduledDate) {
            const frequency = new Frequency(
                moment(frequencyResult.firstScheduledDate),
                frequencyResult.interval,
                frequencyResult.type,
                frequencyResult.weekOfMonth,
                frequencyResult.dayOfMonth,
                frequencyResult.dayOfWeek
            );

            const nextDate = frequency.getNextDate();

            return <TranslationKey>(
                (ApplicationState.localise(frequency.localisationKeyAndParams.key, frequency.localisationKeyAndParams.params) +
                    (nextDate ? ' ' + ApplicationState.localise('jobs.next-date') + moment(nextDate).format('ll') : ''))
            );
        } else return '';
    }

    public static getRoundNextDate(round: JobGroup) {
        const frequencyResult = round.frequencyData;
        if (frequencyResult !== undefined && frequencyResult.type && frequencyResult.firstScheduledDate) {
            const frequency = new Frequency(
                moment(frequencyResult.firstScheduledDate),
                frequencyResult.interval,
                frequencyResult.type,
                frequencyResult.weekOfMonth,
                frequencyResult.dayOfMonth,
                frequencyResult.dayOfWeek
            );

            return frequency.getNextDate();
        } else return '';
    }

    public static async getRoundsData(pending = false, nearestPoint?: LngLat) {
        //const customers = CustomerService.getCustomers();

        const allJobs = pending ? JobService.getPendingJobs() : JobService.getActiveJobs();
        const rounds = await RoundService.getRounds();
        const roundsData = [];

        const jobsOnRoundDictionary = allJobs.reduce((dictionary, job) => {
            if (!job.rounds?.length) return dictionary;

            if (!dictionary[job.rounds[0]._id]) dictionary[job.rounds[0]._id] = [];
            dictionary[job.rounds[0]._id].push(job);

            return dictionary;
        }, {} as Record<string, Array<Job>>);

        for (const round of rounds) {
            const roundData: JobGroupData = RoundService.getRoundDataFromJobs(
                copyObject(round),
                jobsOnRoundDictionary[round._id] || [],
                nearestPoint
            );
            roundsData.push(roundData);
        }
        return roundsData;
    }

    public static async getRoundData(roundId: string, pending = false, nearestPoint?: LngLat) {
        const round = Data.get<JobGroup>(roundId);
        if (!round) return;

        const allJobs = pending ? await JobService.getPendingJobsInRound(roundId) : await JobService.getActiveJobsInRound(roundId);

        const roundData: JobGroupData = RoundService.getRoundDataFromJobs(
            round,
            allJobs.filter(j => j.rounds?.[0]?._id === round._id),
            nearestPoint
        );

        return roundData;
    }

    private static getRoundDataFromJobs(roundTag: JobGroup, jobsOnRound: Array<Job | QuotedJob>, useJobsNearestToPoint?: LngLat) {
        const roundData: JobGroupData = new JobGroupData();
        roundData.tag = roundTag;

        roundData.matchingText = `${roundTag.location?.addressDescription || ''} ${jobsOnRound
            .map(j => j.location?.addressDescription || '')
            .filter(a => !!a)
            .join(' ')}`.toLowerCase();

        if (roundData.tag.frequencyData) {
            roundData.frequencyText = RoundService.getRoundFrequencyText(roundTag);
        }

        if (useJobsNearestToPoint) {
            const lngLats = jobsOnRound.map(j => j.location?.lngLat).filter(notNullUndefinedEmptyOrZero);
            roundData.lngLat = LocationUtilities.nearestLngLat({ source: useJobsNearestToPoint, lngLats });
        } else {
            roundData.lngLat = roundTag.location && roundTag.location.lngLat;
        }

        if (jobsOnRound) {
            if (!roundData.lngLat && jobsOnRound.length) {
                const jobLngLats = <Array<LngLat>>(
                    jobsOnRound.filter(job => job.location && job.location.lngLat).map(job => job.location && job.location.lngLat)
                );
                roundData.lngLat = LocationUtilities.getCentroid(jobLngLats);
            }
            const now = moment();
            const today = now.format('YYYY-MM-DD');
            for (const job of jobsOnRound) {
                if (job.frequencyType === FrequencyType.NoneRecurring) {
                    const occurrences = Data.all<JobOccurrence>(
                        'joboccurrences',
                        o => {
                            const date = o.isoPlannedDate || o.isoDueDate;
                            return date >= today;
                        },
                        { jobId: job._id }
                    );
                    const originalDateIncluded =
                        !!job.date &&
                        occurrences.some(
                            o => (o.isoPlannedDate && o.isoPlannedDate.slice(0, 10) === job.date) || o.isoDueDate?.slice(0, 10) === job.date
                        );

                    roundData.financialData.totalOneOff += occurrences.map(o => Number(o.price || 0) || 0).reduce((p1, p2) => p1 + p2, 0);
                    if (!originalDateIncluded && job.date) roundData.financialData.totalOneOff += Number(job.price || 0) || 0;
                } else {
                    roundData.financialData.totalPerYear +=
                        parseFloat((job.price || 0).toString()) * JobService.getJobOccurancesPerYear(job);
                }

                roundData.jobs.push(job);
            }
            roundData.financialData.totalPerMonth =
                roundData.financialData.totalPerYear > 0 ? roundData.financialData.totalPerYear / 12 : 0;
            roundData.financialData.avgPerJobPerYear =
                roundData.financialData.totalPerYear > 0 ? roundData.financialData.totalPerYear / roundData.jobs.length : 0;
            roundData.financialData.avgPerJobPerMonth =
                roundData.financialData.avgPerJobPerYear > 0 ? roundData.financialData.avgPerJobPerYear / 12 : 0;
        }
        return roundData;
    }

    public static async hardResetRoundSchedules() {
        const rounds = await this.getRounds();
        for (const round of rounds.filter(round => round.frequencyData)) {
            if (!round.frequencyData) continue;

            const nextDate = this.getRoundNextDueDate(round.frequencyData);
            if (nextDate) round.frequencyData.firstScheduledDate = nextDate;

            await this.setRoundFrequencyData(round._id, round.frequencyData);
        }
    }

    public static getRoundNextDueDate(frequencyData: FrequencyData) {
        const frequency = new Frequency(
            moment(frequencyData.firstScheduledDate),
            frequencyData.interval,
            frequencyData.type,
            frequencyData.weekOfMonth,
            frequencyData.dayOfMonth,
            frequencyData.dayOfWeek
        );

        return frequency.getNextDate();
    }

    //TODO Create a merge method to properly merge rounds
}
