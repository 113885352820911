import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import type { Lecture } from '../Views/TrainingView';

export const LecturePanel = ({ lecture, onClickHandler }: { lecture: Lecture; onClickHandler: (lecture: Lecture) => void }) => {
    const { title, imageUrl } = lecture;

    return (
        <Box onClick={() => onClickHandler(lecture)}>
            <Card sx={{ maxWidth: 310, bgcolor: 'gainsboro' }}>
                <CardMedia component="img" height="160" image={imageUrl} alt={title} />
                <CardContent>
                    <Typography variant="body1">{title}</Typography>
                </CardContent>
            </Card>
        </Box>
    );
};
