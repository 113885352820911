import type { BalanceDbClearOptions, CoreLedgerType, ILedgerDetails, LedgerId } from '@nexdynamic/balance-core';
import { LedgerDetailsProvider } from '@nexdynamic/balance-core';
import { TransactionUtilities, type Customer, type ExpenseCategory, type PaymentAccount, type Supplier } from '@nexdynamic/squeegee-common';
import { t } from 'i18next';
import { Data } from '../../Data/Data';

export class SqueegeeLedgersProvider extends LedgerDetailsProvider {
    get(_id: string): Promise<ILedgerDetails | undefined> {
        throw new Error('Method not implemented.');
    }
    async all(): Promise<ILedgerDetails[]> {
        const customers = Data.all<Customer>('customers');
        const ledgers: ILedgerDetails[] = [];

        for (const r of customers) {
            const ledgerDetails: ILedgerDetails = {
                id: `assets.current_assets.debtors.${TransactionUtilities.sanitizeId(r._id)}`,
                name: `${r.name} (${r._externalId})`,
                type: 'assets',
                description: t('balance.customer_debtors_description'),
            };
            ledgers.push(ledgerDetails);
        }

        const suppliers = Data.all<Supplier>('supplier');
        for (const r of suppliers) {
            const ledgerDetails: ILedgerDetails = {
                id: `liabilities.current_liabilities.creditors.suppliers.${TransactionUtilities.sanitizeId(r._id)}`,
                name: `${r.name} (${r._externalId})`,
                type: 'liabilities',
                description: t('balance.suppliers_creditors_description'),
            };
            ledgers.push(ledgerDetails);
        }

        const paymentAccounts = Data.all<PaymentAccount>('paymentaccounts');
        for (const r of paymentAccounts) {
            let type: LedgerId = 'assets.current_assets.funds.bank';
            if (r.type) {
                const mappedType = TransactionUtilities.mapAccountTypeToLedgerId(r.type, r.subType);
                if (mappedType) {
                    type = mappedType;
                }
            }
            const ledgerDetails: ILedgerDetails = {
                id: `${type}.${TransactionUtilities.sanitizeId(r._id)}`,
                name: `${r.name}`,
                type: `${type.split('.')[0]}` as CoreLedgerType,
                description: t('balance.payments_accounts_description'),
            };
            ledgers.push(ledgerDetails);
        }

        // const transactions = Data.all<Transaction>('transactions');

        // await BalanceRouter.streamData<Transaction>(
        //     res,
        //     userTable,
        //     'transactions',
        //     RethinkDb.r.row('_deleted').default(false).eq(false).and(RethinkDb.r.row('transactionType').eq(TransactionType.Invoice)),
        //     r => {
        //         const ledgerDetails: ILedgerDetails = {
        //             id: `assets.current_assets.debtors.${r.customerId}.${r._id}`,
        //             name: `Invoice ${r.invoice?.invoiceNumber} (${moment(r.date).format('ll')})`,
        //             type: 'assets',
        //             description: `Debtor / accounts receivable account for invoice`,
        //         };
        //         return [ledgerDetails];
        //     },
        //     false
        // );

        const expenseCategorys = Data.all<ExpenseCategory>('tags', { type: 3 });

        for (const r of expenseCategorys) {
            const ledgerDetails: ILedgerDetails = {
                id: `expenditure.operating.overheads.${TransactionUtilities.sanitizeId(r._id)}`,
                name: `${r.description}`,
                type: 'expenditure',
                description: `${t('balance.expenditure_category_description')} ${r.description}`,
            };
            ledgers.push(ledgerDetails);
        }

        return ledgers;
    }
    id = 'SqueegeeLedgersProvider';

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async clear(_options?: BalanceDbClearOptions): Promise<void> {
        //
    }
}
