import { GradientButton } from '@nexdynamic/nex-ui-react';
import { useNavigate } from 'react-router';
import useTranslation from '../../../../hooks/useTranslation';

const AudiencesCreateButton = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <GradientButton
            data-testid='AudiencesCreateButton'
            text={t('audiences.create-action')}
            onClick={() => navigate('create')}
            color='secondary'
        ></GradientButton>
    );
};

export default AudiencesCreateButton;
