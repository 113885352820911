import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { Box, Checkbox, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useMultiSelect } from '@nexdynamic/nex-ui-react';
import type { Customer, Notification } from '@nexdynamic/squeegee-common';
import type { Moment } from 'moment';
import moment from 'moment';
import { Message } from '../../../Messages';
import { t } from '../../../t';
import useStoredObject from '../../hooks/useStoredObject';
import useTranslation from '../../hooks/useTranslation';
import useInboxMarkAsRead from '../hooks/useInboxMarkAsRead';

type Props = {
    id: string;
    notification: Notification;
    onSelect?: (notification: Notification) => void;
};

const formatDate = (date: Moment) => {
    const today = moment(); // Assuming today is already defined
    const currentYear = today.year();

    let formatString = 'Do MMM HH:mm'; // Default formatting

    // Check if the message date is from a different year
    if (date.year() !== currentYear) {
        formatString = 'l'; // Use localized date format, including the year
    } else if (date.isSame(today, 'day')) {
        // If the message is from today
        formatString = `HH:mm [${t('dates.today')}]`;
    } else {
        const yesterday = today.clone().subtract(1, 'day');
        const tomorrow = today.clone().add(1, 'day');

        if (date.isSame(yesterday, 'day')) {
            // If the message is from yesterday
            formatString = `HH:mm [${t('dates.yesterday')}]`;
        } else if (date.isSame(tomorrow, 'day')) {
            // If the message is for tomorrow
            formatString = `HH:mm [${t('dates.tomorrow')}]`;
        }
        // If none of the above conditions are met, it uses the default format 'Do MMM HH:mm'
    }

    return date.format(formatString);
};

const MailItem = ({ id, notification, onSelect }: Props) => {
    const { select, isSelected } = useMultiSelect<Notification>();
    const customer = useStoredObject<Customer>(notification.customerId, { watch: false });
    const message = Message.fromNotification(notification);
    const { t } = useTranslation();
    const markAsRead = useInboxMarkAsRead();

    const checked = isSelected?.(notification);
    const labelId = 'mail-' + id;

    const isInbound = message.outgoing === false;
    const unread = message.unread;
    const to = customer?.name || t('customer.unknown');

    const primary = (
        <Typography noWrap fontWeight={unread ? 'bold' : 'normal'}>
            {isInbound ? to : `To: ${to}`}
        </Typography>
    );
    const messageDate = !notification.sendAtSecondsTimestamp
        ? moment(message.createdDate)
        : moment.unix(notification.sendAtSecondsTimestamp);

    const displayDate = message.pending ? t('messages.status-pending') : formatDate(messageDate);

    return (
        <ListItem
            id={id}
            disablePadding
            sx={{
                bgcolor: unread ? 'background.paper' : 'rgba(180, 180, 180, 0.1)',
                border: 0,
                borderBottom: 1,
                borderColor: 'divider',
            }}
        >
            <Box component={onSelect ? ListItemButton : ListItem} onClick={() => onSelect && onSelect(notification)} dense>
                {select && (
                    <ListItemIcon>
                        <Checkbox
                            onClick={e => {
                                e.stopPropagation();
                                select(notification);
                            }}
                            edge="start"
                            checked={checked}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                        />
                    </ListItemIcon>
                )}
                <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, overflow: 'hidden' }}>
                    <ListItemText
                        sx={{ flex: 1 }}
                        id={labelId}
                        primary={primary}
                        secondary={<Typography noWrap>{message.content}</Typography>}
                    />

                    {message.unread === true ? (
                        <IconButton
                            color="info"
                            onClick={event => {
                                event.stopPropagation();
                                markAsRead([notification]);
                            }}
                        >
                            <MarkEmailReadIcon />
                        </IconButton>
                    ) : null}
                    <Box sx={{ flex: '0 0 64px', textAlign: 'right' }}>
                        <Typography variant="caption">{displayDate}</Typography>
                    </Box>
                </Box>
            </Box>
        </ListItem>
    );
};

export default MailItem;
