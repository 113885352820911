import { List } from '@mui/material';
import { LecturePanel } from '../components/LecturePanel';
import { useLectures } from '../hooks/useLectures';
import type { Lecture } from '../Views/TrainingView';

export const SearchLectures = ({ clickHandler, searchText }: { clickHandler: (lecture: Lecture) => void; searchText?: string }) => {
    const { lectures: _lectures } = useLectures();
    const words = searchText?.toLowerCase().trim().split(' ');

    const foundLectures = _lectures.filter(lecture => {
        if (!words) return true;

        const title = lecture.title.toLowerCase();
        if (words.every(word => title.includes(word))) return true;

        const lesson = lecture.lesson.toLowerCase();
        if (words.every(word => lesson.includes(word))) return true;

        const content = lecture.content.toLowerCase();
        if (words.every(word => content.includes(word))) return true;

        return false;
    });

    if (!foundLectures) return null;

    const lectures = foundLectures.map((lecture: Lecture) => {
        return <LecturePanel key={lecture.videoId} lecture={lecture} onClickHandler={clickHandler} />;
    });

    return <List sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>{lectures}</List>;
};
