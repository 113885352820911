import { activationStrategy } from 'aurelia-router';
import { ExpenseCategories } from './ExpenseCategories';
export class ExpenseCategoriesModule {

    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    module: ReturnType<typeof ExpenseCategories>;

    attached() {
        const container = document.getElementById('expense-categoires-root');
        if (!container) throw new Error('expense-categoires-root element not found');
        this.module = ExpenseCategories(container);
    }

    detached() {
        this.module?.unmount();
    }
}
