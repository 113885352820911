import type { BulkPriceChangeProps, PriceChangeNearest } from '@nexdynamic/squeegee-common';
import { BulkPriceChange, PriceChangeNearestValues, to2dp } from '@nexdynamic/squeegee-common';

export class ChangeFields {
    id?: string;
    name = '';
    description? = '';

    type: 'percentage' | 'fixed' = 'percentage';
    value = 1;
    nearest?: PriceChangeNearest = PriceChangeNearestValues[0];
    plannedDate = new Date().valueOf();

    static toBulkPriceChange(fields: ChangeFields) {
        const updateType: BulkPriceChangeProps = {
            name: fields.name,
            description: fields.description,
            plannedDate: fields.plannedDate,
            type:
                fields.type === 'percentage'
                    ? {
                          is: fields.type,
                          value: to2dp(fields.value / 100),
                          nearest: fields.nearest || 0.01,
                      }
                    : {
                          is: fields.type,
                          value: fields.value,
                      },
        };
        return new BulkPriceChange(updateType);
    }

    static updateBulkPriceChange(change: BulkPriceChange, fields: ChangeFields) {
        change.name = fields.name;
        change.description = fields.description;
        change.plannedDate = fields.plannedDate;
        change.type =
            fields.type === 'percentage'
                ? {
                      is: fields.type,
                      value: to2dp(fields.value / 100),
                      nearest: fields.nearest || 0.01,
                  }
                : {
                      is: fields.type,
                      value: fields.value,
                  };
        return change;
    }

    static fromBulkPriceChange(change: BulkPriceChange) {
        const fields = new ChangeFields();
        fields.id = change._id;
        fields.name = change.name;
        fields.description = change.description;
        fields.plannedDate = change.plannedDate;
        fields.type = change.type.is;
        fields.value = change.type.is === 'percentage' ? Math.round(change.type.value * 100) : change.type.value;
        fields.nearest = change.type.is === 'percentage' ? change.type.nearest : undefined;
        return fields;
    }
}
