import type { SxProps } from '@mui/material';
import { ListItemText, Paper, Stack } from '@mui/material';

type SettingCardProps = {
    primary: string | React.ReactNode;
    secondary: string | React.ReactNode;
    toggleComponent?: React.ReactNode;
    inputComponent?: React.ReactNode;
    optional?: boolean;
    sx?: SxProps;
    children?: React.ReactNode;
};

export const SettingCard = ({ primary, secondary, toggleComponent, inputComponent, sx, children }: SettingCardProps) => {
    return (
        <Paper sx={{ p: 2, ...sx }}>
            <Stack direction="row" width="100%" justifyContent="space-between">
                <ListItemText primary={primary} secondary={secondary} />
                {toggleComponent}
            </Stack>
            {inputComponent}
            {children}
        </Paper>
    );
};
