import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import type { Theme } from '@mui/material';
import { Box, ButtonBase, Paper, ThemeProvider, Typography } from '@mui/material';

type Props = {
    theme: {
        id: string;
        name: string;
        theme: Theme;
    };
    selected: boolean;
    onClick: () => void;
    onDelete?: () => void;
};

const ThemeCard = ({ theme, selected, onClick, onDelete }: Props) => {
    const { palette } = theme.theme;
    return (
        <Box sx={{ position: 'relative' }}>
            {onDelete && (
                <DeleteIcon
                    onClick={onDelete}
                    sx={{
                        position: 'absolute',
                        top: 2,
                        right: 2,
                        zIndex: 1,
                        color: palette.primary.contrastText,
                        width: 16,
                        height: 16,
                        cursor: 'pointer',
                    }}
                />
            )}
            <ButtonBase
                sx={{
                    position: 'relative',
                    display: 'flex',
                    width: 150,
                    height: 100,
                    borderRadius: 1,
                    border: 1,
                    borderColor: palette.primary.main,
                    flexDirection: 'column',
                    background: palette.background.default,
                    overflow: 'hidden',
                }}
                onClick={onClick}
            >
                <ThemeProvider theme={theme.theme}>
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            height: 24,
                            backgroundColor: palette.primary.main,
                            alignItems: 'center',
                            px: 1,
                        }}
                    >
                        <Typography variant="caption" sx={{ color: palette.primary.contrastText, fontFamily: 'Roboto' }}>
                            {theme.name}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            position: 'relative',
                            display: 'grid',
                            gridTemplateColumns: 'repeat(2, 1fr)',
                            gridTemplateRows: 'repeat(2, 1fr)',
                            width: '100%',
                            height: '100%',
                            gap: 1,
                            p: 1,
                        }}
                    >
                        {selected && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    height: '100%',
                                    backdropFilter: 'blur(2px)',
                                    position: 'absolute',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        p: 1,
                                        borderRadius: '50%',
                                        border: 1,
                                        borderColor: palette.primary.dark,
                                        background: palette.primary.main,
                                    }}
                                >
                                    <DoneIcon
                                        fontSize="large"
                                        sx={{
                                            color: palette.primary.contrastText,
                                        }}
                                    />
                                </Box>
                            </Box>
                        )}
                        <Paper
                            sx={{
                                display: 'flex',
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                            }}
                        >
                            <Box sx={{ width: 16, height: 16, borderRadius: 8, backgroundColor: palette.primary.dark }} />
                            <Box sx={{ width: 16, height: 16, borderRadius: 8, backgroundColor: palette.primary.main }} />
                            <Box sx={{ width: 16, height: 16, borderRadius: 8, backgroundColor: palette.primary.light }} />
                        </Paper>
                        <Paper
                            sx={{
                                display: 'flex',
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                            }}
                        >
                            <Box sx={{ width: 16, height: 16, borderRadius: 8, backgroundColor: palette.secondary.dark }} />
                            <Box sx={{ width: 16, height: 16, borderRadius: 8, backgroundColor: palette.secondary.main }} />
                            <Box sx={{ width: 16, height: 16, borderRadius: 8, backgroundColor: palette.secondary.light }} />
                        </Paper>
                        <Paper
                            sx={{
                                display: 'flex',
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                            }}
                        >
                            <Box sx={{ width: 16, height: 16, borderRadius: 8, backgroundColor: palette.success.dark }} />
                            <Box sx={{ width: 16, height: 16, borderRadius: 8, backgroundColor: palette.success.main }} />
                            <Box sx={{ width: 16, height: 16, borderRadius: 8, backgroundColor: palette.success.light }} />
                        </Paper>
                        <Paper
                            sx={{
                                display: 'flex',
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                                alignItems: 'center',
                                justifyContent: 'space-evenly',
                            }}
                        >
                            <Box sx={{ width: 16, height: 16, borderRadius: 8, backgroundColor: palette.error.dark }} />
                            <Box sx={{ width: 16, height: 16, borderRadius: 8, backgroundColor: palette.error.main }} />
                            <Box sx={{ width: 16, height: 16, borderRadius: 8, backgroundColor: palette.error.light }} />
                        </Paper>
                    </Box>
                </ThemeProvider>
            </ButtonBase>
        </Box>
    );
};

export default ThemeCard;
