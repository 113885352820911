import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ViewLoading from '../components/ViewLoading';
import { MainAnalytics } from './views/MainAnalytics';

function AnalyticsApp() {
    return (
        <Suspense fallback={<ViewLoading />}>
            <Routes>
                <Route path='analytics/*' element={<MainAnalytics />}></Route>
            </Routes>
        </Suspense>
    );
}

export default AnalyticsApp;
