import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import type { EditorTemplate } from '@nexdynamic/send-editor/core';

type TemplatePreviewProps = {
    template: EditorTemplate;
    onClick: (template: EditorTemplate) => void;
    selected?: boolean;
    selectText?: string;
};

export const BlockTemplatePreview = ({ template, onClick, selected, selectText }: TemplatePreviewProps) => {
    return (
        <Card sx={{ maxWidth: 345, ...(selected ? { outline: 3, outlineColor: 'success.main' } : {}) }}>
            <CardMedia sx={{ height: 100 }} image="/sqg.png" title="Basic" />
            <CardContent>
                <Typography variant="h5" component="div">
                    {template.name}
                </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: 'flex-end' }}>
                {selected ? (
                    <Box>
                        <CheckCircleOutline fontSize="large" sx={{ color: 'success.main' }} />
                    </Box>
                ) : (
                    <Button variant="outlined" size="small" onClick={() => onClick(template)}>
                        {selectText || 'Select'}
                    </Button>
                )}
            </CardActions>
        </Card>
    );
};

type ChooseStartingTemplateProps = {
    onSelect: (template: EditorTemplate) => void;
    value?: EditorTemplate;
};

const DefaultTemplates: EditorTemplate[] = [
    {
        id: 'Basic-template',
        name: 'Basic',
        blocks: {
            id: 'Basic-template',
            label: 'Root',
            tag: 'Container',
            props: {
                style: {
                    backgroundColor: '#fff',
                    minHeight: '600px',
                    maxHeight: '100%',
                    height: '100%',
                    maxWidth: '600px',
                    minWidth: '310px',
                    width: '100%',
                    margin: 'auto',
                },
            },
            children: [],
        },
    },
];

export const ChooseStartingTemplate = ({ onSelect, value }: ChooseStartingTemplateProps) => {
    return (
        <Box sx={{ p: 2, backgroundColor: 'background.default', borderRadius: 1 }}>
            {DefaultTemplates.map(template => (
                <BlockTemplatePreview selected={template.id === value?.id} onClick={onSelect} key={template.id} template={template} />
            ))}
        </Box>
    );
};
