import type { AudienceFilterGroup, Customer, Job, JobOccurrence } from '@nexdynamic/squeegee-common';
import { audienceFilterToCustomerSearch, searchCustomers, searchJobs } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../../../../../ApplicationState';
import { Data } from '../../../../../../Data/Data';

export const getAffectedJobsAndOccurrences = (filterGroups: Array<AudienceFilterGroup>) => {
    const searchText = audienceFilterToCustomerSearch(filterGroups);
    const customers = Data.all<Customer>('customers');

    const affectedCustomers = searchCustomers({
        customers,
        searchText,
        searchJobs: true,
        mobilePrefixes: ApplicationState.mobilePrefixes || undefined,
    });

    const jobs = affectedCustomers.reduce<Array<Job>>((jobs, customer) => {
        jobs.push(...Object.values(customer.jobs));
        return jobs;
    }, new Array<Job>());

    const affectedJobs = searchJobs({ jobs, searchText, ignoreNonJobFields: true });

    const getOccurrences = (jobId: string) => Data.all<JobOccurrence>('joboccurrences', { jobId }).slice();

    const jobsAndOccurrences = affectedJobs.map(job => ({ job, occurrences: getOccurrences(job._id) }));

    return jobsAndOccurrences;
};
