import { Box, Breadcrumbs, Fade, Grid } from '@mui/material';
import { GradientButton, PageContainer } from '@nexdynamic/nex-ui-react';
import { useNavigate } from 'react-router-dom';
import Text from '../../components/Text';
import useTranslation from '../../hooks/useTranslation';
import SupplierList from '../components/SuppliersList';

const SuppliersView = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Fade in={true}>
            <div>
                <PageContainer>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ flex: 1 }}>
                                <Breadcrumbs>
                                    <Text variant='h5' fontWeight='bold' color='text.primary' text='suppliers.title' />
                                </Breadcrumbs>
                            </Box>
                            <Box>
                                <GradientButton color='primary' text={t('suppliers.add')} onClick={() => navigate('/suppliers/add')} />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <SupplierList />
                    </Grid>
                </PageContainer>
            </div>
        </Fade>
    );
};

export default SuppliersView;
