import type { Transaction, TranslationKey } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { Prompt } from '../Dialogs/Prompt';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { TransactionService } from '../Transactions/TransactionService';

interface PaymentActionResult {
    error?: TranslationKey;
    completed: boolean;
}

export class PaymentActions {
    public static async cancelPayment(paymentId: string): Promise<PaymentActionResult> {
        const payment = Data.get<Transaction>(paymentId);
        if (!payment) return { completed: false, error: 'payments.no-payment-found' };

        if (payment.paymentDetails?.refundId) return { completed: false, error: 'payments.payment-already-refunded' };

        const isAllocatedToInvoices = Boolean(
            payment.paymentDetails && payment.paymentDetails.invoiceIds && payment.paymentDetails.invoiceIds.length
        );

        let msg = ApplicationState.localise('payments.cancel-confirm-description');

        if (isAllocatedToInvoices) {
            const count = (payment.paymentDetails?.invoiceIds?.length || 1) - 1;
            msg = ApplicationState.localise(
                count ? 'payments.cancel-confirm-with-other-invoices' : 'payments.cancel-confirm-with-no-other-invoices',
                { count: count.toFixed(0) }
            );
        }

        const prompt = new Prompt('menubar.cancel-payment', msg, { okLabel: 'general.yes' });
        await prompt.show();

        if (prompt.cancelled) return { completed: false, error: 'payments.cancellation-aborted' };

        try {
            await TransactionService.cancelTransaction(payment);
            return { completed: true };
        } catch (err) {
            Logger.error('Unable to cancel payment', err);
            const error = ApplicationState.localise('payments.cancel-failed');
            new NotifyUserMessage(error);
            return { completed: false, error };
        }
    }
}
