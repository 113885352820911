import type { AudienceFilterModel } from '@nexdynamic/squeegee-common';
import { useEffect, useState } from 'react';
import { DataRefreshedEvent } from '../../../../../Events/DataRefreshedEvent';
import { audienceFiltersResourceTypesToWatch, getAudienceFilters } from '../AudienceFilters';

let cachedFilters: Array<AudienceFilterModel>;

const useAudienceFilters = () => {
    const [filters, setFilters] = useState<AudienceFilterModel[]>(cachedFilters || (cachedFilters = getAudienceFilters()));

    useEffect(() => {
        // Update the filters when data needed to build the filters is changed

        const sub = DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
            if (event.hasAnyType(...audienceFiltersResourceTypesToWatch)) setFilters((cachedFilters = getAudienceFilters()));
        });

        return () => {
            sub.dispose();
        };
    }, []);

    return filters;
};

export default useAudienceFilters;
