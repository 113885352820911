import StoreOutlined from '@mui/icons-material/StoreOutlined';
import { GradientIcon } from '@nexdynamic/nex-ui-react';

const SuppliersIcon = () => {
    return (
        <GradientIcon gradient='audiences'>
            <StoreOutlined />
        </GradientIcon>
    );
};

export default SuppliersIcon;
