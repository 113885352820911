import { activationStrategy } from 'aurelia-router';
import { Library } from './Library';

export class LibraryModule {
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    library: ReturnType<typeof Library>;

    attached() {
        const container = document.getElementById('library-root');
        if (!container) throw new Error('library-root element not found');
        this.library = Library(container);
    }

    detached() {
        this.library?.unmount();
    }
}
