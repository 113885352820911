import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';
import AppProviders from '../../ReactUI/AppProviders';
import ApplicationStateProvider from '../../ReactUI/components/ApplicationStateProvider';
import { BrowserFallBackToHashRouter } from '../../ReactUI/components/BrowserFallBackToHashRouter';
import Text from '../../ReactUI/components/Text';
import ViewLoading from '../../ReactUI/components/ViewLoading';
import BalanceRoutes from './BalanceRoutes';
import { SqueegeeBalance } from './SqueegeeBalance';

export const Balance = (element: HTMLElement) => {
    const root = ReactDOM.createRoot(element);

    root.render(
        <AppProviders>
            <ApplicationStateProvider>
                <SqueegeeBalance>
                    <BrowserFallBackToHashRouter>
                        <ErrorBoundary fallback={<Text text='general.unable-to-load-view' />}>
                            <Suspense fallback={<ViewLoading />}>
                                <Routes>
                                    <Route path='/balance/*' element={<BalanceRoutes />}></Route>
                                </Routes>
                            </Suspense>
                        </ErrorBoundary>
                    </BrowserFallBackToHashRouter>
                </SqueegeeBalance>
            </ApplicationStateProvider>
        </AppProviders>
    );

    return {
        unmount: () => root.unmount(),
    };
};
