import { JobOccurrenceStatus } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { useMemo } from 'react';
import { ScheduleService } from '../../Schedule/ScheduleService';

export const useScheduleItemsByDay = (date: string) => {
    const scheduleItemsByDay = useMemo(() => {
        const dateMoment = moment(date);
        return ScheduleService.getScheduledJobsByDay(dateMoment, undefined, [
            JobOccurrenceStatus.NotDone,
            JobOccurrenceStatus.Done,
            JobOccurrenceStatus.Skipped,
        ]);
    }, [date]);
    return { scheduleItemsByDay };
};
