import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { useState } from 'react';

const TermsAndConditions = ({ htmlText, acceptanceChanged }: { htmlText: string; acceptanceChanged: (accepted: boolean) => void }) => {
    const [accepted, setAccepted] = useState(false);

    const handleAcceptanceChange = (checked: boolean) => {
        setAccepted(checked);
        acceptanceChanged(checked);
    };

    return (
        <Box>
            <Box overflow={'scroll'} sx={{ backgroundColor: 'silver' }} p={1}>
                <Typography component='div' mb={2} dangerouslySetInnerHTML={{ __html: htmlText }} height={120} overflow-y={'scroll'} />
            </Box>
            <Box display={'flex'} alignItems={'center'}>
                <FormControlLabel
                    control={<Checkbox checked={accepted} onChange={(_event, checked) => handleAcceptanceChange(checked)} />}
                    label='I accept the Terms and Conditions'
                />
            </Box>
        </Box>
    );
};

TermsAndConditions.propTypes = {
    htmlText: PropTypes.string.isRequired,
};

export default TermsAndConditions;
