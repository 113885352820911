import PriceChangeOutlined from '@mui/icons-material/PriceChangeOutlined';
import { GradientIcon } from '@nexdynamic/nex-ui-react';

export const ChangeIcon = () => {
    return (
        <GradientIcon gradient="pricing">
            <PriceChangeOutlined />
        </GradientIcon>
    );
};
