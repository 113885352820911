import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import { ApplicationState } from '../../../ApplicationState';

type MenuProps = {
    items: Array<{ title: TranslationKey; func: <T>() => T | void }>;
};
export const BasicMenu = ({ items }: MenuProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const t = (key: TranslationKey) => {
        return ApplicationState.localise(key);
    };

    return (
        <Box sx={{ mr: '1rem' }}>
            <IconButton onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                {items.map((item, index) => {
                    return (
                        <MenuItem key={index} onClick={item.func}>
                            {t(item.title)}
                        </MenuItem>
                    );
                })}
            </Menu>
        </Box>
    );
};
