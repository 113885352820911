import { Divider, List, Paper } from '@mui/material';
import type { TimeEntry } from '@nexdynamic/squeegee-common';
import { UserService } from '../../../Users/UserService';
import LeaderboardListItem from './LeaderboardListItem';

type LeaderboardProps = {
    timeEntries: TimeEntry[];
};

const Leaderboard = ({ timeEntries }: LeaderboardProps) => {
    const users = UserService.getActiveUsers();

    const userTimes = users
        .map(user => {
            const userTime = timeEntries
                .filter(entry => entry.assigneeId === user._id)
                .reduce((acc, curr) => {
                    const entryStart = curr.start?.eventTimestamp;
                    const entryEnd = curr.stop?.eventTimestamp;
                    if (!entryStart || !entryEnd) {
                        return acc;
                    }
                    return acc + (entryEnd - entryStart);
                }, 0);
            return { userId: user._id, time: userTime };
        })
        .sort((a, b) => b.time - a.time)
        .slice(0, 3);

    return (
        <>
            <Paper elevation={1} sx={{ height: '100%', borderRadius: '10px' }}>
                <List sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-around' }}>
                    {userTimes.map(userTime => {
                        const user = users.find(user => user._id === userTime.userId);
                        if (!user) return;

                        const userRanks = userTimes.map((userTime, index) => {
                            return { ...userTime, rank: index + 1 };
                        });
                        const userRank = userRanks.find(userRank => userRank.userId === userTime.userId)?.rank;

                        return (
                            <>
                                <LeaderboardListItem key={userTime.userId} user={user} time={userTime.time} rank={userRank || 0} />
                                {userRank !== 3 ? <Divider /> : null}
                            </>
                        );
                    })}
                    {userTimes.length < 3 && <LeaderboardListItem user={null} time={0} rank={null} />}
                    {userTimes.length < 2 && <LeaderboardListItem user={null} time={0} rank={null} />}
                </List>
            </Paper>
        </>
    );
};

export default Leaderboard;
