import { Box, keyframes } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import type { IAdvertisement } from '@nexdynamic/squeegee-common';
import { EmptyAdvertList } from './EmptyAdvertList';
import MyAdvertListingCard from './MyAdvertListingCard';
type MarketplaceAdvertsListProps = {
    adverts: Array<IAdvertisement>;
    onSelect: (advert: IAdvertisement) => void;
};

const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
export const MyAdvertList = ({ adverts, onSelect }: MarketplaceAdvertsListProps) => {
    return (
        <>
            <Box sx={{ px: 2 }}>
                <Grid2
                    sx={{
                        animation: `${fade} 1s ease 0.2s both`,
                    }}
                    container
                    spacing={2}
                >
                    {(adverts.length &&
                        adverts.map(advert => {
                            return (
                                <Grid2 key={advert.id} xl={4} lg={6} md={12} sm={12} xs={12}>
                                    <MyAdvertListingCard onClick={advert => onSelect(advert)} advert={advert} />
                                </Grid2>
                            );
                        })) || <EmptyAdvertList />}
                </Grid2>
            </Box>
        </>
    );
};
