import type { SchemaOf } from 'yup';
import { array, object, string } from 'yup';
import { ApplicationState } from '../../../../../ApplicationState';
import type { CampaignCreateFields } from '../hooks/useCampaignCreate';

const t = ApplicationState.localise;

export const CampaignCreateSchema = () => {
    const CampaignCreateSchema: SchemaOf<CampaignCreateFields> = object({
        name: string().required(t('campaigns-form.name-required')),
        audienceIds: array().min(1, t('campaigns-form.audienceIds-min')).required(t('campaigns-form.audienceIds-min')),
        selectedDeliveryMethods: array().min(1, t('campaigns-form.select-delivery-method-helper')).required(),
        description: string().required(t('campaigns-form.description-required')),
        templateId: string().optional(),
    });

    return CampaignCreateSchema;
};

export default CampaignCreateSchema;
