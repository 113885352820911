import type { useConfirm, useNexAction } from '@nexdynamic/nex-ui-react';
import type { BulkPriceChange } from '@nexdynamic/squeegee-common';
import type { useNavigate } from 'react-router';
import { Data } from '../../../../../Data/Data';
import type useTranslation from '../../../../hooks/useTranslation';

export const removePriceChange = async (
    bulkPriceChange: BulkPriceChange,
    askForConfirmation: ReturnType<typeof useConfirm>['askForConfirmation'],
    handleAction: ReturnType<typeof useNexAction>[0],
    t: ReturnType<typeof useTranslation>['t'],
    navigate: ReturnType<typeof useNavigate>
) => {
    const translations = { name: bulkPriceChange.name };
    return await handleAction(
        {
            successMsg: t('price-change.removed', translations),
            errorMsg: t('price-change.remove-failed', translations),
        },
        async () => {
            if (!bulkPriceChange) return { success: false, errorMsg: 'price-change.not-found' };

            const { agreed } = await askForConfirmation({
                title: t('general.continue'),
                description: bulkPriceChange.notified
                    ? t('price-change.remove-confirm-after-notification')
                    : t('price-change.remove-confirm-before-notification'),
            });
            if (!agreed) return { success: false, notify: false };

            await Data.delete(bulkPriceChange);
            navigate('/pricing/changes');

            return { success: true };
        }
    );
};
