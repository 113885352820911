import { CardActionArea, CardContent, CardMedia, Chip, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import type { IAdvertisementListing } from '@nexdynamic/squeegee-common';
import { getDistanceInteger } from '../../../Utilities';
import { AttachmentCount } from './AttachmentCount';
import { Price } from './Price';

export type ItemCardProps = {
    advert: IAdvertisementListing;
    onClick: (advert: IAdvertisementListing) => void;
};

const AdvertListingCard = ({ advert, onClick }: ItemCardProps) => {
    const { title, description, sellerName, price, currency, tags, squeegeeAssets, distance } = advert;

    const distanceUsingAccountUnits = getDistanceInteger(distance);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>
            <CardActionArea sx={{ minHeight: '200px' }} onClick={() => onClick(advert)}>
                <Card sx={{ display: 'flex', flexDirection: matches ? 'column' : 'row', minHeight: '200px' }}>
                    {advert.image && <CardMedia sx={{ minWidth: 180, minHeight: 180 }} image={advert.image}></CardMedia>}
                    <CardContent
                        sx={{
                            'position': 'relative',
                            'overflow': 'hidden',
                            'p': 2,
                            'display': 'flex',
                            'flexDirection': 'column',
                            'flex': 1,
                            '&:last-child': {
                                paddingBottom: 0,
                                marginBottom: 0,
                            },
                        }}
                    >
                        <Box sx={{ display: 'flex', alignCenter: 'center', gap: '0.5rem', mb: 0.5, flexWrap: 'wrap' }}>
                            <Tooltip title={`Distance from business address: ${distanceUsingAccountUnits}`}>
                                <Typography variant="body2" sx={{ flex: 1, py: 0.5 }} color="text.secondary" gutterBottom>
                                    Distance: {distanceUsingAccountUnits}
                                </Typography>
                            </Tooltip>

                            <Chip size="small" sx={{ color: 'text.secondary', borderRadius: 2 }} label={sellerName} />
                        </Box>

                        <Box sx={{ flex: 1, overflow: 'hidden' }}>
                            <Typography noWrap={true} textOverflow="ellipsis" fontSize={18}>
                                {title}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    mb: 1.5,
                                    overflow: 'hidden',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '4',
                                    WebkitBoxOrient: 'vertical',
                                    textOverflow: 'ellipsis',
                                }}
                                color="text.secondary"
                            >
                                {description}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', py: 1 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 1,
                                    flex: 1,
                                    alignItems: 'center',
                                    mb: 1,
                                }}
                            >
                                {squeegeeAssets?.length && <AttachmentCount count={squeegeeAssets.length} />}
                                {tags.map(tag => {
                                    return <Chip key={tag} size="small" sx={{ color: 'text.secondary', borderRadius: 2 }} label={tag} />;
                                })}
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'right',
                                }}
                            >
                                <Typography sx={{ lineHeight: '1.5rem' }}>
                                    <Price price={price} currency={currency} />
                                </Typography>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </CardActionArea>
        </>
    );
};

export default AdvertListingCard;
