import { Box, Grid, Paper, Typography } from '@mui/material';
import type { Supplier } from '@nexdynamic/squeegee-common';
import Text from '../../components/Text';
import SupplierActions from './SupplierActions';
import SupplierContactList from './SupplierContactList';
import SupplierRecentTransactions from './SupplierRecentTransactions';

type SupplierDashboardProps = {
    supplier: Supplier;
};

const SupplierDashboard = ({ supplier }: SupplierDashboardProps) => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                        <Box sx={{ flex: 1 }}>
                            <Typography m={0} variant='h4' fontWeight='bold'>
                                {supplier.name}
                            </Typography>
                        </Box>
                        <Box>
                            <SupplierActions supplierId={supplier._id} />
                        </Box>
                    </Box>
                </Grid>

                {supplier.notes ? (
                    <Grid item xs={12}>
                        <Text text='supplier.notes' variant='h6' mb={1} />
                        <Box component={Paper} p={2}>
                            <Typography whiteSpace='pre-wrap'>{supplier.notes}</Typography>
                        </Box>
                    </Grid>
                ) : null}

                <Grid item xs={12}>
                    <Text text='contacts.title' variant='h6' mb={1} />
                    <SupplierContactList supplier={supplier} />
                </Grid>

                <Grid item xs={12}>
                    <SupplierRecentTransactions supplierId={supplier._id} />
                </Grid>
            </Grid>
        </>
    );
};

export default SupplierDashboard;
