import { Box, Breadcrumbs, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Text from '../../components/Text';
import MailListHeader from '../components/MailListHeader';

export const ScheduleMessagesHeader = () => {
    const navigate = useNavigate();

    return (
        <MailListHeader>
            <Box sx={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                <Breadcrumbs>
                    <Link underline='none' component='button' sx={{ cursor: 'pointer' }} onClick={() => navigate('/inbox')}>
                        <Text variant='subtitle1' text='inbox.inbox-title' />
                    </Link>
                    <Text color='text.primary' variant='subtitle1' text='inbox.scheduled' />
                </Breadcrumbs>
            </Box>
        </MailListHeader>
    );
};
