import { ListItem, ListItemText, TextField } from '@mui/material';
import { GradientButton, useIsSmallScreen } from '@nexdynamic/nex-ui-react';
import { useState } from 'react';
import { createPortalTheme, type PortalThemeOptions } from '../../../../DirectoryAndPortal/types/PortalTheme';

type AdvBorderRadiusProps = {
    theme: PortalThemeOptions;
    changeBorderRadius: (borderRadius: number) => void;
};

const AdvBorderRadius = ({ theme: themeProps, changeBorderRadius }: AdvBorderRadiusProps) => {
    const theme = createPortalTheme(themeProps);
    const isSmallScreen = useIsSmallScreen();

    const [borderRadius, setBorderRadius] = useState<number>(theme.shape.borderRadius);

    const [hasChanged, setHasChanged] = useState<boolean>(false);

    return (
        <ListItem sx={{ gap: 1, flexDirection: isSmallScreen ? 'column' : 'row', alignItems: isSmallScreen ? 'flex-start' : 'center' }}>
            <ListItemText primary='Border Radius' secondary='Enter a border radius' />
            <TextField
                variant='outlined'
                size='small'
                value={borderRadius}
                onChange={e => {
                    setBorderRadius(Number(e.target.value));
                    setHasChanged(Number(e.target.value) !== theme.shape.borderRadius);
                }}
                type='number'
            />
            {hasChanged && (
                <GradientButton
                    color='info'
                    onClick={() => {
                        changeBorderRadius(borderRadius);
                    }}
                >
                    Apply
                </GradientButton>
            )}
        </ListItem>
    );
};

export default AdvBorderRadius;
