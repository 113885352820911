import type { Customer, Transaction } from '@nexdynamic/squeegee-common';
import { TransactionType } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { Data } from '../../Data/Data';
import { ExpenseSummaryDialog } from '../../Expenses/Components/ExpenseSummaryDialog';
import { Logger } from '../../Logger';
import { TransactionDialog } from '../../Transactions/Components/TransactionDialog';

export class PaymentCustomElement {
    @bindable payment?: Transaction;

    customer?: Customer;
    protected async view() {
        try {
            if (this.payment) {
                switch (this.payment.transactionType) {
                    case TransactionType.AutomaticPayment:
                    case TransactionType.Payment:
                    case TransactionType.Transfer:
                        new TransactionDialog(this.payment).show();
                        break;
                    case TransactionType.Expense:
                        new ExpenseSummaryDialog(this.payment._id).show();
                        break;
                }
            }
        } catch (error) {
            Logger.error('Error in viewing transaction', error);
        }
    }

    attached() {
        if (this.payment && this.payment.customerId) {
            this.customer = Data.get(this.payment.customerId);
        }
    }

    @computedFrom('payment', 'customer.name')
    get title() {
        if (this.customer) {
            return this.customer.name;
        }
        return this.payment?.description;
    }

    @computedFrom('payment', 'customer.address')
    get description() {
        if (this.customer) {
            return this.customer.address.addressDescription;
        }
        return this.payment?.description;
    }
}
