import { Box, Grid, Radio, Typography } from '@mui/material';

const RadioExamples = () => {
    return (
        <div>
            <Grid container spacing={4} padding={3}>
                <Grid item>
                    <Box sx={{ border: 1, borderRadius: '12px', borderColor: '#ccc', width: '12rem', px: 2, pb: 1, pt: 0.5 }}>
                        <Typography variant='subtitle1' textAlign='center' sx={{ pb: 0.5 }}>
                            Radio Colors
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '0.5rem' }}>
                            <Radio checked={true} color='primary' />
                            <Radio checked={true} color='secondary' />
                            <Radio checked={true} color='error' />
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '0.5rem' }}>
                            <Radio checked={true} color='info' />
                            <Radio checked={true} color='success' />
                            <Radio checked={true} color='warning' />
                        </Box>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{ border: 1, borderRadius: '12px', borderColor: '#ccc', width: '12rem', px: 2, pb: 1, pt: 0.5 }}>
                        <Typography variant='subtitle1' textAlign='center' sx={{ pb: 0.5 }}>
                            Radio Sizes
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '0.5rem' }}>
                            <Radio checked={true} size='small' />
                            <Radio checked={true} size='medium' />
                        </Box>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{ border: 1, borderRadius: '12px', borderColor: '#ccc', width: '12rem', px: 2, pb: 1, pt: 0.5 }}>
                        <Typography variant='subtitle1' textAlign='center' sx={{ pb: 0.5 }}>
                            Radio Disabled
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '0.5rem' }}>
                            <Radio disabled={true} />
                            <Radio checked={true} disabled={true} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default RadioExamples;
