import type { SvgIconTypeMap } from '@mui/material';
import { Box } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import type { TranslationKey } from '@nexdynamic/squeegee-common';
import Text from '../../components/Text';

type Props = {
    icon: OverridableComponent<SvgIconTypeMap<any, 'svg'>>;
    text: TranslationKey;
};
export const InboxEmpty = ({ icon, text }: Props) => {
    const Icon = icon;

    return (
        <Box
            sx={{
                m: 2,
                p: 2,
                borderRadius: 1,
                border: 1,
                borderColor: 'divider',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <Box sx={{ color: 'text.secondary' }}>
                <Icon />
            </Box>
            <Text variant='subtitle1' color='text.secondary' text={text} />
        </Box>
    );
};
