import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import ConnectivityIcon from '@mui/icons-material/CircleRounded';
import { List, ListItem, ListItemButton, ListItemText, Skeleton, Stack, Typography } from '@mui/material';
import { DialogPaper } from '@nexdynamic/nex-ui-react';
import { ApplicationState } from '../../../ApplicationState';
import { SmtpDialog } from '../../../Dialogs/SmtpDialog';
import { Api } from '../../../Server/Api';
import useApplicationState from '../../hooks/useApplicationState';
import { useSqueegeeCredits } from '../../hooks/useSqueegeeCredits';
import useStateFlags from '../../hooks/useStateFlags';
import useTranslation from '../../hooks/useTranslation';
import ActionableListItem from '../components/ActionableListItem';
import { useEmailEngine, type EmailProvider } from '../provider/EmailEngineProvider';
import { useEmailSettingsViewRouter } from '../useEmailSettingsViewRouter';

export const EmailSettingsMain = () => {
    const { t } = useTranslation();
    const { devMode } = useStateFlags();
    const { navigateTo } = useEmailSettingsViewRouter();

    const { credits, loading: loadingCredits, inboundEmailCreditsCharge } = useSqueegeeCredits();
    const { account: emailEngineAccount, emailProviders } = useEmailEngine();
    const { account } = useApplicationState();

    const handleProviderClick = async (provider: EmailProvider) => {
        if (provider.route) navigateTo(provider.route);
    };

    const openLegacySmtpSettingsDialog = async () => {
        const smtpDialog = new SmtpDialog();
        smtpDialog.show();
    };

    if (!emailProviders) return null;

    const username = Object.values(emailProviders).find(x => x.connected)?.connection?.auth.user || 'unknown';
    const visibleProviders = Object.values(emailProviders).filter(
        provider =>
            provider.connected ||
            provider.oneWay === true ||
            ((!provider.devOnly || (provider.devOnly && devMode)) && ApplicationState.features.twoWayEmail)
    );
    return (
        <Stack gap={2}>
            <DialogPaper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                    {t('email-settings.email-providers-title')}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {t('email-settings.current-configuration')}
                    {': '}
                    {t(`email-api.${account.emailAPI || 'squeegee-smtp'}`, { provider: username })}
                </Typography>

                <List sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, py: 2 }}>
                    {visibleProviders.map(provider => {
                        {
                            let connected = false;
                            if (provider.oneWay && (account.emailAPI || 'squeegee-smtp') === 'squeegee-smtp') connected = true;
                            else if (!provider.oneWay && provider.connected) connected = true;

                            return (
                                <ActionableListItem
                                    key={provider.name}
                                    primary={
                                        <Stack direction="row" alignItems="center" gap={1}>
                                            <ConnectivityIcon
                                                sx={{ color: connected ? 'success.main' : 'divider', fontSize: 10, mb: '2px' }}
                                            />
                                            <Typography>
                                                {t(provider.name)}
                                                <Typography component="span" variant="body2" color="text.secondary">
                                                    {provider.connected ? ` (${t('general.connected')})` : ''}
                                                </Typography>
                                            </Typography>
                                        </Stack>
                                    }
                                    secondary={t(provider.description)}
                                    onClick={() => handleProviderClick(provider)}
                                    slots={{
                                        secondaryAction: <ChevronRightIcon />,
                                        icon: provider.icon,
                                    }}
                                    slotProps={{ avatarProps: { color: 'info' } }}
                                    disabled={!Api.isConnected}
                                />
                            );
                        }
                    })}
                </List>
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                    <Typography variant="caption">
                        {t('email-settings.inbound-charge', { emails: (1 / inboundEmailCreditsCharge).toString() })}
                    </Typography>
                    <Typography variant="caption" fontWeight="bold">
                        {loadingCredits && !credits ? <Skeleton /> : credits.toLocaleString()} credits
                    </Typography>
                </Stack>
            </DialogPaper>
            <DialogPaper>
                <List disablePadding>
                    <ListItem disablePadding secondaryAction={<ChevronRightIcon />}>
                        <ListItemButton onClick={() => navigateTo('blocklist')}>
                            <ListItemText
                                primary={t('email-settings.ignored-emails')}
                                secondary={t('email-settings.ignored-emails-subtitle')}
                            />
                        </ListItemButton>
                    </ListItem>
                    {(devMode || account.emailAPI === 'custom-smtp') && (
                        <>
                            <ListItem disablePadding secondaryAction={<ChevronRightIcon />}>
                                <ListItemButton onClick={openLegacySmtpSettingsDialog}>
                                    <ListItemText
                                        primary={t('email-settings.legacy-smtp-title')}
                                        secondary={t('email-settings.legacy-smtp-description')}
                                    />
                                </ListItemButton>
                            </ListItem>
                            {devMode && emailEngineAccount?.webhooks && (
                                <ListItem>
                                    <ListItemText primary="Webhook Server" secondary={emailEngineAccount.webhooks} />
                                </ListItem>
                            )}
                        </>
                    )}
                </List>
            </DialogPaper>
        </Stack>
    );
};
