import { Box, Button, Typography } from '@mui/material';

const UncaughtErrorFallback = () => {
    return (
        <Box
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}
        >
            <Typography variant="h1" fontWeight={500}>
                Oops!
            </Typography>
            <Typography variant="h4" color="textSecondary">
                Something went wrong.
            </Typography>
            <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={() => window.location.reload()}>
                Try again
            </Button>
        </Box>
    );
};

export default UncaughtErrorFallback;
