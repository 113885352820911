import { Button, IconButton, ListItem, ListItemButton, ListItemSecondaryAction, ListItemText } from '@mui/material';
import type { TimeEntry } from '@nexdynamic/squeegee-common';
import { ScheduleService } from '../../../Schedule/ScheduleService';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

type TimeEntrieListItemProps = {
    timeEntry: TimeEntry;
    onEdit: (timeEntry: TimeEntry) => void;
    onDelete: (timeEntry: TimeEntry) => void;
    onClicked: (timeEntry: TimeEntry) => void;
};

export const TimeEntryListItem = ({ timeEntry, onEdit, onDelete, onClicked }: TimeEntrieListItemProps) => {
    const job = timeEntry.occurrenceId ? ScheduleService.getScheduleItemForJobOccurrenceId(timeEntry.occurrenceId) : null;

    const primary = job ? `${job.addressDescription} | ${job.customer.name}` : '-';

    return (
        <ListItem>
            <ListItemButton onClick={() => onClicked(timeEntry)} key={timeEntry.start?.eventTimestamp}>
                <ListItemText
                    primary={primary}
                    secondary={
                        <>
                            Start: {timeEntry.start?.eventTimestamp && new Date(timeEntry.start.eventTimestamp).toLocaleTimeString()} - End:{' '}
                            {timeEntry.type === 'running'
                                ? 'Running'
                                : timeEntry.stop?.eventTimestamp && new Date(timeEntry.stop.eventTimestamp).toLocaleTimeString()}
                        </>
                    }
                ></ListItemText>
            </ListItemButton>
            <ListItemSecondaryAction>
                <Button variant='outlined' size='small' onClick={() => onEdit(timeEntry)} disabled={timeEntry.type === 'running'}>
                    Edit
                </Button>
                <IconButton
                    color='error'
                    size='small'
                    onClick={() => onDelete(timeEntry)}
                    disabled={timeEntry.type === 'running'}
                    sx={{ ml: 1 }}
                >
                    <DeleteOutlineIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default TimeEntryListItem;
