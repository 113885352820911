import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Dialog,
    FormControl,
    IconButton,
    InputLabel,
    ListItem,
    ListItemText,
    MenuItem,
    Popover,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { GradientButton } from '@nexdynamic/nex-ui-react';
import type { AccountUser, TimeEntry } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import type { ScheduleItem } from '../../../../Schedule/Components/ScheduleItem';
import { ScheduleService } from '../../../../Schedule/ScheduleService';
import { TimerService } from '../../../../Tracking/TimerService';
import { UserService } from '../../../../Users/UserService';
import useTranslation from '../../../hooks/useTranslation';

type EditTimeEntryDialogProps = {
    selectedWorkerId: string | null;
    selectedTimeEntry: TimeEntry;
    anchorPosition?: { top: number; left: number } | null;
    open: boolean;
    setOpen: (open: boolean) => void;
    onClose: () => void;
    setDeleteDialog: (open: boolean) => void;
    dialog?: boolean;
};

const EditTimeEntryDialog = ({
    selectedWorkerId,
    selectedTimeEntry,
    anchorPosition,
    open,
    setOpen,
    onClose,
    setDeleteDialog,
    dialog,
}: EditTimeEntryDialogProps) => {
    const onCloseDialog = () => {
        onClose();
        setAutocompleteOpen(false);
    };
    const { t } = useTranslation();

    if (!selectedTimeEntry) {
        throw new Error('selectedTimeEntry is required @EditTimeEntryDialog');
    } else if (!selectedWorkerId) {
        throw new Error('selectedWorkerId is required @EditTimeEntryDialog');
    }

    const initialStartTime = (
        selectedTimeEntry.start?.eventTimestamp ? moment(selectedTimeEntry.start?.eventTimestamp).toDate() : new Date()
    )
        .toTimeString()
        .slice(0, 5);
    const [startTime, setStartTime] = useState(
        selectedTimeEntry.start?.eventTimestamp ? moment(selectedTimeEntry.start?.eventTimestamp).toDate() : new Date()
    );

    const initialEndTime = (selectedTimeEntry.stop?.eventTimestamp ? moment(selectedTimeEntry.stop?.eventTimestamp).toDate() : new Date())
        .toTimeString()
        .slice(0, 5);
    const [endTime, setEndTime] = useState(
        selectedTimeEntry.stop?.eventTimestamp ? moment(selectedTimeEntry.stop?.eventTimestamp).toDate() : new Date()
    );

    const [selectedWorker, setSelectedWorker] = useState<AccountUser | undefined>(UserService.getUserById(selectedWorkerId));
    const [selectedJob, setSelectedJob] = useState<ScheduleItem | undefined>(
        selectedTimeEntry.occurrenceId ? ScheduleService.getScheduleItemForJobOccurrenceId(selectedTimeEntry.occurrenceId) : undefined
    );

    const handleDelete = () => {
        onClose();
        setDeleteDialog(true);
    };

    const workers = UserService.getActiveUsers();

    const [scheduleItems, setScheduleItems] = useState<ScheduleItem[]>([]);

    const onSave = async (startTime: Date, endTime: Date) => {
        if (selectedTimeEntry === null || !selectedJob) throw 'No selectedTimeEntry or selectedJob @EditTimeEntryDialog:78';

        await TimerService.updateTimeEntry(selectedTimeEntry, startTime.valueOf(), endTime.valueOf(), selectedJob.occurrence);
        setOpen(false);
    };

    const [autocompleteOpen, setAutocompleteOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!autocompleteOpen) return;
        setLoading(true);

        const loadTimer = setTimeout(() => {
            if (!selectedWorker) return;
            const scheduleByDay = ScheduleService.getScheduledJobsForUsersAndTeamsByDay([selectedWorker._id], moment(startTime));

            const options = scheduleByDay[moment(startTime).format('YYYY-MM-DD')] || {};
            const scheduleItems = Object.values(options);

            setScheduleItems(scheduleItems);
            setLoading(false);
        }, 100);

        return () => {
            clearTimeout(loadTimer);
        };
    }, [autocompleteOpen]);

    const Form = () => (
        <Box sx={{ width: '24rem', p: 2, pt: 1, pb: 0 }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography variant="body1">Edit Time Entry</Typography>
                <IconButton
                    size="small"
                    onClick={handleDelete}
                    sx={{
                        color: '#FF7961',
                    }}
                >
                    <DeleteOutlineIcon />
                </IconButton>
            </Box>
            <Box display="flex" flexDirection="column" marginTop={1}>
                <FormControl size="small" fullWidth>
                    <InputLabel id="worker">Assignee</InputLabel>
                    <Select
                        disabled
                        size="small"
                        sx={{
                            width: '100%',
                            marginBottom: '1rem',
                        }}
                        label="Assignee"
                        labelId="worker"
                        inputProps={{
                            name: 'worker',
                            id: 'worker',
                        }}
                        value={selectedWorker?._id}
                        onChange={event =>
                            setSelectedWorker(
                                workers.find(worker => {
                                    return worker._id === event.target.value;
                                })
                            )
                        }
                    >
                        {workers.map(worker => {
                            return (
                                <MenuItem key={worker._id} value={worker._id}>
                                    {worker.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', mb: '1rem' }}>
                    <TextField
                        size="small"
                        fullWidth
                        id="time"
                        label="Start"
                        type="time"
                        defaultValue={initialStartTime}
                        onBlur={e => {
                            const [hours, mins] = e.target.value.split(':');

                            const newStartTime = new Date(startTime);
                            newStartTime.setHours(Number(hours), Number(mins), 0, 0);
                            setStartTime(newStartTime);
                            return true;
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                    />
                    <TextField
                        size="small"
                        fullWidth
                        id="time"
                        label="End"
                        type="time"
                        defaultValue={initialEndTime}
                        onBlur={e => {
                            const [hours, mins] = e.target.value.split(':');

                            const newEndTime = new Date(endTime);
                            newEndTime.setHours(Number(hours), Number(mins), 0, 0);
                            setEndTime(newEndTime);
                            return true;
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            step: 300, // 5 min
                        }}
                    />
                </Box>
                <Autocomplete
                    open={autocompleteOpen}
                    onOpen={() => setAutocompleteOpen(true)}
                    onClose={() => setAutocompleteOpen(false)}
                    isOptionEqualToValue={(option, value) => {
                        return option.occurrence._id === value.occurrence._id;
                    }}
                    size="small"
                    options={scheduleItems}
                    loading={loading}
                    getOptionLabel={option =>
                        `${option.addressDescription} | ${option.customerName}` || "shouldn't see this, please contact support."
                    }
                    renderInput={params => (
                        <TextField
                            {...params}
                            label="Job"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </Fragment>
                                ),
                            }}
                        />
                    )}
                    renderOption={(props, si) => {
                        return (
                            <ListItem {...props}>
                                <ListItemText primary={si.addressDescription} secondary={`${si.customerName}`} />
                            </ListItem>
                        );
                    }}
                    noOptionsText="No jobs scheduled within the selected parameters."
                    onChange={(_event, value) => setSelectedJob(value || undefined)}
                    value={selectedJob}
                />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingY: '0.8rem', gap: '0.5rem' }}>
                <Button
                    size="small"
                    variant="text"
                    onClick={(event: React.MouseEvent) => {
                        event.stopPropagation();
                        onClose();
                    }}
                >
                    Cancel
                </Button>
                <GradientButton
                    onClick={(event: React.MouseEvent) => {
                        event.stopPropagation();
                        onSave(startTime, endTime);
                    }}
                    variant="text"
                    color="primary"
                    text={t('general.save')}
                    disabled={!selectedWorker || !selectedJob || !startTime || !endTime}
                />
            </Box>
        </Box>
    );

    return (
        <>
            {!dialog && (
                <Popover
                    open={open}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    anchorReference="anchorPosition"
                    anchorPosition={anchorPosition ? anchorPosition : { top: 0, left: 0 }}
                    onClose={onCloseDialog}
                >
                    <Form />
                </Popover>
            )}

            {dialog && (
                <Dialog open={open} onClose={onCloseDialog}>
                    <Form />
                </Dialog>
            )}
        </>
    );
};

export default EditTimeEntryDialog;
