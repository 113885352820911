import { Box, Collapse, Divider, List, ListItem, ListItemButton, Typography } from '@mui/material';
import { Suspense, useState } from 'react';
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ViewLoading from '../components/ViewLoading';
import Colours from './colours/colours';
import ChartsDemo from './squeegee-viz/ChartsDemo';
import Content from './squeegee-viz/Content';
import TypographyDemo from './typography/typography';
// icons
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AccordionExamples from './ui-components/Accordion';
import AvatarExamples from './ui-components/Avatar';
import BadgeExamples from './ui-components/Badge';
import BreadcrumbExamples from './ui-components/Breadcrumb';
import CardExamples from './ui-components/Card';
import ChipExamples from './ui-components/Chip';
import ListExamples from './ui-components/List';
import TabExamples from './ui-components/Tab';
import ButtonExamples from './ui-components/form-components/ButtonExamples';
import CheckboxExamples from './ui-components/form-components/CheckboxExamples';
import RadioExamples from './ui-components/form-components/RadioExamples';
import SliderExamples from './ui-components/form-components/SliderExamples';
import SwitchExamples from './ui-components/form-components/SwitchExamples';
import TextfeildExamples from './ui-components/form-components/TextfeildExamples';

const LibraryMenu = () => {
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <List sx={{ width: '100%', maxWidth: 260, bgcolor: 'white' }} component='nav' aria-labelledby='nested-list-subheader'>
            <ListItem sx={{ padding: 1, py: 0.25 }}>
                <ListItemButton
                    onClick={() => navigate('typography')}
                    sx={{
                        paddingY: 0.5,
                        borderRadius: 1,
                        color: '#3e5060',
                        '&:hover': {
                            background: '#f5f5f5',
                        },
                        ...(location.pathname === '/library/typography' && {
                            color: '#0072E5',
                            background: '#F0F7FF',
                        }),
                    }}
                >
                    <Typography
                        sx={{
                            color: 'inherit',
                            fontWeight: 500,
                        }}
                    >
                        Typography
                    </Typography>
                </ListItemButton>
            </ListItem>
            <ListItem sx={{ padding: 1, py: 0.25 }}>
                <ListItemButton
                    sx={{
                        paddingY: 0.5,
                        borderRadius: 1,
                        color: '#3e5060',
                        '&:hover': {
                            background: '#f5f5f5',
                        },
                        ...(location.pathname === '/library/colours' && {
                            color: '#0072E5',
                            background: '#F0F7FF',
                        }),
                    }}
                    onClick={() => navigate('colours')}
                >
                    <Typography
                        sx={{
                            color: 'inherit',
                            fontWeight: 500,
                        }}
                    >
                        Colours
                    </Typography>
                </ListItemButton>
            </ListItem>
            <ListItem sx={{ padding: 1, py: 0.25 }}>
                <ListItemButton
                    sx={{
                        paddingY: 0.5,
                        borderRadius: 1,
                        color: '#3e5060',
                        '&:hover': {
                            background: '#f5f5f5',
                        },
                    }}
                    onClick={() => setOpen(!open)}
                >
                    <Typography sx={{ color: 'inherit', fontWeight: 500 }}>UI Components</Typography>
                    {open ? <ExpandLess sx={{ ml: 'auto' }} /> : <ExpandMore sx={{ ml: 'auto' }} />}
                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout='auto' unmountOnExit>
                <List component='div' sx={{ padding: 1, py: 0 }}>
                    <Typography sx={{ pl: 4, py: 0.5 }} variant='subtitle2'>
                        Form Components
                    </Typography>
                    <Divider />
                    <ListItemButton
                        sx={{
                            my: 0.5,
                            paddingY: 0.5,
                            pl: 4,
                            borderRadius: 1,
                            color: '#3e5060',
                            '&:hover': {
                                background: '#f5f5f5',
                            },
                            ...(location.pathname === '/library/ui-components/button' && {
                                color: '#0072E5',
                                background: '#F0F7FF',
                            }),
                        }}
                        onClick={() => navigate('ui-components/button')}
                    >
                        <Typography sx={{ color: 'inherit', fontWeight: 500 }}>Button</Typography>
                    </ListItemButton>
                    <ListItemButton
                        sx={{
                            my: 0.5,
                            paddingY: 0.5,
                            pl: 4,
                            borderRadius: 1,
                            color: '#3e5060',
                            '&:hover': {
                                background: '#f5f5f5',
                            },
                            ...(location.pathname === '/library/ui-components/checkbox' && {
                                color: '#0072E5',
                                background: '#F0F7FF',
                            }),
                        }}
                        onClick={() => navigate('ui-components/checkbox')}
                    >
                        <Typography sx={{ color: 'inherit', fontWeight: 500 }}>Checkbox</Typography>
                    </ListItemButton>
                    <ListItemButton
                        sx={{
                            my: 0.5,
                            paddingY: 0.5,
                            pl: 4,
                            borderRadius: 1,
                            color: '#3e5060',
                            '&:hover': {
                                background: '#f5f5f5',
                            },
                            ...(location.pathname === '/library/ui-components/radio' && {
                                color: '#0072E5',
                                background: '#F0F7FF',
                            }),
                        }}
                        onClick={() => navigate('ui-components/radio')}
                    >
                        <Typography sx={{ color: 'inherit', fontWeight: 500 }}>Radio</Typography>
                    </ListItemButton>
                    <ListItemButton
                        sx={{
                            my: 0.5,
                            paddingY: 0.5,
                            pl: 4,
                            borderRadius: 1,
                            color: '#3e5060',
                            '&:hover': {
                                background: '#f5f5f5',
                            },
                            ...(location.pathname === '/library/ui-components/slider' && {
                                color: '#0072E5',
                                background: '#F0F7FF',
                            }),
                        }}
                        onClick={() => navigate('ui-components/slider')}
                    >
                        <Typography sx={{ color: 'inherit', fontWeight: 500 }}>Slider</Typography>
                    </ListItemButton>
                    <ListItemButton
                        sx={{
                            my: 0.5,
                            paddingY: 0.5,
                            pl: 4,
                            borderRadius: 1,
                            color: '#3e5060',
                            '&:hover': {
                                background: '#f5f5f5',
                            },
                            ...(location.pathname === '/library/ui-components/switch' && {
                                color: '#0072E5',
                                background: '#F0F7FF',
                            }),
                        }}
                        onClick={() => navigate('ui-components/switch')}
                    >
                        <Typography sx={{ color: 'inherit', fontWeight: 500 }}>Switch</Typography>
                    </ListItemButton>
                    <ListItemButton
                        sx={{
                            my: 0.5,
                            paddingY: 0.5,
                            pl: 4,
                            borderRadius: 1,
                            color: '#3e5060',
                            '&:hover': {
                                background: '#f5f5f5',
                            },
                            ...(location.pathname === '/library/ui-components/textfield' && {
                                color: '#0072E5',
                                background: '#F0F7FF',
                            }),
                        }}
                        onClick={() => navigate('ui-components/textfield')}
                    >
                        <Typography sx={{ color: 'inherit', fontWeight: 500 }}>Text Field</Typography>
                    </ListItemButton>
                    <Typography sx={{ pl: 4, py: 0.5 }} variant='subtitle2'>
                        Surfaces
                    </Typography>
                    <Divider />
                    <ListItemButton
                        sx={{
                            my: 0.5,
                            paddingY: 0.5,
                            pl: 4,
                            borderRadius: 1,
                            color: '#3e5060',
                            '&:hover': {
                                background: '#f5f5f5',
                            },
                            ...(location.pathname === '/library/ui-components/accordion' && {
                                color: '#0072E5',
                                background: '#F0F7FF',
                            }),
                        }}
                        onClick={() => navigate('ui-components/accordion')}
                    >
                        <Typography sx={{ color: 'inherit', fontWeight: 500 }}>Accordion</Typography>
                    </ListItemButton>
                    <ListItemButton
                        sx={{
                            my: 0.5,
                            paddingY: 0.5,
                            pl: 4,
                            borderRadius: 1,
                            color: '#3e5060',
                            '&:hover': {
                                background: '#f5f5f5',
                            },
                            ...(location.pathname === '/library/ui-components/avatar' && {
                                color: '#0072E5',
                                background: '#F0F7FF',
                            }),
                        }}
                        onClick={() => navigate('ui-components/avatar')}
                    >
                        <Typography sx={{ color: 'inherit', fontWeight: 500 }}>Avatar</Typography>
                    </ListItemButton>
                    <ListItemButton
                        sx={{
                            my: 0.5,
                            paddingY: 0.5,
                            pl: 4,
                            borderRadius: 1,
                            color: '#3e5060',
                            '&:hover': {
                                background: '#f5f5f5',
                            },
                            ...(location.pathname === '/library/ui-components/Badge' && {
                                color: '#0072E5',
                                background: '#F0F7FF',
                            }),
                        }}
                        onClick={() => navigate('ui-components/Badge')}
                    >
                        <Typography sx={{ color: 'inherit', fontWeight: 500 }}>Badge</Typography>
                    </ListItemButton>
                    <ListItemButton
                        sx={{
                            my: 0.5,
                            paddingY: 0.5,
                            pl: 4,
                            borderRadius: 1,
                            color: '#3e5060',
                            '&:hover': {
                                background: '#f5f5f5',
                            },
                            ...(location.pathname === '/library/ui-components/breadcrumb' && {
                                color: '#0072E5',
                                background: '#F0F7FF',
                            }),
                        }}
                        onClick={() => navigate('ui-components/breadcrumb')}
                    >
                        <Typography sx={{ color: 'inherit', fontWeight: 500 }}>Breadcrumb</Typography>
                    </ListItemButton>
                    <ListItemButton
                        sx={{
                            my: 0.5,
                            paddingY: 0.5,
                            pl: 4,
                            borderRadius: 1,
                            color: '#3e5060',
                            '&:hover': {
                                background: '#f5f5f5',
                            },
                            ...(location.pathname === '/library/ui-components/card' && {
                                color: '#0072E5',
                                background: '#F0F7FF',
                            }),
                        }}
                        onClick={() => navigate('ui-components/card')}
                    >
                        <Typography sx={{ color: 'inherit', fontWeight: 500 }}>Card</Typography>
                    </ListItemButton>
                    <ListItemButton
                        sx={{
                            my: 0.5,
                            paddingY: 0.5,
                            pl: 4,
                            borderRadius: 1,
                            color: '#3e5060',
                            '&:hover': {
                                background: '#f5f5f5',
                            },
                            ...(location.pathname === '/library/ui-components/chip' && {
                                color: '#0072E5',
                                background: '#F0F7FF',
                            }),
                        }}
                        onClick={() => navigate('ui-components/chip')}
                    >
                        <Typography sx={{ color: 'inherit', fontWeight: 500 }}>Chip</Typography>
                    </ListItemButton>
                    <ListItemButton
                        sx={{
                            my: 0.5,
                            paddingY: 0.5,
                            pl: 4,
                            borderRadius: 1,
                            color: '#3e5060',
                            '&:hover': {
                                background: '#f5f5f5',
                            },
                            ...(location.pathname === '/library/ui-components/list' && {
                                color: '#0072E5',
                                background: '#F0F7FF',
                            }),
                        }}
                        onClick={() => navigate('ui-components/list')}
                    >
                        <Typography sx={{ color: 'inherit', fontWeight: 500 }}>List</Typography>
                    </ListItemButton>
                    <ListItemButton
                        sx={{
                            my: 0.5,
                            paddingY: 0.5,
                            pl: 4,
                            borderRadius: 1,
                            color: '#3e5060',
                            '&:hover': {
                                background: '#f5f5f5',
                            },
                            ...(location.pathname === '/library/ui-components/tab' && {
                                color: '#0072E5',
                                background: '#F0F7FF',
                            }),
                        }}
                        onClick={() => navigate('ui-components/tab')}
                    >
                        <Typography sx={{ color: 'inherit', fontWeight: 500 }}>Tab</Typography>
                    </ListItemButton>
                </List>
            </Collapse>
            <ListItem sx={{ padding: 1, py: 0.25 }}>
                <ListItemButton
                    sx={{
                        paddingY: 0.5,
                        borderRadius: 1,
                        color: '#3e5060',
                        '&:hover': {
                            background: '#f5f5f5',
                        },
                        ...(location.pathname === '/library/charts' && {
                            color: '#0072E5',
                            background: '#F0F7FF',
                        }),
                    }}
                    onClick={() => navigate('charts')}
                >
                    <Typography
                        sx={{
                            color: 'inherit',
                            fontWeight: 500,
                        }}
                    >
                        Charts
                    </Typography>
                </ListItemButton>
            </ListItem>
            <ListItem sx={{ padding: 1, py: 0.25 }}>
                <ListItemButton
                    sx={{
                        paddingY: 0.5,
                        borderRadius: 1,
                        color: '#3e5060',
                        '&:hover': {
                            background: '#f5f5f5',
                        },
                        ...(location.pathname === '/library/layout-editor' && {
                            color: '#0072E5',
                            background: '#F0F7FF',
                        }),
                    }}
                    onClick={() => navigate('layout-editor')}
                >
                    <Typography
                        sx={{
                            color: 'inherit',
                            fontWeight: 500,
                        }}
                    >
                        Layout Editor
                    </Typography>
                </ListItemButton>
            </ListItem>
        </List>
    );
};

const LibraryLayout = () => {
    return (
        <>
            <Box sx={{ display: 'flex', height: '100%' }}>
                <LibraryMenu />
                <Box sx={{ flex: 1 }}>
                    <Outlet />
                </Box>
            </Box>
        </>
    );
};

function LibraryApp() {
    return (
        <Suspense fallback={<ViewLoading />}>
            <Outlet />
            <Routes>
                <Route path='/library/*' element={<LibraryLayout />}>
                    <Route index path='typography/' element={<TypographyDemo />} />
                    <Route path='layout-editor/' element={<Content />} />
                    <Route path='charts/' element={<ChartsDemo />} />
                    <Route path='colours/' element={<Colours />} />
                    <Route path='ui-components/button/' element={<ButtonExamples />} />
                    <Route path='ui-components/checkbox/' element={<CheckboxExamples />} />
                    <Route path='ui-components/radio/' element={<RadioExamples />} />
                    <Route path='ui-components/slider/' element={<SliderExamples />} />
                    <Route path='ui-components/switch/' element={<SwitchExamples />} />
                    <Route path='ui-components/textfield/' element={<TextfeildExamples />} />
                    {/* surfaces */}
                    <Route path='ui-components/accordion/' element={<AccordionExamples />} />
                    <Route path='ui-components/avatar/' element={<AvatarExamples />} />
                    <Route path='ui-components/badge/' element={<BadgeExamples />} />
                    <Route path='ui-components/breadcrumb/' element={<BreadcrumbExamples />} />
                    <Route path='ui-components/card/' element={<CardExamples />} />
                    <Route path='ui-components/chip/' element={<ChipExamples />} />
                    <Route path='ui-components/list/' element={<ListExamples />} />
                    <Route path='ui-components/tab/' element={<TabExamples />} />
                </Route>

                <Route
                    path='*'
                    element={
                        <Typography variant='h6' textAlign='center'>
                            Error Page Not Found
                        </Typography>
                    }
                />
            </Routes>
        </Suspense>
    );
}

export default LibraryApp;
