import type { Campaign, CampaignUpdateInput } from '@nexdynamic/squeegee-common';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import useTranslation from '../../../../hooks/useTranslation';
import { campaignUpdate } from '../CampaignService';

import type { CampaignCreateFields } from './useCampaignCreate';

type UseCampaignUpdate = (campaignId: string) => {
    running: boolean;
    campaignUpdate: (fields: Partial<CampaignCreateFields>) => Promise<Campaign | undefined | null>;
};

const toCampaignUpdateInput = ({
    selectedDeliveryMethods,
    audienceIds,
    name,
    templateId,
}: Partial<CampaignCreateFields>): CampaignUpdateInput => {
    const updatedObject: CampaignUpdateInput = {
        audienceIds,
        name,
        templateId,
    };

    if (selectedDeliveryMethods) {
        updatedObject.deliveryMethods = selectedDeliveryMethods;
    }

    return updatedObject;
};
const useCampaignUpdate: UseCampaignUpdate = id => {
    const [running, setRunning] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    return {
        running,
        async campaignUpdate(fields) {
            try {
                setRunning(true);
                const campaign = await campaignUpdate(id, toCampaignUpdateInput(fields));

                enqueueSnackbar(t('campaigns.saved', { name: fields.name || '' }), { variant: 'success' });
                return campaign;
            } catch (error) {
                enqueueSnackbar(t('campaigns.save-error'), { variant: 'error' });
            } finally {
                setRunning(false);
            }
        },
    };
};

export default useCampaignUpdate;
