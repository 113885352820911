import { Box, Divider, TextField, Typography } from '@mui/material';
import { GradientButton } from '@nexdynamic/nex-ui-react';
import { useState } from 'react';
import { standardMessageModelTokenList } from '../../Settings/MessageTemplates';
import useTranslation from '../hooks/useTranslation';
import type { CannedResponse } from './CannedResponse';

type Props = {
    cannedResponse: CannedResponse;
    onSave: (cannedResponse: CannedResponse) => void;
    onDelete: (cannedResponse: CannedResponse) => void;
    onCancel: () => void;
    showDelete?: boolean;
};

const EditCannedResponseDialog = ({ showDelete, cannedResponse: initialCannedResponse, onSave, onDelete, onCancel }: Props) => {
    const { t } = useTranslation();
    const [cannedResponse, setCannedResponse] = useState<CannedResponse | null>(initialCannedResponse);

    const handleValueChange = (key: keyof CannedResponse, value: string | Array<string>) => {
        setCannedResponse(prev => {
            if (prev) {
                return {
                    ...prev,
                    [key]: value,
                };
            }
            return null;
        });
    };

    return (
        <Box
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}
        >
            <TextField
                label='Tags'
                value={cannedResponse?.tags?.join(',')}
                onChange={e => handleValueChange('tags', e.target.value.split(','))}
                size='small'
            />
            <Typography variant='caption' color='text.secondary'>
                {t('settings.canned-responses.tags-helper-text')}
            </Typography>
            <Divider />
            <TextField
                label='Title'
                value={cannedResponse?.title}
                onChange={e => handleValueChange('title', e.target.value)}
                size='small'
                required
            />
            <TextField
                label='Message'
                value={cannedResponse?.message}
                onChange={e => handleValueChange('message', e.target.value)}
                multiline
                minRows={2}
                maxRows={4}
                required
            />
            <Typography variant='caption'>{standardMessageModelTokenList}</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <GradientButton variant='text' onClick={onCancel}>
                    Cancel
                </GradientButton>
                {showDelete && (
                    <GradientButton
                        variant='contained'
                        color='error'
                        disabled={!cannedResponse?.title || !cannedResponse?.message}
                        onClick={() => cannedResponse && onDelete(cannedResponse)}
                    >
                        Delete
                    </GradientButton>
                )}
                <GradientButton
                    variant='contained'
                    color='success'
                    disabled={!cannedResponse?.title || !cannedResponse?.message}
                    onClick={() => cannedResponse && onSave(cannedResponse)}
                >
                    Save
                </GradientButton>
            </Box>
        </Box>
    );
};

export default EditCannedResponseDialog;
