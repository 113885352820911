import AddIcon from '@mui/icons-material/Add';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, IconButton, List, ListItem, ListItemText, Paper, styled, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState } from 'react';
import { ApplicationState } from '../../../../ApplicationState';
import type { AureliaReactComponentDialogComponent } from '../../../../Dialogs/AureliaReactComponentDialogComponent';
import useTranslation from '../../../hooks/useTranslation';

type StripePrepayOption = {
    label: string;
    amount: number;
};
export type PrepaymentOptions = Array<StripePrepayOption>;
export type StripePrepayProps = {
    prepaymentOptions: PrepaymentOptions;
    okCallback: (options: PrepaymentOptions) => void;
    cancelCallback: () => void;
};

const AnimatedListItem = styled(ListItem)(() => ({
    'transition': 'transform 0.3s',
    '&.move-up': {
        transform: 'translateY(-20px)',
    },
    '&.move-down': {
        transform: 'translateY(20px)',
    },
}));
export const StripePrepayOptionsComponent: AureliaReactComponentDialogComponent<StripePrepayProps> = ({
    prepaymentOptions,
    okCallback,
    cancelCallback,
}) => {
    const [options, setOptions] = useState(prepaymentOptions);
    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [editLabel, setEditLabel] = useState('');
    const [editValue, setEditValue] = useState<number>(0);
    const [newLabel, setNewLabel] = useState('');
    const [newValue, setNewValue] = useState('');
    const handleEditClick = (index: number) => {
        setEditIndex(index);
        setEditLabel(options[index].label);
        setEditValue(options[index].amount);
    };

    const handleSaveClick = (index: number) => {
        const newData = [...options];
        newData[index] = { label: editLabel, amount: editValue };
        setOptions(newData);
        setEditIndex(null);
    };

    const handleMoveUp = (index: number) => {
        if (index === 0) return;
        const newData = [...options];
        const temp = newData[index - 1];
        newData[index - 1] = newData[index];
        newData[index] = temp;
        setOptions(newData);
        animateItem(index, 'up');
    };

    const handleMoveDown = (index: number) => {
        if (index === options.length - 1) return;
        const newData = [...options];
        const temp = newData[index + 1];
        newData[index + 1] = newData[index];
        newData[index] = temp;
        setOptions(newData);
        animateItem(index, 'down');
    };

    const handleAddClick = () => {
        if (newLabel.trim() === '' || newValue === '') return;
        const newData = [...options, { label: newLabel, amount: parseFloat(newValue) }];
        setOptions(newData);
        setNewLabel('');
        setNewValue('');
    };

    const animateItem = (index: number, direction: 'up' | 'down') => {
        const listItem = document.getElementById(`list-item-${index}`);
        if (listItem) {
            listItem.classList.add(`move-${direction}`);
            setTimeout(() => {
                listItem.classList.remove(`move-${direction}`);
            }, 300);
        }
    };

    const { t } = useTranslation();

    return (
        <>
            <Paper elevation={3} style={{ padding: '20px', margin: '18px' }}>
                <Stack display="flex" flexDirection="row" justifyContent="start" mb="8px" gap="8px">
                    <Button variant="contained" color="primary" onClick={() => okCallback(options)}>
                        Save
                    </Button>
                    <Button variant="contained" color="error" onClick={() => cancelCallback()}>
                        Cancel
                    </Button>
                </Stack>
                <Stack
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    gap="8px"
                    marginBottom="8px"
                    width="100%"
                >
                    <TextField
                        label="New Label"
                        value={newLabel}
                        onChange={e => setNewLabel(e.target.value)}
                        margin="dense"
                        variant="outlined"
                        size="small"
                    />
                    <TextField
                        label="New Value"
                        value={newValue}
                        onChange={e => setNewValue(e.target.value)}
                        margin="dense"
                        variant="outlined"
                        size="small"
                        type="number"
                    />
                    <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddClick}>
                        {t('general.add')}
                    </Button>
                </Stack>
            </Paper>
            <Paper elevation={3} style={{ padding: '20px', margin: '18px' }}>
                <List>
                    {options.map((item, index) => (
                        <React.Fragment key={index}>
                            <AnimatedListItem id={`list-item-${index}`} divider>
                                <Box display="flex" alignItems="center" width="100%">
                                    {editIndex === index ? (
                                        <>
                                            <TextField
                                                value={editLabel}
                                                onChange={e => setEditLabel(e.target.value)}
                                                margin="dense"
                                                variant="outlined"
                                                size="small"
                                                style={{ marginRight: '10px' }}
                                            />
                                            <TextField
                                                value={editValue}
                                                onChange={e => setEditValue(Number(e.target.value))}
                                                margin="dense"
                                                variant="outlined"
                                                size="small"
                                                type="number"
                                                style={{ marginRight: '10px' }}
                                            />
                                            <IconButton onClick={() => handleSaveClick(index)}>
                                                <SaveIcon />
                                            </IconButton>
                                        </>
                                    ) : (
                                        <>
                                            <ListItemText
                                                primary={item.label}
                                                secondary={`Value: ${ApplicationState.currencySymbol()}${item.amount}`}
                                            />
                                            <IconButton onClick={() => handleEditClick(index)}>
                                                <EditIcon />
                                            </IconButton>
                                        </>
                                    )}
                                    <IconButton onClick={() => handleMoveUp(index)} disabled={index === 0}>
                                        <ArrowUpwardIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleMoveDown(index)} disabled={index === options.length - 1}>
                                        <ArrowDownwardIcon />
                                    </IconButton>
                                </Box>
                            </AnimatedListItem>
                        </React.Fragment>
                    ))}
                </List>
            </Paper>
        </>
    );
};
