import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Box, ButtonBase, Card, CardContent, Chip, Stack, Tooltip, Typography, alpha, useTheme } from '@mui/material';

type Props = {
    label: string;
    value: number | string;
    icon?: React.ReactNode;
    percentageChange?: number;
    color?: string;
    button?: true;
    onClick?: () => void;
    selected?: boolean;
};

const StatCard = ({ label, value, icon, percentageChange, color, button, onClick, selected }: Props) => {
    const theme = useTheme();

    if (button) {
        return (
            <ButtonBase onClick={onClick} sx={{ flex: 1, textAlign: 'left' }}>
                <Card
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 180,
                        bgcolor: selected ? theme.palette.background.paper : alpha(theme.palette.background.paper, 0.5),
                        borderRadius: 0,
                        borderBottom: selected ? `2px solid ${theme.palette.primary.main}` : '2px solid transparent',
                    }}
                >
                    <Stack direction="row">
                        {color && <Box sx={{ width: 6, backgroundColor: color, borderRadius: 4, m: 2, mr: 0 }} />}
                        <CardContent sx={{ flex: 1 }}>
                            <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                                <Typography variant="h6" color="text.secondary" noWrap>
                                    {label}
                                </Typography>
                                <Box sx={{ ml: 1, mr: 0.5 }}>{icon}</Box>
                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} mt={0.5}>
                                <Typography variant="h3">{value}</Typography>
                                {percentageChange !== undefined && (
                                    <Tooltip
                                        title={`${Math.abs(percentageChange).toFixed(0)}% ${
                                            percentageChange > 0 ? 'more' : 'fewer'
                                        } ${label.toLowerCase()} than the previous period`}
                                        placement="top"
                                        arrow
                                    >
                                        <Chip
                                            size="small"
                                            label={
                                                isFinite(percentageChange)
                                                    ? `${percentageChange > 0 ? '+' : ''}${percentageChange.toFixed(0)}%`
                                                    : 'N/A'
                                            }
                                            sx={{
                                                bgcolor:
                                                    percentageChange === 0
                                                        ? 'default'
                                                        : percentageChange > 0
                                                        ? alpha(theme.palette.success.main, 0.3)
                                                        : alpha(theme.palette.error.main, 0.3),
                                                color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)',
                                                fontWeight: 'bold',
                                                borderRadius: 1,
                                            }}
                                            icon={
                                                percentageChange === 0 ? (
                                                    <TrendingFlatIcon
                                                        sx={{
                                                            color:
                                                                theme.palette.mode === 'dark'
                                                                    ? 'rgba(255, 255, 255, 0.6)'
                                                                    : 'rgba(0, 0, 0, 0.6)',
                                                        }}
                                                    />
                                                ) : percentageChange > 0 ? (
                                                    <TrendingUpIcon
                                                        sx={{
                                                            color:
                                                                theme.palette.mode === 'dark'
                                                                    ? 'rgba(255, 255, 255, 0.6)'
                                                                    : 'rgba(0, 0, 0, 0.6)',
                                                        }}
                                                    />
                                                ) : (
                                                    <TrendingDownIcon
                                                        sx={{
                                                            color:
                                                                theme.palette.mode === 'dark'
                                                                    ? 'rgba(255, 255, 255, 0.6)'
                                                                    : 'rgba(0, 0, 0, 0.6)',
                                                        }}
                                                    />
                                                )
                                            }
                                        />
                                    </Tooltip>
                                )}
                            </Stack>
                        </CardContent>
                    </Stack>
                </Card>
            </ButtonBase>
        );
    }
    return (
        <Card
            sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                bgcolor: alpha(theme.palette.background.paper, 0.5),
                borderRadius: 0,
            }}
        >
            <Stack direction="row">
                {color && <Box sx={{ width: 6, backgroundColor: color, borderRadius: 4, m: 2, mr: 0 }} />}
                <CardContent sx={{ flex: 1 }}>
                    <Stack direction="row" gap={1} alignItems="center" justifyContent="space-between">
                        <Typography variant="h6" color="text.secondary" noWrap>
                            {label}
                        </Typography>
                        <Box sx={{ mr: 0.5 }}>{icon}</Box>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} mt={0.5}>
                        <Typography variant="h3">{value}</Typography>
                        {percentageChange !== undefined && (
                            <Chip
                                label={
                                    isFinite(percentageChange) ? `${percentageChange > 0 ? '+' : ''}${percentageChange.toFixed(0)}%` : 'N/A'
                                }
                                color={percentageChange > 0 ? 'success' : 'error'}
                                icon={percentageChange > 0 ? <TrendingUpIcon /> : <TrendingDownIcon />}
                            />
                        )}
                    </Stack>
                </CardContent>
            </Stack>
        </Card>
    );
};

export default StatCard;
