import type { useConfirm, useNexAction } from '@nexdynamic/nex-ui-react';
import type { BulkPriceChange, Customer, JobOccurrence, StoredObject } from '@nexdynamic/squeegee-common';
import { JobOccurrenceStatus } from '@nexdynamic/squeegee-common';
import { Data } from '../../../../../Data/Data';
import type useTranslation from '../../../../hooks/useTranslation';

export const rollbackPriceChange = async (
    bulkPriceChange: BulkPriceChange,
    askForConfirmation: ReturnType<typeof useConfirm>['askForConfirmation'],
    handleAction: ReturnType<typeof useNexAction>[0],
    t: ReturnType<typeof useTranslation>['t']
) => {
    const translations = { name: bulkPriceChange.name };
    return await handleAction(
        {
            successMsg: t('price-change.rollback-complete', translations),
            errorMsg: t('price-change.rollback-failed', translations),
        },
        async () => {
            if (bulkPriceChange?.result?.success && bulkPriceChange.result.changes) {
                if (!bulkPriceChange) return { success: false, errorMsg: 'price-change.not-found' };

                const { agreed } = await askForConfirmation({
                    title: t('general.continue'),
                    description: bulkPriceChange.notified
                        ? t('price-change.rollback-confirm-after-notification', translations)
                        : t('price-change.rollback-confirm-before-notification', translations),
                });
                if (!agreed) return { success: false, notify: false };

                const jobIdToCustomer = new Map<string, string>();
                for (const customer of Data.all<Customer>('customers')) {
                    for (const jobId in customer.jobs) {
                        jobIdToCustomer.set(jobId, customer._id);
                    }
                }

                const updates = new Array<StoredObject>();
                for (const change of bulkPriceChange.result.changes) {
                    const customerId = jobIdToCustomer.get(change.jobId);
                    if (!customerId) continue;

                    const customer = Data.get<Customer>(customerId);
                    if (!customer) continue;

                    const job = customer.jobs[change.jobId];
                    if (!job) continue;

                    job.price = change.oldPrice;
                    updates.push(customer);

                    const occurrences = Data.all<JobOccurrence>('joboccurrences', {
                        jobId: change.jobId,
                        price: change.newPrice,
                        status: JobOccurrenceStatus.NotDone,
                    });
                    for (const occurrence of occurrences) {
                        occurrence.price = change.oldPrice;
                        updates.push(occurrence);
                    }
                }

                bulkPriceChange.result = undefined;
                bulkPriceChange.notified = false;
                updates.push(bulkPriceChange);

                Data.put(updates);

                return { success: true };
            }
            return { success: false, errorMsg: 'price-change.not-found' };
        }
    );
};
