import type { Notification } from '@nexdynamic/squeegee-common';
import React from 'react';
import { Logger } from '../../../Logger';
import { NotificationService } from '../../../Notifications/NotificationService';

const useInboxMarkAsRead = () => {
    const markAsRead = React.useCallback((notifications: Notification[]) => {
        if (notifications.length === 0) return;
        NotificationService.markAsRead(notifications.map(n => n._id)).catch(() => {
            Logger.error('updateLastRead Unable to mark message as read');
        });
    }, []);
    return markAsRead;
};

export default useInboxMarkAsRead;
