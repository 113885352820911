import OutboxIcon from '@mui/icons-material/Outbox';
import { Box, Breadcrumbs, Fade, Link } from '@mui/material';
import { MultiSelectProvider } from '@nexdynamic/nex-ui-react';
import { useNavigate } from 'react-router-dom';
import Text from '../../components/Text';
import MailListHeader from '../components/MailListHeader';
import { useOutbox } from '../hooks/useOutbox';
import MailListContainer from './MailListContainer';

export const OutboxView = () => {
    const notifications = useOutbox();

    const navigate = useNavigate();

    return (
        <Fade in={true}>
            <Box>
                <MultiSelectProvider disableSelect={true}>
                    <MailListHeader>
                        <Breadcrumbs>
                            <Link underline='none' component='button' sx={{ cursor: 'pointer' }} onClick={() => navigate('/inbox')}>
                                <Text variant='subtitle1' text='inbox.inbox-title' />
                            </Link>
                            <Text color='text.primary' variant='subtitle1' text='inbox.outbox' />
                        </Breadcrumbs>
                    </MailListHeader>
                    <MailListContainer emptyText='inbox.outbox-empty' EmptyIcon={OutboxIcon} notifications={notifications} />
                </MultiSelectProvider>
            </Box>
        </Fade>
    );
};

export default OutboxView;
