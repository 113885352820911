import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ViewLoading from '../components/ViewLoading';
import { EditorView } from './EditorView';
export const EditorApp = () => {
    return (
        <Suspense fallback={<ViewLoading />}>
            <Routes>
                <Route path="block-editor/:id" element={<EditorView />}></Route>
            </Routes>
        </Suspense>
    );
};
