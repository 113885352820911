import { MoreActionMenu } from '@nexdynamic/nex-ui-react';
import type { FC } from 'react';
import useSupplierActions from '../hooks/useSupplierActions';

type Props = {
    supplierId: string;
};

export const SupplierActions: FC<Props> = ({ supplierId }) => {
    const { edit, running, remove } = useSupplierActions(supplierId);
    const actions = [edit, remove];

    return <MoreActionMenu label='supplier-actions' actions={actions} disabled={running} />;
};

export default SupplierActions;
