import { css } from "@emotion/react";
import { RichTextContentStyles } from "../components/editor/RichTextContentStyles";

const templateStyles = css`
    html {
        margin: 0;
        padding: 0;
    }
    
    figure {
        margin: 0;
    }

    .content-wrapper {
        margin: 0;
        padding: 16px;
        border-radius: 2px;
        color: #000;
        position: relative;
        line-height: 1.7;
        font-weight: 400;
        background-color: #f2f2f2;
    }

    .content {
        font-size: 14px;
        margin: 16px auto;
        margin-top: 32px;
        background: #fff;
        padding: 32px;
        position: relative;
        cursor: text;
        max-width: 816px;
        display: block;
        border-radius: 10px;
    }

    .unsubscribe-link {
        padding: 32px 0;
    }

    ${RichTextContentStyles.styles}
`

export default templateStyles
