import { Box, ButtonBase, CircularProgress, ImageList, ImageListItem, ImageListItemBar, Skeleton, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { createApi } from 'unsplash-js';
import type { ApiResponse } from 'unsplash-js/dist/helpers/response';
import type { Basic } from 'unsplash-js/dist/methods/photos/types';
import type { Photos } from 'unsplash-js/dist/methods/search/types/response';
import { GlobalFlags } from '../../../../GlobalFlags';
import { RethinkDbAuthClient } from '../../../../Server/RethinkDbAuthClient';

type UnsplashGalleryProps = {
    query: string;
    onSelected: (photo: Basic) => void;
};

type UnsplashPhotoProps = {
    photo: Basic;
    onSelected: (photo: Basic) => void;
};

const UnsplashPhoto = ({ photo, onSelected }: UnsplashPhotoProps) => {
    const [loaded, setLoaded] = useState(false);

    return (
        <ButtonBase onClick={() => onSelected({ ...photo })}>
            <ImageListItem sx={{ position: 'relative', minHeight: 264 }} key={photo.id}>
                {!loaded && <Skeleton sx={{ position: 'absolute', top: 0, width: '100%', height: '100%' }} variant='rectangular' />}
                <img src={photo.urls.small} alt={photo.alt_description || ''} loading='lazy' onLoad={() => setLoaded(true)} />
                {loaded && <ImageListItemBar sx={{ textAlign: 'right' }} subtitle={photo.user.name} />}
            </ImageListItem>
        </ButtonBase>
    );
};

const UnsplashGallery = ({ query, onSelected }: UnsplashGalleryProps) => {
    const [data, setPhotosResponse] = useState<ApiResponse<Photos> | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const api = useMemo(
        () =>
            createApi({
                apiUrl: 'https://unsplash.sqgee.com/api',
                headers: {
                    authorization: RethinkDbAuthClient.getSessionKeyAndValue() || '',
                    dev: GlobalFlags.isDevServer ? 'true' : 'false',
                },
            }),
        []
    );

    useEffect(() => {
        setLoading(true);
        api.search
            .getPhotos({ query, orientation: 'landscape' })
            .then(result => {
                if (result?.errors) console.error('Unsplash error', result.errors);
                setPhotosResponse(result);
            })
            .catch(error => {
                console.error('Error fetching photos', error);
            })
            .finally(() => setLoading(false));
    }, [query, api]);

    if (!loading && query === '') {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <Typography>Start typing to search for images</Typography>
            </Box>
        );
    }

    if (data === null) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <CircularProgress />
            </Box>
        );
    } else if (data.errors) {
        return (
            <div>
                <div>Unable to load unsplash Photos please try again</div>
            </div>
        );
    } else {
        return (
            <ImageList cols={3}>
                {data.response.results.map(photo => (
                    <UnsplashPhoto onSelected={onSelected} photo={photo} key={photo.id} />
                ))}
            </ImageList>
        );
    }
};

export default UnsplashGallery;
