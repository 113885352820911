import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ApplicationState } from '../../ApplicationState';
import ViewLoading from './components/ViewLoading';
import { Adverts } from './views/Adverts';
import MarketplaceApplicationForm from './views/MarketplaceApplicationForm';
import { MyAdverts } from './views/MyAdverts';
import { MyPurchases } from './views/MyPurchases';
import { SellJobs } from './views/SellJobs';
import { SellQuote } from './views/SellQuote';

export const MarketplaceApp = () => {
    return (
        <Suspense fallback={<ViewLoading />}>
            <Routes>
                {(ApplicationState.features.marketplaceBuyer || ApplicationState.features.marketplaceSeller) && (
                    <Route path="marketplace/home" element={<Adverts />}></Route>
                )}
                {ApplicationState.features.marketplaceBuyer && (
                    <Route path="marketplace/my-purchases/:id?" element={<MyPurchases />}></Route>
                )}
                <Route key="adverts" path="marketplace/adverts/:id?" element={<Adverts />}></Route>
                {ApplicationState.features.marketplaceBuyer && <Route path="marketplace/adverts/:id/success" element={<Adverts />}></Route>}
                {ApplicationState.features.marketplaceBuyer && <Route path="marketplace/adverts/:id/failure" element={<Adverts />}></Route>}
                {ApplicationState.features.marketplaceSeller && <Route path="marketplace/my-adverts/:id?" element={<MyAdverts />}></Route>}
                {ApplicationState.features.marketplaceSeller && <Route path="marketplace/sell-jobs" element={<SellJobs />}></Route>}
                {ApplicationState.features.marketplaceSeller && <Route path="marketplace/sell-quoted-jobs" element={<SellQuote />}></Route>}
                {ApplicationState.features.marketplaceSeller && (
                    <Route path="marketplace/apply" element={<MarketplaceApplicationForm />}></Route>
                )}
            </Routes>
        </Suspense>
    );
};
