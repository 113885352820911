import CreateIcon from '@mui/icons-material/Create';
import { Box, Container, Paper, styled, useMediaQuery, useTheme } from '@mui/material';
import { GradientButton, GradientFab } from '@nexdynamic/nex-ui-react';
import { useSnackbar } from 'notistack';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';
import { ApplicationState } from '../../ApplicationState';
import { SelectCustomerDialog } from '../../Customers/Components/SelectCustomerDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { SendMessageToCustomer } from '../../Dialogs/SendMessageToCustomer';
import AppProviders from '../AppProviders';
import ApplicationStateProvider from '../components/ApplicationStateProvider';
import { BrowserFallBackToHashRouter } from '../components/BrowserFallBackToHashRouter';
import Text from '../components/Text';
import ViewLoading from '../components/ViewLoading';
import useTranslation from '../hooks/useTranslation';
import MailMenu from './components/MailMenu';
import InboxView from './views/InboxView';
import MessageView from './views/MessageView';
import OutboxView from './views/OutboxView';
import { ScheduledMessagesView } from './views/ScheduledMessagesView';
import SentView from './views/SentView';
import UnreadView from './views/UnreadView';

const NewMessageFab = styled(GradientFab)`
    position: absolute;
    right: 16px;
    bottom: 16px;
`;

type InboxLayoutProps = {
    children: React.ReactNode;
};
export const InboxLayout = ({ children }: InboxLayoutProps) => {
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const newMessage = async () => {
        if (!ApplicationState.isVerifiedForMessaging) {
            enqueueSnackbar(t('inbox.verification-required'), { variant: 'error' });
            return;
        }

        const dialog = new SelectCustomerDialog();
        const customer = await dialog.show(DialogAnimation.SLIDE_UP);

        if (customer) {
            const dialog = new SendMessageToCustomer(customer, undefined, undefined, 'messages.send-message');
            dialog.show(DialogAnimation.SLIDE_UP);
        }
    };

    return (
        <Container disableGutters={isSmallerScreen ? true : false} sx={{ height: '100%' }} maxWidth="xl">
            <Box sx={{ display: 'flex', height: '100%', flexDirection: isSmallerScreen ? 'column' : 'row', gap: isSmallerScreen ? 0 : 3 }}>
                <Box
                    sx={{
                        p: isSmallerScreen ? 0 : 2,
                        pr: 0,
                        position: 'sticky',
                        top: 0,
                        maxHeight: isSmallerScreen ? 0 : 500,
                        zIndex: 11,
                    }}
                >
                    {!isSmallerScreen && (
                        <GradientButton sx={{ width: '100%', mb: 2 }} color="primary" onClick={newMessage} text={t('inbox.new-message')} />
                    )}
                    <Box sx={isSmallerScreen ? { position: 'absolute', top: -3, right: 0 } : {}}>
                        <MailMenu />
                    </Box>
                </Box>
                <Box sx={{ flex: 1, p: isSmallerScreen ? 0 : 2, borderRadius: 1 }}>
                    <Paper sx={{ flex: 1, height: '100%', bgcolor: 'background.paper' }}>
                        <Container disableGutters={true} maxWidth={'xl'}>
                            {children}
                        </Container>
                    </Paper>
                </Box>

                {isSmallerScreen && (
                    <NewMessageFab onClick={newMessage} color="secondary">
                        <CreateIcon />
                    </NewMessageFab>
                )}
            </Box>
        </Container>
    );
};

export const Inbox = (element: HTMLElement) => {
    const root = ReactDOM.createRoot(element);

    root.render(
        <AppProviders>
            <ApplicationStateProvider>
                <BrowserFallBackToHashRouter>
                    <ErrorBoundary fallback={<Text text="general.unable-to-load-view" />}>
                        <InboxLayout>
                            <Suspense fallback={<ViewLoading />}>
                                <Routes>
                                    <Route path="inbox/unread/*" element={<UnreadView />}>
                                        <Route path=":id" element={<MessageView />} />
                                    </Route>
                                    <Route path="inbox/*" element={<InboxView />}>
                                        <Route path=":id" element={<MessageView />} />
                                    </Route>
                                    <Route path="inbox/sent/*" element={<SentView />}>
                                        <Route path=":id" element={<MessageView />} />
                                    </Route>
                                    <Route path="inbox/outbox/*" element={<OutboxView />}>
                                        <Route path=":id" element={<MessageView />} />
                                    </Route>
                                    <Route path="inbox/scheduled/*" element={<ScheduledMessagesView />}>
                                        <Route path=":id" element={<MessageView />} />
                                    </Route>
                                </Routes>
                            </Suspense>
                        </InboxLayout>
                    </ErrorBoundary>
                </BrowserFallBackToHashRouter>
            </ApplicationStateProvider>
        </AppProviders>
    );

    return {
        unmount: () => root.unmount(),
    };
};
