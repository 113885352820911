import type { TransactionProvider } from '@nexdynamic/squeegee-common';
import { TransactionType } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import type { Filter } from '../../Filters/Filter';
import type { ITransactionsFilterItemDictionary } from '../../Filters/Filters';
import { Filters } from '../../Filters/Filters';
import { FiltersDialog } from '../../Filters/FiltersDialog';
import { Logger } from '../../Logger';
import { TransactionsBase } from '../../Transactions/Components/TransactionsBase';
import { TransactionService } from '../../Transactions/TransactionService';

export class PaymentListCustomElement extends TransactionsBase {
    @bindable invertTotals: boolean;
    @bindable showFilterMenu = false;
    @bindable showSearchBar = false;
    protected hideCancelledTransactions = ApplicationState.getSetting('global.hide-cancelled-transactions', false);
    @bindable paymentAccountId: string;
    @bindable showBalances = false;
    @bindable provider: TransactionProvider;
    @bindable openingBalance?: number = 0;

    protected currentFilter: Filter<ITransactionsFilterItemDictionary>;
    private static transactionTypes: Array<TransactionType> = [
        TransactionType.Payment,
        TransactionType.AutomaticPayment,
        TransactionType.Expense,
    ];

    protected searchText = '';
    protected searchTextChanged = (newValue: string) => {
        this.searchText = newValue;
        this.currentFilter =
            this.currentFilter || Filters.transactionsFilter({ transactionType: PaymentListCustomElement.transactionTypes });
        this.updateTransactionByDayArray(this.currentFilter.filterOptions, this.searchText);
    };

    protected providerChanged(): void {
        this.loadData();
    }

    async loadData() {
        if (this.paymentAccountId) {
            this._transactionsByDay = TransactionService.getAccountTransactionsByDay(this.paymentAccountId, this.provider);
        } else {
            this._transactionsByDay = TransactionService.getAllPaymentsByDay();
        }
        this.currentFilter =
            this.currentFilter || Filters.transactionsFilter({ transactionType: PaymentListCustomElement.transactionTypes });
        await this.updateTransactionByDayArray(this.currentFilter.filterOptions, this.searchText);
    }

    async expandGroup(transactionGroup: any) {
        transactionGroup.expanded = !transactionGroup.expanded;
    }

    protected async openFilterMenu() {
        try {
            const dialog = new FiltersDialog<ITransactionsFilterItemDictionary>(this.currentFilter, Filters.transactionsFilter, {
                transactionType: TransactionType.Invoice,
            });
            await dialog.show(DialogAnimation.SLIDE_UP);
            if (!dialog.cancelled) {
                this.currentFilter = await dialog.getResult();
                this.updateTransactionByDayArray(this.currentFilter.filterOptions, this.searchText);
            }
        } catch (error) {
            Logger.error('Error in openFilterMenu() on PaymentListCustomElement', error);
        }
    }
}
