import VolumeDownRounded from '@mui/icons-material/VolumeDownRounded';
import VolumeUpRounded from '@mui/icons-material/VolumeUpRounded';
import { Box, Grid, Slider, Stack, Typography, useTheme } from '@mui/material';

const SliderExamples = () => {
    const theme = useTheme();
    const lightIconColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)';
    return (
        <div>
            <Grid container spacing={4} padding={3}>
                <Grid item>
                    <Box sx={{ border: 1, borderRadius: '12px', borderColor: '#ccc', width: '24rem', px: 2, pb: 1, pt: 0.5 }}>
                        <Typography variant='subtitle1' textAlign='center' sx={{ pb: 0.5 }}>
                            Slider Colors
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '0.5rem' }}>
                            <Slider color='primary' defaultValue={30} />
                            <Slider color='secondary' defaultValue={30} />
                        </Box>
                    </Box>
                </Grid>

                <Grid item>
                    <Box
                        sx={{
                            border: 1,
                            borderRadius: '12px',
                            borderColor: '#ccc',
                            px: 6,
                            pb: 1,
                            pt: 0.5,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant='subtitle1' textAlign='center' sx={{ pb: 0.5 }}>
                            Vertical Sliders
                        </Typography>
                        <Stack sx={{ height: 300 }} spacing={1} direction='row'>
                            <Slider aria-label='Temperature' orientation='vertical' valueLabelDisplay='auto' defaultValue={30} />
                            <Slider aria-label='Temperature' orientation='vertical' defaultValue={30} valueLabelDisplay='auto' disabled />
                            <Slider
                                getAriaLabel={() => 'Temperature'}
                                orientation='vertical'
                                defaultValue={[20, 37]}
                                valueLabelDisplay='auto'
                                marks={[
                                    {
                                        value: 0,
                                        label: '0°C',
                                    },
                                    {
                                        value: 20,
                                        label: '20°C',
                                    },
                                    {
                                        value: 37,
                                        label: '37°C',
                                    },
                                    {
                                        value: 100,
                                        label: '100°C',
                                    },
                                ]}
                            />
                        </Stack>
                    </Box>
                </Grid>

                <Grid item>
                    <Box sx={{ border: 1, borderRadius: '12px', borderColor: '#ccc', width: '24rem', px: 2, pb: 1, pt: 0.5 }}>
                        <Typography variant='subtitle1' textAlign='center' sx={{ pb: 0.5 }}>
                            Custom Slider
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '0.5rem' }}>
                            <Stack spacing={2} direction='row' sx={{ mb: 1, px: 1, width: '100%' }} alignItems='center'>
                                <VolumeDownRounded htmlColor={lightIconColor} />
                                <Slider
                                    aria-label='Volume'
                                    defaultValue={30}
                                    sx={{
                                        color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(0,0,0,0.87)',
                                        '& .MuiSlider-track': {
                                            border: 'none',
                                        },
                                        '& .MuiSlider-thumb': {
                                            width: 24,
                                            height: 24,
                                            backgroundColor: '#fff',
                                            '&:before': {
                                                boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                            },
                                            '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                boxShadow: 'none',
                                            },
                                        },
                                    }}
                                />
                                <VolumeUpRounded htmlColor={lightIconColor} />
                            </Stack>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default SliderExamples;
