import type { SxProps } from '@mui/material';
import { Typography } from '@mui/material';
import { ApplicationState } from '../../../ApplicationState';

const Price = ({ price, sx }: { price: number; sx?: SxProps }) => {
    const splitPrice = (price: number) => {
        let [whole, decimal] = price.toFixed(2).toString().split('.');
        if (whole[0] === '-') {
            whole = `${whole.substring(1)}`;
            decimal = `${decimal})`;
        }
        return [whole, decimal.padEnd(2, '0')];
    };

    const [whole, decimal] = splitPrice(price);

    return (
        <Typography variant="h5" sx={sx}>
            <Typography component="span">
                {price < 0 ? ApplicationState.currencySymbol() + '(' : ApplicationState.currencySymbol()}
            </Typography>
            {whole}.<Typography component="span">{decimal}</Typography>
        </Typography>
    );
};

export default Price;
