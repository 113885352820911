import Code from '@mui/icons-material/Code';
import CodeOff from '@mui/icons-material/CodeOff';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { CopyButton } from '@nexdynamic/nex-ui-react';
import type { AudienceFilterGroup } from '@nexdynamic/squeegee-common';
import { audienceFilterToCustomerSearch } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import useStateFlags from '../../../../hooks/useStateFlags';

type Props = {
    filters: AudienceFilterGroup[];
};

const AudienceSearchDetails = ({ filters }: Props) => {
    console.time('audienceFilterToCustomerSearch');
    const search = audienceFilterToCustomerSearch(filters);
    console.timeEnd('audienceFilterToCustomerSearch');

    const [showDetails, setShowDetails] = useState(false);

    const { devMode: devMode } = useStateFlags();
    const searchDetails = (devMode && JSON.stringify(filters, null, 4)) || '';

    return (
        <>
            {devMode && (
                <Tooltip title='View the search filter groups JSON'>
                    <IconButton color='primary' onClick={() => setShowDetails(() => !showDetails)}>
                        {showDetails ? <CodeOff /> : <Code />}
                    </IconButton>
                </Tooltip>
            )}
            <Typography sx={{ mr: 1 }} variant='caption'>
                {search}
            </Typography>
            <CopyButton text={search} size='small' />
            {devMode && (
                <Collapse in={showDetails}>
                    <Typography style={{ textAlign: 'left', whiteSpace: 'pre-wrap' }}>{searchDetails}</Typography>
                </Collapse>
            )}
        </>
    );
};

export default AudienceSearchDetails;
