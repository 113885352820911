import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ViewLoading from '../components/ViewLoading';
import Main from './views/AuditMain';

function AuditApp() {
    return (
        <Suspense fallback={<ViewLoading />}>
            <Routes>
                <Route path='/audit/*' element={<Main />}></Route>
            </Routes>
        </Suspense>
    );
}

export default AuditApp;
