import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ViewLoading from '../components/ViewLoading';
import { PricingMain } from './views/PricingMain';

export const PricingApp = () => {
    return (
        <Suspense fallback={<ViewLoading />}>
            <Routes>
                <Route path='/pricing/*' element={<PricingMain />}></Route>
            </Routes>
        </Suspense>
    );
};
