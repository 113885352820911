import { Chip, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { ElevatedListItemButton } from '@nexdynamic/nex-ui-react';
import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { useNavigate } from 'react-router-dom';
import Text from '../../../../../components/Text';

export type BulkPriceChangeListItemProps = {
    item: {
        id: string;
        name: string;
        description?: string;
        user: string | undefined;
        change: string;
        when: string;
        count: number;
        status: 'general.pending' | 'general.error' | 'general.success';
    };
};

export const ChangesListItem = ({ item }: BulkPriceChangeListItemProps) => {
    const navigate = useNavigate();

    const view = () => navigate(`${item.id}`);

    const { id, name, description, change, user, when, count, status } = item;
    const colour = status === 'general.success' ? 'success' : status === 'general.error' ? 'error' : 'info';
    return (
        <ElevatedListItemButton key={id} sx={{ minHeight: 55 }} dense role='listitem' onClick={view}>
            <ListItemText
                primaryTypographyProps={{ noWrap: true }}
                primary={name}
                secondary={
                    <>
                        <Text noWrap component='span' variant='body2' text={description as TranslationKey} />
                        <br />
                        <Text
                            noWrap
                            component='span'
                            variant='body2'
                            text={`${user} created a ${change}, effective ${when}` as TranslationKey}
                            params={{ count: count.toString() }}
                        />
                    </>
                }
            />
            <ListItemSecondaryAction>
                <Chip variant='outlined' color={colour} label={<Text variant='body2' text={status} />} />
            </ListItemSecondaryAction>
        </ElevatedListItemButton>
    );
};
