import DeleteOutline from '@mui/icons-material/DeleteOutline';
import EditOutlined from '@mui/icons-material/EditOutlined';
import Web from '@mui/icons-material/Web';
import type { NexAction } from '@nexdynamic/nex-ui-react';
import { useConfirm, useNexAction } from '@nexdynamic/nex-ui-react';
import type { BlockTemplate, Template } from '@nexdynamic/squeegee-common';
import { useNavigate } from 'react-router-dom';
import { ApplicationState } from '../../../../../ApplicationState';
import useStoredObject from '../../../../hooks/useStoredObject';
import useTranslation from '../../../../hooks/useTranslation';
import { templateRemove } from '../TemplateService';

type UseTemplateActions = (id: string) => {
    running: boolean;
    remove: NexAction<() => void>;
    edit: NexAction<() => void>;
    openInEditor: NexAction<() => void>;
};

const useTemplateActions: UseTemplateActions = id => {
    const navigate = useNavigate();
    const template = useStoredObject<BlockTemplate | Template>(id);
    const { askForConfirmation } = useConfirm();

    const [handleAction, running] = useNexAction();

    const { t } = useTranslation();
    const translations = { name: template?.name || '' };

    return {
        running,
        remove: {
            run: () =>
                handleAction(
                    {
                        successMsg: t('templates.removed', translations),
                        errorMsg: t('templates.removeFailed', translations),
                    },
                    async () => {
                        if (template) {
                            const { agreed } = await askForConfirmation({
                                disagreeLabel: t('general.cancel'),
                                agreeLabel: t('general.remove'),
                                title: t('templates.removeConfirmationTitle'),
                                description: t('templates.removeConfirmationDescription', translations),
                            });

                            if (agreed && template?._id) {
                                await templateRemove(template);
                                return { success: true };
                            } else {
                                return { success: true, notify: false };
                            }
                        }
                        return { success: false, errorMsg: 'templates.templateNotFound' };
                    }
                ),
            name: t('general.remove'),
            icon: <DeleteOutline />,
        },
        edit: {
            run: () => navigate(`/send/templates/${template?._id}/edit`),
            name: t('general.edit'),
            icon: <EditOutlined />,
            disabled: template?.readonly === true,
        },

        openInEditor: {
            run: () => ApplicationState.navigateTo('block-editor', { id: template?._id }),
            name: t('send.open-in-editor'),
            icon: <Web />,
            disabled: template?.readonly === true,
        },
    };
};

export default useTemplateActions;
