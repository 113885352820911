import { activationStrategy } from 'aurelia-router';
import { Marketplace } from './Marketplace';

export class MarketplaceModule {
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    marketplace: ReturnType<typeof Marketplace>;

    attached() {
        const container = document.getElementById('marketplace-root');
        if (!container) throw new Error('marketplace-root element not found');
        this.marketplace = Marketplace(container);
    }

    detached() {
        this.marketplace?.unmount();
    }
}
