import PeopleOutline from '@mui/icons-material/PeopleOutline';
import { Chip } from '@mui/material';

type Props = {
    count: number;
};

const ContactsCount = ({ count }: Props) => {
    return <Chip color='primary' icon={<PeopleOutline />} label={count} variant='outlined' />;
};

export default ContactsCount;
