import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import LinkOffIcon from '@mui/icons-material/LinkOffRounded';
import SyncIcon from '@mui/icons-material/SyncRounded';
import { Button, Chip, IconButton, Stack, Typography } from '@mui/material';
import { DialogPaper } from '@nexdynamic/nex-ui-react';
import useTranslation from '../../hooks/useTranslation';
import type { EmailProvider } from '../provider/EmailEngineProvider';
import { useEmailSettingsViewRouter } from '../useEmailSettingsViewRouter';
import ActionableListItem from './ActionableListItem';

type Props = {
    provider: EmailProvider;
    handleConnect?: () => void;
    handleDisconnect?: () => void;
    handleSync?: () => void;
    loading?: boolean;
};

const EmailProviderActions = ({ provider, handleConnect, handleDisconnect, handleSync, loading }: Props) => {
    const { t } = useTranslation();
    const { navigateTo } = useEmailSettingsViewRouter();

    return (
        <DialogPaper sx={{ p: 2 }}>
            <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                <IconButton onClick={() => navigateTo('email-settings')}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6" gutterBottom>
                    {t('email-settings.connection-details')}
                </Typography>
            </Stack>
            <ActionableListItem
                primary={t('email-settings.account', { name: t(provider.name) })}
                secondary={
                    provider.connected
                        ? t('email-settings.renew-connection', { name: t(provider.name) })
                        : t('email-settings.new-connection', { name: t(provider.name) })
                }
                slots={{
                    icon: provider.icon,
                    secondaryArea: (
                        <Chip
                            label={provider.connected ? t('general.connected') : t('general.not-connected')}
                            color={provider.connected ? 'success' : 'default'}
                            size="small"
                        />
                    ),
                }}
                slotProps={{ paper: { sx: { backgroundImage: 'none', backgroundColor: 'transparent' }, elevation: 0 } }}
                onClick={handleConnect}
            />
            {provider.connected && (handleSync || handleDisconnect) && (
                <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                    {handleSync && (
                        <Button onClick={handleSync} variant="outlined" color="success" endIcon={<SyncIcon />} fullWidth disabled={loading}>
                            {t('email-settings.request-sync')}
                        </Button>
                    )}
                    {handleDisconnect && (
                        <Button
                            onClick={handleDisconnect}
                            variant="outlined"
                            color="error"
                            endIcon={<LinkOffIcon />}
                            fullWidth
                            disabled={loading}
                        >
                            {t('email-settings.disconnect-account')}
                        </Button>
                    )}
                </Stack>
            )}
        </DialogPaper>
    );
};

export default EmailProviderActions;
