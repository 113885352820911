import { useNexAction } from '@nexdynamic/nex-ui-react';
import type { Supplier } from '@nexdynamic/squeegee-common';
import useTranslation from '../../hooks/useTranslation';
import { isDuplicateSupplier, supplierCreate } from '../SupplierService';
import type { SupplierFormFields } from '../components/SupplierForm';

type UseCreateSupplier = () => {
    createSupplier: (fields: SupplierFormFields) => Promise<Supplier | undefined>;
    running: boolean;
};

const useSupplierCreate: UseCreateSupplier = () => {
    const { t } = useTranslation();
    const [handleAction, running] = useNexAction();

    const createSupplier = async (fields: SupplierFormFields) => {
        const translations = { name: fields.name };
        const result = await handleAction<Supplier>(
            {
                successMsg: t('supplier.created', translations),
                errorMsg: t('supplier.create-failed'),
            },
            async () => {
                if (isDuplicateSupplier(fields.name)) {
                    return { success: false, errorMsg: t('supplier.duplicate', { name: fields.name }) };
                }
                const supplier = await supplierCreate(fields);
                if (supplier) return { success: true, value: supplier };
                return { success: false, errorMsg: t('supplier.create-failed') };
            }
        );

        return result.value;
    };

    return {
        createSupplier,
        running,
    };
};

export default useSupplierCreate;
