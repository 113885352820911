import type {
    ConnectedServiceSettings,
    CustomerProvider,
    ICustomerPaymentProvidersData,
    IFabAction,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { ConnectedServicesService } from '../ConnectedServices/ConnectedServicesService';
import { FabWithActions } from '../FabWithActions';
import { Utilities } from '../Utilities';

export class Payments {
    public async attached() {
        FabWithActions.register(this.getActions());
    }
    public detached() {
        FabWithActions.unregister();
    }

    private getActions(): Array<IFabAction> {
        const fabActions: Array<IFabAction> = [];

        if (ApplicationState.hasAdvancedOrAbove) {
            const settings = ApplicationState.getSetting<ConnectedServiceSettings>('global.connected-services');

            if (settings) {
                for (const provider in settings) {
                    const setting = settings[provider as CustomerProvider];
                    if (!setting?.provides?.length) continue;
                    if (setting.provides.indexOf('payments') > -1)
                        fabActions.push({
                            tooltip: `Sync with ${Utilities.toTitleCase(provider)}` as TranslationKey,
                            actionType: 'action-download',
                            handler: () => {
                                ConnectedServicesService.importPayments(provider as keyof ICustomerPaymentProvidersData);
                            },
                            roles: ['Owner', 'Admin'],
                        });
                }
            }
        }

        return fabActions;
    }
}
