import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { TagType } from '@nexdynamic/squeegee-common';
import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { ApplicationState } from '../ApplicationState';
import type { IFabAction } from '../Components/Fabs/IFabAction';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { TextDialog } from '../Dialogs/TextDialog';
import { DataRefreshedEvent } from '../Events/DataRefreshedEvent';
import type { Subscription } from '../Events/SqueegeeEventAggregator';
import { ViewResizeEvent } from '../Events/ViewResizeEvent';
import { FabWithActions } from '../FabWithActions';
import { Logger } from '../Logger';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import type { JobGroupData } from '../Tags/TagData';
import { TagService } from '../Tags/TagService';
import { RoundDataDialog } from './RoundDataDialog';
import { RoundService } from './RoundService';
@inject(Router, EventAggregator)
export class Rounds {
    _routeChangedSubscription: Subscription;
    constructor(protected router: Router, protected eventAggregator: EventAggregator) {
        this._routeChangedSubscription = eventAggregator.subscribe('router:navigation:success', () => {
            this.needsRefreshing = true;
            this.loadTags();
        });
    }

    protected roundsData: Array<JobGroupData> = [];
    protected roundsDataFiltered: Array<JobGroupData> = [];
    protected isLoading = true;
    protected includeQuotes = false;
    protected totalPerMonth: number;
    protected totalPerYear: number;
    protected totalOneOff: number;
    protected totalJobs = 0;
    protected fabWithActions: FabWithActions;

    private _dataChangedSub: Subscription;

    private _loadTagsDebounce: any;

    @bindable public searchText = '';
    public searchTextChanged(): void {
        this.filter();
    }
    public async loadTags(debounceTime = 15000) {
        clearTimeout(this._loadTagsDebounce);
        clearTimeout(this._refreshTimer);
        delete this._loadTagsDebounce;

        this._loadTagsDebounce = setTimeout(async () => {
            try {
                if (this.needsRefreshing) {
                    this.roundsData = await RoundService.getRoundsData(this.router.currentInstruction.fragment === '/rounds/pending');
                    this.filter();
                    this.needsRefreshing = false;
                }
                this.isLoading = false;
                this._refreshTimer = setTimeout(() => {
                    this.loadTags();
                }, debounceTime);
            } catch (error) {
                Logger.error('Unable to load tags', error);
            }
        }, 50);
    }

    protected filter() {
        let filtered;
        this.roundsDataFiltered = this.roundsData;

        if (this.searchText) {
            filtered = this.roundsData.filter((roundData: JobGroupData) => {
                return this.searchText.length === 0 || roundData.tag.description.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1;
            });
        } else {
            filtered = this.roundsData;
        }

        filtered.sort((x, y) => (x.tag.description > y.tag.description ? 1 : x.tag.description < y.tag.description ? -1 : 0));
        this.roundsDataFiltered = filtered;
        this.totalPerMonth = this.roundsDataFiltered.reduce((count, round) => count + round.financialData.totalPerMonth, 0);
        this.totalPerYear = this.roundsDataFiltered.reduce((count, round) => count + round.financialData.totalPerYear, 0);
        this.totalOneOff = this.roundsDataFiltered.reduce((count, round) => count + round.financialData.totalOneOff, 0);
        this.totalJobs = this.roundsDataFiltered.reduce((count, round) => (count += round.jobs.length), 0);
    }

    protected async viewRoundData(roundData: JobGroupData) {
        try {
            const dialog = new RoundDataDialog(roundData, this.router.currentInstruction.fragment === '/rounds/pending');
            await dialog.show(DialogAnimation.SCALE);
            this.loadTags();
        } catch (error) {
            Logger.error('Error in viewRoundData() on Rounds', { roundData, error });
        }
    }

    private needsRefreshing = true;
    private _refreshTimer: any;
    public attached() {
        this.isLoading = true;
        new ViewResizeEvent();
        this.loadTags();
        FabWithActions.register(this.getFabActions());
        this._dataChangedSub = DataRefreshedEvent.subscribe((e: DataRefreshedEvent) => {
            if (e.hasAnyType('customers', 'jobgroups', 'tags')) {
                this.needsRefreshing = true;
            }
        });
    }

    private _delegateAddRound = () => this.addRound();
    private getFabActions(): Array<IFabAction> {
        const fabActions: Array<IFabAction> = [
            {
                tooltip: ApplicationState.localise('dialogs.new-round'),
                actionType: 'action-new-round',
                handler: this._delegateAddRound,
                roles: ['Owner', 'Admin'],
            },
        ];
        return fabActions;
    }

    protected async addRound() {
        const dialog = new TextDialog(
            ApplicationState.localise('dialogs.new-round'),
            ApplicationState.localise('dialogs.add-round-title'),
            '',
            ApplicationState.localise('dialogs.add-round-placeholder'),
            this.roundNameValid,
            false
        );
        const roundName = await dialog.show(DialogAnimation.SLIDE_UP);
        if (roundName) {
            try {
                await TagService.addTag(roundName.trim(), TagType.ROUND);
                this.loadTags(10);
            } catch (error) {
                Logger.error(`Error during addRound in rounds`, error);
                new NotifyUserMessage('rounds.error-adding-round');
            }
        }
    }

    private roundNameValid: (roundName: string) => true | TranslationKey = (roundName: string) => {
        if (!roundName || !roundName.trim()) return 'rounds.enter-round';

        const value = roundName.trim();

        const duplicate = this.roundsData.some(rd => rd.tag.description.toLowerCase() === value.toLowerCase());
        if (duplicate) {
            return 'rounds.round-already-exists';
        }
        return true;
    };

    public detached() {
        this._dataChangedSub && this._dataChangedSub.dispose();
        this._routeChangedSubscription && this._routeChangedSubscription.dispose();
        FabWithActions.unregister();
        clearTimeout(this._loadTagsDebounce);
        clearTimeout(this._refreshTimer);
    }
}
