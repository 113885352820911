import { List } from '@mui/material';
import { EmptyList } from '@nexdynamic/nex-ui-react';
import type { Campaign } from '@nexdynamic/squeegee-common';
import useStoredObjects from '../../../../hooks/useStoredObjects';
import useTranslation from '../../../../hooks/useTranslation';
import CampaignIcon from './CampaignIcon';
import CampaignListItem from './CampaignListItem';

const CampaignList = () => {
    const { t } = useTranslation();

    const campaigns = useStoredObjects<Campaign>('campaigns', { sort: (a, b) => b.createdDate.localeCompare(a.createdDate) });

    const items = campaigns?.map(campaign => {
        return <CampaignListItem key={campaign._id} campaign={campaign} />;
    });

    return (
        <List data-testid='CampaignsList'>{items?.length ? items : <EmptyList text={t('campaigns.empty')} icon={<CampaignIcon />} />}</List>
    );
};

export default CampaignList;
