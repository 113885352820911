import React from 'react';
import { ApplicationState } from '../../ApplicationState';

type ApplicationStateProviderProps = {
    children: React.ReactNode;
};

type ApplicationStateContextType = {
    applicationState: typeof ApplicationState;
};

export const ApplicationStateContext = React.createContext<ApplicationStateContextType>({ applicationState: ApplicationState });

const ApplicationStateProvider = ({ children }: ApplicationStateProviderProps) => {
    const context = {
        applicationState: ApplicationState,
    };

    return <ApplicationStateContext.Provider value={context}>{children}</ApplicationStateContext.Provider>;
};

export default ApplicationStateProvider;
