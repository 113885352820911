import { Container } from '@mui/system';
import { Outlet, Route, Routes } from 'react-router-dom';
import { ViewBulkPriceChange } from './views/change/ViewBulkPriceChange';
import { EditAffectedJobs } from './views/jobs/EditAffectedJobs';
import { Changes } from './views/list/Changes';
import { CreateBulkPriceChange } from './views/list/CreateBulkPriceChange';
import { EditBulkPriceChange } from './views/list/EditBulkPriceChange';

const BulkPriceChangesView = () => {
    return <Outlet></Outlet>;
};

export const ChangesRoutes = () => {
    return (
        <Container sx={{ padding: 2 }}>
            <Routes>
                <Route path='/' element={<BulkPriceChangesView />}>
                    <Route index element={<Changes />} />
                    <Route path='create' element={<CreateBulkPriceChange />} />
                    <Route path=':id' element={<ViewBulkPriceChange />} />
                    <Route path=':id/edit' element={<EditBulkPriceChange />} />
                    <Route path=':id/affected-jobs' element={<EditAffectedJobs />} />
                </Route>
            </Routes>
        </Container>
    );
};
