import PercentOutlined from '@mui/icons-material/PriceChangeSharp';
import { Grid, Paper, Typography } from '@mui/material';
import type { BulkPriceChange } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../../../../../ApplicationState';

export const ChangeHeader = ({ change }: { change: BulkPriceChange }) => {
    return (
        <Paper sx={{ p: 3 }}>
            <Grid container>
                <Grid item lg={9}>
                    <Typography sx={{ mb: 1.5 }} variant='h5' component='div'>
                        <PercentOutlined></PercentOutlined> {change.name}
                    </Typography>
                    <Typography sx={{ mb: 1.5, fontSize: 14 }} color='text.secondary' gutterBottom>
                        {change.description}
                    </Typography>
                </Grid>
                <Grid item lg={3} textAlign={'right'}>
                    <Typography variant={change.type.is === 'percentage' ? 'h3' : 'h4'} color='text.secondary'>
                        <b>
                            {change.type.is === 'percentage'
                                ? `${(change.type.value * 100).toFixed(2)}%`
                                : `${ApplicationState.currencySymbol()}${change.type.value.toFixed(2)}`}
                        </b>
                    </Typography>
                    <Typography variant='h5' color='text.secondary'>
                        {change.type.value > 0 ? 'Increase' : 'Decrease'}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};
