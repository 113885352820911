import type { BackupDataItem } from '@nexdynamic/squeegee-common';
import { useEffect, useState } from 'react';
import { backupServer } from '../../../../Data/backupServer';
import { Api } from '../../../../Server/Api';

import { ApplicationState } from '../../../../ApplicationState';
import useStateFlags from '../../../hooks/useStateFlags';
import { getMondayOfTheWeekCommencingDate } from '../utils/getBackupDateInfo';

type BackupWeekInfo = {
    weekCommencingDateAsISOString: string;
    weekCommencingDateAsDate: Date;
    weekNumber: number;
    backups: Array<BackupDataItem>;
};

type BackupTreeAsObject = Record<string, Record<string, Record<string, BackupWeekInfo>>>; // used to generate the tree structure
type BackupTreeAsArray = Array<{ year: string; months: Array<{ month: string; weeks: Array<BackupWeekInfo> }> }>; // used after the object version is generated to get sorted arrays for front end

const backupNonAutoInitialiserTasks = ['shift-schedule', 'reset-schedule', 'restore-backup', 'import-data', 'manual']; // tasks that are not auto backups and should be available to all users

// auto backups are only show for ultimate or dev mode
const filteredBackupTree = ({ devMode, isUltimate, backups }: { backups?: BackupDataItem[]; isUltimate: boolean; devMode: boolean }) => {
    if (!backups) return [];
    const showAll = isUltimate || devMode;
    if (showAll) return backups;
    // an alternative would be to say initiatorTask !== 'auto'
    // but this is explicitly specifiying what the tasks that are always available are
    return backups.filter(backup => backupNonAutoInitialiserTasks.includes(backup.initiatorTask));
};

export const useBackups = (): { mostRecentBackup?: BackupDataItem; backupTree: BackupTreeAsArray | null; refresh: () => void } => {
    const [mostRecentBackup, setMostRecentBackup] = useState<BackupDataItem>();
    const [backupTree, setBackupTree] = useState<BackupTreeAsArray | null>(null);
    const [refreshKey, setRefreshKey] = useState(0);

    const isUltimate = ApplicationState.hasUltimateOrAbove;
    const { devMode } = useStateFlags();

    useEffect(() => {
        Api.get<Array<BackupDataItem>>(backupServer(), '/api/backups').then(backupsResponse => {
            console.log('backupsResponse', backupsResponse);
            const backups = filteredBackupTree({ backups: backupsResponse?.data, isUltimate, devMode });
            if (!backups || !backups.length) {
                setBackupTree([]);
                return;
            }
            // Set the latest backup as the most recent
            setMostRecentBackup(backups.sort((a, b) => b.date.localeCompare(a.date))[0]);

            const backupTree: BackupTreeAsObject = {};
            for (const backup of backups) {
                const date = new Date(backup.date);
                const year = date.getFullYear().toString();
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const weekNumber = Math.ceil((date.getDate() + 0.1) / 7);
                const week = `Week${weekNumber}`;
                const weekCommencingDateAsDate = getMondayOfTheWeekCommencingDate(date);
                const weekCommencingDateAsISOString = weekCommencingDateAsDate.toISOString();
                // const startDate = new Date(date.setDate(date.getDate() - date.getDay() + 1)).toISOString().split('T')[0];

                if (!backupTree[year]) {
                    backupTree[year] = {};
                }
                if (!backupTree[year][month]) {
                    backupTree[year][month] = {};
                }
                if (!backupTree[year][month][week]) {
                    backupTree[year][month][week] = { weekNumber, weekCommencingDateAsDate, weekCommencingDateAsISOString, backups: [] };
                }
                backupTree[year][month][week].backups.push(backup);
            }
            const backupTreeAsArray: BackupTreeAsArray = [];
            // sort years descending
            Object.entries(backupTree)
                .sort((a, b) => b[0].localeCompare(a[0]))
                .forEach(([year, months]) => {
                    backupTreeAsArray.push({
                        year,
                        months: Object.entries(months)
                            .sort((a, b) => b[0].localeCompare(a[0]))
                            .map(([month, weeks]) => {
                                return {
                                    month,
                                    weeks: Object.values(weeks)
                                        .sort((a, b) => b.weekNumber - a.weekNumber)
                                        .map(weekInfo => {
                                            return {
                                                weekCommencingDateAsDate: weekInfo.weekCommencingDateAsDate,
                                                weekCommencingDateAsISOString: weekInfo.weekCommencingDateAsISOString,
                                                weekNumber: weekInfo.weekNumber,
                                                backups: weekInfo.backups.sort((a, b) => b.date.localeCompare(a.date)),
                                            };
                                        }),
                                };
                            }),
                    });
                });
            setBackupTree(backupTreeAsArray);
        });
    }, [refreshKey]);

    return {
        mostRecentBackup,
        backupTree,
        refresh: () => setRefreshKey(refreshKey + 1),
    };
};
