import { Box, List } from '@mui/material';
import { EmptyList } from '@nexdynamic/nex-ui-react';
import { useTranslation } from 'react-i18next';
import { getPreviousPriceChangeModels } from '../../../../utils/getPreviousPriceChangeModels';
import { ChangeIcon } from '../../components/ChangeIcon';
import { ChangesListItem } from './ChangesListItem';

export const ChangesList = () => {
    const { t } = useTranslation();

    const priceChangeHistory = getPreviousPriceChangeModels();

    const bulkPriceChangeListItems = priceChangeHistory?.map(item => {
        return <ChangesListItem key={item.id} item={item} />;
    });

    return (
        <Box>
            <List data-testid='BulkPriceChangesList'>
                {bulkPriceChangeListItems?.length ? (
                    bulkPriceChangeListItems
                ) : (
                    <EmptyList text={t('bulk-price-change.list-empty')} icon={<ChangeIcon />} />
                )}
            </List>
        </Box>
    );
};
