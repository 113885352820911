import { Box, Divider, Grid, Paper, Typography } from '@mui/material';
import type { AudienceFilter, AudienceFilterGroup, TranslationKey } from '@nexdynamic/squeegee-common';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';

import { useDebounce } from '@nexdynamic/nex-ui-react';
import useAudienceFilters from '../../hooks/useAudienceFilters';
import AudienceSearchDetails from '../AudienceSearchDetails';
import AudienceFilterForm from './AudienceFilterForm';
import AudienceFiltersPreview from './AudienceFiltersPreview';
import { FilterGroup } from './FilterGroup';

export type FilterTerm = 'and' | 'or';

type Props = {
    filters?: AudienceFilterGroup[];
    audienceDescription?: TranslationKey;
    audienceNoResultsDescription?: TranslationKey;
};

const AudienceFilters = ({ filters = [], audienceDescription, audienceNoResultsDescription }: Props) => {
    const { setFieldValue } = useFormikContext();
    const availableFilters = useAudienceFilters();

    const previewFilters = useDebounce(filters, 500);
    const audienceFiltersPreviewMemo = useMemo(() => {
        return (
            <AudienceFiltersPreview
                filterGroups={previewFilters}
                audienceDescription={audienceDescription}
                audienceNoResultsDescription={audienceNoResultsDescription}
            />
        );
    }, [previewFilters, audienceDescription, audienceNoResultsDescription]);

    const onChange = (filterGroups: Array<AudienceFilterGroup>) => {
        setFieldValue('filterGroups', filterGroups);
    };

    const addFilter = (filter: AudienceFilter, group?: AudienceFilterGroup, term?: FilterTerm) => {
        if (!group || term === 'or') {
            // this is the first filter to be added then create the group and add it to the list
            onChange([...filters, [filter]]);
        } else {
            group.push(filter);
            onChange([...filters]);
        }
    };

    const removeFilterItem = (group: AudienceFilterGroup, filter: AudienceFilter) => {
        const matchingFilter = group.findIndex(f => f.id === filter.id);

        if (matchingFilter === -1) {
            console.error('Filter not found');
            return;
        }

        group.splice(matchingFilter, 1);

        const updatedFilters = [...filters];
        if (group.length === 0) {
            updatedFilters.splice(updatedFilters.indexOf(group), 1);
        }

        onChange(updatedFilters);
    };

    const updateFilter = (group: AudienceFilterGroup, filter: AudienceFilter) => {
        const matchingFilter = group.findIndex(f => f.id === filter.id);
        if (matchingFilter === -1) {
            console.error('Filter not found');
            return;
        }
        group.splice(matchingFilter, 1, filter);

        const changedGroups = [...filters];

        onChange(changedGroups);
    };

    const mergeGroup = (group: AudienceFilterGroup) => {
        const updatedFilters = [...filters];

        const nextGroup = updatedFilters[updatedFilters.indexOf(group) + 1];
        const mergedGroup = [...group, ...nextGroup];
        updatedFilters.splice(updatedFilters.indexOf(group), 2, mergedGroup);
        onChange(updatedFilters);
    };

    const moveFilter = (group: AudienceFilterGroup, filter: AudienceFilter, term: FilterTerm) => {
        // If the term is 'or' then we need to move the filter to a new group
        if (term === 'or') {
            const filtersToMove = group.splice(group.indexOf(filter) + 1, group.length);
            // If nothing is left in the group delete the group
            const updatedFilters = [...filters];
            if (group.length === 0) {
                updatedFilters.splice(updatedFilters.indexOf(group), 1);
            }

            updatedFilters.splice(updatedFilters.indexOf(group) + 1, 0, [...filtersToMove]);
            // Add the filter to a new group
            onChange(updatedFilters);
        }
    };

    const Empty = () => (
        <Grid item xs={12}>
            <Box sx={{ border: 1, textAlign: 'center', borderColor: 'divider', borderRadius: 1, p: 2 }}>
                <Typography variant='overline'>No Filters</Typography>
            </Box>
        </Grid>
    );

    const audienceFilters = useMemo(
        () =>
            filters?.map((group, i) => (
                <Grid key={`filter-group-${i}-items-${group.length}`} item xs={12}>
                    <FilterGroup
                        isLast={i === filters.length - 1}
                        filterGroup={group}
                        availableFilters={availableFilters}
                        add={(group, filter, term) => addFilter(filter, group, term)}
                        remove={removeFilterItem}
                        update={updateFilter}
                        moveFilter={moveFilter}
                        mergeGroup={mergeGroup}
                    />
                </Grid>
            )),
        [filters, availableFilters, addFilter]
    );

    if (!audienceFilters) return <Empty />;

    const showForm = audienceFilters.length === 0 && availableFilters.length;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper>
                    <Box p={2}>
                        <Grid container spacing={2}>
                            {Boolean(filters.length) && (
                                <Grid item xs={12}>
                                    <Box sx={{ border: 1, textAlign: 'center', borderColor: 'divider', borderRadius: 1, p: 1 }}>
                                        <AudienceSearchDetails filters={filters} />
                                    </Box>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                {audienceFilters.length > 0 && <Grid container>{audienceFilters}</Grid>}

                                {audienceFilters?.length === 0 && <Empty />}

                                {showForm && (
                                    <>
                                        <Divider sx={{ my: 2 }} />
                                        <AudienceFilterForm onSubmit={filter => addFilter(filter)} filters={availableFilters} />
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>

            <Grid item xs={12}>
                <Paper>
                    <Box p={2}>{audienceFiltersPreviewMemo}</Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default AudienceFilters;
