import { Box, Card, CardActions, CardContent, CardHeader, Divider, Rating, Typography } from '@mui/material';
import { GradientButton } from '@nexdynamic/nex-ui-react';
import type { CustomerRating } from '@nexdynamic/squeegee-common';
import type { PortalCustomerAppointmentRating } from '@nexdynamic/squeegee-portal-common';
import { useState } from 'react';
import { ApplicationState } from '../../../ApplicationState';
import type { AureliaReactComponentDialogComponent } from '../../../Dialogs/AureliaReactComponentDialogComponent';

export type ScheduleDetailsRatingsDialogProps = {
    occurrenceId: string;
    rating: CustomerRating;
    published: boolean;
};

const ScheduleDetailsRatingsDialogComponent: AureliaReactComponentDialogComponent = ({
    occurrenceId,
    rating,
    published,
}: ScheduleDetailsRatingsDialogProps) => {
    const [isPublished, setIsPublished] = useState(published);
    const handlePublish = () => {
        if (isPublished) return;
        const publicRatings = ApplicationState.getSetting<Array<PortalCustomerAppointmentRating>>(
            'global.public-customer-appointment-ratings',
            []
        );
        publicRatings.push({
            appointmentId: occurrenceId,
            displayName: rating.customerName || '',
            rating: rating.rating,
            feedback: rating.feedback || '',
            date: rating.date,
        });
        ApplicationState.setSetting('global.public-customer-appointment-ratings', publicRatings);

        setIsPublished(true);
    };

    const handleUnpublish = async () => {
        if (!isPublished) return;
        const publicRatings = await ApplicationState.getSetting<Array<PortalCustomerAppointmentRating>>(
            'global.public-customer-appointment-ratings',
            []
        );
        publicRatings.splice(
            publicRatings.findIndex(r => r.appointmentId === occurrenceId),
            1
        );
        await ApplicationState.setSetting('global.public-customer-appointment-ratings', publicRatings);
        setIsPublished(false);
    };

    return (
        <Box sx={{ m: 2 }}>
            <Card>
                <CardHeader
                    title={
                        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant='h6'>{rating.customerName}</Typography>
                            <Rating value={rating.rating} readOnly />
                        </Box>
                    }
                ></CardHeader>
                <Divider />
                <CardContent>
                    <Typography>{rating.feedback}</Typography>
                </CardContent>
                <CardActions sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                    {!isPublished ? (
                        <GradientButton variant='contained' color='success' onClick={handlePublish}>
                            Publish
                        </GradientButton>
                    ) : (
                        <GradientButton variant='contained' color='error' onClick={handleUnpublish}>
                            Unpublish
                        </GradientButton>
                    )}
                </CardActions>
            </Card>
        </Box>
    );
};

export default ScheduleDetailsRatingsDialogComponent;
