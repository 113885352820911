import type { SelectChangeEvent } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import type { AudienceFilterModel, AudienceFilterOption } from '@nexdynamic/squeegee-common';
import { AudienceFilterValueType } from '@nexdynamic/squeegee-common';
type ValueInputProps = {
    filter: AudienceFilterModel | undefined;
    selected: string;
    value: string;
    disabled?: boolean;
    onChange: (value: string) => void;
    onSelected: (option: AudienceFilterOption) => void;
    label?: string;
    inputRef?: React.Ref<HTMLInputElement>;
};

const FilterValueInput = ({ disabled, filter, selected, value, onChange, onSelected, label, inputRef }: ValueInputProps) => {
    const handleSelected = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;

        if (typeof value === 'string') {
            const option = filter?.options.find(o => o.id === value);
            if (option) onSelected(option);
        }
    };

    switch (filter?.valueType) {
        case AudienceFilterValueType.Text:
            return (
                <TextField
                    size='small'
                    required
                    fullWidth
                    disabled={disabled}
                    value={value}
                    onChange={event => onChange(event.target.value)}
                    label={label}
                    inputRef={inputRef}
                />
            );
        case AudienceFilterValueType.Boolean:
        case AudienceFilterValueType.Select:
            return (
                <FormControl size='small' required fullWidth disabled={disabled}>
                    {label && <InputLabel id='filter-field-value-label'>{label}</InputLabel>}

                    <Select
                        labelId='filter-field-value-label'
                        id='filter-value-select'
                        value={selected}
                        label={label}
                        onChange={handleSelected}
                        ref={inputRef}
                    >
                        {filter?.options?.map(option => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        default:
            return null;
    }
};

export default FilterValueInput;
