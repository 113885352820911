import { Box, Button, Chip, Dialog, DialogActions, DialogContent, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { type IAdvertisementListing } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import { getDistanceInteger } from '../../../../Utilities';
import { cancelCheckout } from '../../services/AdvertisementService';
import { AdvertAssets } from '../AdvertAssets';
import { AdvertTypeChip } from '../AdvertTypeLabel';
import { Price } from '../Price';
import RequestInfoButton from '../RequestInfoButton';
import AdvertCheckoutDialog from './AdvertCheckoutDialog';
type DialogProps = {
    onClose: () => void | undefined;
    onCheckoutSuccess?: (advert: IAdvertisementListing) => void;
    open: boolean;
    advert: IAdvertisementListing;
};

const AdvertDetails = ({ onClose, open, advert, onCheckoutSuccess }: DialogProps) => {
    const { title, description, sellerName, price, currency, tags, type, squeegeeAssets } = advert;

    const [checkoutOpen, setCheckoutOpen] = useState(false);
    const handleBuy = () => {
        if (!advert) return;

        setCheckoutOpen(true);
    };
    const handleCheckoutSuccess = () => {
        setCheckoutOpen(false);
        onClose();
        onCheckoutSuccess?.(advert);
    };

    const handleCheckoutCancel = () => {
        setCheckoutOpen(false);
        cancelCheckout(advert.id);
    };

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div>
            <Dialog
                fullScreen={matches ? true : false}
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ sx: { width: '37.5rem', height: matches ? '100%' : '50rem' } }}
            >
                <DialogContent sx={{ paddingBottom: '0', width: '100%' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Box width="100%">
                            <Box
                                sx={{
                                    display: 'flex',
                                    maxHeight: '1rem',
                                    marginBottom: '1rem',
                                }}
                            >
                                <Typography sx={{ fontSize: 13 }} color="text.secondary" gutterBottom>
                                    Distance: {getDistanceInteger(advert.distance)}
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.6rem',
                                        ml: 'auto',
                                        maxWidth: '20rem',
                                        height: '0.5rem',
                                        background: '#f5f5f5',
                                        borderRadius: '3px',
                                        padding: 1,
                                    }}
                                >
                                    <Typography sx={{ color: '#696969', fontSize: '0.9em', ml: 'auto' }}>{sellerName}</Typography>
                                </Box>
                            </Box>
                            {advert.image && (
                                <Box
                                    sx={{
                                        width: '99.8%',
                                        height: '18rem',
                                        background: `url(${advert.image || 'no-image.png'}'})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        border: 1,
                                        borderColor: '#cacaca',
                                        borderRadius: '4px',
                                    }}
                                ></Box>
                            )}
                            <Typography variant="h6" component="div" sx={{ mt: '1rem' }}>
                                {title}
                            </Typography>
                            <Typography sx={{ mb: 1.4, fontSize: '0.9em' }} color="text.secondary">
                                {description}
                            </Typography>
                            {squeegeeAssets && <AdvertAssets squeegeeAssets={squeegeeAssets} onClose={onClose} />}
                        </Box>
                    </Box>
                    {checkoutOpen && (
                        <AdvertCheckoutDialog
                            onSuccess={handleCheckoutSuccess}
                            advert={advert}
                            onCancel={handleCheckoutCancel}
                            open={true}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            paddingX: '1rem',
                            mb: '0.7rem',
                        }}
                    >
                        <Box sx={{ display: 'flex' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    justifyContent: 'flex-start',
                                    gap: 1,
                                }}
                            >
                                <AdvertTypeChip advertType={type} />
                                {tags.map((tag, index) => {
                                    return <Chip size="small" key={index} sx={{ borderRadius: 1 }} label={tag} />;
                                })}
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'end',
                                    justifyContent: 'flex-end',
                                    marginLeft: 'auto',
                                }}
                            >
                                <Typography variant="h6">
                                    <b>Price</b> <Price price={price} currency={currency} />
                                </Typography>
                            </Box>
                        </Box>
                        <Divider sx={{ marginY: '0.5rem', borderRadius: '2px' }} />
                        <Box sx={{ display: 'flex', ml: 'auto', gap: '10px' }}>
                            <Button sx={{ paddingY: 0.5, color: '#696969' }} onClick={onClose}>
                                Close
                            </Button>
                            <RequestInfoButton advertTitle={title} advertId={advert.id} onClose={onClose} />
                            {onCheckoutSuccess && (
                                <Button
                                    sx={{
                                        'width': '100px',
                                        'paddingY': 0.5,
                                        'background': '#fa0f5d',
                                        'color': 'white',
                                        '&:hover': {
                                            color: 'white',
                                            background: `#fa0f5d`,
                                        },
                                    }}
                                    variant="contained"
                                    onClick={handleBuy}
                                >
                                    BUY
                                </Button>
                            )}
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AdvertDetails;
