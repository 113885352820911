// Registered on the portal

import type { DateRangeValue } from '@nexdynamic/nex-ui-react';
import { calculatePercentageChange } from '@nexdynamic/nex-ui-react';
import type { Customer, Quote } from '@nexdynamic/squeegee-common';
import { useMemo } from 'react';
import { Data } from '../../../../Data/Data';
import { QuoteService } from '../../../../Quotes/QuoteService';
import { groupTimestamps } from '../portalAnalyticsUtils';
import type { Grouping } from '../usePortalAnalytics';
import type { AllLeads } from './useLeadAnalytics';

const filterLeads = (leads: Array<Customer>) => {
    const now = Date.now();
    const quotes = Data.all<Quote>('quotes', q => q.expiry > now);
    const pendingQuotes = quotes.filter(quote => quote.state === 'pending');

    const quoteCustomerIds = new Set(pendingQuotes.map(quote => quote.customerId));

    const customersLeads = leads.filter(lead => quoteCustomerIds.has(lead._id));

    return customersLeads.filter(lead => {
        const customerQuotes = quotes.filter(quote => quote.customerId === lead._id);
        return !customerQuotes.some(quote => QuoteService.quoteIsConverted(quote));
    });
};

export const usePendingLeads = (portalLeads: AllLeads, grouping: Grouping, dateRange: DateRangeValue) => {
    const { leads: allLeads, previousLeads: allPreviousLeads } = portalLeads;

    const { leads, previousLeads } = useMemo(() => {
        return {
            leads: filterLeads(allLeads),
            previousLeads: filterLeads(allPreviousLeads),
        };
    }, [allLeads]);

    const timestamps = useMemo(() => {
        return leads.map(lead => new Date(lead.createdDate).valueOf());
    }, [leads]);

    const groupedLeads = useMemo(() => {
        return groupTimestamps(grouping, timestamps, dateRange);
    }, [timestamps, grouping]);

    const percentageChange = calculatePercentageChange(previousLeads.length, leads.length);

    return {
        leads,
        groupedLeads,
        percentageChange,
        count: leads.length,
        timestamps,
    };
};
