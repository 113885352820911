import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, IconButton, Paper } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import type { AccountUser, TimeEntry } from '@nexdynamic/squeegee-common';
import type { Dayjs } from 'dayjs';
import { useContext, useState } from 'react';
import TrackerContext from '../TrackerContext';
import CalendarLayout from './CalendarGrid';
import TimeRow from './TimeRow';
import SettingsDialog from './dialogs/SettingsDialog/SettingsDialog';

type TimelineProps = {
    showUnboundEvents: boolean;
    setShowUnboundEvents: (showUnboundEvents: boolean) => void;
    showAllWorkers: boolean;
    setShowAllWorkers: (showAllWorkers: boolean) => void;
    selectedWorkerId: string | null;
    setSelectedWorkerId: (selectedWorkerId: string | null) => void;
    selectedDate: Dayjs | null;
    setSelectedDate: (selectedDate: Dayjs | null) => void;
    workDayStart: Date;
    setWorkDayStart: (workDayStart: Date) => void;
    workDayEnd: Date;
    setWorkDayEnd: (workDayEnd: Date) => void;
    dayStats: { date: string; recorded: number; unrecorded: number; timeEntries: TimeEntry[] };
    workers: AccountUser[];
    unboundEvents: TimeEntry[];
};

const Timeline = ({
    showUnboundEvents,
    setShowUnboundEvents,
    showAllWorkers,
    setShowAllWorkers,
    selectedWorkerId,
    setSelectedWorkerId,
    selectedDate,
    setSelectedDate,
    workDayStart,
    setWorkDayStart,
    workDayEnd,
    setWorkDayEnd,
    dayStats,
    workers,
    unboundEvents,
}: TimelineProps) => {
    const [settingsAnchorEl, setSettingsAnchorEl] = useState<HTMLElement | null>(null);

    const { showUnrecordedJobs, setShowUnrecordedJobs, unrecordedTimeThreshold, setUnrecordedTimeThreshold } = useContext(TrackerContext);

    return (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '8px',
                flex: 1,
                overflow: 'hidden',
            }}
        >
            <Box sx={{ display: 'flex', width: '100%', gap: '1rem', padding: 1, zIndex: 1 }}>
                <IconButton
                    id='settings-button'
                    onClick={() => {
                        setSettingsAnchorEl(document.getElementById('settings-button'));
                    }}
                >
                    <SettingsIcon />
                </IconButton>
                <Box
                    sx={{
                        display: 'flex',
                        ml: 'auto',
                        gap: 1,
                    }}
                >
                    <Box>
                        <IconButton
                            onClick={() => {
                                const newDate = selectedDate?.subtract(1, 'day');
                                if (newDate) {
                                    setSelectedDate(newDate);
                                }
                            }}
                        >
                            <ChevronLeftIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => {
                                const newDate = selectedDate?.add(1, 'day');
                                if (newDate) {
                                    setSelectedDate(newDate);
                                }
                            }}
                        >
                            <ChevronRightIcon />
                        </IconButton>
                    </Box>
                    <DatePicker
                        sx={{ maxWidth: 150 }}
                        slotProps={{
                            textField: {
                                size: 'small',
                            },
                        }}
                        value={selectedDate}
                        onChange={date => {
                            setSelectedDate(date as Dayjs);
                        }}
                        format='DD/MM/YYYY'
                    />
                </Box>
            </Box>
            <CalendarLayout workDayStart={workDayStart} workDayEnd={workDayEnd}>
                <Box sx={{ pt: 3 }}>
                    {workers.map(worker => {
                        return (
                            <TimeRow
                                showUnboundEvents={showUnboundEvents}
                                unboundEvents={unboundEvents}
                                refTime={selectedDate?.toDate() || new Date()}
                                workDayStart={workDayStart}
                                workDayEnd={workDayEnd}
                                key={worker._id}
                                timeEntries={dayStats?.timeEntries ? dayStats.timeEntries?.filter(te => te.assigneeId === worker._id) : []}
                                worker={worker}
                                selected={selectedWorkerId === worker._id}
                                onSelected={() => setSelectedWorkerId(worker._id)}
                                unrecordedTimeThreshold={unrecordedTimeThreshold || 0}
                            />
                        );
                    })}
                </Box>
            </CalendarLayout>
            <SettingsDialog
                open={!!settingsAnchorEl}
                anchorEl={settingsAnchorEl}
                onClose={() => setSettingsAnchorEl(null)}
                defaultDayEnd={workDayEnd}
                defaultDayStart={workDayStart}
                showAllWorkers={showAllWorkers}
                showUnboundEvents={showUnboundEvents}
                showUnrecordedJobs={showUnrecordedJobs}
                unrecordedTimeThreshold={unrecordedTimeThreshold}
                setDefaultDayEnd={setWorkDayEnd}
                setDefaultDayStart={setWorkDayStart}
                setShowAllWorkers={setShowAllWorkers}
                setShowUnboundEvents={setShowUnboundEvents}
                setShowUnrecordedJobs={setShowUnrecordedJobs}
                setUnrecordedTimeThreshold={setUnrecordedTimeThreshold}
            />
        </Paper>
    );
};

export default Timeline;
