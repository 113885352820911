import type { AdvertismentType } from '@nexdynamic/squeegee-common';

import AttachIcon from '@mui/icons-material/AttachFileOutlined';
import { Chip } from '@mui/material';

export const AdvertTypeChip = ({ advertType }: { advertType: AdvertismentType }) => {
    switch (advertType) {
        case 'squeegee-assets': {
            return <Chip size='small' label={'Squeegee Data'} icon={<AttachIcon />}></Chip>;
        }
        default:
            return <Chip size='small' sx={{ borderRadius: 1 }} label={advertType}></Chip>;
    }
};
