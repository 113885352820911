import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { default as RestoreBackupIcon } from '@mui/icons-material/Restore';
import type { SxProps } from '@mui/material';
import { Button, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import type { Theme } from '@mui/system';
import type { BackupDataItem } from '@nexdynamic/squeegee-common';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Utilities } from '../../../Utilities';
import { BackupInitiatorTaskComponent } from './BackupInitiatorTaskComponent';

dayjs.extend(advancedFormat);

export const BackupListItemComponent = ({
    backupDataItem,
    isDev,
    showDivider,
    disablePadding,
    loadBackupIntoMemory,
    restoreBackupUi,
    downloadBackup,
    sx,
}: {
    backupDataItem: BackupDataItem;
    showDivider: boolean;
    disablePadding: boolean;
    isDev: boolean;
    loadBackupIntoMemory: (backupDataItem: BackupDataItem) => void;
    restoreBackupUi: (backupDataItem: BackupDataItem) => void;
    downloadBackup: (backupDataItem: BackupDataItem) => void;
    sx?: SxProps<Theme> | undefined;
}) => {
    return (
        <ListItem divider={showDivider} disablePadding={disablePadding} sx={sx}>
            <ListItemText
                primary={dayjs(backupDataItem.date).format('ddd Do h:mma')}
                secondary={
                    <>
                        {' '}
                        <Stack direction="row" spacing={1} alignItems={'center'} fontSize={'16px'}>
                            <Typography>
                                {backupDataItem.size ? `${Utilities.formatBytes(backupDataItem.size)}` : 'Unknown size'}
                            </Typography>

                            {/* {manual ? (
                        <UserIcon fontSize="inherit" />
                    ) : (
                        <AutomaticIcon fontSize="inherit" />
                    )} */}
                        </Stack>
                        <BackupInitiatorTaskComponent initiatorTask={backupDataItem.initiatorTask} />
                    </>
                }
                secondaryTypographyProps={{ component: 'div' }}
            />
            <Stack direction={'column'} spacing={1}>
                {isDev && (
                    <Button
                        size="small"
                        variant="outlined"
                        color="info"
                        endIcon={<BrowserUpdatedIcon />}
                        onClick={() => loadBackupIntoMemory(backupDataItem)}
                        sx={{ mr: 1 }}
                    >
                        Load
                    </Button>
                )}
                <Button
                    size="small"
                    variant="contained"
                    color="error"
                    endIcon={<RestoreBackupIcon />}
                    onClick={() => restoreBackupUi(backupDataItem)}
                    sx={{ mr: 1 }}
                >
                    Restore
                </Button>

                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    endIcon={<CloudDownloadIcon />}
                    onClick={() => downloadBackup(backupDataItem)}
                >
                    Download
                </Button>
            </Stack>
        </ListItem>
    );
};
