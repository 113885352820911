import { useNexAction } from '@nexdynamic/nex-ui-react';
import { TagType, type Tag } from '@nexdynamic/squeegee-common';
import { Data } from '../../../Data/Data';
import { TagService } from '../../../Tags/TagService';
import useTranslation from '../../hooks/useTranslation';
import type { ExpenseCategoryFields } from '../components/ExpenseCategoryForm';

type UseCreateExpenseCategory = () => {
    createExpenseCategory: (fields: ExpenseCategoryFields) => Promise<Tag | undefined>;
    running: boolean;
};

export const isDuplicateExpenseCategory = (description: string, id?: string) => {
    const duplicateTags = Data.all(
        'tags',
        (t: Tag) => t.type === TagType.EXPENSE_CATEGORY && t.description === description && t._id !== id
    );
    return duplicateTags.length > 0;
};

const expenseCategoryCreate = async (fields: ExpenseCategoryFields) => {
    const tag = await TagService.addExpenseCategory(fields.description, fields.balanceLedgerId);
    return tag;
};

const useExpenseCategoryCreate: UseCreateExpenseCategory = () => {
    const { t } = useTranslation();
    const [handleAction, running] = useNexAction();

    const createExpenseCategory = async (fields: ExpenseCategoryFields) => {
        const translations = { name: fields.description };
        const result = await handleAction<Tag>(
            {
                successMsg: t('expense-category.created', translations),
                errorMsg: t('expense-category.create-failed'),
            },
            async () => {
                if (isDuplicateExpenseCategory(fields.description)) {
                    return { success: false, errorMsg: t('expense-category.duplicate', { name: fields.description }) };
                }
                const ExpenseCategory = await expenseCategoryCreate(fields);
                if (ExpenseCategory) return { success: true, value: ExpenseCategory };
                return { success: false, errorMsg: t('expense-category.create-failed') };
            }
        );

        return result.value;
    };

    return {
        createExpenseCategory,
        running,
    };
};

export default useExpenseCategoryCreate;
