import type { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';

type AreaChartProps = {
    categories: Array<any>;
    series: ApexAxisChartSeries;
    strokeColor: string;
};

export const BareComparisonChart = ({ categories, series }: AreaChartProps) => {
    const options: ApexOptions = {
        chart: {
            type: 'area',
            sparkline: {
                enabled: true,
            },
            stacked: true,
            toolbar: {
                show: false,
            },
        },
        legend: {
            show: true,
            floating: true,
            position: 'top',
            offsetY: -24,
            fontSize: '14px',
            labels: {
                colors: '#333',
            },
            markers: {
                // These properties appear to no longer exist.
                // width: 10,
                // height: 10,
                strokeWidth: 0,
                strokeColor: '#fff',
            },
        },
        stroke: {
            curve: 'smooth',
            width: 1,
        },
        grid: {
            show: false,
        },
        yaxis: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: categories,
            labels: {
                show: false,
            },
        },
        tooltip: {
            theme: 'dark',
            fixed: {
                enabled: false,
            },
            x: {
                show: true,
            },
            y: {
                formatter: value => {
                    return `${value} GBP`;
                },
            },
            marker: {
                show: false,
            },
        },
        theme: {},
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 0,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 100],
            },
        },
    };

    return <Chart type="area" options={options} series={series} height="100%" />;
};
