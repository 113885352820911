import { Box, Button, Skeleton, Typography } from '@mui/material';
import { TableListItem } from '@nexdynamic/nex-ui-react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat.js';
dayjs.extend(advancedFormat);

export const AuditLoadingItem = ({ dense }: { dense: boolean }) => {
    if (dense)
        return (
            <TableListItem dense={true}>
                <Box sx={{ display: 'flex', height: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Box>
                            <Typography fontWeight='bold' variant='subtitle2'></Typography>
                        </Box>

                        <Typography variant='body2'></Typography>
                        <Typography
                            variant='body2'
                            color='text.secondary'
                            sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: 238 }}
                        ></Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            flex: 1,
                            alignItems: 'center',
                        }}
                    >
                        <Button variant='outlined' color='primary'>
                            Details
                        </Button>
                    </Box>
                </Box>
            </TableListItem>
        );

    return (
        <TableListItem>
            <Skeleton sx={{ gridColumn: '1 / span 1' }} variant='text' />
            <Skeleton sx={{ mr: 2, gridColumn: '3 / span 2' }} variant='text'></Skeleton>
            <Skeleton sx={{ mr: 2, gridColumn: '5 / span 7' }} variant='text'></Skeleton>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gridColumn: '12 / span 1' }}>
                <Skeleton variant='rounded' width={80} height={32}></Skeleton>
            </Box>
        </TableListItem>
    );
};
