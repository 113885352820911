import type { Customer, IFabAction, Location, TranslationKey } from '@nexdynamic/squeegee-common';
import { FrequencyType, Job, JobOccurrence, sortByDateAsc, sortByDateDesc } from '@nexdynamic/squeegee-common';
import { computedFrom } from 'aurelia-framework';
import moment from 'moment';
import type { ItemActionType } from '../Actions/IListItemAction';
import { ApplicationState } from '../ApplicationState';
import { CustomerDialog } from '../Customers/Components/CustomerDialog';
import { CustomerFormDialog } from '../Customers/Components/CustomerFormDialog';
import { SelectCustomerDialog } from '../Customers/Components/SelectCustomerDialog';
import { Data } from '../Data/Data';
import { RouteOptimisationService } from '../DayPilot/RouteOptimisationService';
import { AddressLookupDialog } from '../Dialogs/AddressLookupDialog';
import { AureliaReactComponentDialog } from '../Dialogs/AureliaReactComponentDialog';
import { CustomDialog } from '../Dialogs/CustomDialog';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { FrequencyPickerDialog } from '../Dialogs/FrequencyPicker/FrequencyPickerDialog';
import { Prompt } from '../Dialogs/Prompt';
import { TextDialog } from '../Dialogs/TextDialog';
import { ViewDialog } from '../Dialogs/ViewDialog';
import { DataRefreshedEvent } from '../Events/DataRefreshedEvent';
import { LoaderEvent } from '../Events/LoaderEvent';
import type { Subscription } from '../Events/SqueegeeEventAggregator';
import { NewJobDialog } from '../Jobs/Components/NewJobDialog';
import { JobService } from '../Jobs/JobService';
import type { JobSummary } from '../Jobs/JobSummary';
import { Logger } from '../Logger';
import type { IMenuBarAction } from '../Menus/IMenuBarAction';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import type { TransferJobsProps } from '../ReactUI/transfer/TransferJobs';
import { TransferJobs } from '../ReactUI/transfer/TransferJobs';
import { ScheduleItem } from '../Schedule/Components/ScheduleItem';
import { ScheduleService } from '../Schedule/ScheduleService';
import type { JobGroupData } from '../Tags/TagData';
import { Utilities } from '../Utilities';
import { RoundService } from './RoundService';

export class RoundDataDialog extends CustomDialog<boolean> {
    private static _cssClasses = 'round-data-dialog details-dialog no-nav-shadow';

    private _dataChangedSub: Subscription;
    protected menuTitle = 'Round data';
    protected moreActions: Array<IMenuBarAction>;
    protected jobLocations: Array<Location> = [];
    protected jobSummaries: Array<JobSummary> = [];
    protected scheduleItems: Array<ScheduleItem> = [];
    protected totalJobsDue = 0;
    protected totalJobsOverDue = 0;
    protected appointmentsWithOverDueJobs = 0;
    protected appointmentsWithDueJobs = 0;
    protected loadedListItems: { [key: string]: number } = {};

    private _frequencyText?: string;

    @computedFrom('_frequencyText')
    protected get frequencyText() {
        if (this._frequencyText === undefined) this._frequencyText = RoundService.getRoundFrequencyText(this.roundData.tag);
        return this._frequencyText;
    }

    public loading = true;

    constructor(private roundData: JobGroupData, private _pending: boolean) {
        super(roundData.tag._id, '../Rounds/RoundDataDialog.html', '', {
            okLabel: '',
            cancelLabel: '',
            cssClass: RoundDataDialog._cssClasses,
            isSecondaryView: true,
        });

        this.moreActions = this.getRoundMoreActions();
        if (this.roundData.tag.description) {
            this.menuTitle = this.roundData.tag.description;
        }

        this.updateLocations();
    }

    private updateLocations() {
        if (!this.roundData.jobs.length && this.roundData.tag.location) this.jobLocations = [this.roundData.tag.location];
        for (let i = 0; i < this.roundData.jobs.length; i++) {
            const job = this.roundData.jobs[i];
            if (job.location && job.location.isVerified) {
                this.jobLocations.push(job.location);
            }
        }
    }

    public async init() {
        this._extractJobs();
        this._dataChangedSub = DataRefreshedEvent.subscribe(async (evt: DataRefreshedEvent) => {
            if (!evt.hasAnyType('jobgroups', 'customers')) return;
            clearTimeout(this._debounceExtractJobs);
            delete this._debounceExtractJobs;
            this._debounceExtractJobs = setTimeout(async () => {
                this._extractJobs();
            }, 1000);
        });

        const actions: Array<IFabAction> = [
            {
                tooltip: 'actions.add-job',
                actionType: 'action-new-job',
                handler: () => {
                    this.addJobForCustomer();
                },
                roles: ['Owner', 'Admin', 'Creator', 'JobEditor'],
            },
            {
                tooltip: 'actions.add-existing-job',
                actionType: 'action-existing-job',
                handler: () => {
                    this.addExistingJob();
                },
                roles: ['Owner', 'Admin', 'Creator', 'JobEditor'],
            },
        ];

        this._settings.fab = { actions };
        this.fabActions = actions;
    }

    public async addExistingJob() {
        const title = ApplicationState.localise('dialogs.add-existing-job', { round: this.roundData.tag.description });
        try {
            const viewDialog = new ViewDialog(title);
            const jobsToAdd = await viewDialog.show(DialogAnimation.SLIDE_UP_IN);

            if (jobsToAdd && jobsToAdd.length) await ScheduleService.bulkMoveRoundScheduleItems(jobsToAdd, undefined, [this.roundData.tag]);
        } catch (error) {
            Logger.error('Error in addExistingJob() on RoundDataDialog', { error });
        }
    }

    public async addJobForCustomer() {
        let jobDate: string | undefined;
        let customer: Customer | undefined;
        try {
            jobDate = RoundService.getRoundNextDate(this.roundData.tag);
            const pickCustomerDialog = new SelectCustomerDialog(undefined);
            customer = await pickCustomerDialog.show(DialogAnimation.SLIDE_UP);
            if (!pickCustomerDialog.cancelled) {
                if (customer) {
                    const frequency = ApplicationState.defaultJobFrequency;
                    const type = (frequency && frequency.type) || FrequencyType.NoneRecurring;
                    const interval = (frequency && frequency.interval) || 0;

                    const newJob = new Job(
                        customer._id,
                        undefined,
                        undefined,
                        jobDate,
                        Utilities.copyObject(customer.address),
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        interval,
                        type,
                        [this.roundData.tag],
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        ApplicationState.account.defaultJobDuration
                    );
                    const dialog = new NewJobDialog(customer, newJob);
                    if (pickCustomerDialog.customerIsNew) {
                        const customerFormDialog = new CustomerFormDialog(customer);
                        const originalCustomer = customer;
                        customerFormDialog.beforeNextDialog = async () => {
                            dialog.job.location = Utilities.copyObject(originalCustomer.address);
                        };
                        customerFormDialog.nextDialog = dialog;
                        await customerFormDialog.show();
                    } else {
                        await dialog.show(DialogAnimation.SLIDE_UP);
                    }
                }
            }
        } catch (error) {
            Logger.error('Error in addJobForCustomer() on RoundDataDialog', { jobDate, customer, error });
        }
    }

    protected async optimiseRoute() {
        try {
            new LoaderEvent(true);

            if (ApplicationState.hasValidbusinessAddress()) {
                //TODO: Update optimised route for this day
                const optimisedRoute = await RouteOptimisationService.getRouteItemsForScheduleDataDay(
                    'round' + this.roundData.tag._id,
                    undefined,
                    this.scheduleItems,
                    true,
                    'optimal',
                    true,
                    true
                );
                const jobsToOrder = optimisedRoute.routeItems.filter(x => x.scheduleItem).map(x => <ScheduleItem>x.scheduleItem);

                ScheduleService.updateListPlannedOrder(0, this.scheduleItems, jobsToOrder, true);

                //TODO: Update using new data object
                this._extractJobs();
                new LoaderEvent(false);
            } else {
                new Prompt('general.business-address-required', 'sorting.optimised-drive-business-address-required', {
                    cancelLabel: '',
                    okLabel: 'general.dismiss-message',
                }).show();
            }
        } catch (ex) {
            Logger.error('Error running optimise round', ex);
        } finally {
            new LoaderEvent(false);
        }
    }

    public dispose() {
        this._dataChangedSub && this._dataChangedSub.dispose();
        super.dispose();
    }

    private _debounceExtractJobs: any;
    private async _extractJobs() {
        if (this.roundData.tag) {
            const roundData = await RoundService.getRoundData(this.roundData.tag._id, this._pending);
            if (roundData) {
                this.roundData = roundData;
                delete this._frequencyText;
                this.moreActions = this.getRoundMoreActions();
            }
        }

        const scheduleItems: Array<ScheduleItem> = [];

        this.jobSummaries = await JobService.getJobSummariesFromJobs(this.roundData.jobs);
        if (this.jobSummaries) {
            for (const item of this.jobSummaries) {
                if (this._pending) {
                    const firstDateOccurrence = new JobOccurrence(item.job, moment().format('YYYY-MM-DD'));
                    const scheduleItem = new ScheduleItem(item.job, item.customer, firstDateOccurrence);
                    scheduleItems.push(scheduleItem);
                    scheduleItem.representingJob = true;
                } else {
                    const scheduleItemsArray = (await ScheduleService.getScheduleForJobs(item.job, moment(), undefined, [], 1)).sort(
                        sortByDateDesc
                    );

                    const notDoneJobs = scheduleItemsArray.filter(x => x.status === 0).sort(sortByDateAsc);

                    const scheduleItem = notDoneJobs.length
                        ? notDoneJobs[0]
                        : scheduleItemsArray.length
                        ? scheduleItemsArray[0]
                        : undefined;

                    if (scheduleItem) {
                        scheduleItems.push(scheduleItem);
                        scheduleItem.representingJob = true;
                    }
                }
            }
        }

        this.scheduleItems = scheduleItems.sort((a, b) => {
            return a.plannedOrder - b.plannedOrder;
        });

        this.loading = false;
    }

    protected async viewCustomer(jobSummary: JobSummary) {
        const dialog = new CustomerDialog(jobSummary.customer);
        await dialog.show(DialogAnimation.SLIDE);
    }

    private getRoundMoreActions(): Array<IMenuBarAction> {
        const actions: Array<IMenuBarAction> = [
            {
                tooltip: 'menubar.rename',
                actionType: 'action-edit',
                handler: async () => await this.renameRound(),
                roles: ['Owner', 'Admin'],
            },
            {
                tooltip: 'menubar.set-color',
                actionType: 'action-change-color',
                handler: async () => await this.setColor(),
                roles: ['Owner', 'Admin'],
            },
            {
                tooltip: 'jobgroup.set-group-location',
                actionType: 'action-set-location',
                handler: async () => await this.setRoundLocation(),
                roles: ['Owner', 'Admin'],
            },
            {
                tooltip: 'jobgroup.set-round-schedule',
                actionType: 'action-edit-schedule',
                handler: async () => await this.setRoundSchedule(),
                roles: ['Owner', 'Admin'],
            },
        ];

        if (this.roundData.tag.frequencyData) {
            actions.push({
                tooltip: 'jobgroup.reset-job-schedule',
                actionType: 'action-replan',
                handler: async () => await this.setRoundSchedule(true),
                roles: ['Owner', 'Admin'],
            });
            actions.push({
                tooltip: 'jobgroup.remove-round-schedule',
                actionType: 'action-delete',
                handler: async () => await this.removeRoundSchedule(),
                roles: ['Owner', 'Admin'],
            });
        }

        if (ApplicationState.hasUltimateOrAbove)
            actions.push({
                tooltip: 'jobgroup.set-as-portal-round',
                actionType: 'action-enable-portal',
                handler: async () => await this.setAsPortalRound(),
                roles: ['Owner', 'Admin'],
            });

        if (this.roundData.tag.isPortalRound && ApplicationState.hasUltimateOrAbove) {
            actions.push({
                tooltip: 'jobgroup.edit-post-codes',
                actionType: 'action-edit',
                handler: async () => await this.setRoundPostcodes(),
                roles: ['Owner', 'Admin'],
            });
            actions.push({
                tooltip: 'jobgroup.edit-schedules',
                actionType: 'action-edit-schedule',
                handler: async () => await this.editSchedules(),
                roles: ['Owner', 'Admin'],
            });
        }

        actions.push({
            tooltip: 'sorting.by-optimised-drive',
            actionType: <ItemActionType>'action-optimise-route',
            handler: async () => await this.optimiseRoute(),
            roles: ['Owner', 'Admin'],
        });

        // WTF: Delete must appear last in the list.
        actions.push({
            tooltip: 'menubar.delete',
            actionType: 'action-delete',
            handler: this.delegateDeleteRound,
            roles: ['Owner', 'Admin'],
        });
        if (ApplicationState.features.marketplaceSeller) {
            actions.push({
                tooltip: 'menubar.sell-round',
                actionType: 'action-sell',
                handler: async () => await this.sellRound(),
                roles: ['Owner', 'Admin'],
            });
        }
        if (ApplicationState.features.transferData) {
            actions.push({
                tooltip: 'Transfer round' as TranslationKey,
                actionType: 'action-sell',
                handler: async () => await this.transferRound(),
                roles: ['Owner', 'Admin'],
            });
        }

        return actions;
    }

    private sellRound() {
        this.cancel();
        const jobIds = this.roundData.jobs.map(x => x.customerId + ':' + x._id).join(',');
        ApplicationState.navigateToRouteFragment(`marketplace/sell-jobs?jobIds=${jobIds}&is-round-transfer=true`);
    }

    private async transferRound() {
        const jobIds = this.roundData.jobs.map(x => x.customerId + ':' + x._id);
        const { dialog, show } = AureliaReactComponentDialog.show<boolean, TransferJobsProps>({
            dialogTitle: 'Transfer Jobs' as TranslationKey,
            component: TransferJobs,
            componentProps: {
                jobIds: jobIds,
                isRoundTransfer: true,
            },
            isSecondaryView: true,
        });

        const result = await show;

        if (dialog.cancelled) return;

        if (result) {
            this.ok();
            new NotifyUserMessage('jobs.transfer-complete');
        }
    }

    private async setAsPortalRound() {
        this.roundData.tag.isPortalRound = true;
        this.moreActions = this.getRoundMoreActions();
        await Data.put(this.roundData.tag);
    }

    private async setRoundPostcodes() {
        const dialog = new TextDialog(
            'Round Post Codes' as TranslationKey,
            'Add Post codes for this round, one per line.' as TranslationKey,
            this.roundData.tag.servicedPostcodes?.join('\n') || '',
            '',
            undefined,
            false,
            'textarea'
        );
        const result = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            this.roundData.tag.servicedPostcodes = result.trim().split('\n');
            await Data.put(this.roundData.tag);
        }
    }

    private async editSchedules() {
        let frequencyData = this.roundData.tag.schedules?.[0]
            ? this.roundData.tag.schedules?.[0]
            : ApplicationState.defaultJobFrequency
            ? ApplicationState.defaultJobFrequency
            : {
                  firstScheduledDate: moment().format('YYYY-MM-DD'),
                  interval: 4,
                  type: FrequencyType.Weeks,
              };

        const dialog = new FrequencyPickerDialog({
            frequencyData,
            showOneOff: false,
            showJobGroups: false,
            defaultShowDateRequiredToggle: false,
        });
        frequencyData = await dialog.show(DialogAnimation.SLIDE_UP);
        if (!dialog.cancelled) {
            this.roundData.tag.schedules = [frequencyData];
            await RoundService.setRoundSchedules(this.roundData.tag._id, [frequencyData]);
            new NotifyUserMessage('jobgroup.set-round-schedule-success');
            this.moreActions = this.getRoundMoreActions();
        }
    }

    public delegateDeleteRound = () => this.deleteRound();
    private async renameRound() {
        const dialog = new TextDialog(
            'dialogs.rename-round',
            'dialogs.add-round-placeholder',
            this.roundData.tag.description,
            'dialogs.add-round-placeholder'
        );
        const result = await dialog.show();
        if (!dialog.cancelled) {
            await RoundService.renameRound(result, this.roundData.tag._id);
            this.roundData.tag.description = result;
            this.ok(true);
        }
    }

    private async setColor() {
        const dialog = new TextDialog(
            'dialogs.set-round-color',
            'dialogs.set-color-placeholder',
            this.roundData.tag.color || '',
            '',
            undefined,
            undefined,
            'colour'
        );

        const result = await dialog.show();
        if (!dialog.cancelled) {
            this.roundData.tag.color = result;
            await RoundService.setRoundColor(result, this.roundData.tag._id);
        }
    }

    private async setRoundLocation() {
        if (!this.roundData.tag.location) {
            const foundLocation = this.jobLocations.find(jobLocation => jobLocation.isVerified);
            if (foundLocation) {
                this.roundData.tag.location = Utilities.copyObject(foundLocation);
            }
        }

        const dialog = new AddressLookupDialog(ApplicationState.account.businessAddress);
        const location = await dialog.show();
        if (dialog.cancelled) return;

        this.roundData.tag.location = location;
        await RoundService.setRoundLocation(this.roundData.tag._id, this.roundData.tag.location);
        this.updateLocations();
        new NotifyUserMessage('jobgroup.set-group-location-success');
    }

    private async setRoundSchedule(reset = false) {
        let frequencyData = this.roundData.tag.frequencyData
            ? this.roundData.tag.frequencyData
            : ApplicationState.defaultJobFrequency
            ? ApplicationState.defaultJobFrequency
            : {
                  firstScheduledDate: moment().format('YYYY-MM-DD'),
                  interval: 4,
                  type: FrequencyType.Weeks,
              };

        if (!reset) {
            const dialog = new FrequencyPickerDialog({
                frequencyData,
                showOneOff: false,
                showJobGroups: false,
                defaultShowDateRequiredToggle: false,
            });
            frequencyData = await dialog.show(DialogAnimation.SLIDE_UP);
            reset = !dialog.cancelled;
        }

        if (reset) {
            let goAhead = this.roundData.jobs.length === 0;
            if (!goAhead) {
                const prompt = new Prompt('jobgroup.set-round-schedule', 'jobgroup.set-round-schedule-confirm', {
                    okLabel: 'general.ok',
                    localisationParams: { jobs: this.roundData.jobs.length.toString() },
                });
                await prompt.show();
                goAhead = !prompt.cancelled;
            }

            if (goAhead) {
                this.roundData.tag.frequencyData = frequencyData;
                await RoundService.setRoundFrequencyData(this.roundData.tag._id, frequencyData);
                delete this._frequencyText;
                new NotifyUserMessage('jobgroup.set-round-schedule-success');
            }
        }
        this.moreActions = this.getRoundMoreActions();
        this._extractJobs();
    }

    private async removeRoundSchedule() {
        const prompt = new Prompt('jobgroup.remove-round-schedule', 'jobgroup.remove-round-schedule-confirm', {
            okLabel: 'general.yes',
            cancelLabel: 'general.cancel',
            localisationParams: { jobs: this.roundData.jobs.length.toString() },
        });
        await prompt.show();

        if (!prompt.cancelled) {
            delete this.roundData.tag.frequencyData;
            await RoundService.setRoundFrequencyData(this.roundData.tag._id);

            delete this._frequencyText;
            new NotifyUserMessage('jobgroup.set-round-schedule-success');
        }
        this.moreActions = this.getRoundMoreActions();
    }

    private async deleteRound() {
        let message: TranslationKey;
        const length = this.roundData.jobs.length;
        if (length === 0) message = 'dialogs.rounds-delete-message-none';
        else if (length === 1) message = 'dialogs.rounds-delete-message-one';
        else message = 'dialogs.rounds-delete-message-many';

        const prompt = new Prompt('prompts.confirm-title', message, {
            okLabel: 'general.delete',
            localisationParams: length > 1 ? { count: this.roundData.jobs.length.toString() } : undefined,
        });
        await prompt.show();

        if (!prompt.cancelled) {
            await RoundService.deleteRound(this.roundData.tag._id);
            this.ok(true);
        }
    }
    public handleDragComplete = async (toIndex: number, items: Array<ScheduleItem>, itemsDragged: Array<ScheduleItem>) => {
        await ScheduleService.handleDragComplete(toIndex, items, itemsDragged, true);
        this._extractJobs();
    };

    protected async executePrimary(target: ScheduleItem): Promise<ScheduleItem | null> {
        if (!target.scheduleItemActions.primaryAction) return Promise.reject(null);
        return target.scheduleItemActions.primaryAction.handler();
    }

    protected async executeSecondary(target: ScheduleItem): Promise<ScheduleItem | null> {
        if (!target.scheduleItemActions.secondaryAction) return Promise.reject(null);
        return target.scheduleItemActions.secondaryAction.handler();
    }

    detached() {
        clearTimeout(this._debounceExtractJobs);
        this._dataChangedSub && this._dataChangedSub.dispose();
    }
}
