import Contacts from '@mui/icons-material/Contacts';
import { GradientIcon } from '@nexdynamic/nex-ui-react';
const ContactsIcon = () => {
    return (
        <GradientIcon gradient='contacts'>
            <Contacts />
        </GradientIcon>
    );
};

export default ContactsIcon;
