import CampaignOutlined from '@mui/icons-material/CampaignOutlined';
import { GradientIcon } from '@nexdynamic/nex-ui-react';

const CampaignIcon = () => {
    return (
        <GradientIcon gradient='campaigns'>
            <CampaignOutlined />
        </GradientIcon>
    );
};

export default CampaignIcon;
