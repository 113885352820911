import { Box, Button, Card, Input, List, ListItem, ListItemSecondaryAction, ListItemText, Switch } from '@mui/material';
import { portalComponentSettingsDefaults } from '@nexdynamic/squeegee-portal-common';
import { useState } from 'react';
import { ApplicationState } from '../../../../ApplicationState';
import type { AureliaReactComponentDialogComponent } from '../../../../Dialogs/AureliaReactComponentDialogComponent';
import { clearPortalComponentSetting } from './clearPortalComponentSetting';
import { setPortalComponentSetting } from './setPortalComponentSetting';

export const BookAppointmentSettingsEditor: AureliaReactComponentDialogComponent<any> = () => {
    const settingDefaults = portalComponentSettingsDefaults['book-appointment'];
    type SettingType = typeof settingDefaults;

    const bookAppointmentComponentSettings = ApplicationState.getSetting<SettingType>('portal.component-book-appointment', settingDefaults);

    const [bookAppointmentSettings, setBookAppointmentSettings] = useState<SettingType>(bookAppointmentComponentSettings);

    return (
        <>
            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: 2 }}>
                    <Button variant="contained" color="error" onClick={async () => await clearPortalComponentSetting('book-appointment')}>
                        {ApplicationState.localise('general.clear-settings')}
                    </Button>
                    <Button variant="contained" onClick={() => setPortalComponentSetting('book-appointment', bookAppointmentSettings)}>
                        {ApplicationState.localise('general.save-button')}
                    </Button>
                </Box>
                <Card sx={{ m: 2 }} variant="outlined">
                    <List disablePadding>
                        <ListItem>
                            <ListItemText
                                primary={ApplicationState.localise('portal-component.book-appointment-date-buffer')}
                                secondary={ApplicationState.localise('portal-component.book-appointment-date-buffer-description')}
                            />
                            <ListItemSecondaryAction>
                                <Input
                                    inputProps={{
                                        step: 1,
                                        min: 0,
                                        max: 365,
                                    }}
                                    color="secondary"
                                    defaultValue={bookAppointmentSettings.jobBookingBuffer}
                                    onChange={e =>
                                        setBookAppointmentSettings({
                                            ...bookAppointmentSettings,
                                            jobBookingBuffer: Number(e.target.value),
                                        })
                                    }
                                    type="number"
                                    sx={{
                                        maxWidth: '40px',
                                    }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={ApplicationState.localise('portal-component.book-appointment-disable-weekends')} />
                            <Switch
                                value={bookAppointmentSettings.disableWeekendBookings}
                                onChange={e =>
                                    setBookAppointmentSettings({
                                        ...bookAppointmentSettings,
                                        disableWeekendBookings: e.target.checked,
                                    })
                                }
                            />
                        </ListItem>
                    </List>
                    <Box sx={{ display: 'flex', justifyContent: 'right', p: 1 }}></Box>
                </Card>
            </Box>
        </>
    );
};
