import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import type { Notification } from '@nexdynamic/squeegee-common';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { LoaderEvent } from '../../../Events/LoaderEvent';
import { NotificationService } from '../../../Notifications/NotificationService';
import { Api } from '../../../Server/Api';

export const useInboxActions = () => {
    const [running, setRunning] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const cancel = async (selectedItems: Array<Notification>) => {
        if (!Api.isConnected) {
            enqueueSnackbar('You must be connected to the internet to perform this action', { variant: 'error' });
            return;
        }
        try {
            setRunning(true);
            await NotificationService.cancelScheduledNotifications(selectedItems);
            setRunning(false);
            enqueueSnackbar('Scheduled message(s) cancelled successfully', { variant: 'success' });
        } catch {
            enqueueSnackbar('Error cancelling scheduled message(s)', { variant: 'error' });
            //Swallow
        } finally {
            setRunning(false);
        }
    };

    useEffect(() => {
        if (running) new LoaderEvent(true, false, 'schedule.cancelling-messages-loader');
        else new LoaderEvent(false);
    }, [running]);

    return {
        actions: [
            {
                name: 'Cancel scheduled message(s)',
                run: (selectedItems: Array<Notification>) => cancel(selectedItems),
                icon: <CancelScheduleSendIcon />,
            },
        ],
        running,
    };
};
