import { Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { PageContainer } from '@nexdynamic/nex-ui-react';
import type { EditorTemplate } from '@nexdynamic/send-editor/core';
import type { BlockTemplateUpdateInput, Template, TemplateState, TemplateUpdateInput } from '@nexdynamic/squeegee-common';
import { isEditorTemplate } from '@nexdynamic/squeegee-common';
import { useNavigate, useParams } from 'react-router';
import { ApplicationState } from '../../../../../ApplicationState';
import Text from '../../../../components/Text';
import useStoredObject from '../../../../hooks/useStoredObject';
import type { TemplateDetailsFormFields } from '../forms/TemplateDetailsForm';
import { TemplateFormSteps } from '../forms/TemplateFormSteps';
import type { TemplateSmsFormFields } from '../forms/TemplateSmsForm';
import useUpdateTemplate from '../hooks/useUpdateTemplate';

const EditTemplate = () => {
    const navigate = useNavigate();
    const { id = '' } = useParams();
    const { updateTemplate } = useUpdateTemplate(id);

    const template = useStoredObject<Template>(id);

    const onSubmit = async (
        details: TemplateDetailsFormFields,
        sms: TemplateSmsFormFields,
        template: TemplateState | EditorTemplate,
        openEditor?: boolean
    ) => {
        if (isEditorTemplate(template)) {
            const blockTemplateUpdateInput: BlockTemplateUpdateInput = {
                ...template,
                ...details,
                ...sms,
            };

            await updateTemplate(blockTemplateUpdateInput);
            if (openEditor) {
                ApplicationState.navigateTo('block-editor', {
                    id,
                });
                return;
            }
        } else {
            const legacyTemplate: TemplateUpdateInput = {
                ...details,
                ...sms,
                state: template,
            };
            await updateTemplate(legacyTemplate);
        }

        navigate('/send/templates');
    };

    if (!template) return <Typography>Template not found</Typography>;

    return (
        <PageContainer>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link sx={{ cursor: 'pointer' }} component="button" underline="none" onClick={() => navigate('/send/templates')}>
                        <Text variant="subtitle1" text="templates.title" />
                    </Link>
                    <Typography variant="subtitle1">{template?.name}</Typography>
                    <Text variant="subtitle1" color="text.primary" text="general.edit" />
                </Breadcrumbs>
            </Grid>

            <Grid item xs={12}>
                <TemplateFormSteps templateId={id} onFinished={onSubmit} />
            </Grid>
        </PageContainer>
    );
};

export default EditTemplate;
