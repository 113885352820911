import { type Customer } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { Api } from '../../Server/Api';

export const getGoCardlessSignUpForCustomer = (customer: Customer) => {
    if (!ApplicationState.account.goCardlessPublishableKey) return;

    if (customer.hideGoCardlessSignupFromInvoice) return;
    const gc = customer.paymentProviderMetaData?.gocardless;
    const goCardlessAvailable = gc?.sourceOrMandateId && ['active', 'pending'].includes(gc?.status || '');

    if (goCardlessAvailable) return;

    if (customer.paymentProviderMetaData?.stripe?.status === 'active') return;

    if (ApplicationState.account.invoiceSettings.hideGoCardlessSignupFromInvoice) return;

    return `${Api.currentHostAndScheme}/go/p/${customer.ownerEmail}/${customer._id}`;
};
