import type { Customer } from '@nexdynamic/squeegee-common';
import { useEffect, useState } from 'react';
import { campaignGetContacts } from '../CampaignService';

type Options = {
    search?: string;
    paging?: { take: number; skip: number };
};

type UseCampaignContacts = (
    campaignId: string,
    options?: Options
) => {
    contacts: Customer[];
};

const useCampaignContacts: UseCampaignContacts = (id, options = {}) => {
    const [contacts, setContacts] = useState<Customer[]>(campaignGetContacts(id, options.paging, options.search));

    useEffect(() => {
        const contacts = campaignGetContacts(id, options.paging, options.search);
        setContacts(contacts);
    }, [options?.paging?.skip, options?.search, id]);

    return { contacts };
};

export default useCampaignContacts;
