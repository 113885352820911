// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#editor-root .MuiDrawer-paper {
    padding-top: 64px;
}

.send-editor-media-library-dialog {
    z-index: 7 !important;
}`, "",{"version":3,"sources":["webpack://./src/ReactUI/editor/EditorView.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":["#editor-root .MuiDrawer-paper {\n    padding-top: 64px;\n}\n\n.send-editor-media-library-dialog {\n    z-index: 7 !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
