import { Box, Breadcrumbs, Grid } from '@mui/material';
import { PageContainer } from '@nexdynamic/nex-ui-react';
import Text from '../../../../../components/Text';
import { ChangeButton } from '../../components/ChangeButton';
import { ChangesList } from './ChangesList';

export const Changes = () => {
    return (
        <PageContainer>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ flex: 1 }}>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <Text variant='subtitle1' color='text.primary' text='bulk-price-change.list-title' />
                        </Breadcrumbs>
                    </Box>
                    <Box>
                        <ChangeButton />
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <ChangesList />
            </Grid>
        </PageContainer>
    );
};
