import { Box, Grid, Typography } from '@mui/material';

const TypographyDemo = () => {
    return (
        <div>
            <Grid container spacing={6} padding={3}>
                <Grid item>
                    {/* Headings */}
                    <Typography variant='h1'>Heading 1</Typography>
                    <Typography variant='h2'>Heading 2</Typography>
                    <Typography variant='h3'>Heading 3</Typography>
                    <Typography variant='h4'>Heading 4</Typography>
                    <Typography variant='h5'>Heading 5</Typography>
                    <Typography variant='h6'>Heading 6</Typography>
                    {/* Subtitles */}
                    <Typography variant='subtitle1'>Subtitle 1</Typography>
                    <Typography variant='subtitle2'>Subtitle 2</Typography>
                    {/* Body text */}
                    <Typography variant='body1'>Body 1</Typography>
                    <Typography variant='body2'>Body 2</Typography>
                    {/* Other text elements */}
                    <Typography variant='button'>Button</Typography>
                    <Typography variant='caption'>Caption</Typography>
                    <Typography variant='overline'>Overline</Typography>
                </Grid>
                <Grid item>
                    {/* Text colors */}
                    <Typography variant='body1' color='primary'>
                        Primary
                    </Typography>
                    <Typography variant='body1' color='secondary'>
                        Secondary
                    </Typography>
                    <Typography variant='body1' color='text.primary'>
                        Text Primary
                    </Typography>
                    <Typography variant='body1' color='text.secondary'>
                        Text Secondary
                    </Typography>
                    <Typography variant='body1' color='error'>
                        Error
                    </Typography>
                </Grid>
                <Grid item>
                    {/* Text alignment */}
                    <Box sx={{ width: '8rem', padding: 2, border: 1 }}>
                        <Typography variant='body1' align='left'>
                            Left
                        </Typography>
                        <Typography variant='body1' align='center'>
                            Center
                        </Typography>
                        <Typography variant='body1' align='right'>
                            Right
                        </Typography>
                        <Typography variant='body1' align='justify'>
                            Justify
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    {/* Text wrapping */}
                    <Box sx={{ width: '32rem', padding: 2, border: 1 }}>
                        <Typography variant='body1' noWrap>
                            This is a very long text that will be truncated if it does not fit in the available space.
                        </Typography>
                    </Box>

                    <Box sx={{ width: '32rem', padding: 2, border: 1 }}>
                        <Typography variant='body1'>
                            This is a very long text that will wrap if it does not fit in the available space.
                        </Typography>
                    </Box>

                    <Box sx={{ width: '32rem', padding: 2, border: 1 }}>
                        <Typography variant='body1' paragraph>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat.
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    {/* Text transform */}
                    <Typography variant='body1' textTransform='uppercase'>
                        Uppercase
                    </Typography>
                    <Typography variant='body1' textTransform='lowercase'>
                        Lowercase
                    </Typography>
                    <Typography variant='body1' textTransform='capitalize'>
                        Capitalize
                    </Typography>
                    <Typography variant='body1' textTransform='none'>
                        None
                    </Typography>
                </Grid>
                <Grid item>
                    {/* Text decoration */}
                    <Typography variant='body1' sx={{ textDecoration: 'underline' }}>
                        Underline
                    </Typography>
                    <Typography variant='body1' sx={{ textDecoration: 'line-through' }}>
                        Line-through
                    </Typography>
                    <Typography variant='body1' sx={{ textDecoration: 'overline' }}>
                        Overline
                    </Typography>
                    <Typography variant='body1' sx={{ textDecoration: 'none' }}>
                        None
                    </Typography>
                </Grid>
                <Grid item>
                    {/* Text weight */}
                    <Typography variant='body1' fontWeight='light'>
                        Light
                    </Typography>
                    <Typography variant='body1' fontWeight='normal'>
                        Normal
                    </Typography>
                    <Typography variant='body1' fontWeight='medium'>
                        Medium
                    </Typography>
                    <Typography variant='body1' fontWeight='semibold'>
                        Semibold
                    </Typography>
                    <Typography variant='body1' fontWeight='bold'>
                        Bold
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default TypographyDemo;
