import { createRoot } from 'react-dom/client';
import { BrowserFallBackToHashRouter } from '../components/BrowserFallBackToHashRouter';
import { SqueegeeThemeProvider } from '../SqueegeeThemeProvider';
import { MarketplaceApp } from './MarketplaceApp';

export const Marketplace = (element: HTMLElement) => {
    const root = createRoot(element);

    root.render(
        <BrowserFallBackToHashRouter>
            <SqueegeeThemeProvider>
                <MarketplaceApp />
            </SqueegeeThemeProvider>
        </BrowserFallBackToHashRouter>
    );

    return {
        unmount: () => root.unmount(),
    };
};
