import { Box, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Switch, Typography } from '@mui/material';

const SwitchExamples = () => {
    return (
        <div>
            <Grid container spacing={4} padding={3}>
                <Grid item>
                    <Box sx={{ border: 1, borderRadius: '12px', borderColor: '#ccc', px: 2, pb: 1, pt: 0.5 }}>
                        <Typography variant='subtitle1' textAlign='center' sx={{ pb: 0.5 }}>
                            Switch Colors
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '0.5rem' }}>
                            <Switch checked color='primary' />
                            <Switch checked color='secondary' />
                            <Switch checked color='error' />
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '0.5rem' }}>
                            <Switch checked color='info' />
                            <Switch checked color='success' />
                            <Switch checked color='warning' />
                        </Box>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{ border: 1, borderRadius: '12px', borderColor: '#ccc', px: 2, pb: 1, pt: 0.5 }}>
                        <Typography variant='subtitle1' textAlign='center' sx={{ pb: 0.5 }}>
                            Switch FormGroup
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '0.5rem' }}>
                            <FormControl component='fieldset' variant='standard'>
                                <FormLabel component='legend'>Assign responsibility</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked name='gilad' />}
                                        label='Gilad Gray'
                                    />
                                    <FormControlLabel
                                        control={<Switch name='jason' />}
                                        label='Jason Killian'
                                    />
                                    <FormControlLabel
                                        control={<Switch checked name='antoine' />}
                                        label='Antoine Llorca'
                                    />
                                </FormGroup>
                                <FormHelperText>Be careful</FormHelperText>
                            </FormControl>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default SwitchExamples;
