import type { BulkPriceChange, TranslationKey } from '@nexdynamic/squeegee-common';
import { sortByCreatedDateDesc } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { ApplicationState } from '../../../ApplicationState';
import { Data } from '../../../Data/Data';
import { UserService } from '../../../Users/UserService';

export const getPreviousPriceChangeModels = () => {
    const bulkPriceChanges = Data.all<BulkPriceChange>('bulkpricechange').slice().sort(sortByCreatedDateDesc);

    return bulkPriceChanges.map(bulkPriceChange => {
        const id = bulkPriceChange._id;
        const user = UserService.getUser(bulkPriceChange.createdBy)?.name || bulkPriceChange.createdBy;
        const direction = bulkPriceChange.type.value > 0 ? 'increase' : 'decrease';
        const change =
            bulkPriceChange.type.is === 'percentage'
                ? `${Math.round(bulkPriceChange.type.value * 100)}% ${direction}`
                : `${ApplicationState.currencySymbol()}${bulkPriceChange.type.value.toFixed(2)} ${direction}`;

        const when = moment(bulkPriceChange.plannedDate).format('ll');
        const count = bulkPriceChange?.result?.success ? bulkPriceChange.result.changes.length : 0;

        const status: TranslationKey = bulkPriceChange?.result ? (bulkPriceChange.result.success ? 'general.success' : 'general.error') : 'general.pending';

        const name = bulkPriceChange.name;
        const description = bulkPriceChange.description;

        return { id, name, description, user, change, when, count, status };
    });
};
