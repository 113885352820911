import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import OutboxRoundedIcon from '@mui/icons-material/OutboxRounded';
import type { StepIconProps } from '@mui/material';
import { GradientStepIconRoot } from '@nexdynamic/nex-ui-react';

export const StepIcon = (props: StepIconProps) => {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactNode } = {
        1: <OutboxRoundedIcon sx={{ fontSize: 16 }} />,
        3: <MailOutlineRoundedIcon sx={{ fontSize: 16 }} />,
    };

    return (
        <GradientStepIconRoot ownerState={{ active, completed }} color="secondary" className={className}>
            {icons[String(props.icon)]}
        </GradientStepIconRoot>
    );
};
