import styled from "@emotion/styled";
import { Card as MuiCard } from "@mui/material";

type CardProps = {
  children?: React.ReactNode;
  color?: string;
  background?: string;
  borderRadius?: string;
  boxShadow?: string;
  height?: string | number;
  width?: string | number;
  display?:
    | "flex"
    | "block"
    | "inline"
    | "inline-block"
    | "inline-flex"
    | "none"
    | "grid"
    | "inline-grid"
    | "contents"
    | "list-item"
    | "inherit"
    | "initial"
    | "revert"
    | "unset";
};

const Card = styled(MuiCard)<CardProps>`
  box-sizing: border-box;
  align-items: center;
  display: flex;
  width: ${({ width }) => (width ? width : "100%")};
  height: ${({ height }) => (height ? height : "100%")};
  flex: 1;
`;

export default Card;
