import { Box } from '@mui/material';
import { to2dp } from '@nexdynamic/squeegee-common';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { localPoint } from '@visx/event';
import { Grid } from '@visx/grid';
import { LegendOrdinal } from '@visx/legend';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { BarStack } from '@visx/shape';
import { defaultStyles, useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { timeFormat } from 'd3-time-format';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import type React from 'react';
import { ApplicationState } from '../../../ApplicationState';

export type BarData = {
    date: Date;
} & Record<string, number | Date>;

type Props = {
    width: number;
    height: number;

    data: BarData[];
    // handlers
    selectedDate: Dayjs;
    setSelectedDate: (date: Dayjs) => void;
    columnConfig: ColumnConfig;
    defaultWhiteSpace?: number;
    hideYScale?: boolean;
    showGrid?: boolean;
    showLegend?: boolean;
};

export type ColumnConfig = {
    [key: string]: {
        label: string;
        color: string;
    };
};

type TooltipData = {
    bar: BarData;
    key: string;
    index: number;
    height: number;
    width: number;
    x: number;
    y: number;
    color: string;
};

const tooltipStyles = {
    ...defaultStyles,
    minWidth: 60,
    backgroundColor: 'rgba(0,0,0,0.8)',
    color: 'white',
    zIndex: 1,
};

const StackedBarChart: React.FC<Props> = ({
    width,
    height,
    data,
    selectedDate,
    setSelectedDate,
    columnConfig,
    defaultWhiteSpace = 10,
    hideYScale,
    showGrid,
    showLegend,
}) => {
    const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
    const keys = Object.keys(columnConfig);

    const { containerRef, TooltipInPortal } = useTooltipInPortal({
        // TooltipInPortal is rendered in a separate child of <body /> and positioned
        // with page coordinates which should be updated on scroll. consider using
        // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
        scroll: true,
    });

    //const totals = data.map(d => d.recordedTime + d.unrecordedTime + d.billedTime);
    const totals: Array<number> = [];
    for (let i = 0; i < data.length; i++) {
        for (const key of keys) {
            totals[i] = (totals[i] || 0) + (Number(data[i][key]) || 0);
        }
    }
    const maxTotal = totals.length ? Math.ceil(Math.max(...totals)) || defaultWhiteSpace : defaultWhiteSpace;
    //Set the whiteSpace value for each day
    for (let i = 0; i < data.length; i++) {
        let whiteSpace = maxTotal - (totals[i] ? totals[i] : 0);
        if (whiteSpace === 0) whiteSpace = defaultWhiteSpace;
        data[i].whiteSpace = whiteSpace;
    }

    const margin = {
        top: 48,
        right: 0,
        bottom: 25,
        left: hideYScale ? 0 : 25,
    };

    const dateScale = scaleBand<Date>({
        domain: data.map(d => d.date),
        padding: 0.2,
        range: [margin.left, width - margin.right],
    });

    const hourScale = scaleLinear<number>({
        domain: [0, maxTotal],
        nice: true,
        range: [height - margin.top, 0],
    });

    const colorScale = scaleOrdinal<string, string>({
        domain: ['whiteSpace', ...keys],
        range: ['rgba(235,235,235,0.4)', ...keys.map(key => columnConfig[key].color)],
    });

    const labelScale = scaleOrdinal<string, string>({
        domain: keys,
        range: keys.map(key => columnConfig[key].color),
    });

    const format = timeFormat('%a'); // day of the week
    const formatDate = (date: Date) => format(date);

    let tooltipTimeout: number;

    const barType = ApplicationState.getSetting('global.availability-bar-type', false);
    const barTypeLabel = (tooltipData: TooltipData) => {
        const skillsMessage = `${formatDate(tooltipData.bar.date)} ${tooltipData.bar.date.toLocaleDateString('en-gb')}: ${Number(
            tooltipData.bar[tooltipData.key as keyof BarData]
        ).toFixed(1)}hrs`;

        const valueMessage = `${formatDate(tooltipData.bar.date)} ${tooltipData.bar.date.toLocaleDateString('en-gb')}: £${to2dp(
            Number(tooltipData.bar[tooltipData.key as keyof BarData])
        )}`;

        return barType ? valueMessage : skillsMessage;
    };

    return (
        <div>
            <svg ref={containerRef} width={width + margin.left + margin.right} height={height}>
                {showGrid && (
                    <Grid
                        top={margin.top}
                        left={margin.left}
                        xScale={dateScale}
                        yScale={hourScale}
                        width={width}
                        height={height}
                        stroke="black"
                        strokeOpacity={0.1}
                        xOffset={dateScale.bandwidth() / 3}
                    />
                )}
                {!hideYScale && (
                    <AxisLeft
                        left={margin.left}
                        scale={hourScale}
                        stroke="#333333"
                        tickStroke="#333333"
                        tickFormat={(value, index, array) => {
                            return index === array.length - 1 ? '' : String(value);
                        }}
                    />
                )}
                <BarStack
                    data={data}
                    keys={[...keys, 'whiteSpace']}
                    x={d => d.date}
                    xScale={dateScale}
                    yScale={hourScale}
                    color={colorScale}
                >
                    {barStacks =>
                        barStacks.map(barStack =>
                            barStack.bars.map(
                                bar =>
                                    bar.bar.data[bar.key] && (
                                        <rect
                                            onClick={() => {
                                                const newDate = dayjs(bar.bar.data.date);
                                                setSelectedDate(newDate);
                                            }}
                                            key={`bar-stack-${barStack.index}-${bar.index}`}
                                            x={bar.x}
                                            y={bar.y}
                                            height={bar.height > 0 ? bar.height : 0}
                                            width={bar.width > 0 ? bar.width : 0}
                                            fill={selectedDate.isSame(bar.bar.data.date, 'day') ? colorScale(bar.key) : bar.color}
                                            strokeWidth={0}
                                            onMouseLeave={() => {
                                                tooltipTimeout = window.setTimeout(() => {
                                                    hideTooltip();
                                                }, 300);
                                            }}
                                            onMouseMove={event => {
                                                if (tooltipTimeout) clearTimeout(tooltipTimeout);
                                                // TooltipInPortal expects coordinates to be relative to containerRef
                                                // localPoint returns coordinates relative to the nearest SVG, which
                                                // is what containerRef is set to in this example.
                                                const eventSvgCoords = localPoint(event);
                                                const left = bar.x + bar.width / 32;

                                                showTooltip({
                                                    tooltipData: {
                                                        ...bar,
                                                        bar: bar.bar.data,
                                                    } as TooltipData,
                                                    tooltipTop: eventSvgCoords?.y,
                                                    tooltipLeft: left,
                                                });
                                            }}
                                            style={{
                                                cursor: 'pointer',
                                                filter: selectedDate.isSame(bar.bar.data.date, 'day') ? 'brightness(0.8)' : 'none',
                                            }}
                                        />
                                    )
                            )
                        )
                    }
                </BarStack>
                <AxisBottom
                    top={height - margin.top}
                    scale={dateScale}
                    tickFormat={formatDate}
                    stroke="#333333"
                    tickStroke="#333333"
                    tickLabelProps={tickValue => ({
                        fontSize: 11,
                        textAnchor: 'middle',
                        fontWeight: selectedDate.isSame(dayjs(tickValue), 'day') ? 'bold' : 'normal',
                        style: {
                            cursor: 'pointer',
                        },
                        onClick: () => {
                            setSelectedDate(dayjs(tickValue));
                        },
                    })}
                />
            </svg>
            {showLegend && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '14px',
                    }}
                >
                    <LegendOrdinal
                        scale={labelScale}
                        direction="row"
                        labelMargin="0 15px 0 0"
                        labelFormat={(key: string) => {
                            return columnConfig[key].label;
                        }}
                    />
                </Box>
            )}
            {tooltipOpen && tooltipData && tooltipData.key !== 'whiteSpace' && (
                <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
                    <div
                        style={{
                            color: columnConfig[tooltipData.key].color,
                        }}
                    >
                        <strong style={{ color: 'inherit' }}>{tooltipData.key ? columnConfig[tooltipData.key].label : ''}</strong>
                    </div>
                    <div>{barTypeLabel(tooltipData)}</div>
                </TooltipInPortal>
            )}
        </div>
    );
};

export default StackedBarChart;
