import { CardActionArea, CardContent, CardMedia, Chip, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import type { IAdvertisement, TranslationKey } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { AttachmentCount } from './AttachmentCount';
// import { useEffect, useState } from 'react';
// import { useReward } from 'react-rewards';
import { Price } from './Price';
import { StateChip } from './dialogs/MyAdvertDetails';

export type ItemCardProps = {
    advert: IAdvertisement;
    onClick: (advert: IAdvertisement) => void;
};

const MyAdvertListingCard = ({ advert, onClick }: ItemCardProps) => {
    const { id, title, description, price, currency, tags, state, squeegeeAssets, createdOn } = advert;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    // const { reward: confettiReward } = useReward(`confetti-${id}`, 'confetti', {
    //     startVelocity: 20,
    //     position: 'absolute',
    //     angle: 90,
    //     spread: 100,
    //     elementCount: 24,
    //     elementSize: 16,
    //     colors: ['#bb45e9', '#e94581'],
    // });

    // function useElementSize() {
    //     const [width, setWidth] = useState(0);
    //     const [height, setHeight] = useState(0);
    //     const [element, setElement] = useState(null);

    //     useEffect(() => {
    //         const observer = new ResizeObserver(entries => {
    //             if (entries[0]) {
    //                 setWidth(entries[0].contentRect.width);
    //                 setHeight(entries[0].contentRect.height);
    //             }
    //         });

    //         if (element) {
    //             observer.observe(element);
    //         }

    //         return () => {
    //             observer.disconnect();
    //         };
    //     }, [element]);

    //     return [element, width, height, setElement];
    // }
    // const [width, height] = useElementSize();

    // useEffect(() => {
    //     confettiReward();
    // }, []);

    const advertState: TranslationKey = advert.isDirectTransfer ? 'marketplace.adverts-state-direct' : `advert-state.${advert.state}`;

    return (
        <>
            <CardActionArea sx={{ minHeight: '200px' }} onClick={() => onClick(advert)}>
                <Card sx={{ display: 'flex', flexDirection: matches ? 'column' : 'row', minHeight: '200px' }}>
                    {advert.image && <CardMedia sx={{ minWidth: 180, minHeight: 180 }} image={advert.image}></CardMedia>}
                    <CardContent
                        sx={{
                            'position': 'relative',
                            'overflow': 'hidden',
                            'p': 2,
                            'display': 'flex',
                            'flexDirection': 'column',
                            'flex': 1,
                            '&:last-child': {
                                paddingBottom: 0,
                                marginBottom: 0,
                            },
                        }}
                    >
                        <Box sx={{ display: 'flex', alignCenter: 'center', mb: 0.5, flexWrap: 'wrap' }}>
                            <Typography variant="body2" color="text.secondary">
                                {moment(createdOn).fromNow()}
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    ml: 'auto',
                                }}
                            >
                                {state === 'paid' ? (
                                    <Chip
                                        size="small"
                                        sx={{
                                            background: 'linear-gradient(117deg, rgba(187,69,233,1) 0%, rgba(233,69,129,1) 100%)',
                                            color: 'white',
                                            borderRadius: 2,
                                        }}
                                        label="Paid"
                                    />
                                ) : state ? (
                                    <StateChip state={advertState} />
                                ) : (
                                    <Chip size="small" sx={{ borderColor: 'black', borderRadius: 2 }} label="State Unknown" />
                                )}
                            </Box>
                        </Box>
                        {state === 'paid' ? (
                            // <Box width={width as any} height={height as any} sx={{ display: 'block', justifyContent: 'center' }}>
                            <Box sx={{ width: '100%', position: 'absolute', top: '-20px', left: '100px' }} id={`confetti-${id}`} />
                        ) : // </Box>
                        null}

                        <Box sx={{ flex: 1, overflow: 'hidden' }}>
                            <Typography noWrap={true} textOverflow="ellipsis" fontSize={18}>
                                {title}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    mb: 1.5,
                                    overflow: 'hidden',
                                    display: '-webkit-box',
                                    WebkitLineClamp: '4',
                                    WebkitBoxOrient: 'vertical',
                                    textOverflow: 'ellipsis',
                                }}
                                color="text.secondary"
                            >
                                {description}
                            </Typography>
                        </Box>
                        <Box></Box>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', py: 1 }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 1,
                                    flex: 1,
                                    alignItems: 'center',
                                    mb: 1,
                                }}
                            >
                                {squeegeeAssets?.length && <AttachmentCount count={squeegeeAssets.length} />}
                                {tags.map(tag => {
                                    return <Chip key={tag} size="small" sx={{ color: 'text.secondary', borderRadius: 2 }} label={tag} />;
                                })}
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'right',
                                }}
                            >
                                <Typography sx={{ lineHeight: '1.5rem' }}>
                                    <Price price={price} currency={currency} />
                                </Typography>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </CardActionArea>
        </>
    );
};

export default MyAdvertListingCard;
