import { Box, Grid, Paper, Typography } from '@mui/material';
import { GradientButton } from '@nexdynamic/nex-ui-react';
import type { BulkPriceChange } from '@nexdynamic/squeegee-common';
import { useNavigate } from 'react-router-dom';
import { Data } from '../../../../../../Data/Data';
import { Prompt } from '../../../../../../Dialogs/Prompt';
import useTranslation from '../../../../../hooks/useTranslation';
import AudienceFiltersPreview from '../../../../../send/features/audiences/component/filters/AudienceFiltersPreview';

export const ChangeAffectedJobs = ({ change }: { change: BulkPriceChange }) => {
    const navigate = useNavigate();
    const selectAffectedJobs = () => {
        navigate(`/pricing/changes/${change._id}/affected-jobs`);
    };

    const { t } = useTranslation();

    const clearAffectedJobs = async () => {
        if (!(await new Prompt('general.clear', 'bulk-price-change.clear-affected-jobs-prompt').show())) return;
        delete change.affectedJobFilter;
        Data.put(change);
        navigate('', { replace: true });
    };

    return (
        <Paper sx={{ p: 3, mt: 2 }}>
            <Grid container>
                <Grid item lg={12}>
                    <Typography sx={{ mb: 1.5 }} variant='h6'>
                        Affected Customers/Jobs
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <GradientButton
                        text={change.affectedJobFilter ? t('general.edit') : t('bulk-price-change.select-affected-jobs')}
                        variant='contained'
                        color='success'
                        sx={{ mb: 1 }}
                        onClick={() => selectAffectedJobs()}
                    />
                    {change.affectedJobFilter && (
                        <>
                            <GradientButton
                                text={t('general.clear')}
                                variant='contained'
                                color='primary'
                                sx={{ ml: 2, mb: 1 }}
                                onClick={() => clearAffectedJobs()}
                            />
                        </>
                    )}
                </Grid>
                {change.affectedJobFilter && (
                    <Grid item xs={12}>
                        <Paper>
                            <Box p={3}>
                                <AudienceFiltersPreview
                                    filterGroups={change.affectedJobFilter}
                                    audienceDescription='bulk-price-change.affected-jobs-title'
                                    audienceNoResultsDescription='bulk-price-change.affected-jobs-no-results'
                                />
                            </Box>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
};
