import { Box } from '@mui/system';
import { useState } from 'react';
import { AdvertList } from '../components/AdvertList';
import MarketplaceNavbar from '../components/MarketplaceNavbar';
import useAdverts from '../hooks/useAdverts';

export type FilterAdvertsQuery = {
    selectedSellers?: Array<string>;
    tags?: Array<string>;
    keyword?: string;
};

export const Adverts = () => {
    const [query, setQuery] = useState<FilterAdvertsQuery>({});
    const { adverts, refetchAdverts } = useAdverts(query);
    const rawAdverts = useAdverts().adverts;

    const cancelSearch = () => {
        setQuery({
            ...query,
            keyword: '',
        });
    };

    const onQueryChange = (newQuery: FilterAdvertsQuery) => {
        const updatedQuery = {
            ...query,
            ...newQuery,
        };
        setQuery(updatedQuery);
    };

    return (
        <Box>
            <MarketplaceNavbar
                refresh={refetchAdverts}
                allData={rawAdverts}
                cancelSearch={cancelSearch}
                requestSearch={onQueryChange}
                searched={query.keyword || ''}
            />

            <AdvertList onSaved={() => refetchAdverts()} adverts={adverts}></AdvertList>
        </Box>
    );
};
