import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

type Props = {
    open: boolean;
    onClose: () => void;
};

const SmallScreenWarning = ({ open, onClose }: Props) => {
    // TODO: translate this
    // const { t } = useTranslation();
    return (
        <Dialog open={open}>
            <DialogTitle>Warning</DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    Editing a desktop layout on a mobile device is not recommended. For the best experience, please use a device with a
                    larger screen.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SmallScreenWarning;
