import type { BulkPriceChange } from '@nexdynamic/squeegee-common';
import { Data } from '../../../Data/Data';
import { ChangeFields } from '../features/changes/views/change/ChangeFields';


export const updatePriceChange: (fields: ChangeFields) => Promise<BulkPriceChange> = async (fields) => {
    if (!fields.id)
        throw new Error('Cannot edit a price change without an id');

    const change = Data.get<BulkPriceChange>(fields.id);
    if (!change)
        throw new Error(`Could not find price change with id ${fields.id}`);

    const bulkPriceChange = ChangeFields.updateBulkPriceChange(change, fields);
    await Data.put(bulkPriceChange);

    return bulkPriceChange;
};
