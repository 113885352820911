import { useEffect, useState } from 'react';
import { SqueegeeLocalStorage } from '../../Data/SqueegeeLocalStorage';
import { SqueegeeLocalStorageChangeEvent } from '../../Events/SqueegeeLocalStorageChangeEvent';

export default function useLocalStorage<TValue>(key: string, initialValue?: TValue): [TValue | null, (value: TValue) => void] {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<TValue | null>(() => {
        try {
            // Get from local storage by key
            const item = typeof window !== 'undefined' ? SqueegeeLocalStorage.getItem(key) : undefined;
            if (item === undefined || item === null) return initialValue;
            try {
                return JSON.parse(item);
            } catch (e) {
                return item;
            }
            // Parse stored json or if none return initialValue
        } catch (error) {
            // If error also return initialValue
            console.error(error);
            return initialValue;
        }
    });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: TValue) => {
        try {
            // Allow value to be a function so we have same API as useState
            const newValue = value instanceof Function ? value(storedValue) : value;

            const valueToStore = newValue !== undefined && typeof newValue !== 'string' ? JSON.stringify(newValue) : newValue;
            // Save to local storage
            SqueegeeLocalStorage.setItem(key, valueToStore);
            setStoredValue(newValue);
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.error(error);
        }
    };

    useEffect(() => {
        const subscription = SqueegeeLocalStorageChangeEvent.subscribe((event: SqueegeeLocalStorageChangeEvent) => {
            // Don't update if the key isn't in the changes
            if (key in event.changes === false) return;

            const newValue = event.changes[key];

            if (newValue === null) {
                setStoredValue(newValue);
            } else {
                setStoredValue(newValue === undefined ? undefined : JSON.parse(newValue));
            }
        });
        return () => {
            subscription.dispose();
        };
    }, [key]);

    return [storedValue, setValue];
}
