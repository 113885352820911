import type { Quote } from '@nexdynamic/squeegee-common';
import { copyObject, Currency } from '@nexdynamic/squeegee-common';
import { CustomerActions } from '../../Customers/CustomerActions';
import { Data } from '../../Data/Data';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import type { Subscription } from '../../Events/DataRefreshedEvent';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import type { IMenuBarAction } from '../../Menus/IMenuBarAction';
import { QuoteUtils } from '../QuoteUtils';

export class QuoteSummaryDialog extends CustomDialog<void> {
    currencySymbol?: string;
    menuActions: Array<IMenuBarAction>;
    constructor(public quote: Quote) {
        super('quoteSummaryDialog', '../Quotes/Dialogs/QuoteSummaryDialog.html', 'general.quote', {
            cssClass: 'quote-summary-dialog no-nav-shadow',
            isSecondaryView: true,
            okLabel: '',
            cancelLabel: '',
        });

        this.loadQuote();
    }

    private _dataRefreshedSub?: Subscription;
    async init() {
        this._dataRefreshedSub = DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
            if (event.filterByType('quotedjobs').length) {
                console.log('quote loading');
                this.loadQuote();
                return;
            }
            if (this.quote && event && event.updatedObjects[this.quote._id]) {
                this.loadQuote();
            }
        });
    }

    loadQuote() {
        const quote = Data.get<Quote>(this.quote._id);
        console.log('quote', quote);
        if (!quote || quote?._deleted) return this.ok();
        this.quote = copyObject(quote);

        this.menuActions = QuoteUtils.getMenuActions(this.quote);
        this.currencySymbol = Currency.get(this.quote.currency)?.symbol;
        this.createFab(QuoteUtils.getFabActions(this.quote));
    }

    dispose() {
        if (this._dataRefreshedSub) this._dataRefreshedSub.dispose();
        super.dispose();
    }

    viewCustomer() {
        if (!this.quote) return;
        CustomerActions.view(this.quote.customerId);
    }
}
