import { Box } from '@mui/material';
import { useContext } from 'react';
import { UserService } from '../../../Users/UserService';
import TrackerContext from '../TrackerContext';
import TrackerList from '../components/dialogs/TimeEntriesListDialog';
import TrackerStats from '../components/sections/StatSection';
import TimelineSection from '../components/sections/TimelineSection';

const TrackerMain = () => {
    const { timeEntries, selectedWorkerId, setSelectedWorkerId, selectedDate, setSelectedDate, dayStats, weekStats } =
        useContext(TrackerContext);

    const unfilteredWorkers = UserService.getActiveUsers();

    return (
        <>
            <Box padding={3} height='100%' display='flex' flexDirection='column' flex='1'>
                <TrackerStats timeEntries={timeEntries} stats={weekStats} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                <TimelineSection />
                <TrackerList
                    open={selectedWorkerId !== null}
                    onClose={() => setSelectedWorkerId(null)}
                    workerName={unfilteredWorkers.find(w => w._id === selectedWorkerId)?.name || 'Unknown'}
                    selectedWorkerId={selectedWorkerId}
                    timeEntries={dayStats?.timeEntries ? dayStats.timeEntries?.filter(te => te.assigneeId === selectedWorkerId) : []}
                />
            </Box>
        </>
    );
};

export default TrackerMain;
