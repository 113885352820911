import type { IAdvertisement } from '@nexdynamic/squeegee-common';

import { useEffect, useState } from 'react';
import { RethinkDbAuthClient } from '../../../Server/RethinkDbAuthClient';
import { getMyAdverts } from '../services/AdvertisementService';
import type { FilterAdvertsQuery } from '../views/Adverts';

const useMyAdverts = (query?: FilterAdvertsQuery) => {
    const [adverts, setAdverts] = useState<Array<IAdvertisement>>([]);
    const [filteredAdverts, setFilteredAdverts] = useState<Array<IAdvertisement>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const sessionKey = RethinkDbAuthClient.getSessionKeyAndValue();

    const requestSearch = (adverts: Array<IAdvertisement>, filterAdvertsQuery: FilterAdvertsQuery = {}) => {
        const { selectedSellers, keyword, tags } = filterAdvertsQuery;
        const filteredData = adverts.filter(
            advert =>
                (!selectedSellers?.length || selectedSellers.includes(advert.sellerName)) &&
                (!tags?.length || tags.some(tag => advert.tags.indexOf(tag) > -1)) &&
                (!tags?.length || !!advert.tags.find(x => tags.includes(x))) &&
                (!keyword ||
                    advert.sellerName.toLowerCase().includes(keyword.toLowerCase()) ||
                    advert.description.toLowerCase().includes(keyword.toLowerCase()) ||
                    advert.title.toLowerCase().includes(keyword.toLowerCase()) ||
                    advert.tags.toString().toLowerCase().includes(keyword.toLowerCase()))
        );

        setFilteredAdverts(filteredData.sort((a, b) => (a.createdOn < b.createdOn ? 1 : -1)));
    };

    const fetchAdvertisements = async () => {
        if (!sessionKey) {
            return console.error('ERROR SESSION KEY NOT SET');
        }
        setLoading(true);
        const advertsResponse = await getMyAdverts();
        setAdverts(advertsResponse.advertisements);
        setLoading(false);
    };

    useEffect(() => {
        fetchAdvertisements();
    }, []);
    useEffect(() => {
        requestSearch(adverts, query);
    }, [query, adverts]);

    return {
        loading,
        adverts: filteredAdverts,
        refetchAdverts: fetchAdvertisements,
    };
};

export default useMyAdverts;
