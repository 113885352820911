import { Typography } from '@mui/material';
import type React from 'react';

const HelperText = ({
    helperText,
    configErrors,
    property,
}: {
    helperText: React.ReactNode;
    configErrors: Array<{ message: string; key: string }>;
    property: string;
}) => {
    const error = configErrors.find(x => x.key === property);
    const text = error?.message || helperText;

    return (
        <Typography variant="caption" color={error ? 'error' : 'inherit'}>
            {text}
        </Typography>
    );
};

export default HelperText;
