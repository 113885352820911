import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse, ListItemButton, ListItemText, Paper, Stack } from '@mui/material';
import { type TranslationKey } from '@nexdynamic/squeegee-common';
import type { ComponentNames } from '@nexdynamic/squeegee-portal-common';
import { useState } from 'react';
import type { AureliaReactComponentDialogComponent } from '../../../Dialogs/AureliaReactComponentDialogComponent';
import useTranslation from '../../hooks/useTranslation';
import { settingEditors } from './settingEditors';

export const ComponentSettingsMenu: AureliaReactComponentDialogComponent = () => {
    const editors = Object.entries(settingEditors).filter(([, Component]) => Component !== null);
    const [expanded, setExpanded] = useState<{ [key: string]: boolean }>(editors.reduce((acc, [key]) => ({ ...acc, [key]: false }), {}));

    const onExpand = (key: ComponentNames) => {
        setExpanded(prev => ({ ...prev, [key]: !prev[key] }));
    };

    const { t } = useTranslation();

    return (
        <Stack spacing={1} p={2}>
            {editors.map(([key, Component]: [key: ComponentNames, Component: AureliaReactComponentDialogComponent<any>]) => (
                <Paper sx={{ overflow: 'hidden' }} key={key}>
                    <ListItemButton onClick={() => onExpand(key)}>
                        <ListItemText primary={t(`portal-component.${key}` as TranslationKey)} />
                        <ExpandMore
                            sx={{
                                color: 'textSecondary',
                                transform: expanded[key] ? 'rotate(180deg)' : 'rotate(0deg)',
                                transition: 'transform 0.2s ease-in-out',
                            }}
                        />
                    </ListItemButton>
                    <Collapse in={expanded[key]}>{Component && <Component />}</Collapse>
                </Paper>
            ))}
        </Stack>
    );
};
