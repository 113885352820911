import LeadIcon from '@mui/icons-material/AssignmentOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import QuoteIcon from '@mui/icons-material/FormatQuoteOutlined';
import WorkIcon from '@mui/icons-material/WorkOutlineOutlined';
import { List, ListItem, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, ListSubheader, Stack } from '@mui/material';

import type { Customer } from '@nexdynamic/squeegee-common';
import { type ISqueegeeAdvertAsset } from '@nexdynamic/squeegee-common';
import { CustomerDialog } from '../../../Customers/Components/CustomerDialog';
import { JobSummaryDialog } from '../../../Customers/Components/JobSummaryDialog';

import { useMemo } from 'react';
import { ApplicationState } from '../../../ApplicationState';
import { Data } from '../../../Data/Data';
import { Price } from './Price';

type AdvertAssetsProps = {
    squeegeeAssets: Array<ISqueegeeAdvertAsset>;
    onClose: () => void;
};

const AssetTypeIcon = ({ type }: { type: 'lead' | 'quoted-lead' | 'job' }) => {
    switch (type) {
        case 'job': {
            return <WorkIcon />;
        }
        case 'quoted-lead': {
            return <QuoteIcon />;
        }
        default:
            return <LeadIcon />;
    }
};

export const AdvertAssets = ({ squeegeeAssets, onClose }: AdvertAssetsProps) => {
    const openAsset = (customerId: string, jobId?: string) => {
        const customer = Data.get<Customer>(customerId);
        if (!customer) {
            // create snackbar alert
            return;
        }

        const customerDialog = new CustomerDialog(customer);
        customerDialog.show();

        if (jobId) {
            const job = customer.jobs[jobId];
            if (!job) {
                // create snackbar alert
            }
            const jobDialog = new JobSummaryDialog(customer, job);
            jobDialog.show();
        }

        onClose();
    };

    const disabled = useMemo(() => {
        if (!ApplicationState.dataEmail) {
            return true;
        }

        for (let i = 0; i < squeegeeAssets.length; i++) {
            const attachedCustomer = Data.get(squeegeeAssets[i].customerId);
            if (!attachedCustomer) {
                return true;
            }

            if (attachedCustomer.ownerEmail !== ApplicationState.dataEmail) {
                return true;
            }
        }

        return false;
    }, [squeegeeAssets]);

    return (
        <List subheader={<ListSubheader disableGutters>Assets</ListSubheader>}>
            {squeegeeAssets &&
                squeegeeAssets.map(asset => (
                    <ListItem key={asset.id} title={asset.servicesDescription} disableGutters>
                        <ListItemButton
                            onClick={() => openAsset(asset.customerId, asset.type === 'job' ? asset.id : undefined)}
                            disabled={disabled}
                        >
                            <ListItemAvatar>
                                <AssetTypeIcon type={asset.type}></AssetTypeIcon>
                            </ListItemAvatar>
                            <ListItemText primary={asset.servicesDescription} secondary={asset.frequencyDescription} />
                            <ListItemSecondaryAction>
                                <Stack direction="row" gap={1} alignItems="center">
                                    {asset.price ? <Price price={asset.price} /> : 'No Price'}
                                    <ChevronRightIcon />
                                </Stack>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                    </ListItem>
                ))}
        </List>
    );
};
