import type { SettingIds } from '@nexdynamic/squeegee-common';
import { useEffect, useState } from 'react';
import { ApplicationState } from '../../ApplicationState';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';

export function useSetting<ValueType>(id: SettingIds): [ValueType | undefined, (value: ValueType | undefined) => void] {
    const [value, setValue] = useState<ValueType | undefined>(ApplicationState.getSetting(id));

    useEffect(() => {
        const sub = DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
            if (event.filterByType('settings')) {
                setValue(ApplicationState.getSetting(id));
            }
        });

        return () => {
            sub.dispose();
        };
    }, []);

    const setSetting = (value: ValueType) => {
        ApplicationState.setSetting(id, value);
    };

    return [value, setSetting];
}
