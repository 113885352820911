import type { SelectChangeEvent } from '@mui/material';
import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, InputLabel, Typography } from '@mui/material';
import type { IAdvertisement, ISqueegeeAdvertAsset } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import type { AureliaReactComponentDialogComponentStandardProps } from '../../Dialogs/AureliaReactComponentDialogComponent';
import { Logger } from '../../Logger';
import { createAdvertisement, transferFranchiseAssets } from '../Marketplace/services/AdvertisementService';
import { SelectFranchiseSibling } from './SelectFranchiseSibling';

export type TransferQuotesProps = {
    quoteIds: Array<string>;
    type: 'quoted-lead' | 'lead';
    customerId: string;
    notes: string;
};

type Props = AureliaReactComponentDialogComponentStandardProps<boolean> & TransferQuotesProps;

export const TransferQuotes = ({ quoteIds, type, customerId, notes, okCallback }: Props) => {
    const [statusMessage, setStatusMessage] = useState<string | null>('Creating Transfer');
    const attachSqueegeeAssets = quoteIds.map(x => ({ id: x, type, customerId } as ISqueegeeAdvertAsset));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        accountEmail: '',
        complianceAgreement: false,
    });

    const handleSuccess = async (advert: IAdvertisement) => {
        await transferFranchiseAssets(advert.id);
        okCallback(true);
    };

    const handleFailed = (err: Error) => {
        setIsSubmitting(false);
        setFormData({ accountEmail: formData.accountEmail, complianceAgreement: false });
        setStatusMessage(`Uh oh there was an error creating a transfer for these quotes`);
        Logger.error(err.message);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmitting(true);
        setTimeout(() => {
            const title = type === 'lead' ? 'Transfer Quote Request' : 'Transfer Quoted Lead';
            let description = type === 'lead' ? 'Transfer a customer request for a quote' : 'Transfer a quoted customer job';
            if (notes) description += `\n\n${notes}`;

            createAdvertisement({
                title,
                description,
                price: 0,
                tags: [type === 'lead' ? 'quote request' : 'quote', 'transfer'],
                type: 'squeegee-assets',
                attachSqueegeeAssets,
                directTransferAccountEmail: formData.accountEmail,
            })
                .then(x => handleSuccess(x.advertisements[0]))
                .catch(handleFailed);
        }, 2000);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = e.target;
        setFormData({ ...formData, [name]: name === 'accountEmail' ? value : checked });
    };

    const handleSelectChange = (e: SelectChangeEvent) => {
        setFormData({ ...formData, accountEmail: e.target.value });
    };

    if (!quoteIds)
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    textAlign: 'center',
                }}
            >
                <Typography variant="h3">Quote ids not found</Typography>
            </Box>
        );

    return (
        <Box padding={3}>
            {isSubmitting ? (
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            ) : (
                <form onSubmit={handleSubmit}>
                    <Box marginBottom={2}>
                        <Typography variant="h5">Transfer Quotes</Typography>
                    </Box>

                    <Box marginBottom={2}>{attachSqueegeeAssets.length} quotes will be transferred.</Box>

                    <Box marginBottom={2}>
                        <Typography variant="h6">Transfer Quotes</Typography>
                    </Box>
                    <Box marginBottom={2}>
                        <FormControl fullWidth>
                            <InputLabel>Transfer to account</InputLabel>
                            <SelectFranchiseSibling formData={formData} handleSelectChange={handleSelectChange} />
                        </FormControl>
                    </Box>
                    <Box marginBottom={2}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="complianceAgreement"
                                    name="complianceAgreement"
                                    checked={formData.complianceAgreement}
                                    onChange={handleInputChange}
                                    color="primary"
                                    required
                                />
                            }
                            label="I agree to comply with Squeegee policies and guidelines."
                        />
                    </Box>

                    <Box marginBottom={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={!formData.complianceAgreement || !formData.accountEmail}
                        >
                            Submit Transfer
                        </Button>
                    </Box>
                    {statusMessage && <Box marginBottom={2}>{statusMessage}</Box>}
                </form>
            )}
        </Box>
    );
};
