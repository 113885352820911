import QuickreplyIcon from '@mui/icons-material/Quickreply';
import { Box, List, Paper, Typography } from '@mui/material';
import { GradientButton } from '@nexdynamic/nex-ui-react';
import { uuid } from '@nexdynamic/squeegee-common';
import { useState } from 'react';
import { ApplicationState } from '../../ApplicationState';
import useTranslation from '../hooks/useTranslation';
import type { CannedResponse } from './CannedResponse';
import CannedResponseListItem from './CannedResponseListItem';
import EditCannedResponseDialog from './EditCannedResponseDialog';

const SettingsCannedResponsesDialog = () => {
    const { t } = useTranslation();

    const [cannedResponses, setCannedResponses] = useState<Array<CannedResponse>>(ApplicationState.cannedResponses);
    const [selectedCannedResponse, setSelectedCannedResponse] = useState<CannedResponse | null>(null);

    const handleSave = (cannedResponse: CannedResponse) => {
        const index = cannedResponses.findIndex(cr => cr.id === cannedResponse.id);
        if (index > -1) {
            cannedResponses[index] = cannedResponse;
            ApplicationState.setSetting('global.canned-responses', cannedResponses);
            setCannedResponses([...cannedResponses]);
            setSelectedCannedResponse(null);
            return;
        }
        ApplicationState.setSetting('global.canned-responses', [...cannedResponses, cannedResponse]);
        setCannedResponses([...cannedResponses, cannedResponse]);
        setSelectedCannedResponse(null);
    };

    const handleDelete = (cannedResponse: CannedResponse) => {
        const index = cannedResponses.findIndex(cr => cr.id === cannedResponse.id);
        if (index > -1) {
            cannedResponses.splice(index, 1);
            ApplicationState.setSetting('global.canned-responses', cannedResponses);
            setCannedResponses([...cannedResponses]);
            setSelectedCannedResponse(null);
        }
    };

    const handleSelect = (cannedResponse: CannedResponse) => {
        setSelectedCannedResponse(cannedResponse);
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                <Typography variant="h6">{t('settings.canned-responses-title')}</Typography>
                <GradientButton
                    variant="contained"
                    color="primary"
                    onClick={() => setSelectedCannedResponse({ id: uuid(), title: '', message: '', tags: [] })}
                    disabled={!!selectedCannedResponse}
                >
                    {t('settings.canned-response-text-title-new')}
                </GradientButton>
            </Box>
            <Paper sx={{ mx: 2 }}>
                {!selectedCannedResponse && cannedResponses.length ? (
                    <List>
                        {cannedResponses.map(cannedResponse => (
                            <CannedResponseListItem
                                key={cannedResponse.id}
                                primary={cannedResponse.title}
                                secondary={cannedResponse.message.slice(0, 50)}
                                tags={cannedResponse.tags}
                                onSelect={() => handleSelect(cannedResponse)}
                            />
                        ))}
                    </List>
                ) : (
                    selectedCannedResponse && (
                        <EditCannedResponseDialog
                            showDelete={cannedResponses.some(cr => cr.id === selectedCannedResponse.id)}
                            cannedResponse={selectedCannedResponse}
                            onSave={handleSave}
                            onDelete={handleDelete}
                            onCancel={() => setSelectedCannedResponse(null)}
                        />
                    )
                )}
                {!cannedResponses.length && !selectedCannedResponse && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, alignItems: 'center', gap: 1 }}>
                        <QuickreplyIcon sx={{ fontSize: 32 }} color="disabled" />
                        <Typography>{t('settings.canned-responses-empty')}</Typography>
                    </Box>
                )}
            </Paper>
            <Paper sx={{ m: 2, p: 2 }}>
                <Typography variant="body2">{t('settings.canned-responses-description-long')}</Typography>
            </Paper>
        </>
    );
};

export default SettingsCannedResponsesDialog;
