import { Editor } from '@monaco-editor/react';
import CodeIcon from '@mui/icons-material/Code';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LightModeIcon from '@mui/icons-material/LightMode';
import SettingsIcon from '@mui/icons-material/Settings';
import type { Palette, PaletteOptions, TypeText } from '@mui/material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Divider,
    ListItem,
    ListItemText,
    Paper,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import createPalette from '@mui/material/styles/createPalette';
import type { TypographyOptions } from '@mui/material/styles/createTypography';

import { GradientButton } from '@nexdynamic/nex-ui-react';
import { type TranslationKey } from '@nexdynamic/squeegee-common';
import { useEffect, useState } from 'react';
import { ApplicationState } from '../../../ApplicationState';
import type { AureliaReactComponentDialogComponent } from '../../../Dialogs/AureliaReactComponentDialogComponent';
import type { PortalTheme } from '../../../DirectoryAndPortal/types/PortalTheme';
import { createPortalTheme } from '../../../DirectoryAndPortal/types/PortalTheme';
import { NotifyUserMessage } from '../../../Notifications/NotifyUserMessage';
import { isDevMode } from '../../../isDevMode';
import AdvBorderRadius from './components/AdvBorderRadius';
import type { FontVariant } from './components/AdvFontFamily';
import AdvFontFamily from './components/AdvFontFamily';
import AdvOther from './components/AdvOther';
import BackgroundPicker from './components/BackgroundPicker';
import ColorPickerListItem from './components/ColorPickerListItem';
import ThemeCard from './components/ThemeCard';
import { themePresets } from './themePresets';

export type ThemeEditorDialogProps = {
    currentThemeOptions: PortalTheme;
};

type Props = ThemeEditorDialogProps & {
    okCallback: (theme: PortalTheme) => void;
    cancelCallback: () => void;
};

const ThemeEditorDialogComponent: AureliaReactComponentDialogComponent<Props> = ({ currentThemeOptions, okCallback, cancelCallback }) => {
    if (!currentThemeOptions.theme.palette) currentThemeOptions.theme.palette = {};
    if (!currentThemeOptions.theme.palette.text) currentThemeOptions.theme.palette.text = {};

    const [hasChanged, setHasChanged] = useState<boolean>(false);

    const [editingMode, setEditingMode] = useState<'pretty' | 'raw'>(() => {
        const mode = localStorage.getItem('preferred-theme-editor-mode') as 'pretty' | 'raw';
        return mode || 'pretty';
    });
    const [selectedTheme, setSelectedTheme] = useState<PortalTheme>(currentThemeOptions);
    const [customThemes, setCustomThemes] = useState<Array<PortalTheme>>(ApplicationState.getSetting('global.custom-themes', []));
    const [themeName, setThemeName] = useState<string>(selectedTheme.name);

    const [palette, setPalette] = useState<PaletteOptions | undefined>(selectedTheme.theme.palette);

    const handleNewTheme = (themeToCopy?: PortalTheme['theme']) => {
        const newTheme = {
            id: `custom-${new Date().getTime()}`,
            name: `Custom Theme ${customThemes?.length || 0 + 1}`,
            theme: createPortalTheme(themeToCopy || {}),
        };
        setSelectedTheme(newTheme);
        setCustomThemes([...customThemes, newTheme]);
        setThemeName(newTheme.name);
        return newTheme;
    };

    const updatePalette = (newColor: string, keyToUpdate: keyof PaletteOptions, isBackground?: boolean) => {
        let newTheme: PortalTheme = selectedTheme;
        const paletteToAugment = createPortalTheme(selectedTheme.theme).palette;
        let newPalette;

        if (isBackground) {
            newPalette = {
                ...palette,
                background: {
                    ...paletteToAugment.background,
                    [keyToUpdate]: newColor,
                },
            } as Palette;
        } else {
            newPalette = {
                ...palette,
                [keyToUpdate]:
                    keyToUpdate === 'divider' ? newColor : paletteToAugment.augmentColor({ color: { main: newColor }, name: keyToUpdate }),
            } as Palette;
        }

        newTheme.theme.palette = newPalette;

        // If the theme is a preset and we're modifying the palette, create a new custom theme object with a custom theme name/id
        if (!selectedTheme.id.startsWith('custom-')) {
            const newCustomTheme = handleNewTheme(newTheme.theme);
            newTheme = newCustomTheme;
        }

        setSelectedTheme({ ...newTheme });
        setCustomThemes(
            customThemes.some(t => t.id === newTheme.id)
                ? customThemes.map(t => (t.id === newTheme.id ? newTheme : t))
                : [...customThemes, newTheme]
        );
    };

    const switchThemeMode = (mode: 'light' | 'dark') => {
        const newTheme = createPortalTheme(selectedTheme.theme);
        newTheme.palette.mode = mode;
        setSelectedTheme({ ...selectedTheme, theme: newTheme });
    };

    const handleSelectTheme = (themeId: string) => {
        const theme = customThemes.concat(themePresets).find(t => t.id === themeId);
        if (!theme) return console.error('Theme not found');
        const newTheme = {
            ...theme,
            theme: createPortalTheme(theme.theme),
        };
        if (theme) {
            setSelectedTheme(newTheme);
            setThemeName(newTheme.name);
        } else {
            console.error('Theme not found');
        }
    };

    const addFont = (variantsToChange: Array<FontVariant>, fontFamily: string, fontWeight?: number) => {
        setSelectedTheme({
            ...selectedTheme,
            theme: {
                ...selectedTheme.theme,
                typography: {
                    ...selectedTheme.theme.typography,
                    ...variantsToChange.reduce((acc: any, variant: keyof TypographyOptions) => {
                        acc[variant] = {
                            fontFamily,
                            fontWeight,
                        };
                        return acc;
                    }, {}),
                },
            },
        });
    };

    const changeBorderRadius = (borderRadius: number) => {
        setSelectedTheme({
            ...selectedTheme,
            theme: {
                ...selectedTheme.theme,
                shape: {
                    borderRadius,
                },
            },
        });
    };

    const handleChangeElevationColorShift = (disableElevationColorShift: boolean) => {
        if (disableElevationColorShift) {
            const newTheme = selectedTheme.theme;
            newTheme.components = {
                ...newTheme.components,
                MuiPaper: {
                    ...(newTheme.components?.MuiPaper || {}),
                    styleOverrides: {
                        ...newTheme.components?.MuiPaper?.styleOverrides,
                        root: {
                            ...(typeof newTheme.components?.MuiPaper?.styleOverrides?.root === 'object'
                                ? newTheme.components?.MuiPaper?.styleOverrides?.root
                                : {}),
                            backgroundImage: 'none',
                        },
                    },
                },
            };

            setSelectedTheme({
                ...selectedTheme,
                theme: newTheme,
            });
        } else {
            const newTheme = selectedTheme.theme;
            delete (newTheme.components?.MuiPaper?.styleOverrides?.root as Record<string, any>)?.['backgroundImage'];
            setSelectedTheme({
                ...selectedTheme,
                theme: newTheme,
            });

            setHasChanged(true);
        }
    };
    const handleApply = () => {
        const theme = selectedTheme.theme;
        delete theme.unstable_sxConfig;
        if (!theme.palette) theme.palette = {};
        if (theme.palette.text) theme.palette.text = {};

        const currentCustomThemes = ApplicationState.getSetting<Array<PortalTheme>>('global.custom-themes', []);
        if (selectedTheme.id.startsWith('custom-')) {
            const updatedCustomThemes = currentCustomThemes.some(t => t.id === selectedTheme.id)
                ? currentCustomThemes.map(t => (t.id === selectedTheme.id ? selectedTheme : t))
                : [...currentCustomThemes, selectedTheme];
            ApplicationState.setSetting('global.custom-themes', updatedCustomThemes);
        }
        okCallback(selectedTheme);
    };

    useEffect(() => {
        const newTheme = selectedTheme.theme;
        setPalette(newTheme.palette || {});
        if (selectedTheme.id.startsWith('custom-')) {
            setCustomThemes(customThemes.map(t => (t.id === selectedTheme.id ? selectedTheme : t)));
        }
        const hasChanged = JSON.stringify(selectedTheme) !== JSON.stringify(currentThemeOptions);
        setHasChanged(hasChanged);
    }, [selectedTheme]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, p: 2, gap: 1, overflowY: 'auto' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">Edit Theme</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <ToggleButtonGroup
                        value={editingMode}
                        size="small"
                        exclusive
                        onChange={(_, value) => setEditingMode(value)}
                        color="primary"
                    >
                        <ToggleButton value="pretty">
                            <SettingsIcon />
                        </ToggleButton>
                        <ToggleButton value="raw">
                            <CodeIcon />
                        </ToggleButton>
                    </ToggleButtonGroup>
                    {hasChanged && (
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <GradientButton variant="outlined" onClick={cancelCallback}>
                                Discard
                            </GradientButton>
                            <GradientButton variant="contained" color="success" onClick={handleApply}>
                                Apply
                            </GradientButton>
                        </Box>
                    )}
                </Box>
            </Box>

            <Paper sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }} elevation={1}>
                <TextField
                    label="Theme Name"
                    value={themeName}
                    onChange={e => {
                        if (e.target.value.length > 20) return;
                        setThemeName(e.target.value);
                        setSelectedTheme({ ...selectedTheme, name: e.target.value });
                    }}
                    variant="filled"
                    fullWidth
                    disabled={!selectedTheme.id.startsWith('custom-')}
                    sx={{ '& .MuiInputBase-root': { bgcolor: 'rgba(0, 0, 0, 0.02)' } }}
                />
                {editingMode === 'raw' ? (
                    <Editor
                        height="70vh"
                        defaultLanguage="json"
                        value={JSON.stringify(selectedTheme.theme, null, 2)}
                        onChange={value => {
                            try {
                                if (typeof value !== 'string') return;
                                const newTheme = JSON.parse(value);
                                setSelectedTheme({ ...selectedTheme, theme: newTheme });
                            } catch (e) {
                                new NotifyUserMessage(e.message as TranslationKey);
                            }
                        }}
                    />
                ) : (
                    <>
                        <ListItem>
                            <ListItemText primary="Mode" secondary="Choose between light and dark mode" />
                            <ToggleButtonGroup
                                value={selectedTheme.theme.palette?.mode || 'light'}
                                exclusive
                                onChange={(_, value) => switchThemeMode(value)}
                                color="primary"
                            >
                                <ToggleButton value="light">
                                    <LightModeIcon />
                                </ToggleButton>
                                <ToggleButton value="dark">
                                    <DarkModeIcon />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </ListItem>
                        <Divider />
                        <ColorPickerListItem
                            textPrimary="Primary"
                            textSecondary="Primary color for key elements and actions"
                            palette={palette}
                            updatePalette={updatePalette}
                            colorKey="primary"
                        />
                        <ColorPickerListItem
                            textPrimary="Secondary"
                            textSecondary="Secondary color for complementary elements and interactions"
                            palette={palette}
                            updatePalette={updatePalette}
                            colorKey="secondary"
                        />
                        <Divider />
                        <ColorPickerListItem
                            textPrimary="Error"
                            textSecondary="Error color indicates critical issues or validation errors"
                            palette={palette}
                            updatePalette={updatePalette}
                            colorKey="error"
                        />
                        <ColorPickerListItem
                            textPrimary="Warning"
                            textSecondary="Warning color signifies caution or non-critical alerts"
                            palette={palette}
                            updatePalette={updatePalette}
                            colorKey="warning"
                        />
                        <ColorPickerListItem
                            textPrimary="Info"
                            textSecondary="Info color conveys informational messages or hints"
                            palette={palette}
                            updatePalette={updatePalette}
                            colorKey="info"
                        />
                        <ColorPickerListItem
                            textPrimary="Success"
                            textSecondary="Success color represents successful actions or confirmations"
                            palette={palette}
                            updatePalette={updatePalette}
                            colorKey="success"
                        />
                        <Divider />
                        <BackgroundPicker
                            textPrimary="Divider"
                            textSecondary="Divider color for separating elements"
                            palette={palette}
                            updatePalette={updatePalette}
                            colorKey="divider"
                            disableGradient
                        />
                        <BackgroundPicker
                            textPrimary="Default"
                            textSecondary="Background color for default elements"
                            palette={palette}
                            updatePalette={updatePalette}
                            colorKey="default"
                        />
                        <BackgroundPicker
                            textPrimary="Paper"
                            textSecondary="Background color for paper elements"
                            palette={palette}
                            updatePalette={updatePalette}
                            colorKey="paper"
                        />

                        <Accordion disableGutters>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <ListItemText primary="Advanced" secondary="Advanced theme options" />
                            </AccordionSummary>
                            <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 2 }}>
                                {isDevMode() && (
                                    <>
                                        <Typography variant="h6">Typography</Typography>
                                        <AdvFontFamily addFont={addFont} theme={selectedTheme.theme} />
                                    </>
                                )}
                                <Typography variant="h6">Shape</Typography>
                                <AdvBorderRadius changeBorderRadius={changeBorderRadius} theme={selectedTheme.theme} />
                                <Typography variant="h6">Other</Typography>
                                <AdvOther theme={selectedTheme.theme} onDisableElevationColorShift={handleChangeElevationColorShift} />
                                <Typography variant="caption" color="text.secondary">
                                    Please contact support for guidance on making low-level customisations
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </>
                )}
            </Paper>

            <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 2 }} elevation={1}>
                <Typography variant="h6">Custom Themes</Typography>
                <Stack direction="row" spacing={1} justifyContent="center" mb={1}>
                    <GradientButton variant="contained" color="info" onClick={() => handleNewTheme()} fullWidth startIcon={<EditIcon />}>
                        New Theme
                    </GradientButton>
                    <GradientButton
                        variant="contained"
                        color="secondary"
                        onClick={() => handleNewTheme(selectedTheme.theme)}
                        fullWidth
                        startIcon={<ContentCopyIcon />}
                    >
                        Copy Selected Theme
                    </GradientButton>
                </Stack>
                {!!customThemes.length && (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                        {customThemes.map((theme: PortalTheme) => {
                            const newTheme = {
                                ...theme,
                                theme: createPortalTheme(theme.theme),
                            };

                            if (!newTheme.theme.palette) newTheme.theme.palette = createPalette({});
                            if (!newTheme.theme.palette.text) newTheme.theme.palette.text = {} as unknown as TypeText;
                            return (
                                <ThemeCard
                                    key={theme.id}
                                    theme={newTheme}
                                    selected={selectedTheme.id === newTheme.id}
                                    onClick={() => handleSelectTheme(newTheme.id)}
                                    onDelete={() => {
                                        const updatedCustomThemes = customThemes.filter(t => t.id !== theme.id);
                                        setCustomThemes(updatedCustomThemes);
                                        ApplicationState.setSetting('global.custom-themes', updatedCustomThemes);
                                        setSelectedTheme(updatedCustomThemes[customThemes.length - 2]);
                                    }}
                                />
                            );
                        })}
                    </Box>
                )}
            </Paper>
            <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 2 }} elevation={1}>
                <Typography variant="h6">Theme Presets</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                    {themePresets.map((theme: PortalTheme) => {
                        const newTheme = {
                            ...theme,
                            theme: createPortalTheme(theme.theme),
                        };
                        return (
                            <ThemeCard
                                key={theme.id}
                                theme={newTheme}
                                selected={selectedTheme.id === newTheme.id}
                                onClick={() => handleSelectTheme(newTheme.id)}
                            />
                        );
                    })}
                </Box>
            </Paper>
        </Box>
    );
};

export default ThemeEditorDialogComponent;
