import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    ListItem,
    ListItemText,
    MenuItem,
    Popover,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { GradientButton } from '@nexdynamic/nex-ui-react';
import type { AccountUser } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import type { ScheduleItem } from '../../../../Schedule/Components/ScheduleItem';
import { ScheduleService } from '../../../../Schedule/ScheduleService';
import { TimerService } from '../../../../Tracking/TimerService';
import { UserService } from '../../../../Users/UserService';
import useTranslation from '../../../hooks/useTranslation';

interface CreateTimeEntryDialogProps {
    selectedWorkerId: string;
    open: boolean;
    anchorPosition: { top: number; left: number } | undefined;
    onClose: () => void;
    selectedDate: Date;
}

const CreateTimeEntryDialog: React.FC<CreateTimeEntryDialogProps> = ({ open, anchorPosition, onClose, selectedWorkerId, selectedDate }) => {
    const [selectedWorker, setSelectedWorker] = useState<AccountUser | undefined>(UserService.getUserById(selectedWorkerId));
    const [selectedJob, setSelectedJob] = useState<ScheduleItem>();
    const [startTime, setStartTime] = useState<Date>(new Date(`${moment(selectedDate).format('YYYY-MM-DD')}T08:00:00`));
    const [endTime, setEndTime] = useState<Date>(new Date(`${moment(selectedDate).format('YYYY-MM-DD')}T17:00:00`));
    const { t } = useTranslation();
    const handleCreate = () => {
        if (!selectedWorker || !selectedJob || !startTime || !endTime) return;

        const duration = moment.duration(moment(endTime).diff(moment(startTime))).toISOString();
        // creates a time entry for the selected job occurrence
        TimerService.createManualEntryForJobOccurrence(selectedJob.occurrence, selectedWorker, duration, moment(endTime).valueOf());
        onClose();
    };

    const workers = UserService.getActiveUsers();
    const [scheduleItems, setScheduleItems] = useState<ScheduleItem[]>([]);

    const [autocompleteOpen, setAutocompleteOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setScheduleItems([]);
        const loadTimer = setTimeout(() => {
            if (!selectedWorker) return;
            const scheduleByDay = ScheduleService.getScheduledJobsForUsersAndTeamsByDay([selectedWorker._id], moment(startTime));

            const options = scheduleByDay[moment(startTime).format('YYYY-MM-DD')] || {};
            const scheduleItems = Object.values(options);

            setScheduleItems(scheduleItems);
            setLoading(false);
        }, 50);

        return () => {
            clearTimeout(loadTimer);
        };
    }, [autocompleteOpen]);

    return (
        <Popover
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            anchorReference='anchorPosition'
            open={open}
            anchorPosition={anchorPosition}
            onClose={() => {
                onClose();
                setAutocompleteOpen(false);
            }}
        >
            <Box sx={{ width: '24rem', p: 2, pt: 1, pb: 0 }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '31px',
                    }}
                >
                    <Typography variant='body1'>Create Time Entry</Typography>
                </Box>
                <Box display='flex' flexDirection='column' marginTop={1}>
                    <FormControl size='small' fullWidth>
                        <InputLabel id='worker'>Assignee</InputLabel>
                        <Select
                            size='small'
                            sx={{
                                width: '100%',
                                marginBottom: '1rem',
                            }}
                            label='Assignee'
                            labelId='worker'
                            inputProps={{
                                name: 'worker',
                                id: 'worker',
                            }}
                            value={selectedWorker?._id}
                            onChange={event =>
                                setSelectedWorker(
                                    workers.find(worker => {
                                        return worker._id === event.target.value;
                                    })
                                )
                            }
                        >
                            {workers.map(worker => {
                                return (
                                    <MenuItem key={worker._id} value={worker._id}>
                                        {worker.name}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                            mb: '1rem',
                        }}
                    >
                        <TextField
                            size='small'
                            label='Start Time'
                            type='time'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={moment(startTime).format('HH:mm')}
                            onChange={event => {
                                setStartTime(new Date(`${moment(startTime).format('YYYY-MM-DD')} ${event.target.value}`));
                                console.log(
                                    'New start time: ',
                                    new Date(`${moment(startTime).format('YYYY-MM-DD')} ${event.target.value}`)
                                );
                            }}
                            fullWidth
                        />
                        <TextField
                            size='small'
                            label='End Time'
                            type='time'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={moment(endTime).format('HH:mm')}
                            onChange={event => setEndTime(new Date(`${moment(endTime).format('YYYY-MM-DD')} ${event.target.value}`))}
                            fullWidth
                        />
                    </Box>
                    <Autocomplete
                        open={autocompleteOpen}
                        onOpen={() => setAutocompleteOpen(true)}
                        onClose={() => setAutocompleteOpen(false)}
                        loading={loading}
                        isOptionEqualToValue={(option, value) => option === value}
                        size='small'
                        options={scheduleItems}
                        getOptionLabel={option => option.addressDescription || "shouldn't see this, please contact support."}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label='Job'
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color='primary' size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                        renderOption={(props, si) => {
                            return (
                                <ListItem {...props}>
                                    <ListItemText primary={si.addressDescription} secondary={`${si.customerName}`} />
                                </ListItem>
                            );
                        }}
                        noOptionsText='No jobs scheduled within the selected parameters.'
                        onChange={(_event, value) => setSelectedJob(value || undefined)}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingY: '0.8rem', gap: '0.5rem' }}>
                    <Button
                        size='small'
                        onClick={(event: React.MouseEvent) => {
                            event.stopPropagation();
                            onClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <GradientButton
                        variant='text'
                        color='primary'
                        onClick={handleCreate}
                        disabled={!selectedWorker || !selectedJob || !startTime || !endTime}
                        text={t('general.create')}
                    />
                </Box>
            </Box>
        </Popover>
    );
};

export default CreateTimeEntryDialog;
