import ConvertedIcon from '@mui/icons-material/CurrencyExchange';
import FilterListIcon from '@mui/icons-material/FilterList';
import GroupsIcon from '@mui/icons-material/Groups';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Checkbox, FormControlLabel, IconButton, List, ListItem, Paper, Popover, Stack, Typography, useTheme } from '@mui/material';
import type { LineSeriesType } from '@mui/x-charts';
import type { DateRangeValue } from '@nexdynamic/nex-ui-react';
import { DateRange, calculatePercentageChange } from '@nexdynamic/nex-ui-react';
import moment from 'moment';
import { useMemo, useState } from 'react';
import useLeadAnalytics from '../../hooks/leads/useLeadAnalytics';
import { determineFormat, determineGrouping } from '../../hooks/portalAnalyticsUtils';
import usePortalViews from '../../hooks/usePortalViews';
import { analyticsPortalLineChartSx } from '../analyticsPortalLineChartSx';
import { analyticsRanges as ranges } from '../analyticsRanges';
import LeadsTable from '../components/LeadsTable';
import LineChart from '../components/MUI/LineChart';
import StatCard from '../components/Overview/StatCard';

const standardSeriesProps = {
    type: 'line',
    area: true,
    curve: 'linear',
    showMark: false,
};

const OverviewView = () => {
    const [selectedMetric, setSelectedMetric] = useState<string>('views');
    const [leadBreakdown, setLeadBreakdown] = useState<Array<string>>([
        'raw',
        'pending',
        'accepted',
        'declined',
        'expired',
        'converted',
        'active',
    ]);
    const [filtersAnchorEl, setFiltersAnchorEl] = useState<HTMLButtonElement | null>(null);

    const [dateRange, setDateRange] = useState<DateRangeValue>({ start: moment().subtract(6, 'days').toDate(), end: moment().toDate() });
    const memoisedDateRange = useMemo(() => {
        return {
            start: moment(dateRange.start).startOf('d'),
            end: moment(dateRange.end).endOf('d'),
        };
    }, [dateRange]);

    const { groupedViews, viewPercentageChange } = usePortalViews(memoisedDateRange.start, memoisedDateRange.end);

    const { allLeads, rawLeads, pendingLeads, acceptedLeads, declinedLeads, expiredLeads, convertedLeads, activeLeads, cancelledLeads } =
        useLeadAnalytics({ start: memoisedDateRange.start.toDate(), end: memoisedDateRange.end.toDate() });

    const conversionRate = useMemo(() => {
        return ((convertedLeads.count + activeLeads.count) / allLeads.count) * 100;
    }, [convertedLeads.count, activeLeads.count, allLeads.count]);

    const lineChartConversionsData = convertedLeads.groupedLeads.map((group, index) => ({
        value: group.count + activeLeads.groupedLeads[index].count,
    }));

    const xAxisLabels = selectedMetric.includes('leads')
        ? allLeads.groupedLeads.map(group => group.time)
        : selectedMetric.includes('conversions')
        ? convertedLeads.groupedLeads.map(group => group.time)
        : groupedViews.map(group => group.time);

    const theme = useTheme();

    const clipRange: {
        from: string;
        to: string;
    } = useMemo(() => {
        const grouping = determineGrouping(memoisedDateRange.start, memoisedDateRange.end);
        const format = determineFormat(grouping);
        const from = moment().subtract(1, grouping);
        const to = moment().subtract(1, grouping);

        return {
            from: from.format(format),
            to: to.format(format),
        };
    }, [memoisedDateRange, selectedMetric]);
    return (
        <Stack spacing={1} pt={2} flex={1}>
            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="flex-end">
                <Typography variant="h6" sx={{ px: 1 }}>
                    Analytics
                </Typography>
                <DateRange
                    value={{ start: dateRange.start, end: dateRange.end }}
                    onChange={dateRange => setDateRange(dateRange)}
                    ranges={ranges}
                    defaultSelect
                    hidePickers
                />
            </Stack>

            <Paper sx={{ overflow: 'hidden' }} variant="outlined">
                <Stack direction="row" bgcolor={theme.palette.background.default} sx={{ overflowX: 'auto' }}>
                    <StatCard
                        label="Page Views"
                        value={groupedViews.reduce((acc, { count }) => acc + count, 0)}
                        icon={<VisibilityIcon sx={{ color: 'text.secondary' }} />}
                        percentageChange={viewPercentageChange}
                        button
                        onClick={() => setSelectedMetric('views')}
                        selected={selectedMetric.includes('views')}
                    />
                    <StatCard
                        label="Leads"
                        value={allLeads.count}
                        icon={<GroupsIcon sx={{ color: 'text.secondary' }} />}
                        percentageChange={allLeads.percentageChange}
                        button
                        onClick={() => setSelectedMetric('leads')}
                        selected={selectedMetric.includes('leads')}
                    />
                    <StatCard
                        label="Conversions"
                        value={convertedLeads.count + activeLeads.count}
                        icon={<ConvertedIcon sx={{ color: 'text.secondary' }} />}
                        percentageChange={calculatePercentageChange(
                            (convertedLeads.previousLeads?.length || 0) + (activeLeads.previousLeads?.length || 0),
                            convertedLeads.count + activeLeads.count
                        )}
                        button
                        onClick={() => setSelectedMetric('conversions')}
                        selected={selectedMetric.includes('conversions')}
                    />
                    <StatCard label="Conversion Rate" value={`${isNaN(conversionRate) ? '0.0' : conversionRate.toFixed(1)}%`} />
                </Stack>
                <Box sx={{ position: 'relative' }}>
                    <LineChart
                        margin={{ top: 30, bottom: 30, left: 40, right: 40 }}
                        clipRange={clipRange}
                        series={
                            [
                                {
                                    id: 'views',
                                    data: groupedViews.map(group => group.count),
                                    label: 'Page Views',
                                    color: theme.palette.info.dark,
                                    ...standardSeriesProps,
                                },
                                {
                                    id: 'leads-raw',
                                    data: rawLeads.groupedLeads.map(group => group.count),
                                    label: 'Raw Leads',
                                    color: theme.palette.info.dark,
                                    stack: 'leads',
                                    stackOrder: '',
                                    ...standardSeriesProps,
                                },
                                {
                                    id: 'leads-pending',
                                    data: pendingLeads.groupedLeads.map(group => group.count),
                                    label: 'Pending Leads',
                                    color: theme.palette.warning.main,
                                    stack: 'leads',
                                    stackOrder: 'reverse',
                                    ...standardSeriesProps,
                                },
                                {
                                    id: 'leads-accepted',
                                    data: acceptedLeads.groupedLeads.map(group => group.count),
                                    label: 'Accepted Leads',
                                    color: theme.palette.success.main,
                                    stack: 'leads',
                                    stackOrder: '',
                                    ...standardSeriesProps,
                                },
                                {
                                    id: 'leads-declined',
                                    data: declinedLeads.groupedLeads.map(group => group.count),
                                    label: 'Declined Leads',
                                    color: theme.palette.error.dark,
                                    stack: 'leads',
                                    stackOrder: 'reverse',
                                    ...standardSeriesProps,
                                },
                                {
                                    id: 'leads-expired',
                                    data: expiredLeads.groupedLeads.map(group => group.count),
                                    label: 'Expired Leads',
                                    color: theme.palette.grey[500],
                                    stack: 'leads',
                                    stackOrder: 'reverse',
                                    ...standardSeriesProps,
                                },
                                {
                                    id: 'leads-converted',
                                    data: convertedLeads.groupedLeads.map(group => group.count),
                                    label: 'Converted Leads',
                                    color: theme.palette.success.dark,
                                    stack: 'leads',
                                    stackOrder: 'reverse',
                                    ...standardSeriesProps,
                                },
                                {
                                    id: 'leads-active',
                                    data: activeLeads.groupedLeads.map(group => group.count),
                                    label: 'Active Leads',
                                    color: theme.palette.success.light,
                                    stack: 'leads',
                                    stackOrder: 'reverse',
                                    ...standardSeriesProps,
                                },

                                {
                                    id: 'conversions',
                                    data: lineChartConversionsData.map(({ value }) => value),
                                    label: 'Conversions',
                                    color: theme.palette.success.main,
                                    ...standardSeriesProps,
                                },
                            ].filter(({ id }) =>
                                selectedMetric === 'leads' ? leadBreakdown.includes(id.split('-')[1]) : id.includes(selectedMetric)
                            ) as Array<LineSeriesType>
                        }
                        xAxis={[{ scaleType: 'point', data: xAxisLabels }]}
                        height={400}
                        sx={analyticsPortalLineChartSx}
                    />

                    {selectedMetric === 'leads' && (
                        <IconButton
                            onClick={event => {
                                setFiltersAnchorEl(event.currentTarget);
                            }}
                            sx={{ position: 'absolute', top: 0, right: 0, margin: 0.5 }}
                        >
                            <FilterListIcon />
                        </IconButton>
                    )}
                    <Popover
                        open={Boolean(filtersAnchorEl)}
                        anchorEl={filtersAnchorEl}
                        onClose={() => setFiltersAnchorEl(null)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <List dense>
                            {['raw', 'pending', 'accepted', 'declined', 'expired', 'converted', 'active', 'cancelled'].map(lead => (
                                <ListItem key={lead} sx={{ py: 0 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={leadBreakdown.includes(lead)}
                                                onChange={() => {
                                                    setLeadBreakdown(prev =>
                                                        prev.includes(lead) ? prev.filter(item => item !== lead) : [...prev, lead]
                                                    );
                                                }}
                                            />
                                        }
                                        label={lead.charAt(0).toUpperCase() + lead.slice(1)}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Popover>
                </Box>
            </Paper>

            <Typography variant="h6" sx={{ px: 1, pt: 1 }}>
                All Leads
            </Typography>
            <Paper sx={{ flex: 1, overflowX: 'auto' }} variant="outlined">
                <LeadsTable
                    leads={{
                        allLeads,
                        rawLeads,
                        pendingLeads,
                        acceptedLeads,
                        declinedLeads,
                        expiredLeads,
                        convertedLeads,
                        activeLeads,
                        cancelledLeads,
                    }}
                />
            </Paper>
        </Stack>
    );
};

export default OverviewView;
