import { Breadcrumbs, Grid, Link } from '@mui/material';
import { PageContainer } from '@nexdynamic/nex-ui-react';
import type { EditorTemplate } from '@nexdynamic/send-editor/core';
import type { TemplateState } from '@nexdynamic/squeegee-common';
import { BlockTemplate, Template, isEditorTemplate } from '@nexdynamic/squeegee-common';
import { useNavigate } from 'react-router';
import { ApplicationState } from '../../../../../ApplicationState';
import Text from '../../../../components/Text';
import type { TemplateDetailsFormFields } from '../forms/TemplateDetailsForm';
import { TemplateFormSteps } from '../forms/TemplateFormSteps';
import type { TemplateSmsFormFields } from '../forms/TemplateSmsForm';
import { useCreateTemplate } from '../hooks/useCreateTemplate';

export const CreateTemplate = () => {
    const navigate = useNavigate();
    const { createTemplate } = useCreateTemplate();

    const onFinished = async (details: TemplateDetailsFormFields, sms: TemplateSmsFormFields, template: TemplateState | EditorTemplate) => {
        if (isEditorTemplate(template)) {
            const blockTemplate = BlockTemplate.fromEditorTemplate(template);
            blockTemplate.name = details.name;
            blockTemplate.subject = details.subject;
            blockTemplate.smsTemplate = sms.smsTemplate;
            blockTemplate.includeLink = sms.includeLink;
            blockTemplate.useSubjectAsSmsMessage = sms.useSubjectAsSmsMessage;
            const newTemplate = await createTemplate(blockTemplate);
            if (newTemplate) {
                ApplicationState.navigateTo('block-editor', {
                    id: newTemplate._id,
                });
            }
        } else {
            // TODO remove this legacy code once all clients have been updated
            const legacyTemplate = new Template();
            legacyTemplate.name = details.name;
            legacyTemplate.subject = details.subject;
            legacyTemplate.smsTemplate = sms.smsTemplate;
            legacyTemplate.includeLink = sms.includeLink;
            legacyTemplate.useSubjectAsSmsMessage = sms.useSubjectAsSmsMessage;
            legacyTemplate.state = template;
            await createTemplate(legacyTemplate);
            navigate('/send/templates');
        }
    };

    return (
        <PageContainer>
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link sx={{ cursor: 'pointer' }} component="button" underline="none" onClick={() => navigate('/send/templates')}>
                        <Text variant="subtitle1" text="templates.title" />
                    </Link>
                    <Text variant="subtitle1" color="text.primary" text="templates.createAction" />
                </Breadcrumbs>
            </Grid>

            <Grid item xs={12}>
                <TemplateFormSteps onFinished={onFinished} />
            </Grid>
        </PageContainer>
    );
};
