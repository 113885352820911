import InboxIcon from '@mui/icons-material/MoveToInbox';
import { Box, Breadcrumbs, Fade, Link } from '@mui/material';
import { MultiSelectProvider } from '@nexdynamic/nex-ui-react';
import { useNavigate } from 'react-router-dom';
import Text from '../../components/Text';
import MailListHeader from '../components/MailListHeader';
import { useInboxMessages } from '../hooks/useInboxMessages';
import MailListContainer from './MailListContainer';

export const InboxView = () => {
    const navigate = useNavigate();
    const { notifications, loadMore, hasMore, loading } = useInboxMessages({
        filters: {
            inbound: true,
            outbound: false,
            scheduled: false,
        },
    });

    return (
        <Fade in={true}>
            <Box>
                <MailListHeader>
                    <Breadcrumbs>
                        <Link underline='none' component='button' sx={{ cursor: 'pointer' }} onClick={() => navigate('/inbox')}>
                            <Text variant='subtitle1' text='inbox.inbox-title' />
                        </Link>
                        <Text color='text.primary' variant='subtitle1' text='inbox.received' />
                    </Breadcrumbs>
                </MailListHeader>
                <MultiSelectProvider disableSelect={true}>
                    <MailListContainer
                        loading={loading}
                        hasMore={hasMore}
                        emptyText='inbox.inbox-empty'
                        EmptyIcon={InboxIcon}
                        notifications={notifications}
                        loadMore={loadMore}
                    />
                </MultiSelectProvider>
            </Box>
        </Fade>
    );
};

export default InboxView;
