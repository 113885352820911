import type { SelectChangeEvent } from '@mui/material';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { useFranchiseeAccounts } from './useFranchiseeAccounts';

export const SelectFranchiseSibling = ({
    handleSelectChange,
    formData,
}: {
    handleSelectChange: (e: SelectChangeEvent) => void;
    formData: {
        accountEmail: string;
        complianceAgreement: boolean;
    };
}) => {
    const accounts = useFranchiseeAccounts();

    return (
        <Select onChange={handleSelectChange} value={formData.accountEmail} disabled={!accounts || !accounts.length}>
            {accounts?.map(x => (
                <MenuItem key={x.email} value={x.email}>
                    <Box>
                        <Typography>{x.businessName}</Typography>
                        <Typography variant="caption">{x.email}</Typography>
                    </Box>
                </MenuItem>
            ))}
        </Select>
    );
};
