import { Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { PageContainer } from '@nexdynamic/nex-ui-react';
import type { Campaign } from '@nexdynamic/squeegee-common';
import { useNavigate, useParams } from 'react-router';
import Text from '../../../../components/Text';
import useStoredObject from '../../../../hooks/useStoredObject';
import CampaignForm from '../components/forms/CampaignForm';
import type { CampaignCreateFields } from '../hooks/useCampaignCreate';
import useCampaignUpdate from '../hooks/useCampaignUpdate';

const EditCampaign = () => {
    const navigate = useNavigate();
    const { id = '' } = useParams();
    const campaign = useStoredObject<Campaign>(id);
    const { campaignUpdate } = useCampaignUpdate(id);

    const onSubmit = async (fields: CampaignCreateFields) => {
        await campaignUpdate(fields);
        navigate(-1);
    };

    const View = () => {
        if (campaign)
            return (
                <CampaignForm
                    fields={{
                        name: campaign.name,
                        description: campaign.description,
                        audienceIds: campaign.audiences,
                        selectedDeliveryMethods: campaign.deliveryMethods || [],
                        templateId: campaign.templateId,
                    }}
                    onSubmit={onSubmit}
                />
            );
        return (
            <Box>
                <Text text='campaigns.campaign-not-found' />
            </Box>
        );
    };

    return (
        <PageContainer>
            <Grid item xs={12}>
                <Breadcrumbs aria-label='breadcrumb'>
                    <Link component='button' underline='none' sx={{ cursor: 'pointer' }} onClick={() => navigate('/send/campaigns')}>
                        <Text variant='subtitle1' text='campaigns.title' />
                    </Link>
                    <Link sx={{ cursor: 'pointer' }} underline='none' onClick={() => navigate(`/send/campaigns/${campaign?._id}`)}>
                        <Typography variant='subtitle1'>{campaign?.name}</Typography>
                    </Link>
                    <Text variant='subtitle1' color='text.primary' text='general.edit' />
                </Breadcrumbs>
            </Grid>

            <Grid item xs={12}>
                <View />
            </Grid>
        </PageContainer>
    );
};

export default EditCampaign;
