import { Box, Button, Paper, Toolbar, Typography } from '@mui/material';
import { useState } from 'react';
import type { Layouts } from 'react-grid-layout';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { RevenueMonthData, RevenueYearData, WorkerRevenueData } from '../data/phdata';
import AddList from './AddList';
import BleedingLineChart from './charts/BleedingLineChart';
import ComparisonChart from './charts/ComparisonChart';
import LineChart from './charts/LineChart';
import type { Widget } from './types';
const ResponsiveGridLayout = WidthProvider(Responsive);

const Content = () => {
    window.dispatchEvent(new Event('resize'));
    const [state, setState] = useState(true);
    const [editMode, setEditMode] = useState(false);

    const originalItems = (onRemove: (id: string) => void): Widget[] => {
        return [
            {
                id: 'a',
                layout: { x: 0, y: 0, w: 4, h: 3, minW: 4, minH: 3 },
                children: (
                    <LineChart
                        onRemove={() => onRemove('a')}
                        sx={{ background: 'linear-gradient(270deg, #1e88e5, #68afec)' }}
                        setState={setState}
                        state={state}
                        data={RevenueMonthData}
                    />
                ),
            },
            {
                id: 'b',
                layout: { x: 6, y: 0, w: 4, h: 3, minW: 2, minH: 2 },

                children: (
                    <BleedingLineChart
                        onRemove={() => onRemove('b')}
                        sx={{ background: '#eaf5ff' }}
                        data={RevenueYearData}
                        strokeColor='#1e88e5'
                        title='Revenue'
                        subheading='This year'
                        currentValue={RevenueYearData.total}
                    ></BleedingLineChart>
                ),
            },
            {
                id: 'c',
                layout: { x: 0, y: 0, w: 4, h: 4, minW: 4, minH: 1 },

                children: (
                    <ComparisonChart
                        sx={{
                            background: '#fff',
                        }}
                        onRemove={() => onRemove('c')}
                        strokeColor='#333'
                        title='Workers'
                        subheading='March Revenue'
                        currentValue={WorkerRevenueData.monthTotal}
                        data={WorkerRevenueData}
                    />
                ),
            },
            {
                id: 'd',
                layout: { x: 0, y: 0, w: 4, h: 4, minW: 4, minH: 1 },
                children: (
                    <Paper>
                        <Box sx={{ p: 4 }}>
                            <Typography variant='body2'>Item D</Typography>
                        </Box>
                    </Paper>
                ),
            },
        ];
    };

    interface LocalStorageObject {
        [key: string]: any;
    }

    function getFromLS(key: string): any {
        let ls: LocalStorageObject = {};
        if (typeof window !== 'undefined' && window.localStorage) {
            try {
                ls = JSON.parse(window.localStorage.getItem('rgl-8') || '{}');
            } catch (e) {
                console.error('Error parsing local storage: ', e);
            }
        }
        return ls[key];
    }

    function saveToLS(key: string, value: any): void {
        if (typeof window !== 'undefined' && window.localStorage) {
            window.localStorage.setItem(
                'rgl-8',
                JSON.stringify({
                    [key]: value,
                })
            );
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 100);
        }
    }

    function Content() {
        const onRemoveItem = (itemId: string) => {
            setItems(items.filter(item => item.id !== itemId));
        };

        const [items, setItems] = useState(originalItems(onRemoveItem));
        const [layouts, setLayouts] = useState(getFromLS('layouts'));

        const onLayoutChange = (_: any, allLayouts: Layouts) => {
            setLayouts(allLayouts);
            window.dispatchEvent(new Event('resize'));
        };

        const onEditMode = () => {
            setEditMode(true);
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 100);
        };

        const onSave = () => {
            saveToLS('layouts', layouts);
            setEditMode(false);
        };
        const onAddItem = (item: Widget) => {
            setItems([...items, item]);
        };

        return (
            <Box>
                <Toolbar>
                    <Box flexGrow='1'>
                        <AddList items={items} onRemoveItem={onRemoveItem} onAddItem={onAddItem} />
                        {editMode === false ? (
                            <Button onClick={onEditMode}>Edit</Button>
                        ) : (
                            <Button onClick={onSave}>Save</Button>
                        )}
                    </Box>
                </Toolbar>
                <ResponsiveGridLayout
                    style={{ position: 'relative' }}
                    isResizable={editMode}
                    isDraggable={editMode}
                    useCSSTransforms={false}
                    className='layout'
                    layouts={layouts}
                    breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                    cols={{ lg: 16, md: 10, sm: 6, xs: 4, xxs: 2 }}
                    rowHeight={60}
                    onLayoutChange={onLayoutChange}
                >
                    {items.map(i => (
                        <div key={i.id} data-grid={i.layout}>
                            {i.children}
                        </div>
                    ))}
                </ResponsiveGridLayout>
            </Box>
        );
    }
    return <Content />;
};

export default Content;
