import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import type { AudienceFilterModel, AudienceFilterOperator } from '@nexdynamic/squeegee-common';

type Props = {
    filter?: AudienceFilterModel;
    value?: string;
    onChange: (operator: AudienceFilterOperator) => void;
    disabled?: boolean;
    label?: string;
};

const FilterOperator = ({ filter, value, onChange, disabled, label }: Props) => {
    const filterOperators = filter?.operators || [];

    return (
        <FormControl size='small' required disabled={!filter || disabled} fullWidth>
            {label && <InputLabel id='filter-operator-select-label'>{label}</InputLabel>}
            <Select
                disabled={disabled}
                labelId='filter-operator-select-label'
                id='filter-operator-select'
                value={value}
                label={label}
                onChange={event => onChange(event.target.value as AudienceFilterOperator)}
            >
                {filterOperators.map(operator => (
                    <MenuItem key={operator} value={operator}>
                        {operator}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default FilterOperator;
