import { Box, FormHelperText } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { BlockTemplate, Template, TranslationKey } from '@nexdynamic/squeegee-common';
import { ErrorMessage, useFormikContext } from 'formik';
import React from 'react';
import useTranslation from '../../../../hooks/useTranslation';
import type { CampaignCreateFields } from '../../campaigns/hooks/useCampaignCreate';
import useTemplates from '../hooks/useTemplates';
import TemplateGrid from './TemplateGrid';
import TemplateCard from './TemplateItem';

type Props = {
    label?: TranslationKey;
    fieldName: string;
};

const TemplateSelect = ({ fieldName, label }: Props) => {
    const { setFieldValue, setFieldTouched, initialValues } = useFormikContext<CampaignCreateFields>();

    const templates = useTemplates();

    const [open, setOpen] = React.useState(false);
    const [template, setTemplate] = React.useState<Template | BlockTemplate | undefined>(
        templates.find(t => t._id === initialValues[fieldName as keyof CampaignCreateFields])
    );
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFieldTouched(fieldName, true);
    };

    const onSelect = (template: Template) => {
        setTemplate(template);
        handleClose();
        setFieldValue(fieldName, template._id);
    };

    return (
        <>
            <Box>
                <Button data-testid="ChooseTemplateButton" sx={{ mb: 2 }} variant="outlined" onClick={handleClickOpen}>
                    {label ? t(label) : t('templates.choose')}
                </Button>

                {template && (
                    <Box sx={{ bgcolor: 'background.default', p: 2 }}>
                        <TemplateCard hideActions={true} template={template} />
                    </Box>
                )}

                <FormHelperText error={true}>
                    <ErrorMessage name={fieldName} />
                </FormHelperText>
            </Box>

            <Dialog
                PaperProps={{ elevation: 0 }}
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="template-select-dialog-title"
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogTitle id="template-select-dialog-title">{t('templates.dialog.selectTitle')}</DialogTitle>
                <DialogContent data-testid="ChooseTemplateDialog">
                    <Box sx={{ bgcolor: 'background.default', p: 2 }}>
                        <TemplateGrid hideActions={true} onSelect={onSelect} templates={templates} />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TemplateSelect;
