import type { Palette, PaletteOptions, SimplePaletteColorOptions } from '@mui/material';
import { Box, ListItemButton, ListItemText, Tooltip } from '@mui/material';
import { useState } from 'react';
import ColorPicker from './ColorPicker';

type Props = {
    textPrimary: string;
    textSecondary: string;
    palette?: Palette | PaletteOptions;
    updatePalette: (color: string, key: string) => void;
    colorKey: string;
};

const ColorPickerListItem = ({ textPrimary, textSecondary, palette, updatePalette, colorKey }: Props) => {
    const [anchorPos, setAnchorPos] = useState<{ top: number; left: number }>();

    const onCloseColorPicker = (e: MouseEvent) => {
        e.stopPropagation();
        setAnchorPos(undefined);
    };

    const indexedPalette = palette as unknown as { [key: string]: SimplePaletteColorOptions };

    return (
        <ListItemButton onClick={e => setAnchorPos({ top: e.clientY, left: e.clientX })} disableRipple disableTouchRipple>
            <ListItemText primary={textPrimary} secondary={textSecondary} />
            <Box sx={{ display: 'flex', gap: 1, ml: 2 }}>
                <Tooltip title="Light">
                    <Box sx={{ width: 24, height: 24, borderRadius: 4, backgroundColor: indexedPalette[colorKey]?.light }} />
                </Tooltip>
                <Tooltip title="Main">
                    <Box sx={{ width: 24, height: 24, borderRadius: 4, backgroundColor: indexedPalette[colorKey]?.main }} />
                </Tooltip>
                <Tooltip title="Dark">
                    <Box sx={{ width: 24, height: 24, borderRadius: 4, backgroundColor: indexedPalette[colorKey]?.dark }} />
                </Tooltip>
            </Box>
            <ColorPicker
                colorKey={colorKey}
                defaultColor={indexedPalette[colorKey]?.main}
                anchorPos={anchorPos}
                updatePalette={updatePalette}
                onCloseColorPicker={onCloseColorPicker}
            />
        </ListItemButton>
    );
};

export default ColorPickerListItem;
