import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import type React from 'react';
import { useState } from 'react';
import type { Widget } from '../squeegee-viz/types';

type Props = {
    items: Widget[];
    onRemoveItem: (id: string) => void;
    onAddItem: (item: Widget) => void;
};

const widgetNames: { [key: string]: string } = {
    a: 'A',
    b: 'B',
    c: 'C',
    d: 'D',
};

const AddList: React.FC<Props> = ({ items, onRemoveItem, onAddItem }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            onAddItem({
                id: e.target.name,
                children: null,
                layout: { x: 0, y: 0, w: 0, h: 0, minW: 0, maxW: 0 },
            });
        } else {
            onRemoveItem(e.target.name);
        }
    };

    return (
        <>
            <IconButton aria-label='add' onClick={handleClick} aria-describedby={id}>
                <AddCircleOutlineIcon />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box>
                    <FormControl component='fieldset'>
                        <FormLabel component='legend'>Select Widgets</FormLabel>
                        <FormGroup>
                            {items.map(widget => (
                                <FormControlLabel
                                    control={<Checkbox checked={items.includes(widget)} onChange={handleChange} name={widget.id} />}
                                    label={widgetNames[widget.id]}
                                    key={widget.id}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                </Box>
            </Popover>
        </>
    );
};

export default AddList;
