import EmptyIcon from '@mui/icons-material/DoorBackOutlined';
import { Box, Typography } from '@mui/material';
export const EmptyAdvertList = () => {
    return (
        <Box
            sx={{
                p: 2,
                border: 1,
                borderRadius: 1,
                borderColor: 'divider',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                flexDirection: 'column',
            }}
        >
            <Box sx={{ mb: 1 }}>
                <EmptyIcon />
            </Box>
            <Typography variant='subtitle1'>There are no adverts to display</Typography>
        </Box>
    );
};
