import { Box, Input, Slider, Typography } from '@mui/material';
import type { ChangeEvent } from 'react';

export const SliderDistance = ({ value, changed }: { value: number; changed: (value: number) => void }) => {
    const handleValueChange = (_e: Event, newValue: number | number[]) => {
        const newNumberValue = newValue as number;
        if (newNumberValue === value) return;
        changed(newNumberValue);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value === '' ? 0 : Number(e.target.value);
        if (newValue === value) return;
        changed(newValue);
    };

    return (
        <Box sx={{ width: 300 }}>
            <Typography id='distance-slider' gutterBottom>
                Maximum allowed distance between recorded locations (meters)
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
                <Slider
                    value={typeof value === 'number' ? value : 0}
                    onChange={handleValueChange}
                    marks={true}
                    step={50}
                    min={0}
                    max={500}
                />

                <Input type='number' value={value} margin='dense' onChange={handleInputChange} />
            </Box>
        </Box>
    );
};
