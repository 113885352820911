import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import type { IAdvertisement, IAdvertisementListing } from '@nexdynamic/squeegee-common';
import type React from 'react';
import type { FilterAdvertsQuery } from '../views/Adverts';

type PopoverProps = {
    allData: Array<IAdvertisement | IAdvertisementListing>;
    requestSearch: (query: FilterAdvertsQuery) => void;
    cancelSearch: any;
    selectedSellers: any;
    selectedTags: any;
    setSelectedSellers: any;
    setSelectedTags: any;
};

const PopoverContent = ({ allData, selectedSellers, selectedTags, setSelectedSellers, setSelectedTags, requestSearch }: PopoverProps) => {
    const sellers = Array.from(new Set(allData.map(data => data.sellerName))).map(sellerName => ({ sellerName: sellerName }));

    const extractTags = () => {
        const tags: Array<string> = [];
        for (const rec of allData) {
            for (const tag of rec.tags) {
                if (tags.includes(tag)) continue;
                tags.push(tag);
            }
        }
        return tags;
    };

    const tags = extractTags();

    const handleSellerSelect = (seller: string) => {
        selectedSellers.push(seller);
        setSelectedSellers(selectedSellers);
    };

    const handleSellerUnselect = (seller: string) => {
        const idx = selectedSellers.indexOf(seller);
        selectedSellers.splice(idx, 1);
    };

    const isSellerChecked = (seller: string) => {
        return selectedSellers.indexOf(seller) > -1;
    };

    const handleSellerCheckChange = (event: React.ChangeEvent<HTMLInputElement>, sellerName: string) => {
        if (event.target.checked) handleSellerSelect(sellerName);
        else handleSellerUnselect(sellerName);
        requestSearch({ selectedSellers, tags: selectedTags });
    };

    const handleTagSelect = (tag: string) => {
        selectedTags.push(tag);
        setSelectedTags(selectedTags);
    };

    const handleTagUnselect = (tag: string) => {
        const idx = selectedTags.indexOf(tag);
        selectedTags.splice(idx, 1);
    };

    const isTagChecked = (tag: string) => {
        return selectedTags.indexOf(tag) > -1;
    };

    const handleTagCheckChange = (event: React.ChangeEvent<HTMLInputElement>, tag: string) => {
        if (event.target.checked) handleTagSelect(tag);
        else handleTagUnselect(tag);
        requestSearch({ tags: selectedTags, selectedSellers });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: 1, background: '#f9f9f9' }}>
            <FormControl component='fieldset'>
                <FormLabel component='legend'>Providers</FormLabel>
                <FormGroup>
                    {sellers.map(({ sellerName }) => {
                        return (
                            <FormControlLabel
                                key={sellerName}
                                id={sellerName}
                                label={sellerName}
                                control={
                                    <Checkbox
                                        checked={isSellerChecked(sellerName)}
                                        onChange={e => handleSellerCheckChange(e, sellerName)}
                                    />
                                }
                            />
                        );
                    })}
                </FormGroup>
                <FormLabel component='legend'>Tags</FormLabel>
                {tags.map(tag => {
                    return (
                        <FormControlLabel
                            key={tag}
                            label={tag}
                            control={<Checkbox checked={isTagChecked(tag)} onChange={e => handleTagCheckChange(e, tag)} />}
                        />
                    );
                })}
            </FormControl>
        </Box>
    );
};

export default PopoverContent;
