import type { LimitBreakingAccount, Tracker } from '@nexdynamic/squeegee-common';
import { getTrackersOutsideOfLimits } from '@nexdynamic/squeegee-common';
import { Data } from '../../../Data/Data';

export const getLimitBreakers = (maxDistanceMetres: number, maxTimeDifferenceMinutes: number): Array<LimitBreakingAccount> => {
    return Data.all<Tracker>('tracker')
        .map(tracker =>
            getTrackersOutsideOfLimits({ tracker, maxDistanceMetres, maxTimeDifferenceMs: maxTimeDifferenceMinutes * 60 * 1000 })
        )
        .filter(limitBreaker => limitBreaker.limitBreaks.length);
};
