import MoreVert from '@mui/icons-material/MoreVert';
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { GradientButton } from '@nexdynamic/nex-ui-react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import useCampaignActions from '../hooks/useCampaignActions';

type Props = {
    campaignId: string;
};

const CampaignActions = ({ campaignId }: Props) => {
    const actions = useCampaignActions(campaignId);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleAction = (action: () => void) => {
        action();
        setAnchorEl(null);
    };

    const disableActions = Boolean(actions.running);

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
            {!actions.publish.disabled && (
                <Box sx={{ mr: 2 }}>
                    <GradientButton text={actions.publish.name} onClick={actions.publish.run} color='primary'></GradientButton>
                </Box>
            )}

            <Box>
                <IconButton
                    aria-controls='campaign-action-menu'
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={event => setAnchorEl(event.currentTarget)}
                    sx={{ border: 1, borderColor: 'divider' }}
                    disabled={disableActions}
                >
                    <MoreVert />
                </IconButton>
            </Box>

            <Menu
                id='campaign-action-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {!actions.edit.disabled ? (
                    <MenuItem onClick={() => handleAction(actions.edit.run)}>
                        {actions.edit.icon && <ListItemIcon>{actions.edit.icon}</ListItemIcon>}
                        <ListItemText>
                            <>{actions.edit.name}</>
                        </ListItemText>
                    </MenuItem>
                ) : null}
                <MenuItem
                    onClick={async () => {
                        const { success } = await actions.remove.run();
                        if (success) navigate('/send/campaigns');
                    }}
                >
                    {actions.remove.icon && <ListItemIcon>{actions.remove.icon}</ListItemIcon>}
                    <ListItemText>
                        <>{actions.remove.name}</>
                    </ListItemText>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default CampaignActions;
