import {
    Button,
    Chip,
    FormControl,
    Input,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { to2dp, type AccountUser } from '@nexdynamic/squeegee-common';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { ApplicationState } from '../../../ApplicationState';
import { Data } from '../../../Data/Data';
import { WeekNavigator } from '../../components/WeekNavigator';
import type { ColumnConfig } from '../../components/charts/StackedBarChart';
import StackedBarChart from '../../components/charts/StackedBarChart';
import getColourForText from '../../pricing/utils/getColourForText';
import { useWorkersRaw } from '../../time-tracker/hooks/useWorkers';
import { useSkillsFulfillmentWeekStats } from '../hooks/useSkillsFulfillmentWeekStats';
import { useValueFulfillmentWeekStats } from '../hooks/useValueFulfillmentWeekStats';
export const WorkerAvailabilityGrid = () => {
    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
    const [showServices, setShowServices] = useState(false);
    const [showRounds, setShowRounds] = useState(false);

    const startOfWeek = moment(selectedDate.toDate()).startOf('isoWeek').toDate();

    const days = [0, 1, 2, 3, 4, 5, 6].map(day => dayjs(startOfWeek).add(day, 'day').format('YYYY-MM-DD'));

    const [workers, setWorkers] = useState<AccountUser[]>(useWorkersRaw());

    const workerSkills = workers.reduce((acc: Record<string, string[]>, curr) => {
        acc[curr._id] = curr.skills || [];
        return acc;
    }, {});

    const availability = useMemo(() => {
        return workers.reduce((acc: Record<string, number>, curr) => {
            for (const day of days) {
                const dayOfWeek = dayjs(day).format('dddd') as keyof AccountUser['defaultAvailability'];
                acc[curr._id + day] =
                    curr.availability?.[day] === undefined ? curr.defaultAvailability?.[dayOfWeek] || 0 : curr.availability[day];
            }
            return acc;
        }, {});
    }, [workers, days]);

    const { statsByDay, skills, rounds, services, barData, barDataLabels } = useSkillsFulfillmentWeekStats(
        selectedDate,
        availability,
        workerSkills
    );

    const workerIds = workers.map(w => w._id);
    const { barData: barValueData, statsByDay: valueStatsByDay } = useValueFulfillmentWeekStats(selectedDate, workerIds);

    const saveAvailability = (workerId: string, day: string, value: number) => {
        const worker = Data.get<AccountUser>(workerId);
        if (!worker) return;
        worker.availability = worker.availability || {};
        worker.availability[day] = value;
        Data.put(worker);
        setWorkers(workers.map(w => (w._id === workerId ? worker : w)));
    };

    function getBgColourForCell(value: number, limit: number, isSelected: boolean) {
        if (!limit) return isSelected ? '#EEE' : 'default';

        if (value >= limit) {
            return isSelected ? '#C8E6C9' : '#E8F5E9';
        }

        return isSelected ? '#FFCDD2' : '#FFEBEE';
    }

    const Cell = ({ value, limit, isSelected }: { value: number; limit: number; isSelected: boolean }) => {
        return (
            <TableCell
                sx={{
                    bgcolor: getBgColourForCell(value, limit, isSelected),
                    textAlign: 'center',
                    padding: 1,
                }}
                padding="none"
            >
                <Box>
                    {to2dp(value)}
                    {limit ? ' / ' + to2dp(limit) : ''}
                </Box>
            </TableCell>
        );
    };

    const columnConfig: ColumnConfig = barDataLabels.reduce((acc: ColumnConfig, label) => {
        acc[label] = { label, color: getColourForText(label, label.endsWith('unfufilled')) };
        return acc;
    }, {});

    const dataTypeIsSkills = !ApplicationState.getSetting('global.availability-bar-type', false);
    return (
        <TableContainer component={Paper} sx={{ height: 'calc(100vh - 100px)', p: 1 }}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell width="240px" padding="none"></TableCell>
                        <TableCell colSpan={7} padding="none">
                            <Box height="240px" width="100%" marginBottom={2}>
                                <WeekNavigator selectedDate={selectedDate} setSelectedDate={setSelectedDate} />

                                <ParentSize>
                                    {({ width, height }) => (
                                        <StackedBarChart
                                            selectedDate={selectedDate}
                                            setSelectedDate={setSelectedDate}
                                            columnConfig={{
                                                ...columnConfig,
                                            }}
                                            data={dataTypeIsSkills ? barData : barValueData}
                                            height={height}
                                            width={width}
                                            hideYScale={true}
                                            defaultWhiteSpace={12}
                                        />
                                    )}
                                </ParentSize>
                            </Box>
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {services && services.length > 0 && (
                        <TableRow>
                            <TableCell width="240px" padding="none">
                                <Box pb={1} pt={2}>
                                    <Typography variant={'body2'} sx={{ fontWeight: 'bold' }}>
                                        Service Breakdown{' '}
                                        <Button onClick={() => setShowServices(!showServices)}>
                                            {showServices ? 'Collapse' : 'Expand'}
                                        </Button>
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                    )}

                    {showServices && services && services.length > 0
                        ? dataTypeIsSkills
                            ? services.map(service => (
                                  <TableRow key={service}>
                                      <TableCell key={service} width={240} padding="none">
                                          {service}
                                      </TableCell>
                                      {days.map(day => (
                                          <Cell
                                              key={day + service}
                                              value={to2dp(statsByDay[day].serviceSummaries[service]?.duration)}
                                              limit={0}
                                              isSelected={selectedDate.isSame(day, 'day')}
                                          />
                                      ))}
                                  </TableRow>
                              ))
                            : services.map(service => (
                                  <TableRow key={service}>
                                      <TableCell key={service} width={240} padding="none">
                                          {service}
                                      </TableCell>
                                      {days.map(day => (
                                          <Cell
                                              key={day + service}
                                              value={to2dp(valueStatsByDay[day].serviceSummaries[service])}
                                              limit={0}
                                              isSelected={selectedDate.isSame(day, 'day')}
                                          />
                                      ))}
                                  </TableRow>
                              ))
                        : null}
                    {rounds && rounds.length > 0 && (
                        <TableRow>
                            <TableCell width="240px" padding="none">
                                <Box pb={1} pt={2}>
                                    <Typography variant={'body2'} sx={{ fontWeight: 'bold' }}>
                                        Round Breakdown{' '}
                                        <Button onClick={() => setShowRounds(!showRounds)}>{showRounds ? 'Collapse' : 'Expand'}</Button>
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                    )}
                    {showRounds && rounds && rounds.length > 0
                        ? dataTypeIsSkills
                            ? rounds.map(round => (
                                  <TableRow key={round}>
                                      <TableCell key={round} width={240} padding="none">
                                          {round}
                                      </TableCell>
                                      {days.map(day => (
                                          <Cell
                                              key={day + round}
                                              value={to2dp(statsByDay[day].roundSummaries[round]?.duration)}
                                              limit={0}
                                              isSelected={selectedDate.isSame(day, 'day')}
                                          />
                                      ))}
                                  </TableRow>
                              ))
                            : rounds.map(round => (
                                  <TableRow key={round}>
                                      <TableCell key={round} width={240} padding="none">
                                          {round}
                                      </TableCell>
                                      {days.map(day => (
                                          <Cell
                                              key={day + round}
                                              value={to2dp(valueStatsByDay[day].roundSummaries[round])}
                                              limit={0}
                                              isSelected={selectedDate.isSame(day, 'day')}
                                          />
                                      ))}
                                  </TableRow>
                              ))
                        : null}
                    <TableRow>
                        <TableCell width="240px" padding="none">
                            <Box pb={1} pt={2}>
                                <Typography variant={'body2'} sx={{ fontWeight: 'bold' }}>
                                    {dataTypeIsSkills ? `Available / Booked Breakdown` : `Value Fulfilled / Unfulfilled`}
                                </Typography>
                            </Box>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width="240px" padding="none">
                            {dataTypeIsSkills ? `Total Available / Booked Job Hrs` : `Total Value Fulfilled / Unfulfilled`}
                        </TableCell>
                        {days.map(day =>
                            dataTypeIsSkills ? (
                                <Cell
                                    key={day + 'total'}
                                    value={statsByDay[day].totals.availableTotal}
                                    limit={statsByDay[day].totals.required}
                                    isSelected={selectedDate.isSame(day, 'day')}
                                />
                            ) : (
                                <Cell
                                    key={day + 'total'}
                                    value={valueStatsByDay[day].fufilledValue}
                                    limit={valueStatsByDay[day].totalValue}
                                    isSelected={selectedDate.isSame(day, 'day')}
                                />
                            )
                        )}
                    </TableRow>

                    {skills.map(skill => (
                        <TableRow key={skill}>
                            <TableCell key={skill} width={240} padding="none">
                                <Chip
                                    sx={{
                                        bgcolor: getColourForText(skill),
                                        color: 'white',
                                        fontWeight: 'bold',
                                    }}
                                    size="small"
                                    key={skill}
                                    label={skill}
                                />
                            </TableCell>
                            {Object.keys(statsByDay).map(date => (
                                <Cell
                                    key={date + skill}
                                    value={statsByDay[date].skillSummaries[skill]?.availableWorkerHours}
                                    limit={statsByDay[date].skillSummaries[skill]?.duration}
                                    isSelected={selectedDate.isSame(date, 'day')}
                                />
                            ))}
                        </TableRow>
                    ))}

                    <TableRow>
                        <TableCell width="240px" padding="none">
                            <Box pb={1} pt={2}>
                                <Typography variant={'body2'} sx={{ fontWeight: 'bold' }}>
                                    Worker Availability / Assigned Hours
                                </Typography>
                            </Box>
                        </TableCell>
                    </TableRow>

                    {workers.map(worker => (
                        <TableRow key={worker._id}>
                            <TableCell width="240px" padding="none">
                                <Box pb={1} pt={1}>
                                    <Box mb={1}>
                                        <Typography variant={'body2'}>{worker.name}</Typography>
                                    </Box>

                                    {dataTypeIsSkills ? (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '2px' }}>
                                            {worker.skills?.map(skill => (
                                                <Chip
                                                    sx={{
                                                        bgcolor: getColourForText(skill),
                                                        color: 'white',
                                                        fontWeight: 'bold',
                                                        margin: { left: '2px' },
                                                    }}
                                                    size="small"
                                                    key={skill}
                                                    label={skill}
                                                />
                                            ))}
                                        </Box>
                                    ) : (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '2px' }}>
                                            <Chip
                                                sx={{
                                                    bgcolor: getColourForText('Total'),
                                                    color: 'white',
                                                    fontWeight: 'bold',
                                                    margin: { left: '2px' },
                                                }}
                                                size="small"
                                                key={worker._id}
                                                label={`${
                                                    valueStatsByDay[selectedDate.format('YYYY-MM-DD')].valuePerWorkerId[worker._id] || 0
                                                } / ${valueStatsByDay[selectedDate.format('YYYY-MM-DD')].totalValue}`}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            </TableCell>

                            {days.map(day => (
                                <TableCell
                                    key={worker._id + day}
                                    sx={{
                                        bgcolor: selectedDate.isSame(day, 'day') ? '#FFF' : 'default',
                                        textAlign: 'center',
                                        paddingLeft: 1,
                                        paddingRight: 1,
                                    }}
                                    padding="none"
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <FormControl>
                                            <Input
                                                type="text"
                                                defaultValue={availability[worker._id + day] || 0}
                                                onFocus={e => {
                                                    e.target.select();
                                                    setSelectedDate(dayjs(day));
                                                }}
                                                onChange={e => {
                                                    const value = e.target.value;
                                                    const parsed = Number(value);

                                                    if (!isNaN(parsed)) {
                                                        availability[worker._id + day] = parsed;
                                                        saveAvailability(worker._id, day, parsed);
                                                    }
                                                }}
                                                onBlur={e => {
                                                    e.target.value = availability[worker._id + day].toString();
                                                }}
                                            />
                                        </FormControl>
                                        {statsByDay[day].assignedHoursPerWorker?.[worker._id] && dataTypeIsSkills && (
                                            <Box sx={{ display: 'flex' }}>
                                                <Typography> / </Typography>
                                                <Typography>{statsByDay[day].assignedHoursPerWorker?.[worker._id]?.toString()}</Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
