// Define the type for your hook's return value

import type { DateRangeValue } from '@nexdynamic/nex-ui-react';
import type { Customer } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { determineGrouping } from '../portalAnalyticsUtils';
import type { Group } from '../usePortalAnalytics';
import { useAcceptedLeads } from './useAcceptedLeads';
import { useActiveLeads } from './useActiveLeads';
import { useCancelledLeads } from './useCancelledLeads';
import { useConvertedLeads } from './useConvertedLeads';
import { useDeclinedLeads } from './useDeclinedLeads';
import { useExpiredLeads } from './useExpiredLeads';
import { usePendingLeads } from './usePendingLeads';
import { usePortalLeads } from './usePortalLeads';
import { useRawLeads } from './useRawLeads';

export type LeadAnalyticsData = {
    leads: Array<Customer>;
    groupedLeads: Array<Group>;
    percentageChange: number;
    timestamps: Array<number>;
    count: number;
    previousLeads?: Array<Customer>;
};

export type AllLeads = LeadAnalyticsData & {
    previousLeads: Array<Customer>;
};

export type Leads = {
    allLeads: AllLeads;
    rawLeads: LeadAnalyticsData; // Registered on the portal
    pendingLeads: LeadAnalyticsData; // Created a quote that has not expired or been accepted / declined
    acceptedLeads: LeadAnalyticsData; // Accepted a quote
    declinedLeads: LeadAnalyticsData; // Declined a quote
    expiredLeads: LeadAnalyticsData; // Created a quote but did not accept or decline before expiration
    convertedLeads: LeadAnalyticsData; // Created a quote and accepted it and converted to a job
    activeLeads: LeadAnalyticsData; // Created a quote and accepted it, converted to a job and the job has been completed at least once and the job is active / adhoc
    cancelledLeads: LeadAnalyticsData; // Created a quote and accepted it, converted to a job and the job has been cancelled
    // abandonedLeads: LeadAnalyticsData; // Started the quote process but did not complete it
};

const useLeadAnalytics = (dateRange: DateRangeValue): Leads => {
    const grouping = determineGrouping(moment(dateRange.start), moment(dateRange.end));

    const allLeads = usePortalLeads(dateRange, grouping);
    const rawLeads = useRawLeads(allLeads, grouping, dateRange);
    const pendingLeads = usePendingLeads(allLeads, grouping, dateRange);
    const acceptedLeads = useAcceptedLeads(allLeads, grouping, dateRange);
    const declinedLeads = useDeclinedLeads(allLeads, grouping, dateRange);
    const expiredLeads = useExpiredLeads(allLeads, grouping, dateRange);
    const convertedLeads = useConvertedLeads(allLeads, grouping, dateRange);
    const activeLeads = useActiveLeads(allLeads, grouping, dateRange);
    const cancelledLeads = useCancelledLeads(allLeads, grouping, dateRange);

    return {
        allLeads,
        rawLeads,
        pendingLeads,
        acceptedLeads,
        declinedLeads,
        expiredLeads,
        convertedLeads,
        activeLeads,
        cancelledLeads,
    };
};

export default useLeadAnalytics;
