import type { useConfirm, useNexAction } from '@nexdynamic/nex-ui-react';
import type { BulkPriceChange } from '@nexdynamic/squeegee-common';
import type useTranslation from '../../../../hooks/useTranslation';
import { sendPriceChangeNotifications } from './sendPriceChangeNotifications';

export const notifyPriceChange = async (
    bulkPriceChange: BulkPriceChange,
    askForConfirmation: ReturnType<typeof useConfirm>['askForConfirmation'],
    handleAction: ReturnType<typeof useNexAction>['0'],
    t: ReturnType<typeof useTranslation>['t']
) => {
    const translations = { name: bulkPriceChange.name || '' };
    return await handleAction(
        {
            successMsg: t('price-change.notified', translations),
            errorMsg: t('price-change.notify-failed', translations),
        },
        async () => {
            if (bulkPriceChange) {
                const { agreed } = await askForConfirmation({
                    title: t('price-change.notify-confirmation-title'),
                    description: t('price-change.notify-confirmation-description', translations),
                    agreeLabel: t('general.notify'),
                    disagreeLabel: t('general.cancel'),
                });
                if (agreed) {
                    const sent = await sendPriceChangeNotifications(bulkPriceChange);
                    return sent;
                } else {
                    return { success: true, notify: false };
                }
            }
            return { success: false, errorMsg: 'price-change.not-found' };
        }
    );
};
