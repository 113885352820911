// Registered on the portal

import type { DateRangeValue } from '@nexdynamic/nex-ui-react';
import { calculatePercentageChange } from '@nexdynamic/nex-ui-react';
import type { Customer, JobOccurrence } from '@nexdynamic/squeegee-common';
import { JobOccurrenceStatus, sortByCreatedDateDesc } from '@nexdynamic/squeegee-common';
import { useMemo } from 'react';
import { Data } from '../../../../Data/Data';
import { groupTimestamps } from '../portalAnalyticsUtils';
import type { Grouping } from '../usePortalAnalytics';
import type { AllLeads } from './useLeadAnalytics';

const filterLeads = (leads: Array<Customer>) => {
    return leads.filter(customer => {
        const isActive =
            (customer.state || 'active') === 'active' &&
            Data.exists<JobOccurrence>('joboccurrences', {
                customerId: customer._id,
                status: JobOccurrenceStatus.Done,
            });

        return isActive;
    });
};

export const useActiveLeads = (portalLeads: AllLeads, grouping: Grouping, dateRange: DateRangeValue) => {
    const { leads: allLeads, previousLeads: allPreviousLeads } = portalLeads;

    const { leads, previousLeads } = useMemo(() => {
        return {
            leads: filterLeads(allLeads).sort(sortByCreatedDateDesc),
            previousLeads: filterLeads(allPreviousLeads).sort(sortByCreatedDateDesc),
        };
    }, [allLeads]);

    const timestamps = useMemo(() => {
        return leads.map(lead => new Date(lead.createdDate).valueOf());
    }, [leads]);

    const groupedLeads = useMemo(() => {
        return groupTimestamps(grouping, timestamps, dateRange);
    }, [timestamps, grouping]);

    const percentageChange = calculatePercentageChange(previousLeads.length, leads.length);

    return {
        leads,
        groupedLeads,
        previousLeads,
        percentageChange,
        count: leads.length,
        timestamps,
    };
};
