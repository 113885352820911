import { Box, Grid, Paper, Typography } from '@mui/material';
import { FormStep } from '@nexdynamic/nex-ui-react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';
import * as yup from 'yup';
import { ApplicationState } from '../../../../../ApplicationState';
import TokenTextField from '../../../../components/inputs/TokenTextField';
import useTranslation from '../../../../hooks/useTranslation';
export class TemplateDetailsFormFields {
    name = '';
    subject = '';

    constructor(name?: string, subject?: string) {
        this.name = name || '';
        this.subject = subject || '';
    }
}

type TemplateDetailsFormProps = {
    initialValues: TemplateDetailsFormFields;
    onSubmit: (fields: TemplateDetailsFormFields) => Promise<void>;
};

const t = ApplicationState.localise;

export const TemplateDetailsFormSchema = () =>
    yup
        .object({
            name: yup.string().required(t('templates-form.nameRequired')),
            subject: yup.string().required(t('templates-form.subjectRequired')),
        })
        .required();

export const TemplateDetailsForm = React.forwardRef<HTMLFormElement, TemplateDetailsFormProps>(({ initialValues, onSubmit }, ref) => {
    const { t } = useTranslation();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={TemplateDetailsFormSchema()}
            onSubmit={values => {
                return onSubmit(values);
            }}
        >
            {() => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Form autoComplete="off" ref={ref}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Paper>
                                        <Box p={2}>
                                            <FormStep
                                                fields={['name', 'description']}
                                                title={t('templates-form.details')}
                                                description={t('templates-form.detailsDescription')}
                                            >
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                                    <Box sx={{ flex: 1, minWidth: 200 }}>
                                                        <Field
                                                            sx={{ width: '100%' }}
                                                            required
                                                            component={TextField}
                                                            type="text"
                                                            label={t('general.name')}
                                                            name="name"
                                                        />
                                                    </Box>
                                                    <Box sx={{ flex: 1, minWidth: 200 }}>
                                                        <Field
                                                            sx={{ width: '100%' }}
                                                            required
                                                            component={TokenTextField}
                                                            type="text"
                                                            label={t('templates-form.subject')}
                                                            name="subject"
                                                        />
                                                        <Typography variant="caption">{t('templates-form.subjectDescription')}</Typography>
                                                    </Box>
                                                </Box>
                                            </FormStep>
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Form>
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
});
