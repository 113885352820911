import { FormStep } from '@nexdynamic/nex-ui-react';
import useTranslation from '../../../../../hooks/useTranslation';
import AudienceSelectInput from '../../../audiences/component/AudienceSelectInput';

const CampaignToStep = () => {
    const { t } = useTranslation();

    return (
        <FormStep fields={['audienceIds']} title={t('campaigns-form.to')} description={t('campaigns-form.to-description')}>
            <AudienceSelectInput required={true} fieldName='audienceIds' />
        </FormStep>
    );
};

export default CampaignToStep;
