import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import type { BoxProps } from '@mui/material';
import { Button, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import moment from 'moment';
type WeekNavigatorProps = { selectedDate: Dayjs; setSelectedDate: (date: Dayjs) => void } & BoxProps;

export const WeekNavigator = ({ selectedDate, setSelectedDate, ...rest }: WeekNavigatorProps) => {
    const startOfWeek = moment(selectedDate.toDate()).startOf('isoWeek');
    const endOfWeek = moment(selectedDate.toDate()).endOf('isoWeek');

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
            {...rest}
        >
            <IconButton onClick={() => setSelectedDate(selectedDate.subtract(1, 'week'))}>
                <ChevronLeftIcon />
            </IconButton>
            <Box>
                <Typography variant='body1'>
                    {startOfWeek.format('DD MMM YYYY')} - {endOfWeek.format('DD MMM YYYY')}
                </Typography>
            </Box>
            <Button size='small' onClick={() => setSelectedDate(dayjs())}>
                TODAY
            </Button>
            <IconButton onClick={() => setSelectedDate(selectedDate.add(1, 'week'))}>
                <ChevronRightIcon />
            </IconButton>
        </Box>
    );
};
