import { copyObject } from '@nexdynamic/squeegee-common';
import type { IDirectoryEntryDetails } from '@nexdynamic/squeegee-portal-common';
import { TextKeys } from '@nexdynamic/squeegee-portal-common';
import { PortalTextDialog } from '../../DirectoryAndPortal/Dialogs/PortalTextDialog';

export const getPortalTextDialog = (directory: IDirectoryEntryDetails, searchText?: string) => {
    const text = directory.config?.text || copyObject(TextKeys);
    const keys = Object.keys(TextKeys) as Array<keyof typeof TextKeys>;
    for (const key of keys) if (text[key] === undefined) text[key] = TextKeys[key];

    const dialog = new PortalTextDialog({
        textSettings: { text, textDebug: directory.config?.textDebug || false },
        searchValue: searchText,
    });

    return dialog;
};
