import { createTheme, Dialog, DialogContent, ThemeProvider } from '@mui/material';
import { light } from '@nexdynamic/nex-ui-react';
import JsonEditor from './JsonEditor';

type Props = {
    open: boolean;
    onClose: () => void;
    baseJson: object;
    onSave: (json: object) => void;
};

const JsonEditorDialog = ({ open, onClose, baseJson, onSave }: Props) => {
    return (
        <ThemeProvider
            theme={createTheme({
                ...light,
                palette: { ...light.palette, mode: 'dark', background: { paper: '#1E1E1E' } },
                components: {
                    MuiPaper: {
                        ...light.components?.MuiPaper,
                        styleOverrides: {
                            ...light.components?.MuiPaper?.styleOverrides,
                            root: {
                                ...(typeof light.components?.MuiPaper?.styleOverrides?.root === 'object'
                                    ? light.components?.MuiPaper?.styleOverrides?.root
                                    : {}),
                                backgroundImage: 'none',
                            },
                        },
                    },
                },
            })}
        >
            <Dialog open={open} onClose={onClose} fullWidth fullScreen>
                <DialogContent>
                    <JsonEditor baseJson={baseJson} onClose={onClose} onSave={onSave} />
                </DialogContent>
            </Dialog>
        </ThemeProvider>
    );
};

export default JsonEditorDialog;
