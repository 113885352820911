import type { Notification } from '@nexdynamic/squeegee-common';
import useStoredObjects from '../../hooks/useStoredObjects';

export const useOutbox = () => {
    const notifications = useStoredObjects<Notification>('notifications', {
        where: { status: 'pending' },
    });

    return notifications;
};
