import type { Customer, Transaction } from '@nexdynamic/squeegee-common';
import { Data } from '../../../Data/Data';

export const usePendingTransactions = (customer: Customer) => {
    const transactions = Data.all<Transaction>('transactions').filter(transaction => transaction.customerId === customer._id);

    const pendingTransactions = transactions.filter(transaction => transaction.status === 'pending');

    return pendingTransactions;
};
