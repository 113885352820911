import { Box, Breadcrumbs, Fade, Grid } from '@mui/material';
import { FormDialog, GradientButton, PageContainer } from '@nexdynamic/nex-ui-react';
import { useState } from 'react';
import Text from '../components/Text';
import useTranslation from '../hooks/useTranslation';
import ExpenseCategoiresList from './components/ExpenseCategoiresList';
import ExpenseCategoryForm, { ExpenseCategoryFields, ExpenseCategoryFieldsSchema } from './components/ExpenseCategoryForm';
import useExpenseCategoryCreate from './hooks/useCreateExpenseCategory';

export const ExpenseCategoiresView = () => {
    const { t } = useTranslation();
    const [showForm, setShowForm] = useState(false);
    const { createExpenseCategory } = useExpenseCategoryCreate();

    const onSubmitForm = (fields: ExpenseCategoryFields) => {
        setShowForm(false);
        createExpenseCategory(fields);
    };

    const add = () => {
        setShowForm(true);
    };

    const handleClose = () => {
        setShowForm(false);
    };

    return (
        <Fade in={true}>
            <div>
                <PageContainer>
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ flex: 1 }}>
                                <Breadcrumbs>
                                    <Text
                                        variant='h5'
                                        fontWeight='bold'
                                        color='text.primary'
                                        text='route-config.expense-categories-title'
                                    />
                                </Breadcrumbs>
                            </Box>
                            <Box>
                                <GradientButton color='primary' text={t('expense-category.add')} onClick={() => add()} />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <ExpenseCategoiresList />
                    </Grid>
                </PageContainer>
                {showForm && (
                    <FormDialog
                        initialValues={new ExpenseCategoryFields()}
                        schema={ExpenseCategoryFieldsSchema()}
                        open={true}
                        onClose={handleClose}
                        title={t('expense-category.add')}
                        onSave={values => onSubmitForm(values)}
                    >
                        <ExpenseCategoryForm />
                    </FormDialog>
                )}
            </div>
        </Fade>
    );
};

export default ExpenseCategoiresView;
