import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import type { AudienceFilterModel } from '@nexdynamic/squeegee-common';

type Props = {
    filters: Array<AudienceFilterModel>;
    value?: string;
    onChange: (filter: AudienceFilterModel) => void;
    disabled?: boolean;
    label?: string;
};

const FilterField = ({ value, filters, onChange, disabled, label }: Props) => {
    const setFilter = (field: string) => {
        const activeFilter = filters.find(filter => filter.field === field);
        if (activeFilter) onChange(activeFilter);
        else console.error('Unable to set active filter');
    };

    return (
        <FormControl disabled={disabled} size='small' required fullWidth>
            {label && <InputLabel id='filter-field-select-label'>{label}</InputLabel>}
            <Select
                disabled={disabled}
                labelId='filter-field-select-label'
                id='field-field-select'
                value={value}
                label={label}
                onChange={event => setFilter(event.target.value)}
            >
                {filters.map(filter => (
                    <MenuItem key={filter.field} value={filter.field}>
                        {filter.label || filter.field}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default FilterField;
