import { useState } from 'react';
import { transferAdvertismentAssets } from '../../../services/AdvertisementService';
import AlertDialog from './AlertDialog';

type TransferDialogProps = {
    id: string;
    onSuccess: () => void;
    onCancel: () => void;
};

export default function TransferAssetsAlert({ id, onSuccess, onCancel }: TransferDialogProps) {
    const [errorMessage, setAccessDeniedError] = useState('');

    const handleOK = () => {
        transferAdvertismentAssets(id)
            .then(onSuccess)
            .catch(error => setAccessDeniedError(error.message));
    };

    return (
        <div>
            <AlertDialog
                title='Transfer advert assets?'
                content='Are you sure you want to transfer the attached assets on this advert to the purchaser?'
                okText='Yes'
                onOk={handleOK}
                onCancel={onCancel}
            />

            {errorMessage && (
                <AlertDialog
                    title='Transfer Failed'
                    content={errorMessage}
                    okText='OK'
                    onOk={onSuccess}
                    onCancel={onCancel}
                    hideCancel={true}
                />
            )}
        </div>
    );
}
