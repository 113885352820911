import { Typography } from '@mui/material';
import { Suspense } from 'react';
import { Outlet, Route, Routes } from 'react-router';
import ViewLoading from '../../components/ViewLoading';

import { ChangesRoutes } from '../features/changes/ChangesRoutes';

export const PricingMain = () => {
    return (
        <Suspense fallback={<ViewLoading />}>
            <Outlet />
            <Routes>
                <Route path='/changes/*' element={<ChangesRoutes />} />
                <Route
                    path='*'
                    element={
                        <Typography variant='h6' textAlign='center'>
                            Error Page Not Found
                        </Typography>
                    }
                />
            </Routes>
        </Suspense>
    );
};
