import { Container } from '@mui/material';
import { WorkerAvailabilityGrid } from '../components/WorkerAvailabilityGrid';

export const AvailabilityView = () => {
    return (
        <Container maxWidth='lg'>
            <WorkerAvailabilityGrid />
        </Container>
    );
};
