import { Checkbox, ListItemText } from '@mui/material';
import { ElevatedListItemButton } from '@nexdynamic/nex-ui-react';
import type { Customer } from '@nexdynamic/squeegee-common';
import type { ReactEventHandler } from 'react';
import { CustomerDialog } from '../../../../../Customers/Components/CustomerDialog';
import useTranslation from '../../../../hooks/useTranslation';

type ContactListItemProps = {
    contact: Customer;
    onSelect?: (contact: Customer) => void;
    selected?: boolean;
    preventNavigation?: boolean;
};

const ContactListItem = ({ contact, onSelect, selected, preventNavigation }: ContactListItemProps) => {
    const method = contact.email || contact.telephoneNumber || contact.address?.addressDescription || '';
    const { t } = useTranslation();
    const primary = contact.name || t('customer.unknown');

    const onChecked: ReactEventHandler<HTMLButtonElement> = event => {
        event.preventDefault();
        event.stopPropagation();
        if (onSelect) onSelect(contact);
    };

    const secondaryAction = onSelect ? <Checkbox onClick={onChecked} checked={selected} edge='start' tabIndex={-1} sx={{ mr: 1 }} /> : null;

    return (
        <ElevatedListItemButton
            dense={true}
            role='listitem'
            onClick={() => {
                if (!preventNavigation) {
                    new CustomerDialog(contact).show();
                } else if (onSelect) onSelect(contact);
            }}
        >
            {secondaryAction}
            <ListItemText primary={primary} secondary={method} primaryTypographyProps={{ sx: { textTransform: 'capitalize' } }} />
        </ElevatedListItemButton>
    );
};

export default ContactListItem;
