import { useEffect, useState } from 'react';
import { Api } from '../../Server/Api';

type FranchiseeAccount = { businessName: string; email: string };
type FranchiseeAccounts = Array<FranchiseeAccount>;

export const useFranchiseeAccounts = () => {
    const [accounts, setAccounts] = useState<FranchiseeAccounts>();

    const fetchAccounts = async () => {
        const url = '/api/franchise/get-related-accounts';
        const response = await Api.get<FranchiseeAccounts>(null, url);
        if (!response) return { success: false, error: 'Failed to fetch accounts' };

        const data = await response.data;
        if (!data || !data.length) return { success: false, error: 'No accounts found' };
        setAccounts(data);
    };

    useEffect(() => {
        fetchAccounts();
    }, []);

    return accounts;
};
