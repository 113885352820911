import { Outlet, Route, Routes } from 'react-router';

import { Box } from '@mui/material';
import SupplierAddView from './SupplierAddView';
import SupplierEditView from './SupplierEditView';
import SupplierView from './SupplierView';
import SuppliersView from './SuppliersView';

const View = () => {
    return (
        <Box py={2}>
            <Outlet></Outlet>
        </Box>
    );
};

const SuppliersRoutes = () => {
    return (
        <Routes>
            <Route path='/*' element={<View />}>
                <Route path='list/*' element={<SuppliersView />} />
                <Route path='add' element={<SupplierAddView />} />
                <Route path=':id' element={<SupplierView />} />
                <Route path=':id/edit' element={<SupplierEditView />} />
            </Route>
        </Routes>
    );
};

export default SuppliersRoutes;
