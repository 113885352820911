import { Route, Routes } from 'react-router-dom';
import TrackerMain from './views/TrackerMain';

const TrackerApp = () => {
    return (
        <>
            <Routes>
                <Route path='/tracker/home' element={<TrackerMain />} />
                <Route path='/tracker/filter' element={<></>} />
            </Routes>
        </>
    );
};

export default TrackerApp;
