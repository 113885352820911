import type { LedgerId } from '@nexdynamic/balance-core';
import { TransactionUtilities, type PaymentAccount, type Transaction } from '@nexdynamic/squeegee-common';
import { Data } from '../../Data/Data';

export const generateMaps = (formTimestamp?: number) => {
    const pmtAccounts: ReadonlyArray<PaymentAccount> = Data.all('paymentaccounts');
    const paymentAccountToLedgerIdMap = new Map<string, LedgerId>();

    for (const pmtAccount of pmtAccounts) {
        if (pmtAccount.type) {
            const mappedType = TransactionUtilities.mapAccountTypeToLedgerId(pmtAccount.type, pmtAccount.subType);
            if (mappedType) {
                paymentAccountToLedgerIdMap.set(pmtAccount._id, mappedType);
                continue;
            }
        } else {
            paymentAccountToLedgerIdMap.set(pmtAccount._id, 'assets.current_assets.funds.bank');
        }
    }

    const sinceIso = new Date(formTimestamp || 0).toISOString();

    const entities: ReadonlyArray<Transaction> = Data.all<Transaction>(
        'transactions',
        sinceIso ? tran => tran.createdDate >= sinceIso : undefined
        // It is possible to have duplicates but they will not be processed.
    );

    const transactionLookupMap = new Map<string, Transaction>();

    for (const tranferTran of entities) {
        transactionLookupMap.set(tranferTran._id, tranferTran);
    }

    return { paymentAccountToLedgerIdMap, entities, transactionLookupMap };
};
