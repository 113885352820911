import { Box, Container } from '@mui/material';
import type { LimitBreakingAccount } from '@nexdynamic/squeegee-common';
import { useEffect, useState } from 'react';
import { AccountLimitBreakerList } from './AccountLimitBreakerList';
import { getLimitBreakers } from './getLimitBreakers';
import { SlidersPanel } from './Sliders';
import { ToggleShowMobileDevicesOnly } from './ToggleShowMobileDevicesOnly';

export const MainAnalytics = () => {
    const [maxDistanceMetres, setMaxDistanceMetres] = useState<number>(200);
    const [maxTimeDifferenceMinutes, setMaxTimeDifferenceMinutes] = useState<number>(1);
    const [limitBreakers, setLimitBreakers] = useState<Array<LimitBreakingAccount>>(
        getLimitBreakers(maxDistanceMetres, maxTimeDifferenceMinutes)
    );
    const [showMobileDevicesOnly, setShowMobileDevicesOnly] = useState<boolean>(false);

    const updateLimitBreaks = (newMaxDistanceMetres: number, newMaxTimeDifferenceMinutes: number) => {
        setMaxDistanceMetres(newMaxDistanceMetres);
        setMaxTimeDifferenceMinutes(newMaxTimeDifferenceMinutes);

        const newLimitBreakers = showMobileDevicesOnly
            ? getLimitBreakers(newMaxDistanceMetres, newMaxTimeDifferenceMinutes).filter(BadAccount =>
                  BadAccount.limitBreaks.every(
                      AccountInfraction => AccountInfraction.deviceA.isMobile && AccountInfraction.deviceB.isMobile
                  )
              )
            : getLimitBreakers(newMaxDistanceMetres, newMaxTimeDifferenceMinutes);
        console.log(newLimitBreakers);

        setLimitBreakers(newLimitBreakers);
    };

    useEffect(() => {
        updateLimitBreaks(maxDistanceMetres, maxTimeDifferenceMinutes);
    }, [showMobileDevicesOnly]);

    const showMobileOnlyChanged = (showOnMobileOnly: boolean) => {
        setShowMobileDevicesOnly(showOnMobileOnly);
    };

    return (
        <Container>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <ToggleShowMobileDevicesOnly checked={showMobileDevicesOnly} showMobileOnlyChanged={showMobileOnlyChanged} />
                <br />
                <SlidersPanel
                    maxDistanceMetres={maxDistanceMetres}
                    maxTimeDifferenceMinutes={maxTimeDifferenceMinutes}
                    updateLimitBreakers={updateLimitBreaks}
                />
                <br />
                <AccountLimitBreakerList
                    limitBreakers={limitBreakers}
                    distanceConstraintMetres={maxDistanceMetres}
                    timeConstraintMinutes={maxTimeDifferenceMinutes}
                />
            </Box>
        </Container>
    );
};
