import { Box, Chip, ListItem, ListItemSecondaryAction, ListItemText, Paper, Stack, Typography } from '@mui/material';
import type { LedgerId } from '@nexdynamic/balance-core';
import { FormDialog, MoreActionMenu } from '@nexdynamic/nex-ui-react';
import { useState } from 'react';
import useTranslation from '../../hooks/useTranslation';
import useExpenseCategoryActions from '../hooks/useExpenseCategoryActions';
import type { ExpenseCategoryFields } from './ExpenseCategoryForm';
import ExpenseCategoryForm, { ExpenseCategoryFieldsSchema } from './ExpenseCategoryForm';

export type ExpenseCategoryItemProps = {
    description: string;
    id: string;
    balanceLedgerId?: LedgerId | null;
};

export const ExpenseCategoryItem = ({ description, id, balanceLedgerId }: ExpenseCategoryItemProps) => {
    const { edit, remove } = useExpenseCategoryActions(id);
    const [showForm, setShowForm] = useState(false);
    const { t } = useTranslation();

    // TODO This could be made more simple if we create some form of Dialog form provider so the hook can just open the form
    const handleEdit = () => {
        setShowForm(true);
    };

    const onSubmitForm = (fields: ExpenseCategoryFields) => {
        edit.run({ description: fields.description, balanceLedgerId: fields.balanceLedgerId });
        handleClose();
    };

    const handleClose = () => {
        setShowForm(false);
    };

    return (
        <Paper sx={{ mb: 0.5 }}>
            <ListItem>
                <ListItemText
                    primary={
                        <Box>
                            <Stack direction="row" spacing={1}>
                                <Typography sx={{ flex: 1 }}>{description}</Typography>
                                {balanceLedgerId ? (
                                    <Chip size="small" label={t('expense-category.balance-tag')} variant="outlined" color="primary" />
                                ) : null}
                            </Stack>
                        </Box>
                    }
                />
                <ListItemSecondaryAction>
                    <MoreActionMenu
                        variant="text"
                        label={`ExpenseCategoryItem-btn-${id}`}
                        actions={[
                            {
                                ...edit,
                                run: () => handleEdit(),
                            },
                            remove,
                        ]}
                    />
                </ListItemSecondaryAction>
            </ListItem>

            {showForm && (
                <FormDialog
                    initialValues={{ description, balanceLedgerId }}
                    schema={ExpenseCategoryFieldsSchema()}
                    open={true}
                    onClose={handleClose}
                    title={t('expense-category.add')}
                    onSave={values => onSubmitForm(values)}
                >
                    <ExpenseCategoryForm />
                </FormDialog>
            )}
        </Paper>
    );
};

export default ExpenseCategoryItem;
