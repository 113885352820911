import type { IAdvertisement, ICreateAdvertisementRequest, IGetAdvertisementsResponse } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../../ApplicationState';
import { Api } from '../../../Server/Api';

export async function createAdvertisement(request: ICreateAdvertisementRequest): Promise<{ advertisements: Array<IAdvertisement> }> {
    const result = await Api.post<{ advertisements: Array<IAdvertisement> }>(null, `/api/advertisements/`, request);

    const resultData = result?.data;
    if (!resultData) throw new Error('Failed to create advertisement');

    if (!resultData.advertisements?.length) return { advertisements: [] };

    for (const ad of resultData.advertisements) {
        const assetIds = ad.squeegeeAssets?.map(x => x.id) || [];
        if (assetIds.length) AdvertisementAssetCache.addItems(assetIds);
    }

    return resultData;
}

export async function updateAdvertisement(id: string, request: Partial<IAdvertisement>) {
    return await Api.put<{ message: string }>(null, `/api/advertisements/${id}`, request);
}
export async function deleteAdvertisement(id: string) {
    return await Api.delete(null, `/api/advertisements/${id}`);
}

export async function getAdvertisements(query?: string): Promise<IGetAdvertisementsResponse> {
    const advertisementsResponse = await Api.get<IGetAdvertisementsResponse>(null, `/api/advertisements${query ? '?' + query : ''}`);

    if (!advertisementsResponse?.data) throw new Error('Failed to fetch advertisements');

    return advertisementsResponse.data;
}

export async function cancelCheckout(id: string): Promise<{ message: string }> {
    const response = await Api.post<{ message: string }>(null, `/api/advertisements/${id}/cancel-checkout`);

    if (!response?.data) throw new Error('Failed to cancel checkout');
    return response.data;
}

export async function logAdvertEvent(id: string, logCode: 'ADVERTISEMENT_VIEWED', message: string) {
    return await Api.post<{ message: string }>(null, `/api/advertisements/${id}/log-event`, { logCode, message });
}

export async function hasAdvertInArea(): Promise<boolean> {
    const distance = ApplicationState.getSetting('global.marketplace-receive-area', 15);
    const result = await Api.get<boolean>(null, `/api/advertisements-in-area/${distance}`);
    return !!result?.data;
}

export async function getMyAdverts(query?: string): Promise<{ advertisements: Array<IAdvertisement> }> {
    const advertsResponse = await Api.get<{ advertisements: Array<IAdvertisement> }>(
        null,
        `/api/my-advertisements${query ? '?' + query : ''}`
    );

    if (!advertsResponse?.data) throw new Error('Failed to fetch my advertisements');
    return advertsResponse.data;
}
export async function getMyPurchases(query?: string): Promise<{ advertisements: Array<IAdvertisement> }> {
    const myPurchasesResponse = await Api.get<{ advertisements: Array<IAdvertisement> }>(
        null,
        `/api/my-purchases${query ? '?' + query : ''}`
    );
    if (!myPurchasesResponse?.data) throw new Error('Failed to fetch my purchases');
    return myPurchasesResponse.data;
}

export const runAdvertChecker = async () => {
    ApplicationState.hasAdverts = await hasAdvertInArea();

    setTimeout(runAdvertChecker, 900000);
};

export async function refreshCheckoutStatus(id: string): Promise<IAdvertisement> {
    const checkoutResponse = await Api.post<{ message: string; updated: IAdvertisement | undefined }>(
        null,
        `/api/my-advertisements/${id}/refresh-checkout`
    );

    if (!checkoutResponse?.data) throw new Error('Failed to refresh checkout status');

    if ('updated' in checkoutResponse.data !== true || !checkoutResponse.data.updated) throw new Error('Failed to refresh checkout status');

    return checkoutResponse.data.updated;
}

export async function publishAdvertisement(id: string): Promise<void> {
    await Api.post<void>(null, `/api/advertisements/${id}/publish`);
}

export async function unpublishAdvertisement(id: string): Promise<void> {
    await Api.post(null, `/api/advertisements/${id}/unpublish`);
}

export async function transferAdvertismentAssets(id: string) {
    return (await Api.post<{ message: string }>(null, `/api/my-advertisements/${id}/transfer`))?.data;
}

export async function transferFranchiseAssets(id: string) {
    return (await Api.post<{ message: string }>(null, `/api/my-advertisements/${id}/in-house-transfer`))?.data;
}

export async function getSecretForPaymentIntent(id: string): Promise<{ client_secret: string; termsHtml: string }> {
    const url = `/api/advertisements/${id}/checkout`;
    const secretPaymentIntentResponse = await Api.get<
        { client_secret: string; termsHtml: string; message: undefined } | { message: string }
    >(null, url);
    if (!secretPaymentIntentResponse?.data) throw new Error('Failed to get secret for payment intent');

    if ('message' in secretPaymentIntentResponse.data) throw new Error(secretPaymentIntentResponse.data.message);

    return secretPaymentIntentResponse.data;
}

export const sendMarketplaceTermsRequest = async (id: string, terms: string): Promise<{ message: string }> => {
    const termsRequestResponse = await Api.post<{ message: string }>(null, `/api/marketplace/seller/${id}/terms-request`, { terms });
    if (!termsRequestResponse?.data) throw new Error('Failed to send terms request');
    return termsRequestResponse.data;
};

// async function postReq<T>(url: string, data?: any) {
//     const apiEndpoint = Api.apiEndpoint;
//     const resp = await fetch(`${apiEndpoint}${url}`, {
//         body: data && JSON.stringify(data),
//         headers: getHeaders(RethinkDbAuthClient.session, true),
//         method: 'POST',
//     });
//     if (!resp.ok) throw new Error((await resp.text()) || `error fetching from ${url}: ${resp.status}`);

//     return (await resp.json()) as T;
// }

// async function deleteReq(url: string) {
//     const apiEndpoint = Api.apiEndpoint;
//     const resp = await fetch(`${apiEndpoint}${url}`, {
//         headers: getHeaders(RethinkDbAuthClient.session, true),
//         method: 'DELETE',
//     });
//     const respData = await resp.json();
//     switch (resp.status) {
//         case 200: {
//             return respData;
//         }
//         default:
//             throw new Error(respData.message || `Unknown error: ${resp.status}`);
//     }
// }

// async function putReq(url: string, data: any) {
//     const apiEndpoint = Api.apiEndpoint;
//     const resp = await fetch(`${apiEndpoint}${url}`, {
//         body: JSON.stringify(data),
//         headers: getHeaders(RethinkDbAuthClient.session, true),
//         method: 'PUT',
//     });
//     const respData = await resp.json();
//     switch (resp.status) {
//         case 200: {
//             return respData;
//         }
//         default:
//             throw new Error(respData.message || `Unknown error: ${resp.status}`);
//     }
// }

// async function getReq<T>(url: string): Promise<T> {
//     const apiEndpoint = Api.apiEndpoint;
//     const resp = await fetch(`${apiEndpoint}${url}`, {
//         headers: getHeaders(RethinkDbAuthClient.session, true),
//         method: 'GET',
//     });

//     if (!resp.ok) throw new Error((await resp.text()) || `error fetching from ${url}: ${resp.status}`);
//     return (await resp.json()) as T;
// }
const assetIsListed = (id: string) => {
    return AdvertisementAssetCache.assetIds.includes(id);
};
const addItems = (assetIds: Array<string>) => {
    const newIds = assetIds.filter(id => !AdvertisementAssetCache.assetIds.includes(id));

    ApplicationState.setSetting('marketplace.items-listed', [...AdvertisementAssetCache.assetIds, ...newIds]).then(() => {
        AdvertisementAssetCache.assetIds = ApplicationState.getSetting('marketplace.items-listed', []);
    });
};
const removeItem = (assetId: string) => {
    ApplicationState.setSetting(
        'marketplace.items-listed',
        AdvertisementAssetCache.assetIds.filter(id => id !== assetId)
    );
};
export const AdvertisementAssetCache = {
    addItems,
    removeItem,
    assetIsListed,
    clear: () => ApplicationState.clearSetting('marketplace.items-listed'),
    assetIds: Array<string>(),
};
