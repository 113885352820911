import DeleteOutline from '@mui/icons-material/DeleteOutline';
import EditOutlined from '@mui/icons-material/EditOutlined';
import type { Supplier } from '@nexdynamic/squeegee-common';
import { useNavigate } from 'react-router';
import useTranslation from '../../hooks/useTranslation';

import type { NexAction } from '@nexdynamic/nex-ui-react';
import { useConfirm, useNexAction } from '@nexdynamic/nex-ui-react';
import useStoredObject from '../../hooks/useStoredObject';
import { supplierRemove } from '../SupplierService';

type UseSupplierActions = (id: string) => {
    running: boolean;
    remove: NexAction<() => void>;
    edit: NexAction<() => void>;
};

const useSupplierActions: UseSupplierActions = id => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const supplier = useStoredObject<Supplier>(id);
    const [handleAction, running] = useNexAction();
    const { askForConfirmation } = useConfirm();

    const translations = { name: supplier?.name || '' };

    return {
        running,
        remove: {
            run: () =>
                handleAction(
                    {
                        successMsg: t('supplier.removed', translations),
                        errorMsg: t('supplier.remove-failed', translations),
                    },
                    async () => {
                        if (supplier?._id) {
                            const { agreed } = await askForConfirmation({
                                title: t('supplier.remove-confirm-title', translations),
                                description: t('supplier.remove-confirm-description', translations),
                                disagreeLabel: t('general.cancel'),
                                agreeLabel: t('general.remove'),
                                preventClickOff: true,
                            });

                            if (agreed) {
                                await supplierRemove(supplier);
                                navigate('/suppliers/list');
                                return { success: true };
                            }
                        }
                        return { success: true, notify: false };
                    }
                ),
            name: t('general.remove'),
            icon: <DeleteOutline />,
        },
        edit: {
            run: () => navigate(`/suppliers/${id}/edit`),
            name: t('general.edit'),
            icon: <EditOutlined />,
        },
    };
};

export default useSupplierActions;
