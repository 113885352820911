import { Box, Typography } from "@mui/material";

type ChartHeaderProps = {
  onRemove?: () => void;
  title: string;
  subheading: string;
  strokeColor: string;
  currentValue: string | number;
};

const ChartHeader = ({
  title,
  strokeColor,
  subheading,
  currentValue,
}: ChartHeaderProps) => {
  return (
    <Box sx={{ display: "flex", height: 100, p: 2 }}>
      {/* {onRemove && (
        <Box sx={{ mr: 1 }}>
          <IconButton size="small" aria-label="delete" onClick={onRemove}>
            <CloseOutlined />
          </IconButton>
        </Box>
      )} */}
      <Box sx={{ flex: 1 }}>
        <Typography variant="h6" sx={{ color: `${strokeColor}` }}>
          {title}
        </Typography>
        <Typography variant="subtitle2" sx={{ color: "#4d4d4e" }}>
          {subheading}
        </Typography>
      </Box>

      <Box>
        <Typography variant="h6" sx={{ color: "#424242" }}>
          £{currentValue}
        </Typography>
      </Box>
    </Box>
  );
};

export default ChartHeader;
