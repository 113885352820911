import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Link, Typography } from '@mui/material';
import { NexUiDialogProvider, useIsSmallScreen } from '@nexdynamic/nex-ui-react';
import { ContentState, EditorState, convertFromHTML, type RawDraftContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useState } from 'react';
import { ApplicationState } from '../../../../ApplicationState';
import { NotifyUserMessage } from '../../../../Notifications/NotifyUserMessage';
import RichText from '../../../components/editor/RichText';
import { sendMarketplaceTermsRequest } from '../../services/AdvertisementService';

const TermsDialog = ({ handleClose }: { handleClose: () => void }) => {
    const currentTerms = ApplicationState.getSetting(
        'global.marketplace-seller-terms',
        ApplicationState.localise('marketplace.legal-seller-tos-description-placeholder').replace(/\n/g, '<br>')
    );
    const rawHtml = convertFromHTML(currentTerms);
    const contentState = ContentState.createFromBlockArray(rawHtml.contentBlocks, rawHtml.entityMap);
    const initialState = EditorState.createWithContent(contentState);
    const [terms, setTerms] = useState<string>('');

    const uuid = ApplicationState.account.uuid;
    const handleOk = () => {
        sendMarketplaceTermsRequest(uuid, terms);
        handleClose();
        new NotifyUserMessage('marketplace.terms-submitted');
    };

    const handleRichTextTermsChanging = (value: RawDraftContentState) => {
        const newTerms = draftToHtml(value);
        setTerms(newTerms);
    };

    const smallScreen = useIsSmallScreen();

    return (
        <NexUiDialogProvider>
            <Dialog open={true} onClose={handleClose} fullScreen={smallScreen} fullWidth maxWidth="lg">
                <DialogTitle>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        Terms and Conditions
                        <Link href="https://squeeg.ee/terms" target="_blank">
                            https://squeeg.ee/terms
                        </Link>
                    </Box>
                    <Divider sx={{ mb: '1rem' }} />
                    <Typography variant="body2">{ApplicationState.localise('marketplace.terms-keep-safe-when-updating')}</Typography>
                </DialogTitle>

                <DialogContent>
                    <RichText
                        onChange={handleRichTextTermsChanging}
                        options={['inline']}
                        inlineOptions={['bold', 'italic', 'underline', 'strikethrough']}
                        hideCustomButtons={true}
                        hideSuggestions
                        initialState={initialState}
                    ></RichText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleOk} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </NexUiDialogProvider>
    );
};

export default TermsDialog;
