import { AureliaReactComponentDialog } from '../../../Dialogs/AureliaReactComponentDialog';
import { ComponentSettingsMenu } from './ComponentSettingsMenu';

export class ComponentSettingsDialog extends AureliaReactComponentDialog<undefined> {
    constructor() {
        super({
            dialogTitle: 'directory.dynamic-component-settings',
            component: ComponentSettingsMenu,
            isSecondaryView: true,
        });
    }
}
