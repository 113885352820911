import { Box, Grid } from '@mui/material';
import { FormStep } from '@nexdynamic/nex-ui-react';
import Text from '../../../../../components/Text';
import useTranslation from '../../../../../hooks/useTranslation';
import TemplateSelect from '../../../templates/components/TemplateSelect';

const CampaignTemplateStep = () => {
    const { t } = useTranslation();
    return (
        <FormStep fields={['templateId']} title={t('campaigns-form.template')} description={t('campaigns-form.template-description')}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box>
                        <Text sx={{ mb: 1 }} text='campaigns-form.select-template' />
                        <TemplateSelect fieldName={'templateId'} />
                    </Box>
                </Grid>
            </Grid>
        </FormStep>
    );
};

export default CampaignTemplateStep;
