import { Box, Popover } from '@mui/material';
import GColorPicker from 'react-best-gradient-color-picker';

export type ColorPickerProps = {
    colorKey: string;
    anchorPos: { top: number; left: number } | undefined;
    defaultColor: string;
    updatePalette: (color: string, key: string) => void;
    onCloseColorPicker: (e: MouseEvent) => void;
};

const ColorPicker = ({ colorKey, anchorPos, defaultColor, updatePalette, onCloseColorPicker }: ColorPickerProps) => {
    return (
        <Popover
            open={!!anchorPos}
            anchorPosition={anchorPos}
            anchorReference='anchorPosition'
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            slotProps={{ paper: { sx: { overflow: 'visible' } } }}
            onClose={onCloseColorPicker}
            onClick={e => e.stopPropagation()}
        >
            <Box onClick={e => e.stopPropagation()} sx={{ p: 1 }}>
                <GColorPicker
                    value={defaultColor}
                    onChange={color => updatePalette(color, colorKey)}
                    hideEyeDrop
                    disableDarkMode
                    hideColorTypeBtns
                />
            </Box>
        </Popover>
    );
};

export default ColorPicker;
