import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import { alpha, Box, IconButton, Paper, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { ApplicationState } from '../../../../ApplicationState';
import { Api } from '../../../../Server/Api';
import { settingEditorComponents } from '../../ComponentSettings/settingEditorComponents';

type Props = {
    componentName: string;
    icon?: React.ReactNode;
    onRemove?: () => void;
    onSettings?: () => void;
    spacer?: boolean;
};

export const parseComponentName = (componentName: string) => {
    const words = componentName.split('-');
    return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

export class WidgetRenderedEvent extends CustomEvent<{ componentName: string }> {
    constructor(componentName: string) {
        super('widgetRendered', { detail: { componentName } });
    }
}

const WidgetSkeleton = ({ componentName, onRemove, onSettings, spacer }: Props) => {
    const handleSettings = async () => {
        onSettings?.();
    };

    const hasSettings = !!settingEditorComponents[componentName as keyof typeof settingEditorComponents];

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const listener = (event: WidgetRenderedEvent) => {
            if (event.detail.componentName === componentName) {
                console.log('loaded', event.detail.componentName);
                setLoading(false);
            }
        };
        window.addEventListener('widgetRendered', listener);
        return () => window.removeEventListener('widgetRendered', listener);
    }, []);

    return (
        <>
            <Paper
                sx={{
                    'position': 'relative',
                    'width': '100%',
                    'height': '100%',
                    'display': 'flex',
                    'flexDirection': 'column',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'boxShadow': spacer ? 0 : 1,
                    'borderStyle': spacer ? 'dashed' : 'solid',
                    'transition': 'background-color 0.2s',

                    'cursor': 'grab',
                    '&:active': {
                        cursor: 'grabbing',
                    },
                    'overflow': 'hidden',
                }}
                variant="outlined"
            >
                <Box
                    sx={{
                        'position': 'absolute',
                        'top': 0,
                        'right': 0,
                        'padding': 1,
                        'width': '100%',
                        'height': '100%',
                        'display': 'flex',
                        'justifyContent': 'flex-end',
                        'alignItems': 'flex-start',
                        'zIndex': 4,
                        'opacity': 0,
                        'transition': 'opacity 0.2s',
                        '&:hover': {
                            opacity: 1,
                        },
                        'bgcolor': theme => alpha(theme.palette.background.default, 0.5),
                    }}
                    onClick={e => e.stopPropagation()}
                >
                    {!spacer && hasSettings && (
                        <IconButton size="small" onClick={handleSettings}>
                            <SettingsIcon />
                        </IconButton>
                    )}
                    <IconButton size="small" onClick={onRemove}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                {loading && !spacer && (
                    <Skeleton variant="rectangular" width="100%" height="100%" sx={{ position: 'absolute', top: 0, left: 0 }} />
                )}
                {!spacer && (
                    <div
                        data-sqc={componentName}
                        data-sqa={ApplicationState.account.uuid}
                        data-sqe={Api.apiEndpoint}
                        style={{ width: '100%', height: '100%', zIndex: 1 }}
                    ></div>
                )}
            </Paper>
        </>
    );
};

export default WidgetSkeleton;
