import moment from 'moment';
import type { QuickScheduleOptions } from '../QuickScheduleOptions';

export const generateTimes = (selectedDate: Date, timeOptions: QuickScheduleOptions['availableTimeOptions'], method: 'sms' | 'email') => {
    return (
        timeOptions?.map(({ label, hour, minute }) => {
            const date = moment(selectedDate).startOf('day').add(hour, 'hour').add(minute, 'minute');
            const isEmailAndAfter72Hours = method === 'email' ? date.isAfter(moment().add(72, 'hours')) : false;
            return {
                label,
                id: `${hour}:${minute}`,
                hour,
                minute,
                disabled: date.isBefore(moment()) || isEmailAndAfter72Hours,
            };
        }) ?? []
    );
};
