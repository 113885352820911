import { Box, Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { PageContainer } from '@nexdynamic/nex-ui-react';
import type { Campaign } from '@nexdynamic/squeegee-common';
import { useNavigate, useParams } from 'react-router';
import Text from '../../../../components/Text';
import useStoredObject from '../../../../hooks/useStoredObject';
import CampaignDashboard from '../components/CampaignDashboard';

export const CampaignComponent = () => {
    const { id = '' } = useParams();
    const navigate = useNavigate();

    const campaign = useStoredObject<Campaign>(id);

    const View = () => {
        if (campaign) return <CampaignDashboard campaign={campaign} />;
        return (
            <Box>
                <Text text='campaigns.campaign-not-found' />
            </Box>
        );
    };

    return (
        <PageContainer>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ flex: 1 }}>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <Link
                                underline='none'
                                component='button'
                                sx={{ cursor: 'pointer' }}
                                onClick={() => navigate('/send/campaigns')}
                            >
                                <Text variant='subtitle1' text='campaigns.title' />
                            </Link>
                            <Typography variant='subtitle1' color='text.primary'>
                                {campaign?.name}
                            </Typography>
                        </Breadcrumbs>
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <View />
            </Grid>
        </PageContainer>
    );
};

export default CampaignComponent;
