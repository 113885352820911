import { Box, Button, Card, List, ListItem, ListItemSecondaryAction, ListItemText, Switch } from '@mui/material';
import { portalComponentSettingsDefaults } from '@nexdynamic/squeegee-portal-common';
import { useState } from 'react';
import { ApplicationState } from '../../../../ApplicationState';
import type { AureliaReactComponentDialogComponent } from '../../../../Dialogs/AureliaReactComponentDialogComponent';
import { setPortalComponentSetting } from './setPortalComponentSetting';

export const ProfileSettingsEditor: AureliaReactComponentDialogComponent<any> = () => {
    const settingDefaults = portalComponentSettingsDefaults['profile'];
    type SettingType = typeof settingDefaults;

    const profileComponentSettings = ApplicationState.getSetting<SettingType>('portal.component-profile', settingDefaults);
    const [profileSettings, setProfileSettings] = useState<SettingType>(profileComponentSettings);

    return (
        <>
            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                    <Button variant="contained" sx={{ mr: 2, mt: 2 }} onClick={() => setPortalComponentSetting('profile', profileSettings)}>
                        {ApplicationState.localise('general.save-button')}
                    </Button>
                </Box>
                <Card sx={{ m: 2 }} variant="outlined">
                    <List>
                        <ListItem>
                            <ListItemText>{ApplicationState.localise('portal-component.profile-show-edit-button')}</ListItemText>
                            <ListItemSecondaryAction>
                                <Switch
                                    checked={profileSettings.showEditProfileButton}
                                    color="secondary"
                                    onChange={e =>
                                        setProfileSettings({
                                            ...profileSettings,
                                            showEditProfileButton: e.target.checked,
                                        })
                                    }
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Card>
            </Box>
        </>
    );
};
