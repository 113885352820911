import '@fontsource/bebas-neue';
import '@fontsource/inter';
import '@fontsource/josefin-sans';
import type { PortalTheme } from '../../../DirectoryAndPortal/types/PortalTheme';

export const themePresets: Array<PortalTheme> = [
    {
        id: 'default-dark',
        name: 'Default Dark Theme',
        theme: {
            palette: {
                mode: 'dark',
                primary: { main: '#90caf9' },
                secondary: { main: '#ce93d8' },
                background: { default: '#2E2E2E', paper: '#121212' },
            },
        },
    },

    {
        id: 'default-light',
        name: 'Default Light Theme',
        theme: {
            palette: {
                mode: 'light',
                primary: { main: '#1976d2' },
                secondary: { main: '#9c27b0' },
                background: { default: '#fff', paper: '#fff' },
            },
        },
    },
    {
        id: 'm3-theme',
        name: 'Material 3 Theme',
        theme: {
            palette: {
                background: {
                    default: '#F0F4F9',
                    paper: '#fff',
                },
                divider: '#CAC4D0',
                error: {
                    contrastText: '#fff',
                    dark: 'rgb(125, 26, 21)',
                    main: '#b3261e',
                    light: '#FAD2CF',
                },
                info: {
                    contrastText: '#fff',
                    dark: '#01579b',
                    main: '#0288d1',
                    light: '#D2E3FC',
                },
                mode: 'light',
                primary: {
                    contrastText: '#fff',
                    dark: 'rgb(7, 60, 145)',
                    main: '#0b57d0',
                    light: '#D2E3FC',
                },
                secondary: {
                    contrastText: '#fff',
                    dark: '#7b1fa2',
                    main: '#9c27b0',
                    light: '#ba68c8',
                },
                success: {
                    contrastText: '#fff',
                    dark: '#1b5e20',
                    main: '#2e7d32',
                    light: '#CEEAD6',
                },
                warning: {
                    contrastText: '#fff',
                    dark: '#e65100',
                    main: '#ed6c02',
                    light: '#FEEFC3',
                },
            },
            shape: {
                borderRadius: 16,
            },
            components: {
                // all components following the Material 3
                MuiButton: {
                    styleOverrides: {
                        root: {
                            padding: '8px 24px',
                            borderRadius: 20,
                        },
                    },
                    variants: [
                        {
                            props: {
                                variant: 'text',
                            },
                            style: {
                                paddingLeft: 16,
                                paddingRight: 16,
                            },
                        },
                        {
                            props: {
                                size: 'small',
                            },
                            style: {
                                paddingTop: 4,
                                paddingBottom: 4,
                                paddingLeft: 16,
                                paddingRight: 16,
                            },
                        },
                    ],
                },
                MuiCard: {
                    defaultProps: {
                        elevation: 0,
                    },
                },
                MuiDialog: {
                    styleOverrides: {
                        paper: {
                            background: '#F0F4F9',
                        },
                    },
                },
                MuiChip: {
                    styleOverrides: {
                        root: {
                            'height': 32,
                            'padding': '8px 16px',
                            'borderRadius': 8,
                            'fontWeight': 500,
                            'fontSize': 14,
                            '& .MuiChip-label': {
                                padding: 0,
                            },
                        },
                    },
                    variants: [
                        {
                            props: {
                                size: 'small',
                            },
                            style: {
                                height: 24,
                                padding: '4px 8px',
                                fontSize: 12,
                            },
                        },
                        {
                            props: {
                                color: 'default',
                            },
                            style: {
                                backgroundColor: '#F1F3F4',
                                color: 'rgba(0, 0, 0, 0.87)',
                            },
                        },
                        {
                            props: {
                                color: 'primary',
                            },
                            style: {
                                backgroundColor: '#E3F2FD',
                                color: 'rgba(0, 0, 0, 0.87)',
                            },
                        },
                        {
                            props: {
                                color: 'secondary',
                            },
                            style: {
                                backgroundColor: '#F3E5F5',
                                color: 'rgba(0, 0, 0, 0.87)',
                            },
                        },
                        {
                            props: {
                                color: 'error',
                            },
                            style: {
                                backgroundColor: '#FAD2CF',
                                color: 'rgba(0, 0, 0, 0.87)',
                            },
                        },
                        {
                            props: {
                                color: 'warning',
                            },
                            style: {
                                backgroundColor: '#FEEFC3',
                                color: 'rgba(0, 0, 0, 0.87)',
                            },
                        },
                        {
                            props: {
                                color: 'info',
                            },
                            style: {
                                backgroundColor: '#D2E3FC',
                                color: 'rgba(0, 0, 0, 0.87)',
                            },
                        },
                        {
                            props: {
                                color: 'success',
                            },
                            style: {
                                backgroundColor: '#CEEAD6',
                                color: 'rgba(0, 0, 0, 0.87)',
                            },
                        },
                    ],
                },
            },
        },
    },
    {
        id: 'squeegee-web-theme',
        name: 'squeeg.ee Theme',
        theme: {
            palette: {
                background: {
                    default: 'linear-gradient(135deg, #540E6C 0%, #03103B 100%)',
                    paper: '#0B134A',
                },
                primary: {
                    main: '#456DE9',
                },
                secondary: {
                    main: '#F10989',
                },
                error: {
                    main: '#f36833',
                },
                warning: {
                    main: '#edc202',
                },
                info: {
                    main: '#42A5F6',
                },
                success: {
                    main: '#66B988',
                },

                mode: 'dark',
            },
            shape: {
                borderRadius: 8,
            },
            typography: {
                fontFamily: "'Inter', 'Roboto', 'Helvetica', 'Arial', sans-serif",
            },
            components: {
                MuiButton: {
                    variants: [
                        {
                            props: { variant: 'contained', color: 'primary' },
                            style: {
                                background: 'linear-gradient(117deg, rgb(132 69 233) 0%, rgba(69,171,233,1) 100%)',
                                color: 'white',
                            },
                        },
                        {
                            props: { variant: 'contained', color: 'secondary' },
                            style: {
                                background: 'linear-gradient(117deg, rgba(187,69,233,1) 0%, rgba(233,69,129,1) 100%)',
                                color: 'white',
                            },
                        },
                        {
                            props: { variant: 'text', color: 'error' },
                            style: {
                                background: 'linear-gradient(302deg, rgb(241 22 172) 0%, rgb(235 44 13) 100%)',
                                color: 'white',
                            },
                        },
                        {
                            props: { variant: 'text', color: 'warning' },
                            style: {
                                background: 'linear-gradient(117deg, rgba(69,100,233,1) 0%, rgba(69,171,233,1) 100%)',
                                color: 'white',
                            },
                        },
                        {
                            props: { variant: 'text', color: 'info' },
                            style: {
                                background: 'linear-gradient(117deg, rgb(148 184 211) 0%, rgb(66 104 126) 100%)',
                                color: 'white',
                            },
                        },
                        {
                            props: { variant: 'text', color: 'success' },
                            style: {
                                background: 'linear-gradient(302deg, rgb(35 175 11) 0%, rgb(14 168 203) 100%)',
                                color: 'white',
                            },
                        },
                    ],
                },
            },
        },
    },
    {
        id: 'squeegee-theme',
        name: 'Squeegee Theme',
        theme: {
            palette: {
                background: {
                    default: '#F2F2F2',
                    paper: '#ffffff',
                },
                primary: {
                    main: '#03103B',
                },
                secondary: {
                    main: '#F66578',
                },
                error: {
                    main: '#f36833',
                },
                warning: {
                    main: '#edc202',
                },
                info: {
                    main: '#42A5F6',
                },
                success: {
                    main: '#66B988',
                },

                mode: 'light',
            },
            shape: {
                borderRadius: 4,
            },
            typography: {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            },
        },
    },
    {
        id: 'black-theme',
        name: 'Black Theme',
        theme: {
            palette: {
                mode: 'light',
                primary: {
                    main: '#000000',
                },
                secondary: {
                    main: '#555555',
                },
                warning: {
                    main: '#FFC107',
                    contrastText: 'rgba(0,0,0,0.87)',
                },
                info: {
                    main: '#2196F3',
                },
                error: {
                    main: '#F44336',
                    contrastText: '#ffffff',
                },
                success: {
                    main: '#4CAF50',
                    contrastText: 'rgba(255,255,255,0.87)',
                },
                divider: '#111',
            },
            shape: {
                borderRadius: 0,
            },
            typography: {
                h1: {
                    fontFamily: 'Bebas Neue',
                },
                h2: {
                    fontFamily: 'Bebas Neue',
                },
                h3: {
                    fontFamily: 'Bebas Neue',
                },
                h4: {
                    fontFamily: 'Bebas Neue',
                },
                h5: {
                    letterSpacing: 0.8,
                    fontFamily: 'Bebas Neue',
                },
                button: {
                    letterSpacing: 1.2,
                    fontFamily: 'Roboto',
                    fontWeight: 'bold',
                },
            },
            components: {
                MuiChip: {
                    styleOverrides: {
                        root: {
                            borderRadius: 0,
                        },
                    },
                },
                MuiPaper: {
                    defaultProps: {
                        elevation: 0,
                    },
                    styleOverrides: {
                        root: {
                            border: '1px solid #111',
                        },
                    },
                },
            },
        },
    },
    {
        id: 'honey-mustard-theme',
        name: 'Honey Mustard Theme',
        theme: {
            palette: {
                mode: 'dark',
                background: {
                    default: '#310000',
                    paper: '#412c21',
                },
                primary: {
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                    dark: 'rgb(178, 140, 84)',
                    light: 'rgb(255, 211, 147)',
                    main: '#ffc978',
                },
                secondary: {
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                    dark: 'rgb(161, 118, 83)',
                    light: 'rgb(235, 186, 146)',
                    main: '#e7a977',
                },
                error: {
                    contrastText: '#fff',
                    dark: 'rgb(178, 16, 47)',
                    light: 'rgb(255, 69, 105)',
                    main: '#ff1744',
                },
                warning: {
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                    dark: '#f57c00',
                    light: '#ffb74d',
                    main: '#ffa726',
                },
                info: {
                    contrastText: '#fff',
                    dark: 'rgb(17, 82, 147)',
                    light: 'rgb(71, 145, 219)',
                    main: '#1976d2',
                },
                success: {
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                    dark: 'rgb(53, 122, 56)',
                    light: 'rgb(111, 191, 115)',
                    main: '#4caf50',
                },
            },
            shape: {
                borderRadius: 8,
            },
            typography: {
                h1: {
                    fontFamily: 'Josefin Sans',
                },
                h2: {
                    fontFamily: 'Josefin Sans',
                },
                h3: {
                    fontFamily: 'Josefin Sans',
                },
                h4: {
                    fontFamily: 'Josefin Sans',
                },
                h5: {
                    fontFamily: 'Josefin Sans',
                },
                h6: {
                    fontFamily: 'Josefin Sans',
                },
            },
        },
    },
    {
        id: 'lavender-theme',
        name: 'Lavender Theme',
        theme: {
            palette: {
                mode: 'light',
                primary: {
                    main: '#6716F7',
                },
                secondary: {
                    main: '#FFD400',
                },
                error: {
                    main: '#f56a6a',
                },
                info: {
                    main: '#64b5f6',
                },
                success: {
                    main: '#81c784',
                },
                warning: {
                    main: '#ecea41',
                },
                background: {
                    paper: '#ffffff',
                    default: '#e8e8e8',
                },
            },
        },
    },
    {
        id: 'pastel-theme',
        name: 'Pastel Theme',
        theme: {
            palette: {
                mode: 'light',
                primary: {
                    main: '#a9d7fd',
                    light: 'rgb(186, 223, 253)',
                    dark: 'rgb(118, 150, 177)',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                secondary: {
                    main: '#f8a8e3',
                    light: 'rgb(249, 185, 232)',
                    dark: 'rgb(173, 117, 158)',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                error: {
                    main: '#ff8a8a',
                    light: 'rgb(255, 161, 161)',
                    dark: 'rgb(178, 96, 96)',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                warning: {
                    main: '#fdf08e',
                    light: 'rgb(253, 243, 164)',
                    dark: 'rgb(177, 168, 99)',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                info: {
                    main: '#bce8ff',
                    light: 'rgb(201, 236, 255)',
                    dark: 'rgb(131, 162, 178)',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                success: {
                    main: '#bffad3',
                    light: 'rgb(203, 251, 219)',
                    dark: 'rgb(133, 175, 147)',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                background: { default: '#e8eaf6', paper: '#fefeff' },
            },
            shape: { borderRadius: 8 },
            typography: {
                fontFamily: "'Poppins', 'Helvetica', 'Arial', sans-serif",
                fontSize: 14,
                fontWeightLight: 300,
                fontWeightRegular: 400,
                fontWeightMedium: 500,
            },
        },
    },
    {
        id: 'midnight-magenta-theme',
        name: 'Midnight Magenta Theme',
        theme: {
            palette: {
                mode: 'dark',
                background: {
                    default: '#0d1b2a',
                    paper: '#1b263b',
                },
                primary: {
                    main: '#9969c0',
                    light: 'rgb(173, 135, 204)',
                    dark: 'rgb(107, 73, 134)',
                    contrastText: '#fff',
                },
                secondary: {
                    main: '#9969c0',
                    light: 'rgb(173, 135, 204)',
                    dark: 'rgb(107, 73, 134)',
                    contrastText: '#fff',
                },
                error: {
                    main: '#f44336',
                    light: '#e57373',
                    dark: '#d32f2f',
                    contrastText: '#fff',
                },
                warning: {
                    main: '#ffa726',
                    light: '#ffb74d',
                    dark: '#f57c00',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                info: {
                    main: '#29b6f6',
                    light: '#4fc3f7',
                    dark: '#0288d1',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                success: {
                    main: '#66bb6a',
                    light: '#81c784',
                    dark: '#388e3c',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
            },
        },
    },
    {
        id: 'github-theme',
        name: 'Github Theme',
        theme: {
            palette: {
                mode: 'dark',
                primary: {
                    main: '#1f6feb',
                    light: 'rgb(75, 139, 239)',
                    dark: 'rgb(21, 77, 164)',
                    contrastText: '#fff',
                },
                secondary: {
                    main: '#fa4549',
                    light: 'rgb(251, 106, 109)',
                    dark: 'rgb(175, 48, 51)',
                    contrastText: '#fff',
                },
                error: {
                    main: '#da3633',
                    light: 'rgb(225, 94, 91)',
                    dark: 'rgb(152, 37, 35)',
                    contrastText: '#fff',
                },
                warning: {
                    main: '#e3b341',
                    light: 'rgb(232, 194, 103)',
                    dark: 'rgb(158, 125, 45)',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                info: {
                    main: '#58a6ff',
                    light: 'rgb(121, 183, 255)',
                    dark: 'rgb(61, 116, 178)',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                success: {
                    main: '#33bb4b',
                    light: 'rgb(91, 200, 111)',
                    dark: 'rgb(35, 130, 52)',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                background: {
                    default: '#010409',
                    paper: '#0d1117',
                },
            },
            shape: {
                borderRadius: 6,
            },
            typography: {
                h1: {
                    fontFamily: 'Inter',
                },
                h2: {
                    fontFamily: 'Inter',
                },
                h3: {
                    fontFamily: 'Inter',
                },
                h4: {
                    fontFamily: 'Inter',
                },
                h5: {
                    fontFamily: 'Inter',
                },
                h6: {
                    fontFamily: 'Inter',
                },
                body1: {
                    fontFamily: 'Inter',
                },
                body2: {
                    fontFamily: 'Inter',
                },
                subtitle1: {
                    fontFamily: 'Inter',
                },
                subtitle2: {
                    fontFamily: 'Inter',
                },
                caption: {
                    fontFamily: 'Inter',
                },
                overline: {
                    fontFamily: 'Inter',
                },
            },
            components: {
                MuiButton: {
                    defaultProps: {
                        size: 'small',
                    },
                    variants: [
                        {
                            props: { variant: 'contained' },
                            style: {
                                'backgroundColor': '#21262D',
                                'border': '1px solid #363B42',
                                '&:hover': {
                                    backgroundColor: '#30363D',
                                    border: '1px solid #8B949E',
                                },
                            },
                        },
                        {
                            props: { variant: 'text' },
                            style: {
                                'color': '#C9D1D9',
                                '&:hover': {
                                    color: '#F0F6FC',
                                    backgroundColor: '#363B42',
                                },
                            },
                        },
                    ],
                },
                MuiCard: {
                    defaultProps: {
                        variant: 'outlined',
                    },
                },
                MuiDialog: {
                    styleOverrides: {
                        paper: {
                            backgroundImage: 'none',
                        },
                    },
                },
            },
        },
    },
    {
        id: 'gitbook-theme',
        name: 'GitBook Theme',
        theme: {
            breakpoints: {
                keys: ['xs', 'sm', 'md', 'lg', 'xl'],
                values: {
                    xs: 0,
                    sm: 600,
                    md: 900,
                    lg: 1200,
                    xl: 1536,
                },
                unit: 'px',
            },
            direction: 'ltr',
            components: {
                MuiCard: {
                    defaultProps: {
                        variant: 'outlined',
                    },
                },
                MuiDialog: {
                    styleOverrides: {
                        paper: {
                            backgroundImage: 'none',
                        },
                    },
                },
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            backgroundImage: 'none',
                        },
                    },
                },
            },
            palette: {
                mode: 'dark',
                background: {
                    default: '#14171c',
                    paper: '#22272e',
                },
                primary: {
                    main: '#b2a5ff',
                    light: 'rgb(193, 183, 255)',
                    dark: 'rgb(124, 115, 178)',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                secondary: {
                    main: '#FF4081',
                    light: 'rgb(255, 102, 154)',
                    dark: 'rgb(178, 44, 90)',
                    contrastText: '#fff',
                },
                error: {
                    main: '#d33d3d',
                    light: 'rgb(219, 99, 99)',
                    dark: 'rgb(147, 42, 42)',
                    contrastText: '#fff',
                },
                warning: {
                    main: '#b95e04',
                    light: 'rgb(199, 126, 54)',
                    dark: 'rgb(129, 65, 2)',
                    contrastText: '#fff',
                },
                info: {
                    main: '#307f98',
                    light: 'rgb(89, 152, 172)',
                    dark: 'rgb(33, 88, 106)',
                    contrastText: '#fff',
                },
                success: {
                    main: '#008847',
                    light: 'rgb(51, 159, 107)',
                    dark: 'rgb(0, 95, 49)',
                    contrastText: '#fff',
                },
                common: {
                    black: '#000',
                    white: '#fff',
                },
                grey: {
                    '50': '#fafafa',
                    '100': '#f5f5f5',
                    '200': '#eeeeee',
                    '300': '#e0e0e0',
                    '400': '#bdbdbd',
                    '500': '#9e9e9e',
                    '600': '#757575',
                    '700': '#616161',
                    '800': '#424242',
                    '900': '#212121',
                    'A100': '#f5f5f5',
                    'A200': '#eeeeee',
                    'A400': '#bdbdbd',
                    'A700': '#616161',
                },
                contrastThreshold: 3,
                tonalOffset: 0.2,
                divider: 'rgba(255,255,255,0.1)',
            },
            shape: {
                borderRadius: 4,
            },
            typography: {
                fontFamily: "'Noto Sans', 'Helvetica', 'Arial', sans-serif",
                htmlFontSize: 16,
                fontSize: 14,
                fontWeightLight: 300,
                fontWeightRegular: 400,
                fontWeightMedium: 500,
                fontWeightBold: 700,
                h1: {
                    fontFamily: "'Noto Sans', 'Helvetica', 'Arial', sans-serif",
                    fontWeight: 300,
                    fontSize: '6rem',
                    lineHeight: 1.167,
                },
                h2: {
                    fontFamily: "'Noto Sans', 'Helvetica', 'Arial', sans-serif",
                    fontWeight: 300,
                    fontSize: '3.75rem',
                    lineHeight: 1.2,
                },
                h3: {
                    fontFamily: "'Noto Sans', 'Helvetica', 'Arial', sans-serif",
                    fontWeight: 400,
                    fontSize: '3rem',
                    lineHeight: 1.167,
                },
                h4: {
                    fontFamily: "'Noto Sans', 'Helvetica', 'Arial', sans-serif",
                    fontWeight: 400,
                    fontSize: '2.125rem',
                    lineHeight: 1.235,
                },
                h5: {
                    fontFamily: "'Noto Sans', 'Helvetica', 'Arial', sans-serif",
                    fontWeight: 400,
                    fontSize: '1.5rem',
                    lineHeight: 1.334,
                },
                h6: {
                    fontFamily: "'Noto Sans', 'Helvetica', 'Arial', sans-serif",
                    fontWeight: 500,
                    fontSize: '1.25rem',
                    lineHeight: 1.6,
                },
                subtitle1: {
                    fontFamily: "'Noto Sans', 'Helvetica', 'Arial', sans-serif",
                    fontWeight: 400,
                    fontSize: '1rem',
                    lineHeight: 1.75,
                },
                subtitle2: {
                    fontFamily: "'Noto Sans', 'Helvetica', 'Arial', sans-serif",
                    fontWeight: 500,
                    fontSize: '0.875rem',
                    lineHeight: 1.57,
                },
                body1: {
                    fontFamily: "'Noto Sans', 'Helvetica', 'Arial', sans-serif",
                    fontWeight: 400,
                    fontSize: '1rem',
                    lineHeight: 1.5,
                },
                body2: {
                    fontFamily: "'Noto Sans', 'Helvetica', 'Arial', sans-serif",
                    fontWeight: 400,
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                },
                button: {
                    fontFamily: "'Noto Sans', 'Helvetica', 'Arial', sans-serif",
                    fontWeight: 600,
                },
                caption: {
                    fontFamily: "'Noto Sans', 'Helvetica', 'Arial', sans-serif",
                    fontWeight: 400,
                    fontSize: '0.75rem',
                    lineHeight: 1.66,
                },
                overline: {
                    fontFamily: "'Noto Sans', 'Helvetica', 'Arial', sans-serif",
                    fontWeight: 400,
                    fontSize: '0.75rem',
                    lineHeight: 2.66,
                    textTransform: 'uppercase',
                },
            },
            mixins: {
                toolbar: {
                    'minHeight': 56,
                    '@media (min-width:0px)': {
                        '@media (orientation: landscape)': {
                            minHeight: 48,
                        },
                    },
                    '@media (min-width:600px)': {
                        minHeight: 64,
                    },
                },
            },
            shadows: [
                'none',
                '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
                '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
                '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
                '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
                '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
                '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
                '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
                '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
                '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
                '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
                '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
                '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
                '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
                '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
                '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
                '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
                '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
                '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
                '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
                '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
                '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
                '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
            ],
            transitions: {
                easing: {
                    easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
                    easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
                    easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
                    sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
                },
                duration: {
                    shortest: 150,
                    shorter: 200,
                    short: 250,
                    standard: 300,
                    complex: 375,
                    enteringScreen: 225,
                    leavingScreen: 195,
                },
            },
            zIndex: {
                mobileStepper: 1000,
                fab: 1050,
                speedDial: 1050,
                appBar: 1100,
                drawer: 1200,
                modal: 1300,
                snackbar: 1400,
                tooltip: 1500,
            },
        },
    },
    {
        id: 'corporate-theme',
        name: 'Corporate Theme',
        theme: {
            palette: {
                mode: 'light',
                primary: {
                    main: '#023047',
                    light: 'rgb(52, 89, 107)',
                    dark: 'rgb(1, 33, 49)',
                    contrastText: '#fff',
                },
                secondary: {
                    main: '#ffb703',
                    light: 'rgb(255, 197, 53)',
                    dark: 'rgb(178, 128, 2)',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                error: {
                    main: '#e57373',
                    light: '#ed9595',
                    dark: '#bb5555',
                    contrastText: '#fff',
                },
                warning: {
                    main: '#ffb74d',
                    light: '#fac372',
                    dark: '#d89b3f',
                    contrastText: '#fff',
                },
                info: {
                    main: '#219ebc',
                    light: 'rgb(77, 177, 201)',
                    dark: 'rgb(23, 110, 131)',
                    contrastText: '#fff',
                },
                success: {
                    main: '#81c784',
                    light: '#9de3a0',
                    dark: '#619b64',
                    contrastText: '#fff',
                },
                background: { default: '#f5f5f5', paper: '#ffffff' },
            },
            shape: { borderRadius: 12 },
            typography: {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontSize: 14,
                fontWeightLight: 300,
                fontWeightRegular: 400,
                fontWeightMedium: 500,
            },
        },
    },
    {
        id: 'windows-95',
        name: 'Windows 95',
        theme: {
            palette: {
                mode: 'light',
                primary: {
                    main: '#008080',
                },
                secondary: {
                    main: '#008080',
                },
                error: {
                    main: '#ff0000',
                },
                warning: {
                    main: '#ffff00',
                },
                info: {
                    main: '#0000ff',
                },
                success: {
                    main: '#008000',
                },
                background: {
                    default: '#008081',
                    paper: '#C0C0C0',
                },
            },
            shape: {
                borderRadius: 0,
            },
            typography: {
                fontFamily: 'Arial',
            },
            components: {
                MuiButton: {
                    defaultProps: {
                        disableElevation: true,
                        disableTouchRipple: true,
                    },
                    styleOverrides: {
                        root: {
                            'position': 'relative',
                            'transition': 'all 0.2s ease',
                            'letterSpacing': '0.025em',
                            'fontFamily': 'Arial',
                            'borderRadius': '0.125rem',
                            'padding': '0.6rem 2rem',
                            'maxHeight': '37px',
                            'border': '1px solid #a0a0a0',

                            '&::before': {
                                width: '100%',
                                height: '100%',
                                borderTop: '2px solid white',
                                borderLeft: '2px solid white',
                                borderRight: '2px solid #858585',
                                borderBottom: '2px solid #858585',
                                content: "''",
                                position: 'absolute',
                                boxSizing: 'border-box',
                                display: 'block',
                                background: 'transparent',
                                zIndex: 9,
                                top: 0,
                                left: 0,
                            },
                            '&::after': {
                                content: "''",
                                position: 'absolute',
                                boxSizing: 'border-box',
                                display: 'block',
                                background: 'transparent',
                                zIndex: 9,
                                top: 0,
                                left: 0,
                            },
                            '&:active': {
                                'borderColor': 'transparent',
                                '&::before': {
                                    borderColor: 'transparent',
                                    color: '#000',
                                },
                            },
                        },
                    },
                    variants: [
                        {
                            props: { variant: 'text' },
                            style: {
                                'color': '#000',
                                '&:hover': {
                                    color: '#000',
                                },
                            },
                        },
                        {
                            props: { variant: 'outlined' },
                            style: {
                                'border': '2px solid #000',
                                'color': '#000',
                                '&:hover': {
                                    border: '2px solid #000',
                                    color: '#000',
                                },
                            },
                        },
                        {
                            props: { color: 'primary' },
                            style: {
                                'backgroundColor': '#C0C0C0',
                                'color': '#000',
                                '&:hover': {
                                    backgroundColor: '#C0C0C0',
                                    color: '#000',
                                },
                            },
                        },
                        {
                            props: { color: 'secondary' },
                            style: {
                                'backgroundColor': '#000181',
                                'color': '#fff',
                                '&:hover': {
                                    backgroundColor: '#000181',
                                    color: '#fff',
                                },
                            },
                        },
                        {
                            props: { color: 'error' },
                            style: {
                                'backgroundColor': '#FA5252',
                                'color': '#fff',

                                '&:hover': {
                                    backgroundColor: '#FA5252',
                                    color: '#fff',
                                },
                            },
                        },
                        {
                            props: { color: 'warning' },
                            style: {
                                'backgroundColor': '#F5B759',
                                'color': '#fff',

                                '&:hover': {
                                    backgroundColor: '#F5B759',
                                    color: '#fff',
                                },
                            },
                        },
                        {
                            props: { color: 'info' },
                            style: {
                                'backgroundColor': '#1E90FF',
                                'color': '#fff',

                                '&:hover': {
                                    backgroundColor: '#1E90FF',
                                    color: '#fff',
                                },
                            },
                        },
                        {
                            props: { color: 'success' },
                            style: {
                                'backgroundColor': '#00BF9A',
                                'color': '#fff',

                                '&:hover': {
                                    backgroundColor: '#00BF9A',
                                    color: '#fff',
                                },
                            },
                        },
                    ],
                },
                MuiListItemButton: {
                    styleOverrides: {
                        root: {
                            'position': 'relative',
                            'transition': 'all 0.2s ease',
                            'letterSpacing': '0.025em',
                            'fontFamily': 'Arial',
                            'borderRadius': '0.125rem',
                            'border': '1px solid #a0a0a0',

                            '&::before': {
                                width: '100%',
                                height: '100%',
                                borderTop: '2px solid white',
                                borderLeft: '2px solid white',
                                borderRight: '2px solid #858585',
                                borderBottom: '2px solid #858585',
                                content: "''",
                                position: 'absolute',
                                boxSizing: 'border-box',
                                display: 'block',
                                background: 'transparent',
                                zIndex: 9,
                                top: 0,
                                left: 0,
                            },
                            '&::after': {
                                content: "''",
                                position: 'absolute',
                                boxSizing: 'border-box',
                                display: 'block',
                                background: 'transparent',
                                zIndex: 9,
                                top: 0,
                                left: 0,
                            },
                            '&:active': {
                                'borderColor': 'transparent',
                                '&::before': {
                                    borderColor: 'transparent',
                                    color: '#000',
                                },
                            },
                            '&:hover': {
                                backgroundColor: '#C0C0C0',
                            },
                        },
                    },
                },
                MuiButtonBase: {
                    defaultProps: {
                        disableTouchRipple: true,
                    },
                },
                MuiPaper: {
                    defaultProps: {
                        elevation: 0,
                    },
                    styleOverrides: {
                        root: {
                            background: '#C0C0C0',
                            border: '1px solid #000',
                            borderRadius: 0,
                            backgroundImage: 'none',
                            borderTop: '2px solid white',
                            borderLeft: '2px solid white',
                            borderRight: '2px solid #393939',
                            borderBottom: '2px solid #393939',
                        },
                    },
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            borderTop: '2px solid white',
                            borderLeft: '2px solid white',
                            borderRight: '2px solid #393939',
                            borderBottom: '2px solid #393939',
                        },
                    },
                },
                MuiCardHeader: {
                    styleOverrides: {
                        root: {
                            background: '#000181',
                            color: '#fff',
                            padding: '4px',
                            border: '2px solid #BDBDBD',
                        },
                    },
                },
                MuiCardContent: {
                    styleOverrides: {
                        root: {
                            padding: '16px',
                        },
                    },
                },
                MuiDialogTitle: {
                    styleOverrides: {
                        root: {
                            background: '#000181',
                            color: '#fff',
                            padding: '4px',
                            border: '2px solid #BDBDBD',
                        },
                    },
                },
                MuiChip: {
                    styleOverrides: {
                        root: {
                            color: '#000',
                            border: '1px solid #000',
                            borderRadius: 0,
                        },
                    },
                },
                MuiInputLabel: {
                    styleOverrides: {
                        root: {
                            display: 'none',
                        },
                    },
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            background: 'white',
                            color: 'black',
                            borderRadius: 0,
                            margin: 1,
                        },
                    },
                },
                MuiOutlinedInput: {
                    defaultProps: {
                        notched: false,
                    },
                    styleOverrides: {
                        root: {
                            color: 'black',
                            borderColor: '#4A4A4A',
                            boxShadow: 'none',
                            borderRadius: 0,
                        },
                    },
                },
            },
        },
    },
    {
        id: 'modern-theme',
        name: 'Modern Theme',
        theme: {
            breakpoints: {
                keys: ['xs', 'sm', 'md', 'lg', 'xl'],
                values: {
                    xs: 0,
                    sm: 600,
                    md: 900,
                    lg: 1200,
                    xl: 1536,
                },
                unit: 'px',
            },
            direction: 'ltr',
            components: {
                MuiAccordion: {
                    defaultProps: {
                        elevation: 0,
                        disableGutters: true,
                    },
                    styleOverrides: {
                        root: {
                            'padding': 8,
                            'overflow': 'clip',
                            'backgroundColor': '#090E10',
                            'border': '1px solid',
                            'borderColor': '#131B20',
                            ':before': {
                                backgroundColor: 'transparent',
                            },
                            '&:first-of-type': {
                                borderTopLeftRadius: 10,
                                borderTopRightRadius: 10,
                            },
                            '&:last-of-type': {
                                borderBottomLeftRadius: 10,
                                borderBottomRightRadius: 10,
                            },
                        },
                    },
                },
                MuiAccordionSummary: {
                    styleOverrides: {
                        root: {
                            'border': 'none',
                            'borderRadius': 8,
                            '&:hover': {
                                backgroundColor: '#131B20',
                            },
                        },
                    },
                },
                MuiAccordionDetails: {
                    styleOverrides: {
                        root: {
                            mb: 20,
                            border: 'none',
                        },
                    },
                },
                MuiToggleButtonGroup: {
                    styleOverrides: {
                        root: {
                            'borderRadius': '10px',
                            'boxShadow': '0 4px 16px rgba(3, 51, 99, 0.5)',
                            '& .Mui-selected': {
                                color: '#fff',
                            },
                        },
                    },
                },
                MuiToggleButton: {
                    styleOverrides: {
                        root: {
                            'padding': '12px 16px',
                            'textTransform': 'none',
                            'borderRadius': '10px',
                            'fontWeight': 500,
                            'color': '#94A6B8',
                            'boxShadow': '0 4px 16px rgba(0, 0, 0, 0.5)',
                            '&.Mui-selected': {
                                color: '#55A6F6',
                            },
                        },
                    },
                },
                MuiButtonBase: {
                    defaultProps: {
                        disableTouchRipple: true,
                        disableRipple: true,
                    },
                    styleOverrides: {
                        root: {
                            'boxSizing': 'border-box',
                            'transition': 'all 100ms ease-in',
                            '&:focus-visible': {
                                outline: '3px solid rgba(10, 102, 194, 0.5)',
                                outlineOffset: '2px',
                            },
                        },
                    },
                },
                MuiButton: {
                    styleOverrides: {
                        root: {
                            'boxSizing': 'border-box',
                            'boxShadow': 'none',
                            'borderRadius': '10px',
                            'textTransform': 'none',
                            '&:active': {
                                transform: 'scale(0.98)',
                            },
                        },
                    },
                    variants: [
                        {
                            props: {
                                variant: 'contained',
                                color: 'primary',
                            },
                            style: {
                                'color': '#F0F7FF',
                                'background': '#0959AA',
                                'backgroundImage': 'linear-gradient(to bottom, #0A66C2, #064079)',
                                'boxShadow': 'inset 0 1px rgba(85, 166, 246,0.4)',
                                '&:hover': {
                                    backgroundColor: '#0A66C2',
                                    backgroundImage: 'none',
                                    boxShadow: '0 0 0 1px rgba(85, 166, 246, 0.5)',
                                },
                            },
                        },
                        {
                            props: {
                                variant: 'contained',
                                color: 'secondary',
                            },
                            style: {
                                'color': '#F0F7FF',
                                'background': '#6709AA',
                                'backgroundImage': 'linear-gradient(to bottom, #750AC2, #490679)',
                                'boxShadow': 'inset 0 1px rgba(179, 85, 246, 0.4)',
                                '&:hover': {
                                    backgroundColor: '#750AC2',
                                    backgroundImage: 'none',
                                    boxShadow: '0 0 0 1px rgba(179, 85, 246, 0.5)',
                                },
                            },
                        },
                        {
                            props: {
                                variant: 'contained',
                                color: 'error',
                            },
                            style: {
                                'color': '#F0F7FF',
                                'background': '#AA0303',
                                'backgroundImage': 'linear-gradient(to bottom, #C70A0A, #490202)',
                                'boxShadow': 'inset 0 1px rgba(179, 85, 85, 0.4)',
                                '&:hover': {
                                    backgroundColor: '#C70A0A',
                                    backgroundImage: 'none',
                                    boxShadow: '0 0 0 1px rgba(179, 85, 85, 0.5)',
                                },
                            },
                        },
                        {
                            props: {
                                variant: 'contained',
                                color: 'warning',
                            },
                            style: {
                                'color': '#F0F7FF',
                                'background': '#AAAA03',
                                'backgroundImage': 'linear-gradient(to bottom, #C7C70A, #494902)',
                                'boxShadow': 'inset 0 1px rgba(179, 179, 85, 0.4)',
                                '&:hover': {
                                    backgroundColor: '#C7C70A',
                                    backgroundImage: 'none',
                                    boxShadow: '0 0 0 1px rgba(179, 179, 85, 0.5)',
                                },
                            },
                        },
                        {
                            props: {
                                variant: 'contained',
                                color: 'info',
                            },
                            style: {
                                'color': '#F0F7FF',
                                'background': '#033B3B',
                                'backgroundImage': 'linear-gradient(to bottom, #064079, #033363)',
                                'boxShadow': 'inset 0 1px rgba(85, 166, 246, 0.4)',
                                '&:hover': {
                                    backgroundColor: '#064079',
                                    backgroundImage: 'none',
                                    boxShadow: '0 0 0 1px rgba(85, 166, 246, 0.5)',
                                },
                            },
                        },
                        {
                            props: {
                                variant: 'contained',
                                color: 'success',
                            },
                            style: {
                                'color': '#F0F7FF',
                                'background': '#036303',
                                'backgroundImage': 'linear-gradient(to bottom, #136C13, #034903)',
                                'boxShadow': 'inset 0 1px rgba(85, 166, 85, 0.4)',
                                '&:hover': {
                                    backgroundColor: '#136C13',
                                    backgroundImage: 'none',
                                    boxShadow: '0 0 0 1px rgba(85, 166, 85, 0.5)',
                                },
                            },
                        },
                        {
                            props: {
                                variant: 'outlined',
                                color: 'primary',
                            },
                            style: {
                                'backgroundColor': 'rgba(85, 166, 246, 0.1)',
                                'borderColor': '#55a6f6',
                                'color': '#9CCCFC',
                                '&:hover': {
                                    backgroundColor: 'rgba(85, 166, 246, 0.3)',
                                    borderColor: '#9CCCFC',
                                },
                            },
                        },
                        {
                            props: {
                                variant: 'outlined',
                                color: 'secondary',
                            },
                            style: {
                                'backgroundColor': 'rgba(179, 85, 246, 0.1)',
                                'borderColor': '#B355F6',
                                'color': '#D49CFC',
                                '&:hover': {
                                    backgroundColor: 'rgba(179, 85, 246, 0.3)',
                                    borderColor: '#D49CFC',
                                },
                            },
                        },
                        {
                            props: {
                                variant: 'outlined',
                                color: 'error',
                            },
                            style: {
                                'backgroundColor': 'rgba(179, 85, 85, 0.1)',
                                'borderColor': '#EB5151',
                                'color': '#F1A1A1',
                                '&:hover': {
                                    backgroundColor: 'rgba(179, 85, 85, 0.3)',
                                    borderColor: '#F1A1A1',
                                },
                            },
                        },
                        {
                            props: {
                                variant: 'outlined',
                                color: 'warning',
                            },
                            style: {
                                'backgroundColor': 'rgba(179, 179, 85, 0.1)',
                                'borderColor': '#FBEA3D',
                                'color': '#FCEA7A',
                                '&:hover': {
                                    backgroundColor: 'rgba(179, 179, 85, 0.3)',
                                    borderColor: '#FCEA7A',
                                },
                            },
                        },
                        {
                            props: {
                                variant: 'outlined',
                                color: 'info',
                            },
                            style: {
                                'backgroundColor': 'rgba(85, 166, 246, 0.1)',
                                'borderColor': '#5179C2',
                                'color': '#A1B3F6',
                                '&:hover': {
                                    backgroundColor: 'rgba(85, 166, 246, 0.3)',
                                    borderColor: '#A1B3F6',
                                },
                            },
                        },
                        {
                            props: {
                                variant: 'outlined',
                                color: 'success',
                            },
                            style: {
                                'backgroundColor': 'rgba(85, 166, 85, 0.1)',
                                'borderColor': '#A1E8A1',
                                'color': '#C7F7C7',
                                '&:hover': {
                                    backgroundColor: 'rgba(85, 166, 85, 0.3)',
                                    borderColor: '#C7F7C7',
                                },
                            },
                        },
                        {
                            props: {
                                variant: 'text',
                                color: 'primary',
                            },
                            style: {
                                'color': '#9CCCFC',
                                '&:hover': {
                                    backgroundColor: 'rgba(85, 166, 246, 0.3)',
                                },
                            },
                        },
                        {
                            props: {
                                variant: 'text',
                                color: 'secondary',
                            },
                            style: {
                                'color': '#D49CFC',
                                '&:hover': {
                                    backgroundColor: 'rgba(179, 85, 246, 0.3)',
                                },
                            },
                        },
                        {
                            props: {
                                variant: 'text',
                                color: 'error',
                            },
                            style: {
                                'color': '#F1A1A1',
                                '&:hover': {
                                    backgroundColor: 'rgba(179, 85, 85, 0.3)',
                                },
                            },
                        },
                        {
                            props: {
                                variant: 'text',
                                color: 'warning',
                            },
                            style: {
                                'color': '#FCEA7A',
                                '&:hover': {
                                    backgroundColor: 'rgba(179, 179, 85, 0.3)',
                                },
                            },
                        },
                        {
                            props: {
                                variant: 'text',
                                color: 'info',
                            },
                            style: {
                                'color': '#A1B3F6',
                                '&:hover': {
                                    backgroundColor: 'rgba(85, 166, 246, 0.3)',
                                },
                            },
                        },
                        {
                            props: {
                                variant: 'text',
                                color: 'success',
                            },
                            style: {
                                'color': '#C7F7C7',
                                '&:hover': {
                                    backgroundColor: 'rgba(85, 166, 85, 0.3)',
                                },
                            },
                        },
                    ],
                },
                MuiCard: {
                    styleOverrides: {
                        root: {
                            backgroundColor: 'rgba(19, 27, 32, 0.6)',
                            borderRadius: 10,
                            border: '1px solid rgba(54, 64, 73, 0.3)',
                            boxShadow: 'none',
                            transition: 'background-color, border, 80ms ease',
                        },
                    },
                    variants: [
                        {
                            props: {
                                variant: 'outlined',
                            },
                            style: {
                                'background': 'linear-gradient(to bottom, #090E10, rgba(19, 27, 32, 0.5))',
                                '&:hover': {
                                    borderColor: '#033363',
                                    boxShadow: '0 0 24px #02294F',
                                },
                            },
                        },
                    ],
                },
                MuiChip: {
                    defaultProps: {
                        color: 'primary',
                    },
                    styleOverrides: {
                        root: {
                            alignSelf: 'center',
                            py: 1.5,
                            px: 0.5,
                            background: 'linear-gradient(to bottom right, #033363, #021F3B)',
                            border: '1px solid',
                            fontWeight: 600,
                        },
                    },
                    variants: [
                        {
                            props: {
                                color: 'primary',
                            },
                            style: {
                                'background': 'linear-gradient(to bottom right, #033363, #021F3B)',
                                'borderColor': 'rgba(9, 89, 170, 0.5)',
                                '&:hover': {
                                    backgroundColor: '#064079',
                                },
                                '&:focus-visible': {
                                    borderColor: '#9CCCFC',
                                    backgroundColor: '#064079',
                                },
                                '& .MuiChip-label': {
                                    color: '#9CCCFC',
                                },
                                '& .MuiChip-icon': {
                                    color: '#9CCCFC',
                                },
                            },
                        },
                        {
                            props: {
                                color: 'secondary',
                            },
                            style: {
                                'background': 'linear-gradient(to bottom right, #3B0363, #23023B)',
                                'borderColor': 'rgba(103, 9, 170, 0.5)',
                                '&:hover': {
                                    backgroundColor: '#490679',
                                },
                                '&:focus-visible': {
                                    borderColor: '#D49CFC',
                                    backgroundColor: '#490679',
                                },
                                '& .MuiChip-label': {
                                    color: '#D49CFC',
                                },
                                '& .MuiChip-icon': {
                                    color: '#D49CFC',
                                },
                            },
                        },
                        {
                            props: {
                                color: 'error',
                            },
                            style: {
                                'background': 'linear-gradient(to bottom right, #3B0303, #230202)',
                                'borderColor': 'rgba(179, 85, 85, 0.5)',
                                '&:hover': {
                                    backgroundColor: '#490202',
                                },
                                '&:focus-visible': {
                                    borderColor: '#FCD49C',
                                    backgroundColor: '#490202',
                                },
                                '& .MuiChip-label': {
                                    color: '#FCD49C',
                                },
                                '& .MuiChip-icon': {
                                    color: '#FCD49C',
                                },
                            },
                        },
                        {
                            props: {
                                color: 'warning',
                            },
                            style: {
                                'background': 'linear-gradient(to bottom right, #3B3B03, #232302)',
                                'borderColor': 'rgba(179, 179, 85, 0.5)',
                                '&:hover': {
                                    backgroundColor: '#494902',
                                },
                                '&:focus-visible': {
                                    borderColor: '#FCD49C',
                                    backgroundColor: '#494902',
                                },
                                '& .MuiChip-label': {
                                    color: '#FCD49C',
                                },
                                '& .MuiChip-icon': {
                                    color: '#FCD49C',
                                },
                            },
                        },
                        {
                            props: {
                                color: 'info',
                            },
                            style: {
                                'background': 'linear-gradient(to bottom right, #03333B, #021F23)',
                                'borderColor': 'rgba(85, 166, 246, 0.5)',
                                '&:hover': {
                                    backgroundColor: '#064079',
                                },
                                '&:focus-visible': {
                                    borderColor: '#9CCCFC',
                                    backgroundColor: '#064079',
                                },
                                '& .MuiChip-label': {
                                    color: '#9CCCFC',
                                },
                                '& .MuiChip-icon': {
                                    color: '#9CCCFC',
                                },
                            },
                        },
                        {
                            props: {
                                color: 'success',
                            },
                            style: {
                                'background': 'linear-gradient(to bottom right, #033303, #021F02)',
                                'borderColor': 'rgba(85, 166, 85, 0.5)',
                                '&:hover': {
                                    backgroundColor: '#136C13',
                                },
                                '&:focus-visible': {
                                    borderColor: '#C7F7C7',
                                    backgroundColor: '#136C13',
                                },
                                '& .MuiChip-label': {
                                    color: '#C7F7C7',
                                },
                                '& .MuiChip-icon': {
                                    color: '#C7F7C7',
                                },
                            },
                        },
                    ],
                },
                MuiDivider: {
                    styleOverrides: {
                        root: {
                            borderColor: 'rgba(54, 64, 73, 0.4)',
                        },
                    },
                },
                MuiLink: {
                    defaultProps: {
                        underline: 'none',
                    },
                    styleOverrides: {
                        root: {
                            'color': '#9CCCFC',
                            'fontWeight': 500,
                            'position': 'relative',
                            'textDecoration': 'none',
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                width: 0,
                                height: '1px',
                                bottom: 0,
                                left: 0,
                                backgroundColor: '#9CCCFC',
                                opacity: 0.7,
                                transition: 'width 0.3s ease, opacity 0.3s ease',
                            },
                            '&:hover::before': {
                                width: '100%',
                                opacity: 1,
                            },
                        },
                    },
                },
                MuiMenuItem: {
                    styleOverrides: {
                        root: {
                            borderRadius: '99px',
                            color: '#94A6B8',
                            fontWeight: 500,
                        },
                    },
                },
                MuiPaper: {
                    styleOverrides: {
                        root: {
                            backgroundImage: 'none',
                            backgroundColor: 'rgba(9, 14, 16, 1)',
                        },
                    },
                },
                MuiDialog: {
                    defaultProps: {
                        PaperProps: {
                            sx: {
                                border: '1px solid #333d47',
                            },
                        },
                    },
                    variants: [
                        {
                            props: { fullScreen: true },
                            style: {
                                '& .MuiDialog-paper': {
                                    border: 'none',
                                },
                            },
                        },
                    ],
                },
                MuiSwitch: {
                    styleOverrides: {
                        root: {
                            'width': 36,
                            'height': 24,
                            'padding': 0,
                            'transition': 'background-color 100ms ease-in',
                            '&:hover .MuiSwitch-track': {
                                backgroundColor: '#064079',
                            },
                            '& .MuiSwitch-switchBase.Mui-checked': {
                                transform: 'translateX(13px)',
                            },
                            '& .MuiSwitch-track': {
                                borderRadius: 50,
                            },
                            '& .MuiSwitch-thumb': {
                                boxShadow: '0 0 2px 2px rgba(0, 0, 0, 0.2)',
                                backgroundColor: '#FFF',
                                width: 16,
                                height: 16,
                                margin: 2,
                            },
                        },
                        switchBase: {
                            'height': 24,
                            'width': 24,
                            'padding': 0,
                            'color': '#fff',
                            '&.Mui-checked + .MuiSwitch-track': {
                                opacity: 1,
                            },
                        },
                    },
                },
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '& label.Mui-focused': {
                                color: 'white',
                            },
                            '& .MuiInputBase-input': {
                                'boxSizing': 'border-box',
                                '&::placeholder': {
                                    opacity: 0.7,
                                },
                            },
                            '& .MuiOutlinedInput-root': {
                                'boxSizing': 'border-box',
                                'minWidth': 280,
                                'minHeight': 40,
                                'height': '100%',
                                'borderRadius': '10px',
                                'border': '1px solid',
                                'borderColor': '#4C5967',
                                'transition': 'border-color 120ms ease-in',
                                '& fieldset': {
                                    border: 'none',
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)',
                                    background: 'rgba(19, 27, 32, 0.4)',
                                },
                                '&:hover': {
                                    borderColor: '#55A6F6',
                                },
                                '&.Mui-focused': {
                                    borderColor: '#0A66C2',
                                    outline: '4px solid',
                                    outlineColor: 'rgba(9, 102, 170, 0.5)',
                                },
                            },
                        },
                    },
                },
            },
            palette: {
                mode: 'dark',
                primary: {
                    light: '#9CCCFC',
                    main: '#0959AA',
                    dark: '#02294F',
                    contrastText: '#CEE5FD',
                },
                secondary: {
                    light: '#B355F6',
                    main: '#6709AA',
                    dark: '#2F024F',
                    contrastText: '#fff',
                },
                warning: {
                    main: '#F7B538',
                    dark: '#F79F00',
                    light: 'rgb(248, 195, 95)',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                error: {
                    light: '#D32F2F',
                    main: '#D32F2F',
                    dark: '#B22A2A',
                    contrastText: '#fff',
                },
                success: {
                    light: '#51BC51',
                    main: '#1F7A1F',
                    dark: '#0A470A',
                    contrastText: '#fff',
                },
                grey: {
                    '50': '#FBFCFE',
                    '100': '#EAF0F5',
                    '200': '#D6E2EB',
                    '300': '#BFCCD9',
                    '400': '#94A6B8',
                    '500': '#5B6B7C',
                    '600': '#4C5967',
                    '700': '#364049',
                    '800': '#131B20',
                    '900': '#090E10',
                    'A100': '#f5f5f5',
                    'A200': '#eeeeee',
                    'A400': '#bdbdbd',
                    'A700': '#616161',
                },
                divider: 'rgba(76, 89, 103, 0.3)',
                background: {
                    default: '#090E10',
                    paper: '#131B20',
                },
                action: {
                    selected: 'rgba(2, 41, 79, 0.2)',
                    active: '#fff',
                    hover: 'rgba(255, 255, 255, 0.08)',
                    hoverOpacity: 0.08,
                    selectedOpacity: 0.16,
                    disabled: 'rgba(255, 255, 255, 0.3)',
                    disabledBackground: 'rgba(255, 255, 255, 0.12)',
                    disabledOpacity: 0.38,
                    focus: 'rgba(255, 255, 255, 0.12)',
                    focusOpacity: 0.12,
                    activatedOpacity: 0.24,
                },
                common: {
                    black: '#000',
                    white: '#fff',
                },
                info: {
                    main: '#29b6f6',
                    light: '#4fc3f7',
                    dark: '#0288d1',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                contrastThreshold: 3,
                tonalOffset: 0.2,
            },
            shape: {
                borderRadius: 4,
            },
            typography: {
                fontFamily: 'Inter',
                h1: {
                    fontSize: 60,
                    fontWeight: 600,
                    lineHeight: 1.1142857142857143,
                    letterSpacing: -0.2,
                    fontFamily: 'Inter',
                },
                h2: {
                    fontSize: 48,
                    fontWeight: 600,
                    lineHeight: 1.2,
                    fontFamily: 'Inter',
                },
                h3: {
                    fontSize: 42,
                    lineHeight: 1.2,
                    fontFamily: 'Inter',
                    fontWeight: 400,
                },
                h4: {
                    fontSize: 36,
                    fontWeight: 500,
                    lineHeight: 1.5,
                    fontFamily: 'Inter',
                },
                h5: {
                    fontSize: 20,
                    fontWeight: 600,
                    fontFamily: 'Inter',
                    lineHeight: 1.334,
                },
                h6: {
                    fontSize: 18,
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    lineHeight: 1.6,
                },
                subtitle1: {
                    fontSize: 18,
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    lineHeight: 1.75,
                },
                subtitle2: {
                    fontSize: 16,
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    lineHeight: 1.57,
                },
                body1: {
                    fontWeight: 400,
                    fontSize: 15,
                    fontFamily: 'Inter',
                    lineHeight: 1.5,
                },
                body2: {
                    fontWeight: 400,
                    fontSize: 14,
                    fontFamily: 'Inter',
                    lineHeight: 1.43,
                },
                caption: {
                    fontWeight: 400,
                    fontSize: 12,
                    fontFamily: 'Inter',
                    lineHeight: 1.66,
                },
                htmlFontSize: 16,
                fontSize: 14,
                fontWeightLight: 300,
                fontWeightRegular: 400,
                fontWeightMedium: 500,
                fontWeightBold: 700,
                button: {
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    fontSize: '0.875rem',
                    lineHeight: 1.75,
                    textTransform: 'uppercase',
                },
                overline: {
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    fontSize: '0.75rem',
                    lineHeight: 2.66,
                    textTransform: 'uppercase',
                },
            },
            mixins: {
                toolbar: {
                    'minHeight': 56,
                    '@media (min-width:0px)': {
                        '@media (orientation: landscape)': {
                            minHeight: 48,
                        },
                    },
                    '@media (min-width:600px)': {
                        minHeight: 64,
                    },
                },
            },
            shadows: [
                'none',
                '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
                '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
                '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
                '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
                '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
                '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
                '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
                '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
                '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
                '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
                '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
                '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
                '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
                '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
                '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
                '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
                '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
                '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
                '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
                '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
                '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
                '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
                '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
            ],
            transitions: {
                easing: {
                    easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
                    easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
                    easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
                    sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
                },
                duration: {
                    shortest: 150,
                    shorter: 200,
                    short: 250,
                    standard: 300,
                    complex: 375,
                    enteringScreen: 225,
                    leavingScreen: 195,
                },
            },
            zIndex: {
                mobileStepper: 1000,
                fab: 1050,
                speedDial: 1050,
                appBar: 1100,
                drawer: 1200,
                modal: 1300,
                snackbar: 1400,
                tooltip: 1500,
            },
        },
    },
];
