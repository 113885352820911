import { Breadcrumbs, Grid, Link } from '@mui/material';
import { PageContainer } from '@nexdynamic/nex-ui-react';
import { useNavigate } from 'react-router';
import Text from '../../../../components/Text';
import CampaignForm from '../components/forms/CampaignForm';
import type { CampaignCreateFields } from '../hooks/useCampaignCreate';
import useCampaignCreate from '../hooks/useCampaignCreate';

const CreateCampaign = () => {
    const navigate = useNavigate();
    const { campaignCreate } = useCampaignCreate();

    const create = async (fields: CampaignCreateFields) => {
        const campaign = await campaignCreate(fields);
        if (campaign) navigate(`/send/campaigns/${campaign._id}`);
    };

    return (
        <PageContainer>
            <Grid item xs={12}>
                <Breadcrumbs aria-label='breadcrumb'>
                    <Link sx={{ cursor: 'pointer' }} component='button' underline='none' onClick={() => navigate('/send/campaigns')}>
                        <Text variant='subtitle1' text='campaigns.title' />
                    </Link>
                    <Text variant='subtitle1' color='text.primary' text='campaigns.create-action' />
                </Breadcrumbs>
            </Grid>

            <Grid item xs={12}>
                <CampaignForm onSubmit={create} />
            </Grid>
        </PageContainer>
    );
};

export default CreateCampaign;
