import { Box, Button, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ApplicationState } from '../../ApplicationState';

export const SimpleDateTimePickerComponent = ({
    dateSelected,
    okCallback,
    cancelCallback,
    preselectedDate,
    direction = 'forwards',
    targetDate,
    sendGrid72HourLimit,
}: {
    dateSelected: (timestamp: number) => void;
    okCallback: (timestamp: number) => void;
    cancelCallback: () => void;
    preselectedDate?: number;
    direction?: 'forwards' | 'backwards';
    targetDate?: Date;
    sendGrid72HourLimit?: moment.Moment | undefined;
}) => {
    const [selectedDate, setSelectedDate] = useState<number | null>(preselectedDate || moment().valueOf());

    useEffect(() => {
        setSelectedDate(preselectedDate || moment().valueOf());
    }, [preselectedDate]);

    const handleDateChange = (dateAsTimestampMilliseconds: number | null) => {
        dateSelected(dateAsTimestampMilliseconds || moment().valueOf());
        setSelectedDate(dateAsTimestampMilliseconds);
    };

    const disableDate = (day: Date) => {
        if (sendGrid72HourLimit) {
            if (moment(day).isAfter(sendGrid72HourLimit, 'day')) {
                return true;
            }
        }
        if (direction === 'forwards') {
            return moment(day).isBefore(moment(targetDate), 'day');
        } else if (direction === 'backwards') {
            return moment(day).isAfter(moment(targetDate), 'day') || moment(day).isBefore(moment(), 'day');
        } else {
            return false;
        }
    };

    const disableTime = (date: Date) => {
        if (sendGrid72HourLimit) {
            if (moment(date).isAfter(sendGrid72HourLimit, 'minute')) {
                return true;
            }
        }
        return moment(date).isBefore(moment(), 'minute');
    };

    const disableButton = sendGrid72HourLimit ? disableTime(moment(selectedDate).toDate()) : false;
    const disabledDueTosendGrid72HourLimitMessage = ApplicationState.localise('schedule-message.sendgrid-72-hour-limit-message');
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box sx={{ py: 2, width: '100%' }}>
                <DateTimePicker
                    label="Date and Time"
                    value={moment(selectedDate) as any}
                    onChange={handleDateChange}
                    sx={{ width: '100%' }}
                    shouldDisableDate={disableDate}
                    shouldDisableTime={disableTime}
                />
                {disableButton && (
                    <Typography color="error" mt={0.5} mx={0.5}>
                        {disabledDueTosendGrid72HourLimitMessage}
                    </Typography>
                )}
            </Box>

            <Button
                variant="contained"
                onClick={() => (selectedDate ? okCallback(moment(selectedDate).valueOf()) : cancelCallback())}
                disableElevation
                disabled={disableButton}
            >
                Select
            </Button>
        </LocalizationProvider>
    );
};
