import { Button, Typography } from '@mui/material';
import type { IAdvertisement, ISqueegeeAdvertAsset } from '@nexdynamic/squeegee-common';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Logger } from '../../../Logger';

import { AdvertisementAssetCache, createAdvertisement } from '../services/AdvertisementService';


export const SellQuote = () => {
    const [statusMessage, setStatusMessage] = useState<string | null>('Creating advert');
    const [searchParams] = useSearchParams();
    const quoteIds = searchParams.get('quotedJobIds');
    const quotedIds = quoteIds?.split(',') || [];
    const type = searchParams.get('type') as 'quoted-lead' | 'lead';
    const customerId = searchParams.get('customerId') || '';
    const notes = searchParams.get('notes') || '';
    const navigate = useNavigate();
    const handleSuccess = (advert: IAdvertisement) => {
        navigate(`/marketplace/my-adverts/${advert.id}`);
        const assetIds = advert.squeegeeAssets?.map(x => x.id) || [];
        if (assetIds.length) AdvertisementAssetCache.addItems(assetIds);
    };

    const handleFailed = (err: Error) => {
        Logger.error(err.message);
        setStatusMessage(`Uh oh there was an error creating an advert for these jobs.`);
    };

    useEffect(() => {
        const attachSqueegeeAssets = quotedIds.map(x => ({ id: x, type, customerId } as ISqueegeeAdvertAsset));

        const title = type === 'lead' ? 'Quote Request' : 'Quoted Lead';
        let description = type === 'lead' ? 'Customer request for a quote' : 'Quoted customer job';
        if (notes) description += `\n\n${notes}`;

        createAdvertisement({
            title,
            description,
            price: 0,
            tags: [type === 'lead' ? 'quote request' : 'quote'],
            type: 'squeegee-assets',
            attachSqueegeeAssets,
        })
            .then(x => handleSuccess(x.advertisements[0]))
            .catch(handleFailed);
    }, [quotedIds]);

    if (!quoteIds) return <div>Quote Ids not found</div>;

    return (
        <div>
            {statusMessage && <Typography padding={1}>{statusMessage}</Typography>}
            <Button onClick={() => navigate('/marketplace/my-adverts')}>Go to My Adverts</Button>
        </div>
    );
};
