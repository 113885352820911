import type { Quote, QuoteState } from '@nexdynamic/squeegee-common';
import { searchQuotes } from '@nexdynamic/squeegee-common';
import type { FilterItemDictionary, IFilterItem, IOption } from '../Filters/Filter';
import { Filter } from '../Filters/Filter';
import { Logger } from '../Logger';

/**
 * Determines wether or not the passed quote should be included given the state filter
 * @param {Quote} quote
 * @param {Filter<IQuotesFilterItemDictionary>} filter
 * @return {*}  {boolean}
 */
const stateFilter = (quote: Quote, filter: Filter<IQuotesFilterItemDictionary>): boolean => {
    const filterOptions = filter.filterOptions.state.selectedOptions || [];
    return filterOptions.some(option => option.value === quote.state);
};

const sentFilter = (quote: Quote, filter: Filter<IQuotesFilterItemDictionary>): boolean => {
    const filterOptions = filter.filterOptions.sent.selectedOptions || [];
    const sent = !!quote.sentDate;
    if (!filterOptions.length) return true;
    if (filterOptions.map(x => x.value).includes('yes') && sent) return true;
    if (filterOptions.map(x => x.value).includes('no') && !sent) return true;
    return false;
};

/**
 *  Sorts the passed quotes based of the current filter sort by options
 * TODO DUE TO A STUPID AURELIA BUG WE HAVE TO SET FILTERED ATTACHMENTS TO A NEW INSTANCE OF THE ARRAY THUS CAUSING ALL BINDINGS TO FIRE AGIAN -_- CHECK AGAIN WHEN SQUEEGEE NEXT IS MERGED
 * @param {Array<Quote>} filteredQuotes
 * @param {Filter<IQuotesFilterItemDictionary>} filter
 * @return {*}  {Array<Quote>}
 */
export const sortList = (filteredQuotes: Array<Quote>, filter: Filter<IQuotesFilterItemDictionary>): Array<Quote> => {
    const sortOption = filter.filterOptions.sortBy.selectedOption;

    if (sortOption) {
        const key = sortOption.propertyKey;
        if (key) {
            return filteredQuotes.slice(0).sort((a, b) => {
                const aValue = a[key];
                const bValue = b[key];
                if (aValue && bValue) {
                    if (typeof aValue === 'number' && typeof bValue === 'number')
                        return sortOption.reverse ? bValue - aValue : aValue - bValue;
                    else if (typeof aValue === 'string' && typeof bValue === 'string')
                        return sortOption.reverse ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
                }
                return 0;
            });
        }
    }

    return filteredQuotes;
};

/**
 * Filters the passed quotes and modifes the existing filteredQuotes without creating a new instance
 * @param {Array<Quote>} quotes
 * @param {Array<Quote>} filteredQuotes
 * @param {string} searchText
 * @param {Filter<IQuotesFilterItemDictionary>} filter
 */
export const filterList = (quotes: Array<Quote>, searchText: string, filter: Filter<IQuotesFilterItemDictionary>) => {
    try {
        const searchedQuotes = searchText?.trim().length ? searchQuotes(quotes, searchText) : quotes;

        let count = searchedQuotes.length;
        while (count--) {
            const quote = searchedQuotes[count];

            // Only filter if include is set true because a search result has been found
            if (filter.filterOptions.state.selectedOptions?.length && (!stateFilter(quote, filter) || !sentFilter(quote, filter))) {
                searchedQuotes.splice(count, 1);
                continue;
            }
        }

        return searchedQuotes;
    } catch (error) {
        Logger.error('Unable to filter & sort quotes');
        throw error;
    }
};

export type QuoteFilterOption = IOption<QuoteState, keyof Quote>;

export type QuoteSentOption = IOption<'yes' | 'no'>;
export type QuotesSortOptionValues = 'DATE_NEWEST_OLDEST' | 'DATE_OLDEST_NEWEST';
// | 'NAME_A_Z'
// | 'NAME_Z_A'
// | 'FILE_TYPE_A_Z'
// | 'FILE_TYPE_Z_A';
export type QuoteSortOption = IOption<QuotesSortOptionValues, keyof Quote>;

export interface IQuotesFilterItemDictionary extends FilterItemDictionary {
    sortBy: IFilterItem<QuoteSortOption>;
    state: IFilterItem<QuoteFilterOption>;
}

/**
 *  Quotes filter menu model.
 *  Used for ui filter menus
 * @return {*}  {Filter<IQuotesFilterItemDictionary>}
 */
export const filterMenu = (): Filter<IQuotesFilterItemDictionary> => {
    const sortByOptions: Array<QuoteSortOption> = [
        { name: 'sorting.by-newest-oldest', value: 'DATE_NEWEST_OLDEST', propertyKey: 'createdDate' },
        { name: 'sorting.by-oldest-newest', value: 'DATE_OLDEST_NEWEST', propertyKey: 'createdDate', reverse: true },
        // { name: 'sorting.by-alphabetical-a-z', value: 'NAME_A_Z', propertyKey: 'name' },
        // { name: 'sorting.by-alphabetical-z-a', value: 'NAME_Z_A', propertyKey: 'name', reverse: true },
        // { name: 'sorting.by-file-type-a-z', value: 'FILE_TYPE_A_Z', propertyKey: 'mimeType' },
        // { name: 'sorting.by-file-type-z-a', value: 'FILE_TYPE_Z_A', propertyKey: 'mimeType', reverse: true },
    ];

    const stateOptions: Array<QuoteFilterOption> = [
        { name: 'quote.state-pending', value: 'pending' },
        { name: 'quote.state-accepted', value: 'accepted' },
        { name: 'quote.state-declined', value: 'declined' },
    ];

    const sentOptions: Array<QuoteSentOption> = [
        { name: 'general.yes', value: 'yes' },
        { name: 'general.no', value: 'no' },
    ];

    const items: IQuotesFilterItemDictionary = {
        sortBy: {
            type: 'sortBy',
            name: 'filters.sort-by',
            optionNameKey: 'name',
            optionValueKey: 'value',
            options: sortByOptions,
            selectedOption: sortByOptions[0],
        },
        state: {
            type: 'filter',
            name: 'general.state',
            optionNameKey: 'name',
            optionValueKey: 'value',
            options: stateOptions,
        },
        sent: {
            type: 'filter',
            name: 'general.sent',
            optionNameKey: 'name',
            optionValueKey: 'value',
            options: sentOptions,
        },
    };
    return new Filter<IQuotesFilterItemDictionary>(items);
};
