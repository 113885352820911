import type { AvatarProps } from '@mui/material';
import { Avatar, darken, lighten, useTheme } from '@mui/material';
import type React from 'react';

const IconAvatar = ({
    color,
    avatarProps,
    children,
}: {
    color: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    avatarProps?: AvatarProps;
    children: React.ReactNode;
}) => {
    const theme = useTheme();

    return (
        <Avatar
            sx={{
                width: 40,
                height: 40,
                backgroundColor:
                    theme.palette.mode === 'dark' ? darken(theme.palette[color].dark, 0.6) : lighten(theme.palette[color].light, 0.9),
                color: theme.palette.mode === 'dark' ? theme.palette[color].light : theme.palette[color].dark,
            }}
            {...avatarProps}
        >
            {children}
        </Avatar>
    );
};

export default IconAvatar;
