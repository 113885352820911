import { Box, Button, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Switch } from '@mui/material';
import { Stack } from '@mui/system';
import { portalComponentSettingsDefaults } from '@nexdynamic/squeegee-portal-common';
import { useState } from 'react';
import { ApplicationState } from '../../../../ApplicationState';
import type { AureliaReactComponentDialogComponent } from '../../../../Dialogs/AureliaReactComponentDialogComponent';
import { setPortalComponentSetting } from './setPortalComponentSetting';

export const TodaysAppointmentSettingsEditor: AureliaReactComponentDialogComponent<any> = () => {
    const settingDefaults = portalComponentSettingsDefaults['todays-appointment'];
    type SettingType = typeof settingDefaults;

    const todaysAppointmentComponentSettings = ApplicationState.getSetting<SettingType>(
        'portal.component-todays-appointment',
        settingDefaults
    );
    const [todaysAppointmentSettings, setTodaysAppointmentSettings] = useState<SettingType>(todaysAppointmentComponentSettings);

    return (
        <Box>
            <Stack sx={{ m: 2 }} direction="row" justifyContent="flex-end">
                <Button variant="contained" onClick={() => setPortalComponentSetting('todays-appointment', todaysAppointmentSettings)}>
                    {ApplicationState.localise('general.save-button')}
                </Button>
            </Stack>
            <Paper sx={{ m: 2 }} variant="outlined">
                <List>
                    <ListItem>
                        <ListItemText>{ApplicationState.localise('portal-component.todays-appointment-allow-cancellation')}</ListItemText>
                        <ListItemSecondaryAction>
                            <Switch
                                checked={todaysAppointmentSettings.allowAppointmentCancellation}
                                color="secondary"
                                onChange={e =>
                                    setTodaysAppointmentSettings({
                                        ...todaysAppointmentSettings,
                                        allowAppointmentCancellation: e.target.checked,
                                    })
                                }
                            />
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Paper>
        </Box>
    );
};
