import type { Quote } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { QuoteUtils } from '../../Quotes/QuoteUtils';
import { AdvertisementAssetCache } from '../../ReactUI/Marketplace/services/AdvertisementService';

export class QuoteTagsCustomElement {
    @bindable quote?: Quote;

    protected advertisementCache = AdvertisementAssetCache;
    @computedFrom('quote.date', 'quote.state', 'advertisementCache.adverts')
    get tags() {
        if (!this.quote) return;
        const tags = QuoteUtils.getTags(this.quote);
        if (QuoteUtils.quoteIsListedOnMarketplace(this.quote))
            tags.unshift({ text: ApplicationState.localise('marketplace.item-listed'), color: '#0076b6' });
        return tags;
    }
}
