import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import Text from './components/Text';

type ErrorLoggerProps = {
    resetErrorBoundary?: () => void;
    error?: Error;
};
const ErrorLogger = ({ error }: ErrorLoggerProps) => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        console.error('Component Error Occurred', error);
        enqueueSnackbar(
            `Oops! Something went wrong!
        Help us improve your experience by sending an error report`,
            { variant: 'error', preventDuplicate: true }
        );
    }, [error, enqueueSnackbar]);

    return <Text text='general.oopsError' variant='overline' />;
};

export default ErrorLogger;
