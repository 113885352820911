import { Box } from '@mui/material';
import { SliderDistance } from './SliderDistance';
import { SliderTimeDifference } from './SliderTimeDifference';

export const SlidersPanel = ({
    maxDistanceMetres,
    maxTimeDifferenceMinutes,
    updateLimitBreakers,
}: {
    maxDistanceMetres: number;
    maxTimeDifferenceMinutes: number;
    updateLimitBreakers: (maxDistanceMetres: number, maxTimeDifferenceMinutes: number) => void;
}) => {
    const distanceChanged = (newMaxDistanceMetres: number) => {
        updateLimitBreakers(newMaxDistanceMetres, maxTimeDifferenceMinutes);
    };

    const timeDifferenceChanged = (newMaxTimeDifferenceMinutes: number) => {
        updateLimitBreakers(maxDistanceMetres, newMaxTimeDifferenceMinutes);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: { xs: 'centre', sm: 'center' },
                justifyContent: { xs: 'centre', sm: 'space-between' },
                flexDirection: { xs: 'column', sm: 'row' },
                backgroundColor: '#BEBEBE85',
                border: '2px solid #ffffff',
                borderRadius: '5px',
                padding: '16px',
            }}
        >
            <SliderDistance value={maxDistanceMetres} changed={distanceChanged} />
            <SliderTimeDifference value={maxTimeDifferenceMinutes} changed={timeDifferenceChanged} />
        </Box>
    );
};
