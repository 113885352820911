import { Grid, Paper, Typography } from '@mui/material';
import type { BulkPriceChange } from '@nexdynamic/squeegee-common';
import { ChangeResultFailure } from './ChangeResultFailure';
import { ChangeResultSuccess } from './ChangeResultSuccess';

export const ChangeResult = ({ change }: { change: BulkPriceChange }) => {
    if (!change.result) return null;

    return (
        <Paper sx={{ p: 3, mt: 2 }}>
            <Grid container>
                <Grid item lg={12}>
                    <Typography sx={{ mb: 1.5 }} variant='h6'>
                        Result
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {change.result?.success === true ? (
                        <ChangeResultSuccess change={change} />
                    ) : (
                        <ChangeResultFailure result={change.result} />
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
};
