import { Box } from '@mui/material';
import type { BulkPriceChange } from '@nexdynamic/squeegee-common';
import { useParams } from 'react-router-dom';
import { Data } from '../../../../../../Data/Data';
import { updatePriceChange } from '../../../../utils/updatePriceChange';
import { ChangeFields } from '../change/ChangeFields';
import { ChangeForm } from '../change/ChangeForm';

export const EditBulkPriceChange = () => {
    const { id = '' } = useParams();

    const change = Data.get<BulkPriceChange>(id);
    if (!change) return <Box>Change not found</Box>;

    const fields = ChangeFields.fromBulkPriceChange(change);
    return <ChangeForm fields={fields} onSubmit={updatePriceChange} />;
};
