import FilterListIcon from '@mui/icons-material/FilterList';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { Button, IconButton, InputBase, Popover, styled, Tab, Tabs, Toolbar } from '@mui/material';
import type { SxProps } from '@mui/system';
import { Box } from '@mui/system';
import type { IAdvertisement, IAdvertisementListing } from '@nexdynamic/squeegee-common';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApplicationState } from '../../../ApplicationState';
import type { FilterAdvertsQuery } from '../views/Adverts';
import { BasicMenu } from './BasicMenu';
import TermsDialog from './dialogs/UpdateTermsDialog';
import PopoverContent from './PopoverContent';
const Nav = styled(Box)`
    background: #fff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 4rem;
`;
const SearchFieldProps: SxProps = {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    width: '12rem',
    height: '36px',
    mr: '1rem',
    border: 1,
    borderColor: '#cdcdcd',
    background: '#f9f9f9',
    borderRadius: '12px',
    flex: 1,
};
type NavBarProps = {
    allData?: Array<IAdvertisement | IAdvertisementListing>;
    searched?: string;
    requestSearch?: (query: FilterAdvertsQuery) => void;
    cancelSearch?: any;
    refresh?: () => void;
};

const MarketplaceNavbar = ({ allData, searched, requestSearch, cancelSearch, refresh }: NavBarProps) => {
    const [popover, setPopover] = React.useState<HTMLButtonElement | null>(null);
    const [selectedSellers, setSelectedSellers] = useState<Array<string>>([]);
    const [selectedTags, setSelectedTags] = useState<Array<string>>([]);
    const [termsDialog, setTermsDialog] = useState(false);

    useEffect(() => {
        if (requestSearch) requestSearch({ tags: selectedTags, selectedSellers: selectedSellers });
    }, [selectedSellers.length, selectedTags.length]);

    function handleFilterButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        setPopover(event.currentTarget);
    }
    const handleClose = () => {
        setPopover(null);
    };

    const handleTermsDialog = () => {
        console.log('clicked', termsDialog);
        setTermsDialog(true);
    };

    const router = useLocation();
    const routeValue = router.pathname.split('/')[2];

    const open = Boolean(popover);
    const navigate = useNavigate();
    return (
        <>
            <Nav>
                <Box
                    id="root-box"
                    sx={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        alignItems: 'center',
                        gap: '10px',
                    }}
                >
                    <Toolbar>
                        <IconButton
                            onClick={() => {
                                refresh && refresh();
                            }}
                        >
                            <RefreshIcon />
                        </IconButton>
                        <IconButton
                            size="small"
                            sx={{
                                border: 1,
                                background: '#f9f9f9',
                                borderColor: '#cdcdcd',
                                marginLeft: '1rem',
                            }}
                            onClick={handleFilterButtonClick}
                        >
                            <FilterListIcon />
                        </IconButton>
                    </Toolbar>

                    <Popover
                        open={open}
                        anchorEl={popover}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        PaperProps={{
                            sx: {
                                border: 1,
                                borderRadius: '8px',
                                borderColor: '#cdcdcd',
                            },
                        }}
                    >
                        {allData && requestSearch ? (
                            <PopoverContent
                                allData={allData}
                                cancelSearch={cancelSearch}
                                requestSearch={requestSearch}
                                selectedSellers={selectedSellers}
                                setSelectedSellers={setSelectedSellers}
                                selectedTags={selectedTags}
                                setSelectedTags={setSelectedTags}
                            />
                        ) : null}
                    </Popover>

                    <Box
                        id="search-bar"
                        sx={Object.assign(SearchFieldProps, ApplicationState.features.marketplaceSeller ? { mr: '0' } : {})}
                    >
                        <SearchIcon sx={{ marginLeft: '0.6rem', color: '#727272' }} />
                        <InputBase
                            sx={{ flex: 1, color: '#03103b' }}
                            value={searched}
                            onChange={searchVal => {
                                if (requestSearch) requestSearch({ keyword: searchVal.target.value });
                            }}
                        />
                    </Box>

                    {ApplicationState.features.marketplaceSeller && (
                        <Box id="menu-bar">
                            <BasicMenu items={[{ title: 'marketplace.menu-update-seller-terms', func: handleTermsDialog }]}></BasicMenu>
                        </Box>
                    )}
                </Box>
            </Nav>
            {!ApplicationState.getSetting('beta-features.marketplace-seller', false) ||
            !ApplicationState.getSetting('global.marketplace-seller-terms') ? (
                <Box sx={{ width: '100%', height: '1rem', display: 'flex', justifyContent: 'left', margin: '1rem' }}>
                    To enable selling on marketplace, please submit your terms for review.
                </Box>
            ) : null}

            <Tabs sx={{ m: '1rem' }} value={routeValue}>
                {ApplicationState.features.marketplaceBuyer && (
                    <Tab
                        label="Adverts"
                        onClick={() => {
                            navigate('/marketplace/home');
                        }}
                        value="home"
                    />
                )}
                {ApplicationState.features.marketplaceSeller && (
                    <Tab label="My Listings" onClick={() => navigate('/marketplace/my-adverts')} value="my-adverts" />
                )}
                {ApplicationState.features.marketplaceBuyer && (
                    <Tab label="Purchased" onClick={() => navigate('/marketplace/my-purchases')} value="my-purchases" />
                )}
                {ApplicationState.getSetting('global.marketplace-seller-terms', '') === '' && (
                    <Button onClick={() => handleTermsDialog()}>Set Terms</Button>
                )}
            </Tabs>
            {termsDialog && (
                    <TermsDialog handleClose={() => setTermsDialog(false)} />
            )}
        </>
    );
};

export default MarketplaceNavbar;
