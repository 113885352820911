import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SearchIcon from '@mui/icons-material/Search';
import {
    Avatar,
    Box,
    Button,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { useDebounce } from '@nexdynamic/nex-ui-react';
import { useEffect, useState } from 'react';
import { ApplicationState } from '../../../ApplicationState';
import { Api } from '../../../Server/Api';
import { UserService } from '../../../Users/UserService';
import SearchInfoDialog from './QuerySystemDocs';

type Props = {
    handleSearch: (text: string) => void;
    loadMore: (startIndex: number, stopIndex: number) => void;
    length: number;
    isMobile: boolean;
};

const AuditTableHeader = ({ handleSearch, loadMore, length, isMobile }: Props) => {
    const [searchText, setSearchText] = useState('');
    const searchQuery = useDebounce(searchText, 500);
    const [infoOpen, setInfoOpen] = useState<boolean>(false);

    useEffect(() => {
        handleSearch(searchText);
    }, [searchQuery]);

    const users = UserService.getUsers().map(user => {
        return {
            name: user.name,
            email: user.email,
            avatar: user.avatar,
        };
    });

    const [userFilter, setUserFilter] = useState<string>('0');

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    bgcolor: 'background.paper',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        gap: 2,
                        p: 2,
                    }}
                >
                    <Box display="flex" width={isMobile ? '100%' : 'unset'} gap={1}>
                        <TextField
                            variant="outlined"
                            placeholder={`Search`}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            value={searchText}
                            size="small"
                            onChange={e => setSearchText(e.target.value)}
                            fullWidth={isMobile ? true : false}
                        />
                        <IconButton onClick={() => setInfoOpen(true)}>
                            <InfoOutlinedIcon />
                        </IconButton>
                    </Box>
                    {ApplicationState.stateFlags.devMode && (
                        <Button
                            variant="outlined"
                            onClick={() => {
                                Api.post(null, '/api/audit/events/fake', {
                                    iterations: 20,
                                }).then(() => {
                                    loadMore(length, length + 1);
                                });
                            }}
                        >
                            Add Test Entries
                        </Button>
                    )}

                    <FormControl sx={{ minWidth: 150 }} size="small" fullWidth={isMobile}>
                        <InputLabel>Filter by User</InputLabel>
                        <Select
                            fullWidth={isMobile}
                            size="small"
                            value={userFilter}
                            onChange={e => {
                                setUserFilter(e.target.value as string);
                                handleSearch(e.target.value === '0' ? '' : (e.target.value as string));
                            }}
                            label="Filter by User"
                            renderValue={selected => {
                                if (selected === '0') return 'All';
                                return users.find(user => user.email === selected)?.name;
                            }}
                        >
                            <MenuItem value="0">
                                <Avatar sx={{ width: 28, height: 28, mr: 1 }}>
                                    <PeopleAltIcon />
                                </Avatar>
                                All
                            </MenuItem>
                            {users.map(user => (
                                <MenuItem key={user.email} value={user.email}>
                                    <Avatar sx={{ width: 28, height: 28, mr: 1 }} src={user.avatar} />
                                    <Typography>{user.name}</Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            <SearchInfoDialog open={infoOpen} setOpen={setInfoOpen} />
        </>
    );
};

export default AuditTableHeader;
