import { UserService } from '../../../Users/UserService';
import { convertAccountUsersToWorkers } from '../utils/conversions';
import useTimeEntries from './useTimeEntries';

type UseWorkersParams = {
    date: string;
};

export const useWorkersWithTimeEntries = (date: string) => {
    const rawWorkers = UserService.getWorkers();

    const { items: rawTimeEntries } = useTimeEntries({ date: date });

    const workers = convertAccountUsersToWorkers(rawWorkers, rawTimeEntries);

    return workers;
}

export const useRawTimeEntries = (date: string) => {
    const { items: rawTimeEntries } = useTimeEntries({ date: date });

    return rawTimeEntries;
}



const useWorkers = ({ date }: UseWorkersParams) => {
    const workers = useWorkersWithTimeEntries(date);

    return workers
};

export const useWorkersRaw = () => {
    return UserService.getWorkers();
}

export default useWorkers;
