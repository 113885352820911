import { Box, Grid, Typography } from '@mui/material';
import { DiscardButton, GradientButton } from '@nexdynamic/nex-ui-react';
import { copyObject, type AudienceFilterGroup, type BulkPriceChange } from '@nexdynamic/squeegee-common';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { object } from 'yup';
import { Data } from '../../../../../../Data/Data';
import useTranslation from '../../../../../hooks/useTranslation';
import AudienceFilters from '../../../../../send/features/audiences/component/filters/AudienceFilters';

export class AffectedJobsFormFields {
    filterGroups: Array<AudienceFilterGroup> = [];
}
const AffectedJobsSchema = () => object({}).required();
export const EditAffectedJobs = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id = '' } = useParams();
    if (!id) return <Box>Change not found</Box>;

    const change = Data.get<BulkPriceChange>(id);
    if (!change) return <Box>Change not found</Box>;

    const onSubmit: (fields: AffectedJobsFormFields) => Promise<void> = async fields => {
        change.affectedJobFilter = fields.filterGroups;
        await Data.put(change);
        navigate('/pricing/changes/' + id);
    };

    const initialValues = change.affectedJobFilter ? { filterGroups: change.affectedJobFilter } : { filterGroups: [] };
    const discardValues = copyObject(initialValues);

    const handleDiscard = () => {
        onSubmit(discardValues);
        navigate(-1);
    };
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={AffectedJobsSchema()}
            onSubmit={values => {
                return onSubmit(values);
            }}
        >
            {({ isSubmitting, values }) => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Form autoComplete='off'>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                                        <Box sx={{ flex: 1, mr: 1 }}>
                                            <Typography variant='h6'>{change.name + ' - Affected Customers'}</Typography>
                                        </Box>
                                        <Box>
                                            <DiscardButton sx={{ mr: 1 }} onDiscard={() => handleDiscard()} />
                                            <GradientButton
                                                aria-label={t('general.save')}
                                                text={t('general.save')}
                                                type='submit'
                                                disabled={isSubmitting}
                                                color='success'
                                            ></GradientButton>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Form>
                    </Grid>

                    <Grid item xs={12}>
                        <AudienceFilters
                            filters={values?.filterGroups}
                            audienceDescription='bulk-price-change.affected-jobs-title'
                            audienceNoResultsDescription='bulk-price-change.affected-jobs-no-results'
                        />
                    </Grid>
                </Grid>
            )}
        </Formik>
    );
};
