import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ViewLoading from '../components/ViewLoading';
import SendMain from './views/SendMain';

function SendApp() {
    return (
        <Suspense fallback={<ViewLoading />}>
            <Routes>
                <Route path='/send/*' element={<SendMain />}></Route>
            </Routes>
        </Suspense>
    );
}

export default SendApp;
