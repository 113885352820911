import { activationStrategy } from 'aurelia-router';
import { Tracker } from './Tracker';

export class TrackerModule {
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    tracker: ReturnType<typeof Tracker>;

    attached() {
        const container = document.getElementById('time-dashboard-root');
        if (!container) throw new Error('time-dashboard-root element not found');
        this.tracker = Tracker(container);
    }

    detached() {
        this.tracker?.unmount();
    }
}
