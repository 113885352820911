import { ListItemText } from '@mui/material';
import { ElevatedListItemButton } from '@nexdynamic/nex-ui-react';
import type { Supplier } from '@nexdynamic/squeegee-common';

type SupplierListItemProps = {
    supplier: Supplier;
    onClick: (supplier: Supplier) => void;
};

export const SupplierListItem = ({ supplier, onClick }: SupplierListItemProps) => {
    return (
        <ElevatedListItemButton onClick={() => onClick(supplier)}>
            <ListItemText primary={supplier.name} />
        </ElevatedListItemButton>
    );
};

export default SupplierListItem;
