import type { Customer, IQuoteSettings, Job, JobInitialFields, Quote } from '@nexdynamic/squeegee-common';
import {
    Currency,
    QuotedJob,
    getNextDueDateFromQuotedJob,
    notNullUndefinedEmptyOrZero,
    replaceMessageTokensWithModelValues,
    splitName,
} from '@nexdynamic/squeegee-common';
import { TemplateService, type QuoteEmailTemplateProps } from '@nexdynamic/squeegee-templates';
import moment from 'moment';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import { EmailTemplateService } from '../EmailTemplates/EmailTemplateService';
import { NewJobDialog } from '../Jobs/Components/NewJobDialog';
import { Logger } from '../Logger';
import { getGoCardlessSignUpForCustomer } from '../Payments/Utils/getGoCardlessSignUpForCustomer';
import { getStripeSignUpForCustomer } from '../Payments/Utils/getStripeSignUpForCustomer';
import { Api } from '../Server/Api';
import { Utilities } from '../Utilities';
import { t } from '../t';

export class QuoteService {
    public static async decline(quote: Quote, reason?: string, save = true) {
        delete quote.acceptedDate;
        quote.state = 'declined';
        quote.declineReason = reason;
        quote.declinedDate = Date.now();

        if (save) await Data.put(quote);
    }

    public static async accept(quote: Quote, save = true) {
        if (quote.acceptedDate) return;
        delete quote.declineReason;
        delete quote.declinedDate;

        quote.state = 'accepted';
        quote.acceptedDate = Date.now();

        if (save) await Data.put(quote);
    }

    public static async markAsPending(quote: Quote, save = true) {
        //Remove any fields that could of been set before
        delete quote.declineReason;
        delete quote.declinedDate;
        delete quote.acceptedDate;
        delete quote.lastViewedDate;
        delete quote.firstViewedDate;
        delete quote.sentDate;
        delete quote.sentManually;

        quote.state = 'pending';

        if (save) await Data.put(quote);
    }

    public static async delete(quote: Quote) {
        await Data.delete(quote);
        await QuoteService.deleteRelatedItems(quote);
    }

    private static async deleteRelatedItems(quote: Quote) {
        for (const item of quote.items) {
            if (item.relatedId) {
                const relatedItem = Data.get(item.relatedId);
                if (relatedItem) {
                    await Data.delete(relatedItem);
                }
            }
        }
    }

    static getJobs(quote: Quote) {
        const jobs: Array<QuotedJob> = [];

        if (quote) {
            for (const item of quote.items) {
                if (item.type === 'job' && item.relatedId) {
                    const job = QuoteService.getQuotedJob(item.relatedId);
                    if (job) jobs.push(job);
                }
            }
        }

        return jobs;
    }

    static getQuoteSettings(): IQuoteSettings {
        const settings = ApplicationState.getSetting<IQuoteSettings>('global.quote');

        if (!settings) return this.setDefaults();

        return settings;
    }

    static setDefaults(): IQuoteSettings {
        const settings: IQuoteSettings = {
            email: ApplicationState.account.invoiceSettings?.email || ApplicationState.account.email,
            phone: ApplicationState.account.invoiceSettings?.phone,
            website: ApplicationState.account.invoiceSettings?.website,
        };

        ApplicationState.setSetting<IQuoteSettings>('global.quote', settings);

        return settings;
    }

    static async getQuoteLink(quote: Quote) {
        return await Utilities.getShortUrl(`${Api.apiEndpoint}/go/q/${quote._id}/`);
    }

    static async getQuoteTemplates(
        quote: Quote,
        customer: Customer,
        quoteSettings: IQuoteSettings
    ): Promise<{ email: string; sms: string }> {
        const link = await QuoteService.getQuoteLink(quote);
        const account = ApplicationState.account;

        let templateSms = quoteSettings.smsTemplate;
        let templateEmail = quoteSettings.emailTemplate;
        if (!templateSms) {
            templateSms = ApplicationState.localise('quote.default-sms-template', {
                businessName: account.businessName || account.name || '',
                quoteNumber: `${quoteSettings.quotePrefix || ''}${quote.quoteNumber || ''}`,
                date: moment(quote.date).format('LL'),
                customerName: customer.name,
            });
            templateSms = replaceMessageTokensWithModelValues({
                model: { ['link']: link },
                message: templateSms,
                options: { yesLabel: t('general.yes'), noLabel: t('general.no') },
            });
        } else {
            const smsMessageModel = await Utilities.getStandardMessageModel({
                customer,
                isHtml: false,
                additionalModelProperties: { ['link']: link } as any,
                templateText: templateSms,
            });
            templateSms = replaceMessageTokensWithModelValues({
                model: smsMessageModel,
                message: templateSms,
                options: { yesLabel: t('general.yes'), noLabel: t('general.no') },
            });
        }
        const names = splitName(customer.name);
        const gocardlessSignUpLink = getGoCardlessSignUpForCustomer(customer);
        const stripeSignUpLink = getStripeSignUpForCustomer(customer);
        let automaticPaymentSignup;

        if (gocardlessSignUpLink || stripeSignUpLink) automaticPaymentSignup = `${Api.currentHostAndScheme}/go/a/${customer._id}/`;

        const emailModel: QuoteEmailTemplateProps = {
            model: {
                businessEmail: quoteSettings.email || account.email,
                businessName: account.businessName || '',
                currency: quote.currency,
                customerName: customer.name,
                firstName: names.firstname,
                date: quote.date,
                expiry: quote.expiry,
                link,
                quoteNumber: `#${quoteSettings.quotePrefix || ''}${quote.quoteNumber || ''}`,
                quoteItems: quote.items,
                total: quote.total,
                content: `${quote.items.map(i => i.title).join(', \n')}`,
                customerRef: customer._externalId,
                nextDue: QuoteService.getNextDueDateFromQuote(quote) || '',
                gocardlessSignUpLink: gocardlessSignUpLink,
                stripeSignUpLink: stripeSignUpLink,
                automaticPaymentSignup,
            },
        };

        if (!templateEmail) {
            templateEmail = TemplateService.renderComponent<QuoteEmailTemplateProps>('email.quote', emailModel, false, quote.ownerEmail);
        } else {
            const tokenModel: Record<string, string> = {
                content: `${quote.items.map(i => i.title).join(', \n')}`,
                customerRef: customer._externalId || '',
                businessEmail: quoteSettings.email || account.email || '',
                businessName: account.businessName || '',
                customerName: customer.name,
                link,
                quoteNumber: `#${quoteSettings.quotePrefix || ''}${quote.quoteNumber || ''}`,
                date: moment(emailModel.model.date).format('LL'),
                expiry: moment(emailModel.model.expiry).format('LL'),
                total: emailModel.model.total?.toFixed(2) || '',
                currencySymbol: (Currency.get(emailModel.model.currency)?.symbol as string) || '',
                currency: emailModel.model.currency as string,
                nextDate: QuoteService.getNextDueDateFromQuote(quote) || '',
            };

            const model = await Utilities.getStandardMessageModel({
                customer,
                isHtml: true,
                additionalModelProperties: tokenModel,
                templateText: templateEmail,
            });
            templateEmail = EmailTemplateService.getSimpleHtml(
                replaceMessageTokensWithModelValues({
                    model,
                    message: templateEmail,
                    options: { yesLabel: t('general.yes'), noLabel: t('general.no') },
                })
            );
        }

        return { email: templateEmail, sms: templateSms };
    }

    static async markAsSent(quote: Quote, sentDate: number, manuallySent = false) {
        quote.sentDate = sentDate;
        quote.sentManually = manuallySent;
        await Data.put(quote);
    }

    static async markAsUnsent(quote: Quote) {
        delete quote.sentDate;
        delete quote.sentManually;

        await Data.put(quote);
    }

    static getQuotedJob(quotedJobId: string) {
        return Data.get<QuotedJob>(quotedJobId);
    }

    static getQuotes() {
        return Data.all<Quote>('quotes').slice().sort(QuoteService.sortByQuoteNumber);
    }

    static sortByQuoteNumber(a: Quote, b: Quote) {
        if (a.quoteNumber) return (b.quoteNumber || 0) - a.quoteNumber;
        return -1;
    }

    static async convertItemsToJobs(quote: Quote): Promise<Array<{ job: Job; initialFields?: JobInitialFields }>> {
        const jobs: Array<{ job: Job; initialFields?: JobInitialFields }> = [];
        const quotedJobs: Array<QuotedJob> = [];

        for (const item of quote.items) {
            if (item.type === 'job' && item.relatedId && !quotedJobs.find(j => j._id === item.relatedId)) {
                const quotedJob = Data.get<QuotedJob>(item.relatedId);
                if (quotedJob) {
                    quotedJobs.push(quotedJob);
                }
            }
        }

        for (const quotedJob of quotedJobs) {
            const customer = Data.get<Customer>(quotedJob.customerId);
            if (!customer) throw new Error('Customer not found unable to convert quote item into job');

            const job = QuotedJob.toJob(quotedJob);

            const dialog = new NewJobDialog(customer, job.job, false, false, job.initialFields, true, false);
            if (job.job.assignees) dialog.assignees = job.job.assignees;
            const result = await dialog.show();

            result.job.assignees = dialog.assignees;
            //Abort if any dialog was canceled
            if (dialog.cancelled) return [];
            if (result) jobs.push(result);
        }

        return jobs;
    }

    static quoteIsConverted(quote: Quote) {
        const jobIds =
            quote.items
                ?.map(x => x.relatedId)
                .filter(notNullUndefinedEmptyOrZero)
                .map(x => x.replace('quote:', '')) || [];
        if (!jobIds.length) return false;

        const customer = Data.get<Customer>(quote.customerId);
        if (!customer?.jobs) return false;

        for (const jobId of jobIds) {
            if (!customer.jobs[jobId]) return false;
        }

        return true;
    }

    static getNextDueDateFromQuote(quote: Quote) {
        try {
            let nextDate: string | undefined;

            const quoteItem = quote?.items[0];
            if (!quoteItem) throw new Error('Quote item found on quote');

            // TODO shouldn't we also be able to calculate the next date from the quote item frequency?

            if (quoteItem.relatedId) {
                const job = Data.get<QuotedJob>(quoteItem.relatedId);
                if (!job) throw new Error('No quoted job found for that customer');
                nextDate = getNextDueDateFromQuotedJob(job);
            }
            return nextDate;
        } catch (error) {
            Logger.error('Failed to get next date for quote');
            return;
        }
    }
}
