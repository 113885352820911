import { CircularProgress } from '@mui/material';
import { Suspense, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { isDevMode } from '../../../isDevMode';
import LoadingScreen from '../../components/LoadingScreen';
import useTranslation from '../../hooks/useTranslation';
import { PortalLayoutProvider } from '../PortalEditor/PortalLayoutProvider';
import { DirectoryEntryProvider, useDirectoryEntryDetails } from '../useDirectoryEntryDetails';
import PortalDirectoryMain from './views/PortalDirectoryMain';
import { SetupWizard } from './views/SetupWizard';

function PortalDirectory() {
    const { directory } = useDirectoryEntryDetails();

    useEffect(() => {
        if (!directory) return;

        if (directory?.setupComplete || directory?.isPublic || isDevMode()) {
            nav('/portal-directory');
        } else {
            nav('/portal-directory/setup');
        }
    }, [directory]);

    const nav = useNavigate();
    const { t } = useTranslation();

    return (
        <Suspense fallback={<CircularProgress />}>
            <DirectoryEntryProvider>
                <PortalLayoutProvider>
                    <LoadingScreen open={!directory} message={t('portal.loading-description')} />
                    {directory && (
                        <Routes>
                            <Route path="/portal-directory/*" element={<PortalDirectoryMain />}></Route>
                            <Route path="/portal-directory/setup" element={<SetupWizard />}></Route>
                        </Routes>
                    )}
                </PortalLayoutProvider>
            </DirectoryEntryProvider>
        </Suspense>
    );
}

export default PortalDirectory;
