import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, List, Typography } from '@mui/material';
import { useState } from 'react';

import { useLectures } from '../hooks/useLectures';
import type { Lecture } from '../Views/TrainingView';
import { LecturePanel } from './LecturePanel';

export const TrainingAccordion = ({ clickHandler }: { clickHandler: (lecture: Lecture) => void }) => {
    const [expanded, setExpanded] = useState<string | false>(false);
    const { lecturesBySubject } = useLectures();
    if (!lecturesBySubject) return null;

    const handleChange = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
        console.log(panel, isExpanded, 'pressed');
        setExpanded(isExpanded ? panel : false);
    };

    const subjects = Object.keys(lecturesBySubject);

    // how can i make every second accordion bgcolor to be different?

    return (
        <>
            {subjects.map(subject => (
                <Accordion
                    sx={{
                        'bgcolor': '#f0f0f0',
                        '&:nth-of-type(even)': {
                            bgcolor: '#fff',
                        },
                    }}
                    key={subject}
                    expanded={expanded === subject}
                    onChange={handleChange(subject)}
                    disableGutters
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h5">{subject.replace(/-+/g, ' ')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                            {lecturesBySubject[subject].map(lecture => (
                                <LecturePanel lecture={lecture} key={lecture.title} onClickHandler={clickHandler} />
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    );
};
