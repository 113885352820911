import { SqueegeeLocalStorage } from './Data/SqueegeeLocalStorage';
import { Logger } from './Logger';

export class RemoteSupport {
    private static _remoteSupportInitialised = false;
    public static get remoteSupportInitialised() {
        return RemoteSupport._remoteSupportInitialised;
    }
    public static initRemoteSupport() {
        if (SqueegeeLocalStorage.getItem('remoteSupportEnabled') === 'true') RemoteSupport.enableRemoteSupport();
    }
    public static enableRemoteSupport() {
        if (RemoteSupport._remoteSupportInitialised) return;
        RemoteSupport._remoteSupportInitialised = true;

        const vorlonScript = document.createElement('script');
        vorlonScript.src = 'https://r.sqg.ee/vorlon.autostartdisabled.js';
        document.body.appendChild(vorlonScript);
        const waiting = setInterval(() => {
            const vorlon = window.VORLON;
            if (!vorlon) return;
            clearInterval(waiting);

            const vorlonErrorHandler = (...args: Array<any>) => {
                Logger.info('Vorlon error', args);
                RemoteSupport.disableRemoteSupport();
            };

            if (vorlon?._Core?.prototype?._OnError) vorlon._Core.prototype._OnError = vorlonErrorHandler;
            if (vorlon?.Core?._OnError) vorlon.Core._OnError = vorlonErrorHandler;

            setTimeout(() => vorlon.Core.StartClientSide('https://r.sqg.ee', 'default'), 250);

            SqueegeeLocalStorage.setItem('remoteSupportEnabled', 'true');
        }, 250);
    }

    public static disableRemoteSupport() {
        if (RemoteSupport._remoteSupportInitialised) RemoteSupport._remoteSupportInitialised = false;
        SqueegeeLocalStorage.removeItem('remoteSupportEnabled');
    }
}
