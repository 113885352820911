import type { CommonApplicationState } from '@nexdynamic/squeegee-common';
import React, { useEffect } from 'react';
import { ApplicationState } from '../../ApplicationState';
import { ApplicationStateUpdatedEvent } from '../../Events/ApplicationStateUpdatedEvent';

const useApplicationState = () => {
    const [applicationState, setApplicationState] = React.useState<CommonApplicationState>(ApplicationState.instance);

    useEffect(() => {
        const sub = ApplicationStateUpdatedEvent.subscribe(() => {
            setApplicationState({ ...ApplicationState.instance });
        });

        return () => {
            sub.dispose();
        };
    }, []);

    return applicationState;
};

export default useApplicationState;
