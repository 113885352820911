import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography, useTheme } from '@mui/material';
import Card from '../styled-components/Card';

type MiniStatBoxProps = {
    title: string;
    value: string | number;
    icon?: React.ReactNode;
    width?: string | number;
    height?: string | number;
};

const MiniStatBox = ({ title, value, icon, width, height }: MiniStatBoxProps) => {
    const theme = useTheme();
    return (
        <Card background='#eaf5ff' width={width || '230px'} height={height || '49px'} sx={{ px: 2, py: 5 }}>
            <Box>
                <List>
                    <ListItem alignItems='center' disableGutters>
                        <ListItemAvatar>
                            <Avatar
                                variant='rounded'
                                sx={{
                                    width: 48,
                                    height: 48,
                                    fontSize: '32px',
                                    backgroundColor: '#1e88e558',
                                    color: '#449afb',
                                    borderRadius: '8px',
                                    marginRight: '16px',
                                }}
                            >
                                {icon ? icon : <StorefrontTwoToneIcon fontSize='inherit' />}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<Typography variant='h6'>{value}</Typography>}
                            secondary={
                                <Typography
                                    variant='subtitle2'
                                    sx={{
                                        color: theme.palette.grey[500],
                                    }}
                                >
                                    {title}
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>
            </Box>
        </Card>
    );
};

export default MiniStatBox;
