import { useMemo } from 'react';
import type { Layout as LayoutItem } from 'react-grid-layout';
import { Responsive, WidthProvider } from 'react-grid-layout';
import type { settingEditorComponents } from '../../ComponentSettings/settingEditorComponents';
import { usePortalLayout } from '../PortalLayoutProvider';
import { widgetSizes } from '../utils/widgetSizes';
import WidgetSkeleton from './WidgetSkeleton';

type Props = {
    handleSettings: (componentName: keyof typeof settingEditorComponents) => void;
    handleChangesMade: (changesMade: boolean, reason?: string) => void;
};

const GridLayout = ({ handleSettings, handleChangesMade }: Props) => {
    const {
        viewmode,
        selectedLayout,
        layout: layoutItems,
        handleRemoveComponent,
        handleLayoutUpdate,
        handleNewLayout,
        shouldRenderWidget,
    } = usePortalLayout();

    const handleLayoutUpdateInternal = (layout: Array<LayoutItem>) => {
        if (!selectedLayout) {
            handleNewLayout();
            return;
        }
        const referenceLayout = layoutItems;
        const modifiedLayouts = new Array<LayoutItem>();

        for (const item of referenceLayout) {
            if (!shouldRenderWidget(item, viewmode)) {
                modifiedLayouts.push(item);
                continue;
            }
            const newItem = layout.find(l => l.i === item.i);
            if (newItem) {
                modifiedLayouts.push(newItem);
            }
        }

        console.log('internalLayoutUpdate ', referenceLayout, modifiedLayouts);
        if (JSON.stringify(referenceLayout) === JSON.stringify(modifiedLayouts)) {
            console.log('layout is the same. making no changes...');
            return;
        }
        handleChangesMade(true, 'layout update');
        handleLayoutUpdate(layout);
    };

    const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), []);
    return (
        <ResponsiveReactGridLayout
            className="layout"
            cols={viewmode === 'sm' ? { xxs: 4, xs: 4, sm: 4, md: 4, lg: 4 } : { xxs: 1, xs: 1, sm: 1, md: 1, lg: 1 }}
            layouts={{
                lg: layoutItems,
                md: layoutItems,
                sm: layoutItems,
                xs: layoutItems,
                xxs: layoutItems,
            }}
            onLayoutChange={handleLayoutUpdateInternal}
            rowHeight={50}
        >
            {layoutItems.map(layoutItem => {
                if (!shouldRenderWidget(layoutItem, viewmode)) return null;
                return (
                    <div
                        key={layoutItem.i}
                        data-grid={{
                            ...layoutItem,
                            ...widgetSizes[layoutItem.i as keyof typeof widgetSizes],
                        }}
                        style={{
                            position: 'relative',
                            zIndex: 1,
                        }}
                    >
                        <WidgetSkeleton
                            componentName={layoutItem.i}
                            onRemove={() => handleRemoveComponent(layoutItem.i)}
                            onSettings={() => handleSettings(layoutItem.i as keyof typeof settingEditorComponents)}
                            spacer={layoutItem.i.startsWith('spacer')}
                        />
                    </div>
                );
            })}
        </ResponsiveReactGridLayout>
    );
};

export default GridLayout;
