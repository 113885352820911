import { CircularProgress, ScopedCssBaseline } from '@mui/material';
import { NexContactsProvider, NexUiProvider, SnackbarProvider } from '@nexdynamic/nex-ui-react';
import i18next from 'i18next';
import { Suspense, type FC } from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { LocaleResourceBundle } from '../main';
import { ContactsApi } from './contacts/ContactService';
import ThemeProvider from './themes/ThemeProvider';

type Props = {
    children: React.ReactNode;
};

i18next.use(initReactI18next).init({
    resources: LocaleResourceBundle,
    lng: 'en',
    fallbackLng: 'en',
    returnNull: false,
    interpolation: {
        escapeValue: false,
    },
});

const AppProviders: FC<Props> = ({ children }) => {
    return (
        <I18nextProvider i18n={i18next}>
            <ThemeProvider>
                <NexUiProvider>
                    <NexContactsProvider {...ContactsApi}>
                        <ScopedCssBaseline />
                        <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} maxSnack={3}>
                            <Suspense fallback={<CircularProgress />}>{children}</Suspense>
                        </SnackbarProvider>
                    </NexContactsProvider>
                </NexUiProvider>
            </ThemeProvider>
        </I18nextProvider>
    );
};

export default AppProviders;
