import type { Customer, IQuoteItem, Quote, QuoteState } from '@nexdynamic/squeegee-common';
import { Currency, Location, QuotedJob } from '@nexdynamic/squeegee-common';
import { bindable, bindingMode, computedFrom } from 'aurelia-framework';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { AttachmentsDialog } from '../../Attachments/Dialogs/AttachmentsDialog';
import { DateTimePicker } from '../../Components/DateTimePicker/DateTimePicker';
import { CustomerService } from '../../Customers/CustomerService';
import { Data } from '../../Data/Data';
import { Prompt } from '../../Dialogs/Prompt';
import { NewJobDialog } from '../../Jobs/Components/NewJobDialog';
import { Logger } from '../../Logger';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import { t } from '../../t';
import { QuoteActions } from '../QuoteActions';
import { QuoteService } from '../QuoteService';

export type QuoteFormFields = {
    readonly name: string;
    readonly address: Location;
    state: QuoteState;
    items: Array<IQuoteItem>;
    currency: keyof Currency;
    expiry: number;
    date: number;
    taxRate?: number;
    notes?: string;
    jobs: Array<QuotedJob>;
    priceIncludesTax: boolean;
};

export class QuoteFormCustomElement {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) quote?: Quote;
    @bindable() customer?: Customer;
    @bindable() onSubmit: (fields: QuoteFormFields) => void;

    protected fields?: QuoteFormFields;
    protected updateGroupedItems() {
        this.groupedItems = Object.entries(QuoteActions.groupItems([...(this.fields?.items || [])]));
    }
    protected groupedItems: Array<[string, IQuoteItem[]]>;

    attached() {
        this.fields = this.populateFields(this.quote);

        if (this.fields) this.updateGroupedItems();
    }

    submit() {
        if (this.fields) {
            this.onSubmit && this.onSubmit(this.fields);
        }
    }

    quoteItemClicked(itemOrId: IQuoteItem | string) {
        if (!this.fields) return;
        if (typeof itemOrId === 'string') {
            const job = this.fields.jobs.find(j => j._id === itemOrId);
            if (!job) return Logger.error('Unable to edit job on quote item');
            this.editJob(job);
        } else if (itemOrId.type === 'job') {
            const job = this.fields.jobs.find(j => j._id === itemOrId.relatedId);
            if (!job) return Logger.error('Unable to edit job on quote item');
            this.editJob(job);
        }
    }

    private populateFields(quote?: Quote): QuoteFormFields {
        const quoteSettings = QuoteService.getQuoteSettings();
        const taxConfig = this.customer && CustomerService.getTaxConfig(this.customer);
        const taxRate = quote?.taxRate || (taxConfig && taxConfig.taxEnabled) ? taxConfig?.taxRate : undefined;
        return {
            state: quote?.state || 'pending',
            name: quote?.name || this.customer?.name || '',
            address: quote?.address || this.customer?.address || new Location(),
            items: quote?.items.length ? [...quote.items] : [],
            currency: quote?.currency || ApplicationState.account.currency,
            date: quote?.date || moment().valueOf(),
            expiry:
                quote?.expiry ||
                moment()
                    .add(quoteSettings.defaultExpiryDays === undefined ? 30 : quoteSettings.defaultExpiryDays, 'd')
                    .valueOf(),
            taxRate,
            notes: quote?.notes || quoteSettings.defaultNotes,
            jobs: quote ? QuoteService.getJobs(quote) : [],
            priceIncludesTax: Boolean(taxConfig?.priceIncludesTax),
        };
    }

    protected async addJob() {
        if (!this.customer || !this.fields) return;

        const alreadyHasJobs = this.fields.jobs.length;

        if (alreadyHasJobs && !ApplicationState.hasAdvancedOrAbove) {
            new Prompt('general.upgrade', ApplicationState.localise('prompts.upgrade-multiple-jobs-customers-response'), {
                cancelLabel: '',
                okLabel: 'general.dismiss-message',
            }).show();
        } else {
            let quotedJob = new QuotedJob(this.customer._id, this.customer.address);
            const jobDialog = new NewJobDialog(this.customer, quotedJob, false, undefined, undefined, false, true);

            const result = await jobDialog.show();
            if (jobDialog.cancelled || !result.job) return;

            quotedJob = result.job;
            if (jobDialog.assignees) {
                quotedJob.assignees = jobDialog.assignees;
            }
            quotedJob.initialFields = result.initialFields;

            const items = QuoteActions.jobToQuoteItems(quotedJob);
            this.fields.items.push(...items);
            this.fields.jobs.push(quotedJob);
            this.updateGroupedItems();
        }
    }

    protected async addAttachments() {
        if (!this.customer || !this.fields) return;

        const { success, error, quote } = await QuoteActions.saveQuoteForm(this.customer, this.fields, this.quote, true);
        if (error) new NotifyUserMessage(error);
        if (success && quote) {
            this.quote = quote;
            this.fields = this.populateFields(this.quote);
            this._saved = true;
            const header = `${t('general.quote')} ${this.customer.name}, ${this.customer.address?.addressDescription || ''}`;
            new AttachmentsDialog(quote, header, 'request_quote').show();
        }
    }

    protected async editJob(quotedJob: QuotedJob) {
        if (!this.customer || !this.fields) return;
        const dialog = new NewJobDialog(this.customer, quotedJob, false, undefined, quotedJob.initialFields, false, true);
        if (quotedJob.assignees?.length) dialog.assignees.push(...quotedJob.assignees);
        const result = await dialog.show();
        if (dialog.cancelled || !result.job) return;

        quotedJob = result.job;
        quotedJob.initialFields = result.initialFields;
        quotedJob.assignees = dialog.assignees;
        QuoteActions.replaceJob(this.fields, quotedJob);
        this.updateGroupedItems();
    }

    protected removeItem(quoteItem: IQuoteItem) {
        if (!this.fields) return;
        if (quoteItem.type === 'job' && quoteItem.relatedId) QuoteActions.removeJobFromQuoteFields(this.fields, quoteItem.relatedId);
    }

    protected removeItemUsingId(id: string) {
        if (!this.fields) return;
        QuoteActions.removeJobFromQuoteFields(this.fields, id);
        this.updateGroupedItems();
    }

    @computedFrom('quote.currency')
    get currencySymbol() {
        if (!this.fields) return;
        return Currency.get(this.fields.currency)?.symbol;
    }

    private _saved: boolean;
    @computedFrom('_saved')
    get saved() {
        if (this._saved === undefined) this._saved = !!this.quote?._id && !!Data.get(this.quote._id);
        return this._saved;
    }

    private async pickDate(value?: number) {
        const initialDate = value ? new Date(value).toISOString() : null;
        const datePicker = new DateTimePicker(false, initialDate);
        datePicker.init();
        await datePicker.open();
        if (!datePicker.canceled) {
            return datePicker.selectedDate;
        }
    }

    protected quoteItemHasUniqueAddress(quoteItem: IQuoteItem) {
        return (
            this.quote &&
            quoteItem.address?.addressDescription &&
            quoteItem.address.addressDescription !== this.quote.address.addressDescription
        );
    }

    protected async chooseDate() {
        const date = await this.pickDate(this.fields?.date);
        if (date && this.fields) {
            this.fields.date = new Date(date).getTime();
        }
    }

    protected async chooseExpiry() {
        const date = await this.pickDate(this.fields?.expiry);
        if (date && this.fields) {
            this.fields.expiry = new Date(date).getTime();
        }
    }

    @computedFrom('quote.state')
    protected get stateOptions() {
        if (!this.quote) return;

        const options: Array<{ text: string; value: QuoteState }> = [
            {
                text: ApplicationState.localise('quote.state-pending'),
                value: 'pending',
            },
            {
                text: ApplicationState.localise('quote.state-accepted'),
                value: 'accepted',
            },
            {
                text: ApplicationState.localise('quote.state-declined'),
                value: 'declined',
            },
        ];
        return options;
    }
}
