import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';

type LoaderProps = {
    open: boolean;
    message?: string;
};

const LoadingScreen = ({ open, message }: LoaderProps) => {
    return (
        <Backdrop open={open} sx={{ zIndex: theme => theme.zIndex.drawer + 1, color: '#fff' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
                <CircularProgress color='inherit' />
                <Typography variant='subtitle2'>{message}</Typography>
            </Box>
        </Backdrop>
    );
};

export default LoadingScreen;
