import { FormStep } from '@nexdynamic/nex-ui-react';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';

const CampaignDetailsStep = () => {
    const { t } = useTranslation();

    return (
        <FormStep
            fields={['name', 'description']}
            title={t('campaigns-form.details-title')}
            description={t('campaigns-form.details-description')}
        >
            <Field sx={{ width: '100%', mb: 2 }} required component={TextField} type='text' label={t('campaign.name-label')} name='name' />

            <Field
                sx={{ width: '100%' }}
                required
                component={TextField}
                type='text'
                label={t('campaign.description-label')}
                name='description'
            />
        </FormStep>
    );
};

export default CampaignDetailsStep;
