import type { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

type AreaChartProps = {
  width?: string;
  height: string;
  categories: Array<any>;
  series: ApexAxisChartSeries;
};

export const BareLineChart = ({
  categories,
  series,
  width,
  height,
}: AreaChartProps) => {
  const options: ApexOptions = {
    chart: {
      type: "line",
      stacked: true,
      height: 90,
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
    yaxis: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: categories,
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
      fixed: {
        enabled: false,
      },
      x: {
        show: true,
      },
      y: {
        formatter: (value) => {
            return `${(value)} GBP`;
          },
      },
      marker: {
        show: false,
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        color: "#ffffff",
      },
    },
    fill: {
      type: "solid",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.5,
        opacityTo: 0.1,
        stops: [0, 90, 100],
      },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
  };

  return (
    <>
      <Chart
        options={options}
        series={series}
        type="line"
        width={width}
        height={height}
      />
    </>
  );
};

export default BareLineChart;
