import type { AudienceCreateInput, AudienceFilterGroup, AudienceUpdateInput, Campaign, Customer } from '@nexdynamic/squeegee-common';
import { Audience, audienceFilterToCustomerSearch, searchCustomers } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../../../ApplicationState';
import { Data } from '../../../../Data/Data';

export const audienceRemove = async (audience: Audience) => {
    // Remove audiences from campaigns
    const campaigns = Data.all<Campaign>('campaigns')
        .filter(campaign => {
            return campaign.audiences?.includes(audience._id);
        })
        .map(campaign => {
            return {
                ...campaign,
                audiences: campaign.audiences.filter(audienceId => audienceId !== audience._id),
            };
        });

    await Data.put<Campaign>(campaigns);
    await Data.delete([audience]);
};

export const audienceCreate = async (input: AudienceCreateInput) => {
    const audience = {
        ...new Audience(),
        ...input,
    };

    audienceMatchContacts(audience);

    await Data.put(audience);
    return audience;
};

export const audienceMatchContacts = (audience: Audience): Array<Customer> => {
    const contacts = matchContactsFromAudience(audience);
    audience.contacts = contacts.map(contact => contact._id);
    return contacts;
};

export const matchContactsFromAudience = (audience: Audience): Array<Customer> => {
    return matchContactsFromFilterGroups(audience.filterGroups);
};

export const audienceUpdate = async (audienceId: string, input: AudienceUpdateInput) => {
    const currentAudience = Data.get<Audience>(audienceId);

    if (currentAudience) {
        const audience: Audience = {
            ...currentAudience,
            ...input,
        };

        // If filters have changed, re-match contacts
        if (input.filterGroups) audienceMatchContacts(audience);

        await Data.put<Audience>(audience);

        return audience;
    }
};

export const audienceGetContacts = (audienceId: string, paging?: { take: number; skip: number }, searchText?: string) => {
    const audience = Data.get<Audience>(audienceId);

    if (!audience) {
        console.error('audienceGetContacts: Unable to find audience')
        return [];
    }

    const customers: Array<Customer> = [];

    for (const contact of audience.contacts ?? []) {
        const customer = Data.get<Customer>(contact);
        if (customer) customers.push(customer);
    }

    let contacts: Array<Customer> = customers;


    if (searchText) {
        contacts = searchCustomers({ customers: contacts, searchText, searchJobs: true });
    }

    contacts.sort((a, b) => a.name.localeCompare(b.name));

    if (paging) {
        return contacts.slice(paging.skip, paging.skip + paging.take);
    }

    return contacts;
};

export const matchAndUpdateCustomersToAudience = async (contacts: Array<Customer>) => {
    const audiences = Data.all<Audience>('audiences');
    const audiencesToUpdate = new Set<Audience>();

    // For every audience, check if the contact matches the audience
    for (const audience of audiences) {
        if (!audience.filterGroups?.length) continue;

        // If the contact matches the audience, add to audience.contacts
        const search = audienceFilterToCustomerSearch(audience.filterGroups);
        const include = searchCustomers({ customers: contacts, searchText: search, searchJobs: true })

        // If the contact is already in the audience, remove it
        if (!audience.contacts) audience.contacts = [];
        for (const contact of include) {
            if (!audience.contacts.includes(contact._id)) {
                audience.contacts.push(contact._id);
                audiencesToUpdate.add(audience);
            }
        }

        for (const contact of contacts.filter(contact => !include.includes(contact))) {
            if (audience.contacts.includes(contact._id)) {
                audience.contacts = audience.contacts.filter(id => id !== contact._id);
                audiencesToUpdate.add(audience);
            }
        }
    }

    if (audiencesToUpdate.size > 0) {
        await Data.put(Array.from(audiencesToUpdate));
    }
};
export const matchContactsFromFilterGroups = (filterGroups: Array<AudienceFilterGroup>): Array<Customer> => {
    const customers = Data.all<Customer>('customers');

    const search = audienceFilterToCustomerSearch(filterGroups);
    const mobilePrefixes = ApplicationState.account.smsMobilePhonePrefixes?.split(',');
    const contacts = searchCustomers({ customers, searchText: search, searchJobs: true, mobilePrefixes });

    return contacts;
};


export const removeContactFromAudiences = async (contact: Customer) => {
    const audiences = Data.all<Audience>('audiences');

    const audiencesToUpdate: Array<Audience> = [];

    for (const audience of audiences) {
        if (audience.contacts) {
            audience.contacts = audience.contacts.filter(id => id !== contact._id);
            audiencesToUpdate.push(audience);
        }
    }

    if (audiencesToUpdate.length > 0) {
        await Data.put(audiencesToUpdate);
    }
};
