import dayjs from 'dayjs';

import moment from 'moment';
import { useMemo } from 'react';
import type { BarData } from '../../components/charts/StackedBarChart';
import type { Stats } from '../utils/StatUtils';
export const useWeekStats = (selectedDate: dayjs.Dayjs, stats: Stats) => {
    const barData = useMemo(() => {
        const weekStart = moment(selectedDate.toDate()).startOf('isoWeek').toDate();

        const daysOfWeek = [0, 1, 2, 3, 4, 5, 6].map(day => dayjs(weekStart).add(day, 'day').toDate());

        // For every day of the week, calculate the recorded time, unrecorded time, and billed time for that day
        const calculatedBarData: BarData[] = daysOfWeek.map(date => {
            const dateKey = dayjs(date).format('YYYY-MM-DD');
            return { date, recordedTime: stats.byDay[dateKey]?.recorded || 0, unrecordedTime: stats.byDay[dateKey]?.unrecorded || 0 };
        });
        return calculatedBarData;
    }, [selectedDate, stats]);

    return { barData };
};
