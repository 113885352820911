import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, IconButton, List, Toolbar, Typography } from '@mui/material';
import { type TimeEntry as RawTimeEntry } from '@nexdynamic/squeegee-common';
import { useContext, useMemo, useState } from 'react';
import { Data } from '../../../../Data/Data';
import { TimerService } from '../../../../Tracking/TimerService';
import StoredObjectUtils from '../../../../utils/StoredObjectUtils';
import TrackerContext from '../../TrackerContext';
import { getStatsForTimeEntries } from '../../utils/StatUtils';
import TimeBreakdown from '../TimeBreakdown';
import TimeEntryListItem from '../TimeEntryListItem';
import EditTimeEntryDialog from './EditTimeEntryDialog';
import DeleteTimeEntryAlert from './alerts/DeleteTimeEntryAlert';

type TrackerListProps = {
    open: boolean;
    onClose: () => void;
    timeEntries: Array<RawTimeEntry>;
    selectedWorkerId: string | null;
    workerName: string;
};

const TrackerList = ({ timeEntries, selectedWorkerId, workerName, open, onClose }: TrackerListProps) => {
    const { dayStats } = useContext(TrackerContext);

    const showScheduleDetailsItem = (timeEntry: RawTimeEntry) => {
        if (!timeEntry.occurrenceId) return;
        const occurrence = Data.get(timeEntry.occurrenceId);
        if (!occurrence) return;

        const uiModel = StoredObjectUtils.getUIModelForStoredObject(occurrence);
        if (!uiModel?.showDialog) return;

        uiModel.showDialog();
    };

    const statsForSelectedWorker = useMemo(() => {
        if (!dayStats?.timeEntries) return null;
        const filteredTimeEntries = dayStats.timeEntries.filter((te: RawTimeEntry) => te.assigneeId === selectedWorkerId);
        return getStatsForTimeEntries(filteredTimeEntries);
    }, [dayStats, selectedWorkerId]);

    const handleEntryDeleted = (timeEntry: RawTimeEntry) => {
        if (timeEntry === null) return;

        setDeleteDialogOpen(false);
        if (!timeEntry.start || !timeEntry.stop) return;
        TimerService.deleteEntry(timeEntry.start);
        TimerService.deleteEntry(timeEntry.stop);
    };

    const [editTimeEntryDialogOpen, setEditTimeEntryDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedRawTimeEntry, setSelectedRawTimeEntry] = useState<RawTimeEntry | undefined>(undefined);

    const timeEntryItems = useMemo(
        () =>
            timeEntries.map(timeEntry => (
                <TimeEntryListItem
                    key={timeEntry.occurrenceId}
                    timeEntry={timeEntry}
                    onDelete={entry => {
                        setSelectedRawTimeEntry(entry);
                        setDeleteDialogOpen(true);
                    }}
                    onEdit={entry => {
                        setSelectedRawTimeEntry(entry);
                        setEditTimeEntryDialogOpen(true);
                    }}
                    onClicked={entry => {
                        showScheduleDetailsItem(entry);
                    }}
                />
            )),
        [timeEntries]
    );

    const ListContent = () => {
        if (timeEntryItems.length) return <>{timeEntryItems}</>;
        if (selectedWorkerId === null) {
            return (
                <>
                    <Box p={2}>
                        <Typography variant="body1">Select a worker to view their time entries</Typography>
                    </Box>
                </>
            );
        } else if (timeEntryItems.length === 0)
            return (
                <>
                    <Box p={2}>
                        <Typography variant="body1">{workerName} has not recorded any time entires for the selected day</Typography>
                    </Box>
                </>
            );

        return null;
    };

    return (
        <>
            <Dialog
                onClose={onClose}
                open={open}
                sx={{
                    paddingBottom: '1.5rem',
                }}
            >
                <Box
                    sx={{
                        'background': '#F5F5F5',
                        'border': 1,
                        'borderColor': 'divider',
                        'borderRadius': 1,
                        'overflowY': 'auto',
                        '&::-webkit-scrollbar': {
                            width: '0.4em',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: 'rgba(0, 0, 0, 0.4)',
                            borderRadius: '0.5rem',
                        },
                        'minWidth': '40rem',
                    }}
                >
                    <Toolbar
                        variant="dense"
                        disableGutters
                        sx={{
                            display: 'flex',
                            paddingY: 0,
                            px: 2,
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant="subtitle1">
                            {`Showing ${timeEntryItems.length} time entries ${selectedWorkerId ? 'for ' + workerName : ''}`}
                        </Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderTop: 1,
                            borderColor: 'divider',
                            py: 1,
                            px: 2,
                        }}
                    >
                        {selectedWorkerId && statsForSelectedWorker && <TimeBreakdown stats={statsForSelectedWorker} />}
                    </Box>
                    <Box
                        sx={{
                            borderTop: 1,
                            borderRadius: '0 0 6px 6px',
                            borderColor: 'divider',
                            width: '100%',
                            background: '#F5F5F5',
                        }}
                    >
                        <List sx={{ padding: 0 }}>
                            <ListContent />
                        </List>
                    </Box>
                </Box>
            </Dialog>

            {selectedRawTimeEntry && (
                <>
                    {editTimeEntryDialogOpen && selectedRawTimeEntry && (
                        <EditTimeEntryDialog
                            key={`${selectedRawTimeEntry.start?.eventTimestamp}-edit-dialog`}
                            selectedTimeEntry={selectedRawTimeEntry}
                            selectedWorkerId={selectedWorkerId}
                            setOpen={setEditTimeEntryDialogOpen}
                            onClose={() => {
                                setEditTimeEntryDialogOpen(false);
                                setSelectedRawTimeEntry(undefined);
                            }}
                            open={editTimeEntryDialogOpen}
                            dialog={true}
                            setDeleteDialog={setDeleteDialogOpen}
                        />
                    )}

                    <DeleteTimeEntryAlert
                        open={deleteDialogOpen}
                        onClose={() => setDeleteDialogOpen(false)}
                        onConfirm={() => handleEntryDeleted(selectedRawTimeEntry)}
                    />
                </>
            )}
        </>
    );
};

export default TrackerList;
