import { useSqueegeeCredits } from '../hooks/useSqueegeeCredits';
import Text from './Text';

export const SqueegeeCredits = () => {
    const { credits } = useSqueegeeCredits();

    const creditsAsString = credits.toString();
    return (
        <Text
            sx={{ display: 'flex', alignContent: 'center', fontSize: 'xx-small' }}
            text='squeegee.credits-total'
            params={{ creditsAsString }}
        />
    );
};
