import { Box } from '@mui/system';
import type { IAdvertisement } from '@nexdynamic/squeegee-common';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AdvertDetails from '../components/dialogs/AdvertDetails';
import MarketplaceNavbar from '../components/MarketplaceNavbar';
import { MyAdvertList } from '../components/MyAdvertList';
import useMyPurchases from '../hooks/useMyPurchases';

export type FilterAdvertsQuery = {
    selectedSellers?: Array<string>;
    tags?: Array<string>;
    keyword?: string;
};

export const MyPurchases = () => {
    const [query, setQuery] = useState<FilterAdvertsQuery>({});
    const { adverts, refetchAdverts } = useMyPurchases(query);
    const rawAdverts = useMyPurchases().adverts;
    const [selectedAdvert, setSelectedAdvert] = useState<IAdvertisement | null>(null);
    const cancelSearch = () => {
        setQuery({
            ...query,
            keyword: '',
        });
    };

    const onQueryChange = (newQuery: FilterAdvertsQuery) => {
        const updatedQuery = {
            ...query,
            ...newQuery,
        };
        setQuery(updatedQuery);
    };

    const navigate = useNavigate();

    const handleClose = () => {
        navigate('/marketplace/my-purchases');
        setSelectedAdvert(null);
    };

    const handleSelect = (selectedAdvert: IAdvertisement) => {
        navigate(`/marketplace/my-purchases/${selectedAdvert.id}`);
    };

    const params = useParams();

    useEffect(() => {
        const id = params['id'] as string;
        if (id) {
            const advert = adverts.find(advert => advert.id === id);

            if (advert) {
                setSelectedAdvert(advert);
            }
        }
    }, [params, adverts]);

    return (
        <Box>
            <MarketplaceNavbar
                refresh={refetchAdverts}
                allData={rawAdverts}
                cancelSearch={cancelSearch}
                requestSearch={onQueryChange}
                searched={query.keyword || ''}
            />

            <MyAdvertList onSelect={handleSelect} adverts={adverts}></MyAdvertList>

            {selectedAdvert && <AdvertDetails onClose={handleClose} open={true} advert={selectedAdvert}></AdvertDetails>}
        </Box>
    );
};
