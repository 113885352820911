import CreateIcon from '@mui/icons-material/Create';
import {
    Autocomplete,
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Popover,
    Select,
    Stack,
    TextField,
} from '@mui/material';
import type { Customer } from '@nexdynamic/squeegee-common';
import { useMemo } from 'react';
import { Data } from '../../../../Data/Data';
import { usePortalLayout } from '../PortalLayoutProvider';
import type { ViewAsCustomer } from '../types/ViewAsCustomer';
import { ListboxComponent } from './Sidebar/VirtualizedAutocompleteAdapter';

type Props = {
    anchorEl: HTMLElement | null;
    open: boolean;
    onClose: () => void;
};

const LayoutEditorSettingsDialog = ({ anchorEl, open, onClose }: Props) => {
    const { storedLayouts, selectedLayout, viewAsCustomer, handleViewAsCustomer, handleLayoutChange, handleNewLayout, handleDeleteLayout } =
        usePortalLayout();

    const viewAsCustomerOptions: Array<ViewAsCustomer> = useMemo(() => {
        const customers = Data.all<Customer>('customers');
        return customers.map(customer => ({ id: customer._id, label: customer.name }));
    }, []);

    return (
        <Popover
            open={open}
            onClose={onClose}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            slotProps={{
                paper: {
                    sx: {
                        minWidth: 400,
                    },
                },
            }}
        >
            <DialogTitle>Layout Editor Settings</DialogTitle>
            <DialogContent>
                <Stack spacing={1} mt={1}>
                    <Stack direction="row" spacing={1} justifyContent="space-between">
                        <FormControl sx={{ minWidth: 64 }} size="small" disabled={storedLayouts.length === 0} fullWidth>
                            <InputLabel id="layout-select-label">Select a layout</InputLabel>
                            <Select
                                labelId="select-layout-label"
                                value={selectedLayout?.name || ''}
                                label="Select Layout"
                                onChange={handleLayoutChange}
                            >
                                <Box sx={{ px: 0.5 }}>
                                    <Button onClick={handleNewLayout} startIcon={<CreateIcon />} fullWidth>
                                        New Layout
                                    </Button>
                                </Box>
                                {storedLayouts.map((layout, index) => (
                                    <MenuItem key={index} value={layout.name}>
                                        {layout.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button onClick={handleDeleteLayout} variant="contained" color="error">
                            Delete
                        </Button>
                        <Button onClick={handleNewLayout} variant="contained" color="info">
                            New
                        </Button>
                    </Stack>

                    <Box sx={{ py: 1 }}>
                        <Autocomplete
                            ListboxComponent={ListboxComponent}
                            options={[{ label: 'None (signed out)', id: '' }, ...viewAsCustomerOptions]}
                            value={viewAsCustomer}
                            defaultValue={{ id: '', label: 'None (signed out)' }}
                            onChange={(_, value) => handleViewAsCustomer(value || { id: '', label: 'None (signed out)' })}
                            getOptionLabel={option => option.label}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={params => <TextField {...params} label="View As" variant="outlined" size="small" fullWidth />}
                            renderOption={(props, option, state) => [props, option, state.index] as React.ReactNode}
                        />
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Popover>
    );
};

export default LayoutEditorSettingsDialog;
