import { Grid, Paper, Typography } from '@mui/material';
import type { BulkPriceChange } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { UserService } from '../../../../../../Users/UserService';

export const ChangeFooter = ({ change }: { change: BulkPriceChange }) => {
    const who = UserService.getUser(change.createdBy)?.name ?? change.createdBy;
    const when = moment(change.createdDate).format('lll');
    return (
        <Paper sx={{ p: 3, mt: 2 }}>
            <Grid item lg={12}>
                <Typography sx={{ mb: 1.5 }} variant='h6'>
                    Meta Data
                </Typography>
            </Grid>

            <Grid container sx={{ mt: 3 }}>
                <Grid item lg={4}>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                        <b>Author:</b>
                        <br></br>
                        {who}
                    </Typography>
                </Grid>
                <Grid item lg={4}>
                    <Typography sx={{ mb: 1.5 }} color='text.secondary'>
                        <b>Created:</b>
                        <br></br>
                        {when}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};
