import GroupsOutlined from '@mui/icons-material/GroupsOutlined';
import { GradientIcon } from '@nexdynamic/nex-ui-react';

const AudiencesIcon = () => {
    return (
        <GradientIcon gradient='audiences'>
            <GroupsOutlined />
        </GradientIcon>
    );
};

export default AudiencesIcon;
