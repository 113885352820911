import ShowChartIcon from '@mui/icons-material/ShowChart';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { ProfitData, RevenueMonthData, RevenueYearData, WorkerRevenueData } from '../data/phdata';
import BleedingLineChart from './charts/BleedingLineChart';
import ComparisonChart from './charts/ComparisonChart';
import LineChart from './charts/LineChart';
import MiniStatBox from './charts/MiniStatBox';

const ChartsDemo = () => {
    const [timeValue, setTimeValue] = useState(false);
    return (
        <>
            <Grid container spacing={2} padding={3}>
                <Grid item>
                    <LineChart
                        state={timeValue}
                        setState={setTimeValue}
                        data={timeValue ? RevenueMonthData : RevenueYearData}
                        sx={{ background: 'linear-gradient(270deg, #1e88e5, #68afec)' }}
                        icon={<ShowChartIcon sx={{ fontSize: '32px', color: 'white' }} />}
                        width='411px'
                        height='184px'
                    />
                </Grid>
                <Grid item>
                    <BleedingLineChart
                        title='Total Profit'
                        subheading='10% Profit'
                        currentValue={ProfitData.total}
                        sx={{ background: '#eaf5ff' }}
                        strokeColor='#1e88e5'
                        data={ProfitData}
                        height='184px'
                        width='411px'
                    />
                </Grid>
                <Grid item>
                    <BleedingLineChart
                        title='Total Revenue'
                        subheading='This month'
                        currentValue={RevenueMonthData.total}
                        sx={{ background: '#ffeaf1' }}
                        strokeColor='#e51e81'
                        data={RevenueMonthData}
                        height='184px'
                        width='411px'
                    />
                </Grid>
                <Grid item>
                    <ComparisonChart
                        title='Workers'
                        subheading='March Revenue'
                        currentValue={WorkerRevenueData.monthTotal}
                        strokeColor='#333'
                        data={WorkerRevenueData}
                        height='184px'
                        width='411px'
                    />
                </Grid>
                <Grid item>
                    <MiniStatBox title='Total Balance' value='£102,842.46' />
                </Grid>
            </Grid>
        </>
    );
};

export default ChartsDemo;
