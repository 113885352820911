import { activationStrategy } from 'aurelia-router';
import { Send } from './Send';
export class SendModule {

    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    send: ReturnType<typeof Send>;

    attached() {
        const container = document.getElementById('send-root');
        if (!container) throw new Error('send-root element not found');
        this.send = Send(container);
    }

    detached() {
        this.send?.unmount();
    }
}
