import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import ViewLoading from '../components/ViewLoading';
import PendingRouter from './PendingRouter';

function PendingApp() {
    return (
        <Suspense fallback={<ViewLoading />}>
            <Routes>
                <Route path="/pending/*" element={<PendingRouter />}></Route>
            </Routes>
        </Suspense>
    );
}

export default PendingApp;
