import { List } from '@mui/material';
import { ContactCard, ContactMoreActions, EmptyList } from '@nexdynamic/nex-ui-react';
import type { Contact } from '@nexdynamic/squeegee-common';
import { type Supplier } from '@nexdynamic/squeegee-common';
import { useTranslation } from 'react-i18next';
import useStoredObjects from '../../hooks/useStoredObjects';

type SupplierContactListProps = {
    supplier: Supplier;
};

const SupplierContactList = ({ supplier }: SupplierContactListProps) => {
    const contacts = useStoredObjects<Contact>('contact', { in: supplier.contactIds });

    const { t } = useTranslation();

    return (
        <List>
            {contacts.length ? (
                contacts.map(contact => (
                    <ContactCard sx={{ mb: 2 }} key={contact._id} contact={contact} actions={<ContactMoreActions contact={contact} />} />
                ))
            ) : (
                <EmptyList text={t('supplier.no-contacts')} />
            )}
        </List>
    );
};

export default SupplierContactList;
