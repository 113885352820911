import { BalanceDbIndexedDbStore } from '@nexdynamic/balance-components-react';
import type { BalanceAccountingMethodType, BalanceJournal, BalanceResponse } from '@nexdynamic/balance-core';
import { BalanceError, GeneralErrorCode, LedgerTypes, balanceError, balanceSuccess } from '@nexdynamic/balance-core';
import type { Transaction } from '@nexdynamic/squeegee-common';
import { BalanceTransformer } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { Data } from '../../Data/Data';
import StoredObjectUtils from '../../utils/StoredObjectUtils';
import { generateMaps } from './generateMaps';

export class SqueegeeBalanceDbIndexedDbStore extends BalanceDbIndexedDbStore {
    static cache_version = '8'; // INCREMENT THIS IF 1. INDEXEDDB SCHEMA CHANGES OR 2. CACHE INVALIDATION IS NEEDED
    static _lastSyncedTimestamp: number | undefined;

    constructor(dataEmail: string) {
        super({
            dbName: `squeegee_balance_v${SqueegeeBalanceDbIndexedDbStore.cache_version}_${dataEmail}`,
        });
    }

    async refresh(
        accountingMethod: BalanceAccountingMethodType,
        full?: boolean,
        processEntry?: ((entry: BalanceJournal, progress: number) => Promise<void>) | undefined
    ): Promise<BalanceResponse> {
        const timestamp = (await this.getLastSyncedTimestamp()) || 0;
        // Do a full sync first to make sure we have the latest data.
        if (full || this._needsRefresh || !timestamp) {
            await this.clear();
        }
        const { paymentAccountToLedgerIdMap, entities, transactionLookupMap } = generateMaps(timestamp);

        const tran = new BalanceTransformer(
            { salesLedgerId: 'sales', taxLedgerId: 'sales', accountingMethod },
            (id: string) => {
                return paymentAccountToLedgerIdMap.get(id) || `${LedgerTypes['assets.current_assets.funds.bank']}.general`;
            },
            (id: string) => transactionLookupMap.get(id)
        );

        let latestJournalTime: Date | undefined;
        let latestJournalId: string | undefined;

        const db = this.db();

        await tran.transform(entities, async (entry: BalanceJournal, progress) => {
            if (!full) {
                // If we are doing a partial update we need to make sure this journal hasn't already been processed
                const count = await db.trans.where('journalId').equals(entry.journalId).count();
                if (count > 0) {
                    console.log(`Journal with id ${entry.journalId} already exists in the database. Skipping.`);
                }
            }

            // Only process entry if it didn't already exist.
            if (processEntry) {
                await processEntry(entry, progress); // Go and add transactions and update balance caches
            }

            // We should keep track of the last successfully processed journal entry
            if (!latestJournalTime || entry.createdAtDate > latestJournalTime) {
                // Do not set AN OBJECT here because we are using object pooling which will cause the object to be reused and become invalid
                latestJournalTime = entry.createdAtDate;
                latestJournalId = entry.journalId;
            }
        });

        if (latestJournalId && latestJournalTime) {
            await db.syncs.put({ timestamp: latestJournalTime.getTime(), journalId: latestJournalId });
        }

        this._needsRefresh = false;
        return balanceSuccess();
    }

    async getLastSyncedTimestamp(): Promise<number | null> {
        return super.getLastSyncedTimestamp();
    }

    async view(uuid: string | bigint): Promise<BalanceResponse> {
        const storedObject = Data.get<Transaction>(uuid.toString());
        if (!storedObject) {
            return balanceError(new BalanceError('Transaction not found.', GeneralErrorCode.UnknownError));
        }
        const uiModel = StoredObjectUtils.getUIModelForStoredObject(storedObject);
        if (!uiModel?.showDialog) {
            return balanceError(new BalanceError('No dialog found for entity.', GeneralErrorCode.UnknownError));
        }

        await uiModel.showDialog();
        return balanceSuccess<undefined>();
    }

    async newRecordsAvailable() {
        const timestamp = await this.getLastSyncedTimestamp();
        const sinceIso = moment(timestamp).format();
        // Do we have new transactions in squeegee since
        const count = Data.count<Transaction>('transactions', tran => tran.createdDate > sinceIso);
        return count > 0;
    }
}
