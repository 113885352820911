import PersonIcon from '@mui/icons-material/PersonRounded';
import { Chip, Paper, Stack, Typography } from '@mui/material';
import { usePortalLayout } from '../../PortalLayoutProvider';
import WidgetSelector from './WidgetSelector';

const PortalLayoutEditorHeader = () => {
    const { viewAsCustomer } = usePortalLayout();

    return (
        <>
            <Paper sx={{ p: 2, pb: 1, position: 'sticky', top: 0, zIndex: 1000 }} elevation={2}>
                <WidgetSelector />
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
                    <Typography variant="caption" color="textSecondary" fontWeight={500}>
                        Note: Some widgets may be disabled because of your authentication settings. Press the settings icon to change this.
                    </Typography>
                    <Chip
                        label={viewAsCustomer ? `Viewing as ${viewAsCustomer.label}` : 'Viewing as signed out'}
                        icon={<PersonIcon />}
                        size="small"
                    />
                </Stack>
            </Paper>
        </>
    );
};

export default PortalLayoutEditorHeader;
