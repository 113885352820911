import type { StoredEvent } from '@nexdynamic/squeegee-common';
import { useMemo } from 'react';
import useStoredObjects from '../../hooks/useStoredObjects';

type Options = {
    date?: string;
    occurrenceId?: string;
    customerId?: string;
    jobId?: string;
    allocateTimeTo?: string;
    dateRange?: {
        start: string;
        end: string;
    };
};

function getStartOfDay(date: string) {
    const dateObj = new Date(date);
    dateObj.setHours(0, 0, 0, 0);
    return dateObj.valueOf();
}

function getStartofNextDay(date: string) {
    const dateObj = new Date(date);
    dateObj.setDate(dateObj.getDate() + 1);
    dateObj.setHours(0, 0, 0, 0);
    return dateObj.valueOf();
}

const useStoredEvents = ({ date, dateRange }: Options) => {
    const storedObjects = useStoredObjects<StoredEvent>('storedevents');
    const filteredTimeEntries = useMemo(() => {
        let fromTimestamp: number, toTimestamp: number;
        if (dateRange) {
            fromTimestamp = getStartOfDay(dateRange.start)
            toTimestamp = getStartofNextDay(dateRange.end)
        } else if (date) {
            fromTimestamp = getStartOfDay(date);
            toTimestamp = getStartofNextDay(date);
        } else {
            throw new Error('Either date or dateRange must be provided @useStoredEvents:41');
        }
        return storedObjects.filter(x => x.eventTimestamp >= fromTimestamp && x.eventTimestamp < toTimestamp);
    }, [date, dateRange, storedObjects]);

    return filteredTimeEntries;
};

export default useStoredEvents;