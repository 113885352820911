import { activationStrategy } from 'aurelia-router';
import { EmailSettings } from './EmailSettings';
export class EmailSettingsModule {
    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    emailSettings: ReturnType<typeof EmailSettings>;

    attached() {
        const container = document.getElementById('email-settings-root');
        if (!container) throw new Error('email-settings-root element not found');
        this.emailSettings = EmailSettings(container);
    }

    detached() {
        this.emailSettings?.unmount();
    }
}
