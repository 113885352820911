import { AlphanumericCharColourDictionary } from "@nexdynamic/squeegee-common";

const colours = new AlphanumericCharColourDictionary();

export default function getColourForText(text: string, light?: boolean): string {
    const color = colours[text.substring(0, 1).toUpperCase()] || "#000";

    if (light) {
        return add50percentAlpha(color)
    }
    return color;
}

function add50percentAlpha(color: string) {
    return color + 'AA';
}

