import type { useConfirm, useNexAction } from '@nexdynamic/nex-ui-react';
import type { BulkPriceChange, Customer, StoredObject } from '@nexdynamic/squeegee-common';
import { applyBulkPriceChangeToJobsAndNotDoneFutureOccurrences } from '@nexdynamic/squeegee-common';
import { Data } from '../../../../../Data/Data';
import { LoaderEvent } from '../../../../../Events/LoaderEvent';
import type useTranslation from '../../../../hooks/useTranslation';
import { getAffectedJobsAndOccurrences } from '../views/jobs/getAffectedJobsAndOccurrences';

export const applyPriceChange = async (
    bulkPriceChange: BulkPriceChange,
    askForConfirmation: ReturnType<typeof useConfirm>['askForConfirmation'],
    handleAction: ReturnType<typeof useNexAction>['0'],
    t: ReturnType<typeof useTranslation>['t']
) => {
    const translations = { name: bulkPriceChange.name || '' };
    try {
        const result = await handleAction(
            { successMsg: t('price-change.applied', translations), errorMsg: t('price-change.apply-failed', translations) },
            async () => {
                if (bulkPriceChange?.affectedJobFilter) {
                    const { agreed } = await askForConfirmation({
                        title: t('price-change.apply-confirmation-title'),
                        description: t('price-change.apply-confirmation-description', translations),
                        agreeLabel: t('general.apply'),
                        disagreeLabel: t('general.cancel'),
                    });
                    if (agreed && bulkPriceChange?._id) {
                        const jobsAndOccurrences = getAffectedJobsAndOccurrences(bulkPriceChange.affectedJobFilter);

                        new LoaderEvent(true, true, 'price-change.compiling-changes');

                        const { updates } = applyBulkPriceChangeToJobsAndNotDoneFutureOccurrences({
                            bulkPriceChange,
                            jobsAndOccurrences,
                        });

                        new LoaderEvent(false);

                        if (bulkPriceChange.result?.success) {
                            const toSave: Array<StoredObject> = [bulkPriceChange];
                            if (updates?.length) {
                                for (const update of updates) {
                                    const customer = await Data.get<Customer>(update.job.customerId);
                                    if (!customer) continue;

                                    customer.jobs[update.job._id] = update.job;
                                    toSave.push(customer);
                                    toSave.push(...update.occurrences);
                                }
                            }
                            await Data.put(toSave);
                            return { success: true };
                        } else {
                            return { success: false, errorMsg: 'price-change.apply-failed' };
                        }
                    } else {
                        return { success: true, notify: false };
                    }
                }

                return { success: false, errorMsg: 'price-change.not-found' };
            }
        );

        return result;
    } finally {
        new LoaderEvent(false);
    }
};
