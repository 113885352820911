import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import type { SxProps } from '@mui/material';
import { Avatar, Button, Grid, Tooltip, Typography } from '@mui/material';
import Card from '../styled-components/Card';
import type { LineChartData } from '../types';
import BareLineChart from './base/BareLineChart';

type LineChartProps = {
    state: boolean;
    setState: React.Dispatch<React.SetStateAction<boolean>>;
    icon?: React.ReactNode;
    data: LineChartData;
    width?: string | number;
    height?: string | number;
    sx?: SxProps;
    onRemove?: () => void;
};

const LineChart = ({ state, setState, sx = {}, icon, data, width, height }: LineChartProps) => {
    const handleChangeTime = (newValue: any) => {
        setState(newValue);
    };

    const formatCurrency = (value: string | number) => {
        if (typeof value === 'string') {
            return value;
        } else {
            return value.toLocaleString('en-US', {
                maximumFractionDigits: 0,
                style: 'currency',
                currency: 'GBP',
            });
        }
    };

    const trendIcon = () => {
        const initialValue = data.chartData.series[0].data[0];
        const currentValue = data.chartData.series[0].data[data.chartData.series[0].data.length - 1];
        if (currentValue && initialValue) {
            if (currentValue > initialValue) {
                return <TrendingUpIcon sx={{ color: 'white' }} />;
            } else if (currentValue < initialValue) {
                return <TrendingDownIcon sx={{ color: 'white' }} />;
            } else {
                return <TrendingFlatIcon sx={{ color: 'white' }} />;
            }
        }
    };

    const revenueTrend = () => {
        const initialValue = data.chartData.series[0].data[0] as number;
        const currentValue = data.chartData.series[0].data[data.chartData.series[0].data.length - 1] as number;
        if (!isNaN(initialValue) && !isNaN(currentValue)) {
            const percentage = Math.round(((currentValue - initialValue) / initialValue) * 100);
            if (currentValue > initialValue) {
                return `Your revenue is rising by ${percentage}%`;
            } else if (currentValue < initialValue) {
                return `Your revenue is falling by ${percentage}%`;
            } else {
                return `Your average revenue this month is the same as last month`;
            }
        } else {
            return 'No data available for revenue trend analysis';
        }
    };

    return (
        <Card width={width} height={height} sx={{ p: 2, ...sx }}>
            <Grid container direction='column'>
                <Grid item xs={12}>
                    <Grid container justifyContent='space-between'>
                        <Grid item>
                            <Avatar variant='rounded' sx={{ bgcolor: 'rgba(0, 0, 0, 0.35)', borderRadius: '8px' }}>
                                {icon}
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Button
                                disableElevation
                                variant={state ? 'contained' : 'text'}
                                size='small'
                                sx={{ color: 'white', textTransform: 'none' }}
                                onClick={() => handleChangeTime( true)}
                            >
                                Month
                            </Button>
                            <Button
                                disableElevation
                                variant={!state ? 'contained' : 'text'}
                                size='small'
                                sx={{ color: 'white', textTransform: 'none' }}
                                onClick={() => handleChangeTime( false)}
                            >
                                Year
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sx={{ mb: 0.75 }}>
                    <Grid container alignItems='center'>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item>
                                    <Typography
                                        sx={{
                                            color: 'white',
                                            fontSize: '2.125rem',
                                            fontWeight: 500,
                                            mr: 1,
                                            mt: 1.75,
                                        }}
                                    >
                                        {formatCurrency(data.total)}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Tooltip title={revenueTrend()}>
                                        <Avatar
                                            sx={{
                                                bgcolor: 'rgba(255, 255, 255, 0.3)',
                                                width: '28px',
                                                height: '28px',
                                            }}
                                        >
                                            {trendIcon()}
                                        </Avatar>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 500,
                                        color: 'rgba(255, 255, 255, 0.9)',
                                    }}
                                >
                                    Revenue
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <BareLineChart width='188' height='90' categories={data.chartData.categories} series={data.chartData.series} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

export default LineChart;
