import type { ComponentNames } from '@nexdynamic/squeegee-portal-common';
import { ApplicationState } from '../../../../ApplicationState';
import { NotifyUserMessage } from '../../../../Notifications/NotifyUserMessage';

export const clearPortalComponentSetting = async (id: ComponentNames) => {
    ApplicationState.clearSetting(`portal.component-${id}`);

    new NotifyUserMessage('portal-settings.settings-cleared');

    return;
};
