import { ListItemText } from '@mui/material';
import { ElevatedListItemButton } from '@nexdynamic/nex-ui-react';
import type { Audience } from '@nexdynamic/squeegee-common';
import { useNavigate } from 'react-router';
import useStoredObject from '../../../../hooks/useStoredObject';
import ContactsCount from '../../contacts/components/ContactsCount';

type AudienceListItemProps = {
    audienceId: string;
};

const AudienceListItem = ({ audienceId }: AudienceListItemProps) => {
    const navigate = useNavigate();
    const audience = useStoredObject<Audience>(audienceId);

    if (!audience) return null;

    const view = () => {
        navigate(`/send/audiences/${audience._id}`);
    };

    return (
        <ElevatedListItemButton sx={{ minHeight: 55 }} dense role='listitem' onClick={view}>
            <ListItemText primary={audience.name} secondary={audience.description} />
            <ContactsCount count={audience.contacts?.length || 0} />
        </ElevatedListItemButton>
    );
};

export default AudienceListItem;
