import { css } from '@emotion/react';

export const RichTextContentStyles = css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        font-family: Arial, Helvetica, sans-serif;
        margin: 0;
        padding: 0;
    }

    h1 small,
    h2 small,
    h3 small,
    h4 small,
    h5 small,
    h6 small {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 400;
        line-height: 1.35;
        letter-spacing: -0.02em;
        font-size: 0.6em;
    }

    h1 {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 400;
        line-height: 1.35;
        letter-spacing: -0.02em;
        margin-top: 24px;
        margin-bottom: 24px;
    }

    h2 {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 45px;
        font-weight: 400;
        line-height: 48px;
        margin-top: 24px;
        margin-bottom: 24px;
    }

    h3 {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 34px;
        font-weight: 400;
        line-height: 40px;
        margin-top: 24px;
        margin-bottom: 24px;
    }

    h4 {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 24px;
        margin-bottom: 16px;
    }

    h5 {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: 0.02em;
        margin-top: 24px;
        margin-bottom: 16px;
    }

    h6 {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.04em;
        margin-top: 24px;
        margin-bottom: 16px;
    }

    p {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        margin-bottom: 16px;
    }

    a {
        font-family: Arial, Helvetica, sans-serif;
        color: #2626e8;
        font-weight: 500;
    }

    blockquote {
        font-family: Arial, Helvetica, sans-serif;
        position: relative;
        font-size: 24px;
        font-weight: 300;
        font-style: italic;
        line-height: 1.35;
        letter-spacing: 0.08em;
    }

    mark {
        font-family: Arial, Helvetica, sans-serif;
        background-color: #f4ff81;
    }

    dt {
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 700;
    }

    address {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0;
        font-style: normal;
    }

    ul,
    ol {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
    }
    .align-right {
        text-align: right;
    }
    .align-center {
        text-align: center;
    }
    .align-left {
        text-align: left;
    }
    img {
        max-width: 100%;
    }
`;
