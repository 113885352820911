import WebOutlined from '@mui/icons-material/WebOutlined';
import { GradientIcon } from '@nexdynamic/nex-ui-react';

const TemplatesIcon = () => {
    return (
        <GradientIcon gradient='templates'>
            <WebOutlined />
        </GradientIcon>
    );
};

export default TemplatesIcon;
