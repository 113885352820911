import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { useEffect } from 'react';
import { attachSendCustomerCampaignMessageLink } from '../../../../../Notifications/Utils/smsAttachLinkToMessage';
import { Utilities } from '../../../../../Utilities';
import TokenTextField from '../../../../components/inputs/TokenTextField';
import useTranslation from '../../../../hooks/useTranslation';
import type { TemplateSmsFormFields } from '../forms/TemplateSmsForm';

type Props = {
    fieldName: keyof TemplateSmsFormFields;
    subject?: string;
};

export const TemplateSMSInput = ({ fieldName, subject: fieldSubject }: Props) => {
    const { t } = useTranslation();
    const { values, setFieldValue } = useFormikContext<TemplateSmsFormFields>();

    const useSubjectAsSmsMessage = values['useSubjectAsSmsMessage'];
    const subject = fieldSubject;

    const includeLink = values['includeLink'];
    const message = values[fieldName] as string;

    // Random uuid to calculate cost
    const creditsCost = includeLink
        ? Utilities.getSmsLengthText(
              attachSendCustomerCampaignMessageLink({
                  message,
                  notificationId: '00000000-0000-0000-0000-000000000000',
              })
          )
        : Utilities.getSmsLengthText(message);

    useEffect(() => {
        if (useSubjectAsSmsMessage) setFieldValue(fieldName, subject);
    }, [subject, useSubjectAsSmsMessage, fieldName, setFieldValue]);

    const toggleUseSubject = () => {
        if (useSubjectAsSmsMessage) {
            setFieldValue(fieldName, subject || '');
        }
        setFieldValue('useSubjectAsSmsMessage', !useSubjectAsSmsMessage);
    };

    return (
        <>
            <FormControlLabel
                sx={{ mb: 1 }}
                value="end"
                control={<Checkbox checked={useSubjectAsSmsMessage} onChange={() => toggleUseSubject()} />}
                label={t('templates-form.sameAsSubject')}
            />
            <Field
                sx={{ width: '100%', mb: 1 }}
                component={TokenTextField}
                multiline
                readOnly={useSubjectAsSmsMessage}
                disabled={useSubjectAsSmsMessage}
                type="text"
                label={t('templates-form.smsTemplate.label')}
                name={fieldName}
            />

            <Box sx={{ display: 'flex' }}>
                <Box sx={{ flex: 1, pt: 1 }}>
                    <FormControlLabel
                        value="end"
                        control={<Checkbox checked={includeLink} onChange={() => setFieldValue('includeLink', !includeLink)} />}
                        label={t('templates-form.includeLink')}
                    />
                </Box>
                <Box sx={{ textAlign: 'right' }}>
                    <Typography variant="caption">{creditsCost}</Typography>
                    {includeLink && (
                        <Box>
                            <Typography variant="caption">*{t('templates-form.includingLinkCost')}</Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default TemplateSMSInput;
