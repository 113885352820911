import type { Customer } from '@nexdynamic/squeegee-common';
import { useEffect, useState } from 'react';
import { audienceGetContacts } from '../AudienceService';

type Options = {
    search?: string;
    paging?: { take: number; skip: number };
};

type UseAudienceContacts = (
    audienceId: string,
    options?: Options
) => {
    contacts: Customer[];
};

const useAudienceContacts: UseAudienceContacts = (id, options = {}) => {
    const [contacts, setContacts] = useState<Customer[]>(audienceGetContacts(id, options.paging, options.search));

    useEffect(() => {
        const contacts = audienceGetContacts(id, options.paging, options.search);
        setContacts(contacts);
    }, [options?.paging?.skip, options.search, id]);

    return { contacts };
};

export default useAudienceContacts;
