import type {
    AutoMailServerConfig,
    EmailEngineAccountVerificationResponse,
    EmailEngineIMAPConfig,
    EmailEngineRequestError,
    EmailEngineSMTPConfig,
} from '@nexdynamic/squeegee-common';
import { copyObject } from '@nexdynamic/squeegee-common';
import { enqueueSnackbar } from 'notistack';
import type React from 'react';
import { createContext, useContext, useState } from 'react';
import { ApplicationState } from '../../../ApplicationState';
import { Logger } from '../../../Logger';
import { Api } from '../../../Server/Api';
import { Utilities } from '../../../Utilities';

export type SmtpImapSettings = {
    smtp?: EmailEngineSMTPConfig & { valid?: boolean };
    imap?: EmailEngineIMAPConfig & { valid?: boolean };
};

type MailServerContextType = {
    autoConfig?: AutoMailServerConfig;
    updateAutoConfig: (emailAddress: string) => Promise<void>;
    configErrors: Array<{ message: string; key: string }>;
    removeConfigError: (key: string) => void;
    mailServerErrors: { smtp?: string; imap?: string } | undefined;
    mailServerSettings: SmtpImapSettings;
    setMailServerSettings: React.Dispatch<React.SetStateAction<SmtpImapSettings>>;
    verifySmtpImapSettings: ({ smtp, imap }: { smtp?: EmailEngineSMTPConfig; imap?: EmailEngineIMAPConfig }) => Promise<boolean>;
    verifyingSmtpImapSettings: boolean;
};

const MailServerContext = createContext<MailServerContextType | undefined>(undefined);

export const MailServerProvider = ({ children }: { children: React.ReactNode }) => {
    const [autoConfig, setAutoConfig] = useState<AutoMailServerConfig>();
    const [configErrors, setConfigErrors] = useState<Array<{ message: string; key: string }>>([]);
    const [mailServerErrors, setMailServerErrors] = useState<{ smtp?: string; imap?: string } | undefined>();
    const [mailServerSettings, setMailServerSettings] = useState<SmtpImapSettings>({});
    const [verifyingSmtpImapSettings, setVerifyingSmtpImapSettings] = useState(false);

    const awaitConnected = async () => {
        const url = '/api/email-engine/await-connected';
        const response = await Api.get<{ connected: boolean; error?: string }>(null, url).catch(() => undefined);

        if (!response) return Logger.error('Failed to check the connected state of the Email Engine account.'), false;

        return response.data.connected;
    };

    const verifySmtpImapSettings = async ({
        smtp,
        imap,
    }: {
        smtp?: EmailEngineSMTPConfig;
        imap?: EmailEngineIMAPConfig;
    }): Promise<boolean> => {
        setMailServerErrors(undefined);
        setVerifyingSmtpImapSettings(true);
        const existingSmtp = mailServerSettings.smtp && copyObject(mailServerSettings.smtp);
        if (existingSmtp) delete existingSmtp.valid;

        const existingImap = mailServerSettings.imap && copyObject(mailServerSettings.imap);
        if (existingImap) delete existingImap.valid;

        const response = await Api.post<EmailEngineAccountVerificationResponse | EmailEngineRequestError>(
            null,
            '/api/email-engine/verify-account',
            {
                smtp: smtp || existingSmtp,
                imap: imap || existingImap,
            }
        ).then(res => res?.data);

        let isValid = true;
        if (!response || typeof response?.error === 'string') {
            enqueueSnackbar('Failed to verify SMTP settings', { variant: 'error' });
            setConfigErrors(response?.fields || []);
            setVerifyingSmtpImapSettings(false);

            isValid = false;
            return isValid;
        }

        if (smtp) {
            if (response.smtp?.success) {
                await Api.post(null, '/api/email-engine/account', { smtp });
                setMailServerSettings({ ...mailServerSettings, smtp: { ...smtp, valid: true } });
                enqueueSnackbar('SMTP settings verified', { variant: 'success' });
            } else {
                if (response.smtp?.error) setConfigErrors([{ message: response.smtp?.error, key: '' }]);
                setMailServerSettings({ ...mailServerSettings, smtp: { ...smtp, valid: false } });
                isValid = false;
                setMailServerErrors({ smtp: response.smtp?.responseText });
                enqueueSnackbar('Failed to verify SMTP settings', { variant: 'error' });
            }
        }

        if (imap) {
            if (response.imap?.success) {
                await Api.post(null, '/api/email-engine/account', { imap });
                setMailServerSettings({ ...mailServerSettings, imap: { ...imap, valid: true } });
                enqueueSnackbar('IMAP settings verified', { variant: 'success' });
            } else {
                if (response.imap?.error) setConfigErrors([{ message: response.imap?.error, key: '' }]);
                setMailServerSettings({ ...mailServerSettings, imap: { ...imap, valid: false } });
                isValid = false;
                setMailServerErrors({ imap: response.imap?.responseText });
                enqueueSnackbar('Failed to verify IMAP settings', { variant: 'error' });
            }
        }

        if (response.smtp?.success && response.imap?.success) {
            ApplicationState.account.emailAPI = 'email-engine';
            await ApplicationState.save();
            await awaitConnected();
            enqueueSnackbar('All account settings verified, two way email enabled.', { variant: 'success' });
        }

        setVerifyingSmtpImapSettings(false);

        return isValid;
    };

    const removeConfigError = (key: string) => {
        const newErrors = configErrors.filter(x => x.key !== key);
        setConfigErrors(newErrors);
    };

    const updateAutoConfig = async (emailAddress: string) => {
        if (!Utilities.validateEmail(emailAddress)) return;

        const response = await Api.get<AutoMailServerConfig>(
            null,
            '/api/email-engine/get-auto-mail-server-config?email=' + encodeURIComponent(emailAddress)
        ).catch(() => undefined);
        setAutoConfig(response?.data);
    };

    return (
        <MailServerContext.Provider
            value={{
                configErrors,
                mailServerErrors,
                removeConfigError,
                mailServerSettings,
                setMailServerSettings,
                verifySmtpImapSettings,
                verifyingSmtpImapSettings,
                autoConfig,
                updateAutoConfig,
            }}
        >
            {children}
        </MailServerContext.Provider>
    );
};

export const useMailServer = () => {
    const context = useContext(MailServerContext);
    if (context === undefined) {
        throw new Error('useMailServer must be used within an MailServerProvider');
    }
    return context;
};
