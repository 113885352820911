import styled from '@emotion/styled';
import { Paper, Typography } from '@mui/material';
import type { TimeEntry } from '@nexdynamic/squeegee-common';
import { useMemo } from 'react';
import { TimerService } from '../../../Tracking/TimerService';

interface TimeBlockProps {
    id: string;

    entry: TimeEntry;
    containerWidth: number;
    workingHours: number;
    workingDayStart: Date;
    setSelectedTimeEntry: (timeEntry: TimeEntry | null) => void;
    setEditDialogOpen: (open: boolean) => void;
    setEditDialogAnchorPosition: (position: { top: number; left: number } | null) => void;
    refTime: Date;
}

const TimeBlock = ({
    id,
    entry,
    containerWidth,
    workingHours,
    workingDayStart,
    setSelectedTimeEntry,
    setEditDialogOpen,
    setEditDialogAnchorPosition,
    refTime,
}: TimeBlockProps) => {
    const handleContextMenu = (event: React.MouseEvent) => {
        event.stopPropagation();
        setEditDialogAnchorPosition({
            top: event.clientY - 6,
            left: event.clientX + 2,
        });
        setSelectedTimeEntry(entry);
        setEditDialogOpen(true);
    };
    function getBlockColour() {
        const entryType = entry.type;
        if (entryType === 'unrecorded') {
            return '#F66578';
        } else if (entryType === 'recorded') {
            return '#445F8C';
        } else if (entryType === 'running') {
            return '#65b769';
        } else {
            return '#000';
        }
    }

    const referenceTime = useMemo(() => {
        const date = new Date(refTime);
        date.setHours(workingDayStart.getHours());
        date.setMinutes(0);
        date.setSeconds(0);
        return date;
    }, [refTime, workingDayStart]);

    const calculateXPosition = ({ startTime, containerWidth, referenceTime }: CalculateXPositionProps) => {
        const millisecondsInAMinute = 60000;
        const millisecondsInAnHour = 60 * millisecondsInAMinute;
        const startTimeInMilliseconds = startTime.getTime();
        const referenceTimeInMilliseconds = referenceTime.getTime();

        const hoursDifference = (startTimeInMilliseconds - referenceTimeInMilliseconds) / millisecondsInAnHour;
        const minuteWidth = containerWidth / (workingHours * 60);

        return minuteWidth * hoursDifference * 60;
    };

    function calculateBlockWidth({ startTime, endTime, containerWidth }: TimeToPixelsProps): { minuteWidth: number; blockWidth: number } {
        const millisecondsInAMinute = 60000;
        const startTimeInMilliseconds = startTime.getTime();
        const endTimeInMilliseconds = endTime.getTime();

        const durationInMinutes = (endTimeInMilliseconds - startTimeInMilliseconds) / millisecondsInAMinute;

        // Calculate the width of a minute and then the width of the block
        const minuteWidth = containerWidth / (workingHours * 60);
        const blockWidth = minuteWidth * durationInMinutes;

        return {
            minuteWidth,
            blockWidth,
        };
    }

    const xPos = calculateXPosition({
        startTime: entry.start?.eventTimestamp ? new Date(entry.start.eventTimestamp) : new Date(),
        referenceTime,
        containerWidth,
    });

    const blockWidth = calculateBlockWidth({
        startTime: entry.start?.eventTimestamp ? new Date(entry.start.eventTimestamp) : new Date(),
        endTime: entry.stop?.eventTimestamp ? new Date(entry.stop.eventTimestamp) : new Date(),
        containerWidth,
    }).blockWidth;

    const SweepAnimation =
        entry.type === 'running'
            ? styled(Paper)({
                  '@keyframes sweep': {
                      from: {
                          backgroundPosition: '-640px 0',
                      },
                      to: {
                          backgroundPosition: '640px 0',
                      },
                  },
                  'animation': 'sweep 2.8s linear infinite',
                  'animationDuration': '2.8s',
                  'animationFillMode': 'forwards',
                  'animationIterationCount': 'infinite',
                  'animationTimingFunction': 'linear',
                  'background': 'linear-gradient(to right, #65b769 0%, #7cd581 38%, #65b769 44%)',
                  'backgroundSize': '640px 640px',
              })
            : Paper;

    return (
        <>
            <SweepAnimation
                id={id}
                onClick={
                    entry.type !== 'running'
                        ? handleContextMenu
                        : event => {
                              entry.start ? (event.stopPropagation(), TimerService.showScheduleItemForActiveTimer(entry.start)) : undefined;
                          }
                }
                sx={{
                    zIndex: 2,
                    position: 'absolute',
                    left: `calc(${xPos}px)`,
                    width: `${blockWidth}px`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: getBlockColour(),
                    borderRadius: '4px',
                    height: '40px',
                    cursor: 'pointer',
                }}
                elevation={3}
            >
                <Typography fontSize={9} fontWeight='700' fontFamily='Roboto' color='white'>
                    {entry.start &&
                        entry.stop &&
                        `${
                            Math.floor((entry.stop.eventTimestamp - entry.start.eventTimestamp) / (1000 * 60 * 60)) > 0
                                ? Math.floor((entry.stop.eventTimestamp - entry.start.eventTimestamp) / (1000 * 60 * 60)) + 'h '
                                : ''
                        }
                    ${
                        Math.floor(((entry.stop.eventTimestamp - entry.start.eventTimestamp) / (1000 * 60)) % 60) < 10
                            ? ''
                            : Math.floor(((entry.stop.eventTimestamp - entry.start.eventTimestamp) / (1000 * 60)) % 60) + 'm'
                    }`}
                </Typography>
            </SweepAnimation>
        </>
    );
};

type CalculateXPositionProps = {
    startTime: Date;
    containerWidth: number;
    referenceTime: Date;
};

type TimeToPixelsProps = {
    startTime: Date;
    endTime: Date;
    containerWidth: number;
};

export default TimeBlock;
