import { List, ListItem, ListItemText, Stack, TextField } from '@mui/material';
import { debounce } from '@nexdynamic/squeegee-common';
import { portalComponentSettingsDefaults } from '@nexdynamic/squeegee-portal-common';
import { useState } from 'react';
import { ApplicationState } from '../../../../ApplicationState';

const SignInComponentSettings = () => {
    const settingDefaults = portalComponentSettingsDefaults['sign-in'];
    type SettingType = typeof settingDefaults | { redirectUrl: string };
    const currentSettings = ApplicationState.getSetting<SettingType>('portal.component-sign-in', settingDefaults);
    const [signInSettings, setSignInSettings] = useState<SettingType>(currentSettings);

    const onSave = (value: SettingType) => {
        ApplicationState.setSetting('portal.component-sign-in', value);
        console.log('Saved', value);
    };

    const debouncedSave = debounce(onSave, 1000);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const newSettings = { ...signInSettings, redirectUrl: value };
        setSignInSettings(newSettings);
        debouncedSave(newSettings);
    };

    return (
        <List>
            <ListItem>
                <Stack width="100%">
                    <ListItemText primary="Redirect URL" secondary="The relative URL to redirect to after signing in." />
                    <TextField size="small" value={signInSettings.redirectUrl} onChange={onChange} />
                </Stack>
            </ListItem>
        </List>
    );
};

export default SignInComponentSettings;
