import { Box, Button, Card, List, ListItem, ListItemSecondaryAction, ListItemText, Switch } from '@mui/material';
import { portalComponentSettingsDefaults } from '@nexdynamic/squeegee-portal-common';
import { useState } from 'react';
import { ApplicationState } from '../../../../ApplicationState';
import type { AureliaReactComponentDialogComponent } from '../../../../Dialogs/AureliaReactComponentDialogComponent';
import { setPortalComponentSetting } from './setPortalComponentSetting';

export const AppointmentHistorySettingsEditor: AureliaReactComponentDialogComponent<any> = () => {
    const settingDefaults = portalComponentSettingsDefaults['appointment-history'];
    type SettingType = typeof settingDefaults;

    const appointmentHistoryComponentSettings = ApplicationState.getSetting<SettingType>(
        'portal.component-appointment-history',
        settingDefaults
    );
    const [appointmentHistorySettings, setAppointmentHistorySettings] = useState<SettingType>(appointmentHistoryComponentSettings);

    return (
        <>
            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                    <Button
                        variant="contained"
                        sx={{ mr: 2, mt: 2 }}
                        onClick={() => setPortalComponentSetting('appointment-history', appointmentHistorySettings)}
                    >
                        {ApplicationState.localise('general.save-button')}
                    </Button>
                </Box>
                <Card sx={{ m: 2 }} variant="outlined">
                    <List>
                        <ListItem>
                            <ListItemText>
                                {ApplicationState.localise('portal-component.appointment-history-view-attachments')}
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <Switch
                                    checked={appointmentHistorySettings.viewAttachments}
                                    color="secondary"
                                    onChange={e =>
                                        setAppointmentHistorySettings({
                                            ...appointmentHistorySettings,
                                            viewAttachments: e.target.checked,
                                        })
                                    }
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Card>
            </Box>
        </>
    );
};
