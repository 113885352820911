import type { SxProps, Theme } from '@mui/material';
import { alpha } from '@mui/system';

export const analyticsPortalLineChartSx: SxProps = (theme: Theme) => {
    return {
        'position': 'relative',
        '& .MuiAreaElement-series-views': {
            fill: alpha(theme.palette.info.dark, 0.1),
        },
        '& .MuiAreaElement-series-views-incomplete': {
            fill: alpha(theme.palette.info.dark, 0.1),
        },
        '& .MuiLineElement-series-views-incomplete': {
            strokeDasharray: '4 8',
            strokeLinecap: 'round',
        },
        '& .MuiAreaElement-series-leads-raw': {
            fill: alpha(theme.palette.info.dark, 0.1),
        },
        '& .MuiAreaElement-series-leads-pending': {
            fill: alpha(theme.palette.warning.main, 0.1),
        },
        '& .MuiAreaElement-series-leads-accepted': {
            fill: alpha(theme.palette.success.main, 0.1),
        },
        '& .MuiAreaElement-series-leads-declined': {
            fill: alpha(theme.palette.error.dark, 0.1),
        },
        '& .MuiAreaElement-series-leads-expired': {
            fill: alpha(theme.palette.grey[500], 0.1),
        },
        '& .MuiAreaElement-series-leads-converted': {
            fill: alpha(theme.palette.success.dark, 0.1),
        },
        '& .MuiAreaElement-series-leads-active': {
            fill: alpha(theme.palette.success.light, 0.1),
        },
        '& .MuiAreaElement-series-conversions': {
            fill: alpha(theme.palette.success.dark, 0.1),
        },
        '& .MuiAreaElement-series-abandons': {
            fill: alpha(theme.palette.grey[500], 0.1),
        },

        '& .MuiChartsAxisHighlight-root': {
            strokeDasharray: 0,
            strokeWidth: 2,
        },
    };
};
