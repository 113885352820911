import type { Audience, AudienceUpdateInput } from '@nexdynamic/squeegee-common';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import useTranslation from '../../../../hooks/useTranslation';
import { audienceUpdate } from '../AudienceService';
import { AudienceFormFields } from '../component/AudienceForm';

type UseUpdateAudience = (id: string) => {
    running: boolean;
    updateAudience: (fields: Partial<AudienceFormFields>) => Promise<Audience | null | undefined>;
};

export const toAudienceUpdateInput = (fields: Partial<AudienceFormFields>): Partial<AudienceUpdateInput> => {
    return {
        ...fields,
    };
};

const useUpdateAudience: UseUpdateAudience = id => {
    const [running, setRunning] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const updateAudience = async (fields: Partial<AudienceFormFields>) => {
        try {
            setRunning(true);
            const input = AudienceFormFields.toAudienceUpdateInput(fields);
            const audience = await audienceUpdate(id, input);
            enqueueSnackbar(t('audiences.saved', { name: fields.name || '' }), { variant: 'success' });
            return audience;
        } catch (error) {
            console.error(error);
            enqueueSnackbar(t('audiences.save-error'), { variant: 'error' });
        }
        setRunning(false);
    };

    return {
        running,
        updateAudience,
    };
};

export default useUpdateAudience;
