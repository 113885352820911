import type { AccountUser, TimeEntry as RawTimeEntry } from '@nexdynamic/squeegee-common';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { createContext, useEffect, useMemo, useState } from 'react';
import { UserService } from '../../Users/UserService';
import useTimeEntries, { getDateRangeByWeek } from './hooks/useTimeEntries';
import { useUnboundStoredEvents } from './hooks/useUnboundStoredEvents';
import { getStatsForTimeEntries, type Stats } from './utils/StatUtils';

type TrackerContextType = {
    selectedWorkerId: string | null;
    setSelectedWorkerId: (id: string | null) => void;
    selectedDate: Dayjs;
    setSelectedDate: (date: Dayjs) => void;
    workDayStart: Date;
    setWorkDayStart: (date: Date) => void;
    workDayEnd: Date;
    setWorkDayEnd: (date: Date) => void;
    showUnboundEvents: boolean;
    setShowUnboundEvents: (showUnboundEvents: boolean) => void;
    showUnrecordedJobs: boolean;
    setShowUnrecordedJobs: (showUnrecordedJobs: boolean) => void;
    unrecordedTimeThreshold: number;
    setUnrecordedTimeThreshold: (unrecordedTimeThreshold: number) => void;
    weekStats: Stats;
    dayStats: { date: string; recorded: number; unrecorded: number; timeEntries: RawTimeEntry[] };
    workers: AccountUser[];
    unboundEvents: RawTimeEntry[];
    showAllWorkers: boolean;
    setShowAllWorkers: (showAllWorkers: boolean) => void;
    timeEntries: Array<RawTimeEntry>;
};

export const TrackerContext = createContext<TrackerContextType>({} as TrackerContextType);

export const TrackerProvider = ({ children }: { children: React.ReactNode }) => {
    const [showAllWorkers, setShowAllWorkers] = useState<boolean>(false);
    const [selectedWorkerId, setSelectedWorkerId] = useState<string | null>(null);
    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs(new Date()));
    const [workDayStart, setWorkDayStart] = useState<Date>(new Date(new Date().setHours(6, 0, 0)));
    const [workDayEnd, setWorkDayEnd] = useState<Date>(new Date(new Date().setHours(19, 0, 0)));
    const [showUnboundEvents, setShowUnboundEvents] = useState<boolean>(false);
    const [showUnrecordedJobs, setShowUnrecordedJobs] = useState<boolean>(false);
    const [unrecordedTimeThreshold, setUnrecordedTimeThreshold] = useState<number>(0);

    const selectedDateIso = selectedDate.format('YYYY-MM-DD');
    const dateRange = useMemo(() => getDateRangeByWeek(selectedDateIso), [selectedDateIso, selectedDate]);

    const { items: timeEntries } = useTimeEntries({ dateRange: dateRange });

    const weekStats = useMemo(() => {
        return getStatsForTimeEntries(timeEntries);
    }, [timeEntries]);

    const dayStats = useMemo(() => {
        return weekStats.byDay[selectedDateIso];
    }, [selectedDateIso, weekStats]);

    const unfilteredWorkers = UserService.getActiveUsers();
    const workers = useMemo(() => {
        if (showAllWorkers) return unfilteredWorkers;
        if (!dayStats?.timeEntries) return [];
        return unfilteredWorkers.filter(worker => dayStats.timeEntries.find((te: RawTimeEntry) => te.assigneeId === worker._id));
    }, [showAllWorkers, dayStats]);

    const unboundEvents = useUnboundStoredEvents({ date: selectedDate.format('YYYY-MM-DD') });

    useEffect(() => {
        const settings = localStorage.getItem('time-tracker-settings');

        if (!settings) return;
        const parsedSettings = JSON.parse(settings);

        setShowAllWorkers(parsedSettings.showAllWorkers);
        setShowUnboundEvents(parsedSettings.showUnboundEvents);
        setShowUnrecordedJobs(parsedSettings.showUnrecordedJobs);
        setWorkDayStart(new Date(parsedSettings.defaultDayStart));
        setWorkDayEnd(new Date(parsedSettings.defaultDayEnd));
        setUnrecordedTimeThreshold(parsedSettings.unrecordedTimeThreshold);
    }, []);

    return (
        <TrackerContext.Provider
            value={{
                showAllWorkers,
                setShowAllWorkers,
                selectedWorkerId,
                setSelectedWorkerId,
                selectedDate,
                setSelectedDate,
                workDayStart,
                setWorkDayStart,
                workDayEnd,
                setWorkDayEnd,
                showUnboundEvents,
                setShowUnboundEvents,
                showUnrecordedJobs,
                setShowUnrecordedJobs,
                unrecordedTimeThreshold,
                setUnrecordedTimeThreshold,
                dayStats,
                weekStats,
                workers,
                unboundEvents,
                timeEntries,
            }}
        >
            {children}
        </TrackerContext.Provider>
    );
};

export default TrackerContext;
