import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import AppProviders from '../AppProviders';
import ApplicationStateProvider from '../components/ApplicationStateProvider';
import { BrowserFallBackToHashRouter } from '../components/BrowserFallBackToHashRouter';
import Text from '../components/Text';
import TrackerApp from './TrackerApp';
import TrackerAppProviders from './TrackerAppProviders';

export const Tracker = (element: HTMLElement) => {
    const root = createRoot(element);
    root.render(
        <AppProviders>
            <ApplicationStateProvider>
                <BrowserFallBackToHashRouter>
                    <TrackerAppProviders>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <ErrorBoundary fallback={<Text text='general.unable-to-load-view' />}>
                                <TrackerApp />
                            </ErrorBoundary>
                        </LocalizationProvider>
                    </TrackerAppProviders>
                </BrowserFallBackToHashRouter>
            </ApplicationStateProvider>
        </AppProviders>
    );

    return {
        unmount: () => root.unmount(),
    };
};
