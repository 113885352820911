import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';

const CheckboxExamples = () => {
    return (
        <div>
            <Grid container spacing={4} padding={3}>
                <Grid item>
                    <Box sx={{ border: 1, borderRadius: '12px', borderColor: '#ccc', width: '14rem', px: 2, pb: 2, pt: 0.5 }}>
                        <Typography variant='h6' textAlign='center' sx={{ mb: 0.5 }}>
                            Basic
                        </Typography>
                        <Checkbox title='Checkbox' checked={true} />
                        <Checkbox title='Checkbox' checked={false} />
                        <Checkbox title='Checkbox' checked={true} disabled={true} />
                        <Checkbox title='Checkbox' checked={false} disabled={true} />
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{ border: 1, borderRadius: '12px', borderColor: '#ccc', width: '14rem', px: 2, pb: 2, pt: 0.5 }}>
                        <Typography variant='h6' textAlign='center' sx={{ mb: 0.5 }}>
                            Size
                        </Typography>
                        <Box display='flex' justifyContent='center'>
                            <Checkbox title='Checkbox' checked={true} size='small' />
                            <Checkbox title='Checkbox' checked={true} />
                            <Checkbox title='Checkbox' checked={true} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />
                        </Box>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{ border: 1, borderRadius: '12px', borderColor: '#ccc', width: '14rem', px: 2, pb: 2, pt: 0.5 }}>
                        <Typography variant='h6' textAlign='center' sx={{ mb: 0.5 }}>
                            Coloured
                        </Typography>
                        <Box display='flex' justifyContent='center'>
                            <Checkbox title='Checkbox' checked={true} color='primary' />
                            <Checkbox title='Checkbox' checked={true} color='secondary' />
                            <Checkbox title='Checkbox' checked={true} color='success' />
                            <Checkbox title='Checkbox' checked={true} color='error' />
                        </Box>
                        <Box display='flex' justifyContent='center'>
                            <Checkbox title='Checkbox' checked={true} color='warning' />
                            <Checkbox title='Checkbox' checked={true} color='info' />
                        </Box>
                    </Box>
                </Grid>
                <Grid item>
                    <Box sx={{ border: 1, borderRadius: '12px', borderColor: '#ccc', width: '14rem', px: 2, pb: 2, pt: 0.5 }}>
                        <Typography variant='h6' textAlign='center' sx={{ mb: 0.5 }}>
                            With Label
                        </Typography>
                        <Box display='flex' justifyContent='center'>
                            <FormControlLabel control={<Checkbox checked={true} />} label='Label' />
                            <FormControlLabel control={<Checkbox checked={false} />} label='Label' />
                        </Box>
                        <Box display='flex' justifyContent='center'>
                            <FormControlLabel control={<Checkbox checked={true} disabled={true} />} label='Label' />
                            <FormControlLabel control={<Checkbox checked={false} disabled={true} />} label='Label' />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default CheckboxExamples;
