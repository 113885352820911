import { Breadcrumbs, Grid, Link } from '@mui/material';
import { PageContainer } from '@nexdynamic/nex-ui-react';
import { useNavigate } from 'react-router';
import Text from '../../../../components/Text';
import type { AudienceFormFields } from '../component/AudienceForm';
import AudienceForm from '../component/AudienceForm';
import useCreateAudience from '../hooks/useCreateAudience';

const CreateAudience = () => {
    const navigate = useNavigate();

    const { createAudience } = useCreateAudience();

    const create = async (fields: AudienceFormFields) => {
        const audience = await createAudience(fields);
        if (audience) navigate(`/send/audiences/${audience._id}`);
    };

    return (
        <PageContainer>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Breadcrumbs aria-label='breadcrumb'>
                            <Link
                                sx={{ cursor: 'pointer' }}
                                component='button'
                                underline='none'
                                onClick={() => navigate('/send/audiences')}
                            >
                                <Text variant='subtitle1' text='audiences.title' />
                            </Link>
                            <Text variant='subtitle1' color='text.primary' text='audiences.create-action' />
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <AudienceForm onSubmit={create} />
            </Grid>
        </PageContainer>
    );
};

export default CreateAudience;
