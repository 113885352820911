import type { portalComponentSettingsDefaults } from '@nexdynamic/squeegee-portal-common';

export const widgetSizes: {
    [key in keyof typeof portalComponentSettingsDefaults]: {
        minW: number;
        minH: number;
        maxW?: number;
        maxH?: number;
    };
} = {
    'address-search': {
        minW: 1,
        minH: 1,
    },
    'appointment-history': {
        minW: 1,
        minH: 5,
    },
    'all-quotes': {
        minW: 1,
        minH: 5,
    },
    'automatic-payments': {
        minW: 1,
        minH: 5,
    },
    'book-appointment': {
        minW: 1,
        minH: 5,
    },
    'component-previewer': {
        minW: 4,
        minH: 10,
    },
    'edit-profile-button': {
        minW: 1,
        minH: 1,
        maxW: 1,
        maxH: 1,
    },
    'sign-in': {
        minW: 2,
        minH: 7,
    },
    'sign-out': {
        minW: 1,
        minH: 1,
        maxH: 1,
    },
    'financial-history': {
        minW: 1,
        minH: 5,
    },
    'upcoming-appointments': {
        minW: 1,
        minH: 5,
    },
    'my-ratings': {
        minW: 1,
        minH: 1,
    },
    'quote-flow': {
        minW: 1,
        minH: 6,
    },
    'stripe-pre-pay': {
        minW: 1,
        minH: 5,
    },
    'todays-appointment': {
        minW: 1,
        minH: 5,
    },
    'welcome-card': {
        minW: 1,
        minH: 3,
    },
    'chat': {
        minW: 1,
        minH: 4,
    },
    'layout': {
        minW: 1,
        minH: 1,
    },
    'profile': {
        minW: 1,
        minH: 5,
    },
    'quote': {
        minW: 1,
        minH: 6,
    },
    'spacer': {
        minW: 1,
        minH: 1,
    },
};
