import moment from 'moment';
import type { GraphQLLecture, Lecture } from '../Views/TrainingView';

export const refreshLectureCache = async (currentLectures: Array<Lecture>) => {
    const response = await fetch('https://cms.sqg.ee/graphql', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            query: `
            {
                lectures {
                    title
                    description
                    content
                    videoSrc
                    slug
                    lesson {
                        title
                        slug
                        cover {
                            url
                        }
                    }
                }
            }
        `,
        }),
    });

    const result: {
        data: { lectures: Array<GraphQLLecture> };
    } = (await response.json()) || undefined;
    if (result?.data?.lectures) {
        currentLectures = result.data.lectures
            .filter(l => l.lesson?.cover?.url)
            .map(({ title, description, content, videoSrc, slug, lesson }: GraphQLLecture) => {
                title = title.replace(/^Module \d+?\.\d+?\.?\d*? /, '').trim();
                title = title.slice(0, 1).toUpperCase() + title.slice(1);
                const videoId =
                    (videoSrc && /(?:embed\/(.*)$)/.exec(videoSrc)?.[1]) ||
                    (videoSrc && /(?:\?v=(.*)$)/.exec(videoSrc)?.[1]) ||
                    (videoSrc && /(?:youtu\.be\/(.*))/.exec(videoSrc)?.[1]) ||
                    undefined;
                const lecture: Lecture = {
                    title,
                    description,
                    content,
                    videoId,
                    lesson: lesson?.title || '',
                    url: `https://squeeg.ee/academy/${lesson?.slug}/${slug}`,
                    imageUrl: lesson?.cover?.url || '',
                };
                return lecture;
            })
            .sort((a, b) => a.lesson.localeCompare(b.lesson, undefined, { sensitivity: 'base', numeric: true }));
        localStorage.setItem(
            'squeegee-training-videos',
            JSON.stringify({ created: moment().format('YYYY-MM-DD'), lectures: currentLectures })
        );
    }
};
